import SheetColumn from './../../../../sheetImporter/SheetColumn';
import { DataModel } from 'dataModel';
import useEmptyState from '../common/emptyState';

const useViewModel = ({
  sheetColumn,
  matchedDataModel,
  prefixName,
}: {
  sheetColumn: SheetColumn;
  matchedDataModel?: DataModel;
  prefixName: string;
}) => {
  const { noPreviewIndicate, noPreviewValuesRows } = useEmptyState({
    prefixName,
    sheetColumn,
    matchedDataModel,
  });

  const hasBorderSpacing = !noPreviewIndicate;

  return {
    hasBorderSpacing,
    noPreviewIndicate,
    noPreviewValuesRows,
  };
};

export default useViewModel;
