import FeatureWhiteList from '../../settings/FeatureWhiteList';
import { VerifyLicenseKeyResponse } from '../types';

class FeatureMapper {
  static mapDAOtoFeatureWhitelist(
    response: Omit<VerifyLicenseKeyResponse, 'ht_license_key'>
  ) {
    const featureWhiteList = new FeatureWhiteList();

    featureWhiteList.enableI18nOverrides(response.plan_detail.features.i18n);
    featureWhiteList.enableMultiFileUpload(
      response.plan_detail.features.multiple_file_upload
    );
    featureWhiteList.enableReuseMapping(
      response.plan_detail.features.remember_mapping
    );
    featureWhiteList.enableAutomaticMapping(
      response.plan_detail.features.automatic_mapping,
      response.plan
    );
    featureWhiteList.setEnableDataHandler(
      response.plan_detail.features.data_handler,
      response.plan
    );

    if (response.plan_detail.upload_size_limit === 0) {
      featureWhiteList.setFileSizeUnlimited();
    } else {
      featureWhiteList.setMaxFileSizeInMb(
        response.plan_detail.upload_size_limit
      );
    }
    featureWhiteList.setInputTypes(
      response.plan_detail.features.input_types || []
    );
    featureWhiteList.setDefaultLanguage(response.default_language ?? 'en');
    featureWhiteList.setDynamicImport(
      response.plan_detail.features.dynamic_import,
      response.plan
    );
    featureWhiteList.setCustomStyling(
      response.plan_detail.features.custom_style,
      response.plan
    );
    featureWhiteList.setEnableLogs(response.plan_detail.features.logs);
    featureWhiteList.setLoading(false);
    featureWhiteList.setLocale(response.locale || {});
    featureWhiteList.setRowsLimit(
      response.plan_detail.sdk_rows_limit ?? undefined
    );
    featureWhiteList.setEnableSmartTable(
      response.plan_detail.features.smart_table || false
    );
    featureWhiteList.setEnableCleaningAssistant(
      response.plan_detail.features.cleaning_assistant || false
    );
    featureWhiteList.setEnableWatermark(
      response.plan_detail.features.watermark || false
    );

    return featureWhiteList;
  }

  static getEmptyFeatureWhitelist() {
    const featureWhiteList = new FeatureWhiteList();
    featureWhiteList.setLoading(false);
    return featureWhiteList;
  }
}

export default FeatureMapper;
