import { breakpoints } from 'core/constants/breakpoints';
import { useMemo } from 'react';
import { useTheme } from 'theme';
import { css, CSSObject, cx } from '../../../../../core/emotion';

const style = css`
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

const ProcessingRows = () => {
  const theme = useTheme();
  const items = useMemo(() => {
    return [...Array(3)];
  }, []);

  return (
    <div>
      {items.map((_, index) => {
        const isLastRowIndex = index === items.length - 1;
        return (
          <div
            key={index}
            className={cx(
              ' border-1 flex h-12 bg-white pr-4',
              css({
                borderColor: theme.getGlobalTheme().getLight100Color(),
              }),
              isLastRowIndex ? 'rounded-b-medium' : 'border-b-0',
              style,
              css({ '&&': theme.getColumnMatchTheme().columnMatchValue.root }),
              css({
                '&&': {
                  borderColor: (
                    theme.getColumnMatchTheme().columnMatchValue
                      .root as CSSObject
                  )?.borderColor,
                },
              })
            )}
          >
            <div className="flex w-full">
              <div
                className={cx(
                  'border-r-1 xssmd:w-14 flex w-9 flex-shrink-0 items-center justify-center',
                  css({
                    borderColor: theme.getGlobalTheme().getLight100Color(),
                  }),
                  css({
                    '&&': {
                      borderColor: (
                        theme.getColumnMatchTheme().columnMatchValue
                          .root as CSSObject
                      )?.borderColor,
                    },
                  })
                )}
              >
                <div className="h-full w-full py-3 px-4">
                  <div
                    className={cx(
                      'bg-gray-150 h-full w-full animate-pulse rounded',
                      css({
                        '&&': theme.getColumnMatchTheme().columnMatchValue
                          .loading,
                      })
                    )}
                  />
                </div>
              </div>
              <div className="xssmd:pl-4 flex w-full items-center py-3 pl-2">
                <div
                  className={cx(
                    'bg-gray-150 h-full w-full animate-pulse rounded',
                    css({
                      '&&': theme.getColumnMatchTheme().columnMatchValue
                        .loading,
                    })
                  )}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProcessingRows;
