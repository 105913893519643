import Sheet, { SheetData } from './Sheet';
import SpreadSheet, { SpreadSheetType } from './SpreadSheet';

export type { SheetData };

export type MultipleSheetData = {
  sheetName: string;
  data: SheetData;
}[];

export interface SpreadSheetMapper {
  getSpreadSheet({
    autoDetectedHeader,
  }: {
    autoDetectedHeader: boolean;
  }): SpreadSheet;
}

export class SingleSpreadSheetMapper implements SpreadSheetMapper {
  private sheetData: SheetData;
  private filename: string;
  private type: SpreadSheetType;
  private sheetName: string;
  private fileSize: number;

  constructor({
    sheetData,
    filename,
    type,
    sheetName,
    fileSize,
  }: {
    sheetData: SheetData;
    filename: string;
    type: SpreadSheetType;
    sheetName: string;
    fileSize: number;
  }) {
    this.sheetData = sheetData;
    this.filename = filename;
    this.type = type;
    this.sheetName = sheetName;
    this.fileSize = fileSize;
  }

  getSpreadSheet = ({
    autoDetectedHeader,
  }: {
    autoDetectedHeader: boolean;
  }) => {
    const sheet = new Sheet({
      name: this.sheetName,
      data: this.sheetData,
    });

    if (autoDetectedHeader) {
      sheet.setHeaderByAutoDetection();
    }

    const sheets = [sheet];

    return new SpreadSheet({
      sheets,
      filename: this.filename,
      type: this.type,
      fileSize: this.fileSize,
    });
  };
}

export class MultipleFileSpreadSheetMapper implements SpreadSheetMapper {
  private multipleSheetData: MultipleSheetData;
  private filename: string;
  private type: SpreadSheetType;
  private fileSize: number;

  constructor({
    multipleSheetData,
    filename,
    type,
    fileSize,
  }: {
    multipleSheetData: MultipleSheetData;
    filename: string;
    type: SpreadSheetType;
    fileSize: number;
  }) {
    this.multipleSheetData = multipleSheetData;
    this.filename = filename;
    this.type = type;
    this.fileSize = fileSize;
  }

  getSpreadSheet = ({
    autoDetectedHeader,
  }: {
    autoDetectedHeader: boolean;
  }) => {
    const sheets = this.multipleSheetData.map((sheetData) => {
      const sheet = new Sheet({
        data: sheetData.data,
        name: sheetData.sheetName,
      });

      if (autoDetectedHeader) {
        sheet.setHeaderByAutoDetection();
      }

      return sheet;
    });

    return new SpreadSheet({
      type: this.type,
      filename: this.filename,
      sheets,
      fileSize: this.fileSize,
    });
  };
}
