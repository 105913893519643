import { BehaviorSubject } from 'rxjs';
import FilterStrategy, { FilterState } from './FilterStrategy';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import SortStrategy, { SortState } from './SortStrategy';
import Handsontable from 'handsontable';
import FreezeStrategy from './FreezeStrategy';
import HideStrategy from './HideStrategy';
import { DataModel } from '../../../dataModel/model/DataModel';
import ColumnSettingHelper from './ColumnSettingHelper';

export type ColumnSetting = {
  sort: SortState | null;
  freeze: boolean;
  hide: boolean;
  filterState: FilterState | null;
  id: string;
  refIndex: number;
};

class AllColumnSetting {
  private columnSettings: ColumnSetting[] = [];
  private _contextMenuObservable: BehaviorSubject<AllColumnSetting>;
  private hotInstance?: Handsontable;
  private freezeStrategy: FreezeStrategy;
  private hideStrategy: HideStrategy;
  private sortStrategy: SortStrategy;
  private filterStrategy: FilterStrategy;
  private readOnly?: boolean;

  constructor(
    columnSettings: ColumnSetting[],
    columns: ColumnAPI[],
    readOnly?: boolean
  ) {
    this.columnSettings = columnSettings;
    this._contextMenuObservable = new BehaviorSubject<AllColumnSetting>(this);
    this.sortStrategy = new SortStrategy();
    this.freezeStrategy = new FreezeStrategy();
    this.hideStrategy = new HideStrategy();
    this.filterStrategy = new FilterStrategy(columns);
    this.readOnly = readOnly;
  }

  getSortStrategy = () => {
    return this.sortStrategy;
  };

  getFilterStrategy = () => {
    return this.filterStrategy;
  };

  getFreezeStrategy = () => {
    return this.freezeStrategy;
  };

  getSortingColumnIndex = () => {
    return this.columnSettings.findIndex(
      (columnSetting) => columnSetting.sort !== null
    );
  };

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
    this.sortStrategy.setHotInstance(this.hotInstance);
    this.hideStrategy.setHotInstance(this.hotInstance);
    this.freezeStrategy.setHotInstance(this.hotInstance);
    this.columnSettings = this.freezeStrategy.initialFreezeColumns(
      this.columnSettings
    );
  };

  contextMenuObservable = () => {
    return this._contextMenuObservable;
  };

  getColumnSetting = (columnIndex: number) => {
    return this.columnSettings[columnIndex];
  };

  getAllColumnSettings = () => {
    return this.columnSettings;
  };

  setSortToColumnSetting = (columnIndex: number, sort: SortState | null) => {
    for (let i = 0; i < this.columnSettings.length; ++i) {
      this.columnSettings[i].sort = null;
    }
    this.columnSettings[columnIndex].sort = sort;
    this._contextMenuObservable.next(this);
    this.sortStrategy.sortColumn(columnIndex, sort);
  };

  updateId = (oldId: string, updatedId: string) => {
    for (let i = 0; i < this.columnSettings.length; ++i) {
      if (this.columnSettings[i].id === oldId) {
        this.columnSettings[i].id = updatedId;
        break;
      }
    }
  };

  setFilterToColumnSetting = (
    columnIndex: number,
    filter: FilterState | null,
    dataModel: DataModel
  ) => {
    ColumnSettingHelper.setFilter(this.columnSettings[columnIndex], filter);
    this.filterStrategy.filter(
      columnIndex,
      filter,
      !this.hasFilter() || this.readOnly,
      dataModel
    );
    this._contextMenuObservable.next(this);
  };

  clearFilterToColumnSetting = (columnIndex: number, dataModel: DataModel) => {
    ColumnSettingHelper.setFilter(this.columnSettings[columnIndex], null);
    this.filterStrategy.filter(columnIndex, null, !this.hasFilter(), dataModel);
    this._contextMenuObservable.next(this);
  };

  clearFilterToAllColumnSettings = (dataModels: DataModel[]) => {
    this.columnSettings.forEach((_, columnIndex) => {
      ColumnSettingHelper.setFilter(this.columnSettings[columnIndex], null);
    });
    this.filterStrategy.clearAllFilterConditions(dataModels);
  };

  setFreezeToColumnSetting = (columnIndex: number, freeze: boolean) => {
    this.columnSettings = this.freezeStrategy.freezeColumn(
      columnIndex,
      freeze,
      this.columnSettings
    );
    this.hotInstance?.render();
    this._contextMenuObservable.next(this);
  };

  hasMaxFreezeColumn = () => {
    return this.freezeStrategy.hasMaxFreezeColumn();
  };

  setHideToColumnSetting = (columnIndex: number, hide: boolean) => {
    this.columnSettings = this.hideStrategy.hideColumn(
      columnIndex,
      hide,
      this.columnSettings
    );
    this._contextMenuObservable.next(this);
  };

  hasFilter(): boolean {
    return this.columnSettings.some((columnSetting) => {
      return this.checkHasFilter(columnSetting);
    });
  }

  hasFilterByIndex = (columnIndex: number) => {
    return this.checkHasFilter(this.columnSettings[columnIndex]);
  };

  filterCount = () => {
    let count = 0;
    for (let i = 0; i < this.columnSettings.length; ++i) {
      if (this.hasFilterByIndex(i)) {
        count++;
      }
    }

    return count;
  };

  registerEventHeaderMenuButton = (
    th: HTMLTableHeaderCellElement,
    colIndex: number
  ) => {
    const titleElement = th.querySelector(
      '#text-title'
    ) as HTMLDivElement | null;

    if (!titleElement) return;

    titleElement.addEventListener(
      'click',
      () => {
        this.sortStrategy.showLoading(colIndex);
        setTimeout(() => {
          if (this.columnSettings[colIndex].sort === null) {
            this.setSortToColumnSetting(colIndex, SortState.ASC);
          } else if (this.columnSettings[colIndex].sort === SortState.ASC) {
            this.setSortToColumnSetting(colIndex, SortState.DESC);
          } else {
            this.setSortToColumnSetting(colIndex, null);
          }
        }, 100);
      },
      false
    );
  };

  getHiddenColumns = () => {
    return this.columnSettings
      .map((col, colIndex) => {
        if (col.hide) {
          return colIndex;
        } else {
          return null;
        }
      })
      .filter((col) => col !== null) as number[];
  };

  getFreezeColumns = (includeHide = true) => {
    return this.columnSettings
      .map((col, colIndex) => {
        if (col.freeze && (includeHide ? true : !col.hide)) {
          return colIndex;
        } else {
          return null;
        }
      })
      .filter((col) => col !== null) as number[];
  };

  isPreviousFreeze = (colIndex: number) => {
    for (let i = colIndex - 1; i >= 0; --i) {
      if (this.columnSettings[i].hide) {
        continue;
      }
      return this.columnSettings[i].freeze;
    }
    return false;
  };

  isNextFreeze = (colIndex: number) => {
    for (let i = colIndex + 1; i < this.columnSettings.length; ++i) {
      if (this.columnSettings[i].hide) {
        continue;
      }
      return this.columnSettings[i].freeze;
    }
    return false;
  };

  hasMaximumHideColumn = () => {
    return this.hideStrategy.hasMaximumHideColumn(this.columnSettings);
  };

  showHideColumns = (columnIndex: number, isShowLeft: boolean) => {
    return this.hideStrategy.showHideColumns(
      this.columnSettings,
      columnIndex,
      isShowLeft
    );
  };

  getDisplayHideIcon = () => {
    return this.hideStrategy.displayShowColumnIcon(this.columnSettings);
  };

  addColumnSetting = (key: string) => {
    this.columnSettings.push({
      id: key,
      refIndex: this.columnSettings.length,
      freeze: false,
      hide: false,
      sort: null,
      filterState: null,
    });
  };

  removeColumnSetting = (key: string) => {
    const removeIndex = this.columnSettings.findIndex(
      (columnSetting) => columnSetting.id === key
    );
    if (removeIndex < 0) {
      return;
    }
    const removeColumnSetting = this.columnSettings[removeIndex];
    if (!removeColumnSetting) {
      return;
    }

    const updateColumnSettings: ColumnSetting[] = [];
    for (let i = 0; i < this.columnSettings.length; ++i) {
      const columnSetting = this.columnSettings[i];
      if (columnSetting.id === removeColumnSetting.id) {
        continue;
      }

      if (columnSetting.refIndex > removeColumnSetting.refIndex) {
        columnSetting.refIndex -= 1;
      }

      updateColumnSettings.push(columnSetting);
    }
    this.columnSettings = updateColumnSettings;

    if (this.checkHasFreeze(removeColumnSetting)) {
      this.freezeStrategy.removeColumn(removeIndex);
    }
  };

  private checkHasFreeze = (columnSetting: ColumnSetting) => {
    return columnSetting.freeze;
  };

  private checkHasFilter = (columnSetting: ColumnSetting) => {
    return !!(
      ColumnSettingHelper.hasFilterCondition(columnSetting, 0) ||
      ColumnSettingHelper.getFilterByValueSelected(columnSetting)
    );
  };
}

export default AllColumnSetting;
