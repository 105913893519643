import { cx } from '@nuvo-importer/common';
import { SheetColumn } from '@nuvo-importer/common/sdk';
import { useField } from 'react-final-form';

const AppendColumn = ({
  name,
  className,
}: {
  name: string;
  className?: string;
}) => {
  const { input } = useField<SheetColumn>(name);

  return (
    <div
      className={cx(
        'bg-gray-120 rounded-medium border-gray-210 h-8.5 text-blue-dark-900 flex items-center truncate border px-3 text-sm',
        className
      )}
    >
      <span>{input.value.getColumnKey()}</span>
    </div>
  );
};

export default AppendColumn;
