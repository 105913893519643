/* istanbul ignore file */

import { SubmitResult, SubmitResultType } from 'core/submitResult';

export default class RejectSubmitResult implements SubmitResult {
  private title: string;
  private message: string;
  public type = SubmitResultType.Reject;

  constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
  }

  getTitle(): string {
    return this.title;
  }

  getMessage(): string {
    return this.message;
  }
}
