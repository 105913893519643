import { ReactComponent as CloseIcon } from '../../../../../../../../assets/icon/close-gray.svg';

const ClearButton = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      className="absolute top-1/2 right-8 -translate-y-1/2 transform"
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CloseIcon className={className} />
    </div>
  );
};

export default ClearButton;
