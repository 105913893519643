/* istanbul ignore file */

import {
  CalculateSimilarityResult,
  ColumnConfig,
  ExecuteOptions,
  InputData,
  MatchingAble,
} from './types/ml';
import { Observable, zip, EMPTY } from 'rxjs';

export interface NuvoMachineLearningAble {
  execute: (
    columns: ColumnConfig[],
    inputJsons: InputData[],
    licenseKey: string,
    options: ExecuteOptions
  ) => Observable<CalculateSimilarityResult[]>;
  loadVector: () => Observable<unknown>;
  getVectors: () => Promise<Record<string, Float32Array>>;
}

class NuvoMachineLearning implements NuvoMachineLearningAble {
  private matching: MatchingAble;
  private locked = false;

  getVectors = () => {
    return this.matching.getVectors();
  };

  constructor(matching: MatchingAble) {
    this.matching = matching;
  }

  loadVector = () => {
    if (this.locked) {
      return EMPTY;
    }

    return new Observable((subscriber) => {
      this.locked = true;
      this.matching
        .loadVector()
        .then(() => {
          subscriber.next();
        })
        .catch((error) => {
          subscriber.error(error);
        })
        .finally(() => {
          this.locked = false;
          subscriber.complete();
        });
    });
  };

  private executeOne = (
    columns: ColumnConfig[],
    inputJson: InputData,
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return new Observable<CalculateSimilarityResult>((subscriber) => {
      setTimeout(() => {
        this.matching
          .execute(
            columns,
            inputJson,
            {
              licenseKey,
              framework: 'react',
            },
            options
          )
          .then((result) => {
            subscriber.next(result);
          })
          .catch((error) => {
            subscriber.error(error);
          })
          .finally(() => {
            subscriber.complete();
          });
      }, 100);
    });
  };

  execute = (
    columns: ColumnConfig[],
    inputJsons: InputData[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return zip(
      inputJsons.map((inputJson) => {
        return this.executeOne(columns, inputJson, licenseKey, options);
      })
    );
  };

  cleanUp = () => {
    this.cleanUp();
  };
}

export default NuvoMachineLearning;
