import { css, CSSInterpolation, CSSObject, cx } from '../../core/emotion';
import { Switch as ToggleSwitch } from '@headlessui/react';
import { Variant } from './variantStyle';
import useViewModel from './viewModel';

type SwitchParams = {
  label?: string;
  value: boolean;
  onChange: (enabled: boolean) => void;
  variant?: Variant;
  className?: string;
  configTheme?: {
    root?: CSSInterpolation;
    label?: CSSInterpolation;
  };
  clickAble?: boolean;
};

const Switch = ({
  label,
  value,
  onChange,
  variant = 'primary',
  className,
  configTheme,
  clickAble,
}: SwitchParams) => {
  const { variantClassname } = useViewModel({ variant });

  const switchThemeStyle = cx(
    variantClassname,
    css({ '&&': configTheme?.root })
  );

  return (
    <ToggleSwitch.Group as="div" className="flex items-center">
      <ToggleSwitch
        disabled={!clickAble}
        checked={value}
        onChange={onChange}
        className={cx(
          className,
          value ? switchThemeStyle : 'bg-gray-560',
          'focus:outline-none relative flex h-5 w-8 flex-shrink-0 items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          clickAble ? 'cursor-pointer' : 'cursor-not-allowed'
        )}
      >
        <span
          aria-hidden="true"
          className={cx(
            value ? 'translate-x-3' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </ToggleSwitch>
      <ToggleSwitch.Label as="span" className="ml-2">
        <span
          className={cx(
            'text-color-primary text-base',
            css({ '&&': configTheme?.label }),
            css({ '&&': { color: (configTheme?.label as CSSObject)?.color } })
          )}
        >
          {label}
        </span>
      </ToggleSwitch.Label>
    </ToggleSwitch.Group>
  );
};

export default Switch;
