import { useTranslation } from 'react-i18next';
import ContextMenuItem from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import useViewModel from './viewModel';
import { ConfigTheme } from '../../type';
import { DataModel } from '../../../../dataModel/model/DataModel';

const DeleteMenuItem = ({
  configTheme,
  deleteColumn,
  dataModel,
}: {
  configTheme?: ConfigTheme;
  dataModel: DataModel;
  deleteColumn: (columnKey: string) => void;
}) => {
  const { t } = useTranslation();
  const { onMenuClick } = useViewModel({
    dataModel,
    deleteColumn,
  });

  return (
    <ContextMenuItem
      icon={ContextMenuIcon.DELETE}
      text={t('txt_delete_button')}
      onClick={onMenuClick}
      configTheme={configTheme}
      variant="danger"
    />
  );
};

export default DeleteMenuItem;
