export enum MODIFIER_TYPE {
  ADD_ROW = 'addRow',
  ADD_COLUMN = 'addColumn',
  REMOVE_ROW = 'removeRow',
  REMOVE_COLUMN = 'removeColumn',
}

export type {
  DataHandler,
  DataHandlerHeaderStep,
  ReviewStepHandlerData,
  DataHandlerReviewStep,
  DataHandlerMetaData,
  Modifier,
  ModifierAddRow,
  ModifierRemoveRow,
  MappingStepModifierAddColumn,
  ModifierAddColumn,
  ModifierRemoveColumn,
  ModifierStack,
  HeaderStepHandlerData,
  HeaderStepModifierAddRow,
  HeaderStepModifier,
} from './../types';
