import { ColumnAPI } from 'lib-nuvo-react';

export const dataModelCRM: ColumnAPI[] = [
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'company_name',
    label: 'Company Name',
    validations: [{ validate: 'required' }],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'company_domain_name',
    label: 'Company domain name',
    validations: [
      { validate: 'required' },
      {
        validate: 'regex',
        // eslint-disable-next-line
        regex:
          '(https?:\\/\\/|(www\\.)){1}?[-a-zA-Z0-9@:%.\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%\\+.~#?&//=]*)',
        errorMessage:
          'Please use one of the following formats beginning with: https:// or www.',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'phone_number',
    label: 'Phone Number',
    validations: [
      { validate: 'required' },
      {
        validate: 'regex',
        regex:
          '(\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)|00)\\d{3,14}$',
        errorMessage:
          'Please use one of the following formats beginning with +XX or 00XX.',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'city',
    label: 'City',
    validations: [{ validate: 'required' }],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'first_name',
    label: 'First name',
    validations: [{ validate: 'required' }],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'last_name',
    label: 'Last name',
    validations: [{ validate: 'required' }],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'email_address',
    label: 'Email address',
    validations: [
      { validate: 'required' },
      {
        validate: 'unique',
      },
      {
        validate: 'regex',
        // eslint-disable-next-line
        regex:
          '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
        errorMessage: 'Email',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'mobile_phone_number',
    label: 'Mobile phone number',
    validations: [
      {
        validate: 'regex',
        regex:
          '(\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)|00)\\d{3,14}$',
        errorMessage:
          'Please use one of the following formats beginning with +XX or 00XX.',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'deal_name',
    label: 'Deal name',
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'deal_stage',
    label: 'Deal stage',
  },
  {
    columnType: 'category',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'pipeline',
    label: 'Pipeline',
    validations: [
      {
        validate: 'required',
      },
    ],
    dropdownOptions: [
      {
        label: 'Pipeline No. 1',
        value: 'pipeline_1',
        type: 'string',
        alternativeMatches: ['pipeline 1', 'No. 1'],
      },
      {
        label: 'Pipeline No. 2',
        value: 'pipeline_2',
        type: 'string',
        alternativeMatches: ['pipeline 2', 'No. 2'],
      },
      {
        label: 'Pipeline No. 3',
        value: 'pipeline_3',
        type: 'string',
        alternativeMatches: ['pipeline 3', 'No. 3'],
      },
      {
        label: 'Pipeline No. 4',
        value: 'pipeline_4',
        type: 'string',
        alternativeMatches: ['pipeline 4', 'No. 4'],
      },
      {
        label: 'Pipeline No. 5',
        value: 'pipeline_5',
        type: 'string',
        alternativeMatches: ['pipeline 5', 'No. 5'],
      },
      {
        label: 'Pipeline No. 6',
        value: 'pipeline_6',
        type: 'string',
        alternativeMatches: ['pipeline 6', 'No. 6'],
      },
      {
        label: 'Pipeline No. 7',
        value: 'pipeline_7',
        type: 'string',
        alternativeMatches: ['pipeline 7', 'No. 7'],
      },
      {
        label: 'Pipeline No. 8',
        value: 'pipeline_8',
        type: 'string',
        alternativeMatches: ['pipeline 8', 'No. 8'],
      },
      {
        label: 'Pipeline No. 9',
        value: 'pipeline_9',
        type: 'string',
        alternativeMatches: ['pipeline 9', 'No. 9'],
      },
      {
        label: 'Pipeline No. 10',
        value: 'pipeline_10',
        type: 'string',
        alternativeMatches: ['pipeline 10', 'No. 10'],
      },
    ],
  },
  {
    columnType: 'int',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'amount',
    label: 'Amount',
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'close_date',
    label: 'Close date',
    validations: [
      {
        validate: 'regex',
        regex:
          '^(?:(?:31(\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0[1-9]|1\\d|2[0-8])(\\.)(?:(?:0[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{4})$',
        errorMessage:
          'Please use a date format like the following one: dd.mm.yyyy',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'product_of_interest',
    label: 'Product of interest',
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'point_of_contact',
    label: 'Point of contact',
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'country',
    label: 'Country',
    validations: [
      {
        validate: 'required',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'street',
    label: 'Street',
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'contacted_on',
    label: 'Contacted on',
    validations: [
      {
        validate: 'regex',
        regex:
          '^(?:(?:31(\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0[1-9]|1\\d|2[0-8])(\\.)(?:(?:0[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{4})$',
        errorMessage:
          'Please use a date format like the following one: dd.mm.yyyy',
      },
    ],
  },
  {
    columnType: 'string',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'emplyee_ID',
    label: 'Employee ID',
  },
  {
    columnType: 'category',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
    key: 'department',
    label: 'Department',
    dropdownOptions: [
      {
        label: 'HR',
        value: 'hr',
        type: 'string',
      },
      {
        label: 'Sales',
        value: 'sales',
        type: 'string',
      },
      {
        label: 'Marketing',
        value: 'marketing',
        type: 'string',
      },
      {
        label: 'Product',
        value: 'product',
        type: 'string',
      },
      {
        label: 'Design',
        value: 'design',
        type: 'string',
      },
      {
        label: 'Housekeeping',
        value: 'housekeeping',
        type: 'string',
      },
      {
        label: 'Board',
        value: 'board',
        type: 'string',
      },
    ],
  },
];

export const mockDataModels: ColumnAPI[] = [
  {
    columnType: 'category',
    // columnSize: 2,
    dropdownOptions: [
      {
        label: 'fresh: 0° to 2°C',
        value: 'frisch: 0° to 2°C',
        type: 'string',
        alternativeMatches: ['2°C'],
      },
      {
        label: 'fresh: 1° to 4°C',
        value: 'fresh: 1° to 4°C',
        type: 'string',
        alternativeMatches: ['4°C'],
      },
      {
        label: 'fresh: 1° to 7°C',
        value: 'fresh: 1° to 7°C',
        type: 'string',
        alternativeMatches: ['7°C'],
      },
      {
        label: 'dry: 5° to 25°C',
        value: 'dry: 5° to 25°C',
        type: 'string',
        alternativeMatches: ['25°C'],
      },
    ],
    description: 'Storage temperature for the corresponding product',
    key: 'storage_temperature',
    label: 'Storage temperature',
    example: 'fresh: 1° to 7°C',
    validations: [
      {
        validate: 'required',
      },
      {
        validate: 'unique',
      },
    ],
    alternativeMatches: ['item1', 'item2', 'item3', 'item4', 'item5'],
  },
  {
    columnType: 'string',
    dropdownOptions: null,
    // columnSize: 1,
    description: 'Unique product id',
    key: 'product_id',
    label: 'Product ID',
    validations: [
      {
        validate: 'required',
      },
      {
        validate: 'unique',
      },
    ],
    example: 'PRO1',
    alternativeMatches: [],
  },
  {
    columnType: 'string',
    dropdownOptions: null,
    // columnSize: 1,
    description: 'the legal retailer name of the product',
    key: 'vendor_name',
    label: 'Vendor name',
    validations: [
      {
        validate: 'required',
      },
      {
        validate: 'required_with',
        columns: ['product_id'],
      },
      {
        validate: 'unique',
        errorMessage: 'Vendor name must be unique.',
      },
    ],
    example: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    alternativeMatches: ['item11', 'item12', 'item13', 'item14', 'item15'],
  },
  {
    columnType: 'string',
    dropdownOptions: null,
    description: 'Description for product',
    key: 'description',
    label: 'Description',
    example: 'This product is for blar blar',
    validations: [
      {
        validate: 'required_without',
        columns: ['storage_temperature'],
      },
      {
        validate: 'required',
      },
    ],
  },
  {
    columnType: 'int',
    dropdownOptions: null,
    description: 'Product pack size',
    key: 'product_size',
    label: 'Product size',
    example: '30',
    validations: [
      {
        validate: 'required_with_all',
        columns: ['storage_temperature', 'product_id'],
      },
      {
        validate: 'required_without',
        columns: ['product_id'],
      },
    ],
  },
  {
    columnType: 'category',
    dropdownOptions: [
      { label: 'ml', value: 'ml', type: 'string' },
      { label: 'g', value: 'g', type: 'string' },
      { label: 'kg', value: 'kg', type: 'string' },
      { label: 'piece', value: 'piece', type: 'string' },
    ],
    description: 'Product size metric',
    key: 'Product_size_metric',
    label: 'Product size metric',
    validations: [
      {
        validate: 'required_without_all',
        columns: ['storage_temperature', 'product_id'],
      },
      {
        validate: 'required_without_all_values',
        columnValues: {
          storage_temperature: ['fresh: 1° to 7°C'],
          vendor_name: ['boss'],
        },
        regex: null,
      },
    ],
    example: 'piece',
    alternativeMatches: ['item21', 'item22', 'item23', 'item24', 'item25'],
  },
  {
    columnType: 'string',
    dropdownOptions: null,
    description: 'Calorific value (kcal)',
    key: 'calorific_value',
    label: 'Calorific value (kcal)',
    validations: [
      {
        validate: 'required_with_values',
        columnValues: {
          storage_temperature: ['test', 'foo', 'bar'],
        },
      },
      {
        validate: 'required',
        regex: null,
        errorMessage: 'Calorific must be provided',
      },
      {
        validate: 'regex',
        columnValues: null,
        regex: '^(([0-9,]+[ ]{1}kcal)|(0))$',
      },
    ],
    example: '20 kcal',
  },
  {
    columnType: 'boolean',
    dropdownOptions: null,
    description: 'Whether the outer packaging unit has a deposit',
    key: 'deposit',
    label: 'Deposit of the outer packaging unit',
    validations: [
      {
        validate: 'required_without_values',
        columnValues: {
          storage_temperature: 'test',
        },
      },
    ],
    example: '',
  },
  {
    columnType: 'float',
    description: 'Shipping stage',
    key: 'dispatch',
    label: 'Number of dispatch stage 1',
    validations: [
      {
        validate: 'required_with_all',
        columns: ['storage_temperature', 'product_id'],
      },
      { validate: 'required', columnValues: null, regex: null },
    ],
    example: '20',
  },
  {
    columnType: 'string',
    description: 'Price in US',
    key: 'price_in_us',
    label: 'Price in US',
    validations: [
      {
        validate: 'required_with_all',
        columns: ['product_id', 'vendor_name'],
      },
    ],
    example: '20$',
  },
  {
    columnType: 'string',
    description: 'Shipping location',
    key: 'shipping_location',
    label: 'Shipping location',
    validations: [
      {
        validate: 'required_with_values',
        columnValues: {
          Product_size_metric: ['g', 'kg'],
        },
      },
    ],
    example: 'USA, Germany',
  },
  {
    columnType: 'category',
    dropdownOptions: [
      { label: '0,02€', value: 0.02, type: 'float' },
      { label: '0,03€', value: 0.03, type: 'float' },
      { label: '0,08€', value: 0.08, type: 'float' },
      { label: '0,15€', value: 0.15, type: 'float' },
      { label: '0,25€', value: 0.25, type: 'float' },
    ],
    description: 'Deposit per unit applies to your product.',
    key: 'deposit_per_unit',
    label: 'Deposit per unit',
    validations: [
      {
        validate: 'required_without_all',
        columns: ['storage_temperature', 'Product_size_metric'],
        regex: null,
      },
    ],
    example: '0,02€',
  },
  {
    columnType: 'string',
    description: 'More information',
    key: 'note',
    label: 'Note',
    validations: [
      {
        validate: 'required_with_all_values',
        columnValues: {
          Product_size_metric: ['g', 'kg'],
          vendor_name: ['test', 'foo', 'bar'],
        },
      },
    ],
    example: 'Blar Blar anything',
  },
];
