import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { GridSettings } from 'handsontable/settings';
import { compareFunctionFactory as defaultCompareFunctionFactory } from './default';
import { FIRST_AFTER_SECOND, FIRST_BEFORE_SECOND } from './constant';
import { isArray } from 'lodash';

const compareItem = (
  sortOrder: SortOrderType,
  columnMeta: GridSettings,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextValue: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findDropdownOption: (value: any) => any
) => {
  const valueOption = findDropdownOption(value);
  const nextValueOption = findDropdownOption(nextValue);
  const isValueInOption = valueOption !== null;
  const isNextValueInOption = nextValueOption !== null;

  if (isValueInOption && isNextValueInOption) {
    return defaultCompareFunctionFactory(sortOrder, columnMeta)(
      valueOption,
      nextValueOption
    );
  } else if (isValueInOption && !isNextValueInOption) {
    return FIRST_BEFORE_SECOND;
  } else if (!isValueInOption && isNextValueInOption) {
    return FIRST_AFTER_SECOND;
  }

  return defaultCompareFunctionFactory(sortOrder, columnMeta)(value, nextValue);
};

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findDropdownOption: (value: any) => any,
  isMultiple: boolean
) {
  if (!isMultiple) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, nextValue: any) =>
      compareItem(sortOrder, columnMeta, value, nextValue, findDropdownOption);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, nextValue: any) => {
      if (isArray(value) && isArray(nextValue)) {
        return compareItem(
          sortOrder,
          columnMeta,
          value[0],
          nextValue[0],
          findDropdownOption
        );
      } else if (isArray(value) && !isArray(nextValue)) {
        return compareItem(
          sortOrder,
          columnMeta,
          value[0],
          nextValue,
          findDropdownOption
        );
      } else if (!isArray(value) && isArray(nextValue)) {
        return compareItem(
          sortOrder,
          columnMeta,
          value,
          nextValue[0],
          findDropdownOption
        );
      } else {
        return compareItem(
          sortOrder,
          columnMeta,
          value,
          nextValue,
          findDropdownOption
        );
      }
    };
  }
}
