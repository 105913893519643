import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from '../../../../assets/icon/warning.svg';

const WarnNotValuesAreMapped = ({
  configTheme,
}: {
  configTheme?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-fit mt-2 flex items-center">
      <WarningIcon
        className={cx(
          'mr-2 flex-shrink-0',
          css({
            '&&': {
              color: (configTheme?.icon as CSSObject)?.color ?? '#FFC116',
            },
          })
        )}
      />
      <div className={cx('', css({ '&&': configTheme?.root }))}>
        <p
          className={cx(
            'text-xs text-yellow-500',
            css({ '&&': { color: (configTheme?.root as CSSObject)?.color } })
          )}
        >
          {t('txt_not_all_mapped')}
        </p>
      </div>
    </div>
  );
};

export default WarnNotValuesAreMapped;
