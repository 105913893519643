import { ComponentPropsWithoutRef } from 'react';
import { cx } from '../../core/emotion';

type BadgeProps = {
  variant?: 'success' | 'danger' | 'info' | 'warning';
};

const Badge = ({
  children,
  className,
  variant = 'success',
  ...props
}: BadgeProps & ComponentPropsWithoutRef<'span'>) => (
  <div
    className={cx(
      'text-small leading-3.5 flex max-w-full items-center justify-center rounded-xl px-2 py-px',
      {
        'bg-green-500 text-green-50': variant === 'success',
        'bg-salmon-500 text-salmon-50': variant === 'danger',
        'bg-gray-500 text-gray-50': variant === 'info',
        'bg-yellow-500 text-yellow-50': variant === 'warning',
      },
      className
    )}
    {...props}
  >
    <div className="inline-block py-px">{children}</div>
  </div>
);

export default Badge;
