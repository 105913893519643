import { Fragment } from 'react';
import MenuItem from '../MenuItem';
import { UseSelectPropGetters } from 'downshift';
import { Popper } from '../popper';
import { Option } from '../viewModel';
import MenuPane from '../MenuPane';
import { ConfigTheme } from '../type';

type MenuProps<T> = {
  getMenuProps: UseSelectPropGetters<Option<T>>['getMenuProps'];
  isOpen: boolean;
  getItemProps: UseSelectPropGetters<Option<T>>['getItemProps'];
  highlightedIndex: number;
  popper: Popper;
  options: Option<T>[];
  size?: 'small' | 'base';
  configTheme?: ConfigTheme;
  maxHeight?: number;
};

const Menu = <T,>({
  getMenuProps,
  isOpen,
  getItemProps,
  highlightedIndex,
  popper,
  options,
  size,
  configTheme,
  maxHeight,
}: MenuProps<T>) => {
  return (
    <MenuPane
      isOpen={isOpen}
      className="nuvo-dropdown-menu-pane"
      {...getMenuProps({
        ref: popper.popperElement,
      })}
      style={popper.styles.popper}
      configTheme={configTheme}
      maxHeight={maxHeight}
    >
      {options.map((menItem, index) => {
        return (
          <Fragment key={`${menItem.value}`}>
            <MenuItem
              {...getItemProps({ item: menItem, index })}
              isHighlighted={highlightedIndex === index}
              size={size}
              configTheme={configTheme}
            >
              {menItem.label}
            </MenuItem>
            {menItem.separator ? (
              <div className="bg-gray-120 h-px w-full" />
            ) : null}
          </Fragment>
        );
      })}
    </MenuPane>
  );
};

export default Menu;
