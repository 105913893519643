export enum ENVIRONMENT {
  SALE_DEMO,
  PRODUCTION,
  STAGING,
  DEVELOPMENT,
}

export const getEnvironment = () => {
  if (window.location.hostname === 'sales-demo.getnuvo.com') {
    return ENVIRONMENT.SALE_DEMO;
  } else if (window.location.hostname === 'demo-prod.getnuvo.com') {
    return ENVIRONMENT.PRODUCTION;
  } else if (
    process.env.NX_API_URL === 'https://api-gateway-staging.filedocks.com'
  ) {
    return ENVIRONMENT.STAGING;
  } else {
    return ENVIRONMENT.DEVELOPMENT;
  }
};
