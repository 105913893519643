import {
  getSizeByWidthForModal,
  getSizeByWidthForPage,
} from '../../../core/sheetSelection';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useMainView } from 'settings';
import { ViewTemplate } from 'core/constants/storage';
import Sheet from './../../../sheetImporter/Sheet';
import { SpreadSheetType } from './../../../sheetImporter/SpreadSheet';
import { getSelectingSpreadSheetPageIndex } from './utils';
import { NUVO_ELEMENT_ID } from 'core/constants/element';

type UsePaginationParams = {
  sheets: {
    id: string;
    sheets: Sheet[];
    sheet: Sheet;
    filename: string;
    type: SpreadSheetType;
    sheetName: string;
  }[];
  viewTemplate: ViewTemplate;
  setGridHeight: (height: number | null) => void;
};

const defaultPageSize = 8;

const usePagination = ({
  sheets,
  viewTemplate,
  setGridHeight,
}: UsePaginationParams) => {
  const [pageSize, setStatePageSize] = useState(defaultPageSize);

  const { modal } = useMainView();

  const [pageIndex, setPageIndex] = useState(
    getSelectingSpreadSheetPageIndex() || 0
  );

  const onPreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const onNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const onGotoPage = (index: number) => {
    setPageIndex(index);
  };

  const sliceSheets = useMemo(() => {
    return [...sheets].slice(
      pageSize * pageIndex,
      pageSize * pageIndex + pageSize
    );
  }, [sheets, pageIndex, pageSize]);

  const pageCount = Math.ceil(sheets.length / pageSize);

  const pageOptions = useMemo(
    () =>
      pageCount > 0
        ? [...new Array(pageCount)].fill(null).map((d, i) => i)
        : [],
    [pageCount]
  );

  const setPageSize = useCallback((pageSize) => {
    setStatePageSize(pageSize);
  }, []);

  useEffect(() => {
    if (pageIndex >= sheets.length / pageSize) {
      setPageIndex(0);
    }
  }, [pageSize, sheets, pageIndex]);

  useLayoutEffect(() => {
    if (viewTemplate === ViewTemplate.LIST) {
      setPageSize(defaultPageSize);
      return () => {};
    } else {
      const rootElement = document.getElementById(NUVO_ELEMENT_ID.PAGE_ROOT);

      const handleForModal = () => {
        setPageSize(getSizeByWidthForModal(window.innerWidth));
      };

      const handleForPage = () => {
        const elementWidth = rootElement?.offsetWidth;
        if (!elementWidth) {
          setPageSize(defaultPageSize);
          return;
        }
        setPageSize(getSizeByWidthForPage(elementWidth));
      };

      const handleResize = () => {
        if (modal) {
          handleForModal();
        } else {
          handleForPage();
        }

        setTimeout(() => {
          if (viewTemplate === ViewTemplate.GRID) {
            const gridElement = document.getElementById('grid-list-view');

            if (gridElement) {
              setGridHeight(gridElement.getBoundingClientRect().height);
            } else {
              setGridHeight(null);
            }
          }
        }, 0);
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [viewTemplate, modal, setPageSize, setGridHeight]);

  return {
    pageCount,
    pageIndex,
    onPreviousPage,
    onNextPage,
    onGotoPage,
    sliceSheets,
    pageOptions,
  };
};

export default usePagination;
