/* istanbul ignore file */

import Similarity from './Similarity';
import { DataModel } from '../dataModel/model/DataModel';
import Sheet from '../sheet/Sheet';
import SheetColumn from './../sheet/SheetColumn';
import { DATATYPE } from '../dataType';
import { THRESHOLD } from '../constants/similarity';

class SheetColumnDataModelSimilarity {
  private sheetColumn: SheetColumn;
  private dataModel: DataModel;
  private similarity: Similarity;
  private isMarkApplied = false;

  constructor({
    sheetColumn,
    dataModel,
    similarity,
  }: {
    sheetColumn: SheetColumn;
    dataModel: DataModel;
    similarity: Similarity;
  }) {
    this.sheetColumn = sheetColumn;
    this.dataModel = dataModel;
    this.similarity = similarity;
  }

  getSimilarity = () => {
    return this.similarity;
  };

  getSheetColumn = () => {
    return this.sheetColumn;
  };

  getDataModel = () => {
    return this.dataModel;
  };

  isPassThreshold = () => {
    return this.similarity.getSimilarity() > THRESHOLD;
  };

  markApplied = () => {
    this.isMarkApplied = true;
  };

  unMarkApplied = () => {
    this.isMarkApplied = false;
  };

  getMarkApplied = () => {
    return this.isMarkApplied;
  };

  isNull = () => {
    return false;
  };
}

export class NullSheetColumnDataModelSimilarity extends SheetColumnDataModelSimilarity {
  constructor() {
    super({
      dataModel: new DataModel({
        description: 'null',
        example: 'null',
        key: 'null',
        label: 'null',
        type: DATATYPE.STRING,
        columnSize: 1,
      }),
      sheetColumn: new SheetColumn({
        sheet: new Sheet({
          data: [[]],
          name: '',
        }),
        columnKey: '',
        rows: [],
      }),
      similarity: new Similarity({ similarity: 0 }),
    });
  }

  isPassThreshold = () => {
    return false;
  };

  isNull = () => {
    return true;
  };
}

export default SheetColumnDataModelSimilarity;
