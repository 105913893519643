import { DataRow } from '../type';
import { createArrayAssertion } from '../utils';
import { FilterCondition } from '../../../columns/FilterStrategy';
import { DataModel } from '../../../../../../core/dataModel/model/DataModel';

export const CONDITION_NAME = FilterCondition.BY_VALUE;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function condition(dataRow: DataRow, [value]: any[]) {
  return value(dataRow.value, dataRow.meta.type);
}

export const meta = {
  name: 'By value',
  inputsCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputValuesDecorator([data, dataModel]: [any, DataModel]) {
    return [createArrayAssertion(data, dataModel)];
  },
  showOperators: false,
};
