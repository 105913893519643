import { Remote, wrap } from 'comlink';
import {
  CalculateSimilarityResult,
  ColumnConfig,
  ExecuteFn,
  InputData,
  License,
} from '../../types/ml';
import engineWorker from './../../../worker/ml.txt';
import { createWorker } from 'core/worker/createWorker';
import Tracking from '../../../tracking/Tracking';
import { Observable, zip } from 'rxjs';

type BrowserProcessingEngineWrapWorker = {
  executeWorker: ExecuteFn;
};

class BrowserProcessingEngine {
  private worker?: Remote<BrowserProcessingEngineWrapWorker>;

  constructor() {
    const worker = createWorker(engineWorker);
    this.worker = wrap<BrowserProcessingEngineWrapWorker>(worker);
  }

  execute = (
    columns: ColumnConfig[],
    inputData: InputData[],
    licenseKey: string
  ) => {
    return zip(
      inputData.map((data) => {
        return this.executeOne(columns, data, licenseKey);
      })
    );
  };

  private executeOne = (
    columns: ColumnConfig[],
    inputData: InputData,
    licenseKey: string
  ) => {
    return new Observable<CalculateSimilarityResult>((subscriber) => {
      setTimeout(() => {
        this.executeWorker(columns, inputData, {
          licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
        })
          .then((result) => {
            const output = {
              result: [
                {
                  choice: {
                    [result.outputColumnKey]: result.optionSuggestions,
                  },
                  label: result.inputColumnLabel,
                  suggestions: [
                    { key: result.outputColumnKey, value: 1, isSameWord: true },
                  ],
                },
              ],
            };
            subscriber.next(output);
          })
          .catch((error) => {
            subscriber.error(error);
          })
          .finally(() => {
            subscriber.complete();
          });
      }, 100);
    });
  };

  private executeWorker = (
    columns: ColumnConfig[],
    inputData: InputData,
    license: License
  ) => {
    if (!this.worker) {
      throw new Error('worker not setup');
    }

    return this.worker.executeWorker(columns, inputData, license);
  };
}

export default BrowserProcessingEngine;
