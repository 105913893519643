import { css, cx } from 'core/emotion';
import { ReactNode } from 'react';
import { Popper } from '../../Dropdown/popper';
import { useTheme } from '../../../../../../theme';

type ContainerProps = {
  children: ReactNode;
  popper: Popper;
};

const style = css`
  box-shadow: 0px 7px 15px rgba(27, 80, 113, 0.1);
  &[data-popper-reference-hidden='true'] {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
`;

const hideStyle = css`
  visibility: hidden !important;
  opacity: 0;
  pointer-events: none;
`;

const Container = ({ children, popper }: ContainerProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        ...popper.styles.popper,
      }}
      ref={popper.setPopperElement}
      className={cx(
        'rounded-medium z-30 text-left ',
        css({
          backgroundColor: theme.getGlobalTheme().getDark50Color(),
        }),
        style,
        popper.isReferenceHide ? hideStyle : ''
      )}
      {...popper.attributes.popper}
    >
      {children}
    </div>
  );
};

export default Container;
