/* istanbul ignore file */
import {
  ICleaningAssistantContext,
  ICleaningAssistantProps,
} from './index.types';
import { useCallback, useMemo, useRef, useState } from 'react';
import DataCleaningAIRepository from './api/CleaningAssistant.repository';
import DataCleaningAIServices from './api/CleaningAssistant.services';
import {
  CleaningLogsRecord,
  CleaningStatus,
  ICleaningAssistantGroup,
  ICleaningAssistantPayload,
  ICleaningAssistantRequestDTO,
  ICleaningAssistantResponse,
  ICleaningAssistantSuggestion,
} from './api/CleaningAssistant.dto';
import { useTheme } from '../../../../../theme';
import { CUSTOM_SOURCE_CHANGE } from '../../type';
import { Error as ValidatorError } from '../../../../reviewEntries/validator';
import CategoryDataModel from '../../../../dataModel/model/CategoryDataModel';
import ValidateMessageUtil from '../../../../reviewEntries/validator/ValidateMessageUtil';
import { useTranslation } from 'react-i18next';

export const useViewModel = (
  props: ICleaningAssistantProps
): ICleaningAssistantContext => {
  const { t } = useTranslation();
  const theme = useTheme();
  const initialLoaded = useRef<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<ICleaningAssistantGroup[]>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(-1);
  const [selectedSuggestions, setSelectedSuggestions] = useState<
    ICleaningAssistantSuggestion[]
  >([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const cleaningAssistantLogsRef = props.cleaningAssistantLogsRef;
  const errorMessageMapRef = useRef<Record<string, string>>({});

  const preparePayload = useCallback(():
    | ICleaningAssistantRequestDTO
    | undefined => {
    const payload: ICleaningAssistantRequestDTO = {
      input_data: '',
      data_model: '',
    };

    const dataModels = props.dataModelRegistry.getDataModels();
    const errors: (ValidatorError | null)[][] = props.validator.getError();
    const input_data: ICleaningAssistantPayload = [];
    const hotInstance = props.hotInstance.current?.hotInstance;

    if (!hotInstance) {
      return payload;
    }

    for (let row = 0; row < errors.length; row++) {
      const isEmptyErrorRow =
        errors[row] !== undefined && errors[row].every((c) => c === null);

      if (errors[row] === undefined || isEmptyErrorRow) {
        continue;
      }

      const rowValues = hotInstance?.getSourceDataAtRow(row) ?? {};
      input_data.push({
        rowIndex: row,
        data: {},
      });

      const rowData = input_data[input_data.length - 1];

      for (const dm of dataModels) {
        const key = dm.getKey();
        rowData.data[key] = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: rowValues[key],
        };
      }

      for (const colError of errors[row]) {
        if (!colError || colError.colIndex < 0) {
          continue;
        }

        const col = colError.colIndex;
        const key = dataModels[col].getKey();

        const message = ValidateMessageUtil.getValidateMessage(
          t,
          colError,
          props.dataModelRegistry.getColumns(),
          props.baseColumns
        );

        const id = `${row}_${col}`;
        errorMessageMapRef.current[id] = message;

        rowData.data[key].info = [
          {
            message,
            level: 'error',
          },
        ];
      }
    }

    if (input_data.length === 0) {
      return undefined;
    }

    const data_model = dataModels.map((dm) => {
      return {
        columnType: dm.getType(),
        key: dm.getKey(),
        validations: dm.getValidators().map((v) => v.serialize()),
        label: dm.getLabel(),
        description: dm.getDescription(),
        dropdownOptions: dm.isCategoryType()
          ? (dm as CategoryDataModel).getOptions().map((o) => {
              return {
                label: o.label,
                value: o.value,
                type: o.type,
              };
            })
          : [],
        outputFormat: dm.getOutputFormat(),
      };
    });

    payload.input_data = JSON.stringify(input_data);
    payload.data_model = JSON.stringify(data_model);

    return payload;
  }, [props, t]);

  const onSelectAll = () => {
    setSelectedSuggestions(
      (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
        return selectedSuggestions.length === 0
          ? groups[selectedGroupIndex]?.suggestions || []
          : [];
      }
    );
  };

  const onSelectSuggestion = (item: ICleaningAssistantSuggestion) => {
    setSelectedSuggestions(
      (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
        const nextSelectedSuggestions: ICleaningAssistantSuggestion[] = [
          ...selectedSuggestions,
        ];
        const index = selectedSuggestions.findIndex(
          (suggestion: ICleaningAssistantSuggestion) =>
            item.suggestion === suggestion.suggestion
        );

        if (index >= 0) {
          nextSelectedSuggestions.splice(index, 1);
        } else {
          nextSelectedSuggestions.push(item);
        }

        return nextSelectedSuggestions;
      }
    );
  };

  const count: number = useMemo(() => {
    return groups.reduce(
      (a: number, g: ICleaningAssistantGroup) => (a += g.suggestions.length),
      0
    );
  }, [groups]);

  const onOpenPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const onDismissPopover = useCallback(() => {
    setOpen(false);
  }, []);

  const dataCleaningAiRepository = useMemo(() => {
    return new DataCleaningAIRepository(new DataCleaningAIServices());
  }, []);

  const getSuggestions = useCallback((): Promise<void> => {
    const payload: ICleaningAssistantRequestDTO | undefined = preparePayload();

    setShowErrorMessage(false);

    if (!payload) {
      return Promise.resolve();
    }

    setLoading(true);
    setGroups([]);
    setSelectedGroupIndex(-1);
    setSelectedSuggestions([]);

    return dataCleaningAiRepository
      .getSuggestions(props.licenseKey, props.origin, payload)
      .then((response: ICleaningAssistantResponse) => {
        const dataModels = props.dataModelRegistry.getDataModels();
        const groups = dataCleaningAiRepository.groupSuggestions(
          response,
          dataModels,
          cleaningAssistantLogsRef.current || [],
          errorMessageMapRef.current
        );
        setGroups(groups);
      })
      .catch(() => {
        setShowErrorMessage(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    cleaningAssistantLogsRef,
    dataCleaningAiRepository,
    preparePayload,
    props.dataModelRegistry,
    props.licenseKey,
    props.origin,
  ]);

  const onBackClick = () => {
    setSelectedGroupIndex(-1);
    setSelectedSuggestions([]);
  };

  const onFind = useCallback(
    (suggestion: ICleaningAssistantSuggestion) => {
      const hotInstance = props.hotInstance.current?.hotInstance;

      if (!hotInstance) {
        return;
      }

      const row = hotInstance.toVisualRow(suggestion.rowIndex);
      const col = hotInstance.toVisualColumn(suggestion.colIndex);

      hotInstance?.selectCell(row, col, undefined, undefined, false, true);
      hotInstance?.scrollViewportTo({
        row,
        col,
        horizontalSnap: 'start',
        verticalSnap: 'top',
      });
    },
    [props.hotInstance]
  );

  const onDismiss = useCallback(
    (suggestions: ICleaningAssistantSuggestion[]) => {
      setGroups((groups: ICleaningAssistantGroup[]) => {
        const deleteMap: Record<string, boolean> = {};

        for (let i = 0; i < suggestions.length; i++) {
          if (deleteMap[suggestions[i].id] === undefined) {
            deleteMap[suggestions[i].id] = true;
          }
        }

        setSelectedSuggestions(
          (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
            return selectedSuggestions.filter(
              (s: ICleaningAssistantSuggestion) => !deleteMap[s.id]
            );
          }
        );

        let nextGroups: ICleaningAssistantGroup[] = [...groups];
        const nextSuggestions: ICleaningAssistantSuggestion[] = nextGroups[
          selectedGroupIndex
        ].suggestions.filter(
          (s: ICleaningAssistantSuggestion) => !deleteMap[s.id]
        );

        nextGroups[selectedGroupIndex] = {
          ...nextGroups[selectedGroupIndex],
          suggestions: nextSuggestions,
        };

        if (nextSuggestions.length === 0) {
          nextGroups = nextGroups.filter(
            (_: ICleaningAssistantGroup, i: number): boolean =>
              i !== selectedGroupIndex
          );
        }

        if (nextSuggestions.length === 0) {
          setSelectedGroupIndex(-1);
        }

        return nextGroups;
      });
    },
    [selectedGroupIndex]
  );

  const onApply = useCallback(
    (suggestions: ICleaningAssistantSuggestion[]) => {
      for (const suggestion of suggestions) {
        const hotInstance = props.hotInstance.current?.hotInstance;

        if (!hotInstance) {
          return;
        }

        const row = hotInstance.toVisualRow(suggestion.rowIndex);
        const col = hotInstance.toVisualColumn(suggestion.colIndex);

        if (row < 0 || col < 0) {
          continue;
        }

        hotInstance?.setDataAtCell(
          row,
          col,
          suggestion.suggestion,
          CUSTOM_SOURCE_CHANGE.CLEANING_ASSISTANT
        );

        if (cleaningAssistantLogsRef.current) {
          const index = cleaningAssistantLogsRef.current.findIndex(
            (l: CleaningLogsRecord) => l.id === suggestion.id
          );
          if (index >= 0) {
            cleaningAssistantLogsRef.current[index].status =
              CleaningStatus.Applied;
          }
        }
      }
      onDismiss(suggestions);
    },
    [cleaningAssistantLogsRef, onDismiss, props.hotInstance]
  );

  return {
    ...props,
    open,
    setOpen,
    loading,
    setLoading,
    onOpenPopover,
    onDismissPopover,
    getSuggestions,
    groups,
    setGroups,
    selectedGroupIndex,
    setSelectedGroupIndex,
    initialLoaded,
    count,
    selectedSuggestions,
    setSelectedSuggestions,
    onSelectAll,
    onSelectSuggestion,
    onFind,
    onDismiss,
    onApply,
    onBackClick,
    theme,
    showErrorMessage,
    setShowErrorMessage,
  };
};
