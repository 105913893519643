import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ToggleButton from './ToggleButton';
import Card from './Card';
import { useTheme } from 'theme';

const CollapseCardContext = createContext<{
  isCollapse: boolean;
  toggleCollapse: () => void;
  toggleCloseCollapse: () => void;
  toggleOpenCollapse: () => void;
}>({
  isCollapse: false,
  toggleCollapse: () => {},
  toggleCloseCollapse: () => {},
  toggleOpenCollapse: () => {},
});

export const useCollapseCard = () => {
  return useContext(CollapseCardContext);
};

const CollapseCard = ({
  initialIsCollapse,
  children,
  hideToggleButton,
}: {
  initialIsCollapse: boolean;
  children: ReactNode;
  hideToggleButton: boolean;
}) => {
  const [isCollapse, setIsCollapse] = useState(initialIsCollapse);
  const theme = useTheme();

  const toggleCollapse = useCallback(() => {
    setIsCollapse((isCollapse) => !isCollapse);
  }, []);

  const toggleOpenCollapse = useCallback(() => {
    setIsCollapse(false);
  }, []);

  const toggleCloseCollapse = useCallback(() => {
    setIsCollapse(true);
  }, []);

  useEffect(() => {
    if (hideToggleButton) {
      setIsCollapse(true);
    }
  }, [hideToggleButton]);

  return (
    <CollapseCardContext.Provider
      value={{
        isCollapse,
        toggleCollapse,
        toggleCloseCollapse,
        toggleOpenCollapse,
      }}
    >
      <Card configTheme={theme.getColumnMatchTheme().root}>
        {!hideToggleButton ? (
          <ToggleButton
            onClick={toggleCollapse}
            isCollapse={isCollapse}
            disabled={hideToggleButton}
            configTheme={theme.getColumnMatchTheme().icon}
          />
        ) : null}
        {children}
      </Card>
    </CollapseCardContext.Provider>
  );
};

export default CollapseCard;
