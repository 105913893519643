import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import Message from '../../../../../components/Popover/Message';
import Popover from '../../../../../components/Popover';
import Button from '../../../../../components/Button';
import { isMobile, isTablet } from 'react-device-detect';
import { EVENT_LOG } from '../../../../constants/eventLog';
import { CSSObject, css, cx } from '../../../../emotion';
import { useTheme } from '../../../../../theme/theme';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icon/download.svg';

type IExportButtonProps = {
  disableExportHasMoreData: boolean;
  exportValuesToXlsx: () => void;
  sendLog: (event: string) => Promise<void>;
  exporting: boolean;
  isExportDisabled: boolean;
  mediaSize: boolean;
};

const ExportButton = ({
  disableExportHasMoreData,
  exportValuesToXlsx,
  sendLog,
  exporting,
  isExportDisabled,
  mediaSize,
}: IExportButtonProps) => {
  const { t } = useTranslation();
  const configTheme = useTheme();
  const [isShowPopover, setIsShowPopover] = useState(false);
  const disabledClassName = useMemo(
    () =>
      cx(
        `cursor-not-allowed bg-opacity-75`,
        css`
          background-color: ${(
            configTheme.getButtonTheme().secondary as CSSObject
          )?.backgroundColor ?? '#DFE2E4'} !important;
          color: #88959c;
          border-color: #dfe2e4;
        `
      ),
    [configTheme]
  );

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowPopover(true),
        onMouseLeave: () => setIsShowPopover(false),
      };
    } else {
      return {
        onClick: () => setIsShowPopover((isShow) => !isShow),
        onMouseLeave: () => setIsShowPopover(false),
      };
    }
  };

  return (
    <Popover
      isShow={isShowPopover && (disableExportHasMoreData || mediaSize)}
      message={
        <Message className="max-w-355 h-full w-full" variant="info">
          {disableExportHasMoreData
            ? t('txt_not_export_file')
            : t('txt_export_as_excel')}
        </Message>
      }
      flip
      direction="left"
      offset={[0, 8]}
      render={({ referenceElement }) => {
        return (
          <span ref={referenceElement}>
            <Button
              {...getOnHover()}
              variant="secondary"
              onClick={() => {
                if (!disableExportHasMoreData) {
                  exportValuesToXlsx();
                  sendLog(EVENT_LOG.EXPORT_EXCEL_FILE)
                    .then(() => {})
                    .catch((err) => {
                      console.error('nuvo send event-log error: ', err);
                    });
                }
              }}
              disabled={
                !disableExportHasMoreData && (exporting || isExportDisabled)
              }
              hideDisableStyle={disableExportHasMoreData}
              loading={exporting}
              className={cx(
                disableExportHasMoreData && disabledClassName,
                css({
                  '&&': {
                    paddingLeft: mediaSize ? 11 : 16,
                    paddingRight: mediaSize ? 11 : 16,
                    height: 40,
                  },
                }),
                css({
                  '@media (min-width: 901px) and (max-width: 916px)': {
                    padding: '8px 16px!important',
                    marginTop: '0px !important',
                  },
                })
              )}
            >
              <div
                className={cx(
                  'flex items-center',
                  disableExportHasMoreData || exporting ? 'text-gray-260' : ''
                )}
              >
                <DownloadIcon
                  className={cx('mb-px', mediaSize ? '' : 'mr-2')}
                />
                {mediaSize ? '' : t('txt_export_as_excel')}
              </div>
            </Button>
          </span>
        );
      }}
    />
  );
};

export default ExportButton;
