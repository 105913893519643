import CheckboxView from './CheckboxView';
import Handsontable from 'handsontable';
import CheckState, { CheckAllState } from './CheckState';
import ModeViewTable from '../ModeViewTable/ModeViewTable';
import AllColumnSetting from '../columns/AllColumnSetting';

class CheckboxController {
  private checkState: CheckState;
  private view: CheckboxView;
  private hotInstance: Handsontable;
  private currentCheckAllState: CheckAllState;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private dataSet: Record<string, any>[];

  constructor({
    hotInstance,
    modeViewTable,
    allColumnSetting,
    dataSet,
  }: {
    hotInstance: Handsontable;
    modeViewTable: ModeViewTable;
    allColumnSetting: AllColumnSetting;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    dataSet: Record<string, any>[];
  }) {
    this.checkState = new CheckState(
      {},
      hotInstance,
      modeViewTable,
      allColumnSetting,
      dataSet
    );
    this.view = new CheckboxView(this.checkState);
    this.hotInstance = hotInstance;
    this.currentCheckAllState = CheckAllState.NO_CHECK;
    this.dataSet = dataSet;
  }

  private setCheckAllOnlyErrorOrFilter = (
    rowIndexes: number[],
    isChecked: boolean
  ) => {
    for (let i = 0; i < rowIndexes.length; ++i) {
      const rowIndex = rowIndexes[i];
      this.checkState.setChecked(rowIndex, isChecked, false);
    }
    this.checkState.checkBoxObservable().next(this.checkState);
    this.hotInstance.render();
  };

  subscribeToUpdateUICheckAll = () => {
    return this.checkBoxObservable().subscribe(() => {
      const updatedCheckAllState = this.checkState.getCheckAllState();
      if (updatedCheckAllState !== this.currentCheckAllState) {
        this.hotInstance.render();
      }
      this.currentCheckAllState = updatedCheckAllState;
    });
  };

  getView() {
    return this.view;
  }

  getCheckedCount = () => {
    return this.checkState.getCheckedCount();
  };

  checkBoxObservable() {
    return this.checkState.checkBoxObservable();
  }

  registerCheckboxPerRowEvent = (
    row: number,
    th: HTMLTableHeaderCellElement
  ) => {
    const checkboxElem = th.querySelector('input');
    const container = th;

    if (!checkboxElem) {
      return;
    }

    const isChecked = this.checkState.isChecked(row);
    checkboxElem.checked = isChecked;

    const handleMouseOver = () => {
      if (!checkboxElem.checked) {
        container.classList.add(CheckboxView.hoverRowClassName);
      }
    };

    const handleMouseLeave = () => {
      container.classList.remove(CheckboxView.hoverRowClassName);
    };

    const handleChecked = () => {
      if (checkboxElem.checked) {
        container.classList.add(CheckboxView.checkedRowClassName);
        this.checkState.setChecked(row, true, true);
      } else {
        container.classList.remove(CheckboxView.checkedRowClassName);
        this.checkState.setChecked(row, false, true);
      }
    };

    container.addEventListener('mouseover', handleMouseOver, false);
    container.addEventListener('mouseleave', handleMouseLeave, false);

    if (isChecked) {
      container.classList.add(CheckboxView.checkedRowClassName);
    } else {
      container.classList.remove(CheckboxView.checkedRowClassName);
    }

    checkboxElem.addEventListener(
      'change',
      (event) => {
        event.stopPropagation();
        handleChecked();
      },
      false
    );

    checkboxElem.addEventListener(
      'mousedown',
      (event) => {
        event.stopPropagation();
      },
      false
    );
  };

  registerCheckboxAllEvent = (
    TH: HTMLTableHeaderCellElement,
    getCheckedRows: () => number[] | null
  ) => {
    const checkAllState = this.checkState.getCheckAllState();
    const checkboxElem = TH.querySelector('input');

    if (checkboxElem && checkAllState === CheckAllState.NO_CHECK) {
      TH.addEventListener(
        'mouseover',
        () => {
          checkboxElem.style.opacity = '1';
        },
        false
      );
      TH.addEventListener(
        'mouseout',
        () => {
          checkboxElem.style.opacity = '0';
        },
        false
      );
    }

    checkboxElem?.addEventListener(
      'click',
      () => {
        const checkedRows = getCheckedRows();
        if (checkboxElem.checked) {
          if (!checkedRows) {
            this.setCheckAll(true);
          } else {
            this.setCheckAllOnlyErrorOrFilter(checkedRows, true);
          }
        } else {
          if (!checkedRows) {
            this.setCheckAll(false);
          } else {
            this.setCheckAllOnlyErrorOrFilter(checkedRows, false);
          }
        }
        this.hotInstance.render();
      },
      false
    );
  };

  getCheckedState = () => {
    return this.checkState;
  };

  setCheckAll = (isChecked: boolean) => {
    this.checkState.setCheckAll(isChecked);
  };

  clearCheckedMaps = () => {
    this.checkState.clearCache();
    this.checkState.clearCheckedMaps();
  };

  clearCache = () => {
    this.checkState.clearCache();
  };
}

export default CheckboxController;
