import { useMemo, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { ReactComponent as Undo } from '../../assets/icon/undo.svg';
import { ReactComponent as Redo } from '../../assets/icon/redo.svg';
import { cx } from '../../core/emotion';
import Popover from '../Popover';
import Message from '../Popover/Message';

type IIconButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  icon: IconButtonType;
  className?: string;
  showInfo?: boolean;
  infoMessage?: string;
};

export enum IconButtonType {
  UNDO = 'UNDO',
  REDO = 'REDO',
}

const IconButton = ({
  icon,
  onClick,
  disabled,
  className,
  showInfo,
  infoMessage,
}: IIconButtonProps) => {
  const shownIcon = useMemo(() => {
    switch (icon) {
      case IconButtonType.UNDO:
        return <Undo />;
      case IconButtonType.REDO:
        return <Redo />;
    }
  }, [icon]);

  const [isShowInfo, setIsShowInfo] = useState(false);

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowInfo(true),
        onMouseLeave: () => setIsShowInfo(false),
      };
    } else {
      return {
        onClick: () => setIsShowInfo((isShow) => !isShow),
        onMouseLeave: () => setIsShowInfo(false),
      };
    }
  };

  return (
    <Popover
      isShow={isShowInfo && (showInfo || false)}
      message={
        <Message className="max-w-355 h-full w-full" variant="info">
          {infoMessage}
        </Message>
      }
      flip
      direction="top"
      offset={[-2, 4]}
      render={({ referenceElement }) => {
        return (
          <span ref={referenceElement} {...getOnHover()}>
            <button
              onClick={() => {
                if (!disabled) {
                  onClick?.();
                }
              }}
              disabled={disabled}
              className={cx(
                'text-blue-dark-900 disabled:text-gray-260 rounded-medium hover:bg-blue-dark-50 p-3 disabled:cursor-not-allowed disabled:bg-transparent',
                className
              )}
            >
              {shownIcon}
            </button>
          </span>
        );
      }}
    />
  );
};

export default IconButton;
