export const findItemByCondition = <T>(
  array: T[],
  condition: (item: T) => boolean
): T | null => {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (condition(element)) {
      return element;
    }
  }
  return null;
};
