import { cx } from 'core/emotion';
import { Sheet } from '@nuvo-importer/common/sdk';
import HotTable from '@handsontable/react';
import { isFirefox } from 'react-device-detect';
import useViewModel from './viewModel';
import JoinPopover from './JoinPopover';
import { ProgressProcessing, css, useTheme } from '@nuvo-importer/common';
import { useTranslation } from 'react-i18next';
import TruncatePopover from './TruncatePopover';
import WrapperStyled from './WrapperStyled';

const JoinSheetPreviewTableInner = ({
  sheet,
  loading,
}: {
  sheet: { state: Sheet | null };
  loading: boolean;
}) => {
  const {
    instance,
    settingTable,
    styleTable,
    colHeaderController,
    sheetColumns,
  } = useViewModel({
    sheet,
  });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <WrapperStyled
        theme={theme.getJoinSheetTheme().table}
        className="border-gray-250 border-1 rounded-medium relative flex flex-grow overflow-hidden bg-gray-50"
      >
        <div
          className={cx(
            'join-sheet-preview flex-grow',
            !isFirefox && 'not_firefox',
            css({ '&&': theme.getJoinSheetTheme().table?.root })
          )}
          style={styleTable}
        >
          <HotTable id="hotInstance" ref={instance} settings={settingTable} />
          {loading ? (
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-50">
              <ProgressProcessing timer={1} text={t('txt_loading')} />
            </div>
          ) : null}
        </div>
      </WrapperStyled>
      <JoinPopover
        colHeaderController={colHeaderController}
        sheetColumns={sheetColumns}
      />
      <TruncatePopover
        colHeaderController={colHeaderController}
        sheetColumns={sheetColumns}
      />
    </>
  );
};

const JoinSheetPreviewTable = ({
  sheet,
  loading,
}: {
  sheet: { state: Sheet | null };
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!sheet.state) {
    return (
      <div
        className={cx(
          'border-gray-120 relative flex flex-shrink flex-grow items-center justify-center rounded-lg border bg-gray-50',
          css({
            '&&': theme.getJoinSheetTheme().table?.empty?.root,
          })
        )}
      >
        {loading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <ProgressProcessing timer={1} text={t('txt_loading')} />
          </div>
        ) : (
          <div
            className={cx(
              'flex flex-col items-center',
              css({
                '&&': theme.getJoinSheetTheme().table?.empty?.content,
              })
            )}
          >
            <span
              className={cx(
                'text-neutral-gray mb-2 text-sm font-semibold',
                css({
                  '&&': theme.getJoinSheetTheme().table?.empty?.title,
                })
              )}
            >
              {t('txt_no_joined_dataset')}
            </span>
            <span
              className={cx(
                'text-gray-410 text-xs',
                css({
                  '&&': theme.getJoinSheetTheme().table?.empty?.description,
                })
              )}
            >
              {t('txt_no_joined_dataset_desc')}
            </span>
          </div>
        )}
      </div>
    );
  }

  return <JoinSheetPreviewTableInner sheet={sheet} loading={loading} />;
};

export default JoinSheetPreviewTable;
