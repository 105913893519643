import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/icon/arrow-up.svg';

const ToggleButton = ({
  onClick,
  disabled,
  isCollapse,
  configTheme,
}: {
  onClick: () => void;
  disabled: boolean;
  isCollapse: boolean;
  configTheme?: CSSInterpolation;
}) => {
  return (
    <button
      type="button"
      className={cx(
        'smmd:top-7 smmd:right-5 mmd:top-7 mmd:right-7 absolute top-7 right-3 -mt-px -ml-px',
        css({
          '&&': {
            color: (configTheme as CSSObject)?.color ?? '#383838',
          },
        })
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowUpIcon className={isCollapse ? 'rotate-180 transform' : ''} />
    </button>
  );
};

export default ToggleButton;
