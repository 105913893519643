import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  FilterValueItems,
  FilterValuesSelected,
} from '../../../columns/FilterStrategy';
import { ColumnSetting } from '../../../columns/AllColumnSetting';
import ColumnSettingHelper from '../../../columns/ColumnSettingHelper';

const useFilterByValue = ({
  valueItems,
  columnSetting,
}: {
  valueItems: FilterValueItems;
  columnSetting: ColumnSetting;
}) => {
  const [valuesSelected, setValuesSelected] = useState<FilterValuesSelected>(
    {}
  );
  const [searchValue, setSearchValue] = useState('');
  const [debounceSearch, setDebounceSearch] = useState('');

  useDebounce(
    () => {
      setDebounceSearch(searchValue);
    },
    500,
    [searchValue]
  );

  const showValueItems = useMemo(() => {
    const value = debounceSearch.trim();
    if (value === '') {
      return valueItems;
    }
    return valueItems.filter((valueItem) => {
      return `${valueItem.label}`.includes(debounceSearch);
    });
  }, [valueItems, debounceSearch]);

  const onValuesSelected = (valuesSelected: FilterValuesSelected) => {
    setValuesSelected(valuesSelected);
  };

  useEffect(() => {
    setValuesSelected(
      ColumnSettingHelper.getFilterByValueSelectedForPrefill(
        columnSetting,
        valueItems
      )
    );
  }, [columnSetting, valueItems]);

  const checkedCount = useMemo(() => {
    if (valueItems.length === 0) {
      return 0;
    }

    let checkedCount = 0;
    for (let i = 0; i < valueItems.length; i++) {
      const item = valueItems[i];
      const checked = valuesSelected[item.value];
      if (checked) {
        checkedCount++;
      }
    }
    return checkedCount;
  }, [valuesSelected, valueItems]);

  return {
    valuesSelected,
    onValuesSelected,
    setValuesSelected,
    searchValue,
    setSearchValue,
    showValueItems,
    checkedCount,
  };
};

export default useFilterByValue;
