import Icon, { ContextMenuIcon } from './Icon';
import { ReactComponent as ArrowUp } from '../../../../../assets/icon/arrow-up.svg';
import { css, cx } from '../../../../emotion';
import Popover from './../../../../../components/Popover';
import { CSSProperties, RefObject, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { ConfigTheme } from '../../type';

export enum Arrow {
  UP = 'UP',
  DOWN = 'DOWN',
}

type ContextMenuItemProps = {
  icon: ContextMenuIcon;
  text: string;
  arrowIcon?: Arrow;
  disabled?: boolean;
  onClick?: () => void;
  showInfo?: boolean;
  infoMessage?: string;
  configTheme?: ConfigTheme;
  labelClassName?: string;
  variant?: 'danger' | 'base';
};

const ContextMenuItem = ({
  text,
  icon,
  arrowIcon,
  disabled,
  onClick,
  showInfo,
  infoMessage,
  configTheme,
  variant = 'base',
}: ContextMenuItemProps) => {
  const [isShowInfo, setIsShowInfo] = useState(false);

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowInfo(true),
        onMouseLeave: () => setIsShowInfo(false),
      };
    } else {
      return {
        onClick: () => setIsShowInfo((isShow) => !isShow),
        onMouseLeave: () => setIsShowInfo(false),
      };
    }
  };

  return (
    <Popover
      isShowArrow
      arrowClassName={
        (
          configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.item
            ?.popover as CSSProperties
        )?.['backgroundColor'] ?? '#67757C'
      }
      isShow={isShowInfo && (showInfo || false)}
      direction="bottom"
      offset={[0, 0]}
      message={
        <div
          className={cx(
            'text-small bg-info-gray rounded-medium py-sm max-w-372 -mt-px px-4 text-white',
            css({
              '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                ?.item?.popover,
            })
          )}
        >
          {infoMessage}
        </div>
      }
      render={({ referenceElement }) => {
        return (
          <div
            ref={referenceElement as RefObject<HTMLDivElement>}
            {...getOnHover()}
            className={cx(
              'flex items-center px-4 py-2 hover:bg-gray-50',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              css({
                '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                  ?.item?.root,
              })
            )}
            onClick={onClick}
          >
            <div
              className={cx(
                disabled ? 'opacity-40' : 'opacity-100',
                variant === 'danger' ? 'text-red-510' : 'text-blue-dark-900'
              )}
            >
              <Icon
                icon={icon}
                className={css`
                  ${configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                    ?.item?.icon}
                `}
              />
            </div>
            <p
              className={cx(
                `ml-4 text-xs ${disabled ? 'opacity-40' : 'opacity-100'}`,
                variant === 'danger' ? 'text-red-510' : 'text-blue-dark-900',
                css({
                  '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                    ?.item?.label,
                })
              )}
            >
              {text}
            </p>
            {arrowIcon ? (
              <span
                className={cx(
                  'ml-auto',
                  arrowIcon === Arrow.DOWN ? 'rotate-180 transform' : '',
                  'text-blue-dark-900'
                )}
              >
                <ArrowUp
                  className={css({
                    '&&': configTheme?.reviewEntriesTheme?.smartTable
                      ?.contextMenu?.item?.icon,
                  })}
                />
              </span>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default ContextMenuItem;
