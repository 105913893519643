import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { ComponentPropsWithoutRef } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icon/close-gray.svg';

const BadgeMatchItemWrapper = ({
  children,
  className,
  onDeleteMatchColumn,
  configTheme,
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  onDeleteMatchColumn?: () => void;
  configTheme?: { root?: CSSInterpolation; icon?: CSSInterpolation };
}) => {
  return (
    <div
      className={cx(
        'badgeMatchItemWrapper',
        'text-xss text-gray-560 rounded-2lg ml-3 flex w-max items-center justify-between bg-gray-50 px-2 py-1 leading-3',
        className,
        css({ '&&': configTheme?.root })
      )}
      {...props}
    >
      {children}
      <div
        className={cx(
          'text-gray-560',
          css`
            svg {
              width: 10px;
              height: 10px;
            }
          `,
          css({
            '&&': {
              color: (configTheme?.icon as CSSObject)?.color,
            },
          })
        )}
      >
        <CloseIcon
          className="ml-2 cursor-pointer"
          onClick={() => onDeleteMatchColumn && onDeleteMatchColumn()}
        />
      </div>
    </div>
  );
};

export default BadgeMatchItemWrapper;
