/* istanbul ignore file */

import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { MAX_SELECTED_SHEET } from 'core/constants/sheet';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';

export const useDataModelSheetFormConfig = () => {
  const settings = useSettings();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();

  const { maxEntries, enableExamples } = settings;

  const warnExceedMaxRecords = useCallback(() => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_title_sheet_max_entries', {
        maxRecords: maxEntries,
      }),
      description: t('txt_desc_sheet_max_entries', {
        maxEntries: maxEntries,
      }),
      textNegativeButton: t('txt_submit_understand'),
      isShowPositiveButton: false,
      onClickNegativeButton: () => {},
    });
  }, [showConfirmModal, maxEntries, t]);

  const warnSheetNeedToHasData = useCallback(() => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_no_data_in_sheet_title'),
      description: t('txt_no_data_in_sheet_description'),
      textNegativeButton: t('txt_submit_understand'),
      isShowPositiveButton: false,
      onClickNegativeButton: () => {},
    });
  }, [showConfirmModal, t]);

  const warnMaxSelectedSheet = useCallback(() => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_maximum_selected_sheet', { size: MAX_SELECTED_SHEET }),
      description: t('txt_maximum_selected_sheet_desc', {
        size: MAX_SELECTED_SHEET,
      }),
      textNegativeButton: t('txt_close'),
      isShowPositiveButton: false,
      onClickNegativeButton: () => {},
    });
  }, [showConfirmModal, t]);

  return {
    enableExamples: enableExamples ?? false,
    maxRecords: maxEntries,
    warnExceedMaxRecords,
    warnSheetNeedToHasData,
    warnMaxSelectedSheet,
  };
};
