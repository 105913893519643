import AllColumnSetting from '../../columns/AllColumnSetting';
import ContextMenuController from '../controller/ContextMenuController';

const useViewModel = ({
  allColumnSetting,
  contextMenuController,
  hide,
  currentColumnIndex,
}: {
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  hide: boolean;
  currentColumnIndex: number;
}) => {
  const onMenuClick = () => {
    if (allColumnSetting.hasMaximumHideColumn()) {
      return;
    }
    allColumnSetting.setHideToColumnSetting(currentColumnIndex, !hide);
    contextMenuController.closeMenu();
  };

  return { onMenuClick };
};

export default useViewModel;
