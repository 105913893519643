import React, { useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../../../../components/Button';

const LoadingState = () => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);

  return (
    <div className={s.rootClass}>
      <div className={s.spinnerClass}>
        <Loader className={s.spinnerLoaderClass} />
      </div>
      <div className={s.titleClass}>{t('txt_cleaning_data')}</div>
      <div className={s.textClass}>{t('txt_nuvo_fixing_errors')}</div>
    </div>
  );
};

export default LoadingState;
