import { ReactElementType, VariableSizeList } from 'react-window';
import { Ref, forwardRef } from 'react';
import DropdownOptionItem from './DropdownOptionItem';
import type { OptionItemData } from './DropdownOptionItem';

type DropdownOptionsProps = {
  className?: string;
  outerElementType?: ReactElementType;
  innerElementType?: ReactElementType;
  height: number;
  itemCount: number;
  itemSize: (size: number) => number;
  optionItemData: OptionItemData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: Ref<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outerRef?: Ref<any>;
  isShowSimilarity?: boolean;
};

const DropdownOptions = forwardRef<VariableSizeList, DropdownOptionsProps>(
  (
    {
      className,
      outerElementType,
      innerElementType,
      height,
      itemCount,
      itemSize,
      optionItemData,
      innerRef,
      outerRef,
    },
    ref
  ) => {
    return (
      <VariableSizeList
        className={className}
        outerElementType={outerElementType}
        innerElementType={innerElementType}
        innerRef={innerRef}
        outerRef={outerRef}
        height={height}
        itemCount={itemCount}
        itemSize={itemSize}
        ref={ref}
        width="100%"
        itemData={optionItemData}
      >
        {DropdownOptionItem}
      </VariableSizeList>
    );
  }
);

export default DropdownOptions;
