import {
  footerHeight as defaultFooterHeight,
  footerMatchBadgeHeight,
} from 'core/constants/style';
import { useCollapseContext } from '../DataModelSheetMatcherForm/FormFooter/collapse';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { NUVO_ELEMENT_ID } from 'core/constants/element';

export const DropdownBoundaryContext = createContext(
  {} as {
    boundary?: HTMLElement;
    footerHeight: number;
    pageWrapperElement?: HTMLElement;
  }
);

export const useDropdownBoundaryContext = () => {
  return useContext(DropdownBoundaryContext);
};

const DropdownBoundaryProvider = ({ children }: { children: ReactNode }) => {
  const [boundary, setBoundary] = useState<HTMLElement>();

  const { isCollapse, isShow } = useCollapseContext();
  const [footerHeight, setFooterHeight] = useState(defaultFooterHeight);
  const [pageWrapperElement, setPageWrapperElement] = useState<HTMLElement>();

  useEffect(() => {
    const element = document.getElementById(NUVO_ELEMENT_ID.PAGE_WRAPPER);
    setPageWrapperElement(element as HTMLElement);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const boundary = document.querySelector('.modal-view')!;
      setBoundary(boundary as HTMLElement);
    }, 0);
  }, []);

  useEffect(() => {
    if (isCollapse) {
      if (isShow) {
        const footer = document.querySelector(
          '.match-form-footer'
        ) as HTMLDivElement;
        setFooterHeight(footer?.clientHeight ?? defaultFooterHeight);
      } else {
        setFooterHeight(defaultFooterHeight + footerMatchBadgeHeight);
      }
    } else {
      setFooterHeight(defaultFooterHeight);
    }
  }, [isCollapse, isShow]);

  return (
    <DropdownBoundaryContext.Provider
      value={{ boundary, footerHeight, pageWrapperElement }}
    >
      {children}
    </DropdownBoundaryContext.Provider>
  );
};

export default DropdownBoundaryProvider;
