import { useSettings } from 'settings';

export const useUploadDataFormConfig = () => {
  const settings = useSettings();

  return {
    modal: settings.modal,
    title: settings.title,
    disableTemplates: settings.disableTemplates ?? false,
    embedUploadArea: settings.embedUploadArea ?? false,
    disableExcelTemplate: settings.disableExcelTemplate ?? false,
    automaticMapping: settings.automaticMapping ?? false,
    buttonMode: settings.buttonMode,
  };
};
