import { DataModelSheetMatch } from './../../../../../../../matching/DataModelSheetMatching';
import { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import SheetColumn from './../../../../../../../sheetImporter/SheetColumn';

const useViewModel = ({
  sheetColumn,
  onRemoveMatchColumn,
}: {
  sheetColumn: DataModelSheetMatch;
  onRemoveMatchColumn: (value: SheetColumn) => void;
}) => {
  const [isShowPopover, setShowIsPopover] = useState(false);

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setShowIsPopover(true),
        onMouseLeave: () => setShowIsPopover(false),
      };
    } else {
      return {
        onClick: () => setShowIsPopover((isShow) => !isShow),
        onMouseLeave: () => setShowIsPopover(false),
      };
    }
  };

  const onDeleteMatchColumn = () => {
    onRemoveMatchColumn(sheetColumn.sheetColumn);
  };

  return {
    onDeleteMatchColumn,
    isShowPopover,
    setShowIsPopover,
    getOnHover,
  };
};

export default useViewModel;
