import React, { FC } from 'react';
import { Button, Dialog, DialogFooter, DialogHeader } from '@getnuvo/ui-kit';
import { ConfigureForm } from '../ConfigureForm';
import { FormProvider } from 'react-hook-form';
import { RotateCcw } from 'lucide-react';
import { ConfirmResetDialog } from '../ConfirmResetDialog';
import { useViewModel } from './useViewModel';
import { ConfirmDiscardDialog } from '../ConfirmDiscardDialog';

interface IProps {
  open: boolean;
  onDismiss: () => void;
}

export const ConfigureModal: FC<IProps> = ({ open, onDismiss }: IProps) => {
  const {
    form,
    onOpenResetDialog,
    onSubmit,
    processing,
    resetDialog,
    onDismissResetDialog,
    discardDialog,
    onDismissDiscardDialog,
    handleDismissAndReset,
    handleDismiss,
  } = useViewModel({ onDismiss });

  return (
    <FormProvider {...form}>
      <Dialog open={open} onDismiss={handleDismiss} outsidePress={false}>
        <DialogHeader title="Configure" showFullScreenButton />
        <div className="configure-form__body">
          <ConfigureForm />
        </div>
        <DialogFooter>
          <Button
            startIcon={<RotateCcw />}
            variant="plainPrimary"
            className="configure-form__resetButton"
            onClick={onOpenResetDialog}
          >
            Reset
          </Button>
          <Button variant="outlineSecondary" onClick={handleDismiss}>
            Cancel
          </Button>
          <Button onClick={onSubmit} loading={processing}>
            Apply
          </Button>
        </DialogFooter>

        <ConfirmResetDialog
          open={resetDialog}
          onDismiss={onDismissResetDialog}
        />
      </Dialog>

      <ConfirmDiscardDialog
        open={discardDialog}
        onDismiss={onDismissDiscardDialog}
        onConfirm={handleDismissAndReset}
      />
    </FormProvider>
  );
};
