import { BehaviorSubject } from 'rxjs';

class ContextMenuPopper {
  private _contextMenuPopperObservable: BehaviorSubject<ContextMenuPopper>;
  private referenceElement: HTMLDivElement | null = null;

  constructor() {
    this._contextMenuPopperObservable = new BehaviorSubject<ContextMenuPopper>(
      this
    );
  }

  contextMenuPopperObservable = () => {
    return this._contextMenuPopperObservable;
  };

  setReferenceElement = (element: HTMLDivElement | null) => {
    this.referenceElement = element;
    this.contextMenuPopperObservable().next(this);
  };

  getReferenceElement = () => {
    return this.referenceElement;
  };
}

export default ContextMenuPopper;
