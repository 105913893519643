import { useTranslation } from 'react-i18next';
import ButtonSwitch from '../../../../../components/ButtonSwitch';
import { CSSInterpolation, css, cx } from '../../../../emotion';
import { thousandFormat } from '../../../utils';

export enum VIEW_ACTION {
  SHOW_ALL = 'SHOW_ALL',
  SHOW_ERROR = 'SHOW_ONLY_ERROR',
}

type ToggleViewButtonProps = {
  errorCountNumber: number;
  isShowOnlyError: boolean;
  onToggleViewChange: (action: VIEW_ACTION) => void;
  customTheme?: {
    item?: CSSInterpolation;
    selected?: CSSInterpolation;
    allRows?: CSSInterpolation;
    errorRows?: CSSInterpolation;
    root?: CSSInterpolation;
  };
};

const ToggleViewButton = ({
  errorCountNumber,
  isShowOnlyError,
  onToggleViewChange,
  customTheme,
}: ToggleViewButtonProps) => {
  const { t } = useTranslation();
  return (
    <ButtonSwitch<VIEW_ACTION>
      className={css({ '&&': customTheme?.root })}
      itemClassName={css({ '&&': customTheme?.item })}
      selectedClassName={css({ '&&&': customTheme?.selected })}
      actions={[
        {
          label: t('txt_all_rows'),
          key: VIEW_ACTION.SHOW_ALL,
          activeClassName: 'font-semibold',
          className: css({
            '&&&&': customTheme?.allRows,
          }),
        },
        {
          label:
            errorCountNumber === 1
              ? t('txt_one_error')
              : t('txt_error_amount', {
                  amountOfErrors: `${thousandFormat(errorCountNumber)}`,
                }),
          key: VIEW_ACTION.SHOW_ERROR,
          className: cx(
            'px-3',
            css({
              '&&': {
                color: '#ED7070',
              },
            }),
            css({
              '&&&&': customTheme?.errorRows,
            })
          ),
        },
      ]}
      currentAction={
        isShowOnlyError ? VIEW_ACTION.SHOW_ERROR : VIEW_ACTION.SHOW_ALL
      }
      onActionChange={onToggleViewChange}
    />
  );
};

export default ToggleViewButton;
