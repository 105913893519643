import { Sheet, SheetData } from '@nuvo-importer/common/sdk';
import { flatten } from 'lodash';
import { firstValueFrom, from, switchMap } from 'rxjs';
import UploadChunk from 'core/services/UploadChunk';
import UploadInputSheetAPI from './UploadInputSheetAPI';
import InputSheetClearer from './InputSheetClearer';

class UploadInputSheetRepository {
  private uploadInputSheetAPI: UploadInputSheetAPI;
  private uploadChunk: UploadChunk;
  private lastFailedUploadIndex = -1;
  private fileId = '';
  private inputSheetClearer: InputSheetClearer;

  constructor(
    uploadInputSheetAPI: UploadInputSheetAPI,
    inputSheetClearer: InputSheetClearer
  ) {
    this.uploadInputSheetAPI = uploadInputSheetAPI;
    this.inputSheetClearer = inputSheetClearer;
    this.uploadChunk = new UploadChunk();
  }

  private parseData = (allSheets: Sheet[]) => {
    const values: SheetData = [];

    let maxRow = 0;

    const allColumns = flatten(allSheets.map((sheet) => sheet.getColumns()));

    values[0] = [];

    allColumns.forEach((sheetColumn, columnIndex) => {
      values[0][columnIndex] = sheetColumn.getColumnKey();

      if (sheetColumn.getUniqueRows().length > maxRow) {
        maxRow = sheetColumn.getUniqueRows().length;
      }
    });

    for (let i = 0; i < maxRow; ++i) {
      values[i + 1] = [];
      for (let j = 0; j < allColumns.length; ++j) {
        const col = allColumns[j].getUniqueRows()[i] ?? '';
        values[i + 1][j] = col;
      }
    }

    return values;
  };

  private uploadRemote = (licenseKey: string) => (data: SheetData) => {
    return this.uploadInputSheetAPI.uploadData(this.fileId, data, licenseKey);
  };

  initialize = () => {
    this.lastFailedUploadIndex = -1;
    this.fileId = '';
  };

  upload = (allSheets: Sheet[], licenseKey: string) => {
    const data = this.parseData(allSheets);
    this.uploadChunk.prepareInstance(
      data,
      this.uploadRemote(licenseKey),
      this.lastFailedUploadIndex > -1 ? this.lastFailedUploadIndex : 0,
      (failedIndex: number) => {
        this.lastFailedUploadIndex = failedIndex;
      }
    );

    if (this.lastFailedUploadIndex > -1) {
      return from(this.uploadChunk.upload()).pipe(
        switchMap(() => {
          return this.uploadInputSheetAPI.complete(
            this.fileId,
            this.uploadChunk.getTotalChunk(),
            licenseKey
          );
        })
      );
    } else {
      return this.uploadInputSheetAPI.createFile(licenseKey).pipe(
        switchMap((res) => {
          this.fileId = res.id;
          this.inputSheetClearer.add(this.fileId);
          return from(this.uploadChunk.upload());
        }),
        switchMap(() => {
          return this.uploadInputSheetAPI.complete(
            this.fileId,
            this.uploadChunk.getTotalChunk(),
            licenseKey
          );
        })
      );
    }
  };

  getFileId = () => {
    return this.fileId;
  };

  clearData = (licenseKey: string) => {
    this.initialize();
    this.inputSheetClearer.clear(licenseKey);
  };

  complete = (licenseKey: string) => {
    if (this.fileId) {
      const fileId = this.fileId;
      this.initialize();
      this.inputSheetClearer.remove(fileId);
      firstValueFrom(
        this.uploadInputSheetAPI.clearFile(fileId, true, licenseKey)
      ).catch(() => {});
    }
  };
}

export default UploadInputSheetRepository;
