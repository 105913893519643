import DataModelSheetMatching, {
  DataModelSheetMatch,
  MatchedOption,
} from '../../matching/DataModelSheetMatching';
import DataModelSheetMatcher from '../../matching/DataModelSheetMatcher';
import Sheet, { Value } from '../../sheetImporter/Sheet';
import { CategoryDataModel, DataModel, findDataModel } from '../../dataModel';
import {
  findSheetColumnBySheets,
  isSheetColumnOptionEqual,
} from '../../sheetImporter/utils';
import CalculateSimilarityMapper, {
  CalculateSimilarityResult,
} from '../../matching/CalculateSimilarityMapper';
import { DATATYPE } from '../index';

type MatchedDataModelAutoMatching = {
  dataModelKey?: string;
  matchedOptions?: MatchedOption[];
};

type DataModelSheetMatchAutoMatching = {
  sheetColumnKey: string;
  matchedDataModel?: MatchedDataModelAutoMatching;
};

export type LogMultiSelectionOptionMapping = {
  originalMatchedMl: string;
  individualMatchedMl: Record<string, string>;
};

export type LogAutoOptionMapping = {
  ref: string;
  target: string | string[];
  multiSelection?: LogMultiSelectionOptionMapping;
};

export type DataModelSheetMatchingAutoMatching = {
  dataModelSheetMatch: DataModelSheetMatchAutoMatching[];
  calculateSimilarityResult: CalculateSimilarityResult;
};

export class DataModelSheetMatchingAutoMatchingSerializer {
  private static checkIsDataModelOptionNotCustomCreate = (
    dataModelOption?: string,
    dataModel?: DataModel
  ) => {
    if (dataModel?.getType() === DATATYPE.SINGLE_SELECT) {
      const categoryDataModel = dataModel as CategoryDataModel;
      const options = categoryDataModel.getOptions();

      if (
        options
          .filter((item) => !item.creator)
          .some((option) => option.value === dataModelOption)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  static serializeForAutoMatching = (
    dataModelSheetMatcher: DataModelSheetMatcher,
    dataModelSheetMatching: DataModelSheetMatching
  ): DataModelSheetMatchingAutoMatching => {
    const matching = dataModelSheetMatching.getMatching();
    const calculateSimilarityResult =
      dataModelSheetMatcher.getCalculateSimilarityResult();
    const dataModelSheetMatch = matching.map((item) => {
      return {
        sheetColumnKey: item.sheetColumn.getColumnKey(),
        matchedDataModel:
          item.matchedDataModel &&
          !item.matchedDataModel!.dataModel?.getCreator()
            ? {
                dataModelKey: item.matchedDataModel.dataModel?.getKey(),
                matchedOptions: item.matchedDataModel?.matchedOptions?.map(
                  (option) => {
                    return {
                      dataModelOption:
                        this.checkIsDataModelOptionNotCustomCreate(
                          option.dataModelOption,
                          item.matchedDataModel!.dataModel
                        )
                          ? option.dataModelOption
                          : undefined,
                      dataModelOptions: option.dataModelOptions
                        ? option.dataModelOptions.filter((dataModelOption) => {
                            return this.checkIsDataModelOptionNotCustomCreate(
                              dataModelOption,
                              item.matchedDataModel!.dataModel
                            );
                          })
                        : undefined,

                      sheetOption: option.sheetOption,
                    };
                  }
                ),
              }
            : undefined,
      };
    });

    return {
      dataModelSheetMatch,
      calculateSimilarityResult,
    };
  };

  static deSerializeForAutoMatching = (
    serialize: DataModelSheetMatchingAutoMatching,
    sheets: Sheet[],
    dataModels: DataModel[]
  ): {
    calculateSimilarityResult: CalculateSimilarityResult;
    dataModelSheetMatching: DataModelSheetMatching;
  } => {
    const dataModelSheetMatch: DataModelSheetMatch[] =
      serialize.dataModelSheetMatch.map((item) => {
        const uniqueRows: Value[] = [];

        for (
          let i = 0;
          i < (item.matchedDataModel?.matchedOptions?.length ?? 0);
          ++i
        ) {
          if (item.matchedDataModel?.matchedOptions?.[i]) {
            uniqueRows.push(
              item.matchedDataModel?.matchedOptions?.[i].sheetOption
            );
          }
        }

        const sheetColumn = findSheetColumnBySheets(
          sheets,
          item.sheetColumnKey,
          uniqueRows
        );

        if (!sheetColumn) {
          throw new Error('sheetColumn not found: ' + item.sheetColumnKey);
        }

        let dataModel: DataModel | null = null;

        if (item.matchedDataModel) {
          dataModel = findDataModel(
            dataModels,
            item.matchedDataModel.dataModelKey!,
            true
          );

          if (!dataModel && item.matchedDataModel.dataModelKey) {
            throw new Error(
              'data model not found: ' + item.matchedDataModel.dataModelKey
            );
          }
        }

        if (item.matchedDataModel?.matchedOptions) {
          const matchedOptions = item.matchedDataModel?.matchedOptions;
          const usedMatchedOptions: MatchedOption[] = [];
          const newMatchedOptions: MatchedOption[] = [];

          const uniqueRows = sheetColumn.getUniqueRows();
          uniqueRows.forEach((uniqueRow) => {
            const match = matchedOptions.find((matchedOption) => {
              return isSheetColumnOptionEqual(
                matchedOption.sheetOption,
                uniqueRow
              );
            });
            if (match) {
              newMatchedOptions.push(match);
              usedMatchedOptions.push(match);
            } else {
              newMatchedOptions.push({
                sheetOption: uniqueRow,
              });
            }
          });

          if (usedMatchedOptions.length !== matchedOptions.length) {
            throw new Error(
              'matched option is remove in sheet column:' +
                sheetColumn.getColumnKey()
            );
          }

          item.matchedDataModel.matchedOptions = newMatchedOptions;
        }

        return {
          sheetColumn: sheetColumn!,
          matchedDataModel: item.matchedDataModel
            ? {
                dataModel: dataModel!,
                matchedOptions: item.matchedDataModel.matchedOptions,
              }
            : undefined,
        };
      });

    const calculateSimilarityMapper = new CalculateSimilarityMapper({
      calculateSimilarityResult: serialize.calculateSimilarityResult,
      sheets,
      dataModels,
      options: {
        checkAllSheetColumnOptionInSheetColumns: true,
        isAutoMapping: true,
      },
    });

    const sheetColumnDataModelSimilarityList =
      calculateSimilarityMapper.getSheetColumnDataModelSimilarityList();

    const sheetColumnDataModelOptionSimilarityList =
      calculateSimilarityMapper.getSheetColumnDataModelOptionSimilarityList();

    return {
      calculateSimilarityResult: serialize.calculateSimilarityResult,
      dataModelSheetMatching: new DataModelSheetMatching({
        dataModels,
        dataModelSheetMatch,
        sheetColumnDataModelSimilarityList,
        sheetColumnDataModelOptionSimilarityList,
        sheets,
      }),
    };
  };
}
