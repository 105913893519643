import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Sheet } from '@nuvo-importer/common/sdk';
import Form from './Form';
import useViewModel, { IFormValues } from './viewModel';
import { HandleJoin } from '../../../joinSheet';
import { useTranslation } from 'react-i18next';
import { css, cx, useTheme } from '@nuvo-importer/common';

type IJoinSheetFormProps = {
  joinTargetSheet: Sheet;
  sourceSheet: Sheet;
  handleJoin: HandleJoin;
};

const initialValues = {
  columns: [
    {
      target: null,
      source: null,
    },
  ],
};

const JoinSheetForm = ({
  joinTargetSheet,
  sourceSheet,
  handleJoin,
}: IJoinSheetFormProps) => {
  const { onSubmit, loading, validate, isConfirmOpen } = useViewModel({
    handleJoin,
    joinTargetSheet,
  });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="flex h-full min-h-0 flex-grow flex-col">
      <h2
        className={cx(
          'text-neutral-gray mb-2 text-2xl font-semibold',
          css({ '&&': theme.getJoinSheetTheme().joinColumnsDialog?.title })
        )}
      >
        {t('txt_join_sheet')}
      </h2>
      <p
        className={cx(
          'text-gray-970 mb-6 text-sm',
          css({
            '&&': theme.getJoinSheetTheme().joinColumnsDialog?.description,
          })
        )}
      >
        {t('txt_join_sheet_modal_desc')}
      </p>
      <FinalForm<IFormValues>
        initialValues={initialValues}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mutators={arrayMutators as any}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <Form
              handleSubmit={handleSubmit}
              joinTargetSheet={joinTargetSheet}
              sourceSheet={sourceSheet}
              loading={loading && !isConfirmOpen}
            />
          );
        }}
      />
    </div>
  );
};

export default JoinSheetForm;
