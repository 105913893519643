import { cx } from '../../core/emotion';
import { ReactNode } from 'react';

const Badge = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cx(
        'bg-salmon-100 text-xss text-salmon-500 inline-flex h-5 w-5 items-center justify-center rounded-full',
        className
      )}
    >
      <span>{children}</span>
    </div>
  );
};

export default Badge;
