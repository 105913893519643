/* istanbul ignore file */

import { cx, css } from 'core/emotion';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icon/close.svg';
import { useTheme } from '../../theme/theme';

type CardProps = {
  className?: string;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onClick?: () => void;
  children: ReactNode;
};

export const Card = ({
  className,
  onMouseDown,
  onClick,
  children,
}: CardProps) => {
  const theme = useTheme();
  return (
    <div
      className={cx(
        'my-8 inline-block w-full transform overflow-hidden rounded-lg bg-white p-4 px-4 pb-4 text-center align-middle shadow-xl transition-all sm:align-middle',
        className,
        css({
          '&&': {
            borderRadius: theme.getGlobalTheme().getBorderRadius(),
          },
        })
      )}
      onMouseDown={onMouseDown}
      onClick={(event) => {
        onClick?.();
        event.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};

type CloseButtonProps = ComponentPropsWithoutRef<'button'> & {
  closeIcon?: ReactNode;
};

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ onClick, className, closeIcon }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className={cx('focus:outline-none', className)}
        onClick={onClick}
      >
        {closeIcon ? closeIcon : <CloseIcon />}
      </button>
    );
  }
);
