import { css, cx } from '@nuvo-importer/common';
import { Loader } from 'baseUI/Button';
import { TextLoading } from 'baseUI/TextLoading';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMatched } from '../../../../../../assets/icon/matched.svg';
import { ReactComponent as Alert } from '../../../../../../assets/icon/alert.svg';
import useViewModel from './viewModel';
import { JoinSheetMatchStatus } from '@nuvo-importer/common/sdk';

type MatchStatusProps = {
  percent: number;
  isProcessing: boolean;
  configTheme?: JoinSheetMatchStatus;
};

const MatchStatus = ({
  percent,
  configTheme,
  isProcessing,
}: MatchStatusProps) => {
  const { t } = useTranslation();
  const { styled } = useViewModel({ percent, configTheme });

  return isProcessing ? (
    <div
      className={cx(
        'rounded-medium text-gray-560 flex items-center justify-start space-x-2 px-3 py-2 text-base font-semibold',
        css({ '&&': configTheme?.loading?.root })
      )}
    >
      <div>
        <Loader
          className={cx('top-px', css({ '&&': configTheme?.loading?.icon }))}
        />
      </div>
      <TextLoading>{t('txt_data_processing')}</TextLoading>
    </div>
  ) : (
    <div
      className={cx(
        'rounded-medium flex items-center space-x-2 px-3 py-2',
        css({
          '&&': css`
            background: ${styled.background};
          `,
        }),
        css({ '&&': styled.root })
      )}
    >
      {percent === 100 ? (
        <CheckMatched
          className={cx(
            css({
              '&&': styled.icon,
            })
          )}
        />
      ) : (
        <Alert
          className={cx(
            css({
              '&&': styled.icon,
            })
          )}
        />
      )}
      <div className="flex items-center">
        <div
          className={cx(
            'text-base font-semibold',
            css({ '&&': styled.percent })
          )}
        >
          {t('txt_join_sheets_percent_unique_match', {
            percent,
          })}
        </div>
        <p
          className={cx(
            'text-neutral-gray relative top-px ml-2 text-xs',
            css({ '&&': styled.description })
          )}
        >
          {t('txt_join_sheets_percent_unique_match_desc')}
        </p>
      </div>
    </div>
  );
};
export default MatchStatus;
