import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Sheet from './../../../core/sheet/Sheet';

const useViewModel = ({
  sheet,
  hasMoreAllSheet,
  goToSheet,
  isCurrentOrAfter,
}: {
  sheet: Sheet;
  hasMoreAllSheet: boolean;
  goToSheet: (sheet: Sheet) => void;
  isCurrentOrAfter: boolean;
}) => {
  const { t } = useTranslation();
  const sheetName = sheet.getDisplayName({ t });
  const fileName = sheet.getSpreadSheet().getFilename();

  const isClickAble = hasMoreAllSheet && !isCurrentOrAfter;

  const onClick = useCallback(() => {
    if (isClickAble) {
      goToSheet(sheet);
    }
  }, [isClickAble, sheet, goToSheet]);

  return {
    sheetName,
    fileName,
    onClick,
    isClickAble,
  };
};

export default useViewModel;
