import { Plan } from 'core/constants/plan';
import { isNil } from 'lodash';
import { InputType, LanguageType } from './settingsAPI';
import { ILogSetting, LocaleObject } from '../license/types';

class FeatureWhiteList {
  private i18nOverrides = true;
  private reuseMapping = false;
  private maxFileSizeInMb = 25;
  private multiFileUpload = true;
  private automaticMapping: boolean | undefined;
  private inputTypes?: InputType[];
  private dataHandler = false;
  private defaultLanguage: LanguageType = 'en';
  private dynamicImport = false;
  private customStyling = true;
  private logs = { columns: true, mappings: false };
  private locale: LocaleObject = {};
  private loading = true;
  private rowsLimit?: number;
  private smartTable = false;
  private cleaningAssistant = false;
  private watermark = false;

  setFileSizeUnlimited = () => {
    this.maxFileSizeInMb = -1;
  };

  setMaxFileSizeInMb = (sizeInMb: number) => {
    this.maxFileSizeInMb = sizeInMb;
  };

  setInputTypes = (types: InputType[]) => {
    this.inputTypes = types;
  };

  getAutomaticMapping = () => {
    return this.automaticMapping;
  };

  getDataHandler = () => {
    return this.dataHandler;
  };

  getMaxFileSizeInBytes = () => {
    return this.maxFileSizeInMb * 1024 * 1000;
  };

  getMaxFileSizeInMb = () => {
    return this.maxFileSizeInMb;
  };

  getIsFileSizeUnlimited = () => {
    return this.maxFileSizeInMb === -1;
  };

  enableI18nOverrides = (enable: boolean) => {
    this.i18nOverrides = enable;
  };

  getEnableI18nOverrides = () => {
    return this.i18nOverrides;
  };

  getInputTypes = () => {
    return this.inputTypes;
  };

  enableReuseMapping = (enable: boolean) => {
    this.reuseMapping = enable;
  };

  getEnableReuseMapping = () => {
    return this.reuseMapping;
  };

  enableMultiFileUpload = (enable: boolean) => {
    this.multiFileUpload = enable;
  };

  getEnableMultiFileUpload = () => {
    return this.multiFileUpload;
  };

  enableAutomaticMapping = (enable: boolean | undefined, plan: Plan) => {
    if (isNil(enable)) {
      if (plan !== Plan.STARTER) {
        this.automaticMapping = true;
      } else {
        this.automaticMapping = false;
      }
    } else {
      this.automaticMapping = enable;
    }
  };

  setEnableDataHandler = (dataHandler: boolean | undefined, plan: Plan) => {
    if (dataHandler) {
      this.dataHandler = dataHandler;
      return;
    }
    this.dataHandler = plan === Plan.TEST_ACCOUNT || plan === Plan.BUSINESS;
  };

  setDefaultLanguage(lang: LanguageType) {
    this.defaultLanguage = lang;
  }

  getDefaultLanguage(): LanguageType {
    return this.defaultLanguage || 'en';
  }

  setDynamicImport(dynamicImport: boolean | undefined, plan: Plan) {
    if (!isNil(dynamicImport)) {
      this.dynamicImport = dynamicImport;
      return;
    }
    if (
      plan === Plan.TEST_ACCOUNT ||
      plan === Plan.ENTERPRISE ||
      plan === Plan.BUSINESS
    ) {
      this.dynamicImport = true;
    }
  }

  isDynamicImport(): boolean {
    return this.dynamicImport;
  }

  hasCustomStyling(): boolean {
    return this.customStyling;
  }

  setCustomStyling(customStyling: boolean | undefined, plan: Plan): void {
    if (!isNil(customStyling)) {
      this.customStyling = customStyling;
      return;
    }
    if (plan !== Plan.STARTER) {
      this.customStyling = true;
    }
  }

  getEnableLogs(): ILogSetting {
    return this.logs;
  }

  setEnableLogs(logs?: ILogSetting): void {
    if (logs) {
      this.logs = logs;
      return;
    }

    this.logs = {
      columns: true,
      mappings: false,
    };
  }

  setLocale(local: LocaleObject) {
    this.locale = local;
  }

  getLocale(): LocaleObject {
    return this.locale;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  isLoading() {
    return this.loading;
  }

  setRowsLimit(rowsLimit?: number) {
    this.rowsLimit = rowsLimit;
  }

  getRowsLimit() {
    return this.rowsLimit;
  }

  setEnableSmartTable(enable: boolean) {
    this.smartTable = enable;
  }

  getEnableSmartTable(): boolean {
    return this.smartTable;
  }

  setEnableCleaningAssistant(enable: boolean) {
    this.cleaningAssistant = enable;
  }

  getEnableCleaningAssistant(): boolean {
    return this.cleaningAssistant;
  }

  setEnableWatermark(enable: boolean) {
    this.watermark = enable;
  }

  getEnableWatermark(): boolean {
    return this.watermark;
  }
}

export default FeatureWhiteList;
