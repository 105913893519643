import { RefObject, useRef } from 'react';
import { SheetColumn } from '@nuvo-importer/common/sdk';
import { css, CSSInterpolation, cx } from 'core/emotion';
import Popover from 'baseUI/Popover';
import useViewModel from './viewModel';
import { Boundary } from '@popperjs/core';
import { useTheme } from 'theme';

const maxStyle = css`
  max-width: 100px;
`;

const arrowStyled = css`
  &&#arrow {
    left: 3px !important;
  }
`;

type ISheetNameProps = {
  sheetColumn: SheetColumn;
  boundary?: Boundary;
  customTheme?: CSSInterpolation;
  configThemePopoverInfo?: {
    root?: string;
    arrowIcon?: string;
  };
};

const SheetName = ({
  boundary,
  customTheme,
  configThemePopoverInfo,
  sheetColumn,
}: ISheetNameProps) => {
  const { isShowPopover, getOnHover } = useViewModel();
  const ref = useRef<HTMLSpanElement>(null);
  const theme = useTheme();
  const element = ref.current as HTMLSpanElement;
  const sheetName = sheetColumn.getSheet().getName();

  return (
    <Popover
      isShowArrow
      boundary={boundary}
      direction="top"
      isShow={isShowPopover && element?.scrollWidth > element?.clientWidth}
      overflowCheckOffset={{ top: 10, bottom: -25 }}
      padding={{
        right: -100,
      }}
      message={
        <div
          className={cx(
            'text-xss rounded-medium border-none py-1 px-2 text-white',
            css({
              backgroundColor: theme.getGlobalTheme().getDark500Color(),
            }),
            css({
              '&&': configThemePopoverInfo?.root,
            })
          )}
        >
          {sheetName}
        </div>
      }
      arrowClassName={configThemePopoverInfo?.arrowIcon}
      arrowStyled={arrowStyled}
      render={({ referenceElement }) => {
        return (
          <span
            className="ml-3"
            ref={referenceElement as RefObject<HTMLDivElement>}
            {...getOnHover()}
          >
            <div
              className={cx(
                'text-xss text-gray-560 rounded-2lg flex bg-gray-50 px-2 py-1 leading-3',
                css({ '&&': customTheme })
              )}
            >
              <span ref={ref} className={cx('truncate', maxStyle)}>
                {sheetName}
              </span>
            </div>
          </span>
        );
      }}
    />
  );
};

export default SheetName;
