import React, { useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { ICleaningAssistantGroup } from '../../api/CleaningAssistant.dto';
import CleaningGroup from '../CleaningGroup';

const CleaningGroupList = () => {
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);
  const { groups } = context;

  const groupsJSX = groups.map((g: ICleaningAssistantGroup, i: number) => {
    return <CleaningGroup key={g.column} group={g} index={i} />;
  });

  return <div className={s.rootClass}>{groupsJSX}</div>;
};

export default CleaningGroupList;
