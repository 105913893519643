import { DataModel, SheetColumn } from '@nuvo-importer/common/sdk';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type OnSubmitCallback =
  | ((
      dataModel: DataModel,
      newDataModelSheetMatcher: DataModelSheetMatcher
    ) => void)
  | null;

const CreateNewColumnModalContext = createContext<{
  isOpen: boolean;
  sheetColumn: SheetColumn | null;
  open: (sheetColumn: SheetColumn, onSubmit: OnSubmitCallback) => void;
  close: () => void;
  onSubmitCallback: {
    callback: OnSubmitCallback;
  };
}>({
  isOpen: false,
  sheetColumn: null,
  open: () => {},
  close: () => {},
  onSubmitCallback: {
    callback: null,
  },
});

export const useContextCreateNewColumnModal = () => {
  const { isOpen, sheetColumn, close, open, onSubmitCallback } = useContext(
    CreateNewColumnModalContext
  );

  return { isOpen, sheetColumn, close, open, onSubmitCallback };
};

type CreateNewColumnModalContextProviderProps = {
  children: ReactNode;
};

const CreateNewColumnModalContextProvider = ({
  children,
}: CreateNewColumnModalContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sheetColumn, setSheetColumn] = useState<SheetColumn | null>(null);
  const [onSubmitCallback, setOnSubmitCallback] = useState<{
    callback: OnSubmitCallback;
  }>({ callback: null });

  const open = useCallback(
    (sheetColumn: SheetColumn, onSubmitCallback: OnSubmitCallback) => {
      setIsOpen(true);
      setOnSubmitCallback({
        callback: onSubmitCallback,
      });
      setSheetColumn(sheetColumn);
    },
    []
  );

  const close = useCallback(() => {
    setIsOpen(false);
    // NOTE we need to setTimeout, otherwise it show form error while modal is closing
    setTimeout(() => {
      setSheetColumn(null);
    }, 500);
    setOnSubmitCallback({ callback: null });
  }, []);

  return (
    <CreateNewColumnModalContext.Provider
      value={{
        isOpen,
        sheetColumn,
        open,
        close,
        onSubmitCallback,
      }}
    >
      {children}
    </CreateNewColumnModalContext.Provider>
  );
};

export default CreateNewColumnModalContextProvider;
