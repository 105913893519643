/* istanbul ignore file */

import { DataModel } from '../../core/dataModel/model/DataModel';
import { maxBy, orderBy } from 'lodash';
import SheetColumnDataModelSimilarity from './SheetColumnDataModelSimilarity';
import SheetColumn from './../sheet/SheetColumn';

class SheetColumnDataModelSimilarityList {
  private sheetColumnDataModelSimilarities: SheetColumnDataModelSimilarity[];
  constructor({
    sheetColumnDataModelSimilarities,
  }: {
    sheetColumnDataModelSimilarities: SheetColumnDataModelSimilarity[];
  }) {
    this.sheetColumnDataModelSimilarities = sheetColumnDataModelSimilarities;
  }

  public getSheetColumnDataModelSimilarities = () => {
    return this.sheetColumnDataModelSimilarities;
  };

  /* Getting the most similar data model for a sheet column. */
  public getMostSimilarityBySheetColumn = (sheetColumn: SheetColumn) => {
    const sheetColumnDataModelSimilarities: SheetColumnDataModelSimilarity[] =
      [];

    for (let i = 0; i < this.sheetColumnDataModelSimilarities.length; i++) {
      if (
        this.sheetColumnDataModelSimilarities[i].getSheetColumn() ===
        sheetColumn
      ) {
        sheetColumnDataModelSimilarities.push(
          this.sheetColumnDataModelSimilarities[i]
        );
      }
    }

    return maxBy(sheetColumnDataModelSimilarities, (similarityItem) => {
      return similarityItem.getSimilarity().getSimilarity();
    });
  };

  /* Filtering the sheetColumnDataModelSimilarities array and returning the sorted array. */
  public getSortedSheetColumnDataModelSimilarity = () => {
    const filteredSheetColumnDataModelSimilarities =
      this.sheetColumnDataModelSimilarities
        .filter((sheetColumnDataModelSimilarity) => {
          return sheetColumnDataModelSimilarity.isPassThreshold();
        })
        .filter((sheetColumnDataModelSimilarity) => {
          return !sheetColumnDataModelSimilarity.getMarkApplied();
        });

    return orderBy(
      filteredSheetColumnDataModelSimilarities,
      (sheetColumnDataModelSimilarity) => {
        return sheetColumnDataModelSimilarity.getSimilarity().getSimilarity();
      },
      'desc'
    );
  };

  /* Returning the first item in the array that matches the dataModel. */
  getAllSimilaritySheetColumnByDataModel = (dataModel: DataModel) => {
    const allSimilaritySheetColumnByDataModel =
      this.getSortedSheetColumnDataModelSimilarity().find(
        (sheetColumnDataModelSimilarity) => {
          return sheetColumnDataModelSimilarity.getDataModel() === dataModel;
        }
      );

    return allSimilaritySheetColumnByDataModel;
  };

  /* Marking the data model as applied. */
  markDataModel = (dataModel: DataModel) => {
    this.sheetColumnDataModelSimilarities
      .filter((sheetColumnDataModelSimilarity) => {
        return sheetColumnDataModelSimilarity.getDataModel() === dataModel;
      })
      .forEach((sheetColumnDataModelSimilarity) => {
        sheetColumnDataModelSimilarity.markApplied();
      });
  };

  /* Marking the sheet column as applied. */
  markSheetColumn = (sheetColumn: SheetColumn) => {
    this.sheetColumnDataModelSimilarities
      .filter((sheetColumnDataModelSimilarity) => {
        return sheetColumnDataModelSimilarity.getSheetColumn() === sheetColumn;
      })
      .forEach((sheetColumnDataModelSimilarity) => {
        sheetColumnDataModelSimilarity.markApplied();
      });
  };

  /* Clearing the mark of the sheetColumnDataModelSimilarity. */
  clearMark = () => {
    this.sheetColumnDataModelSimilarities.forEach(
      (sheetColumnDataModelSimilarity) => {
        sheetColumnDataModelSimilarity.unMarkApplied();
      }
    );
  };

  /* Returning the sheetColumnDataModelSimilarity that matches the sheetColumn and dataModel. */
  getSimilaritySheetColumn = (
    sheetColumn: SheetColumn,
    dataModel: DataModel
  ) => {
    return this.sheetColumnDataModelSimilarities.find(
      (sheetColumnDataModelSimilarity) => {
        return (
          sheetColumnDataModelSimilarity.getSheetColumn() === sheetColumn &&
          sheetColumnDataModelSimilarity.getDataModel() === dataModel
        );
      }
    );
  };
}

export default SheetColumnDataModelSimilarityList;
