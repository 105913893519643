/* istanbul ignore file */

import { orderBy } from 'lodash';
import { DataModel } from '../dataModel/model/DataModel';
import { Option } from '../dataModel/model/CategoryDataModel';
import SheetColumn from './../sheet/SheetColumn';
import SheetColumnDataModelOptionSimilarity from './SheetColumnDataModelOptionSimilarity';

type MapSheetColumnDataModelOption = {
  sheetColumn: SheetColumn;
  dataModel: DataModel;
};

class SheetColumnDataModelOptionSimilarityList {
  private sheetColumnDataModelOptionSimilarities: SheetColumnDataModelOptionSimilarity[];
  private mapSheetColumnDataModelOptions: MapSheetColumnDataModelOption[] = [];

  constructor({
    sheetColumnDataModelOptionSimilarities,
    mapSheetColumnDataModelOptions,
  }: {
    sheetColumnDataModelOptionSimilarities: SheetColumnDataModelOptionSimilarity[];
    mapSheetColumnDataModelOptions: MapSheetColumnDataModelOption[];
  }) {
    this.sheetColumnDataModelOptionSimilarities =
      sheetColumnDataModelOptionSimilarities;
    this.mapSheetColumnDataModelOptions = mapSheetColumnDataModelOptions;
  }

  /* Filtering the sheet column data model option similarities by the similarity threshold. */
  private getSheetColumnDataModelOptionSimilarities = () => {
    const filteredSheetColumnDataModelOptionSimilarities =
      this.sheetColumnDataModelOptionSimilarities.filter(
        (sheetColumnDataModelOptionSimilarity) => {
          return sheetColumnDataModelOptionSimilarity.isPassThreshold();
        }
      );
    return orderBy(
      filteredSheetColumnDataModelOptionSimilarities,
      (sheetColumnDataModelOptionSimilarity) => {
        return sheetColumnDataModelOptionSimilarity
          .getSimilarity()
          .getSimilarity();
      },
      'desc'
    );
  };

  /* Filtering the sheet column data model option similarities by the sheet column and the data model. */
  getSimilaritySheetColumnOptions = (
    sheetColumn: SheetColumn,
    dataModel: DataModel
  ) => {
    return this.getSheetColumnDataModelOptionSimilarities().filter(
      (sheetColumnDataModelOptionSimilarity) => {
        return (
          sheetColumnDataModelOptionSimilarity.getSheetColumnOption()
            .sheetColumn === sheetColumn &&
          sheetColumnDataModelOptionSimilarity.getDataModelOption()
            .dataModel === dataModel
        );
      }
    );
  };

  /* Finding the similarity between the sheet column and the data model. */
  getSimilaritiesDataModelOption = (
    sheetColumn: SheetColumn,
    dataModel: DataModel,
    dataModelOption: Option['value']
  ) => {
    return this.sheetColumnDataModelOptionSimilarities.filter(
      (sheetColumnDataModelOptionSimilarity) => {
        return (
          sheetColumnDataModelOptionSimilarity.getSheetColumnOption()
            .sheetColumn === sheetColumn &&
          sheetColumnDataModelOptionSimilarity
            .getDataModelOption()
            .dataModel.getKey() === dataModel.getKey() &&
          sheetColumnDataModelOptionSimilarity.getDataModelOption().option ===
            dataModelOption
        );
      }
    );
  };

  getSimilaritiesDataModelBySheetColumn = (
    sheetColumn: SheetColumn,
    dataModel: DataModel
  ) => {
    return this.sheetColumnDataModelOptionSimilarities.filter(
      (sheetColumnDataModelOptionSimilarity) => {
        return (
          sheetColumnDataModelOptionSimilarity.getSheetColumnOption()
            .sheetColumn === sheetColumn &&
          sheetColumnDataModelOptionSimilarity
            .getDataModelOption()
            .dataModel.getKey() === dataModel.getKey()
        );
      }
    );
  };

  getRawSheetColumnDataModelOptionSimilarities = () => {
    return this.sheetColumnDataModelOptionSimilarities;
  };

  merge(
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList
  ) {
    const addedSheetColumnDataModelOptionSimilarities =
      sheetColumnDataModelOptionSimilarityList.getRawSheetColumnDataModelOptionSimilarities();
    const addedMapSheetColumnDataModelOptions =
      sheetColumnDataModelOptionSimilarityList.mapSheetColumnDataModelOptions;

    for (
      let i = 0;
      i < this.sheetColumnDataModelOptionSimilarities.length;
      ++i
    ) {
      const isExistingMatch = addedSheetColumnDataModelOptionSimilarities.find(
        (item) => {
          const dataModel = item.getDataModelOption();
          const sheetCol = item.getSheetColumnOption();
          const oldSheetCol =
            this.sheetColumnDataModelOptionSimilarities[
              i
            ].getSheetColumnOption();
          const oldDataModel =
            this.sheetColumnDataModelOptionSimilarities[i].getDataModelOption();
          return (
            sheetCol.option === oldSheetCol.option &&
            sheetCol.sheetColumn.getColumnKey() ===
              oldSheetCol.sheetColumn.getColumnKey() &&
            dataModel.option === oldDataModel.option &&
            dataModel.dataModel.getKey() === oldDataModel.dataModel.getKey()
          );
        }
      );

      if (isExistingMatch) continue;

      addedSheetColumnDataModelOptionSimilarities.push(
        this.sheetColumnDataModelOptionSimilarities[i]
      );
    }

    for (let i = 0; i < this.mapSheetColumnDataModelOptions.length; ++i) {
      addedMapSheetColumnDataModelOptions.push(
        this.mapSheetColumnDataModelOptions[i]
      );
    }

    return new SheetColumnDataModelOptionSimilarityList({
      sheetColumnDataModelOptionSimilarities:
        addedSheetColumnDataModelOptionSimilarities,
      mapSheetColumnDataModelOptions: addedMapSheetColumnDataModelOptions,
    });
  }

  isAlreadyMapping = (sheetColumn: SheetColumn, dataModel: DataModel) => {
    if (
      this.mapSheetColumnDataModelOptions.some(
        (item) =>
          item.sheetColumn.equal(sheetColumn) && dataModel === item.dataModel
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
}

export default SheetColumnDataModelOptionSimilarityList;
