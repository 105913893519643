import { useEffect } from 'react';
import { ENVIRONMENT, getEnvironment } from '../utils/getEnvironment';

const env = getEnvironment();

const useViewModel = () => {
  useEffect(() => {
    const titleElement = document.querySelector('title');

    if (titleElement) {
      if (env === ENVIRONMENT.SALE_DEMO) {
        titleElement.innerHTML = 'Importer Sales Demo';
      } else if (env === ENVIRONMENT.PRODUCTION) {
        titleElement.innerHTML = 'Importer Production React';
      } else if (env === ENVIRONMENT.STAGING) {
        titleElement.innerHTML = 'Importer Staging React';
      } else {
        titleElement.innerHTML = 'Importer Development React';
      }
    }
  }, []);

  const menuName = (() => {
    if (env === ENVIRONMENT.SALE_DEMO) {
      return 'Sales Demo';
    } else if (env === ENVIRONMENT.PRODUCTION) {
      return 'Production React';
    } else if (env === ENVIRONMENT.STAGING) {
      return 'Staging React';
    } else {
      return 'Development';
    }
  })();

  const menuItems = [{ name: menuName, path: '/' }];

  return { menuItems };
};

export default useViewModel;
