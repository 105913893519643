import {
  CSSProperties,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper as useBasePopper } from 'react-popper';
import type { Placement, Rect } from '@popperjs/core';
import ContextMenuController from '../controller/ContextMenuController';
import { hideFn } from '../../../../popper/customHide';
import AllColumnSetting from '../../columns/AllColumnSetting';

type PopperProps = {
  contextMenuController: ContextMenuController;
  parentTableElement: MutableRefObject<HTMLElement | undefined>;
  htCloneLeftWtHolderElement: MutableRefObject<HTMLElement | undefined>;
  isOpen: boolean;
  allColumnSetting: AllColumnSetting;
  currentColumnIndex: number | null;
};

const popperOffset = ({
  placement,
}: {
  popper: Rect;
  reference: Rect;
  placement: Placement;
}): [number, number] => {
  if (placement === 'bottom-end') {
    return [-5, -2];
  } else {
    return [21, -2];
  }
};

const popperPadding = {
  left: 65,
};

const usePopper = ({
  contextMenuController,
  parentTableElement,
  htCloneLeftWtHolderElement,
  isOpen,
  allColumnSetting,
  currentColumnIndex,
}: PopperProps) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [paddingLeft, setPaddingLeft] = useState(0);

  const {
    styles: currentStyles,
    attributes,
    forceUpdate,
    state,
  } = useBasePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: popperOffset,
        },
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          boundary: parentTableElement.current,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: parentTableElement.current,
          tether: false,
        },
      },
      { name: 'hide', enabled: false },
      {
        name: 'customHide',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['preventOverflow'],
        fn: hideFn,
        options: {
          boundary: parentTableElement.current,
          padding: {
            left: paddingLeft,
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (isOpen) {
      const freezeColumns = allColumnSetting.getFreezeColumns();
      const isFreeze =
        currentColumnIndex === null
          ? false
          : freezeColumns.includes(currentColumnIndex);

      if (htCloneLeftWtHolderElement.current && !isFreeze) {
        setPaddingLeft(htCloneLeftWtHolderElement.current?.clientWidth - 20);
      } else {
        setPaddingLeft(popperPadding.left);
      }
    }
  }, [
    isOpen,
    htCloneLeftWtHolderElement,
    currentColumnIndex,
    allColumnSetting,
  ]);

  const prevStyles = useRef<{
    [key: string]: CSSProperties;
  }>();

  useLayoutEffect(() => {
    const contextMenuPopper = contextMenuController.getContextMenuPopper();
    const subscription = contextMenuPopper
      .contextMenuPopperObservable()
      .subscribe(() => {
        setReferenceElement(contextMenuPopper.getReferenceElement());
        setTimeout(() => {
          forceUpdate?.();
        });
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [contextMenuController, forceUpdate]);

  const referenceWidth = state?.['rects']?.['reference']['width'];

  const isShow = useMemo(() => {
    return referenceWidth !== 0;
  }, [referenceWidth]);

  useEffect(() => {
    if (isShow) {
      prevStyles.current = currentStyles;
    }
  }, [prevStyles, currentStyles, isShow]);

  const styles = useMemo(() => {
    if (isShow) {
      return currentStyles;
    } else {
      return prevStyles.current ?? currentStyles;
    }
  }, [isShow, currentStyles, prevStyles]);

  return {
    styles,
    attributes,
    popperElement,
    setPopperElement,
    setReferenceElement,
    isShow,
  };
};

export default usePopper;
