import { Sheet } from '@nuvo-importer/common/sdk';
import { useCallback, useRef, useState } from 'react';

const useViewModel = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [previewSheet, setPreviewSheet] = useState<Sheet | null>(null);

  const openPreview = useCallback((sheet: Sheet): void => {
    setPreviewSheet(sheet);
  }, []);

  const dismissPreview = useCallback((): void => {
    setPreviewSheet(null);
  }, []);

  return {
    scrollContainerRef,
    openPreview,
    dismissPreview,
    previewSheet,
  };
};

export default useViewModel;
