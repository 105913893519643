import { DataModel } from 'dataModel';
import { isNil, isString, isEmpty as isLodashEmpty } from 'lodash';
import { useMemo } from 'react';
import { Value as SheetValue } from './../../../../sheetImporter/Sheet';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import useOptionMatches from './optionMatches';
import useRowExamples from './rowExamples';

export const getIsEmpty = (value: SheetValue) => {
  if (isNil(value)) {
    return true;
  } else if (isString(value)) {
    return isLodashEmpty(value);
  } else {
    return false;
  }
};

const useEmptyState = ({
  matchedDataModel,
  sheetColumn,
  prefixName,
}: {
  matchedDataModel?: DataModel;
  sheetColumn: SheetColumn;
  prefixName: string;
}) => {
  const { matchingLength } = useOptionMatches({ prefixName });
  const { rows } = useRowExamples({ sheetColumn });

  const isPreviewDataEmpty = rows.length === 0;

  const matchedDataModelDropdown =
    matchedDataModel && matchedDataModel.isDropdown();
  const noPreviewValuesRows = sheetColumn.getRows().length === 0;
  const noPreviewIndicate = matchedDataModelDropdown
    ? matchingLength === sheetColumn.getUniqueRows().length
    : true;

  const isMatchingReportEmpty = useMemo(() => {
    return noPreviewIndicate;
  }, [noPreviewIndicate]);

  return {
    isPreviewDataEmpty,
    noPreviewValuesRows,
    noPreviewIndicate,
    isMatchingReportEmpty,
    getIsEmpty,
  };
};

export default useEmptyState;
