import { useTranslation } from 'react-i18next';
import { useMainView } from 'settings';
import { css, cx } from 'core/emotion';
import ProgressProcessing from 'baseUI/Processing';

const loadingStylePage = cx(
  'absolute left-1/2',
  css`
    top: 19.6rem;
    transform: translateX(-50%);
  `
);

const Loading = ({
  initialProgress,
  timer,
  text,
  notShowJustMoment,
  animateByKeyframe,
}: {
  initialProgress?: number;
  timer?: number;
  text?: string;
  notShowJustMoment?: boolean;
  animateByKeyframe?: boolean;
}) => {
  const { t } = useTranslation();
  const { modal } = useMainView();
  return (
    <div
      className={cx(
        modal ? '!mt-0 ml-1.5 h-full items-center pb-32' : 'min-h-500',
        'mt-5 flex justify-center'
      )}
    >
      <ProgressProcessing
        initialProgress={initialProgress}
        timer={timer as number}
        text={text ?? t('txt_data_processing')}
        notShowJustMoment={notShowJustMoment}
        step={10}
        className={!modal ? loadingStylePage : ''}
        animateByKeyframe={animateByKeyframe}
      />
    </div>
  );
};

export default Loading;
