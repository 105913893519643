import { useTranslation } from 'react-i18next';
import { cx } from '../../../../emotion';

type SelectedBadgeProps = {
  count: number;
  className?: string;
};

const SelectedBadge = ({ count, className }: SelectedBadgeProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        'bg-gray-120 text-neutral-600 rounded-2xl px-2.5 py-0.5 text-sm',
        className
      )}
    >
      {t('txt_count_selected', { count: count })}
    </div>
  );
};

export default SelectedBadge;
