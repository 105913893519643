import { useEffect, useState } from 'react';

export const LIST_NETWORK_SLOW = ['slow-2g', '2g', '3g'];

function getNetworkConnection() {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).connection ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).mozConnection ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).webkitConnection ||
    null
  );
}
function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }
  return {
    effectiveType: connection.effectiveType,
  };
}
export const useNetworkConnection = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, setState] = useState<any>(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });
  useEffect(() => {
    const handleConnectionChange = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setState((prevState: any) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };
    const connection = getNetworkConnection();
    connection?.addEventListener('change', handleConnectionChange);
    return () => {
      connection?.removeEventListener('change', handleConnectionChange);
    };
  }, []);
  return state;
};
