import {
  FilterCondition,
  filterConditionsHasValueField,
  filterConditionsHasSecondValueFields,
} from '../../../columns/FilterStrategy';
import Dropdown from '../../components/Dropdown';
import TextInput from '../../components/TextInput';
import { useTranslation } from 'react-i18next';
import useOptions from './useOptions';
import { DataModel } from '../../../../../../core/dataModel/model/DataModel';
import { ConfigTheme } from '../../../type';
import { css, cx } from '../../../../../../core/emotion';
import { RefObject } from 'react';
import { HotTableClass } from '@handsontable/react';

type FilterByConditionProps = {
  condition: FilterCondition;
  onConditionChange: (condition: FilterCondition) => void;
  className?: string;
  value: string;
  onValueChange: (value: string) => void;
  secondValue?: string;
  onSecondValueChange: (value: string) => void;
  currentDataModel: DataModel;
  configTheme?: ConfigTheme;
  hotInstance?: RefObject<HotTableClass>;
  modal?: boolean;
};

const FilterByCondition = ({
  condition,
  onConditionChange,
  value,
  onValueChange,
  secondValue,
  onSecondValueChange,
  className,
  currentDataModel,
  configTheme,
  hotInstance,
  modal,
}: FilterByConditionProps) => {
  const { t } = useTranslation();
  const { options } = useOptions({ currentDataModel });

  return (
    <div className={className}>
      <p
        className={cx(
          'text-xss mb-1 leading-none text-gray-700',
          css({
            '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
              ?.filter?.label,
          })
        )}
      >
        {t('txt_filter_by_condition')}
      </p>
      <Dropdown<FilterCondition>
        value={condition}
        onChange={onConditionChange}
        options={options}
        size="small"
        configTheme={
          configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
            ?.dropdown
        }
        hotInstance={hotInstance}
        modal={modal}
      />
      {filterConditionsHasValueField.includes(condition) ? (
        <TextInput
          wrapperClassName="mt-2"
          value={value}
          onChange={onValueChange}
          placeholder={t('txt_filter_add_value_placeholder')}
          size="small"
          configTheme={
            configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
              ?.input
          }
        />
      ) : null}
      {filterConditionsHasSecondValueFields.includes(condition) ? (
        <TextInput
          wrapperClassName="mt-2"
          value={secondValue}
          onChange={onSecondValueChange}
          placeholder={t('txt_filter_add_value_placeholder')}
          size="small"
          configTheme={
            configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
              ?.input
          }
        />
      ) : null}
    </div>
  );
};

export default FilterByCondition;
