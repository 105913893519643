import { useMainView } from 'settings';
import { memoize } from 'lodash';

/**
 * It returns a function that scrolls to the top of the page
 * @returns scrollToTop
 */
export const useScroll = () => {
  const { modal } = useMainView();

  const scrollToTop = () => {
    const scrollContainerModal = document.querySelector(
      '.ReactModalPortal > div'
    );
    if (modal) {
      scrollContainerModal?.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return { scrollToTop };
};

/* A memoized function that returns the scrollbar width. */
export const getScrollbarWidth = memoize(() => {
  const outer = document.createElement('div') as HTMLDivElement;
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
});
