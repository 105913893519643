/* istanbul ignore file */

import chroma from 'chroma-js';
import { css, cx } from 'core/emotion';
import { useMemo } from 'react';
import { useTheme } from 'theme';

const wrapperStyle = css`
  margin-top: 44px;
`;

const loaderStyle = css`
  margin-bottom: 59px;
`;

const containerStyle = css`
  width: 160px;
  margin-top: -49px;
`;

const getLoadingColor = (borderColor: string) => {
  return css`
    .loader {
      border-color: ${chroma(borderColor).alpha(0.3).hex()};
      border-top-color: ${borderColor};
    }
    .loader::before {
      --rad: radial-gradient(circle 7px, ${borderColor} 99%, transparent 100%);
    }
  `;
};

const CircleProgress = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  const theme = useTheme();

  const themStyle = useMemo(() => {
    const borderColor = theme.getLoaderTheme().loadAnimationColor;
    return cx(
      'max-h-40 h-40',
      containerStyle,
      className,
      css({ '&&': theme.getLoaderTheme().root }),
      borderColor
        ? getLoadingColor(borderColor)
        : getLoadingColor(theme.getGlobalTheme().getPrimaryColor())
    );
  }, [theme, className]);

  return (
    <div className={themStyle}>
      <div className={cx('w-full', wrapperStyle)}>
        <div className={cx('mx-auto -mt-7', loaderStyle)}>
          <div className="loader" />
        </div>
        <p
          className={cx(
            'text-color-primary mt-16 flex justify-center text-xl font-semibold',
            css({ '&&': theme.getLoaderTheme().content })
          )}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default CircleProgress;
