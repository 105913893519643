import { Sheet } from '@nuvo-importer/common/sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { joinSheetModalId } from '../..';
import { useFormState } from 'react-final-form';
import { IFormValues } from '../viewModel';

const useViewModel = ({
  appendTargetSheet,
  sourceSheet,
}: {
  appendTargetSheet: Sheet;
  sourceSheet: Sheet;
}) => {
  const scrollbarRef = useRef<SimpleBar>(null);
  const { values } = useFormState<IFormValues>();
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const sheetName = appendTargetSheet.getName();

  useEffect(() => {
    setTimeout(() => {
      setModalElement(
        (document.getElementById(joinSheetModalId) as HTMLDivElement) ?? null
      );
    }, 0);
  }, []);

  const getBaseColumnOptions = useCallback(
    (position: number) => {
      return sourceSheet
        .getColumns()
        .filter((sheetColumn) => {
          return values.columns.every((item, index) => {
            if (index === position || !item.source) {
              return true;
            }

            return !item.source.equal(sheetColumn);
          });
        })
        .map((sheetColumn) => {
          return {
            label: sheetColumn.getColumnKey() ?? '',
            value: sheetColumn,
          };
        });
    },
    [sourceSheet, values.columns]
  );

  return {
    scrollbarRef,
    sheetName,
    modalElement,
    getBaseColumnOptions,
  };
};

export default useViewModel;
