import SheetColumn from './../../../../../../sheetImporter/SheetColumn';
import { Option } from '../types';
import PopoverBadgeMatchItem from './popover';
import { Boundary } from '@popperjs/core';
import { CSSInterpolation } from 'core/emotion';

const BadgeMatchItem = ({
  option,
  onRemoveMatchColumn,
  boundary,
  themeBadge,
  configThemePopoverInfo,
}: {
  option: Option;
  onRemoveMatchColumn: (value: SheetColumn) => void;
  boundary?: Boundary;
  themeBadge?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
  configThemePopoverInfo?: {
    root?: string;
    arrowIcon?: string;
  };
}) => {
  return (
    <div>
      {option.sheetColumnMatch ? (
        <PopoverBadgeMatchItem
          boundary={boundary}
          sheetColumn={option.sheetColumnMatch}
          onRemoveMatchColumn={onRemoveMatchColumn}
          configThemeBadge={themeBadge}
          configThemePopoverInfo={configThemePopoverInfo}
        />
      ) : null}
    </div>
  );
};

export default BadgeMatchItem;
