import { EventLogBody } from '../dto/model';
import EventLogAPI from '../remote';

class EventLogRepository {
  private eventLogAPI: EventLogAPI;

  constructor(eventLogAPI: EventLogAPI) {
    this.eventLogAPI = eventLogAPI;
  }

  /* A function that takes in two parameters, body and license. It then returns the result of the
  function call to eventLogAPI.send. */
  sendData = (body: EventLogBody, license: string) => {
    return this.eventLogAPI.send(body, license);
  };
}

export default EventLogRepository;
