import { css, CSSObject, cx } from 'core/emotion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import { useTheme } from 'theme';
import { ReactComponent as QuestionIcon } from '../../../../assets/icon/question.svg';
import { getIsEmpty } from '../common/emptyState';

const ValueRowsAsPercent = ({ sheetColumn }: { sheetColumn: SheetColumn }) => {
  const valueRowsAsPercent = useMemo(() => {
    const values =
      (sheetColumn.getRows().filter((value) => {
        return !getIsEmpty(value);
      }).length /
        sheetColumn.getRows().length) *
      100;

    return `${values.toFixed(2).replace(/[.,]00$/, '')} %`;
  }, [sheetColumn]);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div
      className={cx(
        'mt-2 flex items-center',
        css({
          '&&': {
            color:
              (theme.getColumnMatchTheme().matchingPercent.icon as CSSObject)
                ?.color ?? '#8D8D8D',
          },
        })
      )}
    >
      <QuestionIcon className="relative -top-px h-5 w-5 flex-shrink-0" />
      <p
        className={cx(
          'text-color-secondary ml-2 text-xs',
          css({ '&&': theme.getColumnMatchTheme().matchingPercent.root })
        )}
      >
        {t('txt_percent_row_have_values', {
          percent: valueRowsAsPercent,
        })}
      </p>
    </div>
  );
};

export default ValueRowsAsPercent;
