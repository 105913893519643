import { CELL_TYPE_CLASS } from '../../../DataModelSheetForm/type';
import Core from 'handsontable/core';
import { numericRenderer, textRenderer } from 'handsontable/renderers';
import numbro from 'numbro';
import deCurrency from 'numbro/languages/de-DE';
import { HookedRecordInfoLevel } from '../../../../hooks/hooksAPI';
import { cellClassName } from '../../../../reviewEntries/utils/popperRootClassName';
import { NumberFormat } from '../../../../dataModel/columnsAPI';
import { NumberParser } from '../../../../utils/NumberParser';

numbro.registerLanguage(deCurrency);

type TextInputOptions = {
  numberFormat?: NumberFormat;
  numberAdornment?: 'currency' | 'percentage';
  symbol?: NumberFormat;
  mediaSize?: boolean;
};

export function SimpleTextInputView(
  getCellMeta: (row: number, col: number) => HookedRecordInfoLevel | undefined,
  options?: TextInputOptions
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function Renderer(this: Core, ...args: any) {
    const [_hotInstance, td, row, col, _prop, _value, cellProperties] = args;
    // NOTE: row is visual row
    const level = getCellMeta(row, col);
    td.className = level
      ? `${td.className} ${cellClassName(level)}`
      : `${td.className} ${CELL_TYPE_CLASS.DEFAULT}`;

    cellProperties.mediaSize = options?.mediaSize;
    if (cellProperties.type === 'numeric') {
      if (options?.numberFormat) {
        cellProperties.numberFormat = options?.numberFormat;
        textRenderer.apply(this, args);
        td.innerHTML = NumberParser.convertToFormat(_value, {
          targetFormat: options.numberFormat,
          adornment: options.numberAdornment,
          symbol: options.symbol,
        });
      } else {
        numericRenderer.apply(this, args);
      }
    } else {
      textRenderer.apply(this, args);
    }

    return td;
  };
}

export default SimpleTextInputView;
