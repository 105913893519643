import { DataModel } from '../../dataModel/model/DataModel';
import IValueParser, { PossibleValue } from './IValueParser';
import IntNumberValueParser from './IntNumberValueParser';
import FloatNumberValueParser from './FloatNumberValueParser';
import CurrencyValueParser from './CurrencyValueParser';
import PercentValueParser from './PercentValueParser';
import { DATATYPE } from '../../dataType';

const intNumberValueParser = new IntNumberValueParser();
const floatNumberValueParser = new FloatNumberValueParser();
const currentValueParser = new CurrencyValueParser();
const percentValueParser = new PercentValueParser();

class ValueBeforeHookParser {
  static parse = (dataModel: DataModel, value: PossibleValue) => {
    let parser: IValueParser;
    switch (dataModel.getType()) {
      case DATATYPE.INTEGER:
        parser = intNumberValueParser;
        break;
      case DATATYPE.FLOAT:
        parser = floatNumberValueParser;
        break;
      case DATATYPE.CURRENCY_EUR:
      case DATATYPE.CURRENCY_USD:
        parser = currentValueParser;
        break;
      case DATATYPE.PERCENTAGE:
        parser = percentValueParser;
        break;
      default:
        return value;
    }

    return parser.parse(dataModel, value, true);
  };
}

export default ValueBeforeHookParser;
