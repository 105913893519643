import BaseNumberValueParser from './BaseNumberValueParser';
import { PossibleValue } from './IValueParser';

class IntNumberValueParser extends BaseNumberValueParser {
  valueParser = (value: PossibleValue, isBeforeHook: boolean) => {
    const result = Number(value);

    if (isNaN(result)) {
      return `${value}`;
    }

    if (isBeforeHook) {
      return result;
    }

    return result;
  };
}

export default IntNumberValueParser;
