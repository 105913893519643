import { useTranslation } from 'react-i18next';
import ContextMenuItem from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import useViewModel from './viewModel';
import { ConfigTheme } from '../../type';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { Subject } from 'rxjs';
import { CustomColumnUIObservable } from '../../customColumns';
import ContextMenuController from '../controller/ContextMenuController';

const EditMenuItem = ({
  configTheme,
  dataModel,
  customColumnUIObservable,
  contextMenuController,
}: {
  configTheme?: ConfigTheme;
  dataModel: DataModel;
  customColumnUIObservable: Subject<CustomColumnUIObservable>;
  contextMenuController: ContextMenuController;
}) => {
  const { t } = useTranslation();
  const { onMenuClick } = useViewModel({
    dataModel,
    customColumnUIObservable,
    contextMenuController,
  });

  return (
    <ContextMenuItem
      icon={ContextMenuIcon.EDIT}
      text={t('txt_edit')}
      onClick={onMenuClick}
      configTheme={configTheme}
    />
  );
};

export default EditMenuItem;
