/* istanbul ignore file */

export enum ColumnType {
  BOOLEAN = 'boolean',
  CATEGORY = 'category',
  NUMBER = 'number',
  STRING = 'string',
}

export declare type InputData = Record<
  string,
  string | boolean | number | undefined
>[];

export interface ExecuteOptions {
  reuseMappingData: boolean;
  enableAlternativeMatch: boolean;
  isNetwork3G: boolean;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface ColumnValue {
  key: string;
  value?: string[] | null;
}

interface Validator {
  validate: string;
  columnValues?: ColumnValue[] | null;
  regex?: string | null;
}

export interface ColumnConfig {
  key: string;
  columnType: string;
  label: string;
  description: string;
  validators: Validator[];
  isMultiple?: boolean;
  isMultiSelect?: boolean;
  dropdownOptions?: DropdownOption[] | null;
}

export interface MapObject<T> {
  [key: string]: T;
}

export interface SuggestionMatching {
  key: string;
  value: number;
  isSameWord?: boolean;
}

export interface FullResultMatching {
  label: string;
  suggestions: SuggestionMatching[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  choice: MapObject<any>;
}

export interface License {
  licenseKey: string;
  framework: string;
}

export type CalculateSimilarityResult = {
  result: FullResultMatching[];
};

export type LoadVectorFn = () => Promise<boolean>;

export type ExecuteFn = (
  columns: ColumnConfig[],
  inputJson: InputData,
  license: License,
  options: ExecuteOptions
) => Promise<{
  result: FullResultMatching[];
}>;

export type GetVectorsFn = () => Record<string, Float32Array>;

export interface MatchingAble {
  loadVector: LoadVectorFn;
  execute: ExecuteFn;
  getVectors: () => Promise<Record<string, Float32Array>>;
}
