import { useMemo } from 'react';
import { useTheme } from '@nuvo-importer/common';
import chroma from 'chroma-js';

interface IProps {
  customStyle?: {
    borderColor?: string;
    headerColor?: string;
    cellColor?: string;
  };
}

export default ({ customStyle }: IProps) => {
  const theme = useTheme();
  const secondaryColor = theme.getGlobalTheme().getSecondaryColor();

  const darkSecondaryColor = useMemo(() => {
    const secondaryChroma = chroma(secondaryColor);
    return chroma
      .hsl(
        secondaryChroma.get('hsl.h') + 0.3325,
        secondaryChroma.get('hsl.s') - 0.03,
        secondaryChroma.get('hsl.l') - 0.064
      )
      .hex();
  }, [secondaryColor]);

  return (
    <svg
      width="212"
      height="133"
      viewBox="0 0 212 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7012_69070)">
        <rect
          x="7.21875"
          y="47.2451"
          width="196.945"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="159.213"
          y="47.2451"
          width="44.9496"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.10547"
          y="47.2451"
          width="45.8295"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.21875"
          y="66.4343"
          width="196.945"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="159.213"
          y="66.4343"
          width="44.9496"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.10547"
          y="66.4343"
          width="45.8295"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.21875"
          y="85.6235"
          width="196.945"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="159.213"
          y="85.6235"
          width="44.9496"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.10547"
          y="85.6235"
          width="45.8295"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.21875"
          y="28.0564"
          width="196.945"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="159.213"
          y="28.0564"
          width="44.9496"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.10547"
          y="28.0562"
          width="45.8295"
          height="19.5543"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <path
          d="M7.21875 104.812H204.164V122.367C204.164 123.471 203.268 124.367 202.164 124.367H9.21875C8.11418 124.367 7.21875 123.471 7.21875 122.367V104.812Z"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <path
          d="M159.213 104.812H204.163V122.367C204.163 123.471 203.267 124.367 202.163 124.367H161.213C160.109 124.367 159.213 123.471 159.213 122.367V104.812Z"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <path
          d="M7.10547 104.812H52.935V124.367H9.10547C8.0009 124.367 7.10547 123.471 7.10547 122.367V104.812Z"
          fill={`${customStyle?.cellColor ?? 'white'}`}
        />
        <rect
          x="7.10547"
          y="8.8667"
          width="46.702"
          height="19.5543"
          fill={`${customStyle?.headerColor ?? secondaryColor}`}
        />
        <rect
          x="53.8074"
          y="8.86694"
          width="106.424"
          height="19.5543"
          fill={`${customStyle?.headerColor ?? secondaryColor}`}
        />
        <rect
          x="160"
          y="8.86694"
          width="44.08"
          height="19.5543"
          fill={`${customStyle?.headerColor ?? secondaryColor}`}
        />
        <line
          x1="7.2415"
          y1="9.61299"
          x2="204.008"
          y2="9.61297"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          x1="7.2415"
          y1="27.9003"
          x2="204.008"
          y2="27.9003"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          opacity="0.6"
          x1="7.2415"
          y1="47.1503"
          x2="204.008"
          y2="47.1503"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          opacity="0.6"
          x1="7.2415"
          y1="66.4003"
          x2="204.008"
          y2="66.4003"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          opacity="0.6"
          x1="7.2415"
          y1="85.6501"
          x2="204.008"
          y2="85.6501"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          opacity="0.6"
          x1="7.2415"
          y1="104.9"
          x2="204.008"
          y2="104.9"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          x1="159.773"
          y1="9.56694"
          x2="159.773"
          y2="108.267"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <line
          x1="53.1097"
          y1="9.5667"
          x2="53.1097"
          y2="121.636"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          strokeWidth="1.4"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7012_69070"
          x="-1.4585"
          y="0.866699"
          width="214.167"
          height="131.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.917647 0 0 0 0 0.992157 0 0 0 0.75 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7012_69070"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7012_69070"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
