import { useState } from 'react';
import Menu, { IMenuItems } from './Menu';
import useViewModel from './viewModel';
import { useClickAway } from 'react-use';
import IconButton, { IIconButton } from 'baseUI/IconButton';
import { cx } from '@nuvo-importer/common';
import { SheetItemThemeAPI } from '@nuvo-importer/common/sdk';

type IMenuButtonProps = {
  icon: IIconButton;
  menus: IMenuItems;
  boundary?: HTMLDivElement;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  menuTheme?: SheetItemThemeAPI['addMenu'];
};

const MenuButton = ({
  icon,
  menus,
  boundary,
  onClick,
  disabled,
  className,
  menuTheme,
}: IMenuButtonProps) => {
  const {
    popper,
    setPopperElement,
    setReferenceElement,
    popperElement,
    referenceElement,
  } = useViewModel({ boundary });
  const [isOpen, setIsOpen] = useState(false);

  useClickAway({ current: popperElement }, (event) => {
    if (isOpen && !referenceElement?.contains(event.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  });

  return (
    <>
      <IconButton
        icon={icon}
        className={cx(
          'border-blue-dark-50 border',
          isOpen ? 'bg-blue-dark-100' : '',
          className
        )}
        ref={setReferenceElement}
        disabled={disabled}
        onClick={
          onClick
            ? onClick
            : (event) => {
                event.stopPropagation();
                setIsOpen(!isOpen);
              }
        }
      />
      {isOpen ? (
        <Menu
          popper={popper}
          ref={setPopperElement}
          menus={menus}
          onMenuClick={() => {
            setIsOpen(false);
          }}
          menuTheme={menuTheme}
        />
      ) : null}
    </>
  );
};

export default MenuButton;
