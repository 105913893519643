import Handsontable from 'handsontable';
import { BehaviorSubject } from 'rxjs';
import ModeViewTable, {
  ModeViewTableState,
} from '../ModeViewTable/ModeViewTable';
import AllColumnSetting from '../columns/AllColumnSetting';

type CheckMaps = Record<number, boolean>;

export enum CheckAllState {
  CHECK_ALL,
  CHECK_AT_LEAST_ONE,
  NO_CHECK,
}

class CheckState {
  public checkedMaps: CheckMaps;
  private hotInstance: Handsontable;
  private _checkBoxObservable: BehaviorSubject<CheckState>;
  private cachedCheckAllState: CheckAllState | null = null;
  private modeViewTable: ModeViewTable;
  private allColumnSetting: AllColumnSetting;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private dataSet: Record<string, any>[];

  constructor(
    checkedMaps: CheckMaps,
    hotInstance: Handsontable,
    modeViewTable: ModeViewTable,
    allColumnSetting: AllColumnSetting,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    dataSet: Record<string, any>[]
  ) {
    this.checkedMaps = checkedMaps;
    this.hotInstance = hotInstance;
    this._checkBoxObservable = new BehaviorSubject<CheckState>(this);
    this.modeViewTable = modeViewTable;
    this.allColumnSetting = allColumnSetting;
    this.dataSet = dataSet;
  }

  isChecked = (rowIndex: number, isConvertToPhysicalRow = true) => {
    if (isConvertToPhysicalRow) {
      const physicalRowIndex = this.hotInstance.toPhysicalRow(rowIndex);
      return this.checkedMaps[physicalRowIndex];
    } else {
      return this.checkedMaps[rowIndex];
    }
  };

  setChecked = (
    rowIndex: number,
    isChecked: boolean,
    isEmit: boolean,
    isConvertToPhysicalRow = true
  ) => {
    if (isConvertToPhysicalRow) {
      const physicalRowIndex = this.hotInstance.toPhysicalRow(rowIndex);
      this.checkedMaps[physicalRowIndex] = isChecked;
    } else {
      this.checkedMaps[rowIndex] = isChecked;
    }
    this.cachedCheckAllState = null;
    if (isEmit) {
      this._checkBoxObservable.next(this);
    }
  };

  checkBoxObservable() {
    return this._checkBoxObservable;
  }

  setCheckAll = (checked: boolean) => {
    const numberOfRow = this.getNumberOfRows(true);
    for (let i = 0; i < numberOfRow; ++i) {
      this.setChecked(i, checked, false, false);
    }
    this._checkBoxObservable.next(this);
  };

  getCheckedRows = (countAll?: boolean) => {
    const numberOfRow = this.getNumberOfRows(countAll);
    const checkedRows: number[] = [];

    for (let i = 0; i < numberOfRow; ++i) {
      if (this.isChecked(i, false)) {
        checkedRows.push(this.hotInstance.toVisualRow(i));
      }
    }

    return checkedRows;
  };

  getCheckedCount = () => {
    const rowIndexes = Object.keys(this.checkedMaps);
    let count = 0;
    for (let i = 0; i < rowIndexes.length; ++i) {
      if (this.isChecked(rowIndexes[i] as unknown as number, false)) {
        count++;
      }
    }

    return count;
  };

  getCheckAllState = () => {
    if (this.cachedCheckAllState) {
      return this.cachedCheckAllState;
    }
    const numberOfRows = this.getNumberOfRows();
    const checkRowCount = this.getCheckedCount();

    let result: CheckAllState;

    if ((checkRowCount === 0 && numberOfRows === 0) || checkRowCount === 0) {
      result = CheckAllState.NO_CHECK;
    } else if (checkRowCount >= numberOfRows) {
      result = CheckAllState.CHECK_ALL;
    } else {
      result = CheckAllState.CHECK_AT_LEAST_ONE;
    }

    this.cachedCheckAllState = result;
    return result;
  };

  clearCache = () => {
    this.cachedCheckAllState = null;
  };

  clearCheckedMaps = () => {
    this.checkedMaps = {};
    this._checkBoxObservable.next(this);
  };

  private getNumberOfRows = (countAll?: boolean) => {
    let rows = this.dataSet.length;
    if (countAll) {
      return rows - 1;
    }

    const isErrorViewTable =
      this.modeViewTable.getViewState() === ModeViewTableState.ERROR;
    const hasFilter = this.allColumnSetting.hasFilter();

    if (!isErrorViewTable && !hasFilter) {
      return rows - 1;
    }
    if (isErrorViewTable) {
      rows -= this.modeViewTable.getHiddenRows().length;
    }
    if (hasFilter) {
      rows -= this.allColumnSetting
        .getFilterStrategy()
        .getFilteredHideRowIndex().length;
    }

    return rows;
  };
}

export default CheckState;
