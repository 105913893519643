import { Accept, ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import { makeCancelable, PromiseCancelable } from '../core/promiseUtils';
import buildWorker from '../worker/importWorkerBundle.txt';
import { releaseProxy, wrap } from 'comlink';
import { createWorker } from 'core/worker/createWorker';
import {
  DataFile,
  MultipleSheetDataFile,
  ParseOption,
  STAGE,
  SheetDataFile,
} from '../modules/nuvo.parser.worker';
import { TFunction } from 'i18next';
import { ERROR_CODE_PARSE_FILE } from '../errors/errorCode';
import { InputType, SheetData, Value } from '../types';
import { isNil } from 'lodash';
import languageEncoding from 'detect-file-encoding-and-language';
import { supportedTypes } from './../core/constants/file';

export type ParserWorker = {
  convertXML2Sheet: (
    targetFile: FileWithPath,
    options: ParseOption
  ) => Promise<MultipleSheetDataFile>;
  convertCsv2Sheet: (
    text: string,
    options: ParseOption
  ) => Promise<SheetDataFile>;
  convertXLS2Sheet: (
    targetFile: FileWithPath,
    options: ParseOption
  ) => Promise<MultipleSheetDataFile>;
  convertExcel2Sheet: (
    targetFile: FileWithPath,
    options: ParseOption
  ) => Promise<MultipleSheetDataFile>;
  convertJson2Sheet: (
    targetFile: FileWithPath,
    options: ParseOption
  ) => Promise<SheetDataFile>;
  convertPdf2Sheets: (
    targetFile: FileWithPath,
    options: ParseOption
  ) => Promise<MultipleSheetDataFile>;
  jsonParser: (result: string, opt: ParseOption) => Promise<SheetData>;
  parseDateFormat: (values: Value[]) => Value[];
};

export const parseFilesToSheets = (
  files: FileWithPath[],
  options: ParseOption,
  setWorker?: (worker: Worker) => void
): PromiseCancelable<DataFile[]> => {
  const worker = createWorker(buildWorker);
  const workerInterface = wrap<ParserWorker>(worker);

  setWorker && setWorker(worker);

  return makeCancelable(
    Promise.all(
      files.map(async (targetFile) => {
        if (
          targetFile.type === supportedTypes.csv ||
          targetFile.type === supportedTypes.tsv_text ||
          targetFile.type === supportedTypes.tsv ||
          //NOTE: We do this because Chrome does not detect tsv MIME type in some versions
          targetFile.name.slice(-4) === supportedTypes.tsv
        ) {
          const text = await decodingFile(targetFile);
          return workerInterface.convertCsv2Sheet(text, {
            ...options,
            metaFile: {
              name: targetFile.name,
              type: targetFile.type,
              size: targetFile.size,
            },
          });
        } else if (targetFile.type === supportedTypes.xml) {
          return workerInterface.convertXML2Sheet(targetFile, options);
        } else if (targetFile.type === supportedTypes.json) {
          return workerInterface.convertJson2Sheet(targetFile, options);
        } else if (targetFile.type === supportedTypes.xls) {
          return workerInterface.convertXLS2Sheet(targetFile, options);
        } else if (targetFile.type === supportedTypes.pdf) {
          return workerInterface.convertPdf2Sheets(targetFile, {
            ...options,
            stage: STAGE,
          });
        } else {
          return workerInterface.convertExcel2Sheet(targetFile, options);
        }
      })
    ).finally(() => {
      workerInterface[releaseProxy]();
      worker.terminate();
    })
  );
};

const getErrorMessage = (errors: FileRejection[]) => {
  return errors[0]?.errors[1]?.message
    ? errors[0]?.errors[1]?.message
    : errors[0]?.errors[0]?.message;
};

const mappingErrorMessage: Record<string, string> = {
  'Too many files': 'txt_too_many_files_error',
};

export const getDescriptionTooManyFileError = (errorMessage: string) => {
  return mappingErrorMessage[errorMessage]
    ? mappingErrorMessage[errorMessage]
    : errorMessage;
};

export const getAcceptFileTypes = (acceptedInputTypes: InputType[]): Accept => {
  const accept: Record<string, []> = {};

  acceptedInputTypes.forEach((type) => {
    if (type === 'tsv') {
      accept[supportedTypes.tsv] = [];
      accept[supportedTypes.tsv_text] = [];
    } else {
      accept[supportedTypes[type] as InputType] = [];
    }
  });

  return accept;
};

export const getFileUploadTime = (targetFiles: FileWithPath[]) => {
  const baseTimeMS = 2.5;
  const baseFileSize = 2;
  let fileSize = 0;

  targetFiles.forEach((targetFile) => {
    fileSize =
      fileSize +
      Math.round(
        targetFile.type === supportedTypes.pdf
          ? 6
          : targetFile.type === supportedTypes.csv ||
            targetFile.type === supportedTypes.json
          ? 1
          : Math.max(targetFile.size) / 1000000
      );
  });
  const timeUpload = ((fileSize * baseTimeMS) / baseFileSize) * 10;

  return timeUpload;
};

export const handleFileRejection = (
  onFileSelectedError: (errorMessage: string, title?: string) => void,
  errors: FileRejection[],
  maxUploadFileSize: number,
  t: TFunction<'translation', undefined>,
  inputTypes: InputType[]
) => {
  const isFileTypeInvalid = errors[0].errors.find(
    (error) => error.code === ErrorCode.FileInvalidType
  );

  if (isFileTypeInvalid) {
    onFileSelectedError(
      t('txt_file_format_error', {
        fileTypes: inputTypes.join(', '),
      }),
      t('txt_title_upload_valid_file_error')
    );
    return;
  }

  const isFileTooLarge = errors[0].errors.find(
    (error) => error.code === ErrorCode.FileTooLarge
  );

  if (isFileTooLarge) {
    onFileSelectedError(
      t('txt_upload_exceed_max_size_error', {
        sizeInMb: maxUploadFileSize,
      }),
      t('txt_title_upload_exceed_max_size_error')
    );
    return;
  }

  const errorMessage = getErrorMessage(errors);

  onFileSelectedError(
    t(getDescriptionTooManyFileError(errorMessage)),
    t('txt_title_too_many_files_error')
  );
};

export const handleFileParseError = (
  onFileSelectedError: (errorMessage: string, title?: string) => void,
  errorCode: number,
  t: TFunction<'translation', undefined>,
  inputTypes: InputType[]
) => {
  if (errorCode === ERROR_CODE_PARSE_FILE.ADVANCED_PARSING_NOT_ALLOWED) {
    onFileSelectedError(t('txt_nested_file_error'), t('txt_import_error'));
  } else if (errorCode === ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT) {
    onFileSelectedError(
      t('txt_file_format_error', {
        fileTypes: inputTypes.join(', '),
      }),
      t('txt_title_error_invalid_format')
    );
  } else {
    onFileSelectedError(t('txt_file_error'), t('txt_import_error'));
  }
};

export const getDropzoneStyles = (
  isDragAccept: boolean,
  isDragReject: boolean
) => {
  return {
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  };
};
const baseStyle = {
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  borderColor: '#4B5563',
};

const rejectStyle = {
  borderColor: '#D0021B',
};

const decodingFile = (file: FileWithPath): Promise<string> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<string>(async (resolve) => {
    const text = await file.text();
    try {
      const detectInfo = await languageEncoding(file);
      const encoding = (detectInfo.encoding || 'ascii').toLowerCase();
      if (encoding !== 'utf-8' && !isNil(encoding)) {
        const arrayBuffer = await readArrayBuffer(file);
        const decodedText = new TextDecoder(encoding).decode(
          arrayBuffer as ArrayBuffer
        );
        resolve(decodedText);
      } else {
        resolve(text);
      }
    } catch (err) {
      resolve(text);
    }
  });
};

const readArrayBuffer = (file: FileWithPath) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e?.target?.result;
      resolve(arrayBuffer as ArrayBuffer);
    };

    reader.onerror = () =>
      reject({
        code: ERROR_CODE_PARSE_FILE.UNKNOWN,
      });
    reader.readAsArrayBuffer(file);
  });
