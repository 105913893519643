import { ValueItemData } from '../ValueList/type';

const useViewModel = ({
  data,
  index,
}: {
  data: ValueItemData;
  index: number;
}) => {
  const valueItem = data.valueItems[index];
  const checked = data.valuesSelected[valueItem.value] ?? false;
  const onChecked = (checked: boolean) => {
    data.onValuesSelected({
      ...data.valuesSelected,
      [valueItem.value]: checked,
    });
  };

  const showValue = valueItem.label;
  const value = valueItem.value;

  return { checked, onChecked, showValue, value };
};

export default useViewModel;
