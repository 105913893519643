import { useTheme } from '@nuvo-importer/common';
import { css, cx } from 'core/emotion';
import { ReactNode } from 'react';
import { IPopper } from '../popper';

type IContainerProps = {
  children: ReactNode;
  popper: IPopper;
};

const style = css`
  box-shadow: 0px 7px 15px rgba(27, 80, 113, 0.1);
  &[data-popper-reference-hidden='true'] {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
`;

const Container = ({ children, popper }: IContainerProps) => {
  const theme = useTheme();
  return (
    <div
      style={{ ...popper.styles.popper, width: 344 }}
      ref={popper.setPopperElement}
      className={cx(
        'rounded-medium z-30 text-left',
        css({
          backgroundColor: theme.getGlobalTheme().getDark50Color(),
        }),
        style
      )}
      {...popper.attributes.popper}
    >
      {children}
    </div>
  );
};

export default Container;
