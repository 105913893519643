import { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useScreenSize } from '../../core/constants/screensSize';

const useViewModel = ({ modal }: { modal?: boolean }) => {
  const [isShowInfo, setIsShowInfo] = useState(false);
  const { isXlargeScreen } = useScreenSize();

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowInfo(true),
        onMouseLeave: () => setIsShowInfo(false),
      };
    } else {
      return {
        onClick: () => setIsShowInfo((isShow) => !isShow),
        onMouseLeave: () => setIsShowInfo(false),
      };
    }
  };

  const isShowButton = isXlargeScreen && modal ? true : false;

  return {
    getOnHover,
    isShowInfo,
    setIsShowInfo,
    isShowButton,
  };
};

export default useViewModel;
