import {
  SHEET_SELECTION_SELECTED_SPREAD_SHEET,
  SHEET_SELECTION_SELECTING_SPREADSHEET_PAGE_INDEX,
  SHEET_SELECTION_VIEW_MODE,
  ViewTemplate,
} from 'core/constants/storage';

class SpreadSheetSelecting {
  private selectedSpreadSheet?: string;
  private selectingSpreadSheetPageIndex?: string;
  private sheetSelectionViewMode: ViewTemplate | undefined = ViewTemplate.GRID;

  static instance?: SpreadSheetSelecting;

  private constructor() {}

  static getInstance = () => {
    if (!this.instance) {
      this.instance = new SpreadSheetSelecting();
    }

    return this.instance;
  };

  setSelectedSpreadSheet = (index: number) => {
    try {
      window.sessionStorage.setItem(
        SHEET_SELECTION_SELECTED_SPREAD_SHEET,
        `${index}`
      );
    } catch (err) {
      this.selectedSpreadSheet = `${index}`;
    }
  };

  getSelectedSpreadSheet = () => {
    let selected: string | undefined | null;
    try {
      selected = window.sessionStorage.getItem(
        SHEET_SELECTION_SELECTED_SPREAD_SHEET
      );
    } catch (err) {
      selected = this.selectedSpreadSheet;
    }

    return Number(selected) || 0;
  };

  setSelectingSpreadSheetPageIndex = (index: number) => {
    try {
      window.sessionStorage.setItem(
        SHEET_SELECTION_SELECTING_SPREADSHEET_PAGE_INDEX,
        `${index}`
      );
    } catch (error) {
      this.selectingSpreadSheetPageIndex = `${index}`;
    }
  };

  getSelectingSpreadSheetPageIndex = () => {
    let selected: string | undefined | null;
    try {
      selected = window.sessionStorage.getItem(
        SHEET_SELECTION_SELECTING_SPREADSHEET_PAGE_INDEX
      );
    } catch (error) {
      selected = this.selectingSpreadSheetPageIndex;
    }

    return Number(selected) || 0;
  };

  setSheetSelectionViewMode = (viewTemplate: ViewTemplate) => {
    try {
      sessionStorage.setItem(SHEET_SELECTION_VIEW_MODE, viewTemplate);
    } catch (err) {
      this.sheetSelectionViewMode = viewTemplate;
    }
  };

  getSheetSelectionViewMode = () => {
    let viewTemplate: ViewTemplate;

    try {
      viewTemplate = (sessionStorage.getItem(SHEET_SELECTION_VIEW_MODE) ??
        ViewTemplate.GRID) as ViewTemplate;
    } catch (err) {
      viewTemplate = this.sheetSelectionViewMode ?? ViewTemplate.GRID;
    }

    return viewTemplate;
  };

  removeSheetSelectionViewMode = () => {
    try {
      window.sessionStorage.removeItem(SHEET_SELECTION_VIEW_MODE);
    } catch (err) {
      this.sheetSelectionViewMode = undefined;
    }
  };
}

export default SpreadSheetSelecting;
