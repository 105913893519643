import { css, cx } from '../../../../../../../core/emotion';
import { ReactNode, forwardRef, useMemo } from 'react';
import { ConfigTheme, Size } from '../type';

type MenuItemProps = {
  children: ReactNode;
  isHighlighted: boolean;
  size?: Size;
  configTheme?: ConfigTheme;
};

const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  ({ children, isHighlighted, size, configTheme, ...props }, ref) => {
    const menuItemStyle = useMemo(() => {
      return isHighlighted ? 'bg-gray-50' : '';
    }, [isHighlighted]);

    return (
      <div
        {...props}
        ref={ref}
        className={cx(
          'text-blue-dark-900 cursor-pointer px-4',
          menuItemStyle,
          size === 'small' ? 'py-2 text-xs' : 'py-3 text-sm',
          css({
            '&&': configTheme?.list?.item,
          })
        )}
      >
        {children}
      </div>
    );
  }
);

export default MenuItem;
