import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      min-height: 0;
      outline: none;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.root),
    }),
  ]);

  const headerClass = cx([
    'bg-white',
    css`
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      z-index: 1;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.header),
    }),
  ]);

  const headerBackClass = cx([
    'text-blue-light-500 text-xsmall',
    css`
      display: flex;
      align-items: center;
      gap: 8px;
    `,
    css({
      '&&': cx([
        css({
          color: context.theme.getGlobalTheme().getPrimaryColor(),
        }),
        css(cleaningAssistantTheme.dropdown?.headerBack),
      ]),
    }),
  ]);

  const headerTextClass = cx([
    'text-blue-dark-900 text-xsmall',
    css`
      text-align: left;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.headerText),
    }),
  ]);

  const headerTagClass = cx([
    'text-blue-light-500 bg-blue-light-50 text-xss',
    css`
      display: flex;
      align-items: center;
      text-align: left;
      padding: 0 12px;
      height: 24px;
      border-radius: 12px;
      margin-inline-start: 12px;
    `,
    css({
      '&&': cx([
        css({
          color: context.theme.getGlobalTheme().getPrimaryColor(),
          backgroundColor: context.theme.getGlobalTheme().getLight50Color(),
        }),
        css(cleaningAssistantTheme.dropdown?.headerTag),
      ]),
    }),
  ]);

  const headerActionsClass = cx([
    css`
      margin-inline-start: auto;
      display: flex;
      align-items: center;
      gap: 16px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.headerActions),
    }),
  ]);

  const headerActionRepeatClass = cx([
    'text-gray-560 h-4 w-4 cursor-pointer',
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.headerActionRepeat),
    }),
  ]);

  const headerActionCloseClass = cx([
    'text-gray-560 h-4 w-4 cursor-pointer',
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.headerActionClose),
    }),
  ]);

  const bodyClass = cx([
    'bg-gray-50',
    css`
      display: flex;
      flex-direction: column;
      flex: 1 auto;
      overflow: auto;
      padding: 12px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.body),
    }),
  ]);

  const footerClass = cx([
    'bg-white border-t-1 border-gray-120',
    css`
      position: sticky;
      bottom: 0;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      z-index: 1;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.footer),
    }),
  ]);

  const footerSelectedClass = cx([
    'text-gray-560',
    css`
      display: flex;
      align-items: center;
      gap: 4px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.footerSelected),
    }),
  ]);

  const footerActionsClass = cx([
    css`
      margin-inline-start: auto;
      display: flex;
      align-items: center;
      gap: 8px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.footerActions),
    }),
  ]);

  const commonButtonClass = cx([
    css`
      height: 24px;
      padding: 0 12px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      gap: 8px;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.15s ease-out;
      outline: none;
      user-select: none;
    `,
  ]);

  const footerDismissClass = cx([
    commonButtonClass,
    css(`border: 1px solid transparent`),
    'text-blue-dark-900 bg-transparent border-blue-dark-50 hover:bg-blue-dark-50 disabled:bg-gray-50 disabled:border-gray-260 disabled:text-gray-260',
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.footerDismiss),
    }),
  ]);

  const footerApplyClass = cx([
    commonButtonClass,
    'text-small',
    css({
      '&&': css(cleaningAssistantTheme.dropdown?.footerApply),
    }),
  ]);

  return {
    rootClass,
    headerClass,
    headerBackClass,
    headerTextClass,
    headerTagClass,
    headerActionsClass,
    headerActionRepeatClass,
    headerActionCloseClass,
    bodyClass,
    footerClass,
    footerSelectedClass,
    footerActionsClass,
    footerDismissClass,
    footerApplyClass,
  };
};
