import { ReactNode, useMemo } from 'react';
import { COLOR_SCROLL_BAR } from '../../../../core/constants/colors';
import { css, CSSObject, cx } from '../../../../core/emotion';
import { COLOR_TABLE, JoinSheetTableThemeAPI } from '@nuvo-importer/common/sdk';

type WrapperStyledProps = {
  children: ReactNode;
  theme?: JoinSheetTableThemeAPI;
  className?: string;
};

const WrapperStyled = ({ children, theme, className }: WrapperStyledProps) => {
  const header = useMemo(() => {
    return css`
      .join-sheet-preview .handsontable .header {
        ${theme?.th}
      }

      .join-sheet-preview .handsontable .htCore tr:first-child td {
        border-top-color: ${(theme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }

      .join-sheet-preview .handsontable .htCore tr:first-child th {
        border-top-color: ${(theme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }

      .join-sheet-preview .nuvo-join-column-badge {
        ${theme?.badge}
      }
    `;
  }, [theme]);

  const body = useMemo(() => {
    return css`
      .join-sheet-preview .ht_master .wtHider .htCore tbody .default-cell {
        ${theme?.td}
      }

      .join-sheet-preview .ht_clone_left .wtHider .htCore tbody .default-cell {
        ${theme?.td}
      }
    `;
  }, [theme]);

  const scrollbar = useMemo(() => {
    return css`
      .join-sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-thumb {
        background-color: ${theme?.scrollbar?.navigatorColor};
      }

      .join-sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-track {
        background-color: ${theme?.scrollbar?.backgroundColor};
      }

      scrollbar-color: ${theme?.scrollbar?.navigatorColor
          ? theme?.scrollbar?.navigatorColor
          : COLOR_SCROLL_BAR.NAVIGATOR}
        ${theme?.scrollbar?.backgroundColor
          ? theme?.scrollbar?.backgroundColor
          : COLOR_SCROLL_BAR.BACKGROUND};
    `;
  }, [theme]);

  return (
    <div className={cx(header, body, scrollbar, className)}>{children}</div>
  );
};

export default WrapperStyled;
