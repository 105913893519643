import { RefObject, useEffect, useMemo, useState } from 'react';
import CategoryDataModel from '../../../dataModel/model/CategoryDataModel';
import { Subject } from 'rxjs';
import { CustomOptionUIObservable } from '../customOption';
import { HotTableClass } from '@handsontable/react';

const useOptionViewModel = ({
  customOptionUIObservable,
  hotInstance,
}: {
  customOptionUIObservable: Subject<CustomOptionUIObservable>;
  hotInstance: RefObject<HotTableClass>;
}) => {
  const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);
  const [addingOptionDataModel, setAddingOptionDataModel] =
    useState<CategoryDataModel | null>(null);

  useEffect(() => {
    if (isAddOptionOpen) {
      hotInstance.current?.hotInstance?.unlisten();
    } else {
      hotInstance.current?.hotInstance?.listen();
    }
  }, [hotInstance, isAddOptionOpen]);

  useEffect(() => {
    const subscription = customOptionUIObservable.subscribe((data) => {
      if (data.isOpen) {
        setAddingOptionDataModel(data.dataModel);
      } else {
        setAddingOptionDataModel(null);
      }
      setIsAddOptionOpen(data.isOpen);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [customOptionUIObservable]);

  const onAddOptionClose = () => {
    setIsAddOptionOpen(false);
    setAddingOptionDataModel(null);
  };

  const optionInitialValues = useMemo(() => {
    return {
      optionType: 'string' as const,
      optionName: '',
    };
  }, []);

  return {
    isAddOptionOpen,
    onAddOptionClose,
    addingOptionDataModel,
    optionInitialValues,
  };
};

export default useOptionViewModel;
