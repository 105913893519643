import { ResultValue } from '../../../../../value';

export interface ICleaningAssistantRequestDTO {
  input_data: string;
  data_model: string;
}

export interface ICleaningAssistantRequest {
  value: ResultValue;
  info?: ICleaningAssistantRecordInfo[];
}

export interface ICleaningAssistantRecordInfo {
  message: string;
  level: string;
}

export interface ICleaningAssistantPayloadRow {
  rowIndex: number;
  data: Record<string, ICleaningAssistantRequest>;
}

export type ICleaningAssistantPayload = ICleaningAssistantPayloadRow[];

export interface ICleaningAssistantBaseSuggestion {
  value: ResultValue;
  suggestion: string | number;
}

export interface ICleaningAssistantRowSuggestion {
  rowIndex: number;
  data: Record<string, ICleaningAssistantBaseSuggestion>;
}

export type ICleaningAssistantResponse = ICleaningAssistantRowSuggestion[];

export interface ICleaningAssistantGroup {
  column: string;
  suggestions: ICleaningAssistantSuggestion[];
}

export interface ICleaningAssistantSuggestion {
  id: string;
  rowIndex: number;
  colIndex: number;
  column: string;
  value: ResultValue;
  suggestion: number | string;
}

export type CleaningLogsRecord = {
  // id is a UI-only field
  id?: string;
  key: string;
  value: ResultValue;
  error_message: string;
  suggested_value: string | number;
  status: CleaningStatus;
};

export enum CleaningStatus {
  Applied = 'applied',
  Ignored = 'ignored',
}
