import { ColumnAPI } from 'lib-nuvo-react';

const dropdownOptions = new Array(1000).fill('').map((_, index) => {
  if (index % 5 === 0) {
    return {
      label: index,
      value: index,
      type: 'string',
      validations: [
        {
          validate: {
            NEQ: {
              continent: ['asia', 'europe', 'south_america', 'africa'],
            },
            OR: {
              GTE: { populations: 500001 },
              LTE: { populations: 600001 },
            },
          },
        },
      ],
    };
  } else if (index % 4 === 0) {
    return {
      label: index,
      value: index,
      type: 'string',
      validations: [
        {
          validate: {
            EQ: {
              continent: 'asia',
            },
            GTE: {
              populations: 500000,
            },
          },
        },
      ],
    };
  } else if (index % 3 === 0) {
    return {
      label: index,
      value: index,
      type: 'string',
      validations: [
        {
          validate: {
            EQ: {
              continent: 'europe',
            },
            GTE: {
              populations: 400000,
            },
          },
        },
      ],
    };
  } else if (index % 2 === 0) {
    return {
      label: index,
      value: index,
      type: 'string',
      validations: [
        {
          validate: {
            EQ: {
              continent: 'africa',
            },
            GTE: {
              populations: 300000,
            },
          },
        },
      ],
    };
  } else {
    return {
      label: index,
      value: index,
      type: 'string',
      validations: [
        {
          validate: {
            EQ: {
              continent: 'south_america',
            },
            GTE: {
              populations: 200000,
            },
          },
        },
      ],
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any;

export const multiDropdownOptionsTDM: ColumnAPI[] = [
  {
    key: 'populations',
    label: 'Populations',
    columnType: 'int',
  },
  {
    key: 'continent',
    label: 'Continent',
    columnType: 'category',
    isMultiSelect: true,
    dropdownOptions: [
      {
        type: 'string',
        label: 'Asia',
        value: 'asia',
      },
      {
        type: 'string',
        label: 'Europe',
        value: 'europe',
      },
      {
        type: 'string',
        label: 'Africa',
        value: 'africa',
      },
      {
        type: 'string',
        label: 'North America',
        value: 'north_america',
      },
      {
        type: 'string',
        label: 'South America',
        value: 'south_america',
      },
    ],
  },
  {
    key: 'country_code',
    label: 'Country code',
    columnType: 'category',
    dropdownOptions: dropdownOptions,
  },
];
