import React, { FC } from 'react';
import { ICleaningAssistantProps } from './index.types';
import { useViewModel } from './useViewModel';
import { CleaningAssistantContext } from './context';
import styles from './index.style';
import { Popover } from '@getnuvo/ui-kit';
import Toggle from './components/Toggle';
import Dropdown from './components/Dropdown';

export const CleaningAssistant: FC<ICleaningAssistantProps> = (
  props: ICleaningAssistantProps
) => {
  const context = useViewModel(props);
  const { open, setOpen } = context;
  const s = styles(context);

  return (
    <CleaningAssistantContext.Provider value={context}>
      <Popover
        className={s.rootClass}
        open={open}
        setOpen={setOpen}
        offset={17}
        placement="bottom-end"
        minWidth="320px"
        maxHeight="360px"
        content={<Dropdown />}
      >
        <Toggle />
      </Popover>
    </CleaningAssistantContext.Provider>
  );
};
