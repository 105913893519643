import { ColumnAPI } from '../../dataModel/columnsAPI';
import { isArray, isBoolean } from 'lodash';

/**
 * Compare any value against a regex string
 *
 * @param regexString
 * @param value
 * @param flags
 * @param fallback
 */
export function testRegex(
  regexString: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): boolean {
  if (isEmpty(value)) {
    return true;
  }

  const regex = new RegExp(regexString);
  return regex.test(value ?? '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(value: any): boolean {
  return (
    value === '' ||
    value === undefined ||
    value === null ||
    (isArray(value) && value.length === 0)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseValue = (value: any) => {
  if (isBoolean(value)) {
    return `${value}`;
  }
  return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRowValue(row: any, key: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const col = row.find((col: any) => col.key === key);
  return col?.value;
}

export function someFields(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expectedFields: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rule: (expected: any, value: any, fieldKey: string) => boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any
) {
  return Object.keys(expectedFields).some((fieldKey) => {
    const expectedValues = expectedFields[fieldKey];
    return rule(
      isArray(expectedValues)
        ? expectedValues.map(parseValue)
        : parseValue(expectedValues),
      getRowValue(row, fieldKey),
      fieldKey
    );
  });
}

export function findIndexCol(columns: ColumnAPI[], key: string) {
  return columns.findIndex((item) => item.key === key);
}

export const getColumnByKey = (key: string, columns: ColumnAPI[]) => {
  return columns.find((col) => col.key === key);
};
