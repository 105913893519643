import { DataModel } from '../../core/dataModel/model/DataModel';
import { useMemo } from 'react';

const useViewModel = ({
  dataModels,
  matchedDataModels,
}: {
  dataModels: DataModel[];
  matchedDataModels: DataModel[];
}) => {
  const requiredDataModels = useMemo(() => {
    return dataModels.filter((dataModel) => dataModel.getIsRequired());
  }, [dataModels]);

  const columns = useMemo(() => {
    return requiredDataModels.map((requiredDataModel) => {
      const isDataModelMatched = matchedDataModels.some((dataModel) => {
        return dataModel.getKey() === requiredDataModel.getKey();
      });
      return {
        label: requiredDataModel.getLabel(),
        matched: isDataModelMatched,
      };
    });
  }, [requiredDataModels, matchedDataModels]);

  const requiredCount = columns.reduce((count, column) => {
    if (!column.matched) {
      return count + 1;
    }
    return count;
  }, 0);

  return { columns, requiredCount };
};

export default useViewModel;
