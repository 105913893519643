import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { GridSettings } from 'handsontable/settings';
import { isNumber } from 'lodash';
import { compareFunctionFactory as defaultCompareFunctionFactory } from './default';
import { parseNumberStringToNumber } from '../../../../number';
import { FIRST_AFTER_SECOND, FIRST_BEFORE_SECOND } from './constant';
import Handsontable from 'handsontable';

const parseValue = (value: string) => {
  let sourceValue = value;

  if (Handsontable.helper.isEmpty(value)) {
    return { parsedValue: value, isValid: false };
  }

  if (typeof sourceValue === 'string') {
    sourceValue = sourceValue.replace(/EUR|\$|€|USD/g, '');
  }

  const valueNumber = isNumber(sourceValue)
    ? sourceValue
    : parseNumberStringToNumber(`${sourceValue}`, true);
  let target: string | number;

  const isInvalid = isNaN(valueNumber);

  if (isInvalid) {
    target = `${value}`;
  } else {
    target = valueNumber;
  }

  return { parsedValue: target, isValid: !isInvalid };
};

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: any, nextValue: any) => {
    const { parsedValue, isValid: isValueValid } = parseValue(value);
    const { parsedValue: parsedNextValue, isValid: isNextValueValid } =
      parseValue(nextValue);

    if (isValueValid && !isNextValueValid) {
      return FIRST_BEFORE_SECOND;
    } else if (!isValueValid && isNextValueValid) {
      return FIRST_AFTER_SECOND;
    } else {
      return defaultCompareFunctionFactory(sortOrder, columnMeta)(
        parsedValue,
        parsedNextValue
      );
    }
  };
}
