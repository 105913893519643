import { cx, css, CSSInterpolation } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme/theme';

type TabMenuMultipleItemProps = {
  name: string;
  selected: boolean;
  onSelect: (index: number) => void;
  index: number;
  selectedAmount: number;
  configTheme?: {
    item?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
    selectedItem?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
  };
};

export const TabMenuMultipleHeader = ({
  configTheme,
  name,
  selected,
  onSelect,
  index,
  selectedAmount,
}: TabMenuMultipleItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return selected ? (
    <div
      className={cx(
        'rounded-medium min-w-240 relative mr-0 max-h-60 w-full cursor-pointer overflow-hidden bg-white p-2.5 pl-3.5 md:mb-0',
        css({ '&&': configTheme?.selectedItem?.root }),
        selectedAmount ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
      )}
    >
      <div
        className={cx(
          'absolute left-0 top-0 bottom-0 h-full w-1',
          css({
            backgroundColor: theme.getGlobalTheme().getPrimaryColor(),
          }),
          css({ '&&': configTheme?.selectedItem?.border })
        )}
      />
      <div className="truncate text-left">
        <p
          className={cx(
            'max-w-2xs text-color-primary mb-1 truncate text-base font-semibold',
            css({ '&&': configTheme?.selectedItem?.title })
          )}
        >
          {name}
        </p>
        <p
          className={cx(
            'text-color-secondary text-sm',
            css({ '&&': configTheme?.selectedItem?.description })
          )}
        >
          {t(
            selectedAmount > 1
              ? 'txt_sheet_selection_multiple_desc'
              : 'txt_sheet_selection_one_desc',
            {
              amount: selectedAmount,
            }
          )}
        </p>
      </div>
    </div>
  ) : (
    <div
      className={cx(
        'rounded-medium min-w-240 relative mr-0 max-h-60 w-full overflow-hidden bg-gray-50 p-2.5 pl-3.5 md:mb-0',
        css({ '&&': configTheme?.item?.root }),
        selectedAmount ? 'cursor-pointer' : 'opacity-50'
      )}
      onClick={() => (selectedAmount ? onSelect(index) : () => null)}
    >
      <div
        className={cx(
          'absolute left-0 top-0 bottom-0 h-full w-1 bg-transparent',
          css({ '&&': configTheme?.item?.border })
        )}
      />
      <div className="truncate text-left">
        <p
          className={cx(
            'max-w-2xs text-color-primary mb-1 truncate text-base',
            css({ '&&': configTheme?.item?.title })
          )}
        >
          {name}
        </p>
        <p
          className={cx(
            'text-color-secondary text-sm',
            css({ '&&': configTheme?.item?.description })
          )}
        >
          {t(
            selectedAmount > 1
              ? 'txt_sheet_selection_multiple_desc'
              : 'txt_sheet_selection_one_desc',
            {
              amount: selectedAmount,
            }
          )}
        </p>
      </div>
    </div>
  );
};
