import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints, breakpointsNumber } from './breakpoints';

export const useScreenSize = () => {
  const minWidthXlarge = useMediaQuery({
    query: `(min-width: ${breakpoints.xlarge})`,
  });

  const minWidthSlarge = useMediaQuery({
    query: `(min-width: ${breakpointsNumber.slarge + 1}px)`,
  });

  const minWidthSxlarge = useMediaQuery({
    query: `(min-width: ${breakpoints.sxlarge})`,
  });

  const maxWidth3xl = useMediaQuery({
    query: `(max-width: ${breakpoints['3xl']})`,
  });

  const minWidthLg = useMediaQuery({
    query: `(min-width: ${breakpoints.lg})`,
  });

  const isXlargeScreen = useMemo(() => minWidthXlarge, [minWidthXlarge]);

  const isNotSmallScreen = useMemo(() => minWidthSlarge, [minWidthSlarge]);

  const isSxlargeScreen = useMemo(() => minWidthSxlarge, [minWidthSxlarge]);

  const isLgScreen = useMemo(() => minWidthLg, [minWidthLg]);

  return {
    isXlargeScreen,
    isNotSmallScreen,
    isSxlargeScreen,
    isLgScreen,
    maxWidth3xl,
  };
};
