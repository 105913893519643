import { ReactComponent as TitleView } from '../../assets/icon/title-view.svg';
import { ReactComponent as TitleViewActive } from '../../assets/icon/title-view-active.svg';
import { ReactComponent as ListView } from '../../assets/icon/list-view.svg';
import { ReactComponent as ListViewActive } from '../../assets/icon/list-view-active.svg';
import { ViewTemplate } from 'core/constants/storage';
import { useMainView, useSettings } from 'settings';
import { useTranslation } from 'react-i18next';
import TextInfo from 'baseUI/TextInfo';
import { useTheme } from 'theme';
import { css, cx } from 'core/emotion';

const TopSections = ({
  selectViewTemplate,
  onSelectViewTemplate,
  size,
  fileSize,
}: {
  onSelectViewTemplate: (type: ViewTemplate) => void;
  selectViewTemplate: ViewTemplate;
  size: number;
  fileSize: number;
}) => {
  const { t } = useTranslation();
  const { maxEntries, multipleFileUpload } = useSettings();
  const theme = useTheme();

  const baseStyled = cx(
    'cursor-pointer',
    css({
      '&& > path': theme.getSheetSelectTheme().icon,
    })
  );
  const { modal } = useMainView();

  return (
    <div
      className={cx(
        'mb-6 flex w-full justify-between',
        modal ? 'md:px-3 lg:!px-0' : 'px-6'
      )}
    >
      <TextInfo
        identifier="sheet-selection"
        textColor={cx(
          'text-color-secondary',
          css({
            '&&': theme.getSheetSelectTheme().header,
          })
        )}
        text={
          maxEntries
            ? t('txt_sheet_selection_title', {
                maxEntries: maxEntries,
              })
            : multipleFileUpload
            ? t(
                size > 1
                  ? 'txt_size_multi_import_other'
                  : 'txt_size_multi_import_one',
                {
                  sheet: size,
                  file: fileSize,
                }
              )
            : t(size > 1 ? 'txt_size_import_other' : 'txt_size_import_one', {
                count: size,
              })
        }
        isShowIcon={!!maxEntries ?? false}
      />
      <div className="mt-px flex space-x-2">
        <div>
          {selectViewTemplate === ViewTemplate.GRID ? (
            <TitleViewActive className={baseStyled} />
          ) : (
            <TitleView
              className={baseStyled}
              onClick={() => onSelectViewTemplate(ViewTemplate.GRID)}
            />
          )}
        </div>
        <div>
          {selectViewTemplate === ViewTemplate.LIST ? (
            <ListViewActive className={baseStyled} />
          ) : (
            <ListView
              className={baseStyled}
              onClick={() => onSelectViewTemplate(ViewTemplate.LIST)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopSections;
