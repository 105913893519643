import { isNil } from 'lodash';
import { Value } from './sheet/Sheet';

const regexSplitMultiGroupValues = /[,;|](?=['"`])/g;
const regexMatchMultiValues = /[;]|[|]|,[ a-zA-Z]/g;

export const checkIsMultipleValues = (value?: Value) => {
  if (isNil(value)) return false;

  const isMultiGroupValue = `${value}`.match(regexSplitMultiGroupValues);

  if (isMultiGroupValue) return true;

  return Boolean(`${value}`.match(regexMatchMultiValues));
};

export const separateMultipleValues = (value?: Value) => {
  if (isNil(value)) return [];

  let values = `${value}`.split(regexSplitMultiGroupValues);
  if (values.length === 1) {
    values = [];
    const tempValues = `${value}`.split(/[;]|[|]|, /g);
    for (let i = 0; i < tempValues.length; i++) {
      const currentValue = tempValues[i];
      if (/,[a-zA-Z]/.test(currentValue)) {
        values = values.concat(currentValue.split(','));
      } else {
        values.push(currentValue);
      }
    }
  }

  return values.map((item) => item.replace(/[\s\-_&+'"`]/g, ' ').trim());
};
