export const breakpointsNumber = {
  xss: 375,
  xs: 412,
  xsmd: 468,
  xssmd: 740,
  smmd: 820,
  smd: 885,
  slarge: 900,
  large: 990,
  lg: 1024,
  mmd: 1190,
  sxlarge: 1400,
  xlarge: 1910,
  '3xl': 1440,
  '4xl': 1920,
};

export const breakpoints = {
  xss: `${breakpointsNumber.xss}px`,
  xs: `${breakpointsNumber.xs}px`,
  xsmd: `${breakpointsNumber.xsmd}px`,
  xssmd: `${breakpointsNumber.xssmd}px`,
  smmd: `${breakpointsNumber.smmd}px`,
  smd: `${breakpointsNumber.smd}px`,
  slarge: `${breakpointsNumber.slarge}px`,
  large: `${breakpointsNumber.large}px`,
  lg: `${breakpointsNumber.lg}px`,
  mmd: `${breakpointsNumber.mmd}px`,
  sxlarge: `${breakpointsNumber.sxlarge}px`,
  xlarge: `${breakpointsNumber.xlarge}px`,
  '3xl': `${breakpointsNumber['3xl']}px`,
  '4xl': `${breakpointsNumber['4xl']}px`,
};
