export interface PromiseCancelable<T> {
  promise: Promise<T>;
  cancel(): void;
}

/**
 * It takes a promise and returns a new promise that can be cancelled
 * @param promise - Promise<T>
 * @returns A promise that can be cancelled.
 */
export const makeCancelable = <T>(
  promise: Promise<T>
): PromiseCancelable<T> => {
  let cancelPromise: () => void;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    cancelPromise = () => {
      reject({ isCancelled: true });
    };

    promise.then((val) => resolve(val)).catch((err) => reject(err));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      cancelPromise();
    },
  };
};
