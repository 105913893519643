import { ColumnType, ColumnTypeValue } from '../../dataModel/columnsAPI';
import { IEventStack } from '../../tracking/Tracking';
import { InputType } from '../../types';
import { CleaningLogsRecord } from '@nuvo-importer/common/core';

export type CompleteImportLog = {
  data_model: DataModelLog[];
  mappings: MappingsLog[];
  header_row: string[];
  total_row: number;
  config: ConfigLog;
  version: string;
  framework: string;
  domain: string;
  framework_version: string;
  session_id: string;
  analytics: AnalyticsLog;
};

export type IUploadFileLog = {
  fileSize: number;
  fileType: string;
};

export type AnalyticsLog = {
  time_tracking: IEventStack[];
  uploaded_files: IUploadFileLog[];
};

export type DataModelLog = {
  columnType: ColumnTypeValue | ColumnType;
  key: string;
  label: string;
  validations: string[];
  alternativeMatches: string[];
  isMultiselect: boolean;
  outputFormat: string;
};

export type MappingsLog = {
  column: string;
  user_select_input: string | undefined;
  suggestion_result: string | undefined;
};

export type ConfigLog = {
  identifier: string;
  enable_examples: boolean;
  max_entries: number;
  modal: boolean;
  title: string;
  disable_templates: boolean;
  enable_massive_error_alert: number;
  automatic_header_detection: boolean;
  embed_upload_area: boolean;
  disable_success_modal: boolean;
  allow_manual_input: boolean;
  only_mapped_columns: boolean;
  complete_import_action: string;
  allow_custom_columns: boolean;
  allow_custom_options: boolean;
  disable_excel_template: boolean;
  button_mode: string;
  language: string;
  processing_engine: string;
  column_hooks_usage: boolean;
  data_handler_header_step_usage: boolean;
  data_handler_review_step_usage: boolean;
  i18n_usage: boolean;
  on_entry_change_usage: boolean;
  on_entry_init_usage: boolean;
  preload_data_usage: boolean;
  style_usage: StylesLog;
  conditional_rendering_usage: boolean;
  dynamic_import_usage: boolean;
  multiple_file_upload: boolean;
  automatic_mapping: boolean;
  number_format: NumberFormatLog;
  option_mapping_mode: OptionMappingMode;
  smart_table: boolean;
  cleaning_assistant: boolean;
  input_types: InputType[];
};

export type StylesLog = {
  globals: boolean;
  root: boolean;
  buttons: boolean;
  progressBar: boolean;
  header: boolean;
  dropzone: boolean;
  footer: boolean;
  sheetSelect: boolean;
  headerSelect: boolean;
  joinSheet: boolean;
  columnMatch: boolean;
  reviewEntries: boolean;
  popover: boolean;
  dialog: boolean;
  messagePopup: boolean;
  popoverInfo: boolean;
  loader: boolean;
};

export type NumberFormatLog = {
  eu: number;
  us: number;
};

export type OptionMappingMode = {
  smart: number;
  exact: number;
};

export const optionMappingModeColumnTypes = [`${ColumnTypeValue.CATEGORY}`];

export const numberFormatColumnTypes = [
  `${ColumnTypeValue.FLOAT}`,
  `${ColumnTypeValue.INT}`,
  `${ColumnTypeValue.PERCENTAGE}`,
];

export type CompleteCleaningLogsDTO = {
  cleanings: CleaningLogsRecord[];
  session_id: string;
};
