import {
  setColumnWidthAutoFit,
  setDataValidation,
  setHeaderStyle,
} from 'core/xlsxUtil';
import { DataModel } from 'dataModel';
import { Workbook } from 'nuvo-exceljs';
import { saveAs } from 'file-saver';
import { createOptionSheet } from '@nuvo-importer/common/core';

const getContentDataModelXLSX = (dataModels: DataModel[]) => {
  const headers = dataModels.map((item) => {
    let mark = '';
    /* istanbul ignore if */
    if (item.getIsUniqueAndRequired()) {
      mark = 'REQUIRED/UNIQUE';
    } else {
      if (item.getIsRequired()) {
        mark = 'REQUIRED';
      } else if (item.getIsUnique()) {
        mark = 'UNIQUE';
      }
    }
    return {
      header: item.getLabel(),
      key: item.getLabel(),
      width: 60,
      description: item.getDescription(),
      mark,
    };
  });

  return {
    headers,
  };
};

const exportToXlsx = async (fileName: string, dataModels: DataModel[]) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('sheet-1');
    const { headers } = getContentDataModelXLSX(dataModels);
    sheet.columns = headers;
    setHeaderStyle(sheet, headers, true);
    setColumnWidthAutoFit(sheet);
    createOptionSheet(workbook, dataModels);
    setDataValidation(sheet, dataModels, 2, 100);
    const _data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${fileName}.xlsx`);
    resolve(null);
  });
};

export default exportToXlsx;
