import { css, cx } from 'core/emotion';
import Button from 'baseUI/Button';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { useLicenseKeyAuth } from 'license';

const UploadButton = ({
  className,
  isModalView,
  onLaunch,
}: {
  className?: string;
  isModalView?: boolean;
  onLaunch: () => void;
}) => {
  const { t } = useTranslation();
  const { multipleFileUpload, modal, embedUploadArea } = useSettings();
  const { isLoading } = useLicenseKeyAuth();

  const getTextButton = () => {
    return multipleFileUpload
      ? t('txt_allow_multiple_select_files')
      : t('txt_select_files');
  };

  const isImportButton =
    modal === true &&
    (embedUploadArea || false) === false &&
    isModalView === true;

  return (
    <div className="mt-6 flex items-center justify-center md:mt-0">
      <Button
        variant="import"
        onClick={onLaunch}
        loading={isLoading}
        disabled={isLoading}
        className={cx(
          className,
          css`
            min-width: 182px;
          `,
          'h-auto',
          modal && !embedUploadArea ? 'w-auto' : 'w-full'
        )}
      >
        <div>{isImportButton ? t('txt_import_data') : getTextButton()}</div>
      </Button>
    </div>
  );
};

export default UploadButton;
