import { Variant } from '../types';
import { cx, css } from 'core/emotion';
import { useTheme } from 'theme';

export const useVariant = ({
  variant,
  open,
}: {
  variant: Variant;
  open: boolean;
}) => {
  const theme = useTheme();

  const getVariantClassname = () => {
    if (variant === 'primary' && open) {
      return 'text-primary border-primary';
    } else if (variant === 'danger' && !open) {
      return 'text-red-500 bg-danger-light border-danger hover:opacity-100 focus:border-danger';
    } else if (variant === 'danger') {
      return 'focus:border-danger';
    } else if (variant === 'warning' && !open) {
      return 'text-yellow-800 bg-yellow-200 border-yellow-800 hover:border-yellow-700 focus:border-yellow-800';
    } else if (variant === 'warning') {
      return 'focus:border-yellow-800';
    } else {
      return '';
    }
  };

  const getOpenClassname = () => {
    if (open) {
      return cx(
        'focus:shadow-outline-black',
        css({
          borderColor: theme.getGlobalTheme().getDark600Color(),
          ':focus': {
            borderColor: theme.getGlobalTheme().getPrimaryColor(),
          },
        })
      );
    } else {
      return css({
        borderColor: theme.getGlobalTheme().getDark900Color(),
      });
    }
  };

  return {
    getVariantClassname,
    getOpenClassname,
  };
};
