import { css, cx } from 'core/emotion';
import { COLOR } from 'core/constants/colors';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/icon/arrow.svg';
import { Variant } from '../types';

const openStyle = css`
  svg {
    transform: rotate(180deg);
  }
`;

const closeStyle = css`
  svg {
    transform: rotate(0deg);
  }
`;

const dangerStyle = css`
  svg path {
    fill: ${COLOR.ERROR};
  }
`;

const ArrowDown = ({ variant, open }: { variant: Variant; open: boolean }) => {
  return (
    <div
      className={cx(
        'absolute top-1/2 right-3 -translate-y-1/2 transform',
        open ? openStyle : closeStyle,
        variant === 'danger' ? dangerStyle : ''
      )}
    >
      <ArrowIcon className="block" aria-hidden="true" />
    </div>
  );
};

export default ArrowDown;
