import { ReactNode } from 'react';
import { cx } from '../../../core/emotion';

type ErrorMessageProps = {
  className?: string;
  children: ReactNode;
};

export const ErrorMessage = ({ className, children }: ErrorMessageProps) => {
  return (
    <div
      className={cx(
        className,
        'text-salmon-500 text-small mt-1 whitespace-pre-line font-light'
      )}
    >
      {children}
    </div>
  );
};
