export { COLOR_SCROLL_BAR, COLOR } from '@nuvo-importer/common/sdk';

export enum COLOR_JOIN_SHEET {
  SUCCESS_BG = '#ECFDF5',
  SUCCESS_TEXT = '#10B981',
  WARNING_BG = '#FFFBF0',
  WARNING_TEXT = '#FFC116',
  ERROR_BG = '#FEF8F8',
  ERROR_TEXT = '#ED7070',
  GRAY = '#E1E1E1',
}
