import { SortOrderType } from 'handsontable/plugins/columnSorting';
import {
  DO_NOT_SWAP,
  FIRST_BEFORE_SECOND,
  FIRST_AFTER_SECOND,
} from './constant';
import { GridSettings } from 'handsontable/settings';
import Handsontable from 'handsontable';

// NOTE: reference sort function from handsontable
// https://github.com/handsontable/handsontable/blob/develop/handsontable/src/plugins/columnSorting/sortFunction/default.js

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings
) {
  const locale = columnMeta.locale;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (value: any, nextValue: any) {
    if (typeof value === 'string') {
      value = value.toLocaleLowerCase(locale);
    }

    if (typeof nextValue === 'string') {
      nextValue = nextValue.toLocaleLowerCase(locale);
    }

    if (value === nextValue) {
      return DO_NOT_SWAP;
    }

    if (Handsontable.helper.isEmpty(value)) {
      if (Handsontable.helper.isEmpty(nextValue)) {
        return DO_NOT_SWAP;
      }

      return FIRST_AFTER_SECOND;
    }

    if (Handsontable.helper.isEmpty(nextValue)) {
      return FIRST_BEFORE_SECOND;
    }

    if (isNaN(value) && !isNaN(nextValue)) {
      return sortOrder === 'asc' ? FIRST_AFTER_SECOND : FIRST_BEFORE_SECOND;
    } else if (!isNaN(value) && isNaN(nextValue)) {
      return sortOrder === 'asc' ? FIRST_BEFORE_SECOND : FIRST_AFTER_SECOND;
    } else if (!(isNaN(value) || isNaN(nextValue))) {
      value = parseFloat(value);
      nextValue = parseFloat(nextValue);
    }

    if (value < nextValue) {
      return sortOrder === 'asc' ? FIRST_BEFORE_SECOND : FIRST_AFTER_SECOND;
    }

    if (value > nextValue) {
      return sortOrder === 'asc' ? FIRST_AFTER_SECOND : FIRST_BEFORE_SECOND;
    }

    return DO_NOT_SWAP;
  };
}
