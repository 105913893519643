import { CSSProperties, ReactNode, useMemo } from 'react';
import Checkbox from '../../../core/dataModelSheet/DataModelSheetForm/ContextMenu/components/Checkbox';
import { css, cx } from '../../../core/emotion';
import { ConfigTheme } from '../type';

type MenuItemProps = {
  isHighlighted: boolean;
  children: ReactNode;
  onClick?: () => void;
  onMouseOver?: () => void;
  isSelected: boolean;
  indeterminate?: boolean;
  configTheme?: ConfigTheme;
  className?: string;
};

const MenuItem = ({
  isHighlighted,
  children,
  isSelected: selected,
  onClick,
  onMouseOver,
  indeterminate,
  configTheme,
  className,
}: MenuItemProps) => {
  const menuItemStyle = useMemo(() => {
    return isHighlighted ? 'bg-gray-50' : '';
  }, [isHighlighted]);
  return (
    <div
      className={cx(
        'text-blue-dark-900 cursor-pointer px-3 py-1 text-xs',
        menuItemStyle,
        css({ '&&': configTheme?.list?.item }),
        selected ? css({ '&&': configTheme?.list?.selectedItem }) : '',
        className
      )}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <Checkbox
        tickColor={(configTheme?.list?.checkbox as CSSProperties)?.['color']}
        className={cx(
          '!mr-2',
          css({ '&&.nuvo-checkbox': configTheme?.list?.checkbox })
        )}
        checked={selected}
        indeterminate={indeterminate}
      />
      <span>{children}</span>
    </div>
  );
};

export default MenuItem;
