import { COLOR_SCROLL_BAR } from 'core/constants/colors';
import { css, CSSInterpolation, cx } from 'core/emotion';
import { ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { ViewTemplate } from 'core/constants/storage';
import { TabMenuMultipleHeader } from './TabMenuMultipleHeader';
import { TabMenuMultipleItem } from './TabMenuMultipleItem';

type TabMenuMultipleProps = {
  names: string[];
  sizes: number[];
  subtitle: () => ReactNode;
  configTheme?: {
    root?: CSSInterpolation;
    item?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
    selectedItem?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
    scrollbar?: {
      navigatorColor?: string | undefined;
      backgroundColor?: string | undefined;
    };
  };
  bottomAction?: ReactNode;
  onSelectMenu: (name: number) => void;
  selectedMenu: number;
  selectedAmount: number;
  wrapperClassName?: string;
  menuListWrapperClassName?: string;
  subtitleWrapperClassName?: string;
  tabMenuMultipleRef: RefObject<HTMLDivElement>;
  isHasScroll: boolean;
  viewTemplate: ViewTemplate;
  gridHeight: number | null;
};

const TabMenuMultiple = ({
  names,
  sizes,
  subtitle,
  configTheme,
  bottomAction,
  onSelectMenu,
  selectedMenu,
  selectedAmount = 0,
  wrapperClassName,
  menuListWrapperClassName,
  subtitleWrapperClassName,
  tabMenuMultipleRef,
  isHasScroll,
  viewTemplate,
  gridHeight,
}: TabMenuMultipleProps) => {
  const { t } = useTranslation();
  const { multipleFileUpload } = useSettings();

  return (
    <div
      className={cx(
        'rounded-medium flex h-full w-full flex-grow items-stretch bg-transparent',
        wrapperClassName
      )}
    >
      <div
        className={cx(
          'md:max-w-355 min-w-240 rounded-medium relative mr-4 flex h-auto w-full max-w-full flex-col',
          multipleFileUpload ? 'bg-white p-2' : '',
          css`
            min-height: 390px;
          `
        )}
      >
        <div
          className={cx(
            'rounded-medium flex h-auto flex-col',
            css({ '&&': configTheme?.root }),
            multipleFileUpload ? 'bg-gray-50 p-2' : 'flex-grow',
            'absolute inset-2'
          )}
        >
          <div
            ref={tabMenuMultipleRef}
            className={cx(
              'mb-2 h-full min-h-0 flex-shrink flex-grow space-y-1 overflow-y-auto',
              isHasScroll ? 'pr-2' : '',
              css`
                ::-webkit-scrollbar {
                  -webkit-appearance: none;
                  width: 5px;
                }
                ::-webkit-scrollbar-track {
                  background-color: ${configTheme?.scrollbar?.backgroundColor ||
                  COLOR_SCROLL_BAR.BACKGROUND};
                  border-radius: 60px;
                }
                ::-webkit-scrollbar-thumb {
                  background-color: ${configTheme?.scrollbar?.navigatorColor ||
                  COLOR_SCROLL_BAR.NAVIGATOR};
                  border-radius: 60px;
                  opacity: 1;
                }
              `,
              menuListWrapperClassName
            )}
          >
            {multipleFileUpload && (
              <TabMenuMultipleHeader
                configTheme={{
                  item: configTheme?.item,
                  selectedItem: configTheme?.selectedItem,
                }}
                name={t('txt_sheet_selection_list_title')}
                index={-1}
                onSelect={onSelectMenu}
                selected={selectedMenu === -1}
                selectedAmount={selectedAmount}
              />
            )}
            {names.map((name, index) => (
              <TabMenuMultipleItem
                key={`${name}:${index}`}
                configTheme={{
                  item: configTheme?.item,
                  selectedItem: configTheme?.selectedItem,
                }}
                name={name}
                index={index}
                onSelect={onSelectMenu}
                sizes={sizes[index]}
                selected={index === selectedMenu}
              />
            ))}
          </div>
          {bottomAction}
        </div>
      </div>
      <div
        id="grid-list-view"
        style={{
          height:
            viewTemplate === ViewTemplate.LIST
              ? gridHeight !== null
                ? gridHeight
                : 416
              : 'auto',
        }}
        className={cx(
          'rounded-medium w-full overflow-auto',
          subtitleWrapperClassName,
          css`
            ::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 5px;
            }
            ::-webkit-scrollbar-track {
              background-color: ${configTheme?.scrollbar?.backgroundColor ||
              COLOR_SCROLL_BAR.BACKGROUND};
              border-radius: 60px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: ${configTheme?.scrollbar?.navigatorColor ||
              COLOR_SCROLL_BAR.NAVIGATOR};
              border-radius: 60px;
              opacity: 1;
            }
          `
        )}
      >
        {subtitle()}
      </div>
    </div>
  );
};

export default TabMenuMultiple;
