import React, { FC, useCallback, useMemo } from 'react';
import { ISelectOption, ISelectProps, Select } from '@getnuvo/ui-kit';
import { useFormContext } from 'react-hook-form';

type IProps = Omit<ISelectProps, 'value' | 'onChange'> & {
  name: string;
};

export const SelectControl: FC<IProps> = (props: IProps) => {
  const { setValue, watch } = useFormContext();

  const formValue = watch(props.name);

  const value: ISelectOption | undefined = useMemo(
    () => props.options.find((o: ISelectOption) => o.id === formValue),
    [formValue, props.options]
  );

  const onChange = useCallback(
    (value: ISelectOption) => {
      setValue(props.name, value.id);
    },
    [setValue, props.name]
  );

  return (
    <Select {...props} multiple={false} value={value} onChange={onChange} />
  );
};
