import { css, cx } from 'core/emotion';
import { useTheme } from 'theme';
import { useMemo } from 'react';

type IStyleProps = { open: boolean; classname?: string; isShowError?: boolean };

export const useStyle = ({ open, isShowError }: IStyleProps) => {
  const theme = useTheme();
  const hoverBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark600Color();
  }, [theme]);

  const getOpenClassname = () => {
    if (open) {
      return cx(
        'focus:shadow-outline-black',
        isShowError
          ? 'border-red-510'
          : css({
              borderColor: theme.getGlobalTheme().getDark600Color(),
              ':focus': {
                borderColor: theme.getGlobalTheme().getPrimaryColor(),
              },
            })
      );
    } else {
      return isShowError
        ? 'border-red-510'
        : css({
            borderColor: theme.getGlobalTheme().getDark900Color(),
          });
    }
  };

  const menuButtonClassname = cx(
    getOpenClassname(),
    'relative text-sm text-primary w-full h-8.5 inset py-2 pl-3 pr-8 inline-flex leading-5 items-center border rounded-medium disabled:opacity-80 outline-none bg-white disabled:cursor-not-allowed',
    !isShowError
      ? css({
          ':hover:not([disabled])': {
            borderColor: hoverBorderColor,
          },
        })
      : ''
  );

  return {
    menuButtonClassname,
  };
};
