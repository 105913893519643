import { css, cx } from '../../core/emotion';

export const DownloadTempleViewSkeleton = () => {
  return (
    <div className="block animate-pulse">
      <TextSkeleton className="mb-2 h-7 w-full text-xl" />
      <div className="space-y-1">
        <TextSkeleton className="h-5 w-4/5" />
        <TextSkeleton className="h-5 w-3/5" />
        <TextSkeleton className="h-5 w-2/4" />
      </div>
    </div>
  );
};

export const TextSkeleton = ({ className }: { className: string }) => {
  return (
    <div className="flex animate-pulse">
      <p className={`bg-gray-120 rounded-medium ${className}`} />
    </div>
  );
};

export const HeadlineSkeleton = () => {
  return (
    <TextSkeleton
      className={cx(
        'flex w-40 space-x-2',
        css`
          height: 24px;
          margin-bottom: 4px;
          @media (min-width: 1700px) {
            height: 44px;
            margin-bottom: 0px;
          }
        `
      )}
    />
  );
};

export const SubHeadlineSkeleton = () => {
  return (
    <TextSkeleton
      className={cx(
        'w-81 mt-2',
        css`
          height: 16px;
          margin-bottom: 3px;
          @media (min-width: 1700px) {
            height: 20px;
            margin-bottom: 0px;
          }
        `
      )}
    />
  );
};

export const FileSupportIconSkeleton = ({
  className,
}: {
  className: string;
}) => {
  const rootClass = cx('mb-4 flex animate-pulse space-x-2', className);

  const iconClass = cx(
    'bg-gray-120 rounded-medium',
    css({
      width: '72px',
      height: '72px',
      flexShrink: 0,
    })
  );

  const iconSkeletonJSX = Array(5)
    .fill(0)
    .map((_, i: number) => {
      return <div key={i} className={iconClass}></div>;
    });

  return <div className={rootClass}>{iconSkeletonJSX}</div>;
};

export const BoxSkeleton = ({ className }: { className: string }) => {
  return (
    <div className="flex animate-pulse">
      <div className={`bg-gray-120 rounded-medium ${className}`} />
    </div>
  );
};
