import { cx } from 'core/emotion';
import DownloadTemplateBanner from 'main/MainView/common/DownloadTemplate/Banner';
import Uploader from 'main/MainView/common/Uploader';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainView } from 'settings';
import useViewModel from './viewModel';
import { useManualNavigate } from 'core/navigate';
import ProgressProcessing from 'baseUI/Processing';

const UploadFilePage = () => {
  const { t } = useTranslation();
  const { modal, multipleFileUpload } = useMainView();
  const {
    embedUploadArea,
    isProcessing,
    setProcessing,
    title,
    styleWrapper,
    timeUpload,
  } = useViewModel();
  const { navigateToReviewEntries } = useManualNavigate();

  return (
    <div className={cx(modal ? 'h-full' : '')}>
      {!embedUploadArea ? (
        <Fragment key="UploadFilePage">
          {isProcessing ? (
            <div
              className={cx(
                modal ? 'ml-1.5 h-full items-center pb-32' : 'min-h-500',
                'flex justify-center'
              )}
            >
              <ProgressProcessing
                timer={timeUpload}
                text={t(
                  multipleFileUpload
                    ? 'txt_allow_multiple_upload_file'
                    : 'txt_upload_file'
                )}
              />
            </div>
          ) : (
            <div className={cx(modal ? 'flex h-full flex-col' : '')}>
              <DownloadTemplateBanner title={title} />
              <div
                style={styleWrapper}
                className={cx(
                  'smd:flex-row rounded-2lg flex flex-col border-gray-100',
                  modal ? 'flex-shrink flex-grow' : 'mb-8'
                )}
              >
                <Uploader
                  className={cx(
                    'smd:order-1 order-3 flex flex-auto items-center justify-center'
                  )}
                  containerClassName={modal ? 'mb-8' : 'px-6'}
                  onUploadSuccess={() => setProcessing(false)}
                  onUploadFailure={() => setProcessing(false)}
                  onProcessing={() => setProcessing(true)}
                  onClickManualEntry={() => {
                    navigateToReviewEntries();
                  }}
                />
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <div
          className={cx(
            modal ? 'ml-1.5 h-full items-center pb-32' : 'min-h-500',
            'flex justify-center'
          )}
        >
          <ProgressProcessing
            timer={timeUpload}
            text={t(
              multipleFileUpload
                ? 'txt_allow_multiple_upload_file'
                : 'txt_upload_file'
            )}
          />
        </div>
      )}
    </div>
  );
};

export default UploadFilePage;
