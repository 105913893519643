import { useTheme } from '@nuvo-importer/common';
import { css, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';

type ISearchHeaderProps = {
  isSearching: boolean;
  searchOptionsLength: number;
  configThemeHeader?: string;
  optionsLength: number;
  menuTitle: string;
};

const SearchHeader = ({
  isSearching,
  searchOptionsLength,
  configThemeHeader,
  optionsLength,
  menuTitle,
}: ISearchHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <p
        className={cx(
          'px-4 py-2 text-xs',
          css({
            backgroundColor: theme.getGlobalTheme().getDark50Color(),
            color: theme.getGlobalTheme().getDark500Color(),
          }),
          configThemeHeader
        )}
      >
        {isSearching ? t('txt_search_result') : menuTitle}
      </p>
      {(isSearching && searchOptionsLength <= 0) || optionsLength === 0 ? (
        <p className="block px-4 py-6 text-center text-sm capitalize italic text-gray-400">
          {t('txt_empty')}
        </p>
      ) : null}
    </>
  );
};

export default SearchHeader;
