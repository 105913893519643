import { ErrorMessage } from '../ErrorMessage';
import useViewModel from './viewModel';
import Dropdown, { DropdownStyled, Option, ValueEqual } from './Dropdown';
import { CSSInterpolation } from '../../../core/emotion';

type FieldDropdownProps<T = string> = {
  name: string;
  onChange?: (value: T | null) => void;
  options: Option<T>[];
  placeholder: string;
  disabled?: boolean;
  className?: string;
  menuClassName?: string;
  valueEqual?: ValueEqual<T>;
  rootDropdownPortal?: HTMLDivElement;
  dropdownStyled?: DropdownStyled;
  showInfo?: boolean;
  infoMessage?: string;
  popoverStyled?: CSSInterpolation;
};

const FieldDropdown = <T,>({
  onChange: onChangeProp,
  name,
  options,
  placeholder,
  disabled,
  className,
  menuClassName,
  valueEqual,
  rootDropdownPortal,
  dropdownStyled,
  infoMessage,
  showInfo,
  popoverStyled,
}: FieldDropdownProps<T>) => {
  const { input, onChange, isShowError, errorMessage } = useViewModel({
    name,
    onChange: onChangeProp,
    isFieldShowError: true,
  });

  return (
    <div>
      <Dropdown
        {...input}
        onChange={onChange}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        menuClassName={menuClassName}
        valueEqual={valueEqual}
        rootDropdownPortal={rootDropdownPortal}
        dropdownStyled={dropdownStyled}
        showInfo={showInfo}
        infoMessage={infoMessage}
        popoverStyled={popoverStyled}
      />
      {isShowError ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </div>
  );
};

export default FieldDropdown;
