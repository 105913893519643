import ReactDOM from 'react-dom/client';
import Main from './app/main';
import { StrictMode } from 'react';

// const router = createBrowserRouter([
//   {
//     path: '/nuvo/*',
//     Component: () => {
//       return (
//         <Routes>
//           <Route path="/" element={<Main />} />
//         </Routes>
//       );
//     },
//   },
// ]);

// const NavigationApp = () => {
//   const navigate = useNavigate();
//   return (
//     <div>
//       <button
//         style={{ marginRight: '10px' }}
//         onClick={() => {
//           navigate('/');
//         }}
//       >
//         Home
//       </button>
//       <button
//         onClick={() => {
//           navigate('/suppliers');
//         }}
//       >
//         nuvo
//       </button>
//     </div>
//   );
// };

const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <StrictMode>
      <Main />
      {/* <BrowserRouter>
        <NavigationApp />
        <Routes>
          <Route path="/" element={<p>Home</p>} />
          <Route path="/suppliers/*" element={<Main />} />
        </Routes>
      </BrowserRouter> */}
      {/* <RouterProvider router={router} /> */}
    </StrictMode>
  );
}
