import { Route, Routes } from 'react-router-dom';
import Router from '../../Router';
import Page from './Page';

const RoutePage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Page />} />
      </Routes>
      <Router />
    </>
  );
};

export default RoutePage;
