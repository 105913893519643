import { Subject } from 'rxjs';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { CustomColumnUIObservable } from '../../customColumns';
import ContextMenuController from '../controller/ContextMenuController';

const useViewModel = ({
  dataModel,
  customColumnUIObservable,
  contextMenuController,
}: {
  dataModel: DataModel;
  customColumnUIObservable: Subject<CustomColumnUIObservable>;
  contextMenuController: ContextMenuController;
}) => {
  const onMenuClick = () => {
    customColumnUIObservable.next({
      isOpen: true,
      type: 'edit',
      dataModel,
    });
    contextMenuController.closeMenu();
  };

  return {
    onMenuClick,
  };
};

export default useViewModel;
