import chroma from 'chroma-js';
import { DEFAULT_THEME_COLOR } from '../../core/constants/style';

export class GlobalTheme {
  private fontFamily: string;
  private primaryTextColor?: string;
  private secondaryTextColor?: string;
  private backgroundColor?: string;
  private primaryColor: string;
  private secondaryColor: string;
  private borderRadius: string | number;
  private checkboxThemeColor: string;
  private dark50Color: string;
  private light50Color: string;
  private dark900Color: string;
  private light100Color: string;
  private dark500Color: string;
  private light200Color: string;
  private light400Color: string;
  private dark100Color: string;
  private dark100AlphaColor: string;
  private normal500Color: string;
  private hoverDropdownCategory: string;
  private dark600Color: string;
  private dark400Color: string;
  private legacyPrimaryColor: string;
  private normal50Color: string;
  private light40AlphaColor: string;

  constructor({
    fontFamily,
    primaryTextColor,
    secondaryTextColor,
    backgroundColor,
    primaryColor,
    secondaryColor,
    borderRadius,
  }: {
    fontFamily: string;
    primaryTextColor: string;
    secondaryTextColor: string;
    backgroundColor?: string;
    primaryColor?: string;
    secondaryColor?: string;
    borderRadius?: string | number;
  }) {
    this.fontFamily = fontFamily;
    this.primaryTextColor = primaryTextColor;
    this.secondaryTextColor = secondaryTextColor;
    this.backgroundColor = backgroundColor ?? DEFAULT_THEME_COLOR.BACKGROUND;
    this.primaryColor = primaryColor ?? DEFAULT_THEME_COLOR.PRIMARY;
    this.secondaryColor = secondaryColor ?? DEFAULT_THEME_COLOR.SECONDARY;
    this.checkboxThemeColor = this.setCheckboxThemeColor();
    this.hoverDropdownCategory = this.setHoverDropdownCategory();
    this.legacyPrimaryColor = this.setLegacyPrimaryColor();
    this.normal50Color = this.setNormal50Color();
    this.normal500Color = this.setNormal500Color();
    this.light40AlphaColor = this.setLight40AlphaColor();
    this.light50Color = this.setLight50Color();
    this.light100Color = this.setLight100Color();
    this.light200Color = this.setLight200Color();
    this.light400Color = this.setLight400Color();
    this.dark50Color = this.setDark50Color();
    this.dark100Color = this.setDark100Color();
    this.dark100AlphaColor = this.setDark100AlphaColor();
    this.dark400Color = this.setDark400Color();
    this.dark500Color = this.setDark500Color();
    this.dark600Color = this.setDark600Color();
    this.dark900Color = this.setDark900Color();
    this.borderRadius = borderRadius ?? '8px';
  }

  getFontFamily = () => {
    return this.fontFamily;
  };

  getSuccessColor = () => {
    const normalBackgroundColor = '';

    const darkerColor =
      normalBackgroundColor !== ''
        ? normalBackgroundColor
        : DEFAULT_THEME_COLOR.SUCCESS;

    const lighterColor =
      normalBackgroundColor !== ''
        ? chroma(normalBackgroundColor).brighten(3).hex()
        : '#E3FCEF';

    return {
      lighterColor: lighterColor,
      darkerColor: darkerColor,
    };
  };

  getWarningColor = () => {
    const normalBackgroundColor = '';

    const darkerColor =
      normalBackgroundColor !== ''
        ? normalBackgroundColor
        : DEFAULT_THEME_COLOR.WARNING;

    const lighterColor =
      normalBackgroundColor !== ''
        ? chroma(normalBackgroundColor).brighten(3).hex()
        : DEFAULT_THEME_COLOR.LIGHT_WARNING;

    return {
      lighterColor: lighterColor,
      darkerColor: darkerColor,
    };
  };

  getBorderRadius = () => {
    return this.borderRadius;
  };

  getPrimaryTextColor = () => {
    return this.primaryTextColor ?? '';
  };

  getSecondaryTextColor = () => {
    return this.secondaryTextColor ?? '';
  };

  getBackgroundColor = () => {
    return this.backgroundColor ?? DEFAULT_THEME_COLOR.BACKGROUND;
  };

  getPrimaryColor = () => {
    return this.primaryColor;
  };

  getSecondaryColor = () => {
    return this.secondaryColor;
  };

  getCheckboxThemeColor = () => {
    return this.checkboxThemeColor;
  };

  getHoverDropdownCategory = () => {
    return this.hoverDropdownCategory;
  };

  getLegacyPrimaryColor = () => {
    return this.legacyPrimaryColor;
  };

  getNormal50Color = () => {
    return this.normal50Color;
  };

  getNormal500Color = () => {
    return this.normal500Color;
  };

  getLight40AlphaColor = () => {
    return this.light40AlphaColor;
  };

  getLight50Color = () => {
    return this.light50Color;
  };

  getLight100Color = () => {
    return this.light100Color;
  };

  getLight200Color = () => {
    return this.light200Color;
  };

  getLight400Color = () => {
    return this.light400Color;
  };

  getDark50Color = () => {
    return this.dark50Color;
  };

  getDark100Color = () => {
    return this.dark100Color;
  };

  getDark100AlphaColor = () => {
    return this.dark100AlphaColor;
  };

  getDark400Color = () => {
    return this.dark400Color;
  };

  getDark500Color = () => {
    return this.dark500Color;
  };

  getDark600Color = () => {
    return this.dark600Color;
  };

  getDark900Color = () => {
    return this.dark900Color;
  };

  private setCheckboxThemeColor = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') + 0.4494, 0.84761, 0.20588)
      .hex();

    return color;
  };

  private setHoverDropdownCategory = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 10, 0.09999, 0.96078)
      .hex();

    return color;
  };

  private setLegacyPrimaryColor = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 17.1428, 0.61764, 0.13333)
      .hex();

    return color;
  };

  private setNormal50Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 6.25, 1, 0.96862)
      .hex();

    return color;
  };

  private setNormal500Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 2.7807, 0.91219, 0.59803)
      .hex();

    return color;
  };

  private setLight40AlphaColor = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') + 5.68434, 0.81818, 0.97843)
      .alpha(0.5)
      .hex();

    return color;
  };

  private setLight50Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') + 0.6843, 0.81818, 0.97843)
      .hex();

    return color;
  };

  private setLight100Color = () => {
    const normalLightChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalLightChroma.get('hsl.h') - 0.6896, 0.87878, 0.93529)
      .hex();

    return color;
  };

  private setLight200Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 0.3571, 0.84848, 0.87058)
      .hex();

    return color;
  };

  private setLight400Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') + 0.1652, 0.84615, 0.7196)
      .hex();

    return color;
  };

  private setDark50Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 3.999, 0.45454, 0.95686)
      .hex();

    return color;
  };

  private setDark100Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 2.7586, 0.44615, 0.87254)
      .hex();

    return color;
  };

  private setDark100AlphaColor = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 2.7586, 0.44615, 0.87254)
      .alpha(0.5)
      .hex();

    return color;
  };

  private setDark400Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 3.2941, 0.43589, 0.61764)
      .hex();

    return color;
  };

  private setDark500Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 3.0769, 0.43697, 0.53333)
      .hex();

    return color;
  };

  private setDark600Color = () => {
    const normalPrimaryChroma = chroma(this.primaryColor);

    const color = chroma
      .hsl(normalPrimaryChroma.get('hsl.h') - 2.7999, 0.43478, 0.45098)
      .hex();

    return color;
  };

  private setDark900Color = () => {
    const normalDarkChroma = chroma(this.primaryColor);
    const color = chroma
      .hsl(normalDarkChroma.get('hsl.h') - 2.9411, 0.43589, 0.15294)
      .hex();

    return color;
  };
}
