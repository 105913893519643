import React, { useMemo } from 'react';
import { css, cx, useTheme } from '@nuvo-importer/common';
import chroma from 'chroma-js';

//TODO should be from libs/common/src/lib/theme/themeAPI.ts
type ImageCustomStyle = {
  borderColor?: string;
  headerColor?: string;
  circleHeaderColor?: string;
  circleRowColor?: string;
  downloadIconColor?: string;
  shadowColor?: string;
};

type DownloadTemplateImgProps = {
  customStyle?: ImageCustomStyle;
};

const DownloadTemplateImg = ({ customStyle }: DownloadTemplateImgProps) => {
  const theme = useTheme();
  const primaryColor = theme.getGlobalTheme().getPrimaryColor();
  const secondaryColor = theme.getGlobalTheme().getSecondaryColor();

  const darkSecondaryColor = useMemo(() => {
    const secondaryChroma = chroma(secondaryColor);
    return chroma
      .hsl(
        secondaryChroma.get('hsl.h') + 0.3325,
        secondaryChroma.get('hsl.s') - 0.03,
        secondaryChroma.get('hsl.l') - 0.064
      )
      .hex();
  }, [secondaryColor]);

  const darkerSecondaryColor = useMemo(() => {
    const secondaryChroma = chroma(secondaryColor);

    return chroma
      .hsl(
        secondaryChroma.get('hsl.h') + 0.9249,
        secondaryChroma.get('hsl.s') - 0.0202,
        secondaryChroma.get('hsl.l') - 0.1294
      )
      .hex();
  }, [secondaryColor]);

  return (
    <div
      className={cx(
        'flex justify-center py-12 pb-8 text-center',
        cx(
          'mx-auto object-contain',
          css`
            min-height: 235px;
          `
        )
      )}
    >
      <svg
        width="275"
        height="202"
        viewBox="0 0 275 202"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.75" filter="url(#filter0_f_609_55126)">
          <rect
            x="30"
            y="30.8711"
            width="215"
            height="140.257"
            fill={`${customStyle?.shadowColor ?? secondaryColor}`}
          />
        </g>
        <rect
          x="21.5"
          y="25.5"
          width="232"
          height="151"
          rx="3.5"
          fill="white"
        />
        <rect
          x="41"
          y="50"
          width="193"
          height="19"
          fill={`${customStyle?.headerColor ?? secondaryColor}`}
        />
        <line
          x1="41.1628"
          y1="49.6443"
          x2="233.836"
          y2="49.6443"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          x1="41.1628"
          y1="69.0608"
          x2="233.836"
          y2="69.0608"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          opacity="0.8"
          x1="41.1628"
          y1="88.4773"
          x2="233.836"
          y2="88.4773"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          opacity="0.7"
          x1="41.1628"
          y1="107.894"
          x2="233.836"
          y2="107.894"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          opacity="0.6"
          x1="41.1628"
          y1="127.311"
          x2="233.836"
          y2="127.311"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          opacity="0.5"
          x1="41.1628"
          y1="146.727"
          x2="233.836"
          y2="146.727"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          x1="60.5794"
          y1="158.676"
          x2="60.5794"
          y2="51.1376"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          x1="108.374"
          y1="134.779"
          x2="108.374"
          y2="51.1376"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          x1="156.169"
          y1="146.727"
          x2="156.169"
          y2="51.1376"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <line
          x1="203.964"
          y1="134.779"
          x2="203.964"
          y2="51.1376"
          stroke={`${customStyle?.borderColor ?? darkSecondaryColor}`}
          stroke-width="1.49359"
          stroke-linecap="round"
        />
        <rect
          x="162"
          y="39"
          width="36"
          height="3"
          rx="1.5"
          fill={`${customStyle?.borderColor ?? darkSecondaryColor}`}
        />
        <circle
          cx="116.5"
          cy="40.5"
          r="2.5"
          fill={`${customStyle?.circleHeaderColor ?? darkerSecondaryColor}`}
        />
        <rect
          x="123"
          y="39"
          width="29"
          height="3"
          rx="1.5"
          fill={`${customStyle?.borderColor ?? darkSecondaryColor}`}
        />
        <circle
          cx="68.5"
          cy="40.5"
          r="2.5"
          fill={`${customStyle?.circleHeaderColor ?? darkerSecondaryColor}`}
        />
        <rect
          x="75"
          y="39"
          width="29"
          height="3"
          rx="1.5"
          fill={`${customStyle?.borderColor ?? darkSecondaryColor}`}
        />
        <circle
          cx="49.734"
          cy="78.734"
          r="3.73397"
          fill={`${customStyle?.circleRowColor ?? secondaryColor}`}
        />
        <circle
          cx="49.734"
          cy="98.2018"
          r="3.73397"
          fill={`${customStyle?.circleRowColor ?? secondaryColor}`}
        />
        <circle
          cx="49.734"
          cy="117.67"
          r="3.73397"
          fill={`${customStyle?.circleRowColor ?? secondaryColor}`}
        />
        <circle
          cx="49.734"
          cy="137.138"
          r="3.73397"
          fill={`${customStyle?.circleRowColor ?? secondaryColor}`}
        />
        <rect
          width="32"
          height="32"
          transform="translate(213 136)"
          fill="white"
        />
        <g filter="url(#filter1_d_609_55126)">
          <path
            d="M228.999 165.333C236.363 165.333 242.333 159.364 242.333 152C242.333 144.636 236.363 138.667 228.999 138.667C221.636 138.667 215.666 144.636 215.666 152C215.666 159.364 221.636 165.333 228.999 165.333Z"
            stroke={`${customStyle?.downloadIconColor ?? primaryColor}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_609_55126)">
          <path
            d="M223.666 152L228.999 157.333L234.333 152"
            stroke={`${customStyle?.downloadIconColor ?? primaryColor}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_609_55126)">
          <path
            d="M229 146.667V157.333"
            stroke={`${customStyle?.downloadIconColor ?? primaryColor}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect
          x="21.5"
          y="25.5"
          width="232"
          height="151"
          rx="3.5"
          stroke={`${customStyle?.borderColor ?? secondaryColor}`}
        />
        <defs>
          <filter
            id="filter0_f_609_55126"
            x="0"
            y="0.871094"
            width="275"
            height="200.257"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="15"
              result="effect1_foregroundBlur_609_55126"
            />
          </filter>
          <filter
            id="filter1_d_609_55126"
            x="210.666"
            y="133.667"
            width="36.666"
            height="36.6665"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.360784 0 0 0 0 0.556863 0 0 0 0 0.94902 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_609_55126"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_609_55126"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_609_55126"
            x="218.666"
            y="147"
            width="20.666"
            height="15.3335"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.360784 0 0 0 0 0.556863 0 0 0 0 0.94902 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_609_55126"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_609_55126"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_609_55126"
            x="224"
            y="141.667"
            width="10"
            height="20.6665"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.360784 0 0 0 0 0.556863 0 0 0 0 0.94902 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_609_55126"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_609_55126"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default DownloadTemplateImg;
