import { useMemo, useState } from 'react';
import DataModelSheetMatching from './../../../matching/DataModelSheetMatching';
import DataModelSheetMatcherModel from './../../../matching/DataModelSheetMatcher';
import { DataModel } from 'dataModel';
import SheetColumn from './../../../sheetImporter/SheetColumn';
import PreviewData from './PreviewData';
import MatchingReport from './MatchingReport';
import CollapseCard from './common/CollapseCard';
import useEmptyState from './common/emptyState';
import { ColumnMatchThemeAPI } from './PreviewData/types';
import { CSSInterpolation } from 'core/emotion';

const DataModelSheetMatcher = ({
  sheetColumn,
  matchedDataModel,
  dataModelSheetMatching,
  prefixName,
  dataModelSheetMatcherModel,
  configTheme,
  configThemePopper,
  index,
  setDataModelSheetMatcher,
}: {
  sheetColumn: SheetColumn;
  matchedDataModel?: DataModel;
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcherModel: DataModelSheetMatcherModel;
  prefixName: string;
  configTheme?: ColumnMatchThemeAPI;
  configThemePopper?: CSSInterpolation;
  index: number;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcherModel
  ) => void;
}) => {
  const { isMatchingReportEmpty, isPreviewDataEmpty } = useEmptyState({
    matchedDataModel,
    prefixName,
    sheetColumn,
  });
  const [loadingMatchingMoreOptions, setLoadingMatchingMoreOptions] =
    useState(false);

  const initialIsCollapse = useMemo(() => {
    if (!matchedDataModel) {
      return false;
    }

    if (matchedDataModel.isDropdown()) {
      return false;
    }

    return true;
  }, [matchedDataModel]);

  return (
    <CollapseCard
      initialIsCollapse={initialIsCollapse}
      hideToggleButton={isMatchingReportEmpty && isPreviewDataEmpty}
    >
      <PreviewData
        sheetColumn={sheetColumn}
        matchedDataModel={matchedDataModel}
        prefixName={prefixName}
        dataModelSheetMatcherModel={dataModelSheetMatcherModel}
        configTheme={configTheme}
        configThemePopper={configThemePopper}
        index={index}
        setDataModelSheetMatcher={setDataModelSheetMatcher}
        setLoadingMatchingMoreOptions={setLoadingMatchingMoreOptions}
        loadingMatchingMoreOptions={loadingMatchingMoreOptions}
      />
      <MatchingReport
        matchedDataModel={matchedDataModel}
        dataModelSheetMatching={dataModelSheetMatching}
        sheetColumn={sheetColumn}
        prefixName={prefixName}
        configTheme={configTheme}
        loadingMatchingMoreOptions={loadingMatchingMoreOptions}
      />
    </CollapseCard>
  );
};

export default DataModelSheetMatcher;
