import { useMemo } from 'react';

type Props = {
  pageIndex: number;
  pageCount: number;
};

const useViewModel = ({ pageIndex, pageCount }: Props) => {
  const displayPage = useMemo(
    () =>
      pageIndex <= 3
        ? [2, 3, 4, 5]
        : pageIndex > pageCount - 3
        ? [pageCount - 3, pageCount - 2, pageCount - 1]
        : [0, 1, 2],
    [pageIndex, pageCount]
  );

  return { displayPage };
};

export default useViewModel;
