import { ITargetDataModel } from '../types';

export const parseJSON = (value: string | undefined, defaultValue = {}) => {
  if (value === undefined) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};

export const safeDataHandlerEval = (code: string, nameHook: string) => {
  try {
    // eslint-disable-next-line no-eval
    return eval('(' + code + ')');
  } catch (e) {
    return () => {
      console.log(`Please check the syntax of the ${nameHook}.`);
    };
  }
};

export const findTDM = (
  dataModels: ITargetDataModel[],
  value: string | undefined
) => {
  return dataModels.find((m: ITargetDataModel) => m.value === value);
};
