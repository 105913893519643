import { PreviewConfigProvider } from './context/PreviewConfigProvider';
import Navigation from './Navigation';
import Preview from './Preview';

export default function Example() {
  return (
    <div className="min-h-full">
      <PreviewConfigProvider>
        <Navigation />
        <div className="mt-10">
          <Preview />
        </div>
      </PreviewConfigProvider>
    </div>
  );
}
