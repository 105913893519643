import { isArray, isBoolean, isEmpty, isNil, isNumber, isObject } from 'lodash';
import { DataModel } from '../../dataModel/model/DataModel';
import { PossibleValue } from './IValueParser';
import { parseNumberStringToNumber } from '../../number';

export abstract class BaseValueParser {
  abstract parseStrToValue: (value: PossibleValue) => number;

  parse = (_dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return value ? 1 : 0;
    }

    if (isNumber(value)) {
      return this.parseStrToValue(parseNumberStringToNumber(`${value}`));
    }

    if (isObject(value) || isArray(value) || isEmpty(value)) {
      return '';
    }

    const result = this.parseStrToValue(parseNumberStringToNumber(`${value}`));

    if (isNaN(result)) {
      return value;
    }

    return result;
  };
}
