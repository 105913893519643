import { isEmpty, isNil } from 'lodash';
import { DynamicStepValues, UploadData } from '../../types';
import NuvoSessionListener from './NuvoSessionListener';

export const DATA_EMPTY = [[]];
const DEFAULT_IDENTIFIER = '';

export enum DynamicStep {
  HEADER = 'header',
  MAPPING = 'mapping',
  REVIEW = 'review',
  SHEET_SELECTION = 'sheetSelection',
}

export type UploadOptions = {
  step: DynamicStepValues;
  headerIndex?: number;
  data: UploadData;
};

class NuvoSession {
  private nuvoSessionListener: NuvoSessionListener;
  private dataStack: Record<string, UploadOptions> = {};

  constructor(nuvoSessionListener: NuvoSessionListener) {
    this.nuvoSessionListener = nuvoSessionListener;
    // NOTE: to solve stale data for hot-reload only development
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((module as any)?.hot && process.env.NODE_ENV !== 'production') {
        const callback = (status: string) => {
          if (status === 'prepare') {
            this.dataStack = {};
          }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (module as any)?.hot?.addStatusHandler(callback);
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('nuvo: ', 'Not found module hot reload');
      }
    }
  }

  upload = (options: UploadOptions, identifier?: string) => {
    this.dataStack[identifier ?? DEFAULT_IDENTIFIER] = options;
  };

  start = (identifier?: string) => {
    let sessionKey = DEFAULT_IDENTIFIER;

    if (
      identifier &&
      this.nuvoSessionListener.listenStartCallbackStack[identifier]
    ) {
      sessionKey = identifier;
    } else {
      const callbackStackKey = Object.keys(
        this.nuvoSessionListener.listenStartCallbackStack
      );
      sessionKey = callbackStackKey[callbackStackKey.length - 1];
    }

    let targetData = this.dataStack?.[sessionKey];

    if (isNil(identifier) || isEmpty(identifier)) {
      targetData = this.dataStack?.[DEFAULT_IDENTIFIER];
    }

    this.nuvoSessionListener.listenStartCallbackStack[sessionKey]?.({
      data: targetData?.data,
      headerIndex: targetData?.headerIndex,
      step: targetData?.step as DynamicStep,
    });
  };
}

export default NuvoSession;
