import { css, cx } from 'core/emotion';
import { useMainView } from 'settings';
import { useTheme } from 'theme';
import { ReactComponent as LeftIcon } from '../../assets/icon/chevron-left.svg';
import { ReactComponent as RightIcon } from '../../assets/icon/chevron-right.svg';
import PageNavigationButton from './components/PageNavigationButton';
import useViewModel from './viewModel';

type PaginationProps = {
  pageIndex: number;
  pageCount: number;
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  className?: string;
  pageOptions: number[];
};

const Pagination = ({
  pageIndex,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  pageCount,
  gotoPage,
  className,
  pageOptions,
}: PaginationProps) => {
  const { displayPage } = useViewModel({
    pageIndex,
    pageCount,
  });

  const theme = useTheme();
  const { modal } = useMainView();

  const paginationTheme = theme.getSheetSelectTheme().pagination;

  const activeClassName = cx(
    'cursor-default bg-gray-120 text-blue-dark-900',
    css({ '&&': paginationTheme?.currentPageButton })
  );

  const normalClassName = cx(
    'cursor-pointer text-gray-200',
    css({ '&&': paginationTheme?.pageButton })
  );

  const pageMoreClassName = cx(
    'cursor-default bg-white',
    css({ '&&': paginationTheme?.pageMore })
  );

  return (
    <div
      className={cx(
        'flex flex-col items-center justify-end space-y-4 lg:flex-row lg:space-y-0',
        modal ? 'px-0' : 'px-6',
        className
      )}
    >
      <div className="flex flex-row">
        <button
          onClick={previousPage}
          disabled={!canPreviousPage}
          className={cx(
            'mr-5',
            `${
              canPreviousPage
                ? 'cursor-pointer text-gray-800'
                : 'cursor-default text-gray-200'
            }`,
            css({ '&&': paginationTheme?.prevButton })
          )}
          type="button"
        >
          <LeftIcon className="w-5" />
        </button>
        {pageCount >= 10 ? (
          <>
            <PageNavigationButton
              className={
                pageIndex + 1 !== 1 ? normalClassName : activeClassName
              }
              onClick={() => gotoPage(0)}
              disabled={pageIndex + 1 === 1}
            >
              {`${1}`}
            </PageNavigationButton>
            {pageIndex >= 4 && (
              <PageNavigationButton className={pageMoreClassName}>
                ...
              </PageNavigationButton>
            )}
            {displayPage.map((key) => {
              const num =
                pageIndex <= 3 || pageIndex > pageCount - 3
                  ? key
                  : pageIndex + key;

              return (
                <PageNavigationButton
                  key={num}
                  className={
                    pageIndex + 1 !== num ? normalClassName : activeClassName
                  }
                  onClick={() => gotoPage(num - 1)}
                  disabled={pageIndex + 1 === num}
                >
                  {num}
                </PageNavigationButton>
              );
            })}
            {pageIndex <= pageCount - 4 && (
              <PageNavigationButton className={pageMoreClassName}>
                ...
              </PageNavigationButton>
            )}
            <PageNavigationButton
              className={
                pageIndex !== pageCount - 1 ? normalClassName : activeClassName
              }
              onClick={() => gotoPage(pageCount - 1)}
              disabled={pageIndex === pageCount - 1}
            >
              {`${pageCount}`}
            </PageNavigationButton>
          </>
        ) : (
          <>
            {[...Array(pageOptions.length).keys()].map((key) => {
              const numPage = key + 1;
              return (
                <PageNavigationButton
                  key={key}
                  className={
                    pageIndex + 1 !== numPage
                      ? normalClassName
                      : activeClassName
                  }
                  onClick={() => gotoPage(key)}
                  disabled={pageIndex + 1 === numPage}
                >
                  {`${numPage}`}
                </PageNavigationButton>
              );
            })}
          </>
        )}
        <button
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
          className={cx(
            'ml-5',
            canNextPage
              ? 'cursor-pointer text-gray-800'
              : 'cursor-default text-gray-200',
            css({ '&&': paginationTheme?.nextButton })
          )}
        >
          <RightIcon className="w-5" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
