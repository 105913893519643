import { isArray, isBoolean, isInteger, isNil, isObject } from 'lodash';
import { DataModel } from '../../dataModel/model/DataModel';
import ValueParser, { PossibleValue } from './IValueParser';
import { NumberParser } from '../../utils/NumberParser';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import { NumberFormat } from '../../dataModel/columnsAPI';

class CategoryParser implements ValueParser {
  private parseValueByOptionType = (
    value: PossibleValue,
    dataModel: CategoryDataModel
  ) => {
    const options = dataModel.getOptions();

    const optionType =
      options.find((item) => item.value === value)?.type ?? 'string';

    if (optionType === 'int') {
      const result = Number(value);
      if (!isNaN(result) && isInteger(result)) {
        return result;
      }

      return `${value}`;
    } else if (optionType === 'float') {
      const beforeConvertValue = `${value}`.replace(/,/, '.');
      const result = NumberParser.convertStringToNumber(
        `${beforeConvertValue}`,
        {
          format: NumberFormat.US,
        }
      );
      if (result === null) {
        return `${value}`;
      }
      return Number(result);
    } else {
      return `${value}`;
    }
  };

  private parseSingle = (
    value: PossibleValue,
    dataModel: CategoryDataModel
  ) => {
    if (isNil(value)) {
      return '';
    }

    if (isObject(value) || isArray(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    return this.parseValueByOptionType(value, dataModel);
  };

  private parseMultiple = (
    value: PossibleValue,
    dataModel: CategoryDataModel,
    isPipelineResult?: boolean
  ) => {
    if (value === '') {
      return [];
    }

    if (isPipelineResult && isArray(value)) {
      return value.join(', ');
    }

    if (isArray(value)) {
      return value.map((item) => this.parseValueByOptionType(item, dataModel));
    }

    if (isNil(value) || isObject(value)) {
      return [];
    }

    if (isBoolean(value)) {
      return [JSON.stringify(value)];
    }

    return [this.parseValueByOptionType(value, dataModel)];
  };

  parse = (
    dataModel: DataModel,
    value: PossibleValue,
    _isBeforeHook: boolean,
    isPipelineResult?: boolean
  ) => {
    if (dataModel.getIsMultiSelection()) {
      return this.parseMultiple(
        value,
        dataModel as CategoryDataModel,
        isPipelineResult
      );
    } else {
      return this.parseSingle(value, dataModel as CategoryDataModel);
    }
  };
}

export default CategoryParser;
