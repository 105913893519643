import { RefObject, useCallback } from 'react';
import { HookedRecordResult } from '../../../hooks/hooksAPI';
import { forEach, isNil } from 'lodash';
import DataModelRegistry from './../DataModelRegistry';
import AllColumnSetting from './../columns/AllColumnSetting';
import ColumnSettingHelper from '../columns/ColumnSettingHelper';
import { Validator } from '../../../reviewEntries/validator';
import { FinalChange } from '../type';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import { HotTableClass } from '@handsontable/react';
import useValidate, { getValidatedRowData } from './validate';
import { HandleChangeInfoFn } from '../../../reviewEntries/type';

type IHandleChange = {
  dataModelRegistry: DataModelRegistry;
  updateTotalError: () => void;
  allColumnSetting: AllColumnSetting;
  handleChangeInfo: HandleChangeInfoFn;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  dataSet: Record<string, any>[];
  validator: Validator;
  hotInstance?: RefObject<HotTableClass>;
};

const useHandleChange = ({
  dataModelRegistry,
  updateTotalError,
  allColumnSetting,
  handleChangeInfo,
  dataSet,
  validator,
  hotInstance,
}: IHandleChange) => {
  const updateFilterValueItems = useCallback(
    (columnIndex: number, newCellValue: any) => {
      const columnSetting = allColumnSetting.getColumnSetting(columnIndex);

      if (ColumnSettingHelper.hasFilterValueSelected(columnSetting)) {
        ColumnSettingHelper.addFilterValueSelected(
          columnSetting,
          newCellValue,
          true
        );
      }

      allColumnSetting
        .getFilterStrategy()
        .getFilterValueItems()
        .clearValueItemsSnapshot(columnIndex);
    },
    [allColumnSetting]
  );

  const updateCleanFnChangesToDataSet = useCallback(
    (
      rowIndex: number,
      columns: ColumnAPI[],
      cleanFnChanges: Record<string, HookedRecordResult>
    ) => {
      forEach(cleanFnChanges, (entry, key) => {
        const colIndex = columns.findIndex((col) => col.key === key);
        if (colIndex > -1 && !isNil(entry.value)) {
          dataSet[rowIndex][key] = entry.value;
        }
      });
    },
    [dataSet]
  );

  const { validateUnique, validateCell } = useValidate({
    validator,
    hotInstance,
    dataSet,
  });

  const handleChanges = useCallback(
    (finalChanges: FinalChange[]) => {
      const columns = dataModelRegistry.getColumns();
      const dataModels = dataModelRegistry.getDataModels();
      const validateUniqueCols = columns
        .filter((column) =>
          column.validations?.some((item) => item.validate === 'unique')
        )
        .map((column) => column.key);
      for (let i = 0; i < finalChanges.length; ++i) {
        const finalChange = finalChanges[i];
        const rowIndex = finalChange.rowIndex;
        const cleanFnChanges = finalChange.cleanFnChanges ?? {};
        const currentRowData = finalChange.currentRowData;
        const validatedRowData = getValidatedRowData(
          rowIndex,
          columns,
          cleanFnChanges,
          currentRowData
        );

        updateCleanFnChangesToDataSet(rowIndex, columns, cleanFnChanges);
        handleChangeInfo(cleanFnChanges, rowIndex, dataModels);

        for (let columnIndex = 0; columnIndex < columns.length; ++columnIndex) {
          const column = columns[columnIndex];
          const newCellValue =
            cleanFnChanges?.[columns?.[columnIndex]?.key]?.value ??
            finalChange.currentRowData[columnIndex];

          if (
            finalChange.sourceCols.includes(column.key) ||
            !isNil(cleanFnChanges?.[columns?.[columnIndex]?.key]?.value)
          ) {
            updateFilterValueItems(columnIndex, newCellValue);
          }

          if (
            validateUniqueCols.includes(column.key) &&
            !column.isMultiSelect &&
            (finalChange.sourceCols.includes(column.key) ||
              !isNil(cleanFnChanges?.[columns?.[columnIndex]?.key]?.value))
          ) {
            const oldCellValue = finalChange.sourceCols.includes(column.key)
              ? finalChange.changeLog?.[column.key]
              : currentRowData[columnIndex];
            validateUnique({
              rowIndex,
              column,
              columnIndex,
              columns,
              newCellValue,
              oldCellValue,
            });
          }

          validateCell({
            rowIndex,
            columnIndex,
            column,
            columns,
            validatedRowData,
            newCellValue,
          });
        }
      }
      columns.forEach((column, columnIndex) => {
        validator.removeUniqueErrorFirstRow(column, columnIndex);
      });
      updateTotalError();
    },
    [
      dataModelRegistry,
      handleChangeInfo,
      updateCleanFnChangesToDataSet,
      updateFilterValueItems,
      validateCell,
      validateUnique,
      updateTotalError,
      validator,
    ]
  );

  return { handleChanges };
};

export default useHandleChange;
