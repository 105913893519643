import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@getnuvo/ui-kit';
import { usePreviewConfig } from '../../../../context/PreviewConfigProvider';

interface IProps {
  open: boolean;
  onDismiss: () => void;
}

export const ConfirmResetDialog: FC<IProps> = ({ open, onDismiss }: IProps) => {
  const { removeConfigure } = usePreviewConfig();
  const [loading, setLoading] = useState<boolean>(false);

  const onClear = () => {
    setLoading(true);
    removeConfigure();
    window.location.reload();
  };

  return (
    <Dialog open={open} onDismiss={onDismiss}>
      <DialogHeader title="Reset configuration" />
      <DialogBody>
        <p className="paragraph-2">
          You are about to reset the configuration. Do you want to continue?
        </p>
      </DialogBody>
      <DialogFooter>
        <Button variant="outlineSecondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button loading={loading} onClick={onClear}>
          Reset
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
