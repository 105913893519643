import { useTranslation } from 'react-i18next';
import AllColumnSetting from '../../columns/AllColumnSetting';
import ContextMenuItem from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import ContextMenuController from '../controller/ContextMenuController';
import useViewModel from './viewModel';
import { SortState } from '../../columns/SortStrategy';
import { ConfigTheme } from '../../type';

type SortMenuItemsProps = {
  sort: SortState | null;
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  currentColumnIndex: number;
  configTheme?: ConfigTheme;
};

const SortMenuItems = ({
  sort,
  allColumnSetting,
  contextMenuController,
  currentColumnIndex,
  configTheme,
}: SortMenuItemsProps) => {
  const { onMenuClick } = useViewModel({
    allColumnSetting,
    contextMenuController,
    currentColumnIndex,
  });
  const { t } = useTranslation();

  if (!sort) {
    return (
      <>
        <ContextMenuItem
          onClick={() => {
            onMenuClick(SortState.ASC);
          }}
          icon={ContextMenuIcon.SORT_ASC}
          text={t('txt_sort_asc')}
          configTheme={configTheme}
        />
        <ContextMenuItem
          onClick={() => {
            onMenuClick(SortState.DESC);
          }}
          icon={ContextMenuIcon.SORT_DESC}
          text={t('txt_sort_desc')}
          configTheme={configTheme}
        />
      </>
    );
  } else {
    return (
      <>
        {sort === SortState.ASC ? (
          <ContextMenuItem
            onClick={() => {
              onMenuClick(SortState.DESC);
            }}
            icon={ContextMenuIcon.SORT_DESC}
            text={t('txt_sort_desc')}
            configTheme={configTheme}
          />
        ) : (
          <ContextMenuItem
            onClick={() => {
              onMenuClick(SortState.ASC);
            }}
            icon={ContextMenuIcon.SORT_ASC}
            text={t('txt_sort_asc')}
            configTheme={configTheme}
          />
        )}
        <ContextMenuItem
          onClick={() => {
            onMenuClick(null);
          }}
          icon={ContextMenuIcon.REMOVE}
          text={t('txt_remove_sort')}
          configTheme={configTheme}
        />
      </>
    );
  }
};

export default SortMenuItems;
