import {
  CSSProperties,
  ReactNode,
  RefObject,
  forwardRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { css, cx } from '../../core/emotion';

const Text = ({
  children,
  className,
  searchHeaderTextRef,
}: {
  children: ReactNode;
  className?: string;
  searchHeaderTextRef: RefObject<HTMLParagraphElement>;
}) => {
  const theme = useTheme();

  const wrapperStyled = useMemo(() => {
    return css({
      color: theme.getGlobalTheme().getDark500Color(),
      backgroundColor: theme.getGlobalTheme().getDark50Color(),
    });
  }, [theme]);

  return (
    <p
      className={cx(
        'dropdown-header flex flex-col px-4 py-2 text-left text-xs',
        className,
        wrapperStyled
      )}
      ref={searchHeaderTextRef}
    >
      <span className="dropdown-header">{children}</span>
    </p>
  );
};

type SearchHeaderProps = {
  isSearching: boolean;
  configThemeHeader?: string;
  isMultiSelect?: boolean;
  style: CSSProperties;
  className?: string;
  searchHeaderTextRef: RefObject<HTMLParagraphElement>;
};

const SearchHeader = forwardRef<HTMLDivElement, SearchHeaderProps>(
  (
    {
      isSearching,
      configThemeHeader,
      isMultiSelect,
      style,
      className,
      searchHeaderTextRef,
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div style={style} ref={ref} className={className}>
        <Text
          className={configThemeHeader}
          searchHeaderTextRef={searchHeaderTextRef}
        >
          {isSearching
            ? t('txt_search_result')
            : isMultiSelect
            ? t('txt_multiselection_allowed')
            : t('txt_dropdown_options')}
        </Text>
      </div>
    );
  }
);

export default SearchHeader;
