import { RecordInfo } from '../type';
import { Error } from '../validator/index';

export const getTotalError = ({
  dataSetLength,
  errors,
  columnsLength,
  dataInfos,
}: {
  dataSetLength: number;
  errors: (Error | null)[][];
  columnsLength: number;
  dataInfos: Record<string, RecordInfo[]>;
}) => {
  let counter = 0;
  for (let i = 0; i < dataSetLength; i++) {
    for (let j = 0; j < columnsLength; j++) {
      const hasValidateError = errors[i]?.[j];
      const hasInfoError = dataInfos[i]?.find(
        (entry) => entry.colIndex === j && entry.popover.level === 'error'
      );

      if (hasValidateError || hasInfoError) {
        counter++;
      }
    }
  }

  return counter;
};

export const getTotalErrorRows = ({
  dataSetLength,
  errors,
  columnsLength,
  dataInfos,
}: {
  dataSetLength: number;
  errors: (Error | null)[][];
  columnsLength: number;
  dataInfos: Record<string, RecordInfo[]>;
}) => {
  let counter = 0;
  for (let i = 0; i < dataSetLength; i++) {
    let hasError = false;
    for (let j = 0; j < columnsLength; j++) {
      const hasValidateError = errors[i]?.[j];
      const hasInfoError = dataInfos[i]?.find(
        (entry) => entry.colIndex === j && entry.popover.level === 'error'
      );

      if (hasValidateError || hasInfoError) {
        hasError = true;
        continue;
      }
    }

    if (hasError) {
      counter++;
    }
  }

  return counter;
};
