/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Comlink from 'comlink';
import { isArray } from 'lodash';

import { DataModelSerializer } from '../../dataModel/serializer';
import { DataModel } from '../../dataModel/model/DataModel';
import { DataModelJSON } from '../../dataModel/serializer/type';

Comlink.transferHandlers.set('DataModelArray', {
  canHandle: ((obj: unknown) => {
    if (isArray(obj)) {
      return obj[0] instanceof DataModel;
    } else {
      return false;
    }
  }) as any,
  serialize: (instance: DataModel[]) => {
    return [
      instance.map((item) => {
        return DataModelSerializer.serialize(item);
      }) as any,
      [],
    ];
  },
  deserialize: (obj: DataModelJSON[]) => {
    return obj.map((item) => {
      return DataModelSerializer.deSerialize(item);
    });
  },
});
