import { RefObject } from 'react';
import Menu from './Menu';
import useViewModel, { Option } from './viewModel';
import MenuSelector from './MenuSelector';
import { isNil } from 'lodash';
import { ConfigTheme, Size } from './type';
import { HotTableClass } from '@handsontable/react';

type DropdownProps<T> = {
  value: T;
  onChange: (value: T) => void;
  options: Option<T>[];
  placeholder?: string;
  size?: Size;
  configTheme?: ConfigTheme;
  hotInstance?: RefObject<HotTableClass>;
  modal?: boolean;
};

const Dropdown = <T,>({
  value,
  onChange,
  options,
  placeholder,
  size = 'base',
  configTheme,
  hotInstance,
  modal,
}: DropdownProps<T>) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    popper,
    shownValue,
    maxHeight,
  } = useViewModel<T>({
    value,
    onChange,
    options,
    hotInstance,
    modal,
  });
  return (
    <div>
      <MenuSelector
        shownValue={shownValue}
        placeholder={placeholder}
        hasSelected={!isNil(value)}
        configTheme={configTheme}
        size={size}
        {...getToggleButtonProps({
          ref: popper.referenceElement as unknown as RefObject<HTMLButtonElement>,
        })}
      />
      <Menu
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        popper={popper}
        options={options}
        size={size}
        configTheme={configTheme}
        maxHeight={maxHeight}
      />
    </div>
  );
};

export default Dropdown;
