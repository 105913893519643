import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { GridSettings } from 'handsontable/settings';
import { isNumber } from 'lodash';
import { compareFunctionFactory as defaultCompareFunctionFactory } from './default';
import { parseNumberStringToNumber } from '../../../../number';
import { FIRST_AFTER_SECOND, FIRST_BEFORE_SECOND } from './constant';
import Handsontable from 'handsontable';

const parseValue = (value: string) => {
  if (Handsontable.helper.isEmpty(value)) {
    return { parsedValue: value, isValid: false };
  }

  if (isNumber(value)) {
    return { parsedValue: value, isValid: true };
  } else {
    const sourceValue = value.replace('%', '');
    const valueNumber = parseNumberStringToNumber(sourceValue, true);
    const isValid = !isNaN(valueNumber);

    if (isValid) {
      const target = valueNumber / 100;
      return { parsedValue: target, isValid: true };
    } else {
      return { parsedValue: value, isValid: false };
    }
  }
};

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: any, nextValue: any) => {
    const { parsedValue, isValid: isValueValid } = parseValue(value);
    const { parsedValue: parsedNextValue, isValid: isNextValueValid } =
      parseValue(nextValue);

    if (isValueValid && !isNextValueValid) {
      return FIRST_BEFORE_SECOND;
    } else if (!isValueValid && isNextValueValid) {
      return FIRST_AFTER_SECOND;
    } else {
      return defaultCompareFunctionFactory(sortOrder, columnMeta)(
        parsedValue,
        parsedNextValue
      );
    }
  };
}
