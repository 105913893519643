/* eslint-disable react/jsx-no-useless-fragment */

import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({
  children,
  enable = true,
  root,
}: {
  children: ReactNode;
  enable?: boolean;
  root?: HTMLElement;
}) => {
  if (enable) {
    return <>{createPortal(children, root ?? document.body)}</>;
  } else {
    return <>{children}</>;
  }
};

export default Portal;
