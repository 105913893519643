import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CleaningAssistantContext } from '../../context';
import { ICleaningAssistantContext } from '../../index.types';
import Button from '../../../../../../../components/Button';
import styles from './index.style';
import { ReactComponent as Sparkle } from './../../../../../../../assets/icon/sparkle.svg';
import { ReactComponent as ToggleIcon } from './../../../../../../../assets/icon/receipt-sparkles.svg';

const Toggle = () => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const { onOpenPopover, count } = context;
  const s = styles(context);

  return (
    <Button type="button" className={s.rootClass} onClick={onOpenPopover}>
      <Sparkle className={s.sparkleClassA} />
      <Sparkle className={s.sparkleClassB} />
      {count > 0 && <div className={s.badgeClass}>{count}</div>}
      <ToggleIcon className={s.iconClass} />
      {t('txt_clean_data')}
    </Button>
  );
};

export default Toggle;
