import { Option } from './dataModel/model/CategoryDataModel';

export const CURRENCY_CODE_OPTIONS: Option[] = [
  {
    label: 'AFN',
    value: 'AFN',
    type: 'string',
    alternativeMatches: ['Af', 'Afghanistan'],
  },
  {
    label: 'ARS',
    value: 'ARS',
    type: 'string',
    alternativeMatches: ['AR$', 'Argentina'],
  },
  {
    label: 'AWG',
    value: 'AWG',
    type: 'string',
    alternativeMatches: ['Aruba'],
  },
  {
    label: 'AUD',
    value: 'AUD',
    type: 'string',
    alternativeMatches: ['AU$', 'Australia'],
  },
  {
    label: 'AZN',
    value: 'AZN',
    type: 'string',
    alternativeMatches: ['man.', 'Azerbaijan'],
  },
  {
    label: 'BSD',
    value: 'BSD',
    type: 'string',
    alternativeMatches: ['Bahamas'],
  },
  {
    label: 'BBD',
    value: 'BBD',
    type: 'string',
    alternativeMatches: ['Barbados'],
  },
  {
    label: 'BYN',
    value: 'BYN',
    type: 'string',
    alternativeMatches: ['Br'],
  },
  {
    label: 'BZD',
    value: 'BZD',
    type: 'string',
    alternativeMatches: ['BZ$', 'Belize'],
  },
  {
    label: 'BMD',
    value: 'BMD',
    type: 'string',
    alternativeMatches: ['Bermuda'],
  },
  {
    label: 'BOB',
    value: 'BOB',
    type: 'string',
    alternativeMatches: ['Bs', 'Bolivia'],
  },
  {
    label: 'BAM',
    value: 'BAM',
    type: 'string',
    alternativeMatches: ['KM', 'Bosnia and Herzegovina'],
  },
  {
    label: 'BWP',
    value: 'BWP',
    type: 'string',
    alternativeMatches: ['BWP', 'Botswana'],
  },
  {
    label: 'BGN',
    value: 'BGN',
    type: 'string',
    alternativeMatches: ['BGN', 'Bulgaria'],
  },
  {
    label: 'BRL',
    value: 'BRL',
    type: 'string',
    alternativeMatches: ['R$', 'Brazil'],
  },
  {
    label: 'BND',
    value: 'BND',
    type: 'string',
    alternativeMatches: ['BN$', 'Brunei'],
  },
  {
    label: 'KHR',
    value: 'KHR',
    type: 'string',
    alternativeMatches: ['KHR', 'Cambodia'],
  },
  {
    label: 'CAD',
    value: 'CAD',
    type: 'string',
    alternativeMatches: ['CA$', 'Canada'],
  },
  {
    label: 'KYD',
    value: 'KYD',
    type: 'string',
    alternativeMatches: ['Cayman Islands'],
  },
  {
    label: 'CLP',
    value: 'CLP',
    type: 'string',
    alternativeMatches: ['CL$', 'Chile'],
  },
  {
    label: 'CNY',
    value: 'CNY',
    type: 'string',
    alternativeMatches: ['CN¥', 'China'],
  },
  {
    label: 'COP',
    value: 'COP',
    type: 'string',
    alternativeMatches: ['CO$', 'Colombia'],
  },
  {
    label: 'CRC',
    value: 'CRC',
    type: 'string',
    alternativeMatches: ['₡', 'Costa Rica'],
  },
  {
    label: 'HRK',
    value: 'HRK',
    type: 'string',
    alternativeMatches: ['kn', 'Croatia'],
  },
  {
    label: 'CUP',
    value: 'CUP',
    type: 'string',
    alternativeMatches: ['Cuba'],
  },
  {
    label: 'CZK',
    value: 'CZK',
    type: 'string',
    alternativeMatches: ['Kč', 'Czech Republic'],
  },
  {
    label: 'DKK',
    value: 'DKK',
    type: 'string',
    alternativeMatches: ['Dkr', 'Denmark'],
  },
  {
    label: 'DOP',
    value: 'DOP',
    type: 'string',
    alternativeMatches: ['RD$', 'Dominican Republic'],
  },
  {
    label: 'XCD',
    value: 'XCD',
    type: 'string',
    alternativeMatches: ['Anguilla'],
  },
  {
    label: 'EGP',
    value: 'EGP',
    type: 'string',
    alternativeMatches: ['EGP', 'Egypt'],
  },
  {
    label: 'SVC',
    value: 'SVC',
    type: 'string',
    alternativeMatches: ['El Salvador'],
  },
  {
    label: 'EUR',
    value: 'EUR',
    type: 'string',
    alternativeMatches: ['€', 'Germany'],
  },
  {
    label: 'FKP',
    value: 'FKP',
    type: 'string',
    alternativeMatches: ['Falkland Islands'],
  },
  {
    label: 'FJD',
    value: 'FJD',
    type: 'string',
    alternativeMatches: ['Fiji Islands'],
  },
  {
    label: 'GHS',
    value: 'GHS',
    type: 'string',
    alternativeMatches: ['GH₵', 'Ghana'],
  },
  {
    label: 'GIP',
    value: 'GIP',
    type: 'string',
    alternativeMatches: ['Gibraltar'],
  },
  {
    label: 'GTQ',
    value: 'GTQ',
    type: 'string',
    alternativeMatches: ['GTQ'],
  },
  {
    label: 'GGP',
    value: 'GGP',
    type: 'string',
    alternativeMatches: [],
  },
  {
    label: 'GYD',
    value: 'GYD',
    type: 'string',
    alternativeMatches: ['Guyana'],
  },
  {
    label: 'HNL',
    value: 'HNL',
    type: 'string',
    alternativeMatches: ['HNL', 'Honduras'],
  },
  {
    label: 'HKD',
    value: 'HKD',
    type: 'string',
    alternativeMatches: ['HK$', 'Hong Kong'],
  },
  {
    label: 'HUF',
    value: 'HUF',
    type: 'string',
    alternativeMatches: ['Ft', 'Hungary'],
  },
  {
    label: 'ISK',
    value: 'ISK',
    type: 'string',
    alternativeMatches: ['Ikr', 'Iceland'],
  },
  {
    label: 'INR',
    value: 'INR',
    type: 'string',
    alternativeMatches: ['₹', 'India'],
  },
  {
    label: 'IDR',
    value: 'IDR',
    type: 'string',
    alternativeMatches: ['Rp', 'Indonesia'],
  },
  {
    label: 'IRR',
    value: 'IRR',
    type: 'string',
    alternativeMatches: ['IRR', 'Iran'],
  },
  {
    label: 'IMP',
    value: 'IMP',
    type: 'string',
    alternativeMatches: [],
  },
  {
    label: 'ILS',
    value: 'ILS',
    type: 'string',
    alternativeMatches: ['₪', 'Israel'],
  },
  {
    label: 'JMD',
    value: 'JMD',
    type: 'string',
    alternativeMatches: ['J$', 'Jamaica'],
  },
  {
    label: 'JPY',
    value: 'JPY',
    type: 'string',
    alternativeMatches: ['¥', 'Japan'],
  },
  {
    label: 'JEP',
    value: 'JEP',
    type: 'string',
    alternativeMatches: [],
  },
  {
    label: 'KZT',
    value: 'KZT',
    type: 'string',
    alternativeMatches: ['KZT', 'Kazakhstan'],
  },
  {
    label: 'KPW',
    value: 'KPW',
    type: 'string',
    alternativeMatches: ['North Korea'],
  },
  {
    label: 'KRW',
    value: 'KRW',
    type: 'string',
    alternativeMatches: ['₩', 'South Korea'],
  },
  {
    label: 'KGS',
    value: 'KGS',
    type: 'string',
    alternativeMatches: ['Kyrgyzstan'],
  },
  {
    label: 'LAK',
    value: 'LAK',
    type: 'string',
    alternativeMatches: ['Laos'],
  },
  {
    label: 'LBP',
    value: 'LBP',
    type: 'string',
    alternativeMatches: ['L.L.', 'Lebanon'],
  },
  {
    label: 'LRD',
    value: 'LRD',
    type: 'string',
    alternativeMatches: ['Liberia'],
  },
  {
    label: 'MKD',
    value: 'MKD',
    type: 'string',
    alternativeMatches: ['MKD', 'North Macedonia'],
  },
  {
    label: 'MYR',
    value: 'MYR',
    type: 'string',
    alternativeMatches: ['RM', 'Malaysia'],
  },
  {
    label: 'MUR',
    value: 'MUR',
    type: 'string',
    alternativeMatches: ['MURs', 'Mauritius'],
  },
  {
    label: 'MXN',
    value: 'MXN',
    type: 'string',
    alternativeMatches: ['MX$', 'Mexico'],
  },
  {
    label: 'MNT',
    value: 'MNT',
    type: 'string',
    alternativeMatches: ['Mongolia'],
  },
  {
    label: 'MZN',
    value: 'MZN',
    type: 'string',
    alternativeMatches: ['MTn', 'Mozambique'],
  },
  {
    label: 'NAD',
    value: 'NAD',
    type: 'string',
    alternativeMatches: ['N$', 'Namibia'],
  },
  {
    label: 'NPR',
    value: 'NPR',
    type: 'string',
    alternativeMatches: ['NPRs', 'Nepal'],
  },
  {
    label: 'ANG',
    value: 'ANG',
    type: 'string',
    alternativeMatches: ['Netherlands Antilles'],
  },
  {
    label: 'NZD',
    value: 'NZD',
    type: 'string',
    alternativeMatches: ['NZ$', 'Cook Islands'],
  },
  {
    label: 'NIO',
    value: 'NIO',
    type: 'string',
    alternativeMatches: ['C$', 'Nicaragua'],
  },
  {
    label: 'NGN',
    value: 'NGN',
    type: 'string',
    alternativeMatches: ['₦', 'Nigeria'],
  },
  {
    label: 'NOK',
    value: 'NOK',
    type: 'string',
    alternativeMatches: ['Nkr', 'Bouvet Island'],
  },
  {
    label: 'OMR',
    value: 'OMR',
    type: 'string',
    alternativeMatches: ['OMR', 'Oman'],
  },
  {
    label: 'PKR',
    value: 'PKR',
    type: 'string',
    alternativeMatches: ['PKRs', 'Pakistan'],
  },
  {
    label: 'PAB',
    value: 'PAB',
    type: 'string',
    alternativeMatches: ['B/.', 'Panama'],
  },
  {
    label: 'PYG',
    value: 'PYG',
    type: 'string',
    alternativeMatches: ['₲', 'Paraguay'],
  },
  {
    label: 'PEN',
    value: 'PEN',
    type: 'string',
    alternativeMatches: ['S/.', 'Peru'],
  },
  {
    label: 'PHP',
    value: 'PHP',
    type: 'string',
    alternativeMatches: ['₱', 'Philippines'],
  },
  {
    label: 'PLN',
    value: 'PLN',
    type: 'string',
    alternativeMatches: ['zł', 'Poland'],
  },
  {
    label: 'QAR',
    value: 'QAR',
    type: 'string',
    alternativeMatches: ['QR', 'Qatar'],
  },
  {
    label: 'RON',
    value: 'RON',
    type: 'string',
    alternativeMatches: ['RON', 'Romania'],
  },
  {
    label: 'RUB',
    value: 'RUB',
    type: 'string',
    alternativeMatches: ['RUB', 'Russian Federation'],
  },
  {
    label: 'SHP',
    value: 'SHP',
    type: 'string',
    alternativeMatches: ['Saint Helena'],
  },
  {
    label: 'SAR',
    value: 'SAR',
    type: 'string',
    alternativeMatches: ['SR', 'Saudi Arabia'],
  },
  {
    label: 'RSD',
    value: 'RSD',
    type: 'string',
    alternativeMatches: ['din.', 'Serbia'],
  },
  {
    label: 'SCR',
    value: 'SCR',
    type: 'string',
    alternativeMatches: ['Seychelles'],
  },
  {
    label: 'SGD',
    value: 'SGD',
    type: 'string',
    alternativeMatches: ['S$', 'Singapore'],
  },
  {
    label: 'SBD',
    value: 'SBD',
    type: 'string',
    alternativeMatches: ['Solomon Islands'],
  },
  {
    label: 'SOS',
    value: 'SOS',
    type: 'string',
    alternativeMatches: ['Ssh', 'Somalia'],
  },
  {
    label: 'ZAR',
    value: 'ZAR',
    type: 'string',
    alternativeMatches: ['R', 'South Africa'],
  },
  {
    label: 'LKR',
    value: 'LKR',
    type: 'string',
    alternativeMatches: ['SLRs', 'Sri Lanka'],
  },
  {
    label: 'SEK',
    value: 'SEK',
    type: 'string',
    alternativeMatches: ['Skr', 'Sweden'],
  },
  {
    label: 'CHF',
    value: 'CHF',
    type: 'string',
    alternativeMatches: ['CHF', 'Liechtenstein'],
  },
  {
    label: 'SRD',
    value: 'SRD',
    type: 'string',
    alternativeMatches: ['Suriname'],
  },
  {
    label: 'SYP',
    value: 'SYP',
    type: 'string',
    alternativeMatches: ['SY£', 'Syria'],
  },
  {
    label: 'TWD',
    value: 'TWD',
    type: 'string',
    alternativeMatches: ['NT$'],
  },
  {
    label: 'THB',
    value: 'THB',
    type: 'string',
    alternativeMatches: ['฿', 'Thailand'],
  },
  {
    label: 'TTD',
    value: 'TTD',
    type: 'string',
    alternativeMatches: ['TT$', 'Trinidad and Tobago'],
  },
  {
    label: 'TRY',
    value: 'TRY',
    type: 'string',
    alternativeMatches: ['TL', 'Turkey'],
  },
  {
    label: 'TVD',
    value: 'TVD',
    type: 'string',
    alternativeMatches: [],
  },
  {
    label: 'UAH',
    value: 'UAH',
    type: 'string',
    alternativeMatches: ['₴', 'Ukraine'],
  },
  {
    label: 'GBP',
    value: 'GBP',
    type: 'string',
    alternativeMatches: ['£', 'England'],
  },
  {
    label: 'USD',
    value: 'USD',
    type: 'string',
    alternativeMatches: ['$', 'USA'],
  },
  {
    label: 'UYU',
    value: 'UYU',
    type: 'string',
    alternativeMatches: ['$U', 'Uruguay'],
  },
  {
    label: 'UZS',
    value: 'UZS',
    type: 'string',
    alternativeMatches: ['UZS', 'Uzbekistan'],
  },
  {
    label: 'VEF',
    value: 'VEF',
    type: 'string',
    alternativeMatches: ['Bs.F.', 'Venezuela'],
  },
  {
    label: 'VND',
    value: 'VND',
    type: 'string',
    alternativeMatches: ['₫', 'Vietnam'],
  },
  {
    label: 'YER',
    value: 'YER',
    type: 'string',
    alternativeMatches: ['YR', 'Yemen'],
  },
  {
    label: 'ZWD',
    value: 'ZWD',
    type: 'string',
    alternativeMatches: ['Zimbabwe'],
  },
];

export const COUNTRY_CODE_ALPHA_2_OPTIONS: Option[] = [
  {
    label: 'AF',
    value: 'AF',
    type: 'string',
    alternativeMatches: ['Afghanistan'],
  },
  {
    label: 'AX',
    value: 'AX',
    type: 'string',
    alternativeMatches: ['Aland Islands'],
  },
  {
    label: 'AL',
    value: 'AL',
    type: 'string',
    alternativeMatches: ['Albania'],
  },
  {
    label: 'DZ',
    value: 'DZ',
    type: 'string',
    alternativeMatches: ['Algeria'],
  },
  {
    label: 'AS',
    value: 'AS',
    type: 'string',
    alternativeMatches: ['American Samoa'],
  },
  {
    label: 'AD',
    value: 'AD',
    type: 'string',
    alternativeMatches: ['Andorra'],
  },
  {
    label: 'AO',
    value: 'AO',
    type: 'string',
    alternativeMatches: ['Angola'],
  },
  {
    label: 'AI',
    value: 'AI',
    type: 'string',
    alternativeMatches: ['Anguilla'],
  },
  {
    label: 'AQ',
    value: 'AQ',
    type: 'string',
    alternativeMatches: ['Antarctica'],
  },
  {
    label: 'AG',
    value: 'AG',
    type: 'string',
    alternativeMatches: ['Antigua And Barbuda', 'Antigua', 'Barbuda'],
  },
  {
    label: 'AR',
    value: 'AR',
    type: 'string',
    alternativeMatches: ['Argentina'],
  },
  {
    label: 'AM',
    value: 'AM',
    type: 'string',
    alternativeMatches: ['Armenia'],
  },
  {
    label: 'AW',
    value: 'AW',
    type: 'string',
    alternativeMatches: ['Aruba'],
  },
  {
    label: 'AU',
    value: 'AU',
    type: 'string',
    alternativeMatches: ['Australia'],
  },
  {
    label: 'AT',
    value: 'AT',
    type: 'string',
    alternativeMatches: ['Austria'],
  },
  {
    label: 'AZ',
    value: 'AZ',
    type: 'string',
    alternativeMatches: ['Azerbaijan'],
  },
  {
    label: 'BS',
    value: 'BS',
    type: 'string',
    alternativeMatches: ['Bahamas'],
  },
  {
    label: 'BH',
    value: 'BH',
    type: 'string',
    alternativeMatches: ['Bahrain'],
  },
  {
    label: 'BD',
    value: 'BD',
    type: 'string',
    alternativeMatches: ['Bangladesh'],
  },
  {
    label: 'BB',
    value: 'BB',
    type: 'string',
    alternativeMatches: ['Barbados'],
  },
  {
    label: 'BY',
    value: 'BY',
    type: 'string',
    alternativeMatches: ['Belarus'],
  },
  {
    label: 'BE',
    value: 'BE',
    type: 'string',
    alternativeMatches: ['Belgium'],
  },
  {
    label: 'BZ',
    value: 'BZ',
    type: 'string',
    alternativeMatches: ['Belize'],
  },
  {
    label: 'BJ',
    value: 'BJ',
    type: 'string',
    alternativeMatches: ['Benin'],
  },
  {
    label: 'BM',
    value: 'BM',
    type: 'string',
    alternativeMatches: ['Bermuda'],
  },
  {
    label: 'BT',
    value: 'BT',
    type: 'string',
    alternativeMatches: ['Bhutan'],
  },
  {
    label: 'BO',
    value: 'BO',
    type: 'string',
    alternativeMatches: ['Bolivia'],
  },
  {
    label: 'BA',
    value: 'BA',
    type: 'string',
    alternativeMatches: ['Bosnia And Herzegovina', 'Bosnia', 'Herzegovina'],
  },
  {
    label: 'BW',
    value: 'BW',
    type: 'string',
    alternativeMatches: ['Botswana'],
  },
  {
    label: 'BV',
    value: 'BV',
    type: 'string',
    alternativeMatches: ['Bouvet Island'],
  },
  {
    label: 'BR',
    value: 'BR',
    type: 'string',
    alternativeMatches: ['Brazil'],
  },
  {
    label: 'IO',
    value: 'IO',
    type: 'string',
    alternativeMatches: ['British Indian Ocean Territory'],
  },
  {
    label: 'BN',
    value: 'BN',
    type: 'string',
    alternativeMatches: ['Brunei Darussalam'],
  },
  {
    label: 'BG',
    value: 'BG',
    type: 'string',
    alternativeMatches: ['Bulgaria'],
  },
  {
    label: 'BF',
    value: 'BF',
    type: 'string',
    alternativeMatches: ['Burkina Faso'],
  },
  {
    label: 'BI',
    value: 'BI',
    type: 'string',
    alternativeMatches: ['Burundi'],
  },
  {
    label: 'KH',
    value: 'KH',
    type: 'string',
    alternativeMatches: ['Cambodia'],
  },
  {
    label: 'CM',
    value: 'CM',
    type: 'string',
    alternativeMatches: ['Cameroon'],
  },
  {
    label: 'CA',
    value: 'CA',
    type: 'string',
    alternativeMatches: ['Canada'],
  },
  {
    label: 'CV',
    value: 'CV',
    type: 'string',
    alternativeMatches: ['Cape Verde'],
  },
  {
    label: 'KY',
    value: 'KY',
    type: 'string',
    alternativeMatches: ['Cayman Islands'],
  },
  {
    label: 'CF',
    value: 'CF',
    type: 'string',
    alternativeMatches: ['Central African Republic'],
  },
  {
    label: 'TD',
    value: 'TD',
    type: 'string',
    alternativeMatches: ['Chad'],
  },
  {
    label: 'CL',
    value: 'CL',
    type: 'string',
    alternativeMatches: ['Chile'],
  },
  {
    label: 'CN',
    value: 'CN',
    type: 'string',
    alternativeMatches: ['China'],
  },
  {
    label: 'CX',
    value: 'CX',
    type: 'string',
    alternativeMatches: ['Christmas Island'],
  },
  {
    label: 'CC',
    value: 'CC',
    type: 'string',
    alternativeMatches: ['Cocos (Keeling) Islands'],
  },
  {
    label: 'CO',
    value: 'CO',
    type: 'string',
    alternativeMatches: ['Colombia'],
  },
  {
    label: 'KM',
    value: 'KM',
    type: 'string',
    alternativeMatches: ['Comoros'],
  },
  {
    label: 'CG',
    value: 'CG',
    type: 'string',
    alternativeMatches: ['Congo'],
  },
  {
    label: 'CD',
    value: 'CD',
    type: 'string',
    alternativeMatches: ['Congo (the Democratic Republic of the)'],
  },
  {
    label: 'CK',
    value: 'CK',
    type: 'string',
    alternativeMatches: ['Cook Islands'],
  },
  {
    label: 'CR',
    value: 'CR',
    type: 'string',
    alternativeMatches: ['Costa Rica'],
  },
  {
    label: 'CI',
    value: 'CI',
    type: 'string',
    alternativeMatches: ["Côte d'Ivoire"],
  },
  {
    label: 'HR',
    value: 'HR',
    type: 'string',
    alternativeMatches: ['Croatia'],
  },
  {
    label: 'CU',
    value: 'CU',
    type: 'string',
    alternativeMatches: ['Cuba'],
  },
  {
    label: 'CY',
    value: 'CY',
    type: 'string',
    alternativeMatches: ['Cyprus'],
  },
  {
    label: 'CZ',
    value: 'CZ',
    type: 'string',
    alternativeMatches: ['Czech Republic'],
  },
  {
    label: 'DK',
    value: 'DK',
    type: 'string',
    alternativeMatches: ['Denmark'],
  },
  {
    label: 'DJ',
    value: 'DJ',
    type: 'string',
    alternativeMatches: ['Djibouti'],
  },
  {
    label: 'DM',
    value: 'DM',
    type: 'string',
    alternativeMatches: ['Dominica'],
  },
  {
    label: 'DO',
    value: 'DO',
    type: 'string',
    alternativeMatches: ['Dominican Republic'],
  },
  {
    label: 'EC',
    value: 'EC',
    type: 'string',
    alternativeMatches: ['Ecuador'],
  },
  {
    label: 'EG',
    value: 'EG',
    type: 'string',
    alternativeMatches: ['Egypt'],
  },
  {
    label: 'SV',
    value: 'SV',
    type: 'string',
    alternativeMatches: ['El Salvador'],
  },
  {
    label: 'GQ',
    value: 'GQ',
    type: 'string',
    alternativeMatches: ['Equatorial Guinea'],
  },
  {
    label: 'ER',
    value: 'ER',
    type: 'string',
    alternativeMatches: ['Eritrea'],
  },
  {
    label: 'EE',
    value: 'EE',
    type: 'string',
    alternativeMatches: ['Estonia'],
  },
  {
    label: 'ET',
    value: 'ET',
    type: 'string',
    alternativeMatches: ['Ethiopia'],
  },
  {
    label: 'FK',
    value: 'FK',
    type: 'string',
    alternativeMatches: ['Falkland Islands (Malvinas)'],
  },
  {
    label: 'FO',
    value: 'FO',
    type: 'string',
    alternativeMatches: ['Faroe Islands'],
  },
  {
    label: 'FJ',
    value: 'FJ',
    type: 'string',
    alternativeMatches: ['Fiji'],
  },
  {
    label: 'FI',
    value: 'FI',
    type: 'string',
    alternativeMatches: ['Finland'],
  },
  {
    label: 'FR',
    value: 'FR',
    type: 'string',
    alternativeMatches: ['France'],
  },
  {
    label: 'GF',
    value: 'GF',
    type: 'string',
    alternativeMatches: ['French Guiana'],
  },
  {
    label: 'PF',
    value: 'PF',
    type: 'string',
    alternativeMatches: ['French Polynesia'],
  },
  {
    label: 'TF',
    value: 'TF',
    type: 'string',
    alternativeMatches: ['French Southern Territories'],
  },
  {
    label: 'GA',
    value: 'GA',
    type: 'string',
    alternativeMatches: ['Gabon'],
  },
  {
    label: 'GM',
    value: 'GM',
    type: 'string',
    alternativeMatches: ['Gambia'],
  },
  {
    label: 'GE',
    value: 'GE',
    type: 'string',
    alternativeMatches: ['Georgia'],
  },
  {
    label: 'DE',
    value: 'DE',
    type: 'string',
    alternativeMatches: ['Germany'],
  },
  {
    label: 'GH',
    value: 'GH',
    type: 'string',
    alternativeMatches: ['Ghana'],
  },
  {
    label: 'GI',
    value: 'GI',
    type: 'string',
    alternativeMatches: ['Gibraltar'],
  },
  {
    label: 'GR',
    value: 'GR',
    type: 'string',
    alternativeMatches: ['Greece'],
  },
  {
    label: 'GL',
    value: 'GL',
    type: 'string',
    alternativeMatches: ['Greenland'],
  },
  {
    label: 'GD',
    value: 'GD',
    type: 'string',
    alternativeMatches: ['Grenada'],
  },
  {
    label: 'GP',
    value: 'GP',
    type: 'string',
    alternativeMatches: ['Guadeloupe'],
  },
  {
    label: 'GU',
    value: 'GU',
    type: 'string',
    alternativeMatches: ['Guam'],
  },
  {
    label: 'GT',
    value: 'GT',
    type: 'string',
    alternativeMatches: ['Guatemala'],
  },
  {
    label: 'GG',
    value: 'GG',
    type: 'string',
    alternativeMatches: ['Guernsey'],
  },
  {
    label: 'GN',
    value: 'GN',
    type: 'string',
    alternativeMatches: ['Guinea'],
  },
  {
    label: 'GW',
    value: 'GW',
    type: 'string',
    alternativeMatches: ['Guinea-Bissau'],
  },
  {
    label: 'GY',
    value: 'GY',
    type: 'string',
    alternativeMatches: ['Guyana'],
  },
  {
    label: 'HT',
    value: 'HT',
    type: 'string',
    alternativeMatches: ['Haiti'],
  },
  {
    label: 'HM',
    value: 'HM',
    type: 'string',
    alternativeMatches: [
      'Heard Island and McDonald Islands',
      'Heard Island',
      'McDonald Islands',
    ],
  },
  {
    label: 'VA',
    value: 'VA',
    type: 'string',
    alternativeMatches: ['Holy See (Vatican City State)'],
  },
  {
    label: 'HN',
    value: 'HN',
    type: 'string',
    alternativeMatches: ['Honduras'],
  },
  {
    label: 'HK',
    value: 'HK',
    type: 'string',
    alternativeMatches: ['Hong Kong'],
  },
  {
    label: 'HU',
    value: 'HU',
    type: 'string',
    alternativeMatches: ['Hungary'],
  },
  {
    label: 'IS',
    value: 'IS',
    type: 'string',
    alternativeMatches: ['Iceland'],
  },
  {
    label: 'IN',
    value: 'IN',
    type: 'string',
    alternativeMatches: ['India'],
  },
  {
    label: 'ID',
    value: 'ID',
    type: 'string',
    alternativeMatches: ['Indonesia'],
  },
  {
    label: 'IR',
    value: 'IR',
    type: 'string',
    alternativeMatches: ['Iran (Islamic Republic of)	', 'Iran'],
  },
  {
    label: 'IQ',
    value: 'IQ',
    type: 'string',
    alternativeMatches: ['Iraq'],
  },
  {
    label: 'IE',
    value: 'IE',
    type: 'string',
    alternativeMatches: ['Ireland'],
  },
  {
    label: 'IM',
    value: 'IM',
    type: 'string',
    alternativeMatches: ['Isle Of Man'],
  },
  {
    label: 'IL',
    value: 'IL',
    type: 'string',
    alternativeMatches: ['Israel'],
  },
  {
    label: 'IT',
    value: 'IT',
    type: 'string',
    alternativeMatches: ['Italy'],
  },
  {
    label: 'JM',
    value: 'JM',
    type: 'string',
    alternativeMatches: ['Jamaica'],
  },
  {
    label: 'JP',
    value: 'JP',
    type: 'string',
    alternativeMatches: ['Japan'],
  },
  {
    label: 'JE',
    value: 'JE',
    type: 'string',
    alternativeMatches: ['Jersey'],
  },
  {
    label: 'JO',
    value: 'JO',
    type: 'string',
    alternativeMatches: ['Jordan'],
  },
  {
    label: 'KZ',
    value: 'KZ',
    type: 'string',
    alternativeMatches: ['Kazakhstan'],
  },
  {
    label: 'KE',
    value: 'KE',
    type: 'string',
    alternativeMatches: ['Kenya'],
  },
  {
    label: 'KI',
    value: 'KI',
    type: 'string',
    alternativeMatches: ['Kiribati'],
  },
  {
    label: 'KR',
    value: 'KR',
    type: 'string',
    alternativeMatches: ['Korea, Republic of Korea', 'South Korea'],
  },
  {
    label: 'KP',
    value: 'KP',
    type: 'string',
    alternativeMatches: [
      "Democratic People's Republic of Korea",
      'North Korea',
    ],
  },
  {
    label: 'KW',
    value: 'KW',
    type: 'string',
    alternativeMatches: ['Kuwait'],
  },
  {
    label: 'KG',
    value: 'KG',
    type: 'string',
    alternativeMatches: ['Kyrgyzstan'],
  },
  {
    label: 'LA',
    value: 'LA',
    type: 'string',
    alternativeMatches: ["Lao People's Democratic Republic"],
  },
  {
    label: 'LV',
    value: 'LV',
    type: 'string',
    alternativeMatches: ['Latvia'],
  },
  {
    label: 'LB',
    value: 'LB',
    type: 'string',
    alternativeMatches: ['Lebanon'],
  },
  {
    label: 'LS',
    value: 'LS',
    type: 'string',
    alternativeMatches: ['Lesotho'],
  },
  {
    label: 'LR',
    value: 'LR',
    type: 'string',
    alternativeMatches: ['Liberia'],
  },
  {
    label: 'LY',
    value: 'LY',
    type: 'string',
    alternativeMatches: ['Libya'],
  },
  {
    label: 'LI',
    value: 'LI',
    type: 'string',
    alternativeMatches: ['Liechtenstein'],
  },
  {
    label: 'LT',
    value: 'LT',
    type: 'string',
    alternativeMatches: ['Lithuania'],
  },
  {
    label: 'LU',
    value: 'LU',
    type: 'string',
    alternativeMatches: ['Luxembourg'],
  },
  {
    label: 'MO',
    value: 'MO',
    type: 'string',
    alternativeMatches: ['Macao'],
  },
  {
    label: 'MK',
    value: 'MK',
    type: 'string',
    alternativeMatches: ['Macedonia'],
  },
  {
    label: 'MG',
    value: 'MG',
    type: 'string',
    alternativeMatches: ['Madagascar'],
  },
  {
    label: 'MW',
    value: 'MW',
    type: 'string',
    alternativeMatches: ['Malawi'],
  },
  {
    label: 'MY',
    value: 'MY',
    type: 'string',
    alternativeMatches: ['Malaysia'],
  },
  {
    label: 'MV',
    value: 'MV',
    type: 'string',
    alternativeMatches: ['Maldives'],
  },
  {
    label: 'ML',
    value: 'ML',
    type: 'string',
    alternativeMatches: ['Mali'],
  },
  {
    label: 'MT',
    value: 'MT',
    type: 'string',
    alternativeMatches: ['Malta'],
  },
  {
    label: 'MH',
    value: 'MH',
    type: 'string',
    alternativeMatches: ['Marshall Islands'],
  },
  {
    label: 'MQ',
    value: 'MQ',
    type: 'string',
    alternativeMatches: ['Martinique'],
  },
  {
    label: 'MR',
    value: 'MR',
    type: 'string',
    alternativeMatches: ['Mauritania'],
  },
  {
    label: 'MU',
    value: 'MU',
    type: 'string',
    alternativeMatches: ['Mauritius'],
  },
  {
    label: 'YT',
    value: 'YT',
    type: 'string',
    alternativeMatches: ['Mayotte'],
  },
  {
    label: 'MX',
    value: 'MX',
    type: 'string',
    alternativeMatches: ['Mexico'],
  },
  {
    label: 'FM',
    value: 'FM',
    type: 'string',
    alternativeMatches: ['Micronesia, Federated States Of'],
  },
  {
    label: 'MD',
    value: 'MD',
    type: 'string',
    alternativeMatches: ['Moldova (the Republic of)	'],
  },
  {
    label: 'MC',
    value: 'MC',
    type: 'string',
    alternativeMatches: ['Monaco'],
  },
  {
    label: 'MN',
    value: 'MN',
    type: 'string',
    alternativeMatches: ['Mongolia'],
  },
  {
    label: 'ME',
    value: 'ME',
    type: 'string',
    alternativeMatches: ['Montenegro'],
  },
  {
    label: 'MS',
    value: 'MS',
    type: 'string',
    alternativeMatches: ['Montserrat'],
  },
  {
    label: 'MA',
    value: 'MA',
    type: 'string',
    alternativeMatches: ['Morocco'],
  },
  {
    label: 'MZ',
    value: 'MZ',
    type: 'string',
    alternativeMatches: ['Mozambique'],
  },
  {
    label: 'MM',
    value: 'MM',
    type: 'string',
    alternativeMatches: ['Myanmar'],
  },
  {
    label: 'NA',
    value: 'NA',
    type: 'string',
    alternativeMatches: ['Namibia'],
  },
  {
    label: 'NR',
    value: 'NR',
    type: 'string',
    alternativeMatches: ['Nauru'],
  },
  {
    label: 'NP',
    value: 'NP',
    type: 'string',
    alternativeMatches: ['Nepal'],
  },
  {
    label: 'NL',
    value: 'NL',
    type: 'string',
    alternativeMatches: ['Netherlands'],
  },
  {
    label: 'AN',
    value: 'AN',
    type: 'string',
    alternativeMatches: ['Netherlands Antilles'],
  },
  {
    label: 'NC',
    value: 'NC',
    type: 'string',
    alternativeMatches: ['New Caledonia'],
  },
  {
    label: 'NZ',
    value: 'NZ',
    type: 'string',
    alternativeMatches: ['New Zealand'],
  },
  {
    label: 'NI',
    value: 'NI',
    type: 'string',
    alternativeMatches: ['Nicaragua'],
  },
  {
    label: 'NE',
    value: 'NE',
    type: 'string',
    alternativeMatches: ['Niger'],
  },
  {
    label: 'NG',
    value: 'NG',
    type: 'string',
    alternativeMatches: ['Nigeria'],
  },
  {
    label: 'NU',
    value: 'NU',
    type: 'string',
    alternativeMatches: ['Niue'],
  },
  {
    label: 'NF',
    value: 'NF',
    type: 'string',
    alternativeMatches: ['Norfolk Island'],
  },
  {
    label: 'MP',
    value: 'MP',
    type: 'string',
    alternativeMatches: ['Northern Mariana Islands'],
  },
  {
    label: 'NO',
    value: 'NO',
    type: 'string',
    alternativeMatches: ['Norway'],
  },
  {
    label: 'OM',
    value: 'OM',
    type: 'string',
    alternativeMatches: ['Oman'],
  },
  {
    label: 'PK',
    value: 'PK',
    type: 'string',
    alternativeMatches: ['Pakistan'],
  },
  {
    label: 'PW',
    value: 'PW',
    type: 'string',
    alternativeMatches: ['Palau'],
  },
  {
    label: 'PS',
    value: 'PS',
    type: 'string',
    alternativeMatches: ['Palestinian Territory, Occupied'],
  },
  {
    label: 'PA',
    value: 'PA',
    type: 'string',
    alternativeMatches: ['Panama'],
  },
  {
    label: 'PG',
    value: 'PG',
    type: 'string',
    alternativeMatches: ['Papua New Guinea'],
  },
  {
    label: 'PY',
    value: 'PY',
    type: 'string',
    alternativeMatches: ['Paraguay'],
  },
  {
    label: 'PE',
    value: 'PE',
    type: 'string',
    alternativeMatches: ['Peru'],
  },
  {
    label: 'PH',
    value: 'PH',
    type: 'string',
    alternativeMatches: ['Philippines'],
  },
  {
    label: 'PN',
    value: 'PN',
    type: 'string',
    alternativeMatches: ['Pitcairn'],
  },
  {
    label: 'PL',
    value: 'PL',
    type: 'string',
    alternativeMatches: ['Poland'],
  },
  {
    label: 'PT',
    value: 'PT',
    type: 'string',
    alternativeMatches: ['Portugal'],
  },
  {
    label: 'PR',
    value: 'PR',
    type: 'string',
    alternativeMatches: ['Puerto Rico'],
  },
  {
    label: 'QA',
    value: 'QA',
    type: 'string',
    alternativeMatches: ['Qatar'],
  },
  {
    label: 'RE',
    value: 'RE',
    type: 'string',
    alternativeMatches: ['Reunion'],
  },
  {
    label: 'RO',
    value: 'RO',
    type: 'string',
    alternativeMatches: ['Romania'],
  },
  {
    label: 'RU',
    value: 'RU',
    type: 'string',
    alternativeMatches: ['Russian Federation'],
  },
  {
    label: 'RW',
    value: 'RW',
    type: 'string',
    alternativeMatches: ['Rwanda'],
  },
  {
    label: 'BL',
    value: 'BL',
    type: 'string',
    alternativeMatches: ['Saint Barthelemy'],
  },
  {
    label: 'SH',
    value: 'SH',
    type: 'string',
    alternativeMatches: ['Saint Helena'],
  },
  {
    label: 'KN',
    value: 'KN',
    type: 'string',
    alternativeMatches: ['Saint Kitts And Nevis'],
  },
  {
    label: 'LC',
    value: 'LC',
    type: 'string',
    alternativeMatches: ['Saint Lucia'],
  },
  {
    label: 'MF',
    value: 'MF',
    type: 'string',
    alternativeMatches: ['Saint Martin'],
  },
  {
    label: 'PM',
    value: 'PM',
    type: 'string',
    alternativeMatches: [
      'Saint Pierre and Miquelon',
      'Saint Pierre',
      'Miquelon',
    ],
  },
  {
    label: 'VC',
    value: 'VC',
    type: 'string',
    alternativeMatches: [
      'Saint Vincent and the Grenadines',
      'Saint Vincent',
      'Grenadines',
    ],
  },
  {
    label: 'WS',
    value: 'WS',
    type: 'string',
    alternativeMatches: ['Samoa'],
  },
  {
    label: 'SM',
    value: 'SM',
    type: 'string',
    alternativeMatches: ['San Marino'],
  },
  {
    label: 'ST',
    value: 'ST',
    type: 'string',
    alternativeMatches: ['Sao Tome and Principe', 'Sao Tome', 'Principe'],
  },
  {
    label: 'SA',
    value: 'SA',
    type: 'string',
    alternativeMatches: ['Saudi Arabia'],
  },
  {
    label: 'SN',
    value: 'SN',
    type: 'string',
    alternativeMatches: ['Senegal'],
  },
  {
    label: 'RS',
    value: 'RS',
    type: 'string',
    alternativeMatches: ['Serbia'],
  },
  {
    label: 'SC',
    value: 'SC',
    type: 'string',
    alternativeMatches: ['Seychelles'],
  },
  {
    label: 'SL',
    value: 'SL',
    type: 'string',
    alternativeMatches: ['Sierra Leone'],
  },
  {
    label: 'SG',
    value: 'SG',
    type: 'string',
    alternativeMatches: ['Singapore'],
  },
  {
    label: 'SK',
    value: 'SK',
    type: 'string',
    alternativeMatches: ['Slovakia'],
  },
  {
    label: 'SI',
    value: 'SI',
    type: 'string',
    alternativeMatches: ['Slovenia'],
  },
  {
    label: 'SB',
    value: 'SB',
    type: 'string',
    alternativeMatches: ['Solomon Islands'],
  },
  {
    label: 'SO',
    value: 'SO',
    type: 'string',
    alternativeMatches: ['Somalia'],
  },
  {
    label: 'ZA',
    value: 'ZA',
    type: 'string',
    alternativeMatches: ['South Africa'],
  },
  {
    label: 'GS',
    value: 'GS',
    type: 'string',
    alternativeMatches: [
      'South Georgia and the South Sandwich Islands',
      'South Georgia',
      'South Sandwich Islands',
    ],
  },
  {
    label: 'ES',
    value: 'ES',
    type: 'string',
    alternativeMatches: ['Spain'],
  },
  {
    label: 'LK',
    value: 'LK',
    type: 'string',
    alternativeMatches: ['Sri Lanka'],
  },
  {
    label: 'SD',
    value: 'SD',
    type: 'string',
    alternativeMatches: ['Sudan'],
  },
  {
    label: 'SR',
    value: 'SR',
    type: 'string',
    alternativeMatches: ['Suriname'],
  },
  {
    label: 'SJ',
    value: 'SJ',
    type: 'string',
    alternativeMatches: ['Svalbard and Jan Mayen', 'Svalbard', 'Jan Mayen'],
  },
  {
    label: 'SZ',
    value: 'SZ',
    type: 'string',
    alternativeMatches: ['Swaziland'],
  },
  {
    label: 'SE',
    value: 'SE',
    type: 'string',
    alternativeMatches: ['Sweden'],
  },
  {
    label: 'CH',
    value: 'CH',
    type: 'string',
    alternativeMatches: ['Switzerland'],
  },
  {
    label: 'SY',
    value: 'SY',
    type: 'string',
    alternativeMatches: ['Syrian Arab Republic'],
  },
  {
    label: 'TW',
    value: 'TW',
    type: 'string',
    alternativeMatches: ['Taiwan'],
  },
  {
    label: 'TJ',
    value: 'TJ',
    type: 'string',
    alternativeMatches: ['Tajikistan'],
  },
  {
    label: 'TZ',
    value: 'TZ',
    type: 'string',
    alternativeMatches: ['Tanzania'],
  },
  {
    label: 'TH',
    value: 'TH',
    type: 'string',
    alternativeMatches: ['Thailand'],
  },
  {
    label: 'TL',
    value: 'TL',
    type: 'string',
    alternativeMatches: ['Timor-Leste'],
  },
  {
    label: 'TG',
    value: 'TG',
    type: 'string',
    alternativeMatches: ['Togo'],
  },
  {
    label: 'TK',
    value: 'TK',
    type: 'string',
    alternativeMatches: ['Tokelau'],
  },
  {
    label: 'TO',
    value: 'TO',
    type: 'string',
    alternativeMatches: ['Tonga'],
  },
  {
    label: 'TT',
    value: 'TT',
    type: 'string',
    alternativeMatches: ['Trinidad and Tobago', 'Trinidad', 'Tobago'],
  },
  {
    label: 'TN',
    value: 'TN',
    type: 'string',
    alternativeMatches: ['Tunisia'],
  },
  {
    label: 'TR',
    value: 'TR',
    type: 'string',
    alternativeMatches: ['Turkey'],
  },
  {
    label: 'TM',
    value: 'TM',
    type: 'string',
    alternativeMatches: ['Turkmenistan'],
  },
  {
    label: 'TC',
    value: 'TC',
    type: 'string',
    alternativeMatches: ['Turks And Caicos Islands'],
  },
  {
    label: 'TV',
    value: 'TV',
    type: 'string',
    alternativeMatches: ['Tuvalu'],
  },
  {
    label: 'UG',
    value: 'UG',
    type: 'string',
    alternativeMatches: ['Uganda'],
  },
  {
    label: 'UA',
    value: 'UA',
    type: 'string',
    alternativeMatches: ['Ukraine'],
  },
  {
    label: 'AE',
    value: 'AE',
    type: 'string',
    alternativeMatches: ['United Arab Emirates'],
  },
  {
    label: 'GB',
    value: 'GB',
    type: 'string',
    alternativeMatches: ['United Kingdom'],
  },
  {
    label: 'US',
    value: 'US',
    type: 'string',
    alternativeMatches: ['United States', 'USA'],
  },
  {
    label: 'UM',
    value: 'UM',
    type: 'string',
    alternativeMatches: ['United States Minor Outlying Islands'],
  },
  {
    label: 'UY',
    value: 'UY',
    type: 'string',
    alternativeMatches: ['Uruguay'],
  },
  {
    label: 'UZ',
    value: 'UZ',
    type: 'string',
    alternativeMatches: ['Uzbekistan'],
  },
  {
    label: 'VU',
    value: 'VU',
    type: 'string',
    alternativeMatches: ['Vanuatu'],
  },
  {
    label: 'VE',
    value: 'VE',
    type: 'string',
    alternativeMatches: ['Venezuela'],
  },
  {
    label: 'VN',
    value: 'VN',
    type: 'string',
    alternativeMatches: ['Vietnam'],
  },
  {
    label: 'VG',
    value: 'VG',
    type: 'string',
    alternativeMatches: ['Virgin Islands (British)', 'British'],
  },
  {
    label: 'VI',
    value: 'VI',
    type: 'string',
    alternativeMatches: ['Virgin Islands (U.S.)'],
  },
  {
    label: 'WF',
    value: 'WF',
    type: 'string',
    alternativeMatches: ['Wallis And Futuna'],
  },
  {
    label: 'EH',
    value: 'EH',
    type: 'string',
    alternativeMatches: ['Western Sahara'],
  },
  {
    label: 'YE',
    value: 'YE',
    type: 'string',
    alternativeMatches: ['Yemen'],
  },
  {
    label: 'ZM',
    value: 'ZM',
    type: 'string',
    alternativeMatches: ['Zambia'],
  },
  {
    label: 'ZW',
    value: 'ZW',
    type: 'string',
    alternativeMatches: ['Zimbabwe'],
  },
];

export const COUNTRY_CODE_ALPHA_3_OPTIONS: Option[] = [
  {
    value: 'AFG',
    label: 'AFG',
    type: 'string',
    alternativeMatches: ['Afghanistan'],
  },
  {
    value: 'ALA',
    label: 'ALA',
    type: 'string',
    alternativeMatches: ['Åland Islands'],
  },
  {
    value: 'ALB',
    label: 'ALB',
    type: 'string',
    alternativeMatches: ['Albania'],
  },
  {
    value: 'DZA',
    label: 'DZA',
    type: 'string',
    alternativeMatches: ['Algeria'],
  },
  {
    value: 'ASM',
    label: 'ASM',
    type: 'string',
    alternativeMatches: ['American Samoa'],
  },
  {
    value: 'AND',
    label: 'AND',
    type: 'string',
    alternativeMatches: ['Andorra'],
  },
  {
    value: 'AGO',
    label: 'AGO',
    type: 'string',
    alternativeMatches: ['Angola'],
  },
  {
    value: 'AIA',
    label: 'AIA',
    type: 'string',
    alternativeMatches: ['Anguilla'],
  },
  {
    value: 'ATA',
    label: 'ATA',
    type: 'string',
    alternativeMatches: ['Antarctica'],
  },
  {
    value: 'ATG',
    label: 'ATG',
    type: 'string',
    alternativeMatches: ['Antigua and Barbuda', 'Antigua', 'Barbuda'],
  },
  {
    value: 'ARG',
    label: 'ARG',
    type: 'string',
    alternativeMatches: ['Argentina'],
  },
  {
    value: 'ARM',
    label: 'ARM',
    type: 'string',
    alternativeMatches: ['Armenia'],
  },
  {
    value: 'ABW',
    label: 'ABW',
    type: 'string',
    alternativeMatches: ['Aruba'],
  },
  {
    value: 'AUS',
    label: 'AUS',
    type: 'string',
    alternativeMatches: ['Australia'],
  },
  {
    value: 'AUT',
    label: 'AUT',
    type: 'string',
    alternativeMatches: ['Austria'],
  },
  {
    value: 'AZE',
    label: 'AZE',
    type: 'string',
    alternativeMatches: ['Azerbaijan'],
  },
  {
    value: 'BHS',
    label: 'BHS',
    type: 'string',
    alternativeMatches: ['Bahamas'],
  },
  {
    value: 'BHR',
    label: 'BHR',
    type: 'string',
    alternativeMatches: ['Bahrain'],
  },
  {
    value: 'BGD',
    label: 'BGD',
    type: 'string',
    alternativeMatches: ['Bangladesh'],
  },
  {
    value: 'BRB',
    label: 'BRB',
    type: 'string',
    alternativeMatches: ['Barbados'],
  },
  {
    value: 'BLR',
    label: 'BLR',
    type: 'string',
    alternativeMatches: ['Belarus'],
  },
  {
    value: 'BEL',
    label: 'BEL',
    type: 'string',
    alternativeMatches: ['Belgium'],
  },
  {
    value: 'BLZ',
    label: 'BLZ',
    type: 'string',
    alternativeMatches: ['Belize'],
  },
  {
    value: 'BEN',
    label: 'BEN',
    type: 'string',
    alternativeMatches: ['Benin'],
  },
  {
    value: 'BMU',
    label: 'BMU',
    type: 'string',
    alternativeMatches: ['Bermuda'],
  },
  {
    value: 'BTN',
    label: 'BTN',
    type: 'string',
    alternativeMatches: ['Bhutan'],
  },
  {
    value: 'BOL',
    label: 'BOL',
    type: 'string',
    alternativeMatches: ['Bolivia (Plurinational State of)', 'Bolivia'],
  },
  {
    value: 'BES',
    label: 'BES',
    type: 'string',
    alternativeMatches: ['Bonaire, Sint Eustatius and Saba'],
  },
  {
    value: 'BIH',
    label: 'BIH',
    type: 'string',
    alternativeMatches: ['Bosnia and Herzegovina'],
  },
  {
    value: 'BWA',
    label: 'BWA',
    type: 'string',
    alternativeMatches: ['Botswana'],
  },
  {
    value: 'BVT',
    label: 'BVT',
    type: 'string',
    alternativeMatches: ['Bouvet Island'],
  },
  {
    value: 'BRA',
    label: 'BRA',
    type: 'string',
    alternativeMatches: ['Brazil'],
  },
  {
    value: 'IOT',
    label: 'IOT',
    type: 'string',
    alternativeMatches: ['British Indian Ocean Territory'],
  },
  {
    value: 'BRN',
    label: 'BRN',
    type: 'string',
    alternativeMatches: ['Brunei Darussalam'],
  },
  {
    value: 'BGR',
    label: 'BGR',
    type: 'string',
    alternativeMatches: ['Bulgaria'],
  },
  {
    value: 'BFA',
    label: 'BFA',
    type: 'string',
    alternativeMatches: ['Burkina Faso'],
  },
  {
    value: 'BDI',
    label: 'BDI',
    type: 'string',
    alternativeMatches: ['Burundi'],
  },
  {
    value: 'KHM',
    label: 'KHM',
    type: 'string',
    alternativeMatches: ['Cambodia'],
  },
  {
    value: 'CMR',
    label: 'CMR',
    type: 'string',
    alternativeMatches: ['Cameroon'],
  },
  {
    value: 'CAN',
    label: 'CAN',
    type: 'string',
    alternativeMatches: ['Canada'],
  },
  {
    value: 'CPV',
    label: 'CPV',
    type: 'string',
    alternativeMatches: ['Cape Verde'],
  },
  {
    value: 'CYM',
    label: 'CYM',
    type: 'string',
    alternativeMatches: ['Cayman Islands'],
  },
  {
    value: 'CAF',
    label: 'CAF',
    type: 'string',
    alternativeMatches: ['Central African Republic'],
  },
  {
    value: 'TCD',
    label: 'TCD',
    type: 'string',
    alternativeMatches: ['Chad'],
  },
  {
    value: 'CHL',
    label: 'CHL',
    type: 'string',
    alternativeMatches: ['Chile'],
  },
  {
    value: 'CHN',
    label: 'CHN',
    type: 'string',
    alternativeMatches: ['China'],
  },
  {
    value: 'CXR',
    label: 'CXR',
    type: 'string',
    alternativeMatches: ['Christmas Island'],
  },
  {
    value: 'CCK',
    label: 'CCK',
    type: 'string',
    alternativeMatches: ['Cocos (Keeling) Islands'],
  },
  {
    value: 'COL',
    label: 'COL',
    type: 'string',
    alternativeMatches: ['Colombia'],
  },
  {
    value: 'COM',
    label: 'COM',
    type: 'string',
    alternativeMatches: ['Comoros'],
  },
  {
    value: 'COG',
    label: 'COG',
    type: 'string',
    alternativeMatches: ['Congo'],
  },
  {
    value: 'COD',
    label: 'COD',
    type: 'string',
    alternativeMatches: ['Congo (the Democratic Republic of the)'],
  },
  {
    value: 'COK',
    label: 'COK',
    type: 'string',
    alternativeMatches: ['Cook Islands'],
  },
  {
    value: 'CRI',
    label: 'CRI',
    type: 'string',
    alternativeMatches: ['Costa Rica'],
  },
  {
    value: 'CIV',
    label: 'CIV',
    type: 'string',
    alternativeMatches: ["Côte d'Ivoire"],
  },
  {
    value: 'HRV',
    label: 'HRV',
    type: 'string',
    alternativeMatches: ['Croatia'],
  },
  {
    value: 'CUB',
    label: 'CUB',
    type: 'string',
    alternativeMatches: ['Cuba'],
  },
  {
    value: 'CUW',
    label: 'CUW',
    type: 'string',
    alternativeMatches: ['Curaçao'],
  },
  {
    value: 'CYP',
    label: 'CYP',
    type: 'string',
    alternativeMatches: ['Cyprus'],
  },
  {
    value: 'CZE',
    label: 'CZE',
    type: 'string',
    alternativeMatches: ['Czech Republic'],
  },
  {
    value: 'DNK',
    label: 'DNK',
    type: 'string',
    alternativeMatches: ['Denmark'],
  },
  {
    value: 'DJI',
    label: 'DJI',
    type: 'string',
    alternativeMatches: ['Djibouti'],
  },
  {
    value: 'DMA',
    label: 'DMA',
    type: 'string',
    alternativeMatches: ['Dominica'],
  },
  {
    value: 'DOM',
    label: 'DOM',
    type: 'string',
    alternativeMatches: ['Dominican Republic'],
  },
  {
    value: 'ECU',
    label: 'ECU',
    type: 'string',
    alternativeMatches: ['Ecuador'],
  },
  {
    value: 'EGY',
    label: 'EGY',
    type: 'string',
    alternativeMatches: ['Egypt'],
  },
  {
    value: 'SLV',
    label: 'SLV',
    type: 'string',
    alternativeMatches: ['El Salvador'],
  },
  {
    value: 'GNQ',
    label: 'GNQ',
    type: 'string',
    alternativeMatches: ['Equatorial Guinea'],
  },
  {
    value: 'ERI',
    label: 'ERI',
    type: 'string',
    alternativeMatches: ['Eritrea'],
  },
  {
    value: 'EST',
    label: 'EST',
    type: 'string',
    alternativeMatches: ['Estonia'],
  },
  {
    value: 'ETH',
    label: 'ETH',
    type: 'string',
    alternativeMatches: ['Ethiopia'],
  },
  {
    value: 'FLK',
    label: 'FLK',
    type: 'string',
    alternativeMatches: ['Falkland Islands (Malvinas)'],
  },
  {
    value: 'FRO',
    label: 'FRO',
    type: 'string',
    alternativeMatches: ['Faroe Islands'],
  },
  {
    value: 'FJI',
    label: 'FJI',
    type: 'string',
    alternativeMatches: ['Fiji'],
  },
  {
    value: 'FIN',
    label: 'FIN',
    type: 'string',
    alternativeMatches: ['Finland'],
  },
  {
    value: 'FRA',
    label: 'FRA',
    type: 'string',
    alternativeMatches: ['France'],
  },
  {
    value: 'GUF',
    label: 'GUF',
    type: 'string',
    alternativeMatches: ['French Guiana'],
  },
  {
    value: 'PYF',
    label: 'PYF',
    type: 'string',
    alternativeMatches: ['French Polynesia'],
  },
  {
    value: 'ATF',
    label: 'ATF',
    type: 'string',
    alternativeMatches: ['French Southern Territories'],
  },
  {
    value: 'GAB',
    label: 'GAB',
    type: 'string',
    alternativeMatches: ['Gabon'],
  },
  {
    value: 'GMB',
    label: 'GMB',
    type: 'string',
    alternativeMatches: ['Gambia'],
  },
  {
    value: 'GEO',
    label: 'GEO',
    type: 'string',
    alternativeMatches: ['Georgia'],
  },
  {
    value: 'DEU',
    label: 'DEU',
    type: 'string',
    alternativeMatches: ['Germany'],
  },
  {
    value: 'GHA',
    label: 'GHA',
    type: 'string',
    alternativeMatches: ['Ghana'],
  },
  {
    value: 'GIB',
    label: 'GIB',
    type: 'string',
    alternativeMatches: ['Gibraltar'],
  },
  {
    value: 'GRC',
    label: 'GRC',
    type: 'string',
    alternativeMatches: ['Greece'],
  },
  {
    value: 'GRL',
    label: 'GRL',
    type: 'string',
    alternativeMatches: ['Greenland'],
  },
  {
    value: 'GRD',
    label: 'GRD',
    type: 'string',
    alternativeMatches: ['Grenada'],
  },
  {
    value: 'GLP',
    label: 'GLP',
    type: 'string',
    alternativeMatches: ['Guadeloupe'],
  },
  {
    value: 'GUM',
    label: 'GUM',
    type: 'string',
    alternativeMatches: ['Guam'],
  },
  {
    value: 'GTM',
    label: 'GTM',
    type: 'string',
    alternativeMatches: ['Guatemala'],
  },
  {
    value: 'GGY',
    label: 'GGY',
    type: 'string',
    alternativeMatches: ['Guernsey'],
  },
  {
    value: 'GIN',
    label: 'GIN',
    type: 'string',
    alternativeMatches: ['Guinea'],
  },
  {
    value: 'GNB',
    label: 'GNB',
    type: 'string',
    alternativeMatches: ['Guinea-Bissau'],
  },
  {
    value: 'GUY',
    label: 'GUY',
    type: 'string',
    alternativeMatches: ['Guyana'],
  },
  {
    value: 'HTI',
    label: 'HTI',
    type: 'string',
    alternativeMatches: ['Haiti'],
  },
  {
    value: 'HMD',
    label: 'HMD',
    type: 'string',
    alternativeMatches: [
      'Heard Island and McDonald Islands',
      'Heard Island',
      'McDonald Islands',
    ],
  },
  {
    value: 'VAT',
    label: 'VAT',
    type: 'string',
    alternativeMatches: ['Holy See (Vatican City State)'],
  },
  {
    value: 'HND',
    label: 'HND',
    type: 'string',
    alternativeMatches: ['Honduras'],
  },
  {
    value: 'HKG',
    label: 'HKG',
    type: 'string',
    alternativeMatches: ['Hong Kong'],
  },
  {
    value: 'HUN',
    label: 'HUN',
    type: 'string',
    alternativeMatches: ['Hungary'],
  },
  {
    value: 'ISL',
    label: 'ISL',
    type: 'string',
    alternativeMatches: ['Iceland'],
  },
  {
    value: 'IND',
    label: 'IND',
    type: 'string',
    alternativeMatches: ['India'],
  },
  {
    value: 'IDN',
    label: 'IDN',
    type: 'string',
    alternativeMatches: ['Indonesia'],
  },
  {
    value: 'IRN',
    label: 'IRN',
    type: 'string',
    alternativeMatches: ['Iran (Islamic Republic of)	', 'Iran'],
  },
  {
    value: 'IRQ',
    label: 'IRQ',
    type: 'string',
    alternativeMatches: ['Iraq'],
  },
  {
    value: 'IRL',
    label: 'IRL',
    type: 'string',
    alternativeMatches: ['Ireland'],
  },
  {
    value: 'IMN',
    label: 'IMN',
    type: 'string',
    alternativeMatches: ['Isle of Man'],
  },
  {
    value: 'ISR',
    label: 'ISR',
    type: 'string',
    alternativeMatches: ['Israel'],
  },
  {
    value: 'ITA',
    label: 'ITA',
    type: 'string',
    alternativeMatches: ['Italy'],
  },
  {
    value: 'JAM',
    label: 'JAM',
    type: 'string',
    alternativeMatches: ['Jamaica'],
  },
  {
    value: 'JPN',
    label: 'JPN',
    type: 'string',
    alternativeMatches: ['Japan'],
  },
  {
    value: 'JEY',
    label: 'JEY',
    type: 'string',
    alternativeMatches: ['Jersey'],
  },
  {
    value: 'JOR',
    label: 'JOR',
    type: 'string',
    alternativeMatches: ['Jordan'],
  },
  {
    value: 'KAZ',
    label: 'KAZ',
    type: 'string',
    alternativeMatches: ['Kazakhstan'],
  },
  {
    value: 'KEN',
    label: 'KEN',
    type: 'string',
    alternativeMatches: ['Kenya'],
  },
  {
    value: 'KIR',
    label: 'KIR',
    type: 'string',
    alternativeMatches: ['Kiribati'],
  },
  {
    value: 'PRK',
    label: 'PRK',
    type: 'string',
    alternativeMatches: [
      "Democratic People's Republic of Korea",
      'North Korea',
    ],
  },
  {
    value: 'KOR',
    label: 'KOR',
    type: 'string',
    alternativeMatches: ['Korea, Republic of Korea', 'South Korea'],
  },
  {
    value: 'KWT',
    label: 'KWT',
    type: 'string',
    alternativeMatches: ['Kuwait'],
  },
  {
    value: 'KGZ',
    label: 'KGZ',
    type: 'string',
    alternativeMatches: ['Kyrgyzstan'],
  },
  {
    value: 'LAO',
    label: 'LAO',
    type: 'string',
    alternativeMatches: ["Lao People's Democratic Republic"],
  },
  {
    value: 'LVA',
    label: 'LVA',
    type: 'string',
    alternativeMatches: ['Latvia'],
  },
  {
    value: 'LBN',
    label: 'LBN',
    type: 'string',
    alternativeMatches: ['Lebanon'],
  },
  {
    value: 'LSO',
    label: 'LSO',
    type: 'string',
    alternativeMatches: ['Lesotho'],
  },
  {
    value: 'LBR',
    label: 'LBR',
    type: 'string',
    alternativeMatches: ['Liberia'],
  },
  {
    value: 'LBY',
    label: 'LBY',
    type: 'string',
    alternativeMatches: ['Libya'],
  },
  {
    value: 'LIE',
    label: 'LIE',
    type: 'string',
    alternativeMatches: ['Liechtenstein'],
  },
  {
    value: 'LTU',
    label: 'LTU',
    type: 'string',
    alternativeMatches: ['Lithuania'],
  },
  {
    value: 'LUX',
    label: 'LUX',
    type: 'string',
    alternativeMatches: ['Luxembourg'],
  },
  {
    value: 'MAC',
    label: 'MAC',
    type: 'string',
    alternativeMatches: ['Macao'],
  },
  {
    value: 'MKD',
    label: 'MKD',
    type: 'string',
    alternativeMatches: ['Republic of North Macedonia', 'North Macedonia'],
  },
  {
    value: 'MDG',
    label: 'MDG',
    type: 'string',
    alternativeMatches: ['Madagascar'],
  },
  {
    value: 'MWI',
    label: 'MWI',
    type: 'string',
    alternativeMatches: ['Malawi'],
  },
  {
    value: 'MYS',
    label: 'MYS',
    type: 'string',
    alternativeMatches: ['Malaysia'],
  },
  {
    value: 'MDV',
    label: 'MDV',
    type: 'string',
    alternativeMatches: ['Maldives'],
  },
  {
    value: 'MLI',
    label: 'MLI',
    type: 'string',
    alternativeMatches: ['Mali'],
  },
  {
    value: 'MLT',
    label: 'MLT',
    type: 'string',
    alternativeMatches: ['Malta'],
  },
  {
    value: 'MHL',
    label: 'MHL',
    type: 'string',
    alternativeMatches: ['Marshall Islands'],
  },
  {
    value: 'MTQ',
    label: 'MTQ',
    type: 'string',
    alternativeMatches: ['Martinique'],
  },
  {
    value: 'MRT',
    label: 'MRT',
    type: 'string',
    alternativeMatches: ['Mauritania'],
  },
  {
    value: 'MUS',
    label: 'MUS',
    type: 'string',
    alternativeMatches: ['Mauritius'],
  },
  {
    value: 'MYT',
    label: 'MYT',
    type: 'string',
    alternativeMatches: ['Mayotte'],
  },
  {
    value: 'MEX',
    label: 'MEX',
    type: 'string',
    alternativeMatches: ['Mexico'],
  },
  {
    value: 'FSM',
    label: 'FSM',
    type: 'string',
    alternativeMatches: ['Micronesia (Federated States of)'],
  },
  {
    value: 'MDA',
    label: 'MDA',
    type: 'string',
    alternativeMatches: ['Moldova (the Republic of)'],
  },
  {
    value: 'MCO',
    label: 'MCO',
    type: 'string',
    alternativeMatches: ['Monaco'],
  },
  {
    value: 'MNG',
    label: 'MNG',
    type: 'string',
    alternativeMatches: ['Mongolia'],
  },
  {
    value: 'MNE',
    label: 'MNE',
    type: 'string',
    alternativeMatches: ['Montenegro'],
  },
  {
    value: 'MSR',
    label: 'MSR',
    type: 'string',
    alternativeMatches: ['Montserrat'],
  },
  {
    value: 'MAR',
    label: 'MAR',
    type: 'string',
    alternativeMatches: ['Morocco'],
  },
  {
    value: 'MOZ',
    label: 'MOZ',
    type: 'string',
    alternativeMatches: ['Mozambique'],
  },
  {
    value: 'MMR',
    label: 'MMR',
    type: 'string',
    alternativeMatches: ['Myanmar'],
  },
  {
    value: 'NAM',
    label: 'NAM',
    type: 'string',
    alternativeMatches: ['Namibia'],
  },
  {
    value: 'NRU',
    label: 'NRU',
    type: 'string',
    alternativeMatches: ['Nauru'],
  },
  {
    value: 'NPL',
    label: 'NPL',
    type: 'string',
    alternativeMatches: ['Nepal'],
  },
  {
    value: 'NLD',
    label: 'NLD',
    type: 'string',
    alternativeMatches: ['Netherlands'],
  },
  {
    value: 'NCL',
    label: 'NCL',
    type: 'string',
    alternativeMatches: ['New Caledonia'],
  },
  {
    value: 'NZL',
    label: 'NZL',
    type: 'string',
    alternativeMatches: ['New Zealand'],
  },
  {
    value: 'NIC',
    label: 'NIC',
    type: 'string',
    alternativeMatches: ['Nicaragua'],
  },
  {
    value: 'NER',
    label: 'NER',
    type: 'string',
    alternativeMatches: ['Niger'],
  },
  {
    value: 'NGA',
    label: 'NGA',
    type: 'string',
    alternativeMatches: ['Nigeria'],
  },
  {
    value: 'NIU',
    label: 'NIU',
    type: 'string',
    alternativeMatches: ['Niue'],
  },
  {
    value: 'NFK',
    label: 'NFK',
    type: 'string',
    alternativeMatches: ['Norfolk Island'],
  },
  {
    value: 'MNP',
    label: 'MNP',
    type: 'string',
    alternativeMatches: ['Northern Mariana Islands'],
  },
  {
    value: 'NOR',
    label: 'NOR',
    type: 'string',
    alternativeMatches: ['Norway'],
  },
  {
    value: 'OMN',
    label: 'OMN',
    type: 'string',
    alternativeMatches: ['Oman'],
  },
  {
    value: 'PAK',
    label: 'PAK',
    type: 'string',
    alternativeMatches: ['Pakistan'],
  },
  {
    value: 'PLW',
    label: 'PLW',
    type: 'string',
    alternativeMatches: ['Palau'],
  },
  {
    value: 'PSE',
    label: 'PSE',
    type: 'string',
    alternativeMatches: ['Palestinian Territory, Occupied'],
  },
  {
    value: 'PAN',
    label: 'PAN',
    type: 'string',
    alternativeMatches: ['Panama'],
  },
  {
    value: 'PNG',
    label: 'PNG',
    type: 'string',
    alternativeMatches: ['Papua New Guinea'],
  },
  {
    value: 'PRY',
    label: 'PRY',
    type: 'string',
    alternativeMatches: ['Paraguay'],
  },
  {
    value: 'PER',
    label: 'PER',
    type: 'string',
    alternativeMatches: ['Peru'],
  },
  {
    value: 'PHL',
    label: 'PHL',
    type: 'string',
    alternativeMatches: ['Philippines'],
  },
  {
    value: 'PCN',
    label: 'PCN',
    type: 'string',
    alternativeMatches: ['Pitcairn'],
  },
  {
    value: 'POL',
    label: 'POL',
    type: 'string',
    alternativeMatches: ['Poland'],
  },
  {
    value: 'PRT',
    label: 'PRT',
    type: 'string',
    alternativeMatches: ['Portugal'],
  },
  {
    value: 'PRI',
    label: 'PRI',
    type: 'string',
    alternativeMatches: ['Puerto Rico'],
  },
  {
    value: 'QAT',
    label: 'QAT',
    type: 'string',
    alternativeMatches: ['Qatar'],
  },
  {
    value: 'REU',
    label: 'REU',
    type: 'string',
    alternativeMatches: ['Réunion'],
  },
  {
    value: 'ROU',
    label: 'ROU',
    type: 'string',
    alternativeMatches: ['Romania'],
  },
  {
    value: 'RUS',
    label: 'RUS',
    type: 'string',
    alternativeMatches: ['Russian Federation'],
  },
  {
    value: 'RWA',
    label: 'RWA',
    type: 'string',
    alternativeMatches: ['Rwanda'],
  },
  {
    value: 'BLM',
    label: 'BLM',
    type: 'string',
    alternativeMatches: ['Saint Barthélemy'],
  },
  {
    value: 'SHN',
    label: 'SHN',
    type: 'string',
    alternativeMatches: ['Saint Helena, Ascension and Tristan da Cunha'],
  },
  {
    value: 'KNA',
    label: 'KNA',
    type: 'string',
    alternativeMatches: ['Saint Kitts and Nevis', 'Saint Kitts', 'Nevis'],
  },
  {
    value: 'LCA',
    label: 'LCA',
    type: 'string',
    alternativeMatches: ['Saint Lucia'],
  },
  {
    value: 'MAF',
    label: 'MAF',
    type: 'string',
    alternativeMatches: ['Saint Martin (French part)'],
  },
  {
    value: 'SPM',
    label: 'SPM',
    type: 'string',
    alternativeMatches: [
      'Saint Pierre and Miquelon',
      'Saint Pierre',
      'Miquelon',
    ],
  },
  {
    value: 'VCT',
    label: 'VCT',
    type: 'string',
    alternativeMatches: [
      'Saint Vincent and the Grenadines',
      'Saint Vincent',
      'Grenadines',
    ],
  },
  {
    value: 'WSM',
    label: 'WSM',
    type: 'string',
    alternativeMatches: ['Samoa'],
  },
  {
    value: 'SMR',
    label: 'SMR',
    type: 'string',
    alternativeMatches: ['San Marino'],
  },
  {
    value: 'STP',
    label: 'STP',
    type: 'string',
    alternativeMatches: ['Sao Tome and Principe', 'Sao Tome', 'Principe'],
  },
  {
    value: 'SAU',
    label: 'SAU',
    type: 'string',
    alternativeMatches: ['Saudi Arabia'],
  },
  {
    value: 'SEN',
    label: 'SEN',
    type: 'string',
    alternativeMatches: ['Senegal'],
  },
  {
    value: 'SRB',
    label: 'SRB',
    type: 'string',
    alternativeMatches: ['Serbia'],
  },
  {
    value: 'SYC',
    label: 'SYC',
    type: 'string',
    alternativeMatches: ['Seychelles'],
  },
  {
    value: 'SLE',
    label: 'SLE',
    type: 'string',
    alternativeMatches: ['Sierra Leone'],
  },
  {
    value: 'SGP',
    label: 'SGP',
    type: 'string',
    alternativeMatches: ['Singapore'],
  },
  {
    value: 'SXM',
    label: 'SXM',
    type: 'string',
    alternativeMatches: ['Sint Maarten (Dutch part)'],
  },
  {
    value: 'SVK',
    label: 'SVK',
    type: 'string',
    alternativeMatches: ['Slovakia'],
  },
  {
    value: 'SVN',
    label: 'SVN',
    type: 'string',
    alternativeMatches: ['Slovenia'],
  },
  {
    value: 'SLB',
    label: 'SLB',
    type: 'string',
    alternativeMatches: ['Solomon Islands'],
  },
  {
    value: 'SOM',
    label: 'SOM',
    type: 'string',
    alternativeMatches: ['Somalia'],
  },
  {
    value: 'ZAF',
    label: 'ZAF',
    type: 'string',
    alternativeMatches: ['South Africa'],
  },
  {
    value: 'SGS',
    label: 'SGS',
    type: 'string',
    alternativeMatches: [
      'South Georgia and the South Sandwich Islands',
      'South Georgia',
      'South Sandwich Islands',
    ],
  },
  {
    value: 'SSD',
    label: 'SSD',
    type: 'string',
    alternativeMatches: ['South Sudan'],
  },
  {
    value: 'ESP',
    label: 'ESP',
    type: 'string',
    alternativeMatches: ['Spain'],
  },
  {
    value: 'LKA',
    label: 'LKA',
    type: 'string',
    alternativeMatches: ['Sri Lanka'],
  },
  {
    value: 'SDN',
    label: 'SDN',
    type: 'string',
    alternativeMatches: ['Sudan'],
  },
  {
    value: 'SUR',
    label: 'SUR',
    type: 'string',
    alternativeMatches: ['Suriname'],
  },
  {
    value: 'SJM',
    label: 'SJM',
    type: 'string',
    alternativeMatches: ['Svalbard and Jan Mayen', 'Svalbard', 'Jan Mayen'],
  },
  {
    value: 'SWZ',
    label: 'SWZ',
    type: 'string',
    alternativeMatches: ['Swaziland', 'Eswatini'],
  },
  {
    value: 'SWE',
    label: 'SWE',
    type: 'string',
    alternativeMatches: ['Sweden'],
  },
  {
    value: 'CHE',
    label: 'CHE',
    type: 'string',
    alternativeMatches: ['Switzerland'],
  },
  {
    value: 'SYR',
    label: 'SYR',
    type: 'string',
    alternativeMatches: ['Syrian Arab Republic'],
  },
  {
    value: 'TWN',
    label: 'TWN',
    type: 'string',
    alternativeMatches: ['Taiwan, Province of China'],
  },
  {
    value: 'TJK',
    label: 'TJK',
    type: 'string',
    alternativeMatches: ['Tajikistan'],
  },
  {
    value: 'TZA',
    label: 'TZA',
    type: 'string',
    alternativeMatches: ['Tanzania, United Republic of'],
  },
  {
    value: 'THA',
    label: 'THA',
    type: 'string',
    alternativeMatches: ['Thailand'],
  },
  {
    value: 'TLS',
    label: 'TLS',
    type: 'string',
    alternativeMatches: ['Timor-Leste'],
  },
  {
    value: 'TGO',
    label: 'TGO',
    type: 'string',
    alternativeMatches: ['Togo'],
  },
  {
    value: 'TKL',
    label: 'TKL',
    type: 'string',
    alternativeMatches: ['Tokelau'],
  },
  {
    value: 'TON',
    label: 'TON',
    type: 'string',
    alternativeMatches: ['Tonga'],
  },
  {
    value: 'TTO',
    label: 'TTO',
    type: 'string',
    alternativeMatches: ['Trinidad and Tobago', 'Trinidad', 'Tobago'],
  },
  {
    value: 'TUN',
    label: 'TUN',
    type: 'string',
    alternativeMatches: ['Tunisia'],
  },
  {
    value: 'TUR',
    label: 'TUR',
    type: 'string',
    alternativeMatches: ['Turkey'],
  },
  {
    value: 'TKM',
    label: 'TKM',
    type: 'string',
    alternativeMatches: ['Turkmenistan'],
  },
  {
    value: 'TCA',
    label: 'TCA',
    type: 'string',
    alternativeMatches: ['Turks and Caicos Islands'],
  },
  {
    value: 'TUV',
    label: 'TUV',
    type: 'string',
    alternativeMatches: ['Tuvalu'],
  },
  {
    value: 'UGA',
    label: 'UGA',
    type: 'string',
    alternativeMatches: ['Uganda'],
  },
  {
    value: 'UKR',
    label: 'UKR',
    type: 'string',
    alternativeMatches: ['Ukraine'],
  },
  {
    value: 'ARE',
    label: 'ARE',
    type: 'string',
    alternativeMatches: ['United Arab Emirates'],
  },
  {
    value: 'GBR',
    label: 'GBR',
    type: 'string',
    alternativeMatches: ['United Kingdom'],
  },
  {
    value: 'USA',
    label: 'USA',
    type: 'string',
    alternativeMatches: ['United States'],
  },
  {
    value: 'UMI',
    label: 'UMI',
    type: 'string',
    alternativeMatches: ['United States Minor Outlying Islands'],
  },
  {
    value: 'URY',
    label: 'URY',
    type: 'string',
    alternativeMatches: ['Uruguay'],
  },
  {
    value: 'UZB',
    label: 'UZB',
    type: 'string',
    alternativeMatches: ['Uzbekistan'],
  },
  {
    value: 'VUT',
    label: 'VUT',
    type: 'string',
    alternativeMatches: ['Vanuatu'],
  },
  {
    value: 'VEN',
    label: 'VEN',
    type: 'string',
    alternativeMatches: [
      'Venezuela (Bolivarian Republic of)',
      'Venezuela',
      'Bolivarian',
    ],
  },
  {
    value: 'VNM',
    label: 'VNM',
    type: 'string',
    alternativeMatches: ['Viet Nam'],
  },
  {
    value: 'VGB',
    label: 'VGB',
    type: 'string',
    alternativeMatches: ['Virgin Islands (British)', 'British'],
  },
  {
    value: 'VIR',
    label: 'VIR',
    type: 'string',
    alternativeMatches: ['Virgin Islands (U.S.)'],
  },
  {
    value: 'WLF',
    label: 'WLF',
    type: 'string',
    alternativeMatches: ['Wallis and Futuna'],
  },
  {
    value: 'ESH',
    label: 'ESH',
    type: 'string',
    alternativeMatches: ['Western Sahara'],
  },
  {
    value: 'YEM',
    label: 'YEM',
    type: 'string',
    alternativeMatches: ['Yemen'],
  },
  {
    value: 'ZMB',
    label: 'ZMB',
    type: 'string',
    alternativeMatches: ['Zambia'],
  },
  {
    value: 'ZWE',
    label: 'ZWE',
    type: 'string',
    alternativeMatches: ['Zimbabwe'],
  },
];
