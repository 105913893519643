import { Values } from 'core/value';
import { HookRowResult } from '../hooks/hooksAPI';
import { createContext, ReactNode, useContext } from 'react';

const WidgetContext = createContext<{
  onEntryInitAllRows:
    | ((data: Values) => Promise<(HookRowResult | undefined)[]>)
    | null;
  isWidget: boolean;
  functionsUsage?: FunctionsUsage;
}>({
  onEntryInitAllRows: null,
  isWidget: false,
});

export const useWidgetContext = () => {
  return useContext(WidgetContext);
};

export type FunctionsUsage = {
  dataHandler?: {
    headerStep: boolean;
    reviewStep: boolean;
  };
  cleaningFunctions?: {
    onEntryInit: boolean;
    onEntryChange: boolean;
    columnHooks: boolean;
  };
  onResults?: boolean;
  onCancel?: boolean;
};

export const WidgetProvider = ({
  onEntryInitAllRows,
  children,
  functionsUsage,
}: {
  onEntryInitAllRows: (data: Values) => Promise<(HookRowResult | undefined)[]>;
  children: ReactNode;
  functionsUsage?: FunctionsUsage;
}) => {
  return (
    <WidgetContext.Provider
      value={{ onEntryInitAllRows, isWidget: true, functionsUsage }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
