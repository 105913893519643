import { useConfigure } from 'configure';
import DataModelSheetMatcher from '../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../matching/DataModelSheetMatching';
import { useCallback, useMemo, useRef } from 'react';
import { firstValueFrom } from 'rxjs';
import { CompleteImportCleaningsDTO, CompleteImportDTO } from './services/dto';
import CompleteImportLogService from './services';
import { useLocation } from 'react-router-dom';
import { useWidgetContext } from 'main/WidgetProvider';
import { v4 as uuidv4 } from 'uuid';
import { CleaningLogsRecord } from '@nuvo-importer/common/core';

export const CompleteImportLogs = ({
  dataModelSheetMatching,
  dataModelSheetMatcher,
  headerRow,
}: {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcher: DataModelSheetMatcher;
  headerRow: string[];
}) => {
  const sessionIdRef = useRef<string>(uuidv4());
  const completeImportLogService = useMemo(() => {
    return new CompleteImportLogService();
  }, []);
  const { state: locationState } = useLocation();
  const configure = useConfigure();
  const { functionsUsage, isWidget } = useWidgetContext();

  const createLog = useCallback(
    (totalRow: number) => {
      const body = new CompleteImportDTO(
        dataModelSheetMatching,
        dataModelSheetMatcher,
        totalRow,
        headerRow,
        !!locationState.dynamicUploadStart,
        configure,
        isWidget,
        sessionIdRef.current,
        functionsUsage
      );
      return firstValueFrom(
        completeImportLogService.sendData(
          body.getBodyJSON(),
          body.getLicenseKey()
        )
      );
    },
    [
      dataModelSheetMatching,
      dataModelSheetMatcher,
      headerRow,
      locationState.dynamicUploadStart,
      configure,
      functionsUsage,
      isWidget,
      completeImportLogService,
    ]
  );

  const createCleaningsLog = useCallback(
    (logs: CleaningLogsRecord[], licenseKey: string) => {
      const body = new CompleteImportCleaningsDTO(sessionIdRef.current, logs);
      return firstValueFrom(
        completeImportLogService.sendLogsData(body.getBodyJSON(), licenseKey)
      );
    },
    [completeImportLogService]
  );

  return { createLog, createCleaningsLog };
};
