import { useMemo } from 'react';
import { DataModel } from 'dataModel';
import { useField, useForm, useFormState } from 'react-final-form';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import DataModelSheetMatcher from './../../../../matching/DataModelSheetMatcher';
import Similarity from './../../../../matching/Similarity';
import { isEmpty } from 'lodash';
import { FormValues } from '../../../DataModelSheetMatcherForm/viewModel';
import { useCollapseCard } from '../common/CollapseCard';
import { THRESHOLD } from 'core/constants/similarity';
import { useSettings } from 'settings';
import {
  CalculateSimilarityMapper,
  SpreadSheetNavigate,
} from '@nuvo-importer/common/sdk';
import { useLocation } from 'react-router-dom';
import { useConfigure } from 'configure';
import { firstValueFrom } from 'rxjs';
import { useMatching } from './../../../../matching/MatchingProvider';
import { CategoryDataModel } from '@nuvo-importer/common/core';
import { useContextCreateNewColumnModal } from '../../../CreateCustomModal/CreateNewColumnModal/CreateNewColumnModalContext';

const useViewModel = ({
  dataModelSheetMatcherModel,
  prefixName,
  sheetColumn,
  setDataModelSheetMatcher,
  setLoadingMatchingMoreOptions,
}: {
  dataModelSheetMatcherModel: DataModelSheetMatcher;
  prefixName: string;
  sheetColumn: SheetColumn;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setLoadingMatchingMoreOptions: (loadingMatchingMoreOptions: boolean) => void;
}) => {
  const dropdownName = `${prefixName}.matchedDataModel.dataModel`;
  const matchedOptionsName = `${prefixName}.matchedDataModel.matchedOptions`;
  const { toggleOpenCollapse, toggleCloseCollapse } = useCollapseCard();
  const {
    allowCustomColumns,
    allowCustomOptions: deprecatedAllowCustomOptions,
  } = useSettings();
  const { state: locationState } = useLocation();
  const { licenseKey } = useConfigure();
  const { matchingRepository } = useMatching();
  const { open } = useContextCreateNewColumnModal();

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
  };

  const getDropdownName = (rowIndex: number) => {
    return `matching[${rowIndex}].matchedDataModel.dataModel`;
  };

  const { input, meta } = useField(dropdownName, {
    defaultValue: null,
    subscription: {
      value: true,
    },
  });

  const { values } = useFormState<FormValues>({
    subscription: {
      values: true,
    },
  });

  const { change } = useForm();

  const options = useMemo(() => {
    return dataModelSheetMatcherModel.getDataModels().map((dataModel) => {
      const sheetColumnDataModelSimilarityList =
        dataModelSheetMatcherModel.getSheetColumnDataModelSimilarityList();

      const customColumn =
        dataModel.getCreator() === sheetColumn.getColumnKey();

      const sheetColumnDataModelSimilarity = customColumn
        ? new Similarity({ similarity: 0 })
        : sheetColumnDataModelSimilarityList
            .getSimilaritySheetColumn(sheetColumn, dataModel)
            ?.getSimilarity() ?? new Similarity({ similarity: 0 });

      const sheetColumnMatch = values.matching.find((match) => {
        const allowCustomOptions =
          (
            match.matchedDataModel?.dataModel as CategoryDataModel
          )?.getAllowCustomOptions?.() || false;

        if (
          allowCustomColumns ||
          deprecatedAllowCustomOptions ||
          allowCustomOptions
        ) {
          return (
            match.matchedDataModel?.dataModel === dataModel ||
            match.matchedDataModel?.dataModel?.getKey() === dataModel?.getKey()
          );
        } else {
          return match.matchedDataModel?.dataModel === dataModel;
        }
      });

      return {
        label: dataModel.getLabel(),
        value: dataModel,
        isRequire: dataModel.getIsRequired() ?? false,
        sheetColumnMatch: sheetColumnMatch,
        similarity: sheetColumnDataModelSimilarity,
        customColumn: customColumn,
        baseKey: dataModel.getBaseKey(),
        key: dataModel.getKey(),
        baseKeyCounter: dataModel.getBaseKeyCounter(),
      };
    });
  }, [
    allowCustomColumns,
    deprecatedAllowCustomOptions,
    dataModelSheetMatcherModel,
    sheetColumn,
    values.matching,
  ]);

  const sortOptionsRecommends = useMemo(() => {
    const sortOptions = options.sort((optionA, optionB) => {
      return (
        optionB.similarity.getSimilarity() - optionA.similarity.getSimilarity()
      );
    });

    let hasRecommend = false;
    const similarSorting = sortOptions.map((option) => {
      let isRecommend = false;

      if (!hasRecommend) {
        if (option.similarity.getSimilarity() > THRESHOLD) {
          isRecommend = true;
          hasRecommend = true;
        }
      }

      return {
        ...option,
        isRecommend: isRecommend,
      };
    });

    return similarSorting.sort((itemA, itemB) => {
      if (itemA.isRecommend || (itemA.customColumn && !itemB.isRecommend)) {
        return -1;
      } else if (itemA.customColumn && itemB.isRecommend) {
        return 0;
      } else {
        return 0;
      }
    });
  }, [options]);

  const rematchOptions = (
    updatedDataModel: DataModel | null,
    callback: () => void,
    paramDataModelSheetMatcher?: DataModelSheetMatcher
  ) => {
    if (matchingRepository === undefined) return;
    const currentDataModelSheetMatcherModel = paramDataModelSheetMatcher
      ? paramDataModelSheetMatcher
      : dataModelSheetMatcherModel;

    if (!updatedDataModel) {
      callback();
      change(matchedOptionsName, null);
    } else if (updatedDataModel.isDropdown()) {
      const isAlreadyMappingOptions = currentDataModelSheetMatcherModel
        .getSheetColumnDataModelOptionSimilarityList()
        .isAlreadyMapping(sheetColumn, updatedDataModel);

      if (isAlreadyMappingOptions) {
        setLoadingMatchingMoreOptions(true);
        const updatedMatchOptions =
          currentDataModelSheetMatcherModel.getMatchOptions(
            sheetColumn,
            updatedDataModel
          );
        change(matchedOptionsName, updatedMatchOptions);
        setTimeout(() => {
          callback();
          setLoadingMatchingMoreOptions(false);
        }, 0);
      } else {
        callback();
        setLoadingMatchingMoreOptions(true);
        firstValueFrom(
          matchingRepository.matchOptions({
            dataModel: updatedDataModel,
            sheetColumn,
            sheets:
              state.spreadSheetNavigate
                ?.getSpreadSheetList()
                .getSelectedSheets() ?? [],
            dataModels: currentDataModelSheetMatcherModel.getDataModels(),
            licenseKey,
          })
        )
          .then(
            ({
              sheetColumnDataModelOptionSimilarityList,
              calculateSimilarityResult: addCalculateSimilarityResult,
            }) => {
              currentDataModelSheetMatcherModel.addSheetColumnDataModelOptionSimilarityList(
                sheetColumnDataModelOptionSimilarityList
              );

              const newDataModelSheetMatcher = new DataModelSheetMatcher({
                dataModels: currentDataModelSheetMatcherModel.getDataModels(),
                sheetColumnDataModelOptionSimilarityList:
                  currentDataModelSheetMatcherModel.getSheetColumnDataModelOptionSimilarityList(),
                sheetColumnDataModelSimilarityList:
                  currentDataModelSheetMatcherModel.getSheetColumnDataModelSimilarityList(),
                sheets: currentDataModelSheetMatcherModel.getSheets(),
                calculateSimilarityResult:
                  CalculateSimilarityMapper.mergeCalculateSimilarityResultOptions(
                    currentDataModelSheetMatcherModel.getCalculateSimilarityResult(),
                    addCalculateSimilarityResult
                  ),
              });

              setDataModelSheetMatcher(newDataModelSheetMatcher);

              const updatedMatchOptions =
                currentDataModelSheetMatcherModel.getMatchOptions(
                  sheetColumn,
                  updatedDataModel
                );

              change(matchedOptionsName, updatedMatchOptions);
              setLoadingMatchingMoreOptions(false);
            }
          )
          .catch(() => {
            setLoadingMatchingMoreOptions(false);
          });
      }
    } else {
      callback();
      change(matchedOptionsName, null);
    }
  };

  const removeOtherMatchedDataModel = (dataModel: DataModel) => {
    for (let i = 0; i < values.matching.length; i++) {
      const match = values.matching[i];
      const callback = () => {
        const dropdownName = getDropdownName(i);
        change(dropdownName, null);
      };

      const optionAllowCustomOptions =
        (dataModel as CategoryDataModel)?.getAllowCustomOptions?.() || false;

      if (
        allowCustomColumns ||
        deprecatedAllowCustomOptions ||
        optionAllowCustomOptions
      ) {
        if (
          match.matchedDataModel?.dataModel === dataModel ||
          match.matchedDataModel?.dataModel?.getKey() === dataModel?.getKey()
        ) {
          callback();
          break;
        }
      } else {
        if (match.matchedDataModel?.dataModel === dataModel) {
          callback();
          break;
        }
      }
    }
  };

  const onChange = (
    value: DataModel | null,
    dataModelSheetMatcher?: DataModelSheetMatcher
  ) => {
    if (value) {
      removeOtherMatchedDataModel(value);
    }
    rematchOptions(
      value,
      () => {
        input.onChange(value);
      },
      dataModelSheetMatcher
    );
    if (value?.isDropdown()) {
      toggleOpenCollapse();
    } else {
      toggleCloseCollapse();
    }
  };

  const onRemoveMatchColumn = (value: SheetColumn) => {
    const rowIndex = values.matching.findIndex(
      (match) => match.sheetColumn === value
    );
    const dropdownName = getDropdownName(rowIndex);
    change(dropdownName, null);
  };

  const onSubmitCreateDataModel = () => {
    open(
      sheetColumn,
      (
        newDataModel: DataModel,
        dataModelSheetMatcher: DataModelSheetMatcher
      ) => {
        onChange(newDataModel, dataModelSheetMatcher);
      }
    );
  };

  return {
    value: !isEmpty(input.value) ? input.value : null,
    dropdownName,
    options: sortOptionsRecommends,
    input,
    meta,
    onChange,
    onRemoveMatchColumn,
    onSubmitCreateDataModel,
  };
};

export default useViewModel;
