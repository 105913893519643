import { cx, css } from 'core/emotion';
import { useTheme } from 'theme';

export const useVariant = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const getVariantClassname = () => {
    if (open) {
      return cx(
        'text-primary',
        css({
          borderColor: theme.getGlobalTheme().getLegacyPrimaryColor(),
        })
      );
    } else {
      return '';
    }
  };

  const getOpenClassname = () => {
    if (open) {
      return cx(
        'focus:shadow-outline-black',
        css({
          borderColor: theme.getGlobalTheme().getDark600Color(),
          ':focus': {
            borderColor: theme.getGlobalTheme().getLegacyPrimaryColor(),
          },
        })
      );
    } else {
      return css({
        borderColor: theme.getGlobalTheme().getDark900Color(),
      });
    }
  };

  return {
    getVariantClassname,
    getOpenClassname,
  };
};
