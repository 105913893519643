import { useMemo } from 'react';
import type {
  Option,
  SingleViewModel,
  SingleViewModelProps,
} from './../Dropdown/types';
import { isEqual, isEmpty as lodashIsEmpty } from 'lodash';
import { useSelect, UseSelectStateChange } from 'downshift';
import {
  useViewModel as useBaseViewModel,
  itemToString,
} from '../Dropdown/viewModel';

const useViewModel = ({
  onBlur,
  options,
  onChange,
  value,
  selectRef,
  hasScrollbar,
  type,
  allowCustomOptions,
}: SingleViewModelProps): SingleViewModel => {
  const handleChange = (changes: UseSelectStateChange<Option>) => {
    onChange(changes.selectedItem?.value ?? '');
    onBlur?.();
  };

  const {
    isOpen: isOpenDropdown,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    getMenuProps,
    selectedItem,
    openMenu,
    closeMenu,
    reset,
    setHighlightedIndex,
  } = useSelect({
    items: options,
    itemToString,
    selectedItem:
      options.find((option) => isEqual(itemToString(option), value)) ?? null,
    onSelectedItemChange: handleChange,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownCharacter:
          return { ...changes, highlightedIndex: state.highlightedIndex };
      }

      return changes;
    },
  });

  const shownValue = useMemo(() => {
    return selectedItem?.label;
  }, [selectedItem]);

  const {
    isEmpty,
    isShowCustomOptions,
    isShowSearch,
    onClear,
    onMenuButtonKeyDown,
    onMenuKeyDown,
    onSearchOption,
    popper,
    searchOptions,
    menuRef,
    searchValue,
    toggleDropdown,
  } = useBaseViewModel({
    closeMenu,
    hasScrollbar,
    isOpenDropdown,
    openMenu,
    options,
    reset,
    type,
    value,
    selectRef,
    allowCustomOptions,
  });

  return {
    isOpenDropdown,
    popper,
    shownValue,
    isEmpty,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    getMenuProps,
    menuRef,
    selectedItem,
    onClear,
    onMenuButtonKeyDown,
    onMenuKeyDown,
    searchOptions,
    isSearching: !lodashIsEmpty(searchValue),
    onSearchOption,
    searchValue,
    isShowCustomOptions,
    isShowSearch,
    setHighlightedIndex,
    toggleDropdown,
  };
};

export default useViewModel;
