import { RefObject, useCallback } from 'react';
import { HotTableClass } from '@handsontable/react';
import { Validator } from '../../../reviewEntries/validator';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import { HookedRecordResult } from '../../../hooks/hooksAPI';

export const getValidatedRowData = (
  rowIndex: number,
  columns: ColumnAPI[],
  cleanFnChanges: Record<string, HookedRecordResult>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[]
) => {
  return currentRowData?.map((value, index) => ({
    rowIndex: rowIndex,
    key: columns?.[index]?.key,
    value: cleanFnChanges?.[columns[index].key]?.value ?? value,
  }));
};

const useValidate = ({
  validator,
  hotInstance,
  dataSet,
}: {
  validator: Validator;
  hotInstance?: RefObject<HotTableClass>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>;
}) => {
  const validateUnique = useCallback(
    ({
      rowIndex: physicalRowIndex,
      column,
      newCellValue: newValue,
      columns,
      columnIndex,
      oldCellValue,
    }: {
      rowIndex: number;
      column: ColumnAPI;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newCellValue: any;
      columns: ColumnAPI[];
      columnIndex: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      oldCellValue: any;
    }) => {
      const instance = hotInstance?.current?.hotInstance;
      if (!instance) {
        return;
      }
      // NOTE: affectedRows is physical rows
      const { affectedRows } = validator.validateUnique([
        physicalRowIndex,
        column.key,
        oldCellValue,
        newValue,
      ]);
      affectedRows.forEach((rowIndex) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: any[] = [];

        const sourceRowData = dataSet[rowIndex];
        columns.forEach((column, i) => {
          rowData[i] = sourceRowData?.[column.key];
        });

        const transformedValues = {
          value: rowData?.[columnIndex],
          key: column.key,
          row: rowData?.map((value, index) => ({
            rowIndex: rowIndex,
            key: columns?.[index]?.key,
            value: value,
          })),
        };

        // NOTE: validate for unique in affected row
        const { error } = validator.validateCell(
          column,
          columns,
          transformedValues
        );

        validator.setError(error, columnIndex, rowIndex);
      });
    },
    [hotInstance, validator, dataSet]
  );

  const validateCell = useCallback(
    ({
      rowIndex,
      columnIndex,
      column,
      columns,
      validatedRowData,
      newCellValue,
    }: {
      rowIndex: number;
      columnIndex: number;
      column: ColumnAPI;
      columns: ColumnAPI[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validatedRowData: any[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newCellValue: any;
    }) => {
      const transformedValues = {
        value: newCellValue,
        key: column.key,
        row: validatedRowData,
      };

      const { error } = validator.validateCell(
        column,
        columns,
        transformedValues
      );

      validator.setError(error, columnIndex, rowIndex);
    },
    [validator]
  );

  return { validateUnique, validateCell };
};

export default useValidate;
