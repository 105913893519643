import { DataModel, Sheet, SheetColumn } from '@nuvo-importer/common/sdk';
import { firstValueFrom, of } from 'rxjs';
import MatchingDTO from '../dto/MatchingDTO';
import NodeProcessingEngine from '../engines/node/NodeProcessingEngine';
import BaseMatchingRepository from './BaseMatchingRepository';
import UploadInputSheetRepository from './services/UploadInputSheetRepository';
import { DataModelSheetMatch } from '../DataModelSheetMatching';
import { ExecuteOptions } from '../types/ml';

class NodeMatchingRepository extends BaseMatchingRepository {
  private uploadInputSheetRepository: UploadInputSheetRepository;
  private nodeEngine: NodeProcessingEngine;
  private ignoreReuseMappingColumn = true;

  constructor(
    uploadInputSheetRepository: UploadInputSheetRepository,
    nodeEngine: NodeProcessingEngine
  ) {
    super();
    this.uploadInputSheetRepository = uploadInputSheetRepository;
    this.nodeEngine = nodeEngine;
  }

  prepareData = async (allSheets: Sheet[], licenseKey: string) => {
    await firstValueFrom(
      this.uploadInputSheetRepository.upload(allSheets, licenseKey)
    );
  };

  initialize = () => {
    this.uploadInputSheetRepository.initialize();
  };

  clearData = (licenseKey: string) => {
    this.uploadInputSheetRepository.clearData(licenseKey);
  };

  complete = (licenseKey: string) => {
    this.uploadInputSheetRepository.complete(licenseKey);
  };

  setIgnoreReuseMappingColumn = (ignore: boolean) => {
    this.ignoreReuseMappingColumn = ignore;
  };

  protected override matchColumnsTask = (
    matchingMapperDTO: MatchingDTO,
    options: ExecuteOptions
  ) => {
    const columns = matchingMapperDTO.getColumns().map((column) => {
      return { ...column, dropdownOptions: [] };
    });
    const licenseKey = matchingMapperDTO.getLicenseKey();
    const headers = matchingMapperDTO.getInputHeaderRow();

    return this.nodeEngine.matchColumns(headers, columns, licenseKey, options);
  };

  protected override matchOptionsInitialTask = (
    dataModelSheetMatch: DataModelSheetMatch[],
    matchingMapperDTO: MatchingDTO,
    sheets: Sheet[]
  ) => {
    const licenseKey = matchingMapperDTO.getLicenseKey();
    const allSheetColumns: SheetColumn[] = [];

    sheets.forEach((sheet) =>
      sheet.getColumns().forEach((sheetColumn) => {
        allSheetColumns.push(sheetColumn);
      })
    );

    const mappingHeaderColumns = dataModelSheetMatch.map((match) => {
      const column = MatchingDTO.mapDataModelToColumn(
        match.matchedDataModel!.dataModel!
      );

      const columnIndex = allSheetColumns.findIndex((item) => {
        return item === match.sheetColumn;
      });

      return {
        column_index: columnIndex,
        column: column,
      };
    });

    if (!mappingHeaderColumns.length) {
      return of([]);
    }

    return this.nodeEngine.matchOptions(
      this.uploadInputSheetRepository.getFileId(),
      mappingHeaderColumns,
      licenseKey,
      {
        reuseMappingData: !this.ignoreReuseMappingColumn,
      }
    );
  };

  protected override matchOptionsTask = ({
    dataModel,
    sheets,
    sheetColumn,
    licenseKey,
  }: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    sheets: Sheet[];
    licenseKey: string;
  }) => {
    const column = MatchingDTO.mapDataModelToColumn(dataModel);
    const allSheetColumns: SheetColumn[] = [];

    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      for (let j = 0; j < sheet.getColumns().length; j++) {
        const sheetColumn = sheet.getColumns()[j];
        allSheetColumns.push(sheetColumn);
      }
    }

    const columnIndex = allSheetColumns.findIndex((item) => {
      return item === sheetColumn;
    });

    return this.nodeEngine.matchOptions(
      this.uploadInputSheetRepository.getFileId(),
      [
        {
          column,
          column_index: columnIndex,
        },
      ],
      licenseKey,
      {
        reuseMappingData: !this.ignoreReuseMappingColumn,
      }
    );
  };
}

export default NodeMatchingRepository;
