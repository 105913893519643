import { SheetColumn } from '@nuvo-importer/common/sdk';
import { useSelect } from 'downshift';
import { isBoolean, isNil, isEmpty as lodashIsEmpty } from 'lodash';
import { matchSorter } from 'match-sorter';
import {
  KeyboardEventHandler,
  useRef,
  KeyboardEvent,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useClickAway, useDebounce } from 'react-use';
import usePopper from './popper';

export type IValue = SheetColumn | null;
export type IOption = {
  label: string;
  value: Exclude<IValue, null>;
};
export type IOnChange = (value: SheetColumn | null) => void;

type IViewModelProps = {
  value: IValue;
  options: IOption[];
  onChange: IOnChange;
  disabled?: boolean;
};

export const itemToString = (item: IOption | null) => `${item?.label ?? ''}`;

const useViewModel = ({
  value,
  options,
  onChange,
  disabled,
}: IViewModelProps) => {
  const isEmpty = isNil(value) || (lodashIsEmpty(value) && !isBoolean(value));
  const searchRef = useRef<HTMLInputElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const menuRef = useRef<HTMLDivElement>(null);
  const popper = usePopper();

  const {
    isOpen,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    getMenuProps,
    selectedItem,
    openMenu,
    closeMenu,
    reset,
    setHighlightedIndex,
  } = useSelect({
    items: options,
    itemToString,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem?.value ?? null);
    },
    selectedItem: options.find((item) => item.value === value) ?? null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownCharacter:
          return { ...changes, highlightedIndex: state.highlightedIndex };
        case useSelect.stateChangeTypes.MenuBlur: {
          return { ...changes, isOpen: true };
        }
      }

      return { ...changes, isOpen: disabled ? false : changes.isOpen };
    },
  });

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('');
    }
  }, [isOpen, setSearchValue]);

  const onMenuKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ' ' && isOpen) {
      (
        event.nativeEvent as unknown as { preventDownshiftDefault: boolean }
      ).preventDownshiftDefault = true;
    }
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
    if (event.key === 'Backspace') {
      reset();
    }
  };

  const onMenuButtonKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'ArrowLeft':
      case 'ArrowRight':
        // https://github.com/downshift-js/downshift/issues/734
        (
          event.nativeEvent as unknown as { preventDownshiftDefault: boolean }
        ).preventDownshiftDefault = true;
        break;
      case 'Backspace':
        reset();
        break;
      case 'Enter':
        event.stopPropagation();
    }
  };

  useDebounce(
    () => {
      setDebouncedValue(searchValue);
    },
    500,
    [searchValue]
  );

  const searchOptions = useMemo(
    () =>
      matchSorter(options, debouncedValue, {
        keys: ['value', 'label'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (item) => item,
      }),
    [options, debouncedValue]
  );

  const isSearching = !lodashIsEmpty(searchValue);

  const onSearchValue = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const shownValue = useMemo(() => {
    return selectedItem?.label;
  }, [selectedItem]);

  const onClear = () => {
    reset();
  };

  useClickAway({ current: menuRef.current }, (event) => {
    if (!menuButtonRef.current?.contains(event.target as HTMLElement)) {
      // Handle for react 18
      setTimeout(() => {
        closeMenu();
      }, 0);
    }
  });

  useLayoutEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchRef.current?.focus();
      });
    }
  }, [isOpen]);

  return {
    isEmpty,
    isOpen,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    getMenuProps,
    selectedItem,
    openMenu,
    closeMenu,
    reset,
    setHighlightedIndex,
    onMenuButtonKeyDown,
    searchRef,
    menuButtonRef,
    onMenuKeyDown,
    searchValue,
    setSearchValue,
    isSearching,
    searchOptions,
    menuRef,
    onSearchValue,
    shownValue,
    onClear,
    popper,
  };
};

export default useViewModel;
