import { useCallback, useMemo, ReactNode } from 'react';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import {
  getAcceptFileTypes,
  parseFilesToSheets,
  handleFileRejection,
  getDropzoneStyles,
} from './fileUtils';
import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import {
  useConfigure,
  useFeatureWhiteList,
} from '../configure/ConfigureProvider';
import { useContextFileParserWorkerManager } from './worker/WebWorkerProvider';
import { useDataModels } from './../dataModel';
import { useSettings } from 'settings';
import { DataFile } from '../modules/nuvo.parser.worker';
import Tracking from '../tracking/Tracking';

type DropzoneProps = {
  children: ReactNode;
  className?: string;
  onFileSelectedSuccess: (data: DataFile[]) => void;
  onFileSelectedError: (errorMessage: string, title?: string) => void;
  onProcessing: (processing: boolean) => void;
};

const AddFilesUploader = ({
  children,
  className,
  onFileSelectedSuccess,
  onFileSelectedError,
  onProcessing,
}: DropzoneProps) => {
  const { setUploadWebWorker } = useContextFileParserWorkerManager();
  const { t } = useTranslation();
  const { featureWhiteList } = useFeatureWhiteList();
  const dataModel = useDataModels();
  const { inputTypes, identifier } = useSettings();
  const { licenseKey } = useConfigure();

  const onDropRejected = useCallback(
    (errors: FileRejection[]) => {
      handleFileRejection(
        onFileSelectedError,
        errors,
        featureWhiteList.getMaxFileSizeInMb(),
        t,
        inputTypes || []
      );
    },
    [onFileSelectedError, featureWhiteList, t, inputTypes]
  );

  const onDropAccepted = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      onProcessing(true);
      parseFilesToSheets(
        acceptedFiles,
        {
          hasDateType: dataModel.hasDateType(),
          originRequest: Tracking.getInstance().getOrigin(),
          licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
          sdkVersion: Tracking.getInstance().getVersionNumber(),
        },
        setUploadWebWorker
      )
        .promise.then((dataFiles) => {
          Tracking.getInstance().setUploadedFiles(identifier, dataFiles);
          onFileSelectedSuccess(dataFiles);
        })
        .catch((err) => {
          onFileSelectedError(err);
        })
        .finally(() => {
          onProcessing(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFileSelectedSuccess, setUploadWebWorker, onFileSelectedError, t]
  );

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      onDropRejected(fileRejections);
    } else {
      onDropAccepted(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: getAcceptFileTypes(inputTypes || []),
      onDrop,
      maxSize: featureWhiteList.getIsFileSizeUnlimited()
        ? undefined
        : featureWhiteList.getMaxFileSizeInBytes(),
    });

  const style = useMemo(
    () => getDropzoneStyles(isDragAccept, isDragReject),
    [isDragReject, isDragAccept]
  );

  return (
    <div
      className={cx(className, 'w-full')}
      {...getRootProps({
        style,
      })}
    >
      <input
        {...getInputProps({
          multiple: true,
        })}
      />
      {children}
    </div>
  );
};

export default AddFilesUploader;
