import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { useMemo } from 'react';
import { useTheme } from 'theme';
import chroma from 'chroma-js';

export const useStyle = ({
  active,
  selected,
  className,
  configThemeDropdownMenuItem,
}: {
  active: boolean;
  selected: boolean;
  className?: string;
  configThemeDropdownMenuItem?: {
    selectedOption?: string;
    option?: string;
    badge?: { root?: CSSInterpolation; icon?: CSSInterpolation };
  };
}) => {
  const badgeHover = (configThemeDropdownMenuItem?.badge?.root as CSSObject)?.[
    ':hover'
  ];

  const theme = useTheme();
  const itemBgThemeColor = useMemo(() => {
    const normalPrimaryChroma = chroma(
      theme.getGlobalTheme().getPrimaryColor()
    );

    const color = chroma
      .hsl(
        normalPrimaryChroma.get('hsl.h') - 15.2941,
        normalPrimaryChroma.get('hsl.s') - 0.2226,
        normalPrimaryChroma.get('hsl.l') + 0.2921
      )
      .alpha(0.4)
      .hex();

    return color;
  }, [theme]);

  const menuItemClassname = useMemo(() => {
    return cx(
      active && !selected
        ? cx(
            'bg-priority-bg',
            css`
              &:hover .badgeMatchItemWrapper {
                background-color: #e8e9eb;
                ${badgeHover}
              }
            `
          )
        : '',
      'block px-4 py-2.5 text-sm cursor-pointer text-gray-310 flex justify-between items-center break-all',
      selected
        ? cx(
            css`
              &:hover .badgeMatchItemWrapper {
                ${badgeHover}
              }
            `,
            css({
              backgroundColor: itemBgThemeColor,
            }),
            configThemeDropdownMenuItem?.selectedOption
          )
        : configThemeDropdownMenuItem?.option
    );
  }, [
    active,
    selected,
    badgeHover,
    itemBgThemeColor,
    configThemeDropdownMenuItem?.selectedOption,
    configThemeDropdownMenuItem?.option,
  ]);

  const menuItemTextClassname = useMemo(() => {
    return cx('pr-2', className);
  }, [className]);

  return {
    menuItemClassname,
    menuItemTextClassname,
  };
};
