import { useSettings } from 'settings';

export const useMainView = () => {
  const {
    modal = false,
    multipleFileUpload,
    enableExamples,
    identifier,
  } = useSettings();

  return { modal, multipleFileUpload, enableExamples, identifier };
};
