class FreezeStrategyStore {
  private static instance: FreezeStrategyStore;
  private static freezeColumns: string[] = [];

  private constructor() {
    if (FreezeStrategyStore.instance) {
      throw new Error(
        'This class is singleton please use getInstance method instead 🙏'
      );
    }
  }

  static getInstance() {
    if (this.instance === null) {
      this.instance = new FreezeStrategyStore();
    }

    return this.instance;
  }

  static getFreezeColumns() {
    return this.freezeColumns;
  }

  static removeByIndex(columnIndex: number) {
    this.freezeColumns.splice(columnIndex, 1);
  }

  static add(id: string) {
    this.freezeColumns.push(id);
  }

  static update(oldId: string, newId: string) {
    for (let i = 0; i < this.freezeColumns.length; ++i) {
      if (this.freezeColumns[i] === oldId) {
        this.freezeColumns[i] = newId;
        break;
      }
    }
  }

  static cleanUp() {
    this.freezeColumns = [];
  }
}

export default FreezeStrategyStore;
