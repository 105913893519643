import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { MAIN_PAGE_PATH } from 'core/constants/route';
import useInAppNavigate from 'core/navigate';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useWarnSmallScreen = () => {
  const { showConfirmModal, isOpen, setOpenModal, type } =
    useContextConfirmModalManager();
  const { t } = useTranslation();
  const navigate = useInAppNavigate();

  const warnSmallScreen = useCallback(
    (onClickNegative?: () => void) => {
      showConfirmModal({
        textNegativeButton: t('txt_close'),
        title: t('txt_title_warning_screen'),
        description: t('txt_warning_screen'),
        onClickNegativeButton: () => {
          if (onClickNegative) {
            onClickNegative();
            setOpenModal(false);
          }
          navigate({ pathname: MAIN_PAGE_PATH });
        },
        isShowPositiveButton: false,
        isShowIcon: true,
        disabledClickOutside: true,
        isShowCloseIcon: false,
        type: 'confirm',
      });
    },
    [t, showConfirmModal, navigate, setOpenModal]
  );

  return { warnSmallScreen, isOpen, setOpenModal, type };
};

export default useWarnSmallScreen;
