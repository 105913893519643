import { cx } from '@nuvo-importer/common';

type IEmptyProps = {
  text: string;
  className?: string;
};

const Empty = ({ text, className }: IEmptyProps) => {
  return (
    <div
      style={{ height: 64 }}
      className={cx(
        'rounded-medium flex items-center justify-center bg-gray-50',
        className
      )}
    >
      <p className="text-gray-560 text-sm">{text}</p>
    </div>
  );
};

export default Empty;
