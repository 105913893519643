import { NumberFormat } from '../dataModel/columnsAPI';
import { DATATYPE } from '../dataType';
import { DataModelSheetMatch } from '../matching/DataModelSheetMatching';
import { Value } from '../sheet/Sheet';
import { NumberParser } from './NumberParser';

export class SheetRegionDetector {
  private PROBE_ROWS_COUNT = 25;
  private skipRows = 0;
  private matching: DataModelSheetMatch[] = [];

  constructor(matching: DataModelSheetMatch[]) {
    this.matching = matching;
  }

  public detect(): NumberFormat {
    let detectedNumberFormat: NumberFormat | undefined = undefined;

    while (!detectedNumberFormat) {
      const probes: Value[][] = this.collectProbes();
      const empty = probes.every((probe) => probe.length === 0);

      if (empty) {
        break;
      }

      for (let i = 0; i < probes.length; i++) {
        const format = this.detectNumberFormat(probes[i]);
        if (format !== undefined) {
          detectedNumberFormat = format;
          break;
        }
      }

      this.skipRows += this.PROBE_ROWS_COUNT;
    }

    return detectedNumberFormat || NumberFormat.EU;
  }

  private collectProbes(): Value[][] {
    const rowsWithFloatType: Value[] = [];
    const rowsWithIntType: Value[] = [];
    const rowsWithCurrencyEUType: Value[] = [];
    const rowsWithCurrencyUSType: Value[] = [];
    const rowsWithPercentageType: Value[] = [];

    let pointer: Value[] | null = null;

    for (let i = 0; i < this.matching.length; i++) {
      const type: DATATYPE | undefined =
        this.matching[i].matchedDataModel?.dataModel?.getType();
      const rows: Value[] = this.matching[i].sheetColumn
        .getRows()
        .slice(this.skipRows, this.skipRows + this.PROBE_ROWS_COUNT);

      if (type === DATATYPE.FLOAT) {
        pointer = rowsWithFloatType;
      }

      if (type === DATATYPE.CURRENCY_EUR) {
        pointer = rowsWithCurrencyEUType;
      }

      if (type === DATATYPE.CURRENCY_USD) {
        pointer = rowsWithCurrencyUSType;
      }

      if (type === DATATYPE.INTEGER) {
        pointer = rowsWithIntType;
      }

      if (type === DATATYPE.PERCENTAGE) {
        pointer = rowsWithPercentageType;
      }

      if (pointer !== null) {
        pointer.push(...rows);
        pointer = null;
      }
    }

    return [
      rowsWithFloatType,
      rowsWithCurrencyEUType,
      rowsWithCurrencyUSType,
      rowsWithIntType,
      rowsWithPercentageType,
    ];
  }

  private detectNumberFormat(values: Value[]): NumberFormat | undefined {
    let euFormatsCount = 0;
    let usFormatsCount = 0;

    for (let i = 0; i < values.length; i++) {
      const value: Value = values[i];
      if (typeof value !== 'string') {
        continue;
      }
      const format: NumberFormat | null | undefined =
        NumberParser.detectNumberFormat(value, { resolveUncertainty: false });

      if (format === NumberFormat.EU) {
        euFormatsCount++;
      }

      if (format === NumberFormat.US) {
        usFormatsCount++;
      }
    }

    if (usFormatsCount === 0 && euFormatsCount === 0) {
      return undefined;
    }

    if (usFormatsCount > euFormatsCount) {
      return NumberFormat.US;
    }

    return NumberFormat.EU;
  }
}
