import { DataModel } from '../dataModel/model/DataModel';
import { Workbook } from 'nuvo-exceljs';
import {
  createOptionSheet,
  setColumnWidthAutoFit,
  setDataValidation,
  setErrorStyleAndComment,
  setHeaderStyle,
} from '../xlsxUtil';
import { saveAs } from 'file-saver';
import { FieldValue } from '../value';
import ExportValueParser, { Value } from './valueParser/ExportValueParser';
import { RecordInfo } from '../reviewEntries/type';
import { Error } from '../reviewEntries/validator';
import { HookedRecordInfoLevel } from '../hooks/hooksAPI';

type Content = DataRow[];

type DataRow = Record<
  string,
  {
    value: Value;
    errors: { message: string; level: HookedRecordInfoLevel }[];
  }
>;

type DataExportOptions = {
  dataInfos: Record<string, RecordInfo[]>;
  errors: (Error | null)[][];
  getMessage: (error: Error) => string;
};

const getContentDataModelXLSX = (
  dataModels: DataModel[],
  data: Record<string, FieldValue>[],
  options: DataExportOptions
) => {
  const headers = dataModels.map((item) => {
    return { header: item.getLabel(), key: item.getKey(), width: 60 };
  });

  const contents: Content = [];

  for (let i = 0; i < data.length - 1; ++i) {
    const element = data[i];
    const dataRow: DataRow = {};

    for (let j = 0; j < dataModels.length; ++j) {
      const dataModel = dataModels[j];
      const errorItem = options.errors[i]?.find(
        (error) => error?.colIndex === j
      );
      const errorMessage = errorItem
        ? {
            message: options.getMessage(errorItem),
            level: 'error' as HookedRecordInfoLevel,
          }
        : null;

      let allErrors: { message: string; level: HookedRecordInfoLevel }[] = [];

      const errorInfo = options.dataInfos[i]
        ?.filter((item) => {
          return item.colIndex === j;
        })
        .map((item) => ({
          message: item.popover.message,
          level: item.popover.level,
        }));

      if (errorMessage) {
        allErrors.push(errorMessage);
      }

      if (errorInfo) {
        allErrors = allErrors.concat(errorInfo);
      }

      dataRow[dataModel.getKey()] = {
        value: ExportValueParser.parse(dataModel, element[dataModel.getKey()]),
        errors: allErrors,
      };
    }

    contents.push(dataRow);
  }

  return {
    headers,
    contents,
  };
};

const exportToXlsx = (
  {
    dataModels,
    data,
    options,
  }: {
    dataModels: DataModel[];
    data: Record<string, FieldValue>[];
    options: DataExportOptions;
  },
  fileName: string
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const { headers, contents } = getContentDataModelXLSX(
      dataModels,
      data,
      options
    );
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('sheet-1');
    const dataSheet = [];
    sheet.columns = headers;

    for (let i = 0; i < contents.length; ++i) {
      const element = contents[i];
      const data: Record<string, Value> = {};

      for (let j = 0; j < dataModels.length; ++j) {
        const dataModel = dataModels[j];
        const key = dataModel.getKey();
        data[key] = element[key].value;
      }
      dataSheet.push(data);
    }

    sheet.addRows(dataSheet);
    setHeaderStyle(sheet);
    setErrorStyleAndComment(sheet, contents, dataModels);
    setColumnWidthAutoFit(sheet);
    createOptionSheet(workbook, dataModels);
    setDataValidation(sheet, dataModels, 2, data.length + 1);
    const _data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${fileName}.xlsx`);
    resolve(null);
  });
};

export default exportToXlsx;
