import MenuSelector from '../../core/dataModelSheet/DataModelSheetForm/ContextMenu/components/Dropdown/MenuSelector';
import Menu from './Menu';
import { ConfigTheme } from './type';
import useViewModel, { Option } from './viewModel';

type CheckboxDropdownProps<T> = {
  options: Option<T>[];
  placeholder?: string;
  value: T[];
  onChange: (values: T[]) => void;
  size?: 'small' | 'base';
  configTheme?: ConfigTheme;
  menuSelectorClassName?: string;
  menuItemClassName?: string;
  menuPaneClassName?: string;
};

const CheckboxDropdown = <T,>({
  options,
  placeholder,
  value,
  onChange,
  size,
  configTheme,
  menuSelectorClassName,
  menuItemClassName,
  menuPaneClassName,
}: CheckboxDropdownProps<T>) => {
  const { isOpen, popper, setIsOpen, shownValue } = useViewModel<T>({
    options,
    value,
  });
  return (
    <div>
      <MenuSelector
        ref={popper.referenceElement}
        shownValue={shownValue}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        placeholder={placeholder}
        hasSelected={value.length > 0}
        size={size}
        configTheme={configTheme}
        className={menuSelectorClassName}
      />
      <Menu<T>
        isOpen={isOpen}
        popper={popper}
        options={options}
        selected={value}
        setSelected={onChange}
        configTheme={configTheme}
        menuItemClassName={menuItemClassName}
        menuPaneClassName={menuPaneClassName}
      />
    </div>
  );
};

export default CheckboxDropdown;
