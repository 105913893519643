import { useMemo } from 'react';
import { useSettings } from '../../settings';

export const useDevelopMode = () => {
  const settings = useSettings();

  const mode = useMemo(() => {
    return {
      isDeveloperMode: settings.developerMode ?? false,
    };
  }, [settings]);

  return mode;
};
