import { isArray, isBoolean, isNil, isObject } from 'lodash';
import { IValueParserOptions, PossibleValue } from './IValueParser';

export abstract class BaseNumberValueParser {
  abstract valueParser: (
    value: PossibleValue,
    options?: IValueParserOptions
  ) => number | string;

  parse = (value: PossibleValue, options?: IValueParserOptions) => {
    if (isNil(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return value ? '1' : '0';
    }

    if (isObject(value) || isArray(value)) {
      return '';
    }

    if (`${value}`.trim() === '') {
      return '';
    }

    return this.valueParser(value, options);
  };
}

export default BaseNumberValueParser;
