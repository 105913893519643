import chroma from 'chroma-js';
import { ReactNode, useMemo } from 'react';
import { COLOR_SCROLL_BAR, COLOR_TABLE } from '../../core/constants/colors';
import { css, CSSInterpolation, CSSObject, cx } from '../../core/emotion';
import { useTheme } from '../../theme';

type WrapperStyledProps = {
  children: ReactNode;
  configTheme?: {
    root: CSSInterpolation;
    content?: CSSInterpolation;
    selectRowColor: string;
    hoverRowColor: string;
    scrollbarNavigatorColor: string;
    scrollbarBackgroundColor: string;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
  className?: string;
};

const WrapperStyled = ({
  children,
  configTheme,
  className,
}: WrapperStyledProps) => {
  const theme = useTheme();
  const globalTheme = useMemo(() => {
    return theme.getGlobalTheme();
  }, [theme]);

  const header = useMemo(() => {
    return css`
      .spread-data-sheet .handsontable .header {
        ${configTheme?.th}
      }

      .spread-data-sheet .handsontable .htCore tr:first-child td {
        border-top-color: ${(configTheme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }

      .spread-data-sheet .handsontable .htCore tr:first-child th {
        border-top-color: ${(configTheme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }
    `;
  }, [configTheme]);

  const body = useMemo(() => {
    return css`
      .spread-data-sheet .ht_master .wtHider .htCore tbody .default-cell {
        ${configTheme?.content}
        ${configTheme?.td}
      }

      .spread-data-sheet .ht_clone_left .wtHider .htCore tbody .default-cell {
        ${configTheme?.content}
        ${configTheme?.td}
      }

      .spread-data-sheet .ht_master .wtHider .htCore tbody .selecting-row {
        ${configTheme?.content}
        ${configTheme?.td}
        background-color: ${configTheme?.selectRowColor
          ? configTheme?.selectRowColor
          : globalTheme.getDark50Color()};
      }

      .spread-data-sheet .ht_clone_left .wtHider .htCore tbody .selecting-row {
        ${configTheme?.content}
        ${configTheme?.td}
        background-color: ${configTheme?.selectRowColor
          ? configTheme?.selectRowColor
          : globalTheme.getDark50Color()};
      }

      .spread-data-sheet
        .ht_master
        .wtHider
        .htCore
        tbody
        .selecting-row-and-hovering {
        ${configTheme?.content}
        background-color: ${configTheme?.selectRowColor
          ? chroma(configTheme?.selectRowColor).darken(0.5).hex()
          : globalTheme.getDark100Color()};
      }

      .spread-data-sheet
        .ht_clone_left
        .wtHider
        .htCore
        tbody
        .selecting-row-and-hovering {
        ${configTheme?.content}

        background-color: ${configTheme?.selectRowColor
          ? chroma(configTheme?.selectRowColor).darken(0.5).hex()
          : globalTheme.getDark100Color()};
      }

      .spread-data-sheet .ht_master .wtHider .htCore tbody .hover-row {
        ${configTheme?.content}
        ${configTheme?.td}
      background-color: ${configTheme?.hoverRowColor
          ? configTheme?.hoverRowColor
          : COLOR_TABLE.HOVER};
      }

      .spread-data-sheet .ht_clone_left .wtHider .htCore tbody .hover-row {
        ${configTheme?.content}
        ${configTheme?.td}
      background-color: ${configTheme?.hoverRowColor
          ? configTheme?.hoverRowColor
          : COLOR_TABLE.HOVER};
      }
    `;
  }, [configTheme, globalTheme]);

  const scrollbar = useMemo(() => {
    return css`
      .spread-data-sheet
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.scrollbarNavigatorColor};
      }

      .spread-data-sheet
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-track {
        background-color: ${configTheme?.scrollbarBackgroundColor};
      }

      scrollbar-color: ${configTheme?.scrollbarNavigatorColor
          ? configTheme?.scrollbarNavigatorColor
          : COLOR_SCROLL_BAR.NAVIGATOR}
        ${configTheme?.scrollbarBackgroundColor
          ? configTheme?.scrollbarBackgroundColor
          : COLOR_SCROLL_BAR.BACKGROUND};
    `;
  }, [configTheme]);

  return (
    <div
      className={cx(
        css`
          .spread-data-sheet {
            ${configTheme?.root}
          }
        `,
        header,
        body,
        scrollbar,
        className
      )}
    >
      {children}
    </div>
  );
};

export default WrapperStyled;
