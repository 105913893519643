import { FC, ReactNode, useLayoutEffect } from 'react';
import Modal from 'react-modal';
import { css, CSSInterpolation, cx } from '../../core/emotion';
import { NUVO_TAILWIND_CLASS } from '../../core/constants/style';

type BaseModalProps = {
  children: ReactNode;
  isOpen: boolean;
  wrapperClassName?: string;
  overlayStyled?: CSSInterpolation;
  styledClass?: string;
  id?: string;
};

export const BaseModalCommon: FC<BaseModalProps> = ({
  children,
  isOpen,
  wrapperClassName,
  overlayStyled,
  styledClass,
  id,
}) => {
  useLayoutEffect(() => {
    Modal.setAppElement(document.body);
  }, [isOpen]);

  return (
    <Modal
      portalClassName={styledClass || NUVO_TAILWIND_CLASS}
      isOpen={isOpen}
      id={id}
      overlayClassName={{
        base: cx(
          'fixed inset-0 overflow-y-auto inset-0 bg-black bg-opacity-75 transition-opacity ease-out duration-300 ring-transparent z-20',
          css({ '&&': overlayStyled, opacity: 0 }),
          wrapperClassName
        ),
        afterOpen: css({
          '&&': {
            opacity: 1,
          },
        }),
        beforeClose: css`
          opacity: 0 !important;
        `,
      }}
      className={{
        base: cx(
          'outline-none block scale-95 transform ring-transparent transition-all duration-300 ease-out',
          css`
            opacity: 0;
          `
        ),
        beforeClose: cx(
          css`
            opacity: 0 !important;
          `,
          css`
            transform: scale(0.95) !important;
          `
        ),
        afterOpen: css({
          '&&': {
            opacity: 1,
            transform: 'scale(1)',
          },
        }),
      }}
      preventScroll
      closeTimeoutMS={300}
      bodyOpenClassName="nuvo-ReactModal__Body--open"
    >
      {children}
    </Modal>
  );
};

export default BaseModalCommon;
