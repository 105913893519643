import { SheetColumn, Sheet } from '@nuvo-importer/common/sdk';
import { HandleJoin } from '../../../joinSheet';
import { useState } from 'react';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';

export type IFormValues = {
  columns: {
    target: SheetColumn | null;
    source: SheetColumn | null;
  }[];
};

type IViewModelProps = {
  handleJoin: HandleJoin;
  joinTargetSheet: Sheet;
};

const useViewModel = ({ handleJoin, joinTargetSheet }: IViewModelProps) => {
  const [loading, setLoading] = useState(false);
  const { showConfirmModal, isOpen: isConfirmOpen } =
    useContextConfirmModalManager();
  const { t } = useTranslation();

  const submitJoinSheet = async (
    columns: {
      target: SheetColumn;
      source: SheetColumn;
    }[]
  ) => {
    setLoading(true);
    try {
      await handleJoin(columns);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setLoading(false);
  };

  const onSubmit = async (values: IFormValues) => {
    const columns = values.columns.filter((col) => {
      return col.source && col.target;
    }) as {
      target: SheetColumn;
      source: SheetColumn;
    }[];
    if (columns.length === 0) {
      showConfirmModal({
        title: t('txt_no_column_select'),
        description: t('txt_no_column_select_join_desc', {
          sheetName: joinTargetSheet.getName(),
        }),
        onClickNegativeButton: () => {
          return submitJoinSheet(columns);
        },
        onClickPositiveButton: () => {
          setLoading(false);
        },
        isShowIcon: true,
        isShowCloseIcon: false,
        disabledClickOutside: true,
        textNegativeButton: t('txt_continue'),
        textPositiveButton: t('txt_cancel'),
      });
    } else {
      await submitJoinSheet(columns);
    }
  };

  const validate = (values: IFormValues) => {
    return {
      columns: values.columns.map((col) => {
        if (!col.target && !col.source) {
          return {};
        }
        const error: { joinColumn?: boolean; baseColumn?: boolean } = {};
        if (!col.target) {
          error.joinColumn = true;
        }
        if (!col.source) {
          error.baseColumn = true;
        }

        return error;
      }),
    };
  };

  return { onSubmit, loading, validate, isConfirmOpen };
};

export default useViewModel;
