import React, { FC, useCallback, useMemo } from 'react';
import { ISelectOption, ISelectProps, Select } from '@getnuvo/ui-kit';
import { useFormContext } from 'react-hook-form';

type IProps = Omit<ISelectProps, 'value' | 'onChange' | 'tags'> & {
  name: string;
  tags?: boolean;
};

export const SelectMultiControl: FC<IProps> = (props: IProps) => {
  const { setValue, watch } = useFormContext();

  const formValue = watch(props.name);

  const value: ISelectOption[] = useMemo(
    () =>
      props.options.filter(
        (o: ISelectOption) => formValue?.indexOf(o?.id) >= 0
      ),
    [formValue, props.options]
  );

  const onChange = useCallback(
    (value: ISelectOption[]) => {
      setValue(
        props.name,
        value.map((o: ISelectOption) => o.id)
      );
    },
    [setValue, props.name]
  );

  return (
    <Select {...props} multiple={true} value={value} onChange={onChange} />
  );
};
