import { css } from '../../core/emotion';
import { ReactNode } from 'react';

const style = css`
  width: 12px;
  overflow: hidden;
  display: inline-flex;
  :after {
    overflow: hidden;
    display: inline-flex;
    vertical-align: baseline;
    -webkit-animation: ellipsis steps(4, end) 1300ms infinite;
    animation: ellipsis steps(4, end) 1300ms infinite;
    content: '...';
    text-align: start;
    transform: translateX(-12px);
    width: 12px;
  }
  @keyframes ellipsis {
    to {
      transform: translateX(4px);
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      transform: translateX(4px);
    }
  }
`;

export const TextLoading = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <p className={className}>
      {children}
      <span className={style}></span>
    </p>
  );
};
