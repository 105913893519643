import { MutableRefObject, RefObject, useRef } from 'react';
import { releaseProxy, Remote, wrap } from 'comlink';
import { createWorker } from '../../../../worker/createWorker';
import searchAndReplaceWorkerTxt from './../../../../../../worker-txt/searchAndReplace.txt';
import { isMatchSearchByValue, replaceWord } from '../../../utils';
import { DataModel } from '../../../../dataModel/model/DataModel';
import Handsontable from 'handsontable';
import { ISearchParams } from '.';
import { isNil } from 'lodash';
import ValueParser from '../../../valueParser/ValueParser';
import { HotTableClass } from '@handsontable/react';
import ModeViewTable from '../../ModeViewTable/ModeViewTable';
import AllColumnSetting from '../../columns/AllColumnSetting';
import { ColumnAPI } from '../../../../dataModel/columnsAPI';
import ColumnsAPIMapper from '../../../../dataModel/ColumnsAPIMapper';
import DataModelRegistry from '../../DataModelRegistry';

interface SearchAndReplaceAble {
  getCountBySearchValue: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSet: Record<string, any>[],
    searchParams: ISearchParams,
    hiddenRows: number[],
    hiddenCols: number[],
    skipCols: number[],
    columns: ColumnAPI[]
  ) => { counter: number; hidden: number; skip: number };
}

type ISearchAndReplaceRemote = Remote<SearchAndReplaceAble> | undefined;

type IPosition = {
  row: number;
  col: number;
};

type ILoopOption = {
  currentRow: number;
  currentCol: number;
  gasLimit: number;
  isReplaceFunc?: boolean;
};

type SearchAndReplaceViewModel = {
  hotInstance: RefObject<HotTableClass>;
  modeViewTable: ModeViewTable;
  allColumnSetting: AllColumnSetting;
  dataModelRegistry: DataModelRegistry;
  beforeChangeDataSetLength: MutableRefObject<number>;
};

export const useSearchAndReplace = ({
  hotInstance,
  modeViewTable,
  allColumnSetting,
  dataModelRegistry,
  beforeChangeDataSetLength,
}: SearchAndReplaceViewModel) => {
  const searchAndReplaceWorker = useRef<Worker>();
  const searchAndReplaceRemote = useRef<ISearchAndReplaceRemote | null>();

  const clearSearchAndReplaceRemote = () => {
    if (searchAndReplaceRemote) {
      searchAndReplaceRemote.current?.[releaseProxy]();
      searchAndReplaceWorker.current?.terminate();
      searchAndReplaceRemote.current = null;
    }
  };

  const getAllSearchMatchCount = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSet: Record<string, any>[],
    searchParams: ISearchParams
  ) => {
    const columns = dataModelRegistry.getColumns();
    if (!searchAndReplaceRemote.current) {
      searchAndReplaceWorker.current = createWorker(searchAndReplaceWorkerTxt);
      searchAndReplaceRemote.current = wrap<SearchAndReplaceAble>(
        searchAndReplaceWorker.current
      );
    } else {
      clearSearchAndReplaceRemote();
    }

    const hiddenColumnsPlugin =
      hotInstance.current?.hotInstance?.getPlugin('hiddenColumns');
    const hiddenCols = (hiddenColumnsPlugin?.getHiddenColumns() ?? []).map(
      (visualCol) =>
        hotInstance.current?.hotInstance?.toPhysicalColumn(visualCol) ?? -1
    );
    const hiddenRows = modeViewTable
      .getHiddenRows()
      .map(
        (visualCol) =>
          hotInstance.current?.hotInstance?.toPhysicalColumn(visualCol) ?? -1
      );
    const filteredRows = allColumnSetting
      .getFilterStrategy()
      .getFilteredHideRowIndex(false);

    filteredRows.forEach((filteredRow) => {
      hiddenRows.push(filteredRow);
    });

    const skipCols: number[] = [];
    columns.forEach((col, colIndex) => {
      if (
        DataModel.isTypeDropdown(
          ColumnsAPIMapper.mapDataModelType({ columnType: col.columnType })
        )
      ) {
        skipCols.push(colIndex);
      }
    });

    const count = await searchAndReplaceRemote?.current?.getCountBySearchValue(
      dataSet,
      searchParams,
      hiddenCols,
      hiddenRows,
      skipCols,
      columns
    );
    clearSearchAndReplaceRemote();
    return count;
  };

  const getSearchMatchCursor = (
    hot: Handsontable | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSet: Record<string, any>[],
    searchParams: ISearchParams,
    dataModels: DataModel[],
    opt: ILoopOption
  ): IPosition => {
    let matchRow = 0;
    let matchCol = 0;

    const { value, columns, isExact } = searchParams;

    if (value?.trim()?.length === 0)
      return { row: opt.currentRow, col: opt.currentCol };

    let startRowIndex = opt.currentRow === -1 ? 0 : opt.currentRow;
    const startColIndex = opt.currentCol === -1 ? 0 : opt.currentCol;

    if (startRowIndex > dataSet.length - 1) {
      startRowIndex = 0;
    }

    rowLoop: for (let i = startRowIndex; i < dataSet.length; i++) {
      const physicalRow = hot?.toPhysicalRow(i);
      const hiddenRowPlugins = hot?.getPlugin('hiddenRows');
      const col = dataModels;

      for (let j = 0; j < col.length; j++) {
        if (isNil(physicalRow) || hiddenRowPlugins?.isHidden(i)) {
          break;
        }
        const row = dataSet[physicalRow];
        const physicalCol = hot?.toPhysicalColumn(j) ?? 0;
        const dataCol = row[col[physicalCol].getKey()];
        const targetCol = columns.find(
          (c) => c.key === col[physicalCol].getKey()
        );

        const hiddenColPlugins = hot?.getPlugin('hiddenColumns');
        const isHiddenCol = hiddenColPlugins?.isHidden(j);

        let forceSkipCol = false;
        if (
          opt.isReplaceFunc &&
          targetCol?.type &&
          DataModel.isTypeDropdown(targetCol?.type)
        ) {
          // NOTE: waiting for confirm replace value of dropdown field
          forceSkipCol = true;
        }

        if (
          (i === opt.currentRow &&
            (opt.isReplaceFunc ? j < startColIndex : j <= startColIndex)) ||
          !targetCol ||
          isHiddenCol ||
          forceSkipCol
        ) {
          continue;
        }

        if (
          isMatchSearchByValue(dataCol, {
            value,
            isExact,
            columns: [targetCol],
          })
        ) {
          matchRow = i;
          matchCol = j;
          break rowLoop;
        }
      }

      if (i === dataSet.length - 1 && matchRow === 0 && matchCol === 0) {
        if (opt.gasLimit === 1) {
          const { row, col } = getSearchMatchCursor(
            hot,
            dataSet,
            searchParams,
            dataModels,
            {
              currentRow: -1,
              currentCol: -1,
              gasLimit: 0,
              isReplaceFunc: opt.isReplaceFunc,
            }
          );
          opt.gasLimit--;
          return {
            row,
            col,
          };
        } else {
          matchCol = -1;
          matchRow = -1;
        }
      }
    }

    const row = matchRow === -1 ? opt.currentRow : matchRow;
    const col = matchCol === -1 ? opt.currentCol : matchCol;

    return { row, col };
  };

  const findSearchMatchPosition = (
    hot: Handsontable | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSet: Record<string, any>[],
    searchParams: ISearchParams,
    opt: Omit<ILoopOption, 'gasLimit'> & { dataModels: DataModel[] }
  ) => {
    const { row, col } = getSearchMatchCursor(
      hot,
      dataSet,
      searchParams,
      opt.dataModels,
      { ...opt, gasLimit: 1 }
    );
    return { row, col };
  };

  const replaceWordSearchMatchAllCells = (
    hot: Handsontable | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSet: Record<string, any>[],
    searchParams: ISearchParams
  ) => {
    const { columns, isExact, wordToReplace } = searchParams;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changes: [number, string, any][] = [];
    for (let i = 0; i < dataSet.length; i++) {
      const row = dataSet[i];
      const cols = Object.keys(row);
      for (let j = 0; j < cols.length; j++) {
        const trimValue = searchParams.value?.trim();
        const targetCol = columns.find((col) => col.key === cols[j]);

        let forceSkipCol = false;
        if (targetCol?.type && DataModel.isTypeDropdown(targetCol?.type)) {
          // NOTE: waiting for confirm replace value of dropdown field
          forceSkipCol = true;
        }
        if (targetCol === undefined || forceSkipCol) continue;

        const currentRawData = row[cols[j]];

        if (
          isMatchSearchByValue(currentRawData, {
            value: trimValue,
            columns: [targetCol],
            isExact,
          })
        ) {
          const currentRawDataReplace = ValueParser.parseRawValueToDisplayValue(
            row[cols[j]],
            targetCol
          );

          const newRawData = replaceWord(
            currentRawDataReplace,
            trimValue,
            wordToReplace ?? ''
          );

          changes.push([i, targetCol.key, newRawData]);
        }
      }
    }

    beforeChangeDataSetLength.current = dataSet.length;
    hot?.setSourceDataAtCell(changes);
  };

  return {
    getAllSearchMatchCount,
    findSearchMatchPosition,
    replaceWordSearchMatchAllCells,
  };
};
