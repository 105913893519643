import Handsontable from 'handsontable';
import { Filters, OperationType } from 'handsontable/plugins/filters';
import { customFilterConditions } from '../columns/FilterStrategy';
import ConditionRegisterer from './ConditionRegisterer';

// NOTE: we need to implement our own custom filter, you can see more detail from this
// https://app.asana.com/0/0/1205276334291320/1206089998164293/f

type ConditionCollectionThisType = {
  hot: Handsontable;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runLocalHooks: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOperation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getConditions: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteringStates: any;
};

export const setupAddCondition = (
  columnFiltering: Filters,
  conditionRegisterer: ConditionRegisterer
) => {
  const originalAddCondition = columnFiltering.conditionCollection.addCondition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((columnFiltering as any)['nuvo_isAppliedCustomAddCondition']) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (columnFiltering as any)['nuvo_isAppliedCustomAddCondition'] = true;
  columnFiltering.conditionCollection.addCondition = function (
    this: ConditionCollectionThisType,
    column: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    conditionDefinition: any,
    operation: OperationType,
    position: number
  ) {
    if (customFilterConditions.includes(conditionDefinition.command?.key)) {
      const localeForColumn = this.hot.getCellMeta(0, column).locale;
      const args = Handsontable.helper.arrayMap(conditionDefinition.args, (v) =>
        typeof v === 'string' ? v.toLocaleLowerCase(localeForColumn) : v
      );
      const name = conditionDefinition.name || conditionDefinition.command.key;

      this.runLocalHooks('beforeAdd', column);

      const conditionsForColumn = this.getConditions(column);

      if (conditionsForColumn.length === 0) {
        this.filteringStates.setValueAtIndex(
          column,
          {
            operation,
            conditions: [
              {
                name,
                args,
                func: conditionRegisterer.getCondition(name, args),
              },
            ],
          },
          position
        );
      } else {
        conditionsForColumn.push({
          name,
          args,
          func: conditionRegisterer.getCondition(name, args),
        });
      }

      this.runLocalHooks('afterAdd', column);
    } else {
      originalAddCondition.apply(this, [
        column,
        conditionDefinition,
        operation,
        position,
      ]);
    }
  };
};
