import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Sheet } from 'sheetImporter';

const useViewModel = ({
  wrapperSheetNameRef,
  allSheets,
}: {
  wrapperSheetNameRef: RefObject<HTMLDivElement>;
  allSheets: Sheet[];
}) => {
  const hasScrollRef = useRef(false);
  const [isHasScroll, setIsHasScroll] = useState(false);
  const offsetScroll = 214;

  const allSheetsSize = useMemo(() => {
    return allSheets.length;
  }, [allSheets]);

  const onScrollWrapperSheet = () => {
    const scrollLeft = wrapperSheetNameRef.current?.scrollLeft ?? 0;
    wrapperSheetNameRef.current?.scrollTo({
      left: Math.max(scrollLeft + offsetScroll, 0),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const wrapperSheetName = wrapperSheetNameRef.current;

    const scrollWidth = wrapperSheetName?.scrollWidth ?? 0;
    const clientWidth = wrapperSheetName?.clientWidth ?? 0;

    if (clientWidth < scrollWidth) {
      hasScrollRef.current = true;
    } else {
      hasScrollRef.current = false;
    }

    function hasScrollWrapperSheet() {
      const scrollWidth = wrapperSheetName?.scrollWidth ?? 0;
      const clientWidth = wrapperSheetName?.clientWidth ?? 0;

      if (clientWidth < scrollWidth) {
        hasScrollRef.current = true;
      } else {
        hasScrollRef.current = false;
      }
    }

    function hasScroller() {
      const scrollWidth = wrapperSheetName?.scrollWidth ?? 0;
      const scrollLeft = wrapperSheetName?.scrollLeft ?? 0;
      const offsetWidth = wrapperSheetName?.offsetWidth ?? 0;

      const scrollEndPoint = offsetWidth + scrollLeft;

      if (scrollEndPoint === scrollWidth) {
        setIsHasScroll(true);
      } else {
        setIsHasScroll(false);
      }
    }

    window.addEventListener('resize', hasScrollWrapperSheet, {
      passive: true,
    });
    wrapperSheetName?.addEventListener('scroll', hasScroller, {
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', hasScrollWrapperSheet);
      wrapperSheetName?.removeEventListener('scroll', hasScroller);
    };
  }, [wrapperSheetNameRef]);

  return {
    allSheetsSize,
    onScrollWrapperSheet,
    hasScroll: hasScrollRef.current,
    isHasScroll,
  };
};

export default useViewModel;
