/* istanbul ignore file */

import { THRESHOLD } from '../constants/similarity';
import { DATATYPE } from '../dataType';
import { DataModel } from '../dataModel/model/DataModel';
import CategoryDataModel, {
  Option,
} from '../dataModel/model/CategoryDataModel';
import Sheet, { Value } from '../sheet/Sheet';
import SheetColumn from './../sheet/SheetColumn';
import Similarity from './Similarity';

export type SheetColumnOption = {
  sheetColumn: SheetColumn;
  option: Value;
};

type DataModelOption = {
  dataModel: DataModel;
  option: Option['value'];
};

class SheetColumnDataModelOptionSimilarity {
  private sheetColumnOption: SheetColumnOption;
  private dataModelOption: DataModelOption;
  private similarity: Similarity;

  constructor({
    sheetColumnOption,
    dataModelOption,
    similarity,
  }: {
    sheetColumnOption: SheetColumnOption;
    dataModelOption: DataModelOption;
    similarity: Similarity;
  }) {
    this.sheetColumnOption = sheetColumnOption;
    this.dataModelOption = dataModelOption;
    this.similarity = similarity;
  }

  getSimilarity = () => {
    return this.similarity;
  };

  getSheetColumnOption = () => {
    return this.sheetColumnOption;
  };

  getDataModelOption = () => {
    return this.dataModelOption;
  };

  isPassThreshold = () => {
    return this.similarity.getSimilarity() > THRESHOLD;
  };

  isNull = () => {
    return false;
  };

  /* istanbul ignore next */
  isEqual = (compareItem: SheetColumnDataModelOptionSimilarity) => {
    return (
      this.getDataModelOption().dataModel ===
        compareItem.getDataModelOption().dataModel &&
      this.getDataModelOption().option ===
        compareItem.getDataModelOption().option &&
      this.getSheetColumnOption().sheetColumn ===
        compareItem.getSheetColumnOption().sheetColumn &&
      this.getSheetColumnOption().option ===
        compareItem.getSheetColumnOption().option
    );
  };

  hash = () => {
    const dataModelKey = this.getDataModelOption().dataModel.getKey();
    const dataModelOption = this.getDataModelOption().option;
    const sheetColumnKey =
      this.getSheetColumnOption().sheetColumn.getColumnKey();

    const sheetOption = this.getSheetColumnOption().option;

    return `${dataModelKey}-${dataModelOption}-${sheetColumnKey}-${sheetOption}`;
  };
}

export class NullSheetColumnDataModelOptionSimilarity extends SheetColumnDataModelOptionSimilarity {
  constructor() {
    super({
      sheetColumnOption: {
        option: 'null',
        sheetColumn: new SheetColumn({
          columnKey: '',
          rows: [],
          sheet: new Sheet({
            data: [[]],
            name: '',
          }),
        }),
      },
      dataModelOption: {
        dataModel: new CategoryDataModel({
          description: 'null',
          example: 'null',
          key: 'null',
          options: [],
          type: DATATYPE.SINGLE_SELECT,
          label: 'null',
          columnSize: 1,
          isMultiSelection: false,
        }),
        option: '',
      },
      similarity: new Similarity({ similarity: 0 }),
    });
  }

  isNull = () => {
    return true;
  };
}

export default SheetColumnDataModelOptionSimilarity;
