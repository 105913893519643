import { EventLogBody } from './services/dto/model';
import { useConfigure } from 'configure';
import { useCallback, useMemo } from 'react';
import { firstValueFrom } from 'rxjs';
import EventLogRepository from './services/repository';
import EventLogAPI from './services/remote';

export const useEventLog = () => {
  const eventLogRepository = useMemo(() => {
    return new EventLogRepository(new EventLogAPI());
  }, []);

  const { licenseKey } = useConfigure();

  const sendLog = useCallback(
    (eventName: string) => {
      const body: EventLogBody = {
        event_name: eventName,
      };
      return firstValueFrom(eventLogRepository.sendData(body, licenseKey));
    },
    [eventLogRepository, licenseKey]
  );

  return { sendLog };
};
