import { NumberFormat } from './dataModel/columnsAPI';
import { NumberParser } from './utils/NumberParser';

const regexCurrencyEN = /^[1-9]\d*(((,\d{3}){1,})?(\.\d*)?)$/;

/**
 * It takes a string, replaces all dots with nothing, replaces all commas with dots, and then converts
 * the string to a number
 * @param {string} valueString - The string that you want to convert to a number.
 * @returns A function that takes a string and returns a number.
 */
export const parseNumberStringToNumber = (
  valueString: string,
  acceptEnFormat = false
) => {
  if (acceptEnFormat) {
    const isCurrencyEN = regexCurrencyEN.test(valueString);

    const valueNumber =
      isNaN(Number(valueString)) && !isCurrencyEN
        ? Number(valueString.replace(/\./g, '').replace(',', '.'))
        : isCurrencyEN
        ? Number(valueString.split(',').join(''))
        : Number(valueString.replace(',', '.'));

    return valueNumber;
  } else {
    return Number(valueString.replace(/\./g, '').replace(',', '.'));
  }
};

export const parseTextPercent = (
  valueString: unknown,
  divideBy100 = false,
  numberFormat?: NumberFormat
): string | number => {
  const isString =
    typeof valueString === 'string' && valueString.trim().length !== 0;

  if (!isString) {
    return `${valueString}`;
  }

  const percentSymbol = '%';

  const converted: string | null = NumberParser.convertStringToNumber(
    valueString.replace(percentSymbol, ''),
    { format: numberFormat }
  );

  if (converted === null) {
    return valueString;
  }

  if (valueString.includes('%') && divideBy100) {
    return Number(converted) / 100;
  }

  return Number(converted);
};
