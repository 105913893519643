import { createContext, ReactNode, useContext, useState } from 'react';

type SheetImporterContextType = {
  gridHeight: number | null;
  setGridHeight: (height: number | null) => void;
};

const SheetImporterContext = createContext({} as SheetImporterContextType);

export const SheetImporterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [gridHeight, setGridHeight] = useState<null | number>(null);

  return (
    <SheetImporterContext.Provider
      value={{
        gridHeight,
        setGridHeight,
      }}
    >
      {children}
    </SheetImporterContext.Provider>
  );
};

export const useSheetImporter = () => {
  return useContext(SheetImporterContext);
};
