/* istanbul ignore file */

import { useDataModelSheetFormConfig } from 'dataModelSheet';
import { useCallback } from 'react';
import SpreadSheetList from './SpreadSheetList';

const useUploadFilePolicy = () => {
  const { maxRecords, warnExceedMaxRecords, warnSheetNeedToHasData } =
    useDataModelSheetFormConfig();

  const checkPolicy = useCallback(
    (spreadSheetList: SpreadSheetList) => {
      const allSheets = spreadSheetList.getAllSheets();

      const isAllSheetExceedMaxRecords =
        maxRecords &&
        allSheets
          .map((sheet) => sheet.getValues().length > maxRecords)
          .filter((sheet) => sheet).length === allSheets.length;

      if (allSheets.length === 0) {
        warnSheetNeedToHasData();
        return false;
      } else if (isAllSheetExceedMaxRecords) {
        warnExceedMaxRecords();
        return false;
      } else {
        return true;
      }
    },
    [maxRecords, warnExceedMaxRecords, warnSheetNeedToHasData]
  );

  return { checkPolicy };
};

export default useUploadFilePolicy;
