/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next, { i18n } from 'i18next';
import { useSettings } from 'settings';
import { enLocale } from 'locales';
import { BooleanSingleton } from '@nuvo-importer/common/sdk';
import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { LocaleObject } from '../license/types';

const OverridesTranslator = ({ children }: { children: ReactNode }) => {
  const setting = useSettings();
  const { featureWhiteList } = useFeatureWhiteList();
  const i18nOverrides = setting.i18nOverrides;
  const [i18nIns, setI18nIns] = useState<i18n | null>(null);

  const language = useMemo(() => {
    if (featureWhiteList.getEnableI18nOverrides()) {
      if (setting?.language) {
        return setting?.language;
      }
    }
    return featureWhiteList.getDefaultLanguage();
  }, [featureWhiteList, setting?.language]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resources: Record<string, any> = {};
    const langKey = language || featureWhiteList.getDefaultLanguage();
    const value = i18nOverrides?.[langKey];
    const localeFromApi = featureWhiteList.getLocale();
    const forceCombineEnLocal = process.env.NX_COMBINE_LOCALE_EN === 'true';
    const emptyLocale: LocaleObject = {};

    /**
     * NOTE: the default error message key for dynamic import
     * when executed before the finishing call API
     */
    const defaultErrorLocaleKeys = [
      'txt_invalid_license_key',
      'txt_invalid_license_key_description',
    ];

    Object.keys(enLocale).forEach((key) => {
      if (defaultErrorLocaleKeys.includes(key)) {
        emptyLocale[key] = (enLocale as LocaleObject)?.[key] || '';
      } else {
        emptyLocale[key] = '';
      }
    });

    let locale: LocaleObject = emptyLocale;

    if (!featureWhiteList.isLoading()) {
      if (Object.keys(localeFromApi).length > 0) {
        locale = forceCombineEnLocal
          ? { ...enLocale, ...localeFromApi }
          : { ...localeFromApi };
      } else {
        locale = enLocale;
      }

      if (featureWhiteList.getEnableI18nOverrides()) {
        if (typeof value === 'object') {
          resources[langKey] = {
            translation: { ...locale, ...value },
          };
        } else {
          resources[langKey] = {
            translation: { ...locale, ...i18nOverrides },
          };
        }
      } else {
        resources[langKey] = {
          translation: { ...locale },
        };
      }
    } else {
      resources[langKey] = {
        translation: { ...locale },
      };
    }

    const instance = i18next.createInstance();
    instance
      .init({
        resources,
        lng: langKey,
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => {
        setI18nIns(instance);
      })
      .then(() => {
        BooleanSingleton.getInstance().setOptions([
          {
            label: (
              resources?.[instance.language]?.translation as Record<
                string,
                string
              >
            )?.['txt_boolean_yes'],
            value: 'true',
          },
          {
            label: (
              resources?.[instance.language]?.translation as Record<
                string,
                string
              >
            )?.['txt_boolean_no'],
            value: 'false',
          },
        ]);
        BooleanSingleton.getInstance().setTruthyWords(
          resources?.[instance.language]?.translation?.['txt_boolean_yes']
        );
        BooleanSingleton.getInstance().setFalsyWords(
          resources?.[instance.language]?.translation?.['txt_boolean_no']
        );
      });
  }, [featureWhiteList, i18nOverrides, language]);

  if (!i18nIns) {
    return null;
  }

  return <I18nextProvider i18n={i18nIns}>{children}</I18nextProvider>;
};

export default OverridesTranslator;
