import React, { useCallback, useContext, useMemo } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { ICleaningAssistantSuggestion } from '../../api/CleaningAssistant.dto';
import Checkbox from '../../../../ContextMenu/components/Checkbox';
import { Check, Locate, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/Button';

interface IProps {
  suggestion: ICleaningAssistantSuggestion;
}

const Suggestion: React.FC<IProps> = ({ suggestion }: IProps) => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const {
    selectedSuggestions,
    onSelectSuggestion,
    onFind,
    onDismiss,
    onApply,
  } = context;

  const selected: boolean = useMemo(() => {
    return !!selectedSuggestions.find(
      (s: ICleaningAssistantSuggestion) =>
        s.suggestion === suggestion.suggestion
    );
  }, [selectedSuggestions, suggestion.suggestion]);

  const s = styles(context);

  const onSelect = useCallback(() => {
    onSelectSuggestion(suggestion);
  }, [onSelectSuggestion, suggestion]);

  const handleFind = useCallback(() => {
    onFind(suggestion);
  }, [onFind, suggestion]);

  const handleDismiss = useCallback(() => {
    onDismiss([suggestion]);
  }, [onDismiss, suggestion]);

  const handleApply = useCallback(() => {
    onApply([suggestion]);
  }, [onApply, suggestion]);

  return (
    <div className={s.rootClass}>
      <Checkbox
        checked={selected}
        onChecked={onSelect}
        className={s.checkboxClass}
      />
      <div className={s.detailsClass}>
        <div className={s.newValue}>{suggestion.suggestion}</div>
        <div className={s.oldValue}>
          {suggestion.value || (
            <span className={s.oldValueEmpty}>{t('txt_empty')}</span>
          )}
        </div>
      </div>

      <div className={s.actionsClass}>
        <button
          type="button"
          className={s.findButtonClass}
          onClick={handleFind}
        >
          <Locate size={16} />
        </button>
        <button
          type="button"
          className={s.dismissButtonClass}
          onClick={handleDismiss}
        >
          <X size={16} />
        </button>
        <Button
          type="button"
          className={s.applyButtonClass}
          onClick={handleApply}
        >
          <Check size={16} />
        </Button>
      </div>
    </div>
  );
};

export default Suggestion;
