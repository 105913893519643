import { CSSObject } from '../../core/emotion';
import { ThemeAPI } from '../themeAPI';
import ButtonTheme from './ButtonTheme';
import chroma from 'chroma-js';
import PrimaryButtonTheme from './PrimaryButtonTheme';

class ImportButtonTheme extends ButtonTheme {
  constructor(themeAPI: ThemeAPI) {
    super('import', 'primaryColor', themeAPI);
  }

  generateByGlobalColor = () => {
    const primaryButtonTheme = new PrimaryButtonTheme(this.themeAPI);
    return primaryButtonTheme.generateByGlobalColor();
  };

  protected generateByButtonTheme = () => {
    const theme = {
      ...((this.themeAPI.buttons?.['import'] as CSSObject) ?? {}),
    };

    if (theme.backgroundColor && !theme[':hover']?.backgroundColor) {
      const basedColor = chroma(theme.backgroundColor as string);
      const hoverColor = chroma
        .hsl(
          basedColor.get('hsl.h') + 0.10204,
          basedColor.get('hsl.s') - 0.00009,
          basedColor.get('hsl.l') - 0.103
        )
        .hex();

      theme[':hover'] = {
        backgroundColor: hoverColor,
      };
    }

    return theme;
  };
}

export default ImportButtonTheme;
