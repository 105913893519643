import { useMemo, RefObject } from 'react';
import TruncatePopover from 'baseUI/TruncatePopover';
import { css, CSSObject, cx } from 'core/emotion';
import { breakpoints } from 'core/constants/breakpoints';
import ValueFormatter from 'core/valueFormatter/ValueFormatter';
import { useTheme } from 'theme';
import EmptyText from '../common/EmptyRows/EmptyText';
import { getIsEmpty } from '../common/emptyState';
import { Row } from '../common/rowExamples';

const style = css`
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

const ExampleValues = ({ rows }: { rows: Row[] }) => {
  const theme = useTheme();
  const colBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getLight100Color();
  }, [theme]);

  return (
    <div>
      {rows.map((row, rowIndex) => {
        const isLastRowIndex = rowIndex === rows.length - 1;
        const isEmpty = getIsEmpty(row.value);

        return (
          <div
            key={row.index}
            className={cx(
              'border-1 flex h-12 bg-white pr-4',
              isLastRowIndex ? 'rounded-b-medium' : 'border-b-0',
              style,
              css({
                borderColor: colBorderColor,
              }),
              css({ '&&': theme.getColumnMatchTheme().columnMatchValue.root }),
              css({
                '&&': {
                  borderColor: (
                    theme.getColumnMatchTheme().columnMatchValue
                      .root as CSSObject
                  )?.borderColor,
                },
              })
            )}
          >
            <div className="flex">
              <div
                className={cx(
                  'border-r-1 xssmd:w-14 flex w-9 flex-shrink-0 items-center justify-center',
                  css({
                    borderColor: colBorderColor,
                  }),
                  css({
                    '&&': {
                      borderColor: (
                        theme.getColumnMatchTheme().columnMatchValue
                          .root as CSSObject
                      )?.borderColor,
                    },
                  })
                )}
              >
                <span
                  className={cx(
                    'text-color-primary text-sm',
                    css({
                      '&&': {
                        color: (
                          theme.getColumnMatchTheme().columnMatchValue
                            .root as CSSObject
                        )?.color,
                      },
                    })
                  )}
                >
                  {row.index}
                </span>
              </div>
              <div className="xssmd:pl-4 flex items-center pl-2">
                {isEmpty ? (
                  <EmptyText
                    className={css({
                      '&&': theme.getColumnMatchTheme()?.columnMatchValue
                        ?.emptyValue,
                    })}
                  />
                ) : (
                  <TruncatePopover
                    isCheckLineClamp
                    render={({ referenceElement, textRef, getHover }) => {
                      return (
                        <div
                          ref={referenceElement as RefObject<HTMLDivElement>}
                          {...getHover()}
                        >
                          <p
                            ref={textRef as RefObject<HTMLParagraphElement>}
                            className={cx(
                              'text-color-primary line-clamp-2 break-all text-sm',
                              css({
                                '&&': {
                                  color: (
                                    theme.getColumnMatchTheme().columnMatchValue
                                      .root as CSSObject
                                  )?.color,
                                },
                              })
                            )}
                          >
                            {ValueFormatter.format(row.value)}
                          </p>
                        </div>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExampleValues;
