import { css, cx } from '../../core/emotion';
import ThemeAPIMapper from '../../theme/ThemeAPIMapper';

export type Variant = 'primary' | 'danger';

type VariantClassname = (theme: ThemeAPIMapper) => string;

const primaryClassname: VariantClassname = (theme: ThemeAPIMapper) => {
  return cx(
    'disabled:opacity-75',
    css({
      backgroundColor: theme.getGlobalTheme().getPrimaryColor(),
    })
  );
};

const dangerClassname: VariantClassname = () => {
  return 'btn-salmon-500 disabled:opacity-50';
};

export const variantClassname = ({
  variant,
  theme,
}: {
  variant: Variant;
  theme: ThemeAPIMapper;
}) => {
  switch (variant) {
    case 'primary':
      return primaryClassname(theme);
    case 'danger':
      return dangerClassname(theme);
    default: {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`variant is not avialable: ${variant}`);
      }
      return '';
    }
  }
};
