import { ModelTemplate } from 'core/constants/modelTemplate';
import { exportToCsv, exportToXlsx } from 'core/downloadTemplateSheet';
import { useDataModels } from 'dataModel';
import { useCallback, useMemo, useState } from 'react';
import { useSettings } from 'settings';

const useViewModel = () => {
  const dataModelAPIMapper = useDataModels();
  const { identifier } = useSettings();
  const [isExporting, setExporting] = useState(false);

  const fileName = identifier ?? 'model';

  const models = useMemo(() => {
    return dataModelAPIMapper.getDataModels();
  }, [dataModelAPIMapper]);

  const downloadTemplateSheet = useCallback(
    (type: ModelTemplate) => {
      if (type === ModelTemplate.XLSX) {
        setExporting(true);
        setTimeout(() => {
          exportToXlsx(fileName, models).then(() => {
            setExporting(false);
          });
        }, 500);
      } else {
        exportToCsv(fileName, models);
      }
    },
    [models, fileName]
  );

  return {
    isExporting,
    downloadTemplateSheet,
  };
};

export default useViewModel;
