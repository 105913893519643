import { useTranslation } from 'react-i18next';
import AllColumnSetting, {
  ColumnSetting,
} from '../../columns/AllColumnSetting';
import {
  FilterLogicalOperator,
  FilterValueItems,
} from '../../columns/FilterStrategy';
import ContextMenuController from '../controller/ContextMenuController';
import useFilterByCondition from './hooks/filterByCondition';
import useFilterByValue from './hooks/filterByValue';
import { useMemo, useState } from 'react';
import { DataModel } from '../../../../dataModel/model/DataModel';

const useViewModel = ({
  allColumnSetting,
  contextMenuController,
  valueItems,
  columnSetting,
  currentColumnIndex,
  currentDataModel,
}: {
  columnSetting: ColumnSetting;
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  valueItems: FilterValueItems;
  currentColumnIndex: number;
  currentDataModel: DataModel;
}) => {
  const {
    logicalOperator,
    onFirstFilterByConditionChange,
    onSecondFilterByConditionChange,
    setLogicalOperator,
    filterByConditions,
  } = useFilterByCondition({ columnSetting });
  const {
    valuesSelected,
    onValuesSelected,
    searchValue,
    setSearchValue,
    showValueItems,
    checkedCount,
  } = useFilterByValue({
    columnSetting,
    valueItems,
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [clearFilterLoading, setClearFilterLoading] = useState(false);

  const logicalOptions = useMemo(
    () => [
      {
        label: t('txt_logic_and'),
        value: FilterLogicalOperator.AND,
      },
      {
        label: t('txt_logic_or'),
        value: FilterLogicalOperator.OR,
      },
    ],
    [t]
  );

  const onApplyClick = () => {
    setLoading(true);
    const filterValueItems = allColumnSetting
      .getFilterStrategy()
      .getFilterValueItems();

    const updatedValuesSelected =
      filterValueItems.removeSelectedNotInValueItems(
        valuesSelected,
        valueItems,
        currentDataModel
      );

    const isSelectedAllValueItems = filterValueItems.isSelectedAllValueItems(
      updatedValuesSelected,
      valueItems
    );

    if (!isSelectedAllValueItems) {
      filterValueItems.setValueItemsSnapshot(currentColumnIndex, valueItems);
    } else {
      filterValueItems.clearAllValueItemsSnapshot();
    }

    delayTask(() => {
      allColumnSetting.setFilterToColumnSetting(
        currentColumnIndex,
        {
          byCondition: {
            items: [
              {
                condition: filterByConditions[0].condition,
                value: filterByConditions[0].value,
                secondValue: filterByConditions[0].secondValue,
              },
              filterByConditions[1]
                ? {
                    condition: filterByConditions[1].condition,
                    value: filterByConditions[1].value,
                    secondValue: filterByConditions[1].secondValue,
                  }
                : null,
            ],
            logicalOperator,
          },
          byValue: {
            selected: isSelectedAllValueItems ? null : updatedValuesSelected,
          },
        },
        currentDataModel
      );
      contextMenuController.closeMenu();
    });
  };

  const onClearFilterClick = () => {
    setClearFilterLoading(true);
    delayTask(() => {
      allColumnSetting.clearFilterToColumnSetting(
        currentColumnIndex,
        currentDataModel
      );
      contextMenuController.closeMenu();
    });
  };

  const delayTask = (func: () => void, delayTime = 100) => {
    setTimeout(() => {
      func();
    }, delayTime);
  };

  return {
    filterByConditions,
    onFirstFilterByConditionChange,
    onSecondFilterByConditionChange,
    logicalOperator,
    logicalOptions,
    setLogicalOperator,
    valuesSelected,
    onValuesSelected,
    onApplyClick,
    onClearFilterClick,
    searchValue,
    setSearchValue,
    showValueItems,
    loading,
    clearFilterLoading,
    checkedCount,
  };
};

export default useViewModel;
