import {
  ColumnConfig,
  ExecuteOptions,
  FullResultMatching,
  SuggestionMatching,
} from '../../types/ml';
import { Value } from '@nuvo-importer/common/sdk';
import { zip, map, from } from 'rxjs';
import {
  getColumnMappings,
  getOptionMappings,
  IOptionMappingMode,
  IResultColumnsMapping,
  IResultOptionsMapping,
} from '../../../modules/nuvo.mapping';
import Tracking from '../../../tracking/Tracking';
import { flatten } from 'lodash';

class NodeProcessingEngine {
  matchColumns = (
    headers: Value[],
    columns: ColumnConfig[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return from(
      getColumnMappings(
        columns,
        headers.map((header) => `${header}`),
        {
          licenseKey: licenseKey,
          enableRememberMapping: options.reuseMappingData,
          originRequest: Tracking.getInstance().getOrigin(),
        }
      ).then((result) => this.parseColumnMappingResult(result))
    );
  };

  matchOptions = (
    fileId: string,
    mappingHeaderColumns: {
      column_index: number;
      column: ColumnConfig;
    }[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return zip(
      mappingHeaderColumns.map((item) =>
        getOptionMappings(item.column, [], {
          licenseKey: licenseKey,
          processingEngine: 'node',
          enableRememberMapping: options.reuseMappingData,
          fileLocation: fileId,
          columnIndex: item.column_index,
          originRequest: Tracking.getInstance().getOrigin(),
          optionMappingMode: (item.column.optionMappingMode ??
            'smart') as IOptionMappingMode,
        })
      )
    ).pipe(map((results) => this.parseOptionMappingResult(flatten(results))));
  };

  protected parseOptionMappingResult(results: IResultOptionsMapping[]) {
    const result: FullResultMatching[] = [];
    for (let i = 0; i < results.length; i++) {
      const item = results[i];
      result.push({
        choice: {
          [item.outputColumnKey]: item.optionSuggestions,
        },
        label: item.inputColumnLabel,
        suggestions: [
          { key: item.outputColumnKey, value: 1, isSameWord: true },
        ],
      });
    }
    return [{ result }];
  }

  protected parseColumnMappingResult(results: IResultColumnsMapping[]) {
    const parsedResults: FullResultMatching[] = [];
    for (let i = 0; i < results.length; i++) {
      const mappingColumn = results[i];
      const suggestions: SuggestionMatching[] = [];
      for (let j = 0; j < mappingColumn.columnSuggestions.length; j++) {
        const columnSuggestion = mappingColumn.columnSuggestions[j];
        suggestions.push({
          key: columnSuggestion.key,
          value: columnSuggestion.percentage,
        });
      }
      parsedResults.push({
        label: mappingColumn.inputColumnKey,
        suggestions,
        choice: {},
      });
    }
    return [{ result: parsedResults }];
  }
}

export default NodeProcessingEngine;
