import Badge from './Badge';
import { ReactComponent as ArrowUpGrayIcon } from '../../assets/icon/arrow-up-gray.svg';
import { css, cx } from '../../core/emotion';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';

const ShowMoreButton = ({
  onClick,
  requiredCount,
  isShow,
}: {
  onClick: () => void;
  requiredCount: number;
  isShow: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isShowBadge = requiredCount > 0;

  return (
    <button
      type="button"
      className={cx(
        'rounded-t-medium relative top-px flex items-center bg-white px-4',
        isShowBadge ? 'py-1' : 'py-1.5',
        css({
          '&&': theme.getRequiredColumnsTheme().showMore.root,
        })
      )}
      onClick={onClick}
    >
      <span
        className={cx(
          'text-gray-510 mr-2 text-xs tracking-wide',
          css({ '&&': theme.getRequiredColumnsTheme().showMore.text })
        )}
      >
        {isShow ? t('txt_show_less') : t('txt_show_more')}
      </span>
      {isShowBadge ? (
        <Badge
          className={cx(
            css({ '&&': theme.getRequiredColumnsTheme().showMore.badge })
          )}
        >
          {requiredCount}
        </Badge>
      ) : null}
      <ArrowUpGrayIcon
        className={cx(
          'ml-2',
          isShow ? 'rotate-180 transform' : '',
          css({ '&& path': theme.getRequiredColumnsTheme().showMore.icon })
        )}
      />
    </button>
  );
};

export default ShowMoreButton;
