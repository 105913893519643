import Handsontable from 'handsontable';
import CheckState, { CheckAllState } from './CheckState';

class CheckboxView {
  static checkedRowClassName = 'checked-row';
  static rowHeaderCheckboxClassName = 'row-header-checkbox';
  static checkAllCheckboxClassName = 'check-all-checkbox';
  static hoverRowClassName = 'hover-row';
  static rowHeaderClassName = 'rowHeader';
  private checkedState: CheckState;

  constructor(checkedState: CheckState) {
    this.checkedState = checkedState;
  }

  renderCheckboxPerRow = (rowIndex: number) => {
    return `<div class="flex justify-center w-full">
      <span class="row-header-number">${rowIndex + 1}</span><input class="${
      CheckboxView.rowHeaderCheckboxClassName
    } rounded-medium !w-4 !h-4 block focus:ring-0 focus:outline-none border-gray-260" type="checkbox"/>
    </div>`;
  };

  renderCheckboxAll = (
    TH: HTMLTableHeaderCellElement,
    mediaSize: boolean,
    enableExample?: boolean
  ) => {
    const inputElement = document.createElement('INPUT') as HTMLInputElement;
    inputElement.setAttribute('type', 'checkbox');
    inputElement.tabIndex = -1;
    inputElement.classList.add(CheckboxView.checkAllCheckboxClassName);
    if (enableExample) {
      inputElement.style.position = 'relative';
      if (mediaSize) {
        inputElement.style.top = '-12px';
      } else {
        inputElement.style.top = '-16px';
      }
    }
    const checkAllState = this.checkedState.getCheckAllState();

    TH.style.verticalAlign = 'middle';
    inputElement.style.display = 'inline-block';
    inputElement.style.marginTop = '-2px';

    if (checkAllState === CheckAllState.NO_CHECK) {
      inputElement.style.opacity = '0';
    } else if (checkAllState === CheckAllState.CHECK_ALL) {
      inputElement.checked = true;
    } else {
      inputElement.indeterminate = true;
    }

    Handsontable.dom.empty(TH);
    TH.appendChild(inputElement);
  };
}

export default CheckboxView;
