import { CategoryDataModel, DataModel } from 'dataModel';
import { isNil } from 'lodash';
import DataModelSheetMatching, {
  DataModelSheetMatch,
} from '../../matching/DataModelSheetMatching';
import { useCallback } from 'react';
import { useSettings } from 'settings';
import ColumnsAPIMapper from '../../dataModel/ColumnsAPIMapper';

export const useSubmit = () => {
  const { columns } = useSettings();

  const getDropdownOptionsForCustomOptions = (
    dataModelSheetMatching: DataModelSheetMatching,
    dataModel: CategoryDataModel
  ) => {
    const matchedDataModel = dataModelSheetMatching.getMatchingByDataModelKey(
      dataModel.getKey()
    )?.matchedDataModel;

    const categoryDateModel = dataModel;
    const baseDropdownOptions = categoryDateModel?.getOptions() ?? [];

    const dropdownOptions = baseDropdownOptions.filter((item) => {
      if (item.creator) {
        if (!dataModel.getIsMultiSelection()) {
          const matchedDataModelOption = matchedDataModel?.matchedOptions?.find(
            (matchedOption) => matchedOption.dataModelOption === item.value
          );

          return !isNil(matchedDataModelOption);
        } else {
          const matchedDataModelOption = matchedDataModel?.matchedOptions?.find(
            (matchedOption) =>
              matchedOption.dataModelOptions?.find(
                (dataModelOption) => dataModelOption === item.value
              )
          );

          return !isNil(matchedDataModelOption);
        }
      } else {
        return true;
      }
    });

    return dropdownOptions;
  };

  const getColumnsAndDataModels = useCallback(
    (
      dataModelSheetMatching: DataModelSheetMatching,
      matching: DataModelSheetMatch[]
    ) => {
      const dataModels = dataModelSheetMatching.getDataModels();

      const requiredDataModels = dataModelSheetMatching
        .getDataModels()
        .filter((dataModel) => dataModel.getIsRequired());

      const notMatchedDataModel = requiredDataModels.filter(
        (requiredDataModel) => {
          return !matching.some((matching) => {
            return (
              matching.matchedDataModel?.dataModel?.getKey() ===
              requiredDataModel.getKey()
            );
          });
        }
      );

      const reviewEntriesColumns = [
        ...columns.map((column) => {
          const dataModel = dataModels.find(
            (dataModel) => dataModel.getKey() === column.key
          );
          const matchedDataModel =
            dataModelSheetMatching.getMatchingByDataModelKey(
              column.key
            )?.matchedDataModel;

          if (dataModel?.isCategoryType() && matchedDataModel) {
            const categoryDateModel = dataModel as CategoryDataModel;
            const dropdownOptions = getDropdownOptionsForCustomOptions(
              dataModelSheetMatching,
              categoryDateModel
            );

            return {
              ...column,
              dropdownOptions,
            };
          } else {
            return column;
          }
        }),
      ];

      const matchedDataModelsKeys =
        dataModelSheetMatching.getMatchedDataModelKeys();

      const reviewEntriesDataModels: DataModel[] = dataModels
        .filter((dataModel) => {
          if (dataModel.getCreator()) {
            return matchedDataModelsKeys.includes(dataModel.getKey());
          } else {
            return true;
          }
        })
        .map((dataModel) => {
          const matchedDataModel =
            dataModelSheetMatching.getMatchingByDataModelKey(
              dataModel.getKey()
            )?.matchedDataModel;

          if (dataModel.isCategoryType() && matchedDataModel) {
            const categoryDateModel = dataModel as CategoryDataModel;
            const dropdownOptions = getDropdownOptionsForCustomOptions(
              dataModelSheetMatching,
              categoryDateModel
            );

            const cloneCategoryDateModel = categoryDateModel.clone();
            cloneCategoryDateModel.setOptions(dropdownOptions);
            return cloneCategoryDateModel;
          } else {
            return dataModel;
          }
        });

      const customDataModels = dataModelSheetMatching
        .getDataModels()
        .filter((entry) => entry.getCreator());

      const customMatchedDataModels = customDataModels.filter((entry) =>
        matchedDataModelsKeys.includes(entry.getKey())
      );

      for (let i = 0; i < customMatchedDataModels.length; i++) {
        const entry = customMatchedDataModels[i];

        reviewEntriesColumns.push({
          label: entry.getLabel(),
          key: entry.getKey(),
          columnType: ColumnsAPIMapper.mapColumnType({
            dataType: entry.getType(),
          }),
          description: entry.getDescription(),
          outputFormat: entry.getOutputFormat(),
        });
      }

      return {
        reviewEntriesDataModels,
        reviewEntriesColumns,
        notMatchedDataModel,
      };
    },
    [columns]
  );

  return { getColumnsAndDataModels };
};
