import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterCondition } from '../../../columns/FilterStrategy';
import { DataModel } from '../../../../../../core/dataModel/model/DataModel';
import { DATATYPE } from '../../../../../../core/dataType';

type OptionsProps = {
  currentDataModel: DataModel;
};

const useOptions = ({ currentDataModel }: OptionsProps) => {
  const { t } = useTranslation();

  const labelForConditions = useMemo(() => {
    return {
      [FilterCondition.NONE]: t('txt_filter_condition_none'),
      [FilterCondition.IS_EMPTY]: t('txt_filter_condition_is_empty'),
      [FilterCondition.IS_NOT_EMPTY]: t('txt_filter_condition_is_not_empty'),
      [FilterCondition.IS_EQUAL_TO]: t('txt_filter_condition_is_equal_to'),
      [FilterCondition.IS_NOT_EQUAL_TO]: t(
        'txt_filter_condition_is_not_equal_to'
      ),
      [FilterCondition.BEGINS_WITH]: t('txt_filter_condition_begins_with'),
      [FilterCondition.ENDS_WITH]: t('txt_filter_condition_ends_with'),
      [FilterCondition.CONTAINS]: t('txt_filter_condition_contains'),
      [FilterCondition.DOES_NOT_CONTAIN]: t(
        'txt_filter_condition_does_not_contain'
      ),
      [FilterCondition.IS_ERROR]: t('txt_filter_condition_is_error'),
      [FilterCondition.IS_WARNING]: t('txt_filter_condition_is_warning'),
      [FilterCondition.IS_INFO]: t('txt_filter_condition_is_info'),
      [FilterCondition.GREATER_THAN]: t('txt_filter_condition_greater_than'),
      [FilterCondition.GREATER_THAN_OR_EQUAL]: t(
        'txt_filter_condition_greater_than_or_equal'
      ),
      [FilterCondition.LESS_THAN]: t('txt_filter_condition_less_than'),
      [FilterCondition.LESS_THAN_OR_EQUAL]: t(
        'txt_filter_condition_less_than_or_equal'
      ),
      [FilterCondition.BETWEEN]: t('txt_filter_condition_is_between'),
      [FilterCondition.NOT_BETWEEN]: t('txt_filter_condition_is_not_between'),
      [FilterCondition.DATE_BEFORE]: t('txt_filter_condition_date_before'),
      [FilterCondition.DATE_AFTER]: t('txt_filter_condition_date_after'),
      [FilterCondition.DATE_TOMORROW]: t('txt_filter_condition_date_tomorrow'),
      [FilterCondition.DATE_TODAY]: t('txt_filter_condition_date_today'),
      [FilterCondition.DATE_YESTERDAY]: t(
        'txt_filter_condition_date_yesterday'
      ),
    };
  }, [t]);

  const commonOptions = useMemo(() => {
    return [
      {
        label: labelForConditions[FilterCondition.NONE],
        value: FilterCondition.NONE,
      },
      {
        label: labelForConditions[FilterCondition.IS_EMPTY],
        value: FilterCondition.IS_EMPTY,
      },
      {
        label: labelForConditions[FilterCondition.IS_NOT_EMPTY],
        value: FilterCondition.IS_NOT_EMPTY,
        separator: true,
      },
      {
        label: labelForConditions[FilterCondition.IS_EQUAL_TO],
        value: FilterCondition.IS_EQUAL_TO,
      },
      {
        label: labelForConditions[FilterCondition.IS_NOT_EQUAL_TO],
        value: FilterCondition.IS_NOT_EQUAL_TO,
        separator: true,
      },
    ];
  }, [labelForConditions]);

  const dataInfoOptions = useMemo(() => {
    return [
      {
        label: labelForConditions[FilterCondition.IS_ERROR],
        value: FilterCondition.IS_ERROR,
      },
      {
        label: labelForConditions[FilterCondition.IS_WARNING],
        value: FilterCondition.IS_WARNING,
      },
      {
        label: labelForConditions[FilterCondition.IS_INFO],
        value: FilterCondition.IS_INFO,
      },
    ];
  }, [labelForConditions]);

  const stringOptions = useMemo(() => {
    return [
      ...commonOptions,
      {
        label: labelForConditions[FilterCondition.BEGINS_WITH],
        value: FilterCondition.BEGINS_WITH,
      },
      {
        label: labelForConditions[FilterCondition.ENDS_WITH],
        value: FilterCondition.ENDS_WITH,
        separator: true,
      },
      {
        label: labelForConditions[FilterCondition.CONTAINS],
        value: FilterCondition.CONTAINS,
      },
      {
        label: labelForConditions[FilterCondition.DOES_NOT_CONTAIN],
        value: FilterCondition.DOES_NOT_CONTAIN,
        separator: true,
      },
      ...dataInfoOptions,
    ];
  }, [labelForConditions, commonOptions, dataInfoOptions]);

  const numericOptions = useMemo(() => {
    return [
      ...commonOptions,
      {
        label: labelForConditions[FilterCondition.GREATER_THAN],
        value: FilterCondition.GREATER_THAN,
      },
      {
        label: labelForConditions[FilterCondition.GREATER_THAN_OR_EQUAL],
        value: FilterCondition.GREATER_THAN_OR_EQUAL,
      },
      {
        label: labelForConditions[FilterCondition.LESS_THAN],
        value: FilterCondition.LESS_THAN,
      },
      {
        label: labelForConditions[FilterCondition.LESS_THAN_OR_EQUAL],
        value: FilterCondition.LESS_THAN_OR_EQUAL,
      },
      {
        label: labelForConditions[FilterCondition.BETWEEN],
        value: FilterCondition.BETWEEN,
      },
      {
        label: labelForConditions[FilterCondition.NOT_BETWEEN],
        value: FilterCondition.NOT_BETWEEN,
        separator: true,
      },
      ...dataInfoOptions,
    ];
  }, [labelForConditions, commonOptions, dataInfoOptions]);

  const dateOptions = useMemo(() => {
    return [
      ...commonOptions,
      {
        label: labelForConditions[FilterCondition.DATE_BEFORE],
        value: FilterCondition.DATE_BEFORE,
      },
      {
        label: labelForConditions[FilterCondition.DATE_AFTER],
        value: FilterCondition.DATE_AFTER,
      },
      {
        label: labelForConditions[FilterCondition.BETWEEN],
        value: FilterCondition.BETWEEN,
        separator: true,
      },
      {
        label: labelForConditions[FilterCondition.DATE_TOMORROW],
        value: FilterCondition.DATE_TOMORROW,
      },
      {
        label: labelForConditions[FilterCondition.DATE_TODAY],
        value: FilterCondition.DATE_TODAY,
      },
      {
        label: labelForConditions[FilterCondition.DATE_YESTERDAY],
        value: FilterCondition.DATE_YESTERDAY,
        separator: true,
      },
      ...dataInfoOptions,
    ];
  }, [labelForConditions, commonOptions, dataInfoOptions]);

  const dropdownOptions = useMemo(() => {
    return [
      {
        label: labelForConditions[FilterCondition.NONE],
        value: FilterCondition.NONE,
      },
      {
        label: labelForConditions[FilterCondition.IS_EMPTY],
        value: FilterCondition.IS_EMPTY,
      },
      {
        label: labelForConditions[FilterCondition.IS_NOT_EMPTY],
        value: FilterCondition.IS_NOT_EMPTY,
        separator: true,
      },
      ...dataInfoOptions,
    ];
  }, [labelForConditions, dataInfoOptions]);

  const options = useMemo(() => {
    const columnType = currentDataModel.getType();
    switch (columnType) {
      case DATATYPE.BOOLEAN:
      case DATATYPE.SINGLE_SELECT:
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
      case DATATYPE.CURRENCY_CODE:
        return dropdownOptions;
      case DATATYPE.INTEGER:
      case DATATYPE.FLOAT:
      case DATATYPE.PERCENTAGE:
      case DATATYPE.CURRENCY_EUR:
      case DATATYPE.CURRENCY_USD:
        return numericOptions;
      case DATATYPE.DATE:
        return dateOptions;
      default:
        return stringOptions;
    }
  }, [
    stringOptions,
    numericOptions,
    dateOptions,
    dropdownOptions,
    currentDataModel,
  ]);

  return { options };
};

export default useOptions;
