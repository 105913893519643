import { FilterCondition } from '../../columns/FilterStrategy';
import { RecordInfo } from '../../type';
import { DataRow } from './type';

export const CONDITION_NAME = FilterCondition.IS_WARNING;

export function condition(
  dataRow: DataRow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any,
  dataInfos: Record<string, RecordInfo[]>
) {
  const row = dataRow.meta.visualRow;
  const recordInfo = dataInfos[row];
  if (recordInfo) {
    return recordInfo.some(
      (item) =>
        item.popover.level === 'warning' && item.colIndex === dataRow.meta.col
    );
  }
  return false;
}

export const meta = {
  name: 'Filters:conditions.isWarning',
  inputsCount: 1,
  showOperators: true,
};
