/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn } from 'final-form';
import { useMemo } from 'react';
import ObjectSchema from 'yup/lib/object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setInError = (errors: any, innerError: any) => {
  return setIn(errors, innerError.path, innerError.message);
};
const emptyObj = Object.create(null);

const makeValidate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: ObjectSchema<any>,
  options = { abortEarly: false }
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function validate(values: any) {
    try {
      schema.validateSync(values, options);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return err.inner.reduce(setInError, emptyObj);
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useValidationSchema(schema: ObjectSchema<any>) {
  const validate = useMemo(() => makeValidate(schema), [schema]);
  return validate;
}

export default useValidationSchema;
