import { MouseEvent } from 'react';
import { Button, CSSInterpolation, css, cx } from '@nuvo-importer/common';
import { ReactComponent as SmallPlus } from '../../../../../assets/icon/plus.svg';
import { useTranslation } from 'react-i18next';

type IAddColumnButtonProps = {
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  theme?: CSSInterpolation;
};

const AddColumnButton = ({
  className,
  onClick,
  disabled,
}: IAddColumnButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="secondary"
      className={cx(
        'py-10px !px-2 disabled:opacity-75',
        className,
        css`
          &:disabled:hover {
            background: white;
            border-color: #eff3f9;
          }
        `
      )}
      loadingStyle="!ml-0 !mr-0"
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      <span className="flex items-center">
        <SmallPlus className="mr-1" />
        <span>{t('txt_add_col')}</span>
      </span>
    </Button>
  );
};

export default AddColumnButton;
