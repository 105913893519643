import { UploadData } from '../../types';
import { DynamicStep } from './NuvoSession';

type ListenStartCallback = (params: {
  data?: UploadData;
  headerIndex?: number;
  step?: DynamicStep;
}) => void;

class NuvoSessionListener {
  public listenStartCallbackStack: Record<
    string,
    ListenStartCallback | undefined
  > = {};

  listener = (key: string, listenStartCallback?: ListenStartCallback) => {
    this.listenStartCallbackStack[key] = listenStartCallback;
  };
}

export default NuvoSessionListener;
