import { useMemo } from 'react';
import { FilterValueItems } from '../../../../columns/FilterStrategy';

const useViewModel = ({ valueItems }: { valueItems: FilterValueItems }) => {
  const itemCount = valueItems.length;
  const itemSize = 26;

  const height = useMemo(() => {
    return Math.min(itemCount * itemSize - 8, 96);
  }, [itemCount, itemSize]);

  return { height, itemCount, itemSize };
};

export default useViewModel;
