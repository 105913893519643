import React, { FC } from 'react';
import { FormElement } from '@getnuvo/ui-kit';
import { TextareaControl } from '../controls/TextareaControl';

export const Style: FC = () => {
  return (
    <div className="nuvo-sdk__form-block nuvo-sdk__codeArea">
      <FormElement label="Style">
        <TextareaControl rows={19} placeholder="{}" name="style" />
      </FormElement>
    </div>
  );
};
