import { useEffect } from 'react';
import { useTheme } from './theme';
import ThemeCssVariable from './ThemeCssVariable';

const ThemeCssVariableProvider = () => {
  const theme = useTheme();

  useEffect(() => {
    const themeCssVariable = new ThemeCssVariable(theme);

    themeCssVariable.setup();
  }, [theme]);

  return null;
};

export default ThemeCssVariableProvider;
