import { ReviewEntriesThemeAPI } from '../../../theme/themeAPI';
import { CSSInterpolation } from '../../emotion';
import {
  ChangeActionType,
  HookedRecordInfoLevel,
  HookedRecordResult,
} from '../../hooks/hooksAPI';

export enum OptionKey {
  LABEL = 'label',
  VALUE = 'value',
}

export type FindDropdownOptionFn = (
  columnIndex: number,
  value: string,
  optionValueType: OptionKey
) => string;

export type ConfigTheme = {
  reviewEntriesTheme?: ReviewEntriesThemeAPI;
  popover?: PopoverThemeProps;
  popoverInfo?: CSSInterpolation;
};

export type PopoverThemeProps = {
  root?: CSSInterpolation;
  info?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  warning?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  error?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
};

enum RECORD_INFO_SOURCE {
  CHANGE = 'CHANGE',
}

export enum CUSTOM_SOURCE_CHANGE {
  INITIAL_ROW = 'INITIAL_ROW',
  ADD_NEW_ROW_BY_BUTTON = 'ADD_NEW_ROW_BY_BUTTON',
  SINGLE_REPLACE = 'SINGLE_REPLACE',
  BULK_REPLACE = 'BULK_REPLACE',
  CLEANING_ASSISTANT = 'CLEANING_ASSISTANT',
}

export type RecordInfo = {
  rowIndex: number;
  colIndex: number;
  popover: {
    message: string;
    level: HookedRecordInfoLevel;
    source?: RECORD_INFO_SOURCE;
  };
};

export enum CELL_TYPE_CLASS {
  DEFAULT = 'default-cell',
}

export type FieldMeta = {
  level: HookedRecordInfoLevel;
  message: string;
};

export type UndoRedoEvent = void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CurrentRow = any[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChangeLog = Record<string, any>;

export type RowChange = {
  actionType: ChangeActionType;
  changeLog: ChangeLog;
  rowIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[];
};

export type EditRowChange = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[];
  changeLog: ChangeLog;
  actionType: ChangeActionType;
  rowIndex: number; // NOTE: physical row index
  sourceCols: string[];
};

export type DeleteRowChange = {
  changeLog: ChangeLog;
  rowIndex: number;
  actionType: 'delete';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[];
};

export type NewRowChange = {
  changeLog: ChangeLog;
  rowIndex: number;
  actionType: 'create';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[];
};

export type FinalChange = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentRowData: any[];
  changeLog?: ChangeLog;
  rowIndex: number; // NOTE: physical row index
  cleanFnChanges?: Record<string, HookedRecordResult>;
  sourceCols: string[];
};
