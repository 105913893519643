import { ListChildComponentProps } from 'react-window';
import Checkbox from '../../../components/Checkbox';
import { ValueItemData } from '../ValueList/type';
import useViewModel from './viewModel';
import { cx } from '../../../../../../emotion';

const ValueItem = ({
  style,
  data,
  index,
}: ListChildComponentProps<ValueItemData>) => {
  const { checked, onChecked, showValue } = useViewModel({
    index,
    data,
  });

  return (
    <div
      style={style}
      className={cx(
        'text-blue-dark-900 flex cursor-pointer items-center pl-2 pr-5 text-xs leading-4',
        index < data.itemCount - 1 ? 'pb-2' : '',
        'value-list-item'
      )}
      onClick={() => {
        onChecked(!checked);
      }}
    >
      <Checkbox className="!mr-2" checked={checked} />
      <p className="truncate">{showValue}</p>
    </div>
  );
};

export default ValueItem;
