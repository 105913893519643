import { cx } from 'core/emotion';
import { ReactComponent as ArrowIcon } from '../../../../../../../../assets/icon/arrow.svg';

const ArrowDown = ({ className }: { className?: string }) => {
  return (
    <div className={cx('absolute top-1/2 right-3 -translate-y-1/2 transform')}>
      <ArrowIcon className={cx('block', className)} aria-hidden="true" />
    </div>
  );
};

export default ArrowDown;
