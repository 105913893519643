import Logo from './index.view';
import DesktopNavigation from './Desktop';

const Navigation = () => {
  return (
    <nav className="bg-blue-dark-900 border-b border-gray-200">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <Logo />
            </div>
            <DesktopNavigation />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
