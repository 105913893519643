import { Validator } from '../../../reviewEntries/validator';
import { RecordInfo } from '../type';
import * as isInfoCondition from './condition/isInfo';
import * as isWarningCondition from './condition/isWarning';
import * as isErrorCondition from './condition/isError';
import * as byValueCondition from './condition/byValue';
import * as emptyCondition from './condition/empty';
import * as notEmptyCondition from './condition/notEmpty';
import * as betweenCondition from './condition/between';
import * as beforeDateCondition from './condition/date/before';
import * as afterDateCondition from './condition/date/after';
import * as todayDateCondition from './condition/date/today';
import * as yesterdayDateCondition from './condition/date/yesterday';
import * as tomorrowDateCondition from './condition/date/tomorrow';
import { DataModel } from '../../../dataModel/model/DataModel';
import { DataRow } from './condition/type';

// NOTE: reference from https://github.com/handsontable/handsontable/blob/develop/handsontable/src/plugins/filters/conditionRegisterer.js

class ConditionRegisterer {
  private dataInfos: Record<string, RecordInfo[]>;
  private validator: Validator;
  private dataModels: DataModel[];

  constructor(
    dataInfos: Record<string, RecordInfo[]>,
    validator: Validator,
    dataModels: DataModel[]
  ) {
    this.dataInfos = dataInfos;
    this.validator = validator;
    this.dataModels = dataModels;
  }

  setup = () => {
    this.registerCondition(
      isInfoCondition.CONDITION_NAME,
      isInfoCondition.condition,
      isInfoCondition.meta
    );
    this.registerCondition(
      isWarningCondition.CONDITION_NAME,
      isWarningCondition.condition,
      isWarningCondition.meta
    );
    this.registerCondition(
      isErrorCondition.CONDITION_NAME,
      isErrorCondition.condition,
      isErrorCondition.meta
    );
    this.registerCondition(
      byValueCondition.CONDITION_NAME,
      byValueCondition.condition,
      byValueCondition.meta
    );
    this.registerCondition(
      emptyCondition.CONDITION_NAME,
      emptyCondition.condition,
      emptyCondition.meta
    );
    this.registerCondition(
      notEmptyCondition.CONDITION_NAME,
      notEmptyCondition.condition,
      notEmptyCondition.meta
    );
    this.registerCondition(
      betweenCondition.CONDITION_NAME,
      betweenCondition.condition,
      betweenCondition.meta
    );
    this.registerCondition(
      beforeDateCondition.CONDITION_NAME,
      beforeDateCondition.condition,
      beforeDateCondition.meta
    );
    this.registerCondition(
      afterDateCondition.CONDITION_NAME,
      afterDateCondition.condition,
      afterDateCondition.meta
    );
    this.registerCondition(
      todayDateCondition.CONDITION_NAME,
      todayDateCondition.condition,
      todayDateCondition.meta
    );
    this.registerCondition(
      yesterdayDateCondition.CONDITION_NAME,
      yesterdayDateCondition.condition,
      yesterdayDateCondition.meta
    );
    this.registerCondition(
      tomorrowDateCondition.CONDITION_NAME,
      tomorrowDateCondition.condition,
      tomorrowDateCondition.meta
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCondition(name: string, args: any[]) {
    if (!this.conditions[name]) {
      throw Error(`Filter condition "${name}" does not exist.`);
    }
    const { condition, descriptor } = this.conditions[name];
    let conditionArguments = args;

    if (descriptor.inputValuesDecorator) {
      conditionArguments = descriptor.inputValuesDecorator(conditionArguments);
    }

    const dataInfos = this.dataInfos;
    const validator = this.validator;
    const dataModels = this.dataModels;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (dataRow: DataRow) {
      return condition.apply(
        dataRow.meta.instance,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any).concat(
          [dataRow],
          [conditionArguments],
          [dataInfos, validator, dataModels[dataRow.meta.col]]
        )
      );
    };
  }

  private conditions: Record<
    string,
    {
      condition: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataRow: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values: any[],
        dataInfos: Record<string, RecordInfo[]>,
        validator: Validator
      ) => boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      descriptor: any;
    }
  > = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private registerCondition(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor: any
  ) {
    descriptor.key = name;
    this.conditions[name] = {
      condition,
      descriptor,
    };
  }
}

export default ConditionRegisterer;
