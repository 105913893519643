import { css, cx } from 'core/emotion';
import { useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'theme';
import { StepperView } from '@nuvo-importer/common';
import useViewModel from './viewModel';
import Tracking from '../../tracking/Tracking';

type StepperProps = {
  className?: string;
  isLoading?: boolean;
};

const Stepper = ({ className, isLoading }: StepperProps) => {
  const {
    navigation,
    getStatus,
    currentStep,
    identifier,
    pathname,
    executing,
  } = useViewModel();
  const theme = useTheme();
  const prevPosition = useRef<number>(-1);
  const stepStart = useRef<string>('/');

  useEffect(() => {
    const isHomePath = ['/', '/upload-file'].includes(pathname);
    const isPrevStep =
      prevPosition.current !== -1 &&
      stepStart.current !== pathname &&
      !isHomePath;

    if (isPrevStep) {
      Tracking.getInstance().pushEventStack(identifier, {
        step: stepStart.current,
        action: 'end',
      });
    }

    if (
      prevPosition.current !== currentStep ||
      (currentStep === 0 && pathname === '/match-column' && !executing)
    ) {
      const currentStatus = getStatus(currentStep - 1);
      stepStart.current = navigation?.[currentStep]?.path;
      prevPosition.current = currentStep;
      if (
        currentStatus === 'previous' &&
        !isHomePath &&
        stepStart.current === pathname
      ) {
        Tracking.getInstance().pushEventStack(identifier, {
          step: stepStart.current,
          action: 'start',
        });
      }
    }
  }, [currentStep, identifier, navigation, pathname, executing, getStatus]);

  const stepperTheme = useMemo(() => {
    return {
      incomplete: theme.getProgressBarTheme().incomplete,
      current: theme.getProgressBarTheme().current,
      complete: theme.getProgressBarTheme().complete,
      icon: theme.getProgressBarTheme().icon,
    };
  }, [theme]);

  return (
    <div className={cx('w-fit mb-4 flex justify-start', className)}>
      <nav
        aria-label="Breadcrumb"
        className={cx(
          'h-full',
          css({ '&&': theme.getProgressBarTheme().root })
        )}
      >
        <ol className="flex h-full w-full min-w-0 flex-shrink items-center justify-start overflow-x-auto px-0">
          {navigation.map((step, index) => {
            return (
              <li key={step.path}>
                <div className="flex items-center">
                  <StepperView
                    isLoading={isLoading}
                    configTheme={stepperTheme}
                    label={step.label}
                    status={getStatus(index)}
                    rightIcon={step.rightIcon}
                  />
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Stepper;
