import Handsontable from 'handsontable';

export enum SortState {
  ASC = 'ASC',
  DESC = 'DESC',
}

class SortStrategy {
  static sortLoadingClassName = 'sort-loading';
  static sortLoaderClassName = 'sort-loader';
  private hotInstance?: Handsontable;
  private onSorting?: () => void;

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  registerOnSorting = (callback: () => void) => {
    this.onSorting = callback;
  };

  showLoading = (columnIndex: number) => {
    const hotInstance = this.hotInstance;
    if (!hotInstance) {
      return;
    }
    const headerElement = hotInstance.getCell(-1, columnIndex, true);
    if (headerElement) {
      headerElement.classList.add(SortStrategy.sortLoadingClassName);
    }
  };

  sortColumn = (columnIndex: number, sortState: SortState | null) => {
    const hotInstance = this.hotInstance;
    if (!hotInstance) {
      return;
    }

    const columnSorting = hotInstance.getPlugin('columnSorting');

    if (!columnSorting) {
      return;
    }

    if (sortState === null) {
      columnSorting.clearSort();
    } else {
      hotInstance.batchRender(() => {
        columnSorting.clearSort();
        columnSorting.sort({
          column: columnIndex,
          sortOrder: sortState === SortState.ASC ? 'asc' : 'desc',
        });
      });
    }

    this.onSorting?.();
  };
}

export default SortStrategy;
