import { createContext, ReactNode } from 'react';
import ThemeAPIMapper from '../theme/ThemeAPIMapper';

export const ThemeContext = createContext<ThemeAPIMapper>(
  new ThemeAPIMapper({})
);

type ConfigureThemeProviderProps = {
  theme: ThemeAPIMapper;
  children: ReactNode;
};

export const ConfigureThemeProvider = ({
  theme,
  children,
}: ConfigureThemeProviderProps) => {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};
