import { falsyWords, truthyWords } from '../../constants/boolean';
import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isNil, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';

class BooleanValueParser implements ValueParser {
  parse = (_dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return null;
    }

    if (isArray(value) || isObject(value)) {
      return null;
    }

    if (truthyWords().includes(value)) {
      return true;
    }

    if (falsyWords().includes(value)) {
      return false;
    }

    return value;
  };
}

export default BooleanValueParser;
