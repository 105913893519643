import { useTranslation } from 'react-i18next';
import { Warning, Wrapper, Text } from './index.view';

const NotMatchedText = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Warning />
      <Text className="relative -left-1">{t('txt_unable_match')}</Text>
    </Wrapper>
  );
};

export default NotMatchedText;
