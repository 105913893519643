import { ReactComponent as TickIcon } from '../../assets/icon/tick.svg';
import { ReactComponent as TickCircleIcon } from '../../assets/icon/tick-circle.svg';
import { useTheme } from '../../theme';
import { css, cx } from '../../core/emotion';

const Column = ({
  label,
  matched,
  isError,
}: {
  label: string;
  matched: boolean;
  isError?: boolean;
}) => {
  const theme = useTheme();

  return (
    <div className="mt-2 mr-5 flex items-center whitespace-nowrap">
      {matched ? (
        <TickIcon
          className={css({
            '&& path': theme.getRequiredColumnsTheme().hasMatchIcon,
          })}
        />
      ) : (
        <TickCircleIcon
          className={
            isError
              ? cx(
                  'text-red-510',
                  css({
                    '&& path':
                      theme.getRequiredColumnsTheme().notMatchErrorIcon,
                  })
                )
              : cx(
                  'text-gray-510',
                  css({
                    '&& path': theme.getRequiredColumnsTheme().notMatchIcon,
                  })
                )
          }
        />
      )}
      <span
        className={cx(
          'ml-2 text-sm',
          matched
            ? 'text-green-510'
            : `${isError ? 'text-red-510' : 'text-gray-510'}`,
          matched
            ? css({ '&&': theme.getRequiredColumnsTheme().hasMatch })
            : isError
            ? css({ '&&': theme.getRequiredColumnsTheme().notMatchError })
            : css({ '&&': theme.getRequiredColumnsTheme().notMatch })
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default Column;
