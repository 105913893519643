import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { GridSettings } from 'handsontable/settings';
import { compareFunctionFactory as defaultCompareFunctionFactory } from './default';
import {
  FIRST_AFTER_SECOND,
  FIRST_BEFORE_SECOND,
  DO_NOT_SWAP,
} from './constant';
import { BooleanSingleton } from '../../../../BooleanSingleton';

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: any, nextValue: any) => {
    const isValueBoolean =
      value === BooleanSingleton.trueValue ||
      value === BooleanSingleton.falseValue;
    const isNextValueBoolean =
      nextValue === BooleanSingleton.trueValue ||
      nextValue === BooleanSingleton.falseValue;
    const isValueTrue = value === BooleanSingleton.trueValue;
    const isNextValueTrue = nextValue === BooleanSingleton.trueValue;

    if (isValueBoolean && isNextValueBoolean) {
      if (isValueTrue && !isNextValueTrue) {
        return sortOrder === 'asc' ? FIRST_BEFORE_SECOND : FIRST_AFTER_SECOND;
      } else if (isNextValueTrue && !isValueTrue) {
        return sortOrder === 'asc' ? FIRST_AFTER_SECOND : FIRST_BEFORE_SECOND;
      } else {
        return DO_NOT_SWAP;
      }
    } else if (isValueBoolean && !isNextValueBoolean) {
      return FIRST_BEFORE_SECOND;
    } else if (!isValueBoolean && isNextValueBoolean) {
      return FIRST_AFTER_SECOND;
    }

    return defaultCompareFunctionFactory(sortOrder, columnMeta)(
      value,
      nextValue
    );
  };
}
