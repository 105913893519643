import { SheetColumn } from '@nuvo-importer/common/core';
import ColHeaderController from '../colHeaders/Controller';
import useViewModel from './viewModel';
import { css, cx, useTheme } from '@nuvo-importer/common';
import { useMemo } from 'react';

const TruncatePopover = ({
  colHeaderController,
  sheetColumns,
}: {
  colHeaderController: ColHeaderController;
  sheetColumns: SheetColumn[];
}) => {
  const { popper, isOpen, sheetColumn } = useViewModel({
    colHeaderController,
    sheetColumns,
  });
  const theme = useTheme();
  const infoClassName = css({ '&&': theme.getPopoverInfo() });

  const popoverStyle = useMemo(() => {
    return cx(
      css({
        maxWidth: 320,
        backgroundColor: theme.getGlobalTheme().getDark500Color(),
        borderColor: theme.getGlobalTheme().getDark500Color(),
      }),
      css`
        &[data-popper-reference-hidden='true'] {
          visibility: hidden;
          pointer-events: none;
        }
      `
    );
  }, [theme]);

  if (!isOpen || !sheetColumn) {
    return null;
  }

  return (
    <div
      className={cx(
        'z-50 break-words rounded py-2 px-3 text-left text-base text-white',
        popoverStyle,
        infoClassName
      )}
      style={popper.styles['popper']}
      ref={popper.setPopperElement}
      {...popper.attributes['popper']}
    >
      {sheetColumn.getColumnKey()}
    </div>
  );
};

export default TruncatePopover;
