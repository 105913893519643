import { createContext, ReactNode, useContext } from 'react';

const InRouterContext = createContext<{
  isUseInRouter: boolean;
}>({
  isUseInRouter: false,
});

export const useInRouterContext = () => {
  return useContext(InRouterContext);
};

export const InRouterContextProvider = ({
  isUseInRouter,
  children,
}: {
  isUseInRouter: boolean;
  children: ReactNode;
}) => {
  return (
    <InRouterContext.Provider
      value={{
        isUseInRouter,
      }}
    >
      {children}
    </InRouterContext.Provider>
  );
};
