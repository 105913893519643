import { RefObject, useMemo, useRef } from 'react';
import { usePopper as useBasePopper } from 'react-popper';

export type Popper = {
  referenceElement: React.RefObject<HTMLDivElement>;
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  popperElement: RefObject<HTMLDivElement>;
};

export const usePopper = ({
  flipEnabled = true,
}: {
  flipEnabled?: boolean;
}): Popper => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          tether: true,
          altBoundary: true,
        },
      },
      {
        name: 'flip',
        enabled: flipEnabled,
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ],
    [flipEnabled]
  );

  const { styles, attributes } = useBasePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: 'bottom-end',
      modifiers,
      strategy: 'fixed',
    }
  );

  return {
    referenceElement,
    styles,
    attributes,
    popperElement,
  };
};
