import { cx, css } from 'core/emotion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../types';
import { useTheme } from 'theme';

const Header = ({
  option,
  isRenderOther,
  isSearching,
  theme,
}: {
  option: Option;
  isRenderOther: boolean;
  isSearching: boolean;
  theme?: string;
}) => {
  const { t } = useTranslation();
  const globalTheme = useTheme();
  const useGlobalTheme = useMemo(() => {
    return {
      color: globalTheme.getGlobalTheme().getDark500Color(),
      backgroundColor: globalTheme.getGlobalTheme().getDark50Color(),
    };
  }, [globalTheme]);

  const memoStyled = useMemo(() => {
    return cx(
      'text-xs px-4 py-2 mt-0',
      css({
        color: useGlobalTheme.color,
        backgroundColor: useGlobalTheme.backgroundColor,
      }),
      theme
    );
  }, [theme, useGlobalTheme]);

  if (isSearching) {
    return null;
  }

  return (
    <>
      {option.isRecommend ? (
        <p className={memoStyled}>{t('txt_recommended_match')}</p>
      ) : null}
      {isRenderOther ? <p className={memoStyled}>{t('txt_others')}</p> : null}
    </>
  );
};

export default Header;
