import BaseNumberValueParser from './BaseNumberValueParser';
import { IValueParserOptions, PossibleValue } from './IValueParser';
import { NumberParser } from '../../utils/NumberParser';

class FloatNumberValueParser extends BaseNumberValueParser {
  valueParser = (value: PossibleValue, options?: IValueParserOptions) => {
    const converted = NumberParser.convertStringToNumber(value, {
      format: options?.numberFormat,
    });

    if (converted === null) {
      return `${value}`;
    }

    return Number(converted);
  };
}

export default FloatNumberValueParser;
