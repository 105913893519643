import { useCallback, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { isLineClamp, isTruncated } from '../../core/dom';

const useViewModel = ({ isCheckLineClamp }: { isCheckLineClamp: boolean }) => {
  const textRef = useRef<HTMLElement>(null);
  const [isTextExceed, setIsTextExceed] = useState(false);
  const [isShowPopover, setIsShowPopover] = useState(false);

  const handleTruncate = useCallback(() => {
    const target = textRef.current;

    if (isCheckLineClamp) {
      const isTextTruncated = isLineClamp(target);
      setIsTextExceed(isTextTruncated);
    } else {
      const isTextTruncated = isTruncated(target);
      setIsTextExceed(isTextTruncated);
    }
  }, [isCheckLineClamp]);

  const isShowFullTextPopover = isShowPopover && isTextExceed;

  const getHover = useCallback(() => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => {
          handleTruncate();
          setIsShowPopover(true);
        },
        onMouseLeave: () => setIsShowPopover(false),
      };
    } else {
      return {
        onClick: () => {
          handleTruncate();
          setIsShowPopover((isShow) => !isShow);
        },
        onMouseLeave: () => setIsShowPopover(false),
      };
    }
  }, [handleTruncate]);

  return {
    textRef,
    isTextTruncated: isTextExceed,
    isShowFullTextPopover,
    getHover,
  };
};

export default useViewModel;
