/* istanbul ignore file */

import { cleanSpecialCharAndToLowercase } from '../replaceRegex';
import { booleanWhitelist } from '../constants/boolean';
import { characterWhitelist } from '../constants/character';
import { isNaN, minBy, isString, isEqual, isEmpty, isNil } from 'lodash';
import { SHEET_COLUMN_TYPE, SHEET_TYPE } from '../constants/sheet';
import SheetColumn from './SheetColumn';
import SpreadSheet from './SpreadSheet';
import { TFunction } from 'i18next';
import { ResultValues } from '../value';

export type Value = string | number | boolean;

export type SheetData = Value[][];
export type SheetColumnData = Value[];
export type SheetJSON = {
  name: string;
  id: string;
  type: SHEET_TYPE;
  values: SheetData;
  headerRow: Value[];
};

let id = 0;

class Sheet {
  private name: string;
  private selected = false;
  private isManualSelectHeader = false;
  private id: string;
  private modifiedData: ResultValues;
  protected columns: SheetColumn[] = [];
  protected data: SheetData;
  protected headerRowIndex = 0;

  private spreadSheet: SpreadSheet = new SpreadSheet({
    filename: '',
    sheets: [],
    type: 'xlsx',
    fileSize: 0,
  });

  constructor({
    data,
    name,
    isNotSetColumn,
    id: idConstruct,
  }: {
    data: SheetData;
    name: string;
    isNotSetColumn?: boolean;
    id?: string;
  }) {
    this.data = data;
    this.name = name;
    if (typeof idConstruct === 'undefined') {
      id++;
      this.id = `${name}-${id}`;
    } else {
      this.id = idConstruct;
    }
    if (!isNotSetColumn) {
      this.setColumns();
    }

    this.modifiedData = this.getDataAsResults();
  }

  getId = () => {
    return this.id;
  };

  setSpreadSheet = (spreadSheet: SpreadSheet) => {
    this.spreadSheet = spreadSheet;
  };

  getSpreadSheet = () => {
    return this.spreadSheet;
  };

  setSelected = (selected: boolean) => {
    this.selected = selected;
  };

  isSelected = () => {
    return this.selected;
  };

  setHeaderRowIndex = (rowIndex: number) => {
    this.headerRowIndex = rowIndex;
    this.setColumns();
  };

  getDataTransform = () => {
    return this.data.map((item, index) => {
      return {
        data: item,
        index,
        length: item.filter((data) => data).length,
      };
    });
  };

  getMaxLengthColumns = () => {
    const dataTransform = this.getDataTransform();
    const maxLength = dataTransform.reduce((acc, item) => {
      return Math.max(
        acc,
        item.data?.filter(
          (value) =>
            !isEmpty(value) &&
            !characterWhitelist.some(
              (chr) =>
                chr === value.toString()?.trim() &&
                value.toString()?.startsWith(chr)
            )
        ).length
      );
    }, 0);
    return maxLength;
  };

  setHeaderByAutoDetection = () => {
    const maxLength = this.getMaxLengthColumns();
    const dataTransform = this.getDataTransform();

    const newDataTransform = [];

    for (
      let index = 0;
      index < (dataTransform.length > 50 ? 50 : dataTransform.length);
      index++
    ) {
      const item = dataTransform[index];
      const hasMaxLength = item.length === maxLength;
      const hasAllString =
        item.data.filter(
          (target) =>
            !booleanWhitelist.includes(
              isString(target) ? target.toLowerCase() : target
            ) && isNaN(Number(target))
        ).length === maxLength;

      if (hasAllString && hasMaxLength) {
        newDataTransform.push(item);
      }
    }

    const detectedHeaderRowIndex =
      newDataTransform.length === 1
        ? newDataTransform[0].index
        : minBy(newDataTransform, (item) => item.length)?.index ?? 0;

    this.setHeaderRowIndex(detectedHeaderRowIndex);
  };

  getHeaderRowIndex = () => {
    return this.headerRowIndex;
  };

  getHeaderRow = () => {
    return this.data[this.headerRowIndex]?.filter((header) => !!header);
  };

  getColumns = () => {
    return this.columns.filter((column) => !column.isRemoved());
  };

  getSortedColumns = () => {
    return [...this.getColumns()].sort((a, b) => {
      if (
        cleanSpecialCharAndToLowercase(a.getColumnKey()) <
        cleanSpecialCharAndToLowercase(b.getColumnKey())
      ) {
        return -1;
      }
      if (
        cleanSpecialCharAndToLowercase(a.getColumnKey()) >
        cleanSpecialCharAndToLowercase(b.getColumnKey())
      ) {
        return 1;
      }
      return 0;
    });
  };

  getColumnsWithRemoved = () => {
    return this.columns.filter(
      (column) => column.getType() !== SHEET_COLUMN_TYPE.JOIN
    );
  };

  getColumn = (key: Value, isAutoMapping?: boolean) => {
    return this.columns.find((column) => {
      if (isAutoMapping) {
        return (
          cleanSpecialCharAndToLowercase(column.getColumnKey()) ===
          cleanSpecialCharAndToLowercase(`${key}`)
        );
      }
      return column.getColumnKey() === key;
    });
  };

  getColumnsByKey = (key: Value, isAutoMapping?: boolean) => {
    return this.columns.filter((column) => {
      if (isAutoMapping) {
        return (
          cleanSpecialCharAndToLowercase(column.getColumnKey()) ===
          cleanSpecialCharAndToLowercase(`${key}`)
        );
      }
      return column.getColumnKey() === key;
    });
  };

  getValues = () => {
    const data = this.data.filter(
      (_, rowIndex) => rowIndex > this.headerRowIndex
    );

    if (isEmpty(data)) {
      return [[]];
    }

    const newItems: Value[][] = [];

    for (let i = 0; i < data.length; i++) {
      const subItems = [];
      for (let j = 0; j < data[i].length; j++) {
        if (!isNil(this.data[this.headerRowIndex][j])) {
          subItems.push(this.parseValue(data[i][j]));
        }
      }
      newItems.push(subItems);
    }

    return newItems;
  };

  getData = () => {
    return this.data;
  };

  getName = () => {
    return this.name;
  };

  getDisplayName = ({ t }: { t: TFunction }) => {
    if (this.getName()?.length > 0) {
      return this.getName();
    } else {
      switch (this.spreadSheet.getType()) {
        case 'json':
          return `${t('txt_default_sheet_prefix_name')} 1`;
        case 'csv':
          return `${t('txt_default_sheet_prefix_name')} 1`;
        default:
          return '';
      }
    }
  };

  getFullName = () => {
    if (this.name) {
      return `${this.name} - ${this.spreadSheet.getFilename()}`;
    } else {
      return `${this.spreadSheet.getFilename()}`;
    }
  };

  getSheetFileName = () => {
    return `${this.spreadSheet.getFilename()}`;
  };

  getIsManualSelectHeader = () => {
    return this.isManualSelectHeader;
  };

  setIsManualSelectHeader = () => {
    this.isManualSelectHeader = true;
  };

  clearIsManualSelectHeader = () => {
    this.isManualSelectHeader = false;
  };

  getDataAsResults = () => {
    const parsedData: ResultValues = [];
    const columns = this.getColumnsWithRemoved();
    const data = this.data.filter(
      (_, rowIndex) => rowIndex > this.headerRowIndex
    );

    for (let i = 0; i < data.length; i++) {
      const rowData = data[i];
      parsedData[i] = {};
      for (let j = 0; j < columns.length; j++) {
        const colData = rowData?.[j];
        if (!isEmpty(colData) && !isNil(colData)) {
          const columnKey = columns[j].getColumnKey();
          parsedData[i][columnKey] = this.parseValue(colData);
        }
      }
    }
    return parsedData;
  };

  getModifiedData() {
    return this.modifiedData;
  }

  mergedModifiedData(data: SheetData) {
    const newData: SheetData = [];
    const filteredColumns = this.getColumns();
    for (let i = 0; i < filteredColumns.length; i++) {
      const column = filteredColumns[i];
      const sheetColumnRows: Value[] = [];
      if (!column.isRemoved()) {
        for (let j = 0; j < data.length; j++) {
          if (!newData[j]) {
            newData[j] = [];
          }
          const columnData = data[j][i];
          newData[j][i] = columnData;
          sheetColumnRows[j] = columnData;
        }
        column.setRows(sheetColumnRows);
      }
    }
    this.setData(newData);
  }

  addColumn = (key: string) => {
    this.columns.push(
      new SheetColumn({
        columnKey: `${key}`,
        sheet: this,
        rows: this.getRowsByColumnIndex(this.getColumnIndex(key)),
      })
    );
  };

  removeColumn = (key: string) => {
    this.columns.find((entry) => entry.getColumnKey() === key)?.remove();
  };

  removeColumnByIndex = (index: number) => {
    this.columns.find((_, sheetIndex) => sheetIndex === index)?.remove();
  };

  setData = (data: SheetData) => {
    this.data = data;
  };

  toJSON = (): SheetJSON => {
    return {
      name: this.name,
      id: this.id,
      type: this.getType(),
      values: this.getValues(),
      headerRow: this.getHeaderRow(),
    };
  };

  equal = (sheet: Sheet) => {
    return this.id === sheet.getId();
  };

  getType = () => {
    return SHEET_TYPE.NORMAL;
  };

  protected getRowsByColumnIndex = (columnIndex: number) => {
    if (columnIndex === -1) {
      return [];
    }

    const rows: Value[] = [];

    for (let rowIndex = 0; rowIndex < this.data.length; rowIndex++) {
      const row = this.data[rowIndex];
      if (rowIndex > this.headerRowIndex) {
        const value = row[columnIndex] ?? '';
        rows.push(this.parseValue(value));
      }
    }

    return rows;
  };

  private setColumns = () => {
    const headerRows = this.data[this.headerRowIndex];

    if (headerRows) {
      const columns: SheetColumn[] = [];

      for (
        let headerIndex = 0;
        headerIndex < headerRows.length;
        ++headerIndex
      ) {
        const header = headerRows[headerIndex];
        if (header) {
          columns.push(
            new SheetColumn({
              columnKey: `${header}`,
              sheet: this,
              rows: this.getRowsByColumnIndex(headerIndex),
            })
          );
        }
      }

      this.columns = columns;
    } else {
      this.columns = [];
    }
  };

  private getColumnIndex = (column: Value) => {
    const headerRow = this.data[this.headerRowIndex];
    const columnIndex = headerRow.findIndex((headerColumn) =>
      isEqual(`${headerColumn}`, `${column}`)
    );

    return columnIndex;
  };

  private parseValue = (value: Value) => {
    if (typeof value === 'string') {
      return value.trim();
    } else {
      return value;
    }
  };
}

export default Sheet;
