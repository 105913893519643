import { Watermark } from '@nuvo-importer/common';
import { NUVO_ELEMENT_ID } from 'core/constants/element';
import { useCallback } from 'react';
import { render } from 'react-dom';

type IWaterMarkViewMode = 'upload' | 'footer';

export const useWatermark = () => {
  const renderWatermarkComponent = useCallback(
    (id: string, mode: IWaterMarkViewMode, identifier: string) => {
      return (
        <Watermark
          id={id}
          allowRender
          viewMode={mode}
          identifier={identifier}
        />
      );
    },
    []
  );

  const ensureWatermarkVisibility = useCallback(
    (
      {
        rootEleId,
        isVisible,
        viewMode,
        identifier,
        forceListener,
        forceTargetListener,
      },
      afterRerendering: () => void
    ) => {
      if (isVisible) return;
      const container = document.getElementById(`${rootEleId}-${identifier}`);
      if (!container) return;
      const prependWatermark = (mode: IWaterMarkViewMode) => {
        const viewModeRoot = mode === 'upload' ? 'upload-root' : 'footer-root';
        const rootTargetId = `${NUVO_ELEMENT_ID.WATERMARK}-${viewModeRoot}-${identifier}`;
        const div = document.createElement('div');
        const component = renderWatermarkComponent(
          rootTargetId,
          mode,
          identifier
        );
        if (!component) return;
        setTimeout(() => {
          document.getElementById(rootTargetId)?.remove();
          render(component, div);
          container?.append(div?.children?.[0]);
          forceListener('rootTargetEleId', identifier);
          forceTargetListener();
          afterRerendering();
        }, 0);
      };
      const delayAppend = () => setTimeout(() => prependWatermark(viewMode), 0);
      delayAppend();
    },
    [renderWatermarkComponent]
  );

  return {
    ensureWatermarkVisibility,
  };
};
