type UseViewModelProps = {
  openModal: () => void;
};

const useViewModel = ({ openModal }: UseViewModelProps) => {
  const onUploadSuccess = () => {
    openModal();
  };

  return {
    onUploadSuccess,
  };
};

export default useViewModel;
