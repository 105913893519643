import { Subject } from 'rxjs';
import ColumnsAPIMapper from '../../dataModel/ColumnsAPIMapper';
import {
  ColumnAPI,
  DropdownOptionAPI,
  DropdownOptionType,
} from '../../dataModel/columnsAPI';
import { DataModel } from '../../dataModel/model/DataModel';
import AllColumnSetting from './columns/AllColumnSetting';
import { generateKey } from '../../dataModel/utils';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import FreezeStrategyStore from './columns/FreezeStrategyStore';

type SubjectEvent =
  | {
      action: 'add_column';
      dataModel: DataModel;
      column: ColumnAPI;
    }
  | {
      action: 'edit_column';
      dataModel: DataModel;
      column: ColumnAPI;
      oldKey: string;
    }
  | {
      action: 'remove_column';
      key: string;
    }
  | {
      action: 'add_option';
      dataModel: CategoryDataModel;
    };

class DataModelRegistry {
  private dataModels: DataModel[] = [];
  private columns: ColumnAPI[] = [];
  private _dataModelObservable: Subject<SubjectEvent>;
  private allColumnSetting: AllColumnSetting;

  constructor({
    dataModels,
    columns,
    allColumnSetting,
  }: {
    dataModels: DataModel[];
    columns: ColumnAPI[];
    allColumnSetting: AllColumnSetting;
  }) {
    this.dataModels = [...dataModels];
    this.columns = [...columns];
    this.allColumnSetting = allColumnSetting;
    this._dataModelObservable = new Subject<SubjectEvent>();
  }

  dataModelObservable = () => {
    return this._dataModelObservable;
  };

  getDataModels = () => {
    return this.dataModels;
  };

  getDataModel = (key: string) => {
    return this.dataModels.find((dataModel) => dataModel.getKey() === key);
  };

  getColumns = () => {
    return this.columns;
  };

  findIndex = (key: string) => {
    return this.dataModels.findIndex((dataModel) => dataModel.getKey() === key);
  };

  addColumn = (column: ColumnAPI) => {
    const options = this.dataModels.map((dataModel) => {
      return {
        baseKey: dataModel.getBaseKey(),
        baseKeyCounter: dataModel.getBaseKeyCounter(),
      };
    });
    const { key } = generateKey(column.key, options);
    const updatedKeyValue = key.value.toLowerCase();
    const columnsAPIMapper = new ColumnsAPIMapper([column]);
    const dataModel = columnsAPIMapper.getDataModels()[0];
    dataModel.setCustomKey({
      key: updatedKeyValue,
      baseKey: key.baseKey,
      baseKeyCounter: key.baseKeyCounter,
      creator: 'manual',
    });
    this.dataModels = [...this.dataModels, dataModel];
    this.columns = [...this.columns, { ...column, key: updatedKeyValue }];
    this.allColumnSetting.addColumnSetting(updatedKeyValue);
    this._dataModelObservable.next({
      action: 'add_column',
      dataModel,
      column,
    });
  };

  deleteColumn = (key: string) => {
    const removeDataModel = this.dataModels.find(
      (dataModel) => dataModel.getKey() === key
    );
    if (!removeDataModel) {
      return;
    }

    this.dataModels = this.dataModels.filter((dataModel) => {
      return dataModel.getKey() !== key;
    });
    this.columns = this.columns.filter((column) => {
      return column.key !== key;
    });
    this.allColumnSetting.removeColumnSetting(key);
    this._dataModelObservable.next({
      action: 'remove_column',
      key,
    });
  };

  addOption = (
    dataModel: DataModel,
    newOption: string,
    newOptionType: DropdownOptionType
  ) => {
    if (!dataModel.isCategoryType()) {
      return;
    }
    const categoryModel = dataModel as CategoryDataModel;
    const cleanedValue = newOption.trim() ?? '';
    const options = categoryModel.getOptions();

    const option = generateKey(cleanedValue, options, false);
    const { key } = option;

    const updatedValue = key.value;

    const updateOptions = [
      ...options,
      {
        label: cleanedValue,
        value: updatedValue,
        type: newOptionType,
        alternativeMatches: [],
        creator: 'manual',
        baseKey: key.baseKey,
        baseKeyCounter: key.baseKeyCounter,
      },
    ];

    const columnIndex = this.findIndex(dataModel.getKey());
    if (columnIndex < 0) {
      return;
    }
    const cloneCategoryDataModel = categoryModel.clone();
    cloneCategoryDataModel.setOptions(updateOptions);
    this.dataModels[columnIndex] = cloneCategoryDataModel;
    if (this.columns[columnIndex]) {
      this.columns[columnIndex] = {
        ...this.columns[columnIndex],
        dropdownOptions: [
          ...(this.columns[columnIndex].dropdownOptions ?? []),
          {
            label: cleanedValue,
            value: updatedValue,
            type: newOptionType,
          } as DropdownOptionAPI,
        ],
      };
    }

    this._dataModelObservable.next({
      action: 'add_option',
      dataModel: cloneCategoryDataModel,
    });
  };

  editColumn = (dataModel: DataModel, columnName: string) => {
    const cleanedValue = columnName.trim() ?? '';
    const oldKey = dataModel.getKey();
    const columnIndex = this.findIndex(oldKey);
    if (columnIndex < 0) {
      return;
    }

    const options = this.dataModels
      .filter((item) => item.getKey() !== oldKey)
      .map((dataModel) => {
        return {
          baseKey: dataModel.getBaseKey(),
          baseKeyCounter: dataModel.getBaseKeyCounter(),
        };
      });
    const { key } = generateKey(cleanedValue, options);

    let cloneDataModel: DataModel;
    if (dataModel.isCategoryType()) {
      cloneDataModel = (dataModel as CategoryDataModel).clone();
    } else {
      cloneDataModel = dataModel.baseClone();
    }
    const updatedKeyValue = key.value.toLowerCase();
    cloneDataModel.setCustomKey({
      key: updatedKeyValue,
      baseKey: key.baseKey,
      baseKeyCounter: key.baseKeyCounter,
      creator: 'manual',
    });
    cloneDataModel.setLabel(cleanedValue);
    this.dataModels[columnIndex] = cloneDataModel;
    this.columns[columnIndex] = {
      ...this.columns[columnIndex],
      key: updatedKeyValue,
      label: cleanedValue,
    };

    FreezeStrategyStore.update(oldKey, updatedKeyValue);
    this.allColumnSetting.updateId(oldKey, updatedKeyValue);

    return { dataModel: cloneDataModel, column: this.columns[columnIndex] };
  };
}

export default DataModelRegistry;
