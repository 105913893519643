import ColumnDropdown, { IColumnDropdownProps } from '../ColumnDropdown';
import useViewModel from './viewModel';

export type IColumnDropdownFieldProps = Omit<
  IColumnDropdownProps,
  'onChange' | 'value'
> & {
  name: string;
  isFieldShowError?: boolean;
};

const ColumnDropdownField = ({
  name,
  isFieldShowError = false,
  ...props
}: IColumnDropdownFieldProps) => {
  const { input, isShowError } = useViewModel({ name, isFieldShowError });
  return <ColumnDropdown isShowError={isShowError} {...input} {...props} />;
};

export default ColumnDropdownField;
