import TruncatePopover from 'baseUI/TruncatePopover';
import { css, cx } from 'core/emotion';
import { useMemo, RefObject, useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
import useViewModel, { SheetTableProps } from '../viewModel';
import { useTheme } from 'theme';
import { Eye } from 'lucide-react';
import { useSheetPreview } from '../SheetPreview/useSheetPreview';
import { SheetPreview } from '../SheetPreview';

const SheetTableList = ({
  dataSheet,
  sheet,
  onSelected,
  selected,
  configTheme,
  index,
}: SheetTableProps) => {
  const { id, sheetName, filename } = dataSheet;
  const { isSelected } = useViewModel({ selected, id });
  const { t } = useTranslation();

  const theme = useTheme();
  const sheetPreviewTheme = theme.getSheetSelectTheme().sheetPreview;

  const listSelectSheetBgThemeColor = useMemo(() => {
    return theme.getGlobalTheme().getDark50Color();
  }, [theme]);

  const checkboxThemeColor = useMemo(() => {
    return theme.getGlobalTheme().getCheckboxThemeColor();
  }, [theme]);

  const { showPreview, openPreview, dismissPreview } = useSheetPreview();
  const handleOpenPreview = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      openPreview();
    },
    [openPreview]
  );

  return (
    <div
      key={id}
      className={cx(
        css`
          max-height: 48px;
        `,
        'border-b-1 group flex h-full w-full cursor-pointer items-center border-gray-50 p-4 ',
        isSelected
          ? css({ '&&': configTheme?.checked?.root })
          : css({ '&&': configTheme?.root }),
        isSelected
          ? css({
              backgroundColor: listSelectSheetBgThemeColor,
            })
          : 'hover:bg-gray-55 bg-white'
      )}
      onClick={() => {
        onSelected(id);
      }}
    >
      <Checkbox
        className={cx(
          '!rounded-medium mr-4',
          !isSelected
            ? css({
                '&&': {
                  borderColor: '#6B7280',
                },
              })
            : css({
                '&&:checked': {
                  borderColor: '#6B7280',
                },
              }),
          isSelected
            ? css({ '&&': { '&&:checked': configTheme?.checked?.checkbox } })
            : css({ '&&': { '&&': configTheme?.checkbox } }),
          isSelected
            ? css({
                '&&': {
                  '&&:checked': {
                    backgroundColor: checkboxThemeColor,
                    borderColor: checkboxThemeColor,
                  },
                },
              })
            : css``
        )}
        checked={isSelected}
        readOnly
      />
      <div
        className={cx(
          'items-centertext-color-primary flex w-full min-w-0 text-left text-sm font-normal',
          isSelected
            ? css({ '&&': configTheme?.checked?.title })
            : css({ '&&': configTheme?.title })
        )}
      >
        <div className="flex-grow">
          {sheetName || `${t('txt_default_sheet_prefix_name')} ${index + 1}`}
          <TruncatePopover
            render={({ referenceElement, textRef, getHover }) => {
              return (
                <div
                  ref={referenceElement as RefObject<HTMLDivElement>}
                  {...getHover()}
                >
                  <span
                    ref={textRef}
                    className={cx(
                      'text-color-secondary block min-w-0 max-w-full truncate text-xs',
                      isSelected
                        ? css({ '&&': configTheme?.checked?.description })
                        : css({ '&&': configTheme?.description })
                    )}
                  >
                    {filename}
                  </span>
                </div>
              );
            }}
          />
        </div>
        <button
          className={cx(
            'outline-none color-primary text-xsmall text-blue-light-500 nuvo-invisible relative z-20 flex items-center gap-2 border-0 font-bold group-hover:visible',
            css({ '&&': sheetPreviewTheme.listButton })
          )}
          onClick={handleOpenPreview}
        >
          <Eye size={16} />
          {t('txt_preview')}
        </button>

        {showPreview && (
          <SheetPreview
            sheet={sheet}
            isOpen={showPreview}
            onCloseModal={dismissPreview}
            sheetName={
              sheetName || `${t('txt_default_sheet_prefix_name')} ${index + 1}`
            }
            filename={filename}
            theme={theme.getSheetSelectTheme().sheetPreview}
          />
        )}
      </div>
    </div>
  );
};

export default SheetTableList;
