import { HotTableClass } from '@handsontable/react';
import { MutableRefObject, RefObject, useEffect } from 'react';
import { SheetData } from '@nuvo-importer/common/sdk';

type IBorderStyleProps = {
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
  instance: RefObject<HotTableClass>;
  scrollContainerRef: MutableRefObject<HTMLDivElement | undefined>;
  colAmount: number;
  widthColumn: number;
  dataSet: SheetData;
};

const useBorderStyle = ({
  containerRef,
  instance,
  scrollContainerRef,
  colAmount,
  widthColumn,
  dataSet,
}: IBorderStyleProps) => {
  useEffect(() => {
    const container = (containerRef.current = instance.current?.hotInstance
      ?.rootElement as HTMLDivElement);

    const scrollContainer = (scrollContainerRef.current =
      container.querySelector('.wtHolder') as HTMLDivElement);

    handleBorderLastRow();
    handleSpaceFirstColumn();
    handleSpaceLastColumn();

    function handleBorderLastRow() {
      const hasScrollVertical =
        scrollContainer.scrollHeight > scrollContainer.clientHeight;
      const hasScrollVerticalReachEnd =
        hasScrollVertical &&
        scrollContainer.offsetHeight + scrollContainer.scrollTop >=
          scrollContainer.scrollHeight;

      if (hasScrollVerticalReachEnd) {
        container.classList.add('hide-border-last-row');
      } else {
        container.classList.remove('hide-border-last-row');
      }
    }

    function handleSpaceFirstColumn() {
      setTimeout(() => {
        const hasScrollHorizontal =
          scrollContainer.scrollWidth > scrollContainer.clientWidth;

        if (hasScrollHorizontal) {
          container.classList.add('add-space-scrollbar-h');
          container.classList.add('hide-border-last-column');
        } else {
          container.classList.remove('add-space-scrollbar-h');
          container.classList.remove('hide-border-last-column');
        }

        const allColumnsWidth = colAmount * widthColumn;
        if (allColumnsWidth > container.clientWidth) {
          container.classList.remove('wt-full-width');
        } else {
          container.classList.add('wt-full-width');
        }
      });
    }

    const allColumnsWidth = colAmount * widthColumn;
    if (allColumnsWidth > container.clientWidth) {
      container.classList.remove('wt-full-width');
    } else {
      container.classList.add('wt-full-width');
    }

    function handleSpaceLastColumn() {
      const hasScrollVertical =
        scrollContainer.scrollHeight > scrollContainer.clientHeight;

      if (hasScrollVertical) {
        requestAnimationFrame(() => {
          container.classList.add('add-space-scrollbar-w');
        });
      } else {
        requestAnimationFrame(() => {
          container.classList.remove('add-space-scrollbar-w');
        });
      }
    }

    const hasScrollVertical =
      scrollContainer.scrollHeight > scrollContainer.clientHeight;

    if (hasScrollVertical) {
      requestAnimationFrame(() => {
        container.classList.add('add-space-scrollbar-w');
      });
    } else {
      requestAnimationFrame(() => {
        container.classList.remove('add-space-scrollbar-w');
      });
    }

    scrollContainer.addEventListener('scroll', handleBorderLastRow, {
      passive: true,
    });
    window.addEventListener('resize', handleBorderLastRow, {
      passive: true,
    });
    window.addEventListener('resize', handleSpaceFirstColumn, {
      passive: true,
    });
    window.addEventListener('resize', handleSpaceLastColumn, {
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', handleBorderLastRow);
      window.removeEventListener('resize', handleSpaceFirstColumn);
      window.removeEventListener('resize', handleSpaceLastColumn);
      scrollContainer.removeEventListener('scroll', handleBorderLastRow);
    };
  }, [
    dataSet,
    colAmount,
    scrollContainerRef,
    containerRef,
    instance,
    widthColumn,
  ]);
};

export default useBorderStyle;
