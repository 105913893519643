import { SheetColumn } from '@nuvo-importer/common/sdk';
import ColHeaderController from '../colHeaders/Controller';
import useViewModel from './viewModel';
import { useMemo } from 'react';
import { css } from '@emotion/css';
import { cx, useTheme } from '@nuvo-importer/common';
import { useTranslation } from 'react-i18next';

type JoinPopoverProps = {
  colHeaderController: ColHeaderController;
  sheetColumns: SheetColumn[];
};

const JoinPopover = ({
  colHeaderController,
  sheetColumns,
}: JoinPopoverProps) => {
  const { t } = useTranslation();
  const { popper, isOpen, joinSheetColumnPopover } = useViewModel({
    colHeaderController,
    sheetColumns,
  });
  const theme = useTheme();

  const popoverStyle = useMemo(() => {
    return css`
      &[data-popper-reference-hidden='true'] {
        visibility: hidden;
        pointer-events: none;
      }
    `;
  }, []);

  if (!isOpen) {
    return null;
  }

  const joinedSheetNames = joinSheetColumnPopover
    ? joinSheetColumnPopover.getJoinedSheetColumns().map((sheetColumn) => {
        return `[${sheetColumn.getSheet().getName()}]`;
      })
    : [];

  return (
    <div
      className={cx(
        'bg-blue-light-400 rounded-medium z-170 max-w-204 break-words px-4 py-3 text-center text-xs text-white',
        popoverStyle,
        css({
          '&&': theme.getJoinSheetTheme().joinPopover,
        })
      )}
      style={popper.styles['popper']}
      ref={popper.setPopperElement}
      {...popper.attributes['popper']}
    >
      {t('txt_used_to_join')}{' '}
      {joinedSheetNames ? joinedSheetNames.join(' ') : null}
    </div>
  );
};

export default JoinPopover;
