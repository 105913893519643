import { useCallback } from 'react';
import urlJoin from 'url-join';
import { useNavigate } from 'react-router-dom';
import { useInRouterContext } from '../main/InRouterProvider';
import { REVIEW_ENTRIES_PATH } from './constants/route';
import { useSettings } from 'settings';
import { useDataModels } from 'dataModel';

const useInAppNavigate = () => {
  const navigate = useNavigate();
  const { isUseInRouter } = useInRouterContext();

  const inAppNavigate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (to: { pathname: string } | string, options?: { state?: any }) => {
      if (isUseInRouter) {
        const basePathName = window.location.pathname;
        const baseHash = `${window.location.hash}`.replace('#', '');

        const isBaseOnHash =
          (window.location.pathname === '/' ||
            window.location.pathname === '') &&
          window.location.hash;

        const toPathName = typeof to === 'string' ? to : to.pathname;

        const pathName = isBaseOnHash
          ? urlJoin(
              baseHash,
              basePathName === '/' ? '' : basePathName,
              toPathName
            )
          : urlJoin(basePathName, toPathName);

        return navigate(
          {
            pathname: pathName,
          },
          { ...options, replace: true }
        );
      } else {
        const pathName = typeof to === 'string' ? to : to.pathname;

        return navigate(
          {
            pathname: pathName,
          },
          { ...options, replace: true }
        );
      }
    },
    [navigate, isUseInRouter]
  );

  return inAppNavigate;
};

export const useManualNavigate = () => {
  const navigate = useInAppNavigate();
  const { columns } = useSettings();
  const dataModel = useDataModels();

  const navigateToReviewEntries = useCallback(() => {
    navigate(
      {
        pathname: REVIEW_ENTRIES_PATH,
      },
      {
        state: {
          hideStepper: true,
          columns,
          dataModels: dataModel.getDataModels(),
        },
      }
    );
  }, [columns, dataModel, navigate]);

  return { navigateToReviewEntries };
};

export default useInAppNavigate;
