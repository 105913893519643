export enum COLOR {
  ERROR = '#ED7070',
  WARNING = '#FFC116',
  INFO = '#5C8EF2',
  DARK_BLUE = '#284067',
  DARK_LIGHT = '#7BA3F4',
  DEFAULT = '#162338',
}

export enum COLOR_HOVER {
  ERROR = '#FAD4D4',
  WARNING = '#FFE8A8',
  INFO = '#C2D5FA',
  DEFAULT = '#F5F5F5',
}

export enum COLOR_SCROLL_BAR {
  BACKGROUND = '#f4f4f4',
  NAVIGATOR = '#b9b9b9',
}

export enum COLOR_TABLE {
  HOVER = '#F4F5F6',
  BORDER_COLOR = '#DDDDDD',
  REMOVE = '#FBFBFB',
  READ_ONLY = '#FFFFFF',
}
