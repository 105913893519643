import { RefObject, useCallback } from 'react';
import {
  OnEntryChange,
  OnEntryChangeResult,
  Row,
} from '../../../hooks/hooksAPI';
import { DataModel } from '../../../dataModel/model/DataModel';
import ValueBeforeHookParser from '../../valueResultParser/ValueBeforeHookParser';
import DataModelRegistry from './../DataModelRegistry';
import AllColumnSetting from './../columns/AllColumnSetting';
import { Validator } from '../../../reviewEntries/validator';
import {
  DeleteRowChange,
  EditRowChange,
  NewRowChange,
  RowChange,
} from '../type';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import isPromise from 'is-promise';
import { HotTableClass } from '@handsontable/react';
import useHandleChange from './handleChange';
import {
  getFinalChangesByChangeFnResult,
  getFinalChangesWithEditRows,
} from './finalChange';
import { HandleChangeInfoFn } from '../../../reviewEntries/type';

type IEntryChange = {
  dataModelRegistry: DataModelRegistry;
  updateTotalError: () => void;
  allColumnSetting: AllColumnSetting;
  handleChangeInfo: HandleChangeInfoFn;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  dataSet: Record<string, any>[];
  validator: Validator;
  onEntryChange?: OnEntryChange;
  hotInstance?: RefObject<HotTableClass>;
};

const getRowsParamEntryChange = (
  rowChanges: RowChange[],
  columns: ColumnAPI[],
  dataModels: DataModel[]
) => {
  const rows = [];
  for (let j = 0; j < rowChanges.length; ++j) {
    const rowChange = rowChanges[j];
    const rowDataObj: Row = {};

    for (let i = 0; i < rowChange.currentRowData.length; ++i) {
      const key = columns[i].key;
      const dataModel = dataModels[i];
      rowDataObj[key] = dataModel
        ? ValueBeforeHookParser.parse(dataModel, rowChange.currentRowData[i])
        : rowChange.currentRowData[i];
    }

    rows.push({
      actionType: rowChange.actionType,
      changeLog: rowChange.actionType === 'create' ? null : rowChange.changeLog,
      data: rowDataObj,
      rowIndex: rowChange.rowIndex,
    });
  }

  return rows;
};

const useEntryChange = ({
  dataModelRegistry,
  updateTotalError,
  allColumnSetting,
  handleChangeInfo,
  dataSet,
  validator,
  onEntryChange,
  hotInstance,
}: IEntryChange) => {
  const { handleChanges } = useHandleChange({
    dataModelRegistry,
    updateTotalError,
    allColumnSetting,
    handleChangeInfo,
    dataSet,
    validator,
    hotInstance,
  });

  const getCurrentRowData = useCallback(
    (rowIndex: number, columns: ColumnAPI[]) => {
      const rowData: any[] = [];
      const sourceRowData = dataSet[rowIndex];
      columns.forEach((column, i) => {
        rowData[i] = sourceRowData?.[column.key];
      });

      return rowData;
    },
    [dataSet]
  );

  const handleOnEntryChanges = useCallback(
    (
      editRows: EditRowChange[],
      changeFnResult: OnEntryChangeResult | undefined,
      dataSetLength: number
    ) => {
      const dataModels = dataModelRegistry.getDataModels();
      const columns = dataModelRegistry.getColumns();

      if (changeFnResult) {
        if (isPromise(changeFnResult)) {
          return changeFnResult
            .then((changeFnResultValues) => {
              if (changeFnResultValues) {
                const finalChanges = getFinalChangesByChangeFnResult(
                  changeFnResultValues,
                  editRows,
                  dataModels,
                  columns,
                  dataSetLength,
                  getCurrentRowData
                );
                handleChanges(finalChanges);
              } else {
                const finalChanges = getFinalChangesWithEditRows(editRows);
                handleChanges(finalChanges);
              }

              return;
            })
            .catch(() => {});
        } else {
          const finalChanges = getFinalChangesByChangeFnResult(
            changeFnResult,
            editRows,
            dataModels,
            columns,
            dataSetLength,
            getCurrentRowData
          );
          handleChanges(finalChanges);
          return;
        }
      } else {
        const finalChanges = getFinalChangesWithEditRows(editRows);
        handleChanges(finalChanges);
        return;
      }
    },
    [handleChanges, dataModelRegistry, getCurrentRowData]
  );

  const handleAfterChange = useCallback(
    (
      editRows: EditRowChange[],
      isChangesInPhysical: boolean,
      isCreateNewSpareRow: boolean
    ) => {
      let changeFnResult: OnEntryChangeResult | undefined;
      const dataSetLength = isCreateNewSpareRow
        ? dataSet.length + 1
        : dataSet.length;
      if (onEntryChange) {
        const dataModels = dataModelRegistry.getDataModels();
        const columns = dataModelRegistry.getColumns();
        const rowsParams = getRowsParamEntryChange(
          editRows,
          columns,
          dataModels
        );
        changeFnResult = onEntryChange(rowsParams);
      }
      return handleOnEntryChanges(editRows, changeFnResult, dataSetLength);
    },
    [dataModelRegistry, handleOnEntryChanges, dataSet, onEntryChange]
  );

  const handleAfterRemove = useCallback(
    (deleteRows: DeleteRowChange[]) => {
      if (onEntryChange) {
        const dataModels = dataModelRegistry.getDataModels();
        const columns = dataModelRegistry.getColumns();
        const rowsParams = getRowsParamEntryChange(
          deleteRows,
          columns,
          dataModels
        );

        const changeFnResult = onEntryChange(rowsParams);
        return handleOnEntryChanges([], changeFnResult, dataSet.length);
      }

      return;
    },
    [dataModelRegistry, handleOnEntryChanges, dataSet, onEntryChange]
  );

  const handleAfterNewRow = (newRows: NewRowChange[]) => {
    if (onEntryChange) {
      const dataModels = dataModelRegistry.getDataModels();
      const columns = dataModelRegistry.getColumns();
      const rowsParams = getRowsParamEntryChange(newRows, columns, dataModels);

      const changeFnResult = onEntryChange(rowsParams);
      return handleOnEntryChanges([], changeFnResult, dataSet.length);
    }

    return;
  };

  return {
    handleAfterChange,
    handleAfterRemove,
    handleAfterNewRow,
  };
};

export default useEntryChange;
