import { ReactComponent as InformationCircleIcon } from './../../assets/icon/info-icon-blue.svg';
import { ReactComponent as CloseIcon } from '../../assets/icon/close-gray.svg';
import { css, cx } from '../../core/emotion';
import { useTheme } from '../../theme';

export default function InfoBox(prop: {
  title: string;
  description: string;
  onClickClose: () => void;
  customStyle?: string;
}) {
  const theme = useTheme();

  return (
    <div className={prop?.customStyle}>
      <div
        className={cx(
          'flex w-full rounded-lg border-l-4 py-3 px-4',
          css({
            borderColor: theme.getGlobalTheme().getPrimaryColor(),
            backgroundColor: theme.getGlobalTheme().getLight40AlphaColor(),
          })
        )}
      >
        <div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className={css({
                  color: theme.getGlobalTheme().getPrimaryColor(),
                })}
              />
            </div>
            <p className="text-xsmall px-1 text-gray-700">{prop.title}</p>
          </div>
          <div className="text-xsmall text-gray-560 mt-1 ml-1 text-left">
            {prop.description}
          </div>
        </div>
        <div className=" p-1">
          <CloseIcon
            className="text-gray-410 cursor-pointer"
            onClick={() => {
              prop.onClickClose();
            }}
          />
        </div>
      </div>
    </div>
  );
}
