import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';

class StringValueParser implements ValueParser {
  parse = (_dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (isArray(value) || isObject(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    return `${value}`;
  };
}

export default StringValueParser;
