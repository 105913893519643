import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      gap: 8px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.cleaningGroupList?.root),
    }),
  ]);

  return {
    rootClass,
  };
};
