import { DataRow } from './type';
import { RecordInfo } from '../../type';
import { Validator } from '../../../../reviewEntries/validator';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { condition as dateAfterCondition } from './date/after';
import { condition as dateBeforeCondition } from './date/before';
import { FilterCondition } from '../../columns/FilterStrategy';
import { DATATYPE } from '../../../../dataType';

export const CONDITION_NAME = FilterCondition.BETWEEN;

export function condition(
  dataRow: DataRow,
  [from, to]: [from: string, to: string],
  dataInfos: Record<string, RecordInfo[]>,
  validator: Validator,
  dataModel: DataModel
) {
  let fromValue: string | number = from;
  let toValue: string | number = to;

  if (dataRow.meta.type === 'numeric') {
    const _from = parseFloat(fromValue);
    const _to = parseFloat(toValue);

    fromValue = Math.min(_from, _to);
    toValue = Math.max(_from, _to);
  } else if (dataModel.getType() === DATATYPE.DATE) {
    const dateBefore = dateAfterCondition(
      dataRow,
      [toValue],
      dataInfos,
      validator,
      dataModel
    );
    const dateAfter = dateBeforeCondition(
      dataRow,
      [fromValue],
      dataInfos,
      validator,
      dataModel
    );

    return dateBefore && dateAfter;
  }

  return dataRow.value >= fromValue && dataRow.value <= toValue;
}

export const meta = {
  name: 'Filters:conditions.between',
  inputsCount: 2,
  showOperators: true,
};
