import { forwardRef, RefObject } from 'react';
import TruncatePopover from 'baseUI/TruncatePopover';
import { css, cx } from 'core/emotion';
import { Variant } from '../types';
import { useStyle } from './style';
import ArrowDown from '../ArrowDown';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icon/close-gray.svg';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';

const maxWidthStyle = css`
  max-width: 206px;
`;

const MenuButton = forwardRef<
  HTMLButtonElement,
  {
    variant: Variant;
    open: boolean;
    isEmpty: boolean;
    shownValue: string;
    onClear: () => void;
    configThemeDropdownButton?: {
      root?: string;
      icon?: string;
      placeholder?: string;
    };
  }
>(
  (
    {
      variant,
      open,
      isEmpty,
      shownValue,
      onClear,
      configThemeDropdownButton,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { menuButtonClassname } = useStyle({
      open,
      variant,
    });
    const { allowCustomColumns } = useSettings();

    return (
      <button
        type="button"
        ref={ref}
        className={cx(
          menuButtonClassname,
          'select-button',
          configThemeDropdownButton?.root
        )}
        {...props}
      >
        {!isEmpty ? (
          <TruncatePopover
            detectChangeText={shownValue}
            render={({ referenceElement, textRef, getHover }) => {
              return (
                <div
                  className={cx('flex w-full items-center justify-between')}
                  ref={referenceElement as RefObject<HTMLDivElement>}
                  {...getHover()}
                >
                  <span ref={textRef} className={cx('truncate', maxWidthStyle)}>
                    {shownValue}
                  </span>
                  <CloseIcon
                    className={cx(
                      'flex-shrink-0',
                      configThemeDropdownButton?.icon
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClear();
                    }}
                  />
                </div>
              );
            }}
          />
        ) : (
          <span
            className={cx(
              'text-color-secondary max-w-full truncate',
              configThemeDropdownButton?.placeholder
            )}
          >
            {`${
              allowCustomColumns ? t('txt_select_or_add') : t('txt_select')
            }...`}
          </span>
        )}
        <ArrowDown open={open} variant={variant} />
      </button>
    );
  }
);

export default MenuButton;
