/* istanbul ignore file */

import { IResultOptionsMapping } from '../../modules/nuvo.mapping';

export enum VALIDATE_TYPE {
  REGEX = 'regex',
  UNIQUE = 'unique',
  REQUIRED = 'required',
  REQUIRED_WITH = 'required_with',
  REQUIRED_WITHOUT = 'required_without',
  REQUIRED_WITH_ALL = 'required_with_all',
  REQUIRED_WITHOUT_ALL = 'required_without_all',
  REQUIRED_WITH_VALUES = 'required_with_values',
  REQUIRED_WITHOUT_VALUES = 'required_without_values',
  REQUIRED_WITH_ALL_VALUES = 'required_with_all_values',
  REQUIRED_WITHOUT_ALL_VALUES = 'required_without_all_values',
}

export enum ColumnType {
  BOOLEAN = 'boolean',
  CATEGORY = 'category',
  NUMBER = 'number',
  STRING = 'string',
}

export declare type InputData = Record<
  string,
  string | boolean | number | undefined
>[];

export interface ExecuteOptions {
  reuseMappingData: boolean;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface ColumnValue {
  key: string;
  value?: string[] | null;
}

export interface Validator {
  validate: string;
  columnValues?: ColumnValue[] | null;
  regex?: string | null;
}

export interface ColumnConfig {
  key: string;
  columnType: string;
  label: string;
  description: string;
  validators: Validator[];
  isMultiple?: boolean;
  isMultiSelect?: boolean;
  dropdownOptions?: DropdownOption[] | null;
  optionMappingMode?: 'smart' | 'exact';
}

export interface MapObject<T> {
  [key: string]: T;
}

export interface SuggestionMatching {
  key: string;
  value: number;
  isSameWord?: boolean;
}

export interface FullResultMatching {
  label: string;
  suggestions: SuggestionMatching[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  choice: MapObject<any>;
}

export interface License {
  licenseKey: string;
  framework: string;
}

export type CalculateSimilarityResult = {
  result: FullResultMatching[];
};

export type ExecuteFn = (
  columns: ColumnConfig[],
  inputJson: InputData,
  license: License
) => Promise<IResultOptionsMapping>;
