import { useEffect, useMemo, useState } from 'react';
import { DropdownOptionType } from '../../../dataModel/columnsAPI';
import {
  CategoryDataModel,
  SpreadSheetNavigate,
} from '@nuvo-importer/common/sdk';
import { useContextCreateNewOptionModal } from '../CreateNewOptionModal/CreateNewOptionModalContext';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';
import { useLocation } from 'react-router-dom';
import { generateKey } from '@nuvo-importer/common/core';

export const createNewOptionModalId = 'nuvo-create-new-option-modal';

export type FormValues = {
  optionName: string;
  optionType: DropdownOptionType;
};

type UseViewModelProps = {
  isOpen: boolean;
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const useViewModel = ({
  isOpen,
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: UseViewModelProps) => {
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const { dataModel, onSubmitCallback, sheetColumnOption } =
    useContextCreateNewOptionModal();
  const { state: locationState } = useLocation();

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
    dataModelSheetMatching?: DataModelSheetMatching;
    dataModelSheetMatcher?: DataModelSheetMatcher;
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        setModalElement(
          (document.getElementById(createNewOptionModalId) as HTMLDivElement) ??
            null
        );
      } else {
        setModalElement(null);
      }
    }, 0);
  }, [isOpen]);

  const onSubmit = (values: FormValues) => {
    if (!dataModel) {
      return;
    }
    const cleanedSearchValue = values.optionName?.trim() ?? '';
    const options = (dataModel as CategoryDataModel).getOptions();

    const option = generateKey(cleanedSearchValue, options, false);
    const { key } = option;
    const rawOptions = dataModel.getOptions?.();
    const updatedValue = key.value;

    const updateOptions = [
      ...rawOptions,
      {
        label: cleanedSearchValue,
        value: updatedValue,
        type: values.optionType,
        alternativeMatches: [],
        creator: 'manual',
        baseKey: key.baseKey,
        baseKeyCounter: key.baseKeyCounter,
      },
    ];

    const oldDataModels = [
      ...dataModelSheetMatcher.getMatching().getDataModels(),
    ];
    const spreadSheetList = state.spreadSheetNavigate?.getSpreadSheetList();

    let updateCategoryDataModel: CategoryDataModel | null = null;

    for (let index = 0; index < oldDataModels.length; index++) {
      const entry = oldDataModels[index];
      if (entry.getKey() === dataModel.getKey()) {
        const cloneCategoryDataModel = dataModel.clone();
        cloneCategoryDataModel.setOptions(updateOptions);
        oldDataModels[index] = cloneCategoryDataModel;
        updateCategoryDataModel = cloneCategoryDataModel;
        break;
      }
    }

    const dataModels = [...oldDataModels];
    const sheetColumnDataModelSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelSimilarityList();
    const sheetColumnDataModelOptionSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelOptionSimilarityList();
    const calculateSimilarityResult =
      dataModelSheetMatcher?.getCalculateSimilarityResult();

    if (
      dataModels &&
      spreadSheetList &&
      sheetColumnDataModelSimilarityList &&
      sheetColumnDataModelOptionSimilarityList &&
      calculateSimilarityResult
    ) {
      const newDataModelSheetMatcher = new DataModelSheetMatcher({
        sheetColumnDataModelSimilarityList,
        sheetColumnDataModelOptionSimilarityList,
        dataModels,
        sheets: spreadSheetList.getSelectedSheets(),
        calculateSimilarityResult,
      });
      setDataModelSheetMatcher(newDataModelSheetMatcher);
      setDataModelSheetMatching(newDataModelSheetMatcher.getMatching());
    }

    onSubmitCallback.callback?.(updateCategoryDataModel, updatedValue);
  };

  const initialValues = useMemo(() => {
    return {
      optionType: 'string' as const,
      optionName: `${sheetColumnOption ?? ''}`,
    };
  }, [sheetColumnOption]);

  return {
    onSubmit,
    modalElement,
    initialValues,
  };
};

export default useViewModel;
