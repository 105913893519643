import { Validator } from '../../../../reviewEntries/validator';
import { FilterCondition } from '../../columns/FilterStrategy';
import { RecordInfo } from '../../type';
import { DataRow } from './type';

export const CONDITION_NAME = FilterCondition.IS_ERROR;

export function condition(
  dataRow: DataRow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any,
  dataInfos: Record<string, RecordInfo[]>,
  validator: Validator
) {
  const row = dataRow.meta.visualRow;
  const hasErrorCol = validator.getError()[row]?.[dataRow.meta.col];
  if (hasErrorCol) {
    return hasErrorCol;
  }
  const recordInfo = dataInfos[row];
  const hasErrorInfo = recordInfo?.some(
    (item) =>
      item.popover.level === 'error' && item.colIndex === dataRow.meta.col
  );
  return hasErrorInfo ?? false;
}

export const meta = {
  name: 'Filters:conditions.isError',
  inputsCount: 1,
  showOperators: true,
};
