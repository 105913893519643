import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.emptyState?.root),
    }),
  ]);

  const iconClass = cx([
    'text-gray-260',
    css(cleaningAssistantTheme.emptyState?.icon),
  ]);

  const titleClass = cx([
    'text-xsmall text-neutral-gray',
    css`
      margin-block-start: 16px;
      text-align: center;
    `,
    css({
      '&&': css(cleaningAssistantTheme.emptyState?.title),
    }),
  ]);

  const textClass = cx([
    'text-small text-gray-560',
    css`
      margin-block-start: 4px;
      text-align: center;
    `,
    css({
      '&&': css(cleaningAssistantTheme.emptyState?.text),
    }),
  ]);

  return {
    rootClass,
    iconClass,
    titleClass,
    textClass,
  };
};
