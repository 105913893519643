import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@getnuvo/ui-kit';

interface IProps {
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export const ConfirmDiscardDialog: FC<IProps> = ({
  open,
  onDismiss,
  onConfirm,
}: IProps) => {
  return (
    <Dialog open={open} onDismiss={onDismiss}>
      <DialogHeader title="Discard changes" />
      <DialogBody>
        <p className="paragraph-2">
          You have unsaved changes. Do you want to continue?
        </p>
      </DialogBody>
      <DialogFooter>
        <Button variant="outlineSecondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogFooter>
    </Dialog>
  );
};
