import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isBoolean, isEmpty, isNil, isNumber, isObject } from 'lodash';
import { PossibleValue } from './IValueParser';

export abstract class BaseNumberValueParser {
  abstract valueParser: (
    value: PossibleValue,
    isBeforeHook: boolean
  ) => number | string | null;

  parse = (
    _dataModel: DataModel,
    value: PossibleValue,
    isBeforeHook: boolean
  ) => {
    if (isNumber(value)) {
      return this.valueParser(value, isBeforeHook);
    }

    if (isEmpty(value)) {
      return null;
    }

    if (isNil(value)) {
      return null;
    }

    if (isBoolean(value)) {
      return null;
    }

    if (isObject(value) || isArray(value)) {
      return null;
    }

    const result = this.valueParser(value, isBeforeHook);

    return result;
  };
}

export default BaseNumberValueParser;
