import { displayDateText } from '../../core/date';
import { isBoolean } from 'lodash';

/* eslint-disable @typescript-eslint/ban-types */
type PossibleValue = string | number | object | boolean | null | undefined;

class ValueFormatter {
  private static booleanFormat = (value: boolean) => {
    return JSON.stringify(value).toUpperCase();
  };

  static format = (value: PossibleValue) => {
    if (isBoolean(value)) {
      return this.booleanFormat(value);
    } else if (typeof value === 'string') {
      return displayDateText(value);
    } else {
      return value;
    }
  };
}

export default ValueFormatter;
