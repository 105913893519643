import { SHEET_COLUMN_TYPE } from '../../constants/sheet';
import Sheet, { Value } from '../Sheet';
import SheetColumn, { SheetColumnJSON } from '../SheetColumn';

class JoinSheetColumn extends SheetColumn {
  private sheetColumns: SheetColumn[];
  constructor({
    sheet,
    sheetColumns,
    rows,
    id: idConstruct,
    columnKey,
  }: {
    columnKey: string;
    sheet: Sheet;
    sheetColumns: SheetColumn[];
    rows: Value[];
    id?: string;
  }) {
    super({
      columnKey,
      rows,
      sheet,
      id: idConstruct,
    });

    this.sheetColumns = sheetColumns;
  }

  getType = () => {
    return SHEET_COLUMN_TYPE.JOIN;
  };

  getJoinedSheetColumns = () => {
    return this.sheetColumns;
  };

  toJSON = (): SheetColumnJSON => {
    return {
      columnKey: this.columnKey,
      id: this.id,
      sheet: this.sheet.toJSON(),
      rows: this.rows,
      sheetColumns: this.sheetColumns.map((sheetColumn) => {
        return sheetColumn.toJSON();
      }),
      type: this.getType(),
    };
  };
}

export default JoinSheetColumn;
