import AllColumnSetting from '../../columns/AllColumnSetting';
import { SortState } from '../../columns/SortStrategy';
import ContextMenuController from '../controller/ContextMenuController';

const useViewModel = ({
  allColumnSetting,
  currentColumnIndex,
  contextMenuController,
}: {
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  currentColumnIndex: number;
}) => {
  const onMenuClick = (updateSort: SortState | null) => {
    allColumnSetting.getSortStrategy().showLoading(currentColumnIndex);
    setTimeout(() => {
      allColumnSetting.setSortToColumnSetting(currentColumnIndex, updateSort);
    }, 100);
    contextMenuController.closeMenu();
  };

  return { onMenuClick };
};

export default useViewModel;
