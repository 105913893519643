import React, { useCallback, useContext, useEffect } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, RotateCcw, X, XCircle } from 'lucide-react';
import LoadingState from '../LoadingState';
import EmptyState from '../EmptyState';
import CleaningGroupList from '../CleaningGroupList';
import SuggestionList from '../SuggestionList';
import Button from '../../../../../../../components/Button';

const Dropdown = () => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const {
    loading,
    groups,
    getSuggestions,
    onDismissPopover,
    selectedGroupIndex,
    onBackClick,
    initialLoaded,
    count,
    onDismiss,
    onApply,
    selectedSuggestions,
  } = context;
  const selectedGroup = groups[selectedGroupIndex];
  const s = styles(context);

  useEffect(() => {
    if (initialLoaded.current) {
      return;
    }
    initialLoaded.current = true;

    getSuggestions();
  }, [getSuggestions, initialLoaded]);

  const handleDismiss = useCallback(() => {
    onDismiss(selectedSuggestions);
  }, [onDismiss, selectedSuggestions]);

  const handleApply = useCallback(() => {
    onApply(selectedSuggestions);
  }, [onApply, selectedSuggestions]);

  return (
    <div className={s.rootClass}>
      <header className={s.headerClass}>
        {selectedGroup ? (
          <button className={s.headerBackClass} onClick={onBackClick}>
            <ArrowLeft size={16} />
            {t('txt_back')}
          </button>
        ) : (
          <>
            <p className={s.headerTextClass}>{t('txt_clean_data')}</p>
            {groups.length > 0 && (
              <div className={s.headerTagClass}>
                {t('txt_cleanings', { count })}
              </div>
            )}
          </>
        )}
        <div className={s.headerActionsClass}>
          <RotateCcw
            className={s.headerActionRepeatClass}
            onClick={getSuggestions}
            data-testid="refresh-button"
          />
          <X
            className={s.headerActionCloseClass}
            onClick={onDismissPopover}
            data-testid="dismiss-button"
          />
        </div>
      </header>

      <div className={s.bodyClass}>
        {loading ? (
          <LoadingState />
        ) : context.showErrorMessage ? (
          <EmptyState
            icon={<XCircle className="text-salmon-500" />}
            title={t('txt_get_cleanings_fail_title')}
            message={t('txt_get_cleanings_fail_message')}
          />
        ) : groups.length === 0 ? (
          <EmptyState />
        ) : selectedGroup ? (
          <SuggestionList />
        ) : (
          <CleaningGroupList />
        )}
      </div>

      {selectedGroup && (
        <footer className={s.footerClass}>
          <span className={s.footerSelectedClass}>
            {t('txt_count_selected', { count: selectedSuggestions.length })}
          </span>
          <div className={s.footerActionsClass}>
            <button
              className={s.footerDismissClass}
              disabled={selectedSuggestions.length === 0}
              onClick={handleDismiss}
            >
              {t('txt_dismiss')}
            </button>
            <Button
              className={s.footerApplyClass}
              disabled={selectedSuggestions.length === 0}
              onClick={handleApply}
            >
              {t('txt_apply')}
            </Button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Dropdown;
