import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Sheet } from '@nuvo-importer/common/sdk';
import Form from './Form';
import useViewModel, { IFormValues } from './viewModel';
import { HandleAppendCol } from '../../../joinSheet';
import { useTranslation } from 'react-i18next';
import { css, cx, useTheme } from '@nuvo-importer/common';

type IJoinSheetFormProps = {
  appendTargetSheet: Sheet;
  sourceSheet: Sheet;
  handleAppendCol: HandleAppendCol;
};

const AppendSheetForm = ({
  appendTargetSheet,
  sourceSheet,
  handleAppendCol,
}: IJoinSheetFormProps) => {
  const { onSubmit, loading, initialValues } = useViewModel({
    appendTargetSheet,
    handleAppendCol,
  });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="flex h-full min-h-0 flex-grow flex-col">
      <h2
        className={cx(
          'text-neutral-gray mb-2 text-2xl font-semibold',
          css({ '&&': theme.getJoinSheetTheme().appendColumnsDialog?.title })
        )}
      >
        {t('txt_append_column')}
      </h2>
      <p
        className={cx(
          'text-gray-970 mb-6 text-sm',
          css({
            '&&': theme.getJoinSheetTheme().appendColumnsDialog?.description,
          })
        )}
      >
        {t('txt_append_column_modal_desc')}
      </p>
      <FinalForm<IFormValues>
        initialValues={initialValues}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mutators={arrayMutators as any}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <Form
              handleSubmit={handleSubmit}
              appendTargetSheet={appendTargetSheet}
              sourceSheet={sourceSheet}
              loading={loading}
            />
          );
        }}
      />
    </div>
  );
};

export default AppendSheetForm;
