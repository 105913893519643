import { DataRow } from './type';
import { FilterCondition } from '../../columns/FilterStrategy';
import { condition as emptyCondition } from './empty';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { RecordInfo } from '../../type';
import { Validator } from '../../../../reviewEntries/validator';

export const CONDITION_NAME = FilterCondition.IS_NOT_EMPTY;

export function condition(
  dataRow: DataRow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conditionArgs: any,
  dataInfos: Record<string, RecordInfo[]>,
  validator: Validator,
  dataModel: DataModel
) {
  return !emptyCondition(
    dataRow,
    conditionArgs,
    dataInfos,
    validator,
    dataModel
  );
}

export const meta = {
  name: 'Filters:conditions.notEmpty',
  inputsCount: 0,
  showOperators: true,
};
