import { useLocalStorage } from 'react-use';

export const useIsShowSimilarity = () => {
  const [configure] = useLocalStorage<{ isShowSimilarity: boolean }>(
    'configure',
    { isShowSimilarity: false }
  );

  const isShowSimilarity =
    configure?.isShowSimilarity &&
    process.env.NX_APP_ENVIRONMENT === 'development';

  return isShowSimilarity;
};
