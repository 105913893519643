import { lazy } from 'react';
import Development from './Development';
import { isRenderProd } from './constant';
const Production = lazy(() => import('./Production'));

const Router = () => {
  return isRenderProd ? <Production /> : <Development />;
};

export default Router;
