import React, { FC } from 'react';
import { FormElement } from '@getnuvo/ui-kit';
import { TextareaControl } from '../controls/TextareaControl';

export const DataHandler: FC = () => {
  const placeholder = `{
  headerStep: async (modifier, data) => {},
  reviewStep: async (modifier, data) => {},
}`;

  return (
    <div className="nuvo-sdk__form-block nuvo-sdk__codeArea">
      <FormElement label="Data Handler">
        <TextareaControl
          rows={19}
          placeholder={placeholder}
          name="dataHandler"
        />
      </FormElement>
    </div>
  );
};
