import React, { cloneElement, FC, ReactElement, useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { useTranslation } from 'react-i18next';
import { Sparkles } from 'lucide-react';

interface IProps {
  icon?: ReactElement;
  title?: string;
  message?: string;
}

const EmptyState: FC<IProps> = ({ icon, title, message }: IProps) => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);

  return (
    <div className={s.rootClass}>
      {icon ? (
        cloneElement(icon, { size: 24 })
      ) : (
        <Sparkles className={s.iconClass} size={24} />
      )}
      <div className={s.titleClass}>
        {title || t('txt_empty_get_cleanings_title')}
      </div>
      <div className={s.textClass}>
        {message || t('txt_empty_get_cleanings_message')}
      </div>
    </div>
  );
};

export default EmptyState;
