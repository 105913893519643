import createEmotion from '@emotion/css/create-instance';
import type {
  CSSInterpolation,
  ArrayCSSInterpolation,
  ComponentSelector,
  CSSObject,
} from '@emotion/css';
import { NUVO_TAILWIND_CLASS } from './constants/style';

/**
 * It takes an array of strings, and returns a function that takes a CSS element, and if it's a rule,
 * it adds the strings to the beginning of each of the rule's props
 * @param {string[]} extra - string[] - An array of strings that will be added to the beginning of each
 * CSS selector.
 * @returns A function that takes an element and returns a CSS.
 */
export const createExtraScopePlugin = (...extra: string[]) => {
  const scopes = extra.map((scope: string) => `${scope.trim()} `);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (element: any) => {
    if (element.type !== 'rule') {
      return;
    }

    if (element.root?.type === '@keyframes') {
      return;
    }

    element.props = element.props
      .map((prop: string) =>
        scopes.map((scope: string) => {
          if (!prop.includes(scope)) {
            return scope + prop;
          } else {
            return prop;
          }
        })
      )
      .reduce(
        (scopesArray: string[], scope: string) => scopesArray.concat(scope),
        []
      );
  };
};

/* It's creating an instance of emotion with a plugin that adds a class to the beginning of each
selector. */
const emotion = createEmotion({
  key: 'extra-scope',
  stylisPlugins: [createExtraScopePlugin(`.${NUVO_TAILWIND_CLASS}`)],
  prepend: true,
});

const { css, cx } = emotion;

export {
  css,
  cx,
  CSSInterpolation,
  ArrayCSSInterpolation,
  ComponentSelector,
  CSSObject,
};
