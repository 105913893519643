import { CSSObject } from '../../core/emotion';
import { ThemeAPI } from '../themeAPI';
import ButtonTheme, { ColorGlobalTheme } from './ButtonTheme';
import chroma from 'chroma-js';

class TertiaryButtonTheme extends ButtonTheme {
  constructor(themeAPI: ThemeAPI) {
    super('tertiary', 'tertiaryColor' as unknown as ColorGlobalTheme, themeAPI);
  }

  protected generateByGlobalColor = () => {
    return {};
  };

  protected generateByButtonTheme = () => {
    return {};
  };

  generate = () => {
    if (!this.themeAPI.buttons?.['tertiary']) {
      return;
    }
    const theme = {
      ...((this.themeAPI.buttons?.['tertiary'] as CSSObject) ?? {}),
    };

    if (theme.backgroundColor && !theme[':hover']?.backgroundColor) {
      const basedColor = chroma(theme.backgroundColor as string);
      const hoverColor = chroma
        .hsl(
          basedColor.get('hsl.h') + 0.10204,
          basedColor.get('hsl.s') - 0.00009,
          basedColor.get('hsl.l') - 0.05
        )
        .hex();

      theme[':hover'] = {
        backgroundColor: hoverColor,
      };
    }

    return theme;
  };
}

export default TertiaryButtonTheme;
