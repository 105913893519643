import moment from 'moment';
import { DataRow } from '../type';
import { Validator } from '../../../../../reviewEntries/validator';
import { RecordInfo } from '../../../type';
import { DataModel } from '../../../../../dataModel/model/DataModel';
import { FilterCondition } from '../../../columns/FilterStrategy';

export const CONDITION_NAME = FilterCondition.DATE_TOMORROW;
const tomorrow = moment().add(1, 'days').startOf('day');

export function condition(
  dataRow: DataRow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any,
  __: Record<string, RecordInfo[]>,
  ___: Validator,
  dataModel: DataModel
) {
  const dateFormat = dataModel.getOutputFormat();
  const date = moment(dataRow.value, dateFormat);

  if (!date.isValid()) {
    return false;
  }

  return date.isSame(tomorrow, 'd');
}

export const meta = {
  name: 'Filters:conditions.dateTomorrow',
  inputsCount: 0,
};
