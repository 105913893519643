import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { usePage } from 'main/MainView';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useWarnLicense = () => {
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { closePage } = usePage();

  const warnLicenseKeyIsInvalid = useCallback(() => {
    showConfirmModal({
      textNegativeButton: t('txt_close'),
      textPositiveButton: t('txt_back'),
      title: t('txt_invalid_license_key'),
      isShowIcon: true,
      isShowPositiveButton: false,
      description: t('txt_invalid_license_key_description'),
      onClickNegativeButton: () => {
        closePage();
      },
    });
  }, [t, showConfirmModal, closePage]);

  return { warnLicenseKeyIsInvalid };
};

export default useWarnLicense;
