import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.root),
    }),
  ]);

  const spinnerClass = cx([
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.spinner),
    }),
  ]);

  const spinnerLoaderClass = cx([
    'text-blue-500',
    css({
      '&&': cx([
        css({
          color: context.theme.getGlobalTheme().getPrimaryColor(),
        }),
        css(cleaningAssistantTheme.loadingState?.spinnerLoader),
      ]),
    }),
  ]);

  const titleClass = cx([
    'text-xsmall text-neutral-gray',
    css`
      margin-block-start: 16px;
      text-align: center;
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.title),
    }),
  ]);

  const textClass = cx([
    'text-small text-gray-560',
    css`
      margin-block-start: 4px;
      text-align: center;
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.text),
    }),
  ]);

  return {
    rootClass,
    spinnerClass,
    spinnerLoaderClass,
    titleClass,
    textClass,
  };
};
