import { useMemo, useState } from 'react';
import { Sheet, SheetColumn } from '@nuvo-importer/common/core';
import { HandleAppendCol } from '../../../joinSheet';
import { ColumnMapping } from '@nuvo-importer/common/worker/matching-value';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';

export type IFormValues = {
  columns: {
    target: SheetColumn;
    source: SheetColumn | null;
  }[];
};

type IViewModelProps = {
  appendTargetSheet: Sheet;
  handleAppendCol: HandleAppendCol;
};

const useViewModel = ({
  appendTargetSheet,
  handleAppendCol,
}: IViewModelProps) => {
  const [loading, setLoading] = useState(false);
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return {
      columns: appendTargetSheet.getColumns().map((col) => {
        return {
          target: col,
          source: null,
        };
      }),
    };
  }, [appendTargetSheet]);

  const onSubmit = async (values: IFormValues) => {
    const noBaseColumn = values.columns.every((item) => item.source === null);
    if (noBaseColumn) {
      showConfirmModal({
        title: t('txt_no_column_select'),
        description: t('txt_no_column_select_append_desc'),
        onClickNegativeButton: () => {},
        onClickPositiveButton: () => {},
        isShowIcon: true,
        isShowCloseIcon: false,
        disabledClickOutside: true,
        textNegativeButton: t('txt_continue'),
        isShowPositiveButton: false,
      });
      return;
    }
    setLoading(true);
    const mappings = values.columns
      .filter((item) => item.source && item.target)
      .map((item) => {
        return {
          source: item.source,
          target: item.target,
        };
      }) as ColumnMapping[];
    try {
      await handleAppendCol(mappings);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setLoading(false);
  };

  return { onSubmit, loading, setLoading, initialValues };
};

export default useViewModel;
