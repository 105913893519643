import { RefObject, useMemo } from 'react';
import TruncatePopover from 'baseUI/TruncatePopover';
import DataModelMatcher from './../DataModelMatcher';
import useViewModel from './viewModel';
import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import DataModelSheetMatcherModel from './../../../../matching/DataModelSheetMatcher';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import { JoinSheetColumn } from '@nuvo-importer/common/sdk';
import { DataModel } from 'dataModel';
import { useCollapseCard } from '../common/CollapseCard';
import Previewer from './Previewer';
import { ReactComponent as MatchIcon } from '../../../../assets/icon/arrow-right-match.svg';
import { breakpoints } from 'core/constants/breakpoints';
import { ColumnMatchThemeAPI } from './types';
import { SHEET_COLUMN_TYPE } from 'core/constants/sheet';
import { ReactComponent as JoinIcon } from '../../../../assets/icon/join-icon.svg';
import { useContextJoinColumnsModalManager } from 'baseUI/Modal/JoinColumns/context';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';

const PreviewData = ({
  sheetColumn,
  matchedDataModel,
  prefixName,
  dataModelSheetMatcherModel,
  configTheme,
  configThemePopper,
  index,
  setDataModelSheetMatcher,
  setLoadingMatchingMoreOptions,
  loadingMatchingMoreOptions,
}: {
  sheetColumn: SheetColumn;
  matchedDataModel?: DataModel;
  prefixName: string;
  dataModelSheetMatcherModel: DataModelSheetMatcherModel;
  configTheme?: ColumnMatchThemeAPI;
  configThemePopper?: CSSInterpolation;
  index: number;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcherModel
  ) => void;
  setLoadingMatchingMoreOptions: (loadingMatchingMoreOptions: boolean) => void;
  loadingMatchingMoreOptions: boolean;
}) => {
  const { t } = useTranslation();
  const { isCollapse } = useCollapseCard();
  const theme = useTheme();

  const previewColBgColor = useMemo(() => {
    return theme.getGlobalTheme().getLight50Color();
  }, [theme]);

  const previewColBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getLight100Color();
  }, [theme]);

  const { isBorderBottomRounded, rowExamples, columnName } = useViewModel({
    matchedDataModel,
    sheetColumn,
    prefixName,
    isCollapse,
    index,
  });

  const { setOpenModal, setSheetColumnsJoin } =
    useContextJoinColumnsModalManager();

  const styledNormal500Color = useMemo(() => {
    return theme.getGlobalTheme().getNormal500Color();
  }, [theme]);

  const styled = useMemo(() => {
    return {
      girdTemplate: css`
        grid-template-columns: 1fr 56px 1fr;
        @media (min-width: ${breakpoints.large}) {
          grid-template-columns: 1fr 56px 1fr;
        }
      `,
      root: css({ '&&': configTheme?.columnMatchHeader?.root }),
      borderColorColName: css({
        '&&': {
          borderColor: (configTheme?.columnMatchHeader?.root as CSSObject)
            ?.borderColor,
        },
      }),
      textColor: css({
        '&&': {
          color: (configTheme?.root as CSSObject)?.color,
        },
      }),
      colorIcon: css`
        .color {
          color: ${(configTheme?.columnMatchHeader?.icon as CSSObject)?.color ??
          '#162338'};
          ${configTheme?.columnMatchHeader?.icon}
        }
      `,
      dropdownButton: {
        root: css({
          '&&': configTheme?.columnMatchHeader?.dropdown?.button?.root,
        }),
        icon: css({
          '&&': {
            color:
              (
                configTheme?.columnMatchHeader?.dropdown?.button
                  ?.icon as CSSObject
              )?.color ?? '#C5C5C5',
          },
        }),
        placeholder: css({
          '&&': {
            color:
              configTheme?.columnMatchHeader?.dropdown?.button?.placeholder,
          },
        }),
      },
      dropdownMenuItems: {
        root: css({ '&&': configTheme?.columnMatchHeader?.dropdown?.root }),
        borderColorRoot: css({
          '&&': {
            borderColor: (
              configTheme?.columnMatchHeader?.dropdown?.root as CSSObject
            )?.borderColor,
          },
        }),
        navigatorColor:
          configTheme?.columnMatchHeader?.dropdown?.scrollbar?.navigatorColor &&
          css({
            '&& .simplebar-scrollbar:before': {
              backgroundColor: `${configTheme?.columnMatchHeader?.dropdown?.scrollbar?.navigatorColor} !important`,
            },
          }),
        backgroundColor:
          configTheme?.columnMatchHeader?.dropdown?.scrollbar
            ?.backgroundColor &&
          css({
            '&& .simplebar-track': {
              backgroundColor: `${configTheme?.columnMatchHeader?.dropdown?.scrollbar?.backgroundColor} !important`,
            },
          }),
        header: css({ '&&': configTheme?.columnMatchHeader?.dropdown?.header }),
        menuItem: {
          selectedOption: css({
            '&&': configTheme?.columnMatchHeader?.dropdown?.selectedOption,
          }),
          option: css({
            '&&': configTheme?.columnMatchHeader?.dropdown?.option,
          }),
          badge: configTheme?.columnMatchHeader?.dropdown?.badge,
          search: {
            root: css({
              '&&': configTheme?.columnMatchHeader?.dropdown?.search?.root,
            }),
            icon: css`
              .color {
                color: #383838;
                ${configTheme?.columnMatchHeader?.dropdown?.search?.icon}
              }
            `,
            placeholder: css`
              ::placeholder {
                color: ${configTheme?.columnMatchHeader?.dropdown?.search
                  ?.placeholder};
                opacity: 1;
              }
            `,
          },
          createNewOption: {
            root: css({
              '&&': configTheme?.columnMatchHeader?.dropdown?.createNewOption
                ?.root,
            }),
            icon: css`
              color: #162338;
              ${configTheme?.columnMatchHeader?.dropdown?.createNewOption?.icon}
            `,
          },
          iconCheckedColor:
            configTheme?.columnMatchValue?.dropdown?.iconCheckedColor,
        },
        popoverInfoIcon: css({
          '&&': {
            color:
              (configTheme?.matchingTitle?.icon as CSSObject)?.color ??
              '#162338',
          },
        }),
        popoverInfoTheme: {
          root: css({ '&&': configThemePopper }),
          arrowIcon: (configThemePopper as CSSObject)?.backgroundColor
            ? css((configThemePopper as CSSObject)?.backgroundColor)
            : undefined,
        },
      },
      buttonJoined: {
        root: css({ '&&&': configTheme?.buttonJoined?.root }),
        icon: css({
          '&& path': { fill: `${configTheme?.buttonJoined?.icon?.color}` },
        }),
      },
    };
  }, [configTheme, configThemePopper]);

  return (
    <div className="min-w-0">
      <div
        className={cx(
          'border-1 rounded-t-medium grid h-12 border-b-0 pr-4',
          isBorderBottomRounded ? 'rounded-b-medium border-b' : '',
          css({
            backgroundColor: previewColBgColor,
            borderColor: previewColBorderColor,
          }),
          styled.girdTemplate,
          styled.root
        )}
      >
        <div className="flex">
          <div
            className={cx(
              'xssmd:w-14 border-r-1 flex w-9 flex-shrink-0 items-center justify-center',
              css({
                borderColor: previewColBorderColor,
              }),
              styled.borderColorColName
            )}
          >
            <span
              className={cx('text-color-primary text-sm', styled.textColor)}
            >
              {columnName}
            </span>
          </div>
          <div className="xssmd:pl-4 flex items-center space-x-2 pl-2">
            <div>
              <TruncatePopover
                isCheckLineClamp
                render={({ referenceElement, textRef, getHover }) => {
                  return (
                    <div
                      ref={referenceElement as RefObject<HTMLDivElement>}
                      {...getHover()}
                    >
                      <span
                        ref={textRef as RefObject<HTMLParagraphElement>}
                        className={cx(
                          'text-color-primary line-clamp-2 break-all text-sm',
                          styled.textColor
                        )}
                      >
                        {sheetColumn.getColumnKey()}{' '}
                      </span>
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex-shrink-0">
              {sheetColumn.getType() === SHEET_COLUMN_TYPE.JOIN ? (
                <button
                  type="button"
                  className={cx(
                    'rounded-medium leading-5.5 flex items-center space-x-1 px-2 py-1 text-sm font-semibold',
                    css({
                      backgroundColor: theme
                        .getGlobalTheme()
                        .getLight100Color(),
                      color: styledNormal500Color,
                      ':hover': {
                        backgroundColor: theme
                          .getGlobalTheme()
                          .getLight200Color(),
                      },
                    }),
                    css({ '&&': styled.buttonJoined.root })
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(true);
                    setSheetColumnsJoin(
                      (sheetColumn as JoinSheetColumn).getJoinedSheetColumns()
                    );
                  }}
                >
                  <JoinIcon
                    className={cx(
                      css({
                        '&&': {
                          color: styledNormal500Color,
                        },
                      }),
                      css({ '&&': styled.buttonJoined.icon })
                    )}
                  />
                  <p>{t('txt_joined')}</p>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center px-4">
          <MatchIcon className={cx('flex-shrink-0', styled.colorIcon)} />
        </div>
        <div className={cx('flex items-center justify-end')}>
          <DataModelMatcher
            title={sheetColumn.getColumnKey()}
            sheetColumn={sheetColumn}
            matchedDataModel={matchedDataModel}
            prefixName={prefixName}
            dataModelSheetMatcherModel={dataModelSheetMatcherModel}
            configThemeDropdownButton={styled.dropdownButton}
            configThemeDropdownMenuItems={styled.dropdownMenuItems}
            setDataModelSheetMatcher={setDataModelSheetMatcher}
            setLoadingMatchingMoreOptions={setLoadingMatchingMoreOptions}
          />
        </div>
      </div>
      <div
        className={
          isCollapse
            ? css`
                position: absolute;
                overflow: hidden;
                clip: rect(0 0 0 0);
                height: 1px;
                width: 1px;
                margin: -1px;
                padding: 0;
                border: 0;
              `
            : ''
        }
      >
        <Previewer
          matchedDataModel={matchedDataModel}
          sheetColumn={sheetColumn}
          dataModelSheetMatcher={dataModelSheetMatcherModel}
          prefixName={prefixName}
          rowExamples={rowExamples}
          configTheme={configTheme}
          loadingMatchingMoreOptions={loadingMatchingMoreOptions}
        />
      </div>
    </div>
  );
};

export default PreviewData;
