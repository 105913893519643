import { map, concat, toArray } from 'rxjs';
import MatchingDTO from '../dto/MatchingDTO';
import { DataModel } from 'dataModel';
import { DataModelSheetMatch } from '../DataModelSheetMatching';
import { SheetColumn } from '@nuvo-importer/common/sdk';
import BaseMatchingRepository from './BaseMatchingRepository';
import NodeProcessingEngine from '../engines/node/NodeProcessingEngine';
import BrowserProcessingEngine from '../engines/browser/BrowserProcessingEngine';
import { ExecuteOptions } from '../types/ml';

class BrowserMatchingRepository extends BaseMatchingRepository {
  private browserEngine: BrowserProcessingEngine;
  private nodeEngine: NodeProcessingEngine;

  constructor(
    browserEngine: BrowserProcessingEngine,
    nodeEngine: NodeProcessingEngine
  ) {
    super();
    this.browserEngine = browserEngine;
    this.nodeEngine = nodeEngine;
  }

  prepareData = () => {
    return Promise.resolve();
  };

  initialize = () => {};

  clearData = () => {};

  complete = () => {};

  protected override matchColumnsTask = (
    matchingMapperDTO: MatchingDTO,
    options: ExecuteOptions
  ) => {
    const columns = matchingMapperDTO.getColumns().map((column) => {
      return { ...column, dropdownOptions: [] };
    });
    const headers = matchingMapperDTO.getInputHeaderRow();
    const licenseKey = matchingMapperDTO.getLicenseKey();

    return this.nodeEngine.matchColumns(headers, columns, licenseKey, options);
  };

  protected override matchOptionsInitialTask = (
    dataModelSheetMatch: DataModelSheetMatch[],
    matchingMapperDTO: MatchingDTO
  ) => {
    const licenseKey = matchingMapperDTO.getLicenseKey();

    const allObservables = dataModelSheetMatch.map((match) => {
      const columns = MatchingDTO.mapDataModelToColumn(
        match.matchedDataModel!.dataModel!
      );
      const inputJson = MatchingDTO.getInputJsonBySheetColumn(
        match.sheetColumn,
        match.matchedDataModel!.dataModel!
      );

      return this.browserEngine.execute([columns], inputJson, licenseKey).pipe(
        map((calculateSimilarityResults) => {
          return calculateSimilarityResults[0];
        })
      );
    });

    return concat(...allObservables).pipe(toArray());
  };

  protected override matchOptionsTask = ({
    dataModel,
    sheetColumn,
    licenseKey,
  }: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    licenseKey: string;
  }) => {
    const column = MatchingDTO.mapDataModelToColumn(dataModel);
    const inputJson = MatchingDTO.getInputJsonBySheetColumn(
      sheetColumn,
      dataModel
    );

    return this.browserEngine.execute([column], inputJson, licenseKey);
  };
}

export default BrowserMatchingRepository;
