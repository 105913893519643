import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import './index.css';
import {
  css,
  cx,
  FullScreenButton,
  ProgressProcessing,
} from '@nuvo-importer/common';
import BaseModal from 'baseUI/Modal/BaseModal';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'sheetImporter';
import { HotTable, HotTableClass } from '@handsontable/react';
import { GridSettings } from 'handsontable/settings';
import { useLicenseKeyAuth } from 'license';
import { useHotTableScroll } from './useHotTableScroll';
import WrapperStyled from './WrapperStyled';
import Core from 'handsontable/core';
import {
  SheetPreviewThemeAPI,
  displayDateText,
} from '@nuvo-importer/common/sdk';
import { textRenderer } from 'handsontable/renderers';

interface IProps {
  sheet: Sheet;
  isOpen: boolean;
  onCloseModal: () => void;
  sheetName: string;
  filename: string;
  theme?: SheetPreviewThemeAPI;
}

export const SheetPreview: FC<IProps> = ({
  sheet,
  isOpen,
  onCloseModal,
  sheetName,
  filename,
  theme,
}: IProps) => {
  const { t } = useTranslation();
  const { htLicenseKey } = useLicenseKeyAuth();
  const hotInstanceRef = useRef<HotTableClass>(null);
  const [isDisplayTable, setIsDisplayTable] = useState(false);

  const data = useMemo(() => {
    return sheet.getData();
  }, [sheet]);

  const colAmount = useMemo(() => {
    return data.map((item) => item.length).sort((a, b) => b - a)[0];
  }, [data]);

  const COLUMN_WIDTH = 251;

  useHotTableScroll(hotInstanceRef, colAmount, COLUMN_WIDTH, isOpen);

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const toggleFullScreen = useCallback(() => {
    setFullScreen((f: boolean) => !f);
  }, [setFullScreen]);

  const settings: GridSettings = useMemo(() => {
    return {
      data: sheet.getData(),
      height: '100%',
      autoColumnSize: true,
      autoRowSize: false,
      rowHeaderWidth: 60,
      colHeaders: true,
      rowHeaders: true,
      readOnly: true,
      disableVisualSelection: true,
      stretchH: 'all',
      licenseKey: htLicenseKey,
      rowHeights: 33,
      columnHeaderHeight: 33,
      columns: Array(colAmount).fill({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderer: function renderer(this: Core, ...args: any) {
          args[5] = displayDateText(args[5]);
          textRenderer.apply(this, args);
        },
      }),
      colWidths: Array(colAmount).fill(COLUMN_WIDTH),
      renderAllRows: false,
      className: 'htMiddle default-cell',
      viewportColumnRenderingOffset: 10,
      afterGetColHeader: (_columns, TH) => {
        TH.className = cx(
          'htMiddle htLeft bg-gray-50 text-sm font-normal text-gray-560 header'
        );
        TH.children[0].className =
          'px-4 htMiddle htLeft h-full flex items-center';
      },
    };
  }, [colAmount, sheet, htLicenseKey]);

  const rootClass: string = cx(
    'sheet-preview__modal !my-auto inline-flex flex-col min-h-0',
    fullScreen
      ? css('max-width: calc(100% - 5rem); height: calc(100% - 5rem)')
      : css('height: 720px; max-width: 1080px;'),
    css({
      '& > div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 auto',
      },
    })
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsDisplayTable(true);
        hotInstanceRef.current?.hotInstance?.render();
      }, 700);
    } else {
      setIsDisplayTable(false);
    }
  }, [isOpen]);

  return (
    <BaseModal
      gravity="middle"
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      isShowCloseIcon={true}
      className={rootClass}
      wrapperClassName={css({
        '&&': {
          zIndex: 10000,
        },
      })}
    >
      <div
        className={cx(
          'flex min-h-0 w-full flex-grow flex-col text-left',
          css`
            flex: 1 1 0;
          `
        )}
      >
        <h1
          className={cx(
            'text-2.5xl text-blue-dark-900 mb-2 w-full font-semibold',
            css({ '&&': theme?.title })
          )}
        >
          {t('txt_preview-sheet')}
        </h1>
        <div className="flex items-center gap-2">
          <span
            className={cx(
              'text-neutral-gray text-base font-semibold',
              css({ '&&': theme?.sheetName })
            )}
          >
            {sheetName}
          </span>
          <span
            className={cx(
              'text-xsmall text-gray-560',
              css({ '&&': theme?.fileName })
            )}
          >
            {filename}
          </span>

          <div className="ml-auto">
            <FullScreenButton
              modal={true}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={fullScreen}
            />
          </div>
        </div>

        <div className="sheet-preview__container">
          <div
            className={cx(
              'sheet-preview__table border-1 rounded-medium border-gray-250 mt-4 bg-gray-50',
              !isDisplayTable ? 'flex-shrink opacity-0' : ''
            )}
          >
            <WrapperStyled theme={theme?.table}>
              <div
                className={cx(
                  'sheet-preview h-full',
                  !isFirefox && 'not_firefox'
                )}
              >
                <HotTable ref={hotInstanceRef} settings={settings} />
              </div>
            </WrapperStyled>
          </div>

          {!isDisplayTable && (
            <div className="sheet-preview__loading">
              <ProgressProcessing timer={1} text={t('txt_loading')} />
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
};
