import { PossibleValue } from './IValueParser';
import { BaseValueParser } from './BaseNumberParser';

class FloatNumberValueParser extends BaseValueParser {
  parseStrToValue = (value: PossibleValue) => {
    return parseFloat(`${value}`);
  };
}

export default FloatNumberValueParser;
