import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useLocationPathname = () => {
  const { pathname: basePathname } = useLocation();

  const pathname = useMemo(() => {
    const isBaseOnHash =
      (window.location.pathname === '/' || window.location.pathname === '') &&
      window.location.hash;

    if (isBaseOnHash) {
      const hashPathName = window.location.hash.replace('#', '');
      return basePathname.replace(hashPathName.replace(/\/$/, ''), '');
    } else if (window.location.pathname !== '/') {
      return basePathname.replace(
        window.location.pathname.replace(/\/$/, ''),
        ''
      );
    } else {
      return basePathname;
    }
  }, [basePathname]);

  return { pathname };
};

export default useLocationPathname;
