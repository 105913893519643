import { firstValueFrom } from 'rxjs';
import UploadInputSheetAPI from './UploadInputSheetAPI';

class InputSheetClearer {
  private uploadInputSheetAPI: UploadInputSheetAPI;
  constructor(uploadInputSheetAPI: UploadInputSheetAPI) {
    this.uploadInputSheetAPI = uploadInputSheetAPI;
  }

  private fileIds: string[] = [];

  remove = (removeFileId: string) => {
    this.fileIds = this.fileIds.filter((id) => id !== removeFileId);
  };

  add = (fileId: string) => {
    this.fileIds.push(fileId);
  };

  clear = async (licenseKey: string) => {
    const fileIds = [...this.fileIds];
    for (let i = 0; i < fileIds.length; ++i) {
      const fileId = fileIds[i];
      this.remove(fileId);
      await firstValueFrom(
        this.uploadInputSheetAPI.clearFile(fileId, false, licenseKey)
      ).catch(() => {});
    }
  };
}

export default InputSheetClearer;
