import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';

class CategoryParser implements ValueParser {
  private parseSingle = (
    value: PossibleValue,
    _options?: IValueParserOptions
  ) => {
    if (isNil(value)) {
      return '';
    }

    if (isObject(value) || isArray(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    return `${value}`;
  };

  private parseMultiple = (
    value: PossibleValue,
    _isParseFromSheet: boolean
  ) => {
    if (value === '') {
      return null;
    }

    if (isArray(value)) {
      return value.map((item) => `${item}`);
    }

    if (isNil(value) || isObject(value)) {
      return null;
    }

    if (isBoolean(value)) {
      return [JSON.stringify(value)];
    }

    return [`${value}`];
  };

  parse = (value: PossibleValue, options?: IValueParserOptions) => {
    if (options?.dataModel?.getIsMultiSelection()) {
      return this.parseMultiple(value, !!options.isParseFromSheet);
    } else {
      return this.parseSingle(value);
    }
  };
}

export default CategoryParser;
