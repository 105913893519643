import {
  ColumnAPI,
  ColumnsAPIMapper,
  DataModel,
  RecordInfo,
} from '@nuvo-importer/common/sdk';
import {
  DataHandlerResultValues,
  HookedRecordResult,
  ImportLogs,
  ModifierAddRow,
  ResultValues,
} from '../types';
import {
  MODIFIER_TYPE,
  ModifierAddColumn,
  ModifierRemoveColumn,
} from './DataHandlerAPI';
import { DataHandlerMapper } from './DataHandlerMapper';
import { isArray, isNumber, isObject } from 'lodash';
import FeatureWhiteList from '../settings/FeatureWhiteList';
import { ImportLogsDataModelSheet } from '../hooks/hooksAPI';
import { getImportLogs } from '../hooks/utils/getImportLogs';

type DataHandlerMapperDataProps<T> = {
  data: T;
  columns: ColumnAPI[];
  dataModels: DataModel[];
  featureWhiteList: FeatureWhiteList;
  dataModelSheet: ImportLogsDataModelSheet;
};

export class ReviewStepHandler extends DataHandlerMapper<
  DataHandlerMapperDataProps<ResultValues>,
  Omit<
    DataHandlerMapperDataProps<DataHandlerResultValues>,
    'featureWhiteList' | 'dataModelSheet'
  >
> {
  private columns: ColumnAPI[] = [];
  private dataModels: DataModel[] = [];
  private dataInfos: Record<string, RecordInfo[]> = {};

  protected mappedHandler = async ({
    data,
    columns,
    dataModels,
    featureWhiteList,
    dataModelSheet,
  }: {
    data: DataHandlerResultValues;
    columns: ColumnAPI[];
    dataModels: DataModel[];
    featureWhiteList: FeatureWhiteList;
    dataModelSheet: ImportLogsDataModelSheet;
  }) => {
    this.columns = columns;
    this.dataModels = dataModels;

    if (this?.dataModifier) {
      const logs: ImportLogs = getImportLogs(featureWhiteList, dataModelSheet);

      const parsedData = await this.dataModifier(
        this.getModifier(),
        data,
        logs
      );
      this.modifierData = parsedData ?? data;
      this.modifierStack.forEach((entry) => {
        this.executeModifier(entry);
      });
      this.removeRow();
      this.generateDataAndDataInfo(this.modifierData);
      return {
        data: this.modifierData as ResultValues,
        columns: this.columns ?? [],
        dataModels: this.dataModels ?? [],
        dataInfos: this.dataInfos,
      };
    } else {
      return {
        data: data as ResultValues,
        columns: this.columns ?? [],
        dataModels: this.dataModels ?? [],
        dataInfos: this.dataInfos,
      };
    }
  };

  protected dataModifierAddColumn: ModifierAddColumn = ({
    key,
    label,
    columnType,
    validations,
    dropdownOptions,
    outputFormat,
    isMultiSelect,
  }) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.ADD_COLUMN,
      columnLabel: label,
      columnKey: key,
      columnType: columnType,
      columnValidations: validations,
      columnDropdownOptions: dropdownOptions,
      outputFormat: outputFormat,
      isMultiSelect: isMultiSelect,
    });
  };

  protected dataModifierRemoveColumn: ModifierRemoveColumn = (key) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.REMOVE_COLUMN,
      columnKey: key,
    });
  };

  protected executorAddColumn: ModifierAddColumn = ({
    key,
    label,
    columnType,
    validations,
    dropdownOptions,
    outputFormat,
    isMultiSelect,
  }) => {
    const modifierColumn = [
      {
        key,
        label,
        columnType,
        validations,
        dropdownOptions,
        outputFormat,
        isMultiSelect,
      },
    ];

    const modifierColumnsAPIMapper = new ColumnsAPIMapper(modifierColumn);
    const modifierTargetDataModels = modifierColumnsAPIMapper.getDataModels();
    for (let i = 0; i < modifierTargetDataModels.length; i++) {
      const targetDataModel = modifierTargetDataModels[i];
      const targetColumn = modifierColumn[i];
      const duplicateIndex = this.dataModels.findIndex(
        (entry) => entry.getKey() === targetDataModel.getKey()
      );

      if (duplicateIndex >= 0) {
        this.dataModels[duplicateIndex] = targetDataModel;
        this.columns[duplicateIndex] = targetColumn;
      } else {
        this.dataModels.push(targetDataModel);
        this.columns.push(targetColumn);
      }
    }
  };

  protected executorRemoveColumn: ModifierRemoveColumn = (key) => {
    this.columns = this.columns.filter((entry) => entry.key !== key);
    this.dataModels = this.dataModels.filter((entry) => entry.getKey() !== key);
  };

  protected executorAddRow: ModifierAddRow = ({ data, index }) => {
    if (isNumber(index)) {
      this.modifierData.splice(index, 0, ...data);
    } else {
      this.modifierData.push(...data);
    }
  };

  private generateDataAndDataInfo = (
    data: DataHandlerResultValues,
    rowIndex?: number | null
  ) => {
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      const entryRowIndex =
        rowIndex === null || rowIndex === undefined ? i : rowIndex;
      for (const [key, values] of Object.entries(entry)) {
        if (isObject(values) && !isArray(values)) {
          const columnIndex = this.dataModels.findIndex(
            (entry) => entry.getKey() === key
          );
          const value = (values as HookedRecordResult)?.info ?? [];
          for (let j = 0; j < value?.length; j++) {
            const element = value[j];
            const info = {
              colIndex: columnIndex,
              rowIndex: entryRowIndex,
              popover: element,
            };

            if ((this.dataInfos[entryRowIndex]?.length ?? 0) === 0) {
              this.dataInfos[entryRowIndex] = [info];
            } else {
              this.dataInfos[entryRowIndex].push(info);
            }
          }
          entry[key] = (values as HookedRecordResult).value;
        }
      }
    }
  };

  getDataModifier = () => {
    return this.mappedHandler;
  };

  cleanUp = () => {
    this.modifierData = [];
    this.modifierStack = [];
  };
}
