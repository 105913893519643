export const enum ERROR_CODE_PARSE_FILE {
  FORMAT_ELEMENT = 9001,
  EXCEEDED_MAX_LEVEL_NESTED_OBJECT = 9002,
  ADVANCED_PARSING_NOT_ALLOWED = 9003,
  UNKNOWN = -1,
}

export enum ERROR_CODE_IMPORT_FILE {
  INVALID_FORMAT = 1000,
  NOT_MATCHED_TDM = 1001,
  NOT_SUPPORT = 1002,
  UNKNOWN = 2000,
}
