import { useTheme } from '../../theme';
import { Variant, variantClassname } from './variantStyle';

type UseViewModelParams = {
  variant: Variant;
};

const useViewModel = ({ variant }: UseViewModelParams) => {
  const theme = useTheme();
  return {
    variantClassname: variantClassname({ variant, theme }),
  };
};

export default useViewModel;
