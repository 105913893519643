import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../types';
import { useStyle } from './style';
import BadgeMatchItem from '../BadgeMatchItem';
import SheetColumn from './../../../../../../sheetImporter/SheetColumn';
import { Boundary } from '@popperjs/core';
import { css, CSSInterpolation, cx } from 'core/emotion';
import { useIsShowSimilarity } from 'settings';
import { DataModelDescription } from '../../../MatchingReport/MatchingText/index.view';

export const MenuItem = forwardRef<
  HTMLSpanElement,
  {
    active: boolean;
    option: Option;
    selected: boolean;
    onRemoveMatchColumn: (value: SheetColumn) => void;
    boundary?: Boundary;
    configThemeDropdownMenuItem?: {
      selectedOption?: string;
      option?: string;
      badge?: { root?: CSSInterpolation; icon?: CSSInterpolation };
    };
    popoverInfoIcon?: string;
    popoverInfoTheme?: {
      root?: string;
      arrowIcon?: string;
    };
    hasDescription?: () => boolean;
    setHighlightedIndex?: (index: number) => void;
  }
>(
  (
    {
      active,
      option,
      selected,
      onRemoveMatchColumn,
      boundary,
      configThemeDropdownMenuItem,
      popoverInfoIcon,
      popoverInfoTheme,
      hasDescription,
      setHighlightedIndex,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { menuItemClassname, menuItemTextClassname } = useStyle({
      active,
      selected,
      configThemeDropdownMenuItem,
    });
    const isShowSimilarity = useIsShowSimilarity();

    const textRequired = option.sheetColumnMatch
      ? 'text-gray-500'
      : 'text-red-500';

    const spacing = css`
      min-width: 20px;
    `;

    const hasDescriptionWithSpace = () => {
      return (
        !option.value?.getDescription() &&
        hasDescription?.() && (
          <div className={cx('mr-4 h-5 w-5', spacing)}></div>
        )
      );
    };

    return (
      <span
        {...props}
        ref={ref}
        className={menuItemClassname}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseLeave={() => setHighlightedIndex?.(-1)}
      >
        <div className="leading-4.5 flex items-center justify-center">
          {option.value?.getDescription() ? (
            <div className="pr-4">
              <DataModelDescription
                overflowCheckOffset={{ top: 180, bottom: -25 }}
                boundary={boundary}
                matchedDataModel={option.value}
                configThemePopoverInfoIcon={popoverInfoIcon}
              />
            </div>
          ) : null}
          {hasDescriptionWithSpace()}
          <span className={cx(menuItemTextClassname)}>{`${option.label} ${
            isShowSimilarity ? `(${option.similarity.getSimilarity()})` : ''
          }`}</span>
          {option.isRequire ? (
            <span
              className={`text-xss whitespace-nowrap lowercase italic leading-3 ${textRequired}`}
            >
              ({t('txt_required')})
            </span>
          ) : null}
        </div>
        <BadgeMatchItem
          themeBadge={configThemeDropdownMenuItem?.badge}
          configThemePopoverInfo={popoverInfoTheme}
          option={option}
          onRemoveMatchColumn={onRemoveMatchColumn}
          boundary={boundary}
        />
      </span>
    );
  }
);
