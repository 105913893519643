import { useCallback, useMemo, ReactNode, useRef, useEffect } from 'react';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import {
  getAcceptFileTypes,
  parseFilesToSheets,
  getFileUploadTime,
  handleFileRejection,
  handleFileParseError,
  getDropzoneStyles,
} from './fileUtils';
import { useContextCircleLoadingModalManager } from 'baseUI/Modal/CircleLoading/context';
import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { useLicenseKeyAuth, useWarnLicense } from 'license';
import { useScreenSize } from 'core/constants/screensSize';
import { useWarnSmallScreen } from 'hooks';
import { useSettings } from 'settings';
import {
  useConfigure,
  useFeatureWhiteList,
} from '../configure/ConfigureProvider';
import Tracking from '../tracking/Tracking';
import { useContextFileParserWorkerManager } from './worker/WebWorkerProvider';
import { usePage } from 'main/MainView';
import { useDataModels } from './../dataModel';
import { css } from '@emotion/css';
import { useTheme } from 'theme';
import { DataFile } from '../modules/nuvo.parser.worker';
import { useContextCustomViewModalManager } from 'baseUI/Modal/CustomView';
import { PromiseCancelable } from 'core/promiseUtils';

type DropzoneProps = {
  children: ReactNode;
  className?: string;
  onFileSelectedSuccess: (data: DataFile[]) => void;
  onFileSelectedError: (errorMessage: string, title?: string) => void;
  onProcessing: () => void;
};

const MainUploader = ({
  children,
  className,
  onFileSelectedSuccess,
  onFileSelectedError,
  onProcessing,
}: DropzoneProps) => {
  const { setUploadWebWorker } = useContextFileParserWorkerManager();
  const { convertFile2JsonRef, setConvertFile2Json, setOpenModal } =
    useContextCustomViewModalManager();
  const { isOpen, dismissLoading, isProcessing } =
    useContextCircleLoadingModalManager();
  const convertFile2Json = useRef<PromiseCancelable<DataFile[]>>();
  const { t } = useTranslation();
  const { warnLicenseKeyIsInvalid } = useWarnLicense();
  const { isAuth } = useLicenseKeyAuth();
  const { isNotSmallScreen } = useScreenSize();
  const { warnSmallScreen } = useWarnSmallScreen();
  const { multipleFileUpload, identifier } = useSettings();
  const { isLoading } = useLicenseKeyAuth();
  const { featureWhiteList } = useFeatureWhiteList();
  const { setTimeUpload } = usePage();
  const dataModel = useDataModels();
  const { inputTypes } = useSettings();
  const { licenseKey } = useConfigure();
  const theme = useTheme();
  const dropzoneTheme = theme.getDropzoneTheme();

  useEffect(() => {
    if (!isProcessing) {
      if (convertFile2JsonRef) {
        convertFile2JsonRef.current?.cancel();
        setConvertFile2Json(null);
      } else {
        convertFile2Json.current && convertFile2Json.current.cancel();
      }
    }
  }, [convertFile2JsonRef, isOpen, isProcessing, setConvertFile2Json]);

  const onDropRejected = useCallback(
    (errors: FileRejection[]) => {
      handleFileRejection(
        onFileSelectedError,
        errors,
        featureWhiteList.getMaxFileSizeInMb(),
        t,
        inputTypes || []
      );
    },
    [onFileSelectedError, featureWhiteList, t, inputTypes]
  );

  const onDropAccepted = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      convertFile2Json.current = parseFilesToSheets(
        acceptedFiles,
        {
          hasDateType: dataModel.hasDateType(),
          originRequest: Tracking.getInstance().getOrigin(),
          licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
          sdkVersion: Tracking.getInstance().getVersionNumber(),
        },
        setUploadWebWorker
      );
      setConvertFile2Json(convertFile2Json.current);
      setTimeUpload(getFileUploadTime(acceptedFiles));

      if (!isProcessing) {
        Tracking.getInstance().pushEventStack(identifier, {
          step: 'upload',
          action: 'start',
        });
        setOpenModal(false);
        onProcessing();
        convertFile2Json.current.promise
          .then((result) => {
            Tracking.getInstance().clearUploadedFilesStack(identifier);
            Tracking.getInstance().setUploadedFiles(identifier, result);
            dismissLoading();
            onProcessing();
            onFileSelectedSuccess(result);
            setConvertFile2Json(null);
          })
          .catch((err) => {
            Tracking.getInstance().pushEventStack(identifier, {
              step: 'upload',
              action: 'end',
            });
            dismissLoading();
            if (!err.isCancelled) {
              handleFileParseError(
                onFileSelectedError,
                err.code,
                t,
                inputTypes || []
              );
            }
            setConvertFile2Json(null);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProcessing, onFileSelectedSuccess, onFileSelectedError, inputTypes]
  );

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (!isAuth) {
      warnLicenseKeyIsInvalid();
      return;
    }
    if (isNotSmallScreen) {
      if (fileRejections.length > 0) {
        onDropRejected(fileRejections);
      } else {
        onDropAccepted(acceptedFiles);
      }
    } else {
      warnSmallScreen();
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: getAcceptFileTypes(inputTypes || []),
      noClick: !isAuth || !isNotSmallScreen,
      disabled: isLoading,
      multiple: multipleFileUpload,
      onDrop,
      maxSize: featureWhiteList.getIsFileSizeUnlimited()
        ? undefined
        : featureWhiteList.getMaxFileSizeInBytes(),
    });

  const style = useMemo(
    () => getDropzoneStyles(isDragAccept, isDragReject),
    [isDragReject, isDragAccept]
  );

  return (
    <div
      className={cx(
        'w-full min-w-0',
        css({ '&&': dropzoneTheme.container }),
        className
      )}
      {...getRootProps({
        style,
        onClick: () => {
          if (!isAuth) {
            warnLicenseKeyIsInvalid();
          }
          if (!isNotSmallScreen) {
            warnSmallScreen();
          }
        },
      })}
    >
      <input
        {...getInputProps({
          multiple: multipleFileUpload,
        })}
      />
      {children}
    </div>
  );
};

export default MainUploader;
