import { cx } from '@emotion/css';
import useViewModel from './viewModel';
import { MenuItemProps } from './index';

const DesktopMenuItem = ({ name }: MenuItemProps) => {
  const { isActive } = useViewModel();

  const className = cx(
    isActive
      ? 'border-indigo-500'
      : 'border-transparent hover:border-gray-300 hover:text-gray-700',
    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium text-white'
  );

  return (
    <span className={className} aria-current={isActive ? 'page' : undefined}>
      {name}
    </span>
  );
};

export default DesktopMenuItem;
