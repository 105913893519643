import { DATATYPE } from '../../dataType';
import type { CurrencyType } from '../../value';
import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';
import { formatCurrency } from '../utils';
import { NumberParser } from '../../utils/NumberParser';
import { NumberFormat } from '../../dataModel/columnsAPI';

class CurrencyValueParser implements ValueParser {
  parse = (
    dataModel: DataModel,
    value: PossibleValue,
    isBeforeHook: boolean,
    isPipelineResult?: boolean
  ) => {
    if (isNil(value)) {
      return null;
    }

    if (isArray(value) || isObject(value)) {
      return null;
    }

    if (isBoolean(value)) {
      return null;
    }

    if (value === '') {
      return null;
    }

    const universalDecimal = `${value}`.replace(',', '.');
    const result = NumberParser.convertStringToNumber(universalDecimal, {
      format: NumberFormat.US,
    });

    if (result === null) {
      return `${value}`;
    }

    if (isPipelineResult) {
      return formatCurrency(dataModel.getType(), result);
    }

    if (isBeforeHook) {
      return value;
    }

    const type: CurrencyType =
      dataModel.getType() === DATATYPE.CURRENCY_EUR ? 'EUR' : 'USD';

    return {
      value: Number(result),
      type,
    };
  };
}

export default CurrencyValueParser;
