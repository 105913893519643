import { DataRow } from './type';
import { Validator } from '../../../../reviewEntries/validator';
import { FilterCondition } from '../../columns/FilterStrategy';
import { RecordInfo } from '../../type';
import Handsontable from 'handsontable';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { isArray } from 'lodash';

export const CONDITION_NAME = FilterCondition.IS_EMPTY;

export function condition(
  dataRow: DataRow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any,
  __: Record<string, RecordInfo[]>,
  ___: Validator,
  dataModel: DataModel
) {
  if (dataModel.isDropdown() && dataModel.getIsMultiSelection()) {
    return (
      Handsontable.helper.isEmpty(dataRow.value) ||
      (isArray(dataRow.value) && dataRow.value.length === 0)
    );
  }
  return Handsontable.helper.isEmpty(dataRow.value);
}

export const meta = {
  name: 'Filters:conditions.empty',
  inputsCount: 0,
  showOperators: true,
};
