import { HotTableClass } from '@handsontable/react';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { DATATYPE } from '../../../dataType';
import { CustomColumnUIObservable } from '../customColumns';
import { Subject } from 'rxjs';
import { DataModel } from '../../../dataModel/model/DataModel';

type UseColumnViewModelProps = {
  hotInstance: RefObject<HotTableClass>;
  customColumnUIObservable: Subject<CustomColumnUIObservable>;
};

const useColumnViewModel = ({
  hotInstance,
  customColumnUIObservable,
}: UseColumnViewModelProps) => {
  const [isCustomColumnOpen, setIsCustomColumnOpen] = useState(false);
  const [typeCustomColumn, setTypeCustomColumn] = useState<
    null | 'add' | 'edit'
  >(null);
  const [editingDataModel, setEditingDataModel] = useState<DataModel | null>(
    null
  );

  useEffect(() => {
    if (isCustomColumnOpen) {
      hotInstance.current?.hotInstance?.unlisten();
    } else {
      hotInstance.current?.hotInstance?.listen();
    }
  }, [hotInstance, isCustomColumnOpen]);

  const onCustomColumnClose = () => {
    setIsCustomColumnOpen(false);
    setTimeout(() => {
      setTypeCustomColumn(null);
      setEditingDataModel(null);
    }, 300);
  };

  useEffect(() => {
    const subscription = customColumnUIObservable.subscribe((event) => {
      setIsCustomColumnOpen(event.isOpen);
      setTypeCustomColumn(event.type);
      if (event.type === 'edit') {
        setEditingDataModel(event.dataModel);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [customColumnUIObservable]);

  const columnInitialValues = useMemo(() => {
    if (typeCustomColumn === 'edit' && editingDataModel) {
      return {
        columnType: editingDataModel.getType(),
        columnName: editingDataModel.getLabel(),
        dateFormat: editingDataModel.getOutputFormat(),
      };
    } else {
      return {
        columnType: DATATYPE.STRING,
        columnName: '',
      };
    }
  }, [typeCustomColumn, editingDataModel]);

  return {
    isCustomColumnOpen,
    onCustomColumnClose,
    columnInitialValues,
    typeCustomColumn,
    editingDataModel,
  };
};

export default useColumnViewModel;
