const Loading = () => {
  return (
    <div className="dropdown-item options animate-pulse">
      <div className="3xl:py-2 my-1 mx-1 h-3 w-auto animate-pulse rounded bg-gray-200 py-1" />
      <div className="3xl:py-2 my-1 mx-1 h-3 w-auto animate-pulse rounded bg-gray-200 py-1" />
      <div className="3xl:py-2 my-1 mx-1 h-3 w-auto animate-pulse rounded bg-gray-200 py-1" />
      <div className="3xl:py-2 my-1 mx-1 h-3 w-auto animate-pulse rounded bg-gray-200 py-1" />
    </div>
  );
};

export default Loading;
