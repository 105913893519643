import { ValidatorJSON } from '../../dataModel/serializer/type';
import { DATATYPE } from '../../dataType';
import Validator, { VALIDATION } from '../validator/Validator';
import { NumberFormat, OptionMappingMode } from '../columnsAPI';

export type CategoryDataModelType = Exclude<
  DATATYPE,
  | DATATYPE.BOOLEAN
  | DATATYPE.FLOAT
  | DATATYPE.INTEGER
  | DATATYPE.STRING
  | DATATYPE.DATE_DMY
  | DATATYPE.DATE_MDY
  | DATATYPE.DATE_ISO
  | DATATYPE.DATETIME
  | DATATYPE.TIME_HMS
  | DATATYPE.TIME_HM
  | DATATYPE.EMAIL
  | DATATYPE.URL_WWW
  | DATATYPE.URL_HTTPS
  | DATATYPE.URL
  | DATATYPE.PHONE
  | DATATYPE.COUNTRY_CODE_ALPHA_2
  | DATATYPE.COUNTRY_CODE_ALPHA_3
  | DATATYPE.CURRENCY_CODE
  | DATATYPE.CURRENCY_EUR
  | DATATYPE.CURRENCY_USD
  | DATATYPE.BIC
  | DATATYPE.VAT_EU
  | DATATYPE.GTIN
  | DATATYPE.IBAN
>;

export type DataModelType = DATATYPE;

export class DataModel {
  private description: string;
  private key: string;
  private example: string;
  private label: string;
  private type: DataModelType;
  private validators: Validator[];
  private alternativeMatches: string[];
  private columnSize: number;
  private creator?: string;
  private baseKey: string;
  private baseKeyCounter: number;
  private outputFormat: string;
  private numberFormat: NumberFormat;
  private optionMappingMode: OptionMappingMode;

  constructor({
    description,
    key,
    example,
    label,
    type,
    validators,
    alternativeMatches,
    columnSize,
    creator,
    baseKey,
    baseKeyCounter,
    outputFormat,
    numberFormat,
    optionMappingMode,
  }: {
    description: string;
    key: string;
    example: string;
    label: string;
    type: DataModelType;
    validators?: Validator[];
    alternativeMatches?: string[];
    columnSize: number;
    creator?: string;
    baseKey?: string;
    baseKeyCounter?: number;
    outputFormat?: string;
    numberFormat?: NumberFormat;
    optionMappingMode?: OptionMappingMode;
  }) {
    this.description = description;
    this.key = key;
    this.example = example;
    this.label = label;
    this.type = type;
    this.validators = validators ?? [];
    this.alternativeMatches = alternativeMatches
      ? [
          ...alternativeMatches
            .filter((alternativeMatch) => {
              return alternativeMatch !== label && alternativeMatch !== key;
            })
            ?.map((alt) => alt?.toString()),
        ]
      : [];
    this.columnSize = columnSize;
    this.creator = creator;
    this.baseKey = baseKey || key;
    this.baseKeyCounter = baseKeyCounter || 0;
    this.outputFormat = outputFormat || '';
    this.numberFormat = numberFormat || NumberFormat.EU;
    this.optionMappingMode = optionMappingMode || OptionMappingMode.SMART;
  }

  static getOutputFormat = (outputFormat?: string | null) => {
    const tmp = outputFormat ? outputFormat : 'DD.MM.YYYY';
    return tmp.replace(/d/g, 'D').replace(/e|E/g, '');
  };

  getDescription = () => {
    return this.description;
  };

  getKey = () => {
    return this.key;
  };

  getExample = () => {
    return this.example;
  };

  getLabel = () => {
    return this.label;
  };

  setLabel = (label: string) => {
    this.label = label;
  };

  getType = () => {
    return this.type;
  };

  getOutputFormat = () => {
    return DataModel.getOutputFormat(this.outputFormat);
  };

  getValidators = () => {
    return this.validators;
  };

  getColumnSize = () => {
    return this.columnSize;
  };

  getIsRequired = () => {
    const isRequired = this.validators.find(
      (item) => item.getValidation() === VALIDATION.REQUIRED
    );

    return Boolean(isRequired) ?? false;
  };

  getIsUnique = () => {
    const isUnique = this.validators.find(
      (item) => item.getValidation() === VALIDATION.UNIQUE
    );

    return Boolean(isUnique) ?? false;
  };

  getIsUniqueAndRequired = () => {
    return this.getIsRequired() === true && this.getIsUnique() === true;
  };

  getAlternativeMatches = () => {
    return this.alternativeMatches.filter((item) => !!item);
  };

  static isTypeDropdown = (type: DATATYPE) => {
    return DataModel.isTypeCategory(type) || type === DATATYPE.BOOLEAN;
  };

  getCreator = () => {
    return this.creator;
  };

  getBaseKey = () => {
    return this.baseKey;
  };

  getBaseKeyCounter = () => {
    return this.baseKeyCounter;
  };

  setCustomKey = ({
    key,
    baseKey,
    baseKeyCounter,
    creator,
  }: {
    key: string;
    baseKey: string;
    baseKeyCounter: number;
    creator: string;
  }) => {
    this.baseKey = baseKey;
    this.key = key;
    this.baseKeyCounter = baseKeyCounter;
    this.creator = creator;
  };

  static isTypeCategory = (type: DATATYPE) => {
    return (
      type === DATATYPE.SINGLE_SELECT ||
      type === DATATYPE.CURRENCY_CODE ||
      type === DATATYPE.COUNTRY_CODE_ALPHA_2 ||
      type === DATATYPE.COUNTRY_CODE_ALPHA_3
    );
  };

  isDropdown = () => {
    return DataModel.isTypeDropdown(this.getType());
  };

  isCategoryType = () => {
    return DataModel.isTypeCategory(this.getType());
  };

  serialize = () => {
    return {
      description: this.getDescription(),
      example: this.getExample(),
      key: this.getKey(),
      label: this.getLabel(),
      type: this.getType(),
      validators: [] as ValidatorJSON[],
      alternativeMatches: this.getAlternativeMatches(),
      columnSize: this.getColumnSize(),
      outputFormat: this.getOutputFormat(),
      isMultiSelection: this.getIsMultiSelection(),
      numberFormat: this.getNumberFormat(),
      baseKey: this.getBaseKey(),
      baseKeyCounter: this.getBaseKeyCounter(),
      creator: this.getCreator(),
      optionMappingMode: this.getOptionMappingMode(),
    };
  };

  getIsMultiSelection = () => {
    return false;
  };

  getNumberFormat = (): NumberFormat => {
    return this.numberFormat;
  };

  getOptionMappingMode = () => {
    return this.optionMappingMode;
  };

  isNumeric = () => {
    return (
      this.type === DATATYPE.INTEGER ||
      this.type === DATATYPE.FLOAT ||
      this.type === DATATYPE.CURRENCY_EUR ||
      this.type === DATATYPE.CURRENCY_USD ||
      this.type === DATATYPE.PERCENTAGE
    );
  };

  isCurrency = () => {
    return (
      this.type === DATATYPE.CURRENCY_EUR || this.type === DATATYPE.CURRENCY_USD
    );
  };

  baseClone = () => {
    const dataModelJSON = this.serialize();
    return new DataModel({
      description: dataModelJSON.description,
      example: dataModelJSON.example,
      key: dataModelJSON.key,
      label: dataModelJSON.label,
      type: dataModelJSON.type as CategoryDataModelType,
      validators: this.getValidators(),
      alternativeMatches: dataModelJSON.alternativeMatches,
      columnSize: dataModelJSON.columnSize,
      baseKey: dataModelJSON.baseKey,
      baseKeyCounter: dataModelJSON.baseKeyCounter,
      creator: dataModelJSON.creator,
      numberFormat: dataModelJSON.numberFormat,
      outputFormat: dataModelJSON.outputFormat,
      optionMappingMode: dataModelJSON.optionMappingMode,
    });
  };
}
