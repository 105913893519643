import { HotTableClass } from '@handsontable/react';
import { MutableRefObject, RefObject } from 'react';
import { Validator } from '../../reviewEntries/validator';
import { RecordInfo } from '../../reviewEntries/type';
import CheckboxController from './checkbox/CheckboxController';
import AllColumnSetting from './columns/AllColumnSetting';
import ModeViewTable from './ModeViewTable/ModeViewTable';
import { OnEntryChange } from '../../hooks/hooksAPI';
import DataModelRegistry from './DataModelRegistry';
import { ChangeLog, DeleteRowChange } from './type';
import isPromise from 'is-promise';

type UseRemoveProps = {
  validator: Validator;
  dataInfos: Record<string, RecordInfo[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  hotInstance: RefObject<HotTableClass>;
  updateTotalError: () => void;
  isLastRow: MutableRefObject<boolean>;
  onRepositionPopper: () => void;
  checkboxController?: CheckboxController;
  allColumnSetting: AllColumnSetting;
  modeViewTable: ModeViewTable;
  onEntryChange?: OnEntryChange;
  dataModelRegistry: DataModelRegistry;
  onAfterRemove?: () => void;
  isCustomAddColumn: (col: number) => boolean;
  handleAfterRemove: (
    deleteRows: DeleteRowChange[]
  ) => Promise<void> | undefined;
};

const useRemove = ({
  validator,
  dataInfos,
  dataSet,
  hotInstance,
  updateTotalError,
  isLastRow,
  onRepositionPopper,
  checkboxController,
  allColumnSetting,
  modeViewTable,
  onEntryChange,
  dataModelRegistry,
  onAfterRemove,
  isCustomAddColumn,
  handleAfterRemove,
}: UseRemoveProps) => {
  const onRemove = (rows: number[]) => {
    const columns = dataModelRegistry.getColumns();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pendingDeleteRows: { index: number; data?: any[] }[] = [];

    const sortedRows = rows
      .map((row) => {
        return hotInstance.current?.hotInstance?.toPhysicalRow(row) ?? row;
      })
      .sort((a, b) => (a > b ? -1 : 1));

    for (let i = 0; i < sortedRows.length; i++) {
      const deleteRowIndex = sortedRows[i];
      const originalDataLength = dataSet.length - 1;
      const rowData =
        hotInstance.current?.hotInstance?.getDataAtRow(deleteRowIndex);
      pendingDeleteRows.push({
        index: deleteRowIndex,
        data: rowData,
      });

      dataSet.splice(deleteRowIndex, 1);

      const errors = validator.getError();

      for (
        let rowIndex = deleteRowIndex + 1;
        rowIndex < errors.length;
        ++rowIndex
      ) {
        errors[rowIndex - 1] = errors[rowIndex];
      }

      if (deleteRowIndex < errors.length) {
        errors.splice(-1);
      }

      for (
        let rowIndex = deleteRowIndex + 1;
        rowIndex < originalDataLength;
        rowIndex++
      ) {
        const targetRowIndex = rowIndex - 1;
        dataInfos[`${targetRowIndex}`] = dataInfos[`${rowIndex}`];
        for (
          let j = 0;
          j < (dataInfos[`${targetRowIndex}`]?.length ?? 0);
          ++j
        ) {
          if (dataInfos[`${targetRowIndex}`][j]) {
            dataInfos[`${targetRowIndex}`][j].rowIndex = rowIndex;
          }
        }
      }

      dataInfos[`${originalDataLength - 1}`] = [];
    }

    validator.generateUniqueListFromDataSet(dataSet, columns);
    columns.forEach((col, colIndex) => {
      validator.removeUniqueErrorFirstRow(col, colIndex);
    });

    checkboxController?.clearCheckedMaps();

    hotInstance.current?.hotInstance?.updateData(dataSet);

    if (allColumnSetting.hasFilter()) {
      allColumnSetting.getFilterStrategy().recalculate();
    }

    const sortingColumnIndex = allColumnSetting.getSortingColumnIndex();

    if (sortingColumnIndex > -1) {
      const columnSetting =
        allColumnSetting.getColumnSetting(sortingColumnIndex);
      allColumnSetting
        .getSortStrategy()
        .sortColumn(sortingColumnIndex, columnSetting.sort);
    }

    const deleteRows: DeleteRowChange[] = [];
    let afterRemove;
    if (onEntryChange) {
      for (let i = pendingDeleteRows.length - 1; i >= 0; --i) {
        const pendingDeleteRow = pendingDeleteRows[i];
        const { data, index } = pendingDeleteRow;
        const changeLog: ChangeLog = {};

        if (data) {
          for (let i = 0; i < data.length; i++) {
            const physicalCol =
              hotInstance.current?.hotInstance?.toPhysicalColumn(i) ?? 0;
            if (isCustomAddColumn(i)) {
              continue;
            }
            const key = columns[physicalCol].key;
            changeLog[key] = data[i];
          }

          deleteRows.push({
            actionType: 'delete',
            changeLog,
            currentRowData: [],
            rowIndex: index,
          });
        }
      }
      afterRemove = handleAfterRemove(deleteRows);
    } else {
      updateTotalError();
    }

    const handleRender = () => {
      const selectedCell = hotInstance.current?.hotInstance?.getSelected()?.[0];
      const row = selectedCell?.[0] ?? -1;
      isLastRow.current = dataSet.length === row + 1;

      modeViewTable.recalculate();
      hotInstance.current?.hotInstance?.render();

      onRepositionPopper();
      onAfterRemove?.();
    };

    if (isPromise(afterRemove)) {
      afterRemove.then(() => {
        handleRender();
      });
    } else {
      handleRender();
    }
  };

  return { onRemove };
};

export default useRemove;
