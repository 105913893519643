import { css, cx } from '../../core/emotion';
import Popover from '../Popover';
import Message from '../Popover/Message';
import { ReactNode, RefObject } from 'react';
import useViewModel from './viewModel';

const messageStyle = cx(
  'break-all text-left',
  css`
    max-width: 340px;
  `
);

const TruncatePopover = ({
  render,
  isCheckLineClamp = false,
  pageWrapperElement,
}: {
  isCheckLineClamp?: boolean;
  detectChangeText?: string;
  render: ({
    referenceElement,
    textRef,
    getHover,
  }: {
    referenceElement: RefObject<HTMLDivElement> | RefObject<HTMLElement> | null;
    textRef: RefObject<HTMLDivElement> | RefObject<HTMLElement> | null;
    getHover: () => {
      onMouseEnter?: () => void;
      onMouseLeave?: () => void;
      onClick?: () => void;
    };
  }) => ReactNode;
  pageWrapperElement: HTMLElement | undefined;
}) => {
  const { isShowFullTextPopover, textRef, getHover } = useViewModel({
    isCheckLineClamp,
  });

  return (
    <Popover
      isShow={isShowFullTextPopover}
      offset={[0, 8]}
      direction="top-start"
      rootElement={pageWrapperElement}
      overflowCheckOffset={{
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      message={
        isShowFullTextPopover ? (
          <Message className={messageStyle} variant="info">
            {textRef.current?.innerText}
          </Message>
        ) : null
      }
      className="pointer-events-none"
      render={({ referenceElement }) => {
        return render({ referenceElement, textRef, getHover });
      }}
    />
  );
};

export default TruncatePopover;
