import { DataModel } from 'dataModel';
import { saveAs } from 'file-saver';

const getContentDataModelCSV = (dataModels: DataModel[]) => {
  const headers = dataModels.map((item) => `"${item.getLabel()}"`).join(',');
  const contents = dataModels.map(() => `""`).join(',');
  return [headers, contents];
};

const exportToCsv = (fileName: string, dataModels: DataModel[]) => {
  const contentDataModel = getContentDataModelCSV(dataModels);
  const content = contentDataModel.join('\r\n');

  return saveAs(
    new Blob([content], { type: 'text/csv;charset=utf-8;' }),
    `${fileName}.csv`
  );
};

export default exportToCsv;
