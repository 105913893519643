import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { HotTableClass } from '@handsontable/react';
import { isNil } from 'lodash';
import { IColumnInformation, ISearchParams } from '..';
import CategoryDataModel, {
  Option,
} from '../../../../../dataModel/model/CategoryDataModel';
import { DataModel } from '../../../../../dataModel/model/DataModel';
import { useDebounce } from 'react-use';
import { ConfirmModalProps } from '../../../confirmModal';
import { useTranslation } from 'react-i18next';
import { isMatchSearchByValue } from '../../../../utils';
import DataModelRegistry from '../../../DataModelRegistry';

type ViewModelProps = {
  onFindSearchMatch: (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => void;
  onGetAllSearchMatchCount: (
    searchParams: ISearchParams
  ) => Promise<{ counter: number; hidden: number; skip: number } | undefined>;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  dataModels: DataModel[];
  isOpenMenu: boolean;
  setIsOpenMenu: (open: boolean) => void;
  lastSelectedBySearchCell?: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  showConfirmModal: (props: ConfirmModalProps) => void;
  setWaitingConfirmReplace: (waiting: boolean) => void;
  hotInstance: RefObject<HotTableClass>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  dataModelRegistry: DataModelRegistry;
};

const useViewModel = ({
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  dataModels,
  isOpenMenu,
  setIsOpenMenu,
  lastSelectedBySearchCell,
  showConfirmModal,
  setWaitingConfirmReplace,
  hotInstance,
  dataSet,
  dataModelRegistry,
}: ViewModelProps) => {
  const [matchCount, setMatchCount] = useState(0);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [skipCount, setSkipCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchDataModelKeys, setSearchDataModelKeys] = useState<string[]>(
    dataModels.map((dataModel) => dataModel.getKey())
  );
  const [isExact, setIsExact] = useState(false);
  const [wordToReplace, setWordToReplace] = useState('');
  const [replaceAllButtonLoading, setReplaceAllButtonLoading] = useState(false);
  const [getMatchLoading, setGetMatchLoading] = useState(false);
  const findInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const getIsAllMatchIsSkip = useCallback(
    ({ matchCount, skipCount }: { matchCount: number; skipCount: number }) =>
      matchCount > 0 && matchCount === skipCount,
    []
  );

  const getTargetColumnToSearch = useCallback(() => {
    const cols: IColumnInformation[] = [];

    for (let i = 0; i < searchDataModelKeys.length; i++) {
      const colKey = searchDataModelKeys[i];
      const dataModel = dataModels.find(
        (dataModel) => dataModel.getKey() === colKey
      );
      let options: Option[] = [];

      if (dataModel?.isCategoryType()) {
        options = (dataModel as CategoryDataModel)?.getOptions();
      }

      if (dataModel) {
        cols.push({
          key: dataModel.getKey(),
          type: dataModel.getType(),
          isNumeric: dataModel.isNumeric(),
          numberFormat: dataModel.getNumberFormat(),
          options: options,
          isMultiSelection: dataModel.getIsMultiSelection(),
        });
      }
    }

    return cols;
  }, [searchDataModelKeys, dataModels]);

  const onReplaceClick = () => {
    onReplaceWordSearchMatch({
      value: searchValue,
      isExact: isExact,
      columns: getTargetColumnToSearch(),
      isReplaceAll: false,
      wordToReplace,
    });

    onGetAllSearchMatchCount({
      value: searchValue,
      isExact,
      columns: getTargetColumnToSearch(),
    }).then((result) => {
      const matchCount = result?.counter ?? 0;
      const hiddenCount = result?.hidden ?? 0;
      const skipCount = result?.skip ?? 0;
      setMatchCount(matchCount);
      setHiddenCount(hiddenCount);
      setSkipCount(skipCount);
      checkReplaceCell();
    });
  };

  const replaceAll = () => {
    onReplaceWordSearchMatch({
      value: searchValue,
      isExact,
      columns: getTargetColumnToSearch(),
      isReplaceAll: true,
      wordToReplace,
    }).then(() => {
      return onGetAllSearchMatchCount({
        value: searchValue,
        isExact,
        columns: getTargetColumnToSearch(),
      }).then((result) => {
        const matchCount = result?.counter ?? 0;
        const hiddenCount = result?.hidden ?? 0;
        const skipCount = result?.skip ?? 0;
        setMatchCount(matchCount);
        setHiddenCount(hiddenCount);
        setSkipCount(skipCount);
        setReplaceAllButtonLoading(false);
        checkReplaceCell();
      });
    });
  };

  const onReplaceAllClick = () => {
    if (matchCount > 1) {
      setWaitingConfirmReplace(true);
      showConfirmModal({
        title: t('txt_confirm_title'),
        description: t('txt_confirm_replace_all', {
          count: matchCount - skipCount,
        }),
        onClickNegativeButton: () => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              replaceAll();
              setWaitingConfirmReplace(false);
              resolve();
            }, 100);
          });
        },
        onClickPositiveButton: () => {
          setWaitingConfirmReplace(false);
        },
        isShowIcon: true,
        isShowCloseIcon: false,
        disabledClickOutside: true,
        textNegativeButton: t('txt_replace_all'),
        textPositiveButton: t('txt_cancel'),
      });
    } else {
      setReplaceAllButtonLoading(true);
      replaceAll();
    }
  };

  const checkReplaceCell = useCallback(() => {
    const selectedCell = hotInstance.current?.hotInstance?.getSelected();
    const targetCell = selectedCell?.[0];
    if (isNil(targetCell)) {
      setErrorMessage('');
      return;
    }

    const colIndex =
      hotInstance.current?.hotInstance?.toPhysicalColumn(targetCell[1]) ?? -1;

    const targetDataModel = dataModels[colIndex];

    if (!targetDataModel?.isDropdown() || searchValue.trim().length === 0) {
      setErrorMessage('');
      return;
    }

    const rowIndex =
      hotInstance.current?.hotInstance?.toPhysicalRow(targetCell[0]) ?? -1;
    const dataCol = dataSet[rowIndex][targetDataModel.getKey()];
    const targetCol = getTargetColumnToSearch().find(
      (col) => col.key === targetDataModel.getKey()
    );
    const isMatchSearch =
      targetCol &&
      isMatchSearchByValue(dataCol, {
        value: searchValue,
        isExact,
        columns: [targetCol],
      });

    if (isMatchSearch) {
      setErrorMessage(t('txt_unable_replace_value'));
    } else {
      setErrorMessage('');
    }
  }, [
    hotInstance,
    dataSet,
    t,
    isExact,
    searchValue,
    dataModels,
    getTargetColumnToSearch,
  ]);

  const find = () => {
    onFindSearchMatch(
      {
        value: searchValue,
        isExact,
        columns: getTargetColumnToSearch(),
      },
      false
    );
  };

  const onFindClick = () => {
    find();
    checkReplaceCell();
  };

  useEffect(() => {
    if (isOpenMenu) {
      setErrorMessage('');
      onGetAllSearchMatchCount({
        value: searchValue,
        isExact,
        columns: getTargetColumnToSearch(),
      }).then((result) => {
        const matchCount = result?.counter ?? 0;
        const hiddenCount = result?.hidden ?? 0;
        const skipCount = result?.skip ?? 0;
        setMatchCount(matchCount);
        setHiddenCount(hiddenCount);
        setSkipCount(skipCount);
        checkReplaceCell();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMenu]);

  useDebounce(
    () => {
      setErrorMessage('');
      if (searchValue.trim().length === 0) {
        setMatchCount(0);
        setHiddenCount(0);
        setSkipCount(0);
        setGetMatchLoading(false);
        checkReplaceCell();
        return;
      }

      onGetAllSearchMatchCount({
        value: searchValue,
        isExact,
        columns: getTargetColumnToSearch(),
      }).then((result) => {
        const matchCount = result?.counter ?? 0;
        const hiddenCount = result?.hidden ?? 0;
        const skipCount = result?.skip ?? 0;
        setMatchCount(matchCount);
        setHiddenCount(hiddenCount);
        setSkipCount(skipCount);
        setGetMatchLoading(false);
        checkReplaceCell();
      });
    },
    700,
    [searchValue, isExact, getTargetColumnToSearch, checkReplaceCell]
  );

  useDebounce(
    () => {
      if (searchValue.trim().length === 0) {
        return;
      }
      find();
      setTimeout(() => {
        findInputRef.current?.focus();
      }, 100);
    },
    700,
    [searchValue]
  );

  useEffect(() => {
    if (searchValue.trim().length !== 0) {
      setGetMatchLoading(true);
    }
    if (lastSelectedBySearchCell) {
      lastSelectedBySearchCell.current = null;
    }
  }, [searchValue, isExact, getTargetColumnToSearch, lastSelectedBySearchCell]);

  const actionDisabled = !searchValue.trim() || matchCount <= 0;
  const replaceDisabled = getIsAllMatchIsSkip({ matchCount, skipCount });

  const clearFieldStates = useCallback(() => {
    setMatchCount(0);
    setHiddenCount(0);
    setSkipCount(0);
    setSearchValue('');
    setSearchDataModelKeys(dataModels.map((dataModel) => dataModel.getKey()));
    setIsExact(false);
    setWordToReplace('');
    setGetMatchLoading(false);
    setReplaceAllButtonLoading(false);
    setErrorMessage('');
    if (lastSelectedBySearchCell) {
      lastSelectedBySearchCell.current = null;
    }
  }, [dataModels, lastSelectedBySearchCell]);

  const onCloseClick = () => {
    clearFieldStates();
    setIsOpenMenu(false);
  };

  useEffect(() => {
    const subscription = dataModelRegistry
      .dataModelObservable()
      .subscribe((event) => {
        if (event.action === 'add_column') {
          setSearchDataModelKeys((prevSearchDataModelKeys) => {
            return [...prevSearchDataModelKeys, event.dataModel.getKey()];
          });
        } else if (event.action === 'remove_column') {
          setSearchDataModelKeys((prevSearchDataModelKeys) => {
            return prevSearchDataModelKeys.filter((key) => key !== event.key);
          });
        } else if (event.action === 'edit_column') {
          setSearchDataModelKeys((prevSearchDataModelKeys) => {
            return prevSearchDataModelKeys.map((key) => {
              if (key === event.oldKey) {
                return event.dataModel.getKey();
              } else {
                return key;
              }
            });
          });
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [dataModelRegistry]);

  return {
    matchCount,
    searchValue,
    searchDataModelKeys,
    isExactMatch: isExact,
    wordToReplace,
    setSearchValue,
    setSearchDataModelKeys,
    setIsExactMatch: setIsExact,
    setWordToReplace,
    onReplaceClick,
    onReplaceAllClick,
    onFindClick,
    actionDisabled,
    replaceAllButtonLoading,
    findInputRef,
    getMatchLoading,
    onCloseClick,
    hiddenCount,
    errorMessage,
    replaceDisabled,
  };
};

export default useViewModel;
