import { HooksAPI, OnResults } from './hooksAPI';

const defaultCallback = () => {};

class HooksAPIMapper {
  private hooksAPI: HooksAPI;
  private onResultCallbackMapping: Record<string, OnResults | undefined> = {};

  constructor(hooksAPI: HooksAPI, identifier: string) {
    this.hooksAPI = hooksAPI;
    this.onResultCallbackMapping = { [identifier]: hooksAPI.onResults };
  }

  getGeneralHooks = (identifier: string) => {
    const onCancel = this.hooksAPI.onCancel ?? defaultCallback;
    const onResults =
      identifier?.trim()?.length > 0
        ? this.onResultCallbackMapping[identifier]
        : this.hooksAPI.onResults;
    const onEntryChange = this.hooksAPI.onEntryChange ?? defaultCallback;
    const onEntryInit = this.hooksAPI.onEntryInit ?? defaultCallback;

    return {
      onResults,
      onCancel,
      onEntryChange,
      onEntryInit,
    };
  };
}

export default HooksAPIMapper;
