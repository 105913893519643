import { Values } from '@nuvo-importer/common/core';
import { firstValueFrom, Observable } from 'rxjs';
import { RxiosConfig } from './Rxios';

type Chunk = {
  slice: Blob;
  start: number;
  end: number;
  index: number;
};

type UploadRemote = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any,
  config: RxiosConfig
) => Observable<unknown>;

const DEFAULT_UPLOAD_CHUNK_SIZE = 5000;

class UploadChunk {
  private file!: Values;
  private chunks: Chunk[] = [];
  private uploadRemote: UploadRemote = () => new Observable();
  private chunkStartIndex = 0;
  private onUploadFailed: (failedIndex: number) => void = () => {};
  private currentProcessing = 0;
  private controller?: AbortController;
  private uploadChunkSize: number;

  constructor(uploadChunkSize?: number) {
    this.uploadChunkSize = uploadChunkSize ?? DEFAULT_UPLOAD_CHUNK_SIZE;
  }

  private uploadSlice = (chunk: Chunk) => {
    const body = {
      data: chunk.slice,
      index: chunk.index,
    };

    return firstValueFrom(
      this.uploadRemote(
        body,
        this.controller
          ? {
              signal: this.controller.signal,
            }
          : {}
      )
    );
  };

  private sliceChunk = (start: number) => {
    let end = start + this.uploadChunkSize;

    if (this.file.length - end < 0) {
      end = this.file.length;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileSlice = this.file.slice(start, end) as any;

    this.chunks.push({
      slice: fileSlice,
      start: start,
      end: end,
      index: this.chunks.length,
    });

    if (end < this.file.length) {
      const nextStart = start + this.uploadChunkSize;
      this.sliceChunk(nextStart);
    } else {
      return;
    }
  };

  private generateChunks = () => {
    const start = 0;
    this.chunks = [];
    this.sliceChunk(start);
  };

  prepareInstance(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: any[],
    uploadRemote: UploadRemote,
    chunkStartIndex: number,
    onUploadFailed: (failedIndex: number) => void,
    controller?: AbortController
  ) {
    this.file = file;
    this.uploadRemote = uploadRemote;
    this.chunkStartIndex = chunkStartIndex;
    this.onUploadFailed = onUploadFailed;
    this.controller = controller;
  }

  upload = async () => {
    this.generateChunks();

    try {
      for (const chunk of this.chunks) {
        this.currentProcessing = chunk.index;
        if (chunk.start >= this.chunkStartIndex) {
          await this.uploadSlice(chunk);
        }
      }
    } catch (err) {
      this.onUploadFailed(this.currentProcessing);
      throw err;
    }
  };

  getTotalChunk = () => {
    return this.chunks.length;
  };
}

export default UploadChunk;
