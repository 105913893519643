import { css, cx, useTheme } from '@nuvo-importer/common';
import { Sheet, JoinSheetColumn } from '@nuvo-importer/common/sdk';

const RemoveSheetContent = ({
  removedSheets,
  joinedColumns,
}: {
  removedSheets: Sheet[];
  joinedColumns: JoinSheetColumn[];
}) => {
  const theme = useTheme();
  return (
    <div
      className={cx(
        'mb-4 space-y-3 bg-gray-50 px-3 py-4 text-left',
        css({
          '&&': theme.getJoinSheetTheme().removedSheet?.root,
        })
      )}
    >
      {removedSheets.map((sheet) => {
        return (
          <div>
            <span
              className={cx(
                'text-neutral-gray text-sm',
                css({
                  '&&': theme.getJoinSheetTheme().removedSheet?.sheetName,
                })
              )}
            >
              {sheet.getName()}
            </span>{' '}
            <span
              className={cx(
                'text-gray-970',
                css({
                  '&&': theme.getJoinSheetTheme().removedSheet?.column,
                })
              )}
            >
              /{' '}
              {joinedColumns
                .flatMap((column) => column.getJoinedSheetColumns())
                .filter((column) => column.getSheet().equal(sheet))
                .map((col) => col.getColumnKey())
                .join(' , ')}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RemoveSheetContent;
