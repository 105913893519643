import {
  DATATYPE,
  DataModel,
  SpreadSheetNavigate,
} from '@nuvo-importer/common/sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from '@nuvo-importer/common/core';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import { useContextCreateNewColumnModal } from './CreateNewColumnModalContext';
import { useLocation } from 'react-router-dom';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';

export const createNewColumnModalId = 'nuvo-create-new-column-modal';

export type FormValues = {
  columnName: string;
  columnType: DATATYPE;
  dateFormat?: string;
};

type UseViewModelProps = {
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const useViewModel = ({
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: UseViewModelProps) => {
  const { t } = useTranslation();
  const { sheetColumn, onSubmitCallback } = useContextCreateNewColumnModal();
  const { state: locationState } = useLocation();

  const dataModels = useMemo(() => {
    return dataModelSheetMatcher.getDataModels();
  }, [dataModelSheetMatcher]);

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
    dataModelSheetMatching?: DataModelSheetMatching;
    dataModelSheetMatcher?: DataModelSheetMatcher;
  };

  const onSubmit = (values: FormValues) => {
    if (!sheetColumn) {
      return;
    }

    const options = dataModelSheetMatcher.getDataModels().map((dataModel) => {
      return {
        baseKey: dataModel.getBaseKey(),
        baseKeyCounter: dataModel.getBaseKeyCounter(),
      };
    });

    const cleanedSearchValue = values.columnName?.trim() ?? '';
    const option = generateKey(cleanedSearchValue, options);

    const hasDescription = dataModelSheetMatcher
      .getDataModels()
      .some((entry) => entry.getDescription());

    const { key } = option;

    const newDataModel = new DataModel({
      columnSize: 1,
      description: hasDescription ? t('txt_custom_column_description') : '',
      example: '',
      key: key.value.toLowerCase(),
      label: cleanedSearchValue,
      type: values.columnType,
      creator: sheetColumn.getColumnKey(),
      baseKey: key.baseKey,
      baseKeyCounter: key.baseKeyCounter,
      outputFormat:
        values.columnType === DATATYPE.DATE ? values.dateFormat : undefined,
    });

    const oldDataModels =
      dataModelSheetMatcher?.getMatching().getDataModels() || [];
    const dataModels = [...oldDataModels, newDataModel];
    const spreadSheetList = state.spreadSheetNavigate?.getSpreadSheetList();

    const sheetColumnDataModelSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelSimilarityList();
    const sheetColumnDataModelOptionSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelOptionSimilarityList();

    const calculateSimilarityResult =
      dataModelSheetMatcher?.getCalculateSimilarityResult();
    if (
      dataModels &&
      spreadSheetList &&
      sheetColumnDataModelSimilarityList &&
      sheetColumnDataModelOptionSimilarityList &&
      calculateSimilarityResult
    ) {
      const newDataModelSheetMatcher = new DataModelSheetMatcher({
        sheetColumnDataModelSimilarityList,
        sheetColumnDataModelOptionSimilarityList,
        dataModels,
        sheets: spreadSheetList.getSelectedSheets(),
        calculateSimilarityResult,
      });

      setDataModelSheetMatcher(newDataModelSheetMatcher);
      setDataModelSheetMatching(newDataModelSheetMatcher.getMatching());
      onSubmitCallback.callback?.(newDataModel, newDataModelSheetMatcher);
    }
  };

  const initialValues = useMemo(() => {
    return {
      columnType: DATATYPE.STRING,
      columnName: sheetColumn?.getColumnKey() ?? '',
    };
  }, [sheetColumn]);

  return {
    onSubmit,
    initialValues,
    dataModels,
  };
};

export default useViewModel;
