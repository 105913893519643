import Handsontable from 'handsontable';
import FreezeStrategyStore from './FreezeStrategyStore';
import { ColumnSetting } from './AllColumnSetting';
import { isNil } from 'lodash';

class FreezeStrategy {
  static containerHasFreezeClassName = 'has-freeze';
  private hotInstance?: Handsontable;

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  initialFreezeColumns(columnSettings: ColumnSetting[]) {
    let cloneColumnSettings = [...columnSettings];
    const freezeCols = FreezeStrategyStore.getFreezeColumns();
    for (let index = 0; index < freezeCols.length; index++) {
      const key = freezeCols[index];
      const columnIndex = cloneColumnSettings.findIndex(
        (item) => item.id === key
      );
      cloneColumnSettings = this.swapColumnIndexFreezeCol(
        columnIndex,
        columnSettings,
        false
      );
    }
    return cloneColumnSettings;
  }

  freezeColumn(
    columnIndex: number,
    freeze: boolean,
    columnSettings: ColumnSetting[]
  ) {
    let cloneColumnSettings = [...columnSettings];
    const freezePlugin = this.hotInstance?.getPlugin('manualColumnFreeze');
    cloneColumnSettings[columnIndex].freeze = freeze;
    if (freeze) {
      // NOTE: swap index target column freeze to top stack
      cloneColumnSettings = this.swapColumnIndexFreezeCol(
        columnIndex,
        cloneColumnSettings
      );
      freezePlugin?.freezeColumn(columnIndex);
    } else {
      const freezeStore = FreezeStrategyStore.getFreezeColumns();
      const swapId = freezeStore[columnIndex];
      const lengthFreezeCol = freezeStore.length;
      FreezeStrategyStore.removeByIndex(columnIndex);
      freezePlugin?.unfreezeColumn(columnIndex);

      // NOTE: swap index target column unfreeze to original stack
      cloneColumnSettings = this.swapColumnIndexUnfreezeCol(columnSettings);
      const endIndexSwap = cloneColumnSettings.findIndex(
        (item) => item.id === swapId
      );

      if (lengthFreezeCol === 2) {
        if (columnIndex === 0) {
          this.moveColumn(1, endIndexSwap, true);
        } else {
          this.moveColumn(1, endIndexSwap);
        }
      } else {
        this.moveColumn(columnIndex, endIndexSwap);
      }
    }

    return cloneColumnSettings;
  }

  hasMaxFreezeColumn() {
    return FreezeStrategyStore.getFreezeColumns().length >= 2;
  }

  removeColumn = (columnIndex: number) => {
    FreezeStrategyStore.removeByIndex(columnIndex);
    const freezePlugin = this.hotInstance?.getPlugin('manualColumnFreeze');
    freezePlugin?.unfreezeColumn(columnIndex);
  };

  initializeMoveColumn = (columnSettings: ColumnSetting[]) => {
    if (!this.hotInstance) {
      return;
    }
    const freezeStore = FreezeStrategyStore.getFreezeColumns();

    for (let i = 0; i < freezeStore.length; ++i) {
      const swapId = freezeStore[i];
      const physicalIndex = columnSettings.find(
        (item) => item.id === swapId
      )?.refIndex;

      if (!isNil(physicalIndex)) {
        const visualIndex = this.hotInstance.toVisualColumn(physicalIndex);
        this.moveColumn(visualIndex, i);
      }
    }
  };

  private swapColumnIndexFreezeCol(
    columnIndex: number,
    columnSettings: ColumnSetting[],
    forceAddStore = true
  ) {
    const newColSetting: ColumnSetting[] = [];
    if (forceAddStore) {
      FreezeStrategyStore.add(columnSettings[columnIndex].id);
    }
    const freezeStore = FreezeStrategyStore.getFreezeColumns();
    for (let index = 0; index < freezeStore.length; index++) {
      const element = freezeStore[index];
      newColSetting.push(
        columnSettings.find((col) => col.id === element) as ColumnSetting
      );
    }

    for (let index = 0; index < columnSettings.length; index++) {
      const element = columnSettings[index];
      if (!element.freeze) {
        newColSetting.push(element);
      }
    }

    return newColSetting;
  }

  private swapColumnIndexUnfreezeCol(columnSettings: ColumnSetting[]) {
    const newColSetting: ColumnSetting[] = [];
    const freezeStore = FreezeStrategyStore.getFreezeColumns();
    for (let index = 0; index < freezeStore.length; index++) {
      const element = freezeStore[index];
      newColSetting.push(
        columnSettings.find((col) => col.id === element) as ColumnSetting
      );
    }

    const originalColSetting = columnSettings.sort(
      (a, b) => a.refIndex - b.refIndex
    );

    for (let index = 0; index < originalColSetting.length; index++) {
      const element = originalColSetting[index];
      const isFreeze = freezeStore.includes(element.id);
      if (!isFreeze || freezeStore.length === 0) {
        newColSetting.push(element);
      }
    }

    return newColSetting;
  }

  private moveColumn(start: number, end: number, forceRender = false) {
    this.hotInstance?.getPlugin('manualColumnMove')?.moveColumn(start, end);
    if (forceRender) this.hotInstance?.render();
  }
}

export default FreezeStrategy;
