import { useEffect, useMemo, useRef, useState } from 'react';
import { useContextJoinColumnsModalManager } from './context';

const useViewModel = () => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [isHasScroll, setIsHasScroll] = useState(false);

  const { setOpenModal, isOpen, sheetColumnsJoin } =
    useContextJoinColumnsModalManager();

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const sheetColumns = useMemo(() => {
    return sheetColumnsJoin;
  }, [sheetColumnsJoin]);

  const sheetColumnsSize = sheetColumns.length;

  const handleHasScroll = () => {
    const scrollWidth = tableWrapperRef.current?.scrollWidth ?? 0;
    const offsetWidth = tableWrapperRef.current?.offsetWidth ?? 0;

    if (scrollWidth > offsetWidth) {
      setIsHasScroll(true);
    } else {
      setIsHasScroll(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleHasScroll();
    }, 0);

    function onResizeScreen() {
      handleHasScroll();
    }

    window.addEventListener('resize', onResizeScreen);

    return () => {
      window.removeEventListener('resize', onResizeScreen);
    };
  }, [tableWrapperRef, isOpen]);

  return {
    onCloseModal,
    isOpen,
    sheetColumns,
    sheetColumnsSize,
    isHasScroll,
    tableWrapperRef,
  };
};

export default useViewModel;
