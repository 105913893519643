import {
  PreviewConfig,
  usePreviewConfig,
} from '../../../../context/PreviewConfigProvider';
import { IConfigureForm, ITargetDataModel } from '../../types';
import { useCallback, useMemo, useState } from 'react';
import { findTDM, parseJSON } from '../../utils';
import { FieldErrors, useForm } from 'react-hook-form';
import { ConfigureAPI } from '../../services';
import { Toast } from '@getnuvo/ui-kit';

interface IProps {
  onDismiss: () => void;
}

export const useViewModel = ({ onDismiss }: IProps) => {
  const { configure, setConfigure, dataModels } = usePreviewConfig();

  const defaultValues: IConfigureForm = useMemo(() => {
    const tdm: ITargetDataModel | undefined = findTDM(
      dataModels,
      configure.targetDataModel
    );

    const style = tdm?.style ? tdm.style : configure.style;
    const preloadData =
      tdm?.dataHooks?.preloadData || configure.preloadData || '';

    return {
      ...configure,
      targetDataModel: configure.targetDataModel || '',
      completeImportAction: configure.completeImportAction || '',
      buttonMode: configure.buttonMode || '',
      language: configure.language || '',
      processingEngine: configure.processingEngine || '',
      columnHook: tdm?.dataHooks?.columnHooks || configure?.columnHook || '',
      entryInitHook:
        tdm?.dataHooks?.onEntryInit || configure?.entryInitHook || '',
      entryChangeHook:
        tdm?.dataHooks?.onEntryChange || configure?.entryChangeHook || '',
      resultHook: tdm?.dataHooks?.onResult || configure?.resultHook || '',
      cancelHook: tdm?.dataHooks?.onCancel || configure?.cancelHook || '',
      targetDataModelId: tdm?._id,
      dataHandler: tdm?.dataHooks?.dataHandler || configure?.dataHandler || '',
      preloadData: JSON.stringify(parseJSON(preloadData, []), null, 2),
      style: JSON.stringify(parseJSON(style, {}), null, 2),
    };
  }, [configure, dataModels]);

  const form = useForm<IConfigureForm>({
    defaultValues,
  });

  const [processing, setProcessing] = useState<boolean>(false);

  const { handleSubmit, reset, getValues } = form;

  const onSubmit = handleSubmit(
    (data: IConfigureForm) => {
      setProcessing(true);
      const nextConfig = data as unknown as PreviewConfig;

      ConfigureAPI.updateConfiguration(nextConfig)
        .then(() => {
          setConfigure(nextConfig);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          Toast.error({ title: 'Could not save the configuration' });
          setProcessing(false);
        });
    },
    (errors: FieldErrors<IConfigureForm>) => {
      console.log(errors);
    }
  );

  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const onOpenResetDialog = useCallback(() => {
    setResetDialog(true);
  }, [setResetDialog]);

  const onDismissResetDialog = useCallback(() => {
    setResetDialog(false);
  }, [setResetDialog]);

  const [discardDialog, setDiscardDialog] = useState<boolean>(false);

  const onOpenDiscardDialog = useCallback(() => {
    setDiscardDialog(true);
  }, [setDiscardDialog]);

  const onDismissDiscardDialog = useCallback(() => {
    setDiscardDialog(false);
  }, [setDiscardDialog]);

  const handleDismissAndReset = useCallback(() => {
    reset(defaultValues);
    onDismissDiscardDialog();
    onDismiss();
  }, [reset, defaultValues, onDismissDiscardDialog, onDismiss]);

  const handleDismiss = useCallback(() => {
    const values: IConfigureForm = getValues();
    const hasChanges: boolean =
      JSON.stringify(values) !== JSON.stringify(defaultValues);

    if (hasChanges) {
      onOpenDiscardDialog();
    } else {
      handleDismissAndReset();
    }
  }, [getValues, defaultValues, onOpenDiscardDialog, handleDismissAndReset]);

  return {
    form,
    onOpenResetDialog,
    onSubmit,
    processing,
    resetDialog,
    onDismissResetDialog,
    discardDialog,
    onDismissDiscardDialog,
    handleDismissAndReset,
    handleDismiss,
  };
};
