import { css } from '../../core/emotion';

export type Size = 'small' | 'tiny' | 'normal';

type SizeClassname = () => string;

const tinyClassname: SizeClassname = () => {
  return `h-6 px-8 border-2 ${css`
    font-size: 11px;
  `}`;
};

const smallClassname: SizeClassname = () => {
  return `h-6 px-2 text-xss border-1 ${css`
    font-size: 11px;
  `} border-1`;
};

const normalClassname: SizeClassname = () => {
  return 'px-8 border-2';
};

export const sizeClassname = ({ size }: { size: Size }) => {
  switch (size) {
    case 'tiny':
      return tinyClassname();
    case 'small':
      return smallClassname();
    case 'normal':
      return normalClassname();
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`size is not available: ${size}`);
      }
      return '';
  }
};
