/* istanbul ignore file */

import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import LoadingPopup, { LoadingPopupProps } from './index';

type LoadingPopupManagerProps = {
  children?: ReactNode;
};

type ContextType = {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  showLoadingPopup: (options: LoadingPopupProps) => void;
  isOpenModal: MutableRefObject<boolean>;
};

const LoadingPopupManagerContext = createContext({} as ContextType);

const LoadingPopupManager: FC<LoadingPopupManagerProps> = ({ children }) => {
  const [isOpen, setOpenModal] = useState(false);
  const isOpenModal = useRef(false);
  const [options, setOptions] = useState<LoadingPopupProps>();

  const showLoadingPopup = useCallback((options: LoadingPopupProps) => {
    setOptions(options);
    setOpenModal(true);
    isOpenModal.current = true;
  }, []);

  useEffect(() => {
    isOpenModal.current = isOpen;
    if (!isOpen) {
      setOptions(undefined);
    }
  }, [isOpen]);

  return (
    <LoadingPopupManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        showLoadingPopup,
        isOpenModal,
      }}
    >
      {children}
      {options && <LoadingPopup isShowDialog={options.isShowDialog} />}
    </LoadingPopupManagerContext.Provider>
  );
};

export const useContextLoadingPopupManager = () => {
  return useContext(LoadingPopupManagerContext);
};

export default LoadingPopupManager;
