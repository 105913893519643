import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { DropdownOptionType } from '../../core/dataModel/columnsAPI';
import { useTranslation } from 'react-i18next';
import CategoryDataModel from '../../core/dataModel/model/CategoryDataModel';
import useValidationSchema from '../../core/form/useValidationSchema';

export const createNewOptionModalId = 'nuvo-create-new-option-modal';

export type FormValues = {
  optionName: string;
  optionType: DropdownOptionType;
};

type UseViewModelProps = {
  isOpen: boolean;
  dataModel?: CategoryDataModel | null;
  onSubmit: (values: FormValues) => void;
  close: () => void;
};

const useViewModel = ({
  isOpen,
  dataModel,
  onSubmit,
  close,
}: UseViewModelProps) => {
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [hasSubmit, setHasSubmit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      setHasSubmit(false);
    }
  }, [isOpen]);

  const existingOptions = useMemo(() => {
    return dataModel?.getOptions() ?? [];
  }, [dataModel]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        setModalElement(
          (document.getElementById(createNewOptionModalId) as HTMLDivElement) ??
            null
        );
      } else {
        setModalElement(null);
      }
    }, 0);
  }, [isOpen]);

  const dropdownOptions = useMemo(() => {
    return [
      {
        label: t('txt_data_type_string'),
        value: 'string',
      },
      {
        label: t('txt_data_type_int'),
        value: 'int',
      },
      {
        label: t('txt_data_type_float'),
        value: 'float',
      },
    ];
  }, [t]);

  const validateSchema = useMemo(() => {
    return yup.object({
      optionName: yup
        .string()
        .test(
          'duplicate_label',
          t('txt_field_invalid_dup_option_label'),
          (value?: string) => {
            return existingOptions.every(
              (item) => `${item.label}`.trim() !== value?.trim()
            );
          }
        )
        .when('optionType', {
          is: 'string',
          then: yup.string().required(t('txt_require_field')),
        })
        .when('optionType', {
          is: 'int',
          then: yup
            .string()
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number_int'),
              (value?: string) => {
                return /^[0-9]*$/.test(value ?? '');
              }
            )
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number'),
              (value?: string) => {
                return /^[0-9]{1,}([,.][0-9]{1,})?$/.test(value ?? '');
              }
            )
            .required(t('txt_require_field')),
        })
        .when('optionType', {
          is: 'float',
          then: yup
            .string()
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number_float'),
              (value?: string) => {
                return /^[0-9]{1,}([,.][0-9]{1,})?$/.test(value ?? '');
              }
            )
            .required(t('txt_require_field')),
        }),
      optionType: yup.string().required(t('txt_require_field')),
    });
  }, [t, existingOptions]);

  const validate = useValidationSchema(validateSchema);

  const handleDropdownChange = useCallback(
    (values: FormValues, change) => (value: string | null) => {
      if (values.optionType === 'float' && value === 'int') {
        if (values.optionName.includes('.')) {
          change('optionName', '');
        }
      }

      if (values.optionType === 'string' && value === 'int') {
        const int = parseInt(values.optionName);
        if (int.toString() !== values.optionName) {
          change('optionName', '');
        }
      }

      if (values.optionType === 'string' && value === 'float') {
        const float = parseFloat(values.optionName);
        if (float.toString() !== values.optionName) {
          change('optionName', '');
        }
      }
    },
    []
  );

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
    setHasSubmit(true);
    close();
  };

  return {
    modalElement,
    dropdownOptions,
    validate,
    hasSubmit,
    inputRef,
    handleDropdownChange,
    handleSubmit,
  };
};

export default useViewModel;
