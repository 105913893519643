import { RefObject, useMemo } from 'react';
import TruncatePopover from 'baseUI/TruncatePopover';
import { CategoryDataModel } from 'dataModel';
import OptionMatcher from './OptionMatcher';
import { MatchedOption } from '../../../../../matching/DataModelSheetMatching';
import { cx, css } from 'core/emotion';
import ValueFormatter from 'core/valueFormatter/ValueFormatter';
import { ReactComponent as RightCol } from '../../../../../assets/icon/right-col.svg';
import { getIsEmpty } from '../../common/emptyState';
import EmptyText from '../../common/EmptyRows/EmptyText';
import { breakpoints } from 'core/constants/breakpoints';
import { BaseMatcherOptions } from '../types';
import { useTheme } from 'theme';

export type OptionItemMatcherProps = {
  name: string;
  matchedDataModel: CategoryDataModel;
  index: number;
  isLastRowIndex: boolean;
  prefixName: string;
  matchedOption: MatchedOption;
  hasScrollbar: boolean;
  baseMatcherOptions: BaseMatcherOptions;
  configTheme?: {
    root?: string;
    borderColor?: string;
    color?: string;
    emptyValue?: string;
    icon?: string;
    configThemeMenuButton?: {
      root?: string;
      closeIcon?: string;
      arrowIcon?: string;
      placeholder?: string;
    };
    configThemeMenuItem?: {
      root?: string;
      scrollbarNavigatorColor?: string;
      scrollbarBackgroundColor?: string;
      option?: string;
      selectedOption?: string;
      header?: string;
      search?: {
        root?: string;
        icon?: string;
        placeholder?: string;
      };
      createNewOption?: {
        root?: string;
        icon?: string;
      };
      multiSelectionBadge?: {
        root?: string;
        icon?: string;
      };
    };
  };
  renderInVirtual?: boolean;
};

const style = css`
  grid-template-columns: 1fr 56px 1fr;
  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 56px 1fr;
  }
`;

const OptionItemMatcher = ({
  name,
  matchedDataModel,
  index,
  isLastRowIndex,
  prefixName,
  matchedOption,
  configTheme,
  hasScrollbar,
  baseMatcherOptions,
  renderInVirtual,
}: OptionItemMatcherProps) => {
  const isEmpty = useMemo(() => {
    return getIsEmpty(matchedOption.sheetOption);
  }, [matchedOption.sheetOption]);

  const theme = useTheme();
  const colBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getLight100Color();
  }, [theme]);

  return (
    <div
      className={cx(
        ' border-1 grid h-12 bg-white',
        css({
          borderColor: colBorderColor,
        }),
        isLastRowIndex ? 'rounded-b-medium' : 'border-b-0',
        style,
        configTheme?.root,
        configTheme?.borderColor,
        hasScrollbar ? 'pr-3' : 'pr-4'
      )}
    >
      <div className="flex">
        <div
          className={cx(
            'border-r-1 xssmd:w-14 flex w-9 flex-shrink-0 items-center justify-center',
            css({
              borderColor: colBorderColor,
            }),
            configTheme?.borderColor
          )}
        >
          <span
            className={cx('text-color-primary text-sm', configTheme?.color)}
          >
            {index + 1}
          </span>
        </div>
        <div className="xssmd:pl-4 flex items-center pl-2">
          {isEmpty ? (
            <EmptyText className={configTheme?.emptyValue} />
          ) : (
            <TruncatePopover
              isCheckLineClamp
              render={({ referenceElement, textRef, getHover }) => {
                return (
                  <div
                    ref={referenceElement as RefObject<HTMLDivElement>}
                    {...getHover()}
                  >
                    <p
                      ref={textRef as RefObject<HTMLParagraphElement>}
                      className={cx(
                        'text-color-primary line-clamp-2 break-all text-left text-sm',
                        configTheme?.color
                      )}
                    >
                      {ValueFormatter.format(matchedOption.sheetOption)}
                    </p>
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center px-4">
        <RightCol className={cx('flex-shrink-0', configTheme?.icon)} />
      </div>
      <div className={cx('flex items-center justify-end')}>
        <OptionMatcher
          name={name}
          dataModel={matchedDataModel}
          sheetColumnOption={matchedOption.sheetOption}
          prefixName={prefixName}
          index={index}
          configThemeMenuButton={configTheme?.configThemeMenuButton}
          configThemeMenuItem={configTheme?.configThemeMenuItem}
          hasScrollbar={hasScrollbar}
          baseMatcherOptions={baseMatcherOptions}
          renderInVirtual={renderInVirtual}
        />
      </div>
    </div>
  );
};

export default OptionItemMatcher;
