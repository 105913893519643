import { DataModel } from 'dataModel';
import { useTranslation } from 'react-i18next';
import { Check, DataModelDescription, Text, Wrapper } from './index.view';

const ManualMatchedText = ({
  matchedDataModel,
  configThemePopoverInfoIcon,
}: {
  matchedDataModel: DataModel;
  configThemePopoverInfoIcon?: string;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Check />
      <Text>
        {t('txt_match_manual')}{' '}
        <span className="break-all font-semibold uppercase">
          {matchedDataModel.getLabel()}
        </span>
      </Text>
      {matchedDataModel.getDescription() ? (
        <DataModelDescription
          flip={true}
          configThemePopoverInfoIcon={configThemePopoverInfoIcon}
          matchedDataModel={matchedDataModel}
        />
      ) : null}
    </Wrapper>
  );
};

export default ManualMatchedText;
