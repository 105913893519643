import type {
  ModifierArguments,
  SideObject,
  Rect,
  Offsets,
  Padding,
  Boundary,
} from '@popperjs/core';
import { detectOverflow } from '@popperjs/core';

function getSideOffsets(
  overflow: SideObject,
  rect: Rect,
  preventedOffsets: Offsets = { x: 0, y: 0 }
): SideObject {
  return {
    top: overflow.top - rect.height - preventedOffsets.y,
    right: overflow.right - rect.width + preventedOffsets.x,
    bottom: overflow.bottom - rect.height + preventedOffsets.y,
    left: overflow.left - rect.width - preventedOffsets.x,
  };
}

function isAnySideFullyClipped(overflow: SideObject): boolean {
  return (['right', 'left'] as const).some((side) => overflow[side] >= 0);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hideFn = ({
  state,
  name,
  options,
}: ModifierArguments<{
  padding: Padding;
  boundary?: Boundary;
}>) => {
  const referenceRect = state.rects.reference;
  const popperRect = state.rects.popper;
  const preventedOffsets = state.modifiersData.preventOverflow;

  const referenceOverflow = detectOverflow(state, {
    elementContext: 'reference',
    padding: options.padding,
    boundary: options.boundary,
  });

  const popperAltOverflow = detectOverflow(state, {
    altBoundary: true,
  });

  const referenceClippingOffsets = getSideOffsets(
    referenceOverflow,
    referenceRect
  );
  const popperEscapeOffsets = getSideOffsets(
    popperAltOverflow,
    popperRect,
    preventedOffsets
  );

  const isReferenceHidden = isAnySideFullyClipped(referenceClippingOffsets);
  const hasPopperEscaped = isAnySideFullyClipped(popperEscapeOffsets);

  state.modifiersData[name] = {
    referenceClippingOffsets,
    popperEscapeOffsets,
    isReferenceHidden,
    hasPopperEscaped,
  };

  if (state.rects['reference'].width !== 0) {
    state.attributes.popper = {
      ...state.attributes.popper,
      'data-popper-reference-hidden': isReferenceHidden,
      'data-popper-escaped': hasPopperEscaped,
    };
  }
};
