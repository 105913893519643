import { cx } from 'core/emotion';
import { useMainView } from '../../settings/MainView';
import { ActionFooter, Header } from 'navigation';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@nuvo-importer/common';
import useViewModel from './viewModel';
import JoinSheetSelection from './components/SheetSelection';
import useJoinSheet from './joinSheet';
import JoinSheetModal from './components/JoinSheetModal';
import JoinSheetPreviewTable from './components/JoinSheetPreviewTable';
import './index.css';

const JoinColumnPage = () => {
  const { modal } = useMainView();
  const { t } = useTranslation();
  const theme = useTheme();
  const pageHeaderTheme = theme.getJoinSheetTheme().pageHeader;
  const wrapperClassName = modal ? 'absolute' : 'fixed';
  const widthWrapperClassName = modal ? 'w-full' : 'px-0';
  const {
    onJoinClick,
    close,
    isOpen,
    unSelectedSheets,
    joinTargetSheet,
    sourceSheets,
    onRemoveClick,
    onAddFirstSheet,
    sourceSheet,
    loading,
    disabledContinue,
    onAppendClick,
    appendTargetSheet,
    onAddToAllRows,
    handleJoin,
    handleAppendCol,
  } = useJoinSheet();
  const { onBackClick, onContinueClick } = useViewModel({
    unSelectedSheets,
    sourceSheet,
  });

  return (
    <div
      className={cx(
        'flex flex-col text-left',
        modal ? 'h-full' : '',
        css({
          '&&': theme.getJoinSheetTheme().root,
        })
      )}
    >
      <Header
        title={t('txt_join_sheets')}
        description={t('txt_join_sheet_desc')}
        className="flex-shrink-0"
        headerTheme={pageHeaderTheme}
      />
      <div
        className={cx(
          'mb-4 flex flex-col space-y-6',
          modal ? 'min-h-0 flex-shrink flex-grow' : 'px-6',
          css`
            min-height: 320px;
          `,
          css({
            '&&': theme.getJoinSheetTheme().content,
          }),
          css`
            .nuvo-join-sheet-list-scroll
              .simplebar-track.simplebar-vertical
              .simplebar-scrollbar:before {
              background-color: ${theme.getJoinSheetTheme().sheetSelection
                ?.scrollbar?.navigatorColor};
            }
            .nuvo-join-sheet-list-scroll .simplebar-track {
              background-color: ${theme.getJoinSheetTheme().sheetSelection
                ?.scrollbar?.backgroundColor};
            }
          `
        )}
      >
        <div className="flex min-h-0 flex-shrink flex-grow">
          <JoinSheetSelection
            unSelectedSheets={unSelectedSheets}
            onJoinClick={onJoinClick}
            onAppendClick={onAppendClick}
            onRemoveClick={onRemoveClick}
            onAddFirstSheet={onAddFirstSheet}
            onAddToAllRows={onAddToAllRows}
            sourceSheets={sourceSheets}
            loading={loading}
          />
          <JoinSheetPreviewTable sheet={sourceSheet} loading={loading} />
        </div>
      </div>
      <JoinSheetModal
        joinTargetSheet={joinTargetSheet}
        sourceSheet={sourceSheet.state}
        close={close}
        isOpen={isOpen}
        handleJoin={handleJoin}
        handleAppendCol={handleAppendCol}
        appendTargetSheet={appendTargetSheet}
      />
      <ActionFooter
        footerStyle="px-8"
        className={cx(
          'bottom-0 left-0 right-0 z-10 flex flex-col bg-white',
          wrapperClassName,
          widthWrapperClassName,
          css`
            filter: drop-shadow(0px -13px 50px rgba(17, 48, 68, 0.08));
          `
        )}
        positiveButtonText={t('txt_continue')}
        onPositiveClick={onContinueClick}
        onNegativeClick={onBackClick}
        disabledPositive={disabledContinue}
      />
    </div>
  );
};

export default JoinColumnPage;
