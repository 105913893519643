import { cx } from '@nuvo-importer/common';
import TruncatePopover from 'baseUI/TruncatePopover';
import { ReactNode, RefObject } from 'react';

const Label = ({
  children,
  required = true,
  className,
  asteriskClassName,
}: {
  children: ReactNode;
  required?: boolean;
  className?: string;
  asteriskClassName?: string;
}) => {
  return (
    <TruncatePopover
      render={({ referenceElement, textRef, getHover }) => {
        return (
          <p
            ref={referenceElement as RefObject<HTMLParagraphElement>}
            className={cx('flex text-sm text-gray-700', className)}
            {...getHover()}
          >
            <span className="truncate" ref={textRef}>
              {children}
            </span>
            {required ? (
              <span className={cx('text-red-510 ml-0.5', asteriskClassName)}>
                *
              </span>
            ) : null}
          </p>
        );
      }}
    />
  );
};

export default Label;
