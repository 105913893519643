import { cx, css } from 'core/emotion';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useScreenSize } from 'core/constants/screensSize';
import { useWarnSmallScreen } from 'hooks';
import { useLayoutEffect } from 'react';
import { useSettings } from 'settings';
import { useTheme } from 'theme';
import { useMeasure } from 'react-use';
import Router from './Router';
import { NUVO_ELEMENT_ID } from 'core/constants/element';

const PageView = () => {
  const theme = useTheme();
  const { warnSmallScreen } = useWarnSmallScreen();
  const { isNotSmallScreen } = useScreenSize();
  const { setOpenModal } = useContextConfirmModalManager();
  const { modal } = useSettings();
  const [ref] = useMeasure<HTMLDivElement>();

  useLayoutEffect(() => {
    if (modal) {
      return () => {};
    }

    if (!isNotSmallScreen) {
      warnSmallScreen();
      return;
    } else {
      setOpenModal(false);
    }

    return () => {};
  }, [warnSmallScreen, isNotSmallScreen, setOpenModal, modal]);

  return (
    <div
      id={NUVO_ELEMENT_ID.PAGE_ROOT}
      ref={ref}
      className={cx(
        'max-w-screen-3xl w-full',
        css`
          height: auto;
          background-color: ${theme.getGlobalTheme().getBackgroundColor()};
        `
      )}
    >
      <div
        className={cx(
          'relative',
          'w-full py-6',
          css`
            background-color: ${theme.getGlobalTheme().getBackgroundColor()};
          `,
          css({ '&&': theme.getRootTheme().container })
        )}
      >
        <Router />
      </div>
    </div>
  );
};
export default PageView;
