import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      margin: -12px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.suggestionList?.root),
    }),
  ]);

  const headerClass = cx([
    'bg-blue-light-50',
    css`
      display: flex;
      align-items: center;
      padding: 6px 12px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.suggestionList?.header),
    }),
  ]);

  const headerCheckboxClass = cx([
    css({
      '&&': css(cleaningAssistantTheme.suggestionList?.headerCheckbox),
    }),
  ]);

  const headerCheckboxLabelClass = cx(
    ['text-small text-blue-dark-900'],
    css({
      '&&': css(cleaningAssistantTheme.suggestionList?.headerCheckboxLabel),
    })
  );

  return {
    rootClass,
    headerClass,
    headerCheckboxClass,
    headerCheckboxLabelClass,
  };
};
