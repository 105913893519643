import { useMemo } from 'react';
import { COLOR_JOIN_SHEET } from 'core/constants/colors';
import { JoinSheetMatchStatus } from '@nuvo-importer/common/sdk';

type viewModelProps = {
  percent: number;
  configTheme?: JoinSheetMatchStatus;
};
const useViewModel = ({ percent, configTheme }: viewModelProps) => {
  const styled = useMemo(() => {
    if (percent === 100) {
      return {
        root: configTheme?.success?.root,
        icon: configTheme?.success?.icon || {
          color: COLOR_JOIN_SHEET.SUCCESS_TEXT,
        },
        percent: configTheme?.success?.percent || {
          color: COLOR_JOIN_SHEET.SUCCESS_TEXT,
        },
        background: COLOR_JOIN_SHEET.SUCCESS_BG,
        color: COLOR_JOIN_SHEET.SUCCESS_TEXT,
        description: configTheme?.success?.description,
      };
    } else {
      return {
        root: configTheme?.warning?.root,
        icon: configTheme?.warning?.icon || {
          color: COLOR_JOIN_SHEET.WARNING_TEXT,
        },
        percent: configTheme?.warning?.percent || {
          color: COLOR_JOIN_SHEET.WARNING_TEXT,
        },
        background: COLOR_JOIN_SHEET.WARNING_BG,
        color: COLOR_JOIN_SHEET.WARNING_TEXT,
        description: configTheme?.warning?.description,
      };
    }
  }, [configTheme, percent]);

  return {
    styled,
  };
};
export default useViewModel;
