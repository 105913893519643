import {
  CSSProperties,
  ReactNode,
  forwardRef,
  MouseEvent,
  useMemo,
} from 'react';
import { css, cx } from '@nuvo-importer/common';
import { SheetItemThemeAPI } from '@nuvo-importer/common/sdk';

export type IMenuItem = {
  label: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export type IMenuItems = IMenuItem[];

type Popper = {
  styles: {
    [key: string]: CSSProperties;
  };
  attributes: {
    [key: string]:
      | {
          [key: string]: string;
        }
      | undefined;
  };
};

type IMenuProps = {
  popper: Popper;
  menus: IMenuItems;
  onMenuClick?: () => void;
  menuTheme?: SheetItemThemeAPI['addMenu'];
};

const Menu = forwardRef<HTMLDivElement, IMenuProps>(
  ({ popper, menus, onMenuClick, menuTheme }, ref) => {
    const menuItemClass = useMemo(() => {
      return css({
        '&&': menuTheme?.item,
      });
    }, [menuTheme]);

    return (
      <div
        ref={ref}
        style={popper.styles.popper}
        {...popper.attributes.popper}
        className={cx(
          'rounded-medium bg-white',
          css`
            min-width: 160px;
            box-shadow: 0px 4px 16px 0px rgba(27, 80, 113, 0.08);
          `,
          css({ '&&': menuTheme?.root })
        )}
      >
        {menus.map(({ label, onClick, disabled }) => {
          return (
            <MenuItem
              key={label}
              onClick={(event) => {
                onClick(event);
                onMenuClick?.();
              }}
              disabled={disabled}
              className={menuItemClass}
            >
              {label}
            </MenuItem>
          );
        })}
      </div>
    );
  }
);

type IMenuItemProps = {
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
};

const MenuItem = ({
  children,
  onClick,
  disabled,
  className,
}: IMenuItemProps) => {
  return (
    <button
      className={cx(
        'text-neutral-gray disabled:text-gray-260 block w-full cursor-pointer py-2 pl-4 pr-3 text-left text-sm hover:bg-gray-50 disabled:cursor-not-allowed',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};

export default Menu;
