import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useContextCircleLoadingModalManager } from 'baseUI/Modal/CircleLoading/context';
import { useScreenSize } from 'core/constants/screensSize';
import { usePage } from 'main/MainView';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainView, useSettings } from 'settings';
import {
  setSelectingSpreadSheetIndex,
  setSelectingSpreadSheetPageIndex,
} from '../SheetSelectionPage/common/utils';

const useViewModel = () => {
  const { t } = useTranslation();
  const { embedUploadArea, disableTemplates, disableExcelTemplate, title } =
    useSettings();
  const { setProcessing, isProcessing } = useContextCircleLoadingModalManager();
  const { closePage, timeUpload } = usePage();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { isXlargeScreen } = useScreenSize();
  const { modal } = useMainView();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSelectingSpreadSheetIndex(0);
    setSelectingSpreadSheetPageIndex(0);
  }, []);

  const onBackClick = () => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_confirm_title'),
      description: t('txt_back_page_dialog'),
      textNegativeButton: t('txt_go_back'),
      textPositiveButton: t('txt_cancel'),
      onClickNegativeButton: () => {
        closePage();
      },
    });
  };

  const styleWrapper = useMemo(() => {
    if (modal) {
      return {};
    } else {
      return {
        maxHeight: isXlargeScreen ? 'calc(100vh - 308px)' : '100%',
        minHeight: isXlargeScreen ? 'calc(100vh - 308px)' : '100%',
      };
    }
  }, [isXlargeScreen, modal]);

  return {
    onBackClick,
    disableTemplates: disableTemplates ?? false,
    embedUploadArea: embedUploadArea ?? false,
    setProcessing,
    isProcessing,
    title,
    styleWrapper,
    timeUpload,
    disableExcelTemplate: disableExcelTemplate ?? false,
  };
};

export default useViewModel;
