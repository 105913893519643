import { elementId } from '../../DataModelSheetForm/constant';
import {
  arrowStyled,
  levelInfoClassname,
  wrapperClassname,
} from './levelInfoStyled';
import { FieldMeta, PopoverThemeProps } from '../../DataModelSheetForm/type';
import {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { StackedMessagePopper } from './stackedMessagePopper';
import { css, CSSObject, cx } from '../../../emotion';
import { COLOR } from '../../../constants/colors';
import { LEVEL } from '../../../level';
import { useTheme } from '../../../../theme';

type StackedMessageViewProps = {
  messagesRef: MutableRefObject<FieldMeta[]>;
  configTheme?: PopoverThemeProps;
  stackedMessagePopper: StackedMessagePopper;
  modal?: boolean;
};

const StackedMessageView = ({
  messagesRef,
  configTheme,
  stackedMessagePopper,
  modal,
}: StackedMessageViewProps) => {
  const [messages, setMessage] = useState<FieldMeta[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      setMessage(messagesRef.current);
    }, 0);

    return () => {
      clearTimeout(interval);
    };
  }, [messagesRef, stackedMessagePopper]);

  useLayoutEffect(() => {
    stackedMessagePopper.tryUpdate();
  }, [messages, stackedMessagePopper]);

  const hasMultipleMessage = messages.length > 1;

  containerRef.current = !containerRef.current
    ? document.querySelector('#review-entries-table .ht_master')
    : containerRef.current;

  const wrapperStyle = useMemo(() => {
    return wrapperClassname({
      level: messages?.[0]?.level,
      configTheme,
      hasMultipleMessage,
      theme,
    });
  }, [theme, hasMultipleMessage, configTheme, messages]);

  if (!containerRef.current) {
    return null;
  }

  return createPortal(
    <div
      id={elementId.popoverElementId.parentElementId}
      className={cx(
        wrapperStyle,
        'h-0',
        css`
          [data-popper-placement^='top'] > #arrow-message-info {
            bottom: -7px;
            left: -6px !important;
          }

          [data-popper-placement^='bottom'] > #arrow-message-info {
            top: -7px;
            left: -6px !important;
          }

          [data-popper-placement^='left'] > #arrow-message-info {
            right: ${modal ? '0px' : '-7px'};
          }

          [data-popper-placement^='right'] > #arrow-message-info {
            left: ${modal ? '-14px' : '-7px'};
            top: calc(50% - 8px);
          }

          [data-popper-placement^='top'] > #arrow-message-info {
            ::before {
              border-top: none;
              border-left: none;
            }
          }

          [data-popper-placement^='left'] > #arrow-message-info {
            ::before {
              border-left: none;
              border-bottom: none;
            }
          }

          [data-popper-placement^='right'] > #arrow-message-info {
            ::before {
              border-top: none;
              border-right: none;
            }
          }
        `
      )}
    >
      <div
        id={elementId.cellPopperElementId.root}
        className="default-popper nuvo-invisible opacity-0"
      >
        {messages?.map((item, index) => {
          return (
            <div
              key={item.message + item.level + index}
              className="w-fit flex items-start text-left text-xs"
              style={{
                maxWidth: 300,
              }}
            >
              {hasMultipleMessage && (
                <div
                  className={levelInfoClassname({
                    level: item.level,
                    configTheme,
                    theme,
                  })}
                />
              )}
              <div
                className={cx(
                  hasMultipleMessage && 'ml-2',
                  hasMultipleMessage &&
                    css({
                      '&&&': { backgroundColor: 'transparent' },
                    }),
                  item.level === LEVEL.INFO &&
                    css({ '&&': configTheme?.info?.root }),
                  item.level === LEVEL.WARNING &&
                    css({ '&&': configTheme?.warning?.root }),
                  item.level === LEVEL.ERROR &&
                    css({ '&&': configTheme?.error?.root })
                )}
              >
                {item.message}
              </div>
            </div>
          );
        })}
        <div
          id="arrow-message-info"
          data-popper-arrow="true"
          className={cx(
            css`
              visibility: hidden;
              background: transparent;
              z-index: 5;

              ::before {
                background: ${arrowStyled({
                  level: messages?.[0]?.level,
                  configTheme,
                  hasMultipleMessage,
                  theme,
                })};
                position: absolute;
                width: 13px;
                height: 13px;
                visibility: visible;
                content: '';
                transform: rotate(45deg);
              }
            `,
            hasMultipleMessage
              ? css`
                  &::before {
                    ${configTheme?.root}
                    border: 1px solid ${(configTheme?.root as CSSObject)
                      ?.borderColor ?? COLOR.DARK_BLUE};
                    border-style: ${(configTheme?.root as CSSObject)
                      ?.borderStyle};
                  }
                `
              : '',
            !hasMultipleMessage && messages?.[0]?.level === LEVEL.INFO
              ? css`
                  &::before {
                    ${configTheme?.info?.root}
                    border: 1px solid ${(configTheme?.info?.root as CSSObject)
                      ?.borderColor ??
                    theme.getGlobalTheme().getLight400Color()};
                    border-style: ${(configTheme?.info?.root as CSSObject)
                      ?.borderStyle};
                  }
                `
              : '',
            !hasMultipleMessage && messages?.[0]?.level === LEVEL.WARNING
              ? css`
                  &::before {
                    ${configTheme?.warning?.root}
                    border: 1px solid ${(
                      configTheme?.warning?.root as CSSObject
                    )?.borderColor ?? COLOR.WARNING};
                    border-style: ${(configTheme?.warning?.root as CSSObject)
                      ?.borderStyle};
                  }
                `
              : '',
            !hasMultipleMessage && messages?.[0]?.level === LEVEL.ERROR
              ? css`
                  &::before {
                    ${configTheme?.error?.root}
                    border: 1px solid ${(configTheme?.error?.root as CSSObject)
                      ?.borderColor ?? COLOR.ERROR};
                    border-style: ${(configTheme?.error?.root as CSSObject)
                      ?.borderStyle};
                  }
                `
              : ''
          )}
        ></div>
      </div>
    </div>,
    containerRef.current as HTMLDivElement
  );
};

export default StackedMessageView;
