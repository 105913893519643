import { isFirefox } from 'react-device-detect';
import { css, CSSInterpolation, cx } from '../../core/emotion';
import HotTable from '@handsontable/react';
import WrapperStyled from './WrapperStyled';
import useViewModel from './viewModel';
import Sheet from '../../core/sheet/Sheet';
import { CSSProperties, MutableRefObject } from 'react';

const HeaderSelection = ({
  sheet,
  headerRowIndexRef,
  onHeaderRowIndexChange,
  configTheme,
  wrapperStyledDivClassName,
  wrapperStyledClassName,
  styleTable,
  htLicenseKey,
  readOnly = false,
}: {
  sheet: Sheet;
  headerRowIndexRef: MutableRefObject<number>;
  onHeaderRowIndexChange: (rowIndex: number) => void;
  wrapperStyled?: CSSInterpolation;
  configTheme?: {
    root: CSSInterpolation;
    content: CSSInterpolation;
    selectRowColor: string;
    hoverRowColor: string;
    scrollbarNavigatorColor: string;
    scrollbarBackgroundColor: string;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
  wrapperStyledDivClassName?: string;
  wrapperStyledClassName?: string;
  styleTable?: CSSProperties;
  htLicenseKey: string;
  readOnly: boolean;
}) => {
  const { instance, settingTable } = useViewModel({
    configTheme,
    sheet,
    headerRowIndexRef,
    onHeaderRowIndexChange,
    htLicenseKey,
    readOnly,
  });

  return (
    <WrapperStyled configTheme={configTheme} className={wrapperStyledClassName}>
      <div
        className={cx(
          'spread-data-sheet border-1 rounded-medium border-gray-250 flex-grow overflow-hidden bg-gray-50',
          !isFirefox && 'not_firefox',
          readOnly ? 'read-only' : '',
          wrapperStyledDivClassName,
          css`
            .handsontable .htBorders {
              display: none;
            }
          `
        )}
        style={styleTable}
      >
        <HotTable id="hotInstance" ref={instance} settings={settingTable} />
      </div>
    </WrapperStyled>
  );
};

export default HeaderSelection;
