import OptionItemMatcher, { OptionItemMatcherProps } from './OptionItemMatcher';
import { CategoryDataModel } from 'dataModel';
import { MatchedOption } from '../../../../matching/DataModelSheetMatching';
import useViewModel from './viewModel';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import EmptyRows from '../common/EmptyRows';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import { CSSProperties, Fragment, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { css } from 'core/emotion';
import DataModelSheetMatcher from '../../../../matching/DataModelSheetMatcher';
import ProcessingRows from '../common/ProcessingRows';

type OptionsMatcherProps = {
  matchedDataModel: CategoryDataModel;
  sheetColumn: SheetColumn;
  dataModelSheetMatcher: DataModelSheetMatcher;
  prefixName: string;
  configTheme?: {
    root?: string;
    borderColor?: string;
    color?: string;
    emptyValue?: string;
    icon?: string;
    configThemeMenuButton?: {
      root?: string;
      closeIcon?: string;
      arrowIcon?: string;
      placeholder?: string;
    };
    configThemeMenuItem?: {
      root?: string;
      scrollbarNavigatorColor?: string;
      scrollbarBackgroundColor?: string;
      option?: string;
      selectedOption?: string;
      scrollbar?: {
        navigatorColor?: string;
        backgroundColor?: string;
      };
      header?: string;
      search?: {
        root?: string;
        icon?: string;
        placeholder?: string;
      };
      createNewOption?: {
        root?: string;
        icon?: string;
      };
      multiSelectionBadge?: {
        root?: string;
        icon?: string;
      };
    };
  };
  loadingMatchingMoreOptions: boolean;
};

const Row = ({
  index,
  style,
  data,
}: {
  index: number;
  style: CSSProperties;
  data: OptionItemMatcherProps[];
}) => {
  const props = data[index];

  return (
    <div style={style}>
      <OptionItemMatcher {...props} renderInVirtual />
    </div>
  );
};

const OptionsMatcher = ({
  matchedDataModel,
  sheetColumn,
  dataModelSheetMatcher,
  prefixName,
  configTheme,
  loadingMatchingMoreOptions,
}: OptionsMatcherProps) => {
  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });
  const { matchOptionSelectorName, baseMatcherOptions } = useViewModel({
    prefixName,
    sheetColumn,
    dataModel: matchedDataModel,
    dataModelSheetMatcher,
  });

  const fields = useMemo(() => {
    return (get(values, matchOptionSelectorName) as MatchedOption[]) ?? [];
  }, [values, matchOptionSelectorName]);

  const itemHeight = 48;
  const scrollbarHeight = 700;

  const hasScrollbar = fields.length * itemHeight > scrollbarHeight;

  const items = useMemo(() => {
    return fields.map((item, index) => {
      const length = fields?.length ?? 0;
      return {
        key: item.sheetOption,
        matchedOption: item,
        name: `${matchOptionSelectorName}[${index}]`,
        index: index,
        isLastRowIndex: index === length - 1,
        matchedDataModel: matchedDataModel,
        sheetColumn: sheetColumn,
        prefixName: prefixName,
        configTheme: configTheme,
        hasScrollbar,
        baseMatcherOptions,
      };
    });
  }, [
    configTheme,
    fields,
    matchOptionSelectorName,
    matchedDataModel,
    prefixName,
    sheetColumn,
    hasScrollbar,
    baseMatcherOptions,
  ]);

  const itemStyle = useMemo(() => {
    return css`
      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background-color: ${configTheme?.configThemeMenuItem?.scrollbar
          ?.backgroundColor};
        background-clip: padding-box;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.configThemeMenuItem?.scrollbar
          ?.navigatorColor};
        border-radius: 4px;
      }
    `;
  }, [configTheme]);

  if (loadingMatchingMoreOptions) {
    return <ProcessingRows />;
  }

  if (fields.length === 0) {
    return <EmptyRows />;
  }

  return (
    <Fragment key="OptionsMatcher">
      {hasScrollbar ? (
        <AutoSizer disableHeight>
          {({ width }: { width: number }) => {
            return (
              <List
                overscanCount={5}
                width={width ?? 0}
                itemCount={fields.length}
                height={Math.min(fields.length * itemHeight, scrollbarHeight)}
                itemSize={itemHeight}
                itemData={items}
                className={itemStyle}
              >
                {Row}
              </List>
            );
          }}
        </AutoSizer>
      ) : (
        <div>
          {fields.map((item, index) => {
            const length = fields?.length ?? 0;
            return (
              <OptionItemMatcher
                key={`${item.sheetOption}`}
                matchedOption={item}
                name={`${matchOptionSelectorName}[${index}]`}
                index={index}
                isLastRowIndex={index === length - 1}
                matchedDataModel={matchedDataModel}
                prefixName={prefixName}
                configTheme={configTheme}
                hasScrollbar={false}
                baseMatcherOptions={baseMatcherOptions}
              />
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

export default OptionsMatcher;
