import { DataModelSheetMatch } from '../DataModelSheetMatching';

export const parseMatchingToJSON = (
  dataModelSheetMatch: DataModelSheetMatch[]
) => {
  return dataModelSheetMatch.map((item) => {
    return {
      sheetColumn: item.sheetColumn.toJSON(),
      matchedDataModel: item.matchedDataModel,
    };
  });
};
