import { ModifierArguments, ModifierPhases } from '@popperjs/core';

export const widthModifier = {
  name: 'sameWidth',
  enabled: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: ({ state }: ModifierArguments<any>) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  phase: 'beforeWrite' as ModifierPhases,
  requires: ['computeStyles'],
};
