import { DataModel } from '../../dataModel/model/DataModel';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import { isDropdownOptionEqual } from '../../dataModel/utils';
import { isArray, isNil } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';

class CategoryParser implements ValueParser {
  private parseSingle = (dataModel: DataModel, value: PossibleValue) => {
    const categoryDataModel = dataModel as CategoryDataModel;
    return (
      categoryDataModel.getOptions().find((option) => {
        return isDropdownOptionEqual(option, value);
      })?.label ?? ''
    );
  };

  private parseMultiple = (dataModel: DataModel, value: PossibleValue) => {
    if (isArray(value)) {
      return value
        .map((valueItem) => this.parseSingle(dataModel, valueItem))
        .filter((entry) => entry !== '')
        .join(', ');
    } else {
      return this.parseSingle(dataModel, value);
    }
  };

  parse = (dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (dataModel.getIsMultiSelection()) {
      return this.parseMultiple(dataModel, value);
    } else {
      return this.parseSingle(dataModel, value);
    }
  };
}

export default CategoryParser;
