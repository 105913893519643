import { isArray } from 'lodash';
import { CELL_TYPE_CLASS } from '../../../../DataModelSheetForm/type';
import { clearLineBreak } from '../../../../valueParser/ValueParser';
import { isDropdownOptionEqual } from '../../../../../dataModel/utils';
import { Option } from '../../../../../dataModel/model/CategoryDataModel';
import { HookedRecordInfoLevel } from '../../../../../hooks/hooksAPI';
import { cellClassName } from '../../../../../../core/reviewEntries/utils/popperRootClassName';

const findOptionLabel = (
  options: {
    label: string;
    value: string;
  }[],
  value: string | string[]
) => {
  return value
    ? options.find((item) => isDropdownOptionEqual(item as Option, value))
        ?.label ?? clearLineBreak(value)
    : '';
};

const parsedLabel = (
  options: {
    label: string;
    value: string;
  }[],
  value: string | string[]
) => {
  if (isArray(value)) {
    return value.map((entry) => findOptionLabel(options, entry)).join(', ');
  } else {
    return findOptionLabel(options, value);
  }
};

export default abstract class BaseDropdownInputView {
  abstract classNameIdentifier: string;

  private getCellMeta: (
    row: number,
    col: number
  ) => HookedRecordInfoLevel | undefined;
  private afterClearValue?: () => void;
  private arrowDownIcon = `<svg width="12" height="12" viewBox="0 0 7 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="transform rotate-90"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z" fill="currentColor" /></svg>`;
  private closeGrayIcon = `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="m12 4-8 8M4 4l8 8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

  constructor(
    getCellMeta: (
      row: number,
      col: number
    ) => HookedRecordInfoLevel | undefined,
    afterClearValue?: () => void
  ) {
    this.getCellMeta = getCellMeta;
    this.afterClearValue = afterClearValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderer = (...args: any) => {
    const [instance, td, row, col, _key, value, cellProperties] = args;

    const wrapper = document.createElement('div');
    const textElement = document.createElement('div');
    const arrowIcon = document.createElement('div');
    const readOnly = cellProperties?.['readOnly'];

    textElement.className = `text-sm pointer-events-none ${this.classNameIdentifier}-text-element truncate`;
    wrapper.className = `${
      this.classNameIdentifier
    } htAutocompleteArrow justify-between relative flex items-center !box-border ${
      readOnly ? '' : '!cursor-pointer'
    } h-full`;
    arrowIcon.className =
      'arrow-down-icon w-3 h-3 absolute right-0 pt-1 transform pointer-events-none';
    arrowIcon.id = 'dropdown-arrow-icon';
    arrowIcon.innerHTML = this.arrowDownIcon;

    td.className = `${td.className} !box-border !px-0 relative ${
      readOnly ? 'htDimmed' : ''
    }`;

    const options = cellProperties.dropdownOptions as unknown as {
      label: string;
      value: string;
    }[];

    const textLabel = parsedLabel(options, value);

    textElement.innerText = `${textLabel}`;

    const level = this.getCellMeta(row, col);
    td.className = level
      ? `${td.className} ${cellClassName(level)}`
      : `${td.className} ${CELL_TYPE_CLASS.DEFAULT} text-blue-dark-900`;

    wrapper.appendChild(textElement);
    wrapper.appendChild(arrowIcon);

    if (!readOnly) {
      const closeIcon = document.createElement('div');
      closeIcon.className = 'close-icon w-4 h-4 pt-1';
      closeIcon.innerHTML = this.closeGrayIcon;
      closeIcon.addEventListener(
        'mousedown',
        (event) => {
          instance.setDataAtCell(row, col, null);
          instance.render();
          setTimeout(() => cellProperties?.['onClearEditor']?.(), 0);
          event.stopPropagation();

          if (this.afterClearValue) {
            this.afterClearValue();
          }
        },
        {
          passive: true,
        }
      );

      if (isArray(value)) {
        if (value.length > 0) {
          wrapper.appendChild(closeIcon);
        }
      } else {
        if (textLabel) {
          wrapper.appendChild(closeIcon);
        }
      }
    }
    td.id = `${col}-${row}`;
    td.replaceChildren(wrapper);
  };
}
