import { CategoryDataModel } from '@nuvo-importer/common/sdk';
import { Value } from '../../../sheetImporter/Sheet';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type OnSubmitCallback =
  | ((dataModel: CategoryDataModel | null, updatedValue: string) => void)
  | null;

const CreateNewOptionModalContext = createContext<{
  isOpen: boolean;
  dataModel: CategoryDataModel | null;
  open: (
    dataModel: CategoryDataModel,
    sheetColumnOption: Value,
    onSubmit: OnSubmitCallback
  ) => void;
  close: () => void;
  onSubmitCallback: {
    callback: OnSubmitCallback;
  };
  sheetColumnOption: Value | null;
}>({
  isOpen: false,
  dataModel: null,
  open: () => {},
  close: () => {},
  onSubmitCallback: {
    callback: null,
  },
  sheetColumnOption: null,
});

export const useContextCreateNewOptionModal = () => {
  const {
    isOpen,
    dataModel,
    close,
    open,
    onSubmitCallback,
    sheetColumnOption,
  } = useContext(CreateNewOptionModalContext);

  return {
    isOpen,
    dataModel,
    close,
    open,
    onSubmitCallback,
    sheetColumnOption,
  };
};

type CreateNewOptionModalContextProviderProps = {
  children: ReactNode;
};

const CreateNewOptionModalContextProvider = ({
  children,
}: CreateNewOptionModalContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataModel, setDataModel] = useState<CategoryDataModel | null>(null);
  const [sheetColumnOption, setSheetColumnOption] = useState<Value | null>(
    null
  );
  const [onSubmitCallback, setOnSubmitCallback] = useState<{
    callback: OnSubmitCallback;
  }>({ callback: null });

  const open = useCallback(
    (
      dataModel: CategoryDataModel,
      sheetColumnOption: Value,
      onSubmitCallback: OnSubmitCallback
    ) => {
      setIsOpen(true);
      setDataModel(dataModel);
      setOnSubmitCallback({
        callback: onSubmitCallback,
      });
      setSheetColumnOption(sheetColumnOption);
    },
    []
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setDataModel(null);
    setOnSubmitCallback({ callback: null });
    // NOTE we need to setTimeout, otherwise it show form error while modal is closing
    setTimeout(() => {
      setSheetColumnOption(null);
    }, 500);
  }, []);

  return (
    <CreateNewOptionModalContext.Provider
      value={{
        isOpen,
        dataModel,
        open,
        close,
        onSubmitCallback,
        sheetColumnOption,
      }}
    >
      {children}
    </CreateNewOptionModalContext.Provider>
  );
};

export default CreateNewOptionModalContextProvider;
