export const transformDataSpecial = (value: string) => {
  return (
    value
      .trim()
      /* eslint-disable no-useless-escape */
      ?.replace(/[-!$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g, '')
      ?.replace(/^_+/, '')
      ?.replace(/_+$/, '')
      ?.replace(
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g,
        ''
      )
      ?.replace(/ /g, '_')
      ?.replace(/ß/g, 'ss')
      ?.replace(/ä/g, 'ae')
      ?.replace(/ö/g, 'oe')
      ?.replace(/ü/g, 'ue')
      ?.toLowerCase()
  );
};

export const cleanSpecialCharAndToLowercase = (str: string) => {
  return str
    ?.replace(/[-!$%^&*()_+|~=`{}\\[\]:";'<>?,.\\/]/g, ' ')
    ?.replace(
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g,
      ' '
    )
    ?.toLowerCase();
};
