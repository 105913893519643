import {
  DataModelSheetMatchingAutoMatching,
  LogAutoOptionMapping,
} from 'core/matching/autoMatching';
import BaseAutoMatching from './BaseAutoMatching';

class AutoMatchingLocalStorage extends BaseAutoMatching {
  private localStorageKey = 'auto-matching';

  private getHash = async (key: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(key);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  };

  save = (
    identifier: string,
    serializeValue: DataModelSheetMatchingAutoMatching,
    key: string
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      try {
        const a = await this.getHash(key);

        localStorage.setItem(
          `${this.localStorageKey}`,
          JSON.stringify({
            identifier,
            serializeValue,
            a,
          })
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}
      resolve();
    });
  };

  saveOption = async (_key: string, _items: LogAutoOptionMapping[]) => {
    // NOTE: we can save the option mapping in the local storage
    return Promise.resolve();
  };

  getAutoMatch = async (identifier: string, key: string) => {
    try {
      const item = JSON.parse(
        localStorage.getItem(`${this.localStorageKey}`) ?? ''
      );

      const a = await this.getHash(key);

      if (item.a === a) {
        return this.handleGetAutoMatch(identifier, item);
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default AutoMatchingLocalStorage;
