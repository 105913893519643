/* istanbul ignore file */

import { useEffect } from 'react';
import CircleProgress from '../Progress';
import { css, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'theme';
import { useContextLoadingPopupManager } from '../LoadingPopup/context';
import BaseModal from '../Modal/BaseModal';

export type LoadingPopupProps = {
  isShowDialog: boolean;
};

const LoadingPopup = ({ isShowDialog }: LoadingPopupProps) => {
  const { t } = useTranslation();
  const { isOpen, setOpenModal, isOpenModal } = useContextLoadingPopupManager();
  const theme = useTheme();

  useEffect(() => {
    if (!isShowDialog) setOpenModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDialog]);

  return (
    <BaseModal
      gravity="middle"
      className="sm:max-w-528 w-full !p-0"
      isOpen={isOpen}
      onCloseModal={() => {
        isOpenModal.current = false;
        setOpenModal(false);
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      disabledClickOutside={false}
      isShowCloseIcon={true}
      overlayStyled={theme.getDialogConfirmTheme().overlay}
      bodyStyled={theme.getDialogConfirmTheme().root}
      closeIconStyled={theme.getDialogConfirmTheme().closeIcon}
    >
      <div className="h-298 w-60 px-7 pb-2 pt-3">
        <div className="mb-2 flex h-full w-full items-center justify-center">
          <CircleProgress text={t('txt_loading')} />
        </div>
      </div>
    </BaseModal>
  );
};

export default LoadingPopup;
