import { forwardRef, RefObject } from 'react';
import TruncatePopover from 'baseUI/TruncatePopover';
import { cx } from 'core/emotion';
import { useStyle } from './style';
import ArrowDown from '../ArrowDown';
import ClearButton from './ClearButton';
import { useTranslation } from 'react-i18next';

const MenuButton = forwardRef<
  HTMLButtonElement,
  {
    open: boolean;
    isEmpty: boolean;
    shownValue: string;
    onClear: () => void;
    configThemeMenuButton?: {
      root?: string;
      closeIcon?: string;
      arrowIcon?: string;
      placeholder?: string;
    };
    isShowCustomOptions: boolean;
  }
>(
  (
    {
      open,
      isEmpty,
      shownValue,
      onClear,
      configThemeMenuButton,
      isShowCustomOptions,
      ...props
    },
    ref
  ) => {
    const { menuButtonClassname } = useStyle({
      open,
    });
    const { t } = useTranslation();

    return (
      <button
        type="button"
        ref={ref}
        className={cx(
          menuButtonClassname,
          'select-button',
          configThemeMenuButton?.root
        )}
        {...props}
      >
        {!isEmpty ? (
          <TruncatePopover
            detectChangeText={shownValue}
            render={({ referenceElement, textRef, getHover }) => {
              return (
                <div
                  className="w-full"
                  ref={referenceElement as RefObject<HTMLDivElement>}
                  {...getHover()}
                >
                  <span
                    className="block max-w-full truncate text-left"
                    ref={textRef as RefObject<HTMLSpanElement>}
                  >
                    {shownValue}
                  </span>
                  <ClearButton
                    className={configThemeMenuButton?.closeIcon}
                    onClick={onClear}
                  />
                </div>
              );
            }}
          />
        ) : (
          <span
            className={cx(
              'text-gray-510 truncate',
              configThemeMenuButton?.placeholder
            )}
          >{`${
            isShowCustomOptions
              ? t('txt_select_or_add_dropdown_option')
              : t('txt_select')
          }...`}</span>
        )}
        <ArrowDown className={configThemeMenuButton?.arrowIcon} />
      </button>
    );
  }
);

export default MenuButton;
