import {
  DataModelSerializer,
  NumberFormat,
} from '@nuvo-importer/common/worker/matching-value';
import {
  MatchingJSON,
  MatchingSheetJSON,
} from '../../reviewEntries/DataModelSheetMatchingValues';

export class MatchingSerializer {
  static serialize = (instance: {
    matching: MatchingSheetJSON[];
    numberFormat: NumberFormat;
  }) => {
    const result: { matching: MatchingJSON[]; numberFormat: NumberFormat } = {
      matching: [],
      numberFormat: instance.numberFormat,
    };

    result.matching = instance.matching.map((match) => {
      return {
        sheetColumn: match.sheetColumn,
        matchedDataModel: match.matchedDataModel
          ? {
              dataModel: match.matchedDataModel!.dataModel
                ? DataModelSerializer.serialize(
                    match.matchedDataModel!.dataModel
                  )
                : match.matchedDataModel!.dataModel,
              matchedOptions: match.matchedDataModel.matchedOptions,
            }
          : match.matchedDataModel,
      };
    });

    return [result, []];
  };

  static deserialize = (obj: {
    matching: MatchingJSON[];
    numberFormat: NumberFormat;
  }): { matching: MatchingSheetJSON[]; numberFormat: NumberFormat } => {
    return {
      matching: obj.matching.map((match) => {
        return {
          sheetColumn: match.sheetColumn,
          matchedDataModel: match.matchedDataModel
            ? {
                dataModel: match.matchedDataModel!.dataModel
                  ? DataModelSerializer.deSerialize(
                      match.matchedDataModel!.dataModel
                    )
                  : match.matchedDataModel!.dataModel,
                matchedOptions: match.matchedDataModel.matchedOptions,
              }
            : match.matchedDataModel,
        };
      }),
      numberFormat: obj.numberFormat,
    };
  };
}
