import { useMemo } from 'react';
import { Value as SheetValue } from './../../../../sheetImporter/Sheet';
import SheetColumn from './../../../../sheetImporter/SheetColumn';

export type Row = {
  index: number;
  value: SheetValue;
};

const useRowExamples = ({ sheetColumn }: { sheetColumn: SheetColumn }) => {
  const rows = useMemo(() => {
    const values = sheetColumn.getRows();
    return [...values.slice(0, 3)].map((value, index) => {
      return {
        index: index + 1,
        value: value,
      };
    });
  }, [sheetColumn]);

  return { rows };
};

export default useRowExamples;
