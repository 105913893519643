import { forwardRef, RefObject, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TruncatePopover from 'baseUI/TruncatePopover';
import { css, cx } from 'core/emotion';
import { useStyle } from './style';
import ArrowDown from './ArrowDown';
import { ReactComponent as CloseIcon } from '../../assets/icon/close-gray.svg';
import { UseSelectPropGetters } from 'downshift';
import { IOption } from './viewModel';
import { ColumnDropdownThemeAPI } from '@nuvo-importer/common/sdk';

type IMenuButtonProps = {
  open: boolean;
  isEmpty: boolean;
  shownValue?: string;
  onClear: () => void;
  getToggleButtonProps: UseSelectPropGetters<IOption>['getToggleButtonProps'];
  onKeyDown: (event: KeyboardEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  isShowError?: boolean;
  theme?: ColumnDropdownThemeAPI;
};

const MenuButton = forwardRef<HTMLButtonElement, IMenuButtonProps>(
  (
    {
      open,
      isEmpty,
      shownValue,
      onClear,
      theme,
      onKeyDown,
      getToggleButtonProps,
      placeholder,
      disabled,
      isShowError,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { menuButtonClassname } = useStyle({
      open,
      isShowError,
    });

    return (
      <button
        type="button"
        className={cx(menuButtonClassname, css({ '&&': theme?.button?.root }))}
        onKeyDown={onKeyDown}
        disabled={disabled}
        {...getToggleButtonProps({
          onKeyDown,
          ref,
        })}
      >
        {!isEmpty ? (
          <TruncatePopover
            detectChangeText={shownValue}
            render={({ referenceElement, textRef, getHover }) => {
              return (
                <div
                  className={cx('flex w-full items-center justify-between')}
                  ref={referenceElement as RefObject<HTMLDivElement>}
                  {...getHover()}
                >
                  <span ref={textRef} className="truncate">
                    {shownValue}
                  </span>
                  <CloseIcon
                    className={cx(
                      'text-gray-260 flex-shrink-0',
                      css({ '&&': theme?.button?.icon })
                    )}
                    onClick={(event) => {
                      if (disabled) return;
                      event.stopPropagation();
                      onClear();
                    }}
                  />
                </div>
              );
            }}
          />
        ) : (
          <span
            className={cx(
              'text-color-secondary max-w-full truncate',
              css({ '&&': theme?.button?.placeholder })
            )}
          >
            {placeholder ?? `${t('txt_select')}...`}
          </span>
        )}
        <ArrowDown open={open} />
      </button>
    );
  }
);

export default MenuButton;
