import { isTruncated } from '@nuvo-importer/common/sdk';
import { BehaviorSubject } from 'rxjs';

type SubjectPayload = {
  popoverPos: number | null;
  referenceElement: HTMLElement | null;
};

class ColHeaderController {
  static joinBadgeClassName = 'nuvo-join-column-badge';
  static columnNameClassName = 'nuvo-column-name';
  private _badgePopoverObservable: BehaviorSubject<SubjectPayload>;
  private _truncatePopoverObservable: BehaviorSubject<SubjectPayload>;

  constructor() {
    this._badgePopoverObservable = new BehaviorSubject<SubjectPayload>({
      popoverPos: null,
      referenceElement: null,
    });
    this._truncatePopoverObservable = new BehaviorSubject<SubjectPayload>({
      popoverPos: null,
      referenceElement: null,
    });
  }

  registerBadgeEvent = (th: HTMLTableHeaderCellElement, colIndex: number) => {
    const badgeElement = th.querySelector(
      `.${ColHeaderController.joinBadgeClassName}`
    );

    if (!badgeElement) return;

    badgeElement.addEventListener('mouseenter', () => {
      this.openBadgePopover(colIndex, badgeElement as HTMLDivElement);
    });

    badgeElement.addEventListener('mouseleave', () => {
      this.closeBadgePopover();
    });
  };

  registerTruncateEvent = (
    th: HTMLTableHeaderCellElement,
    colIndex: number
  ) => {
    const textElement = th.querySelector(
      `.${ColHeaderController.columnNameClassName}`
    );

    if (!textElement) return;

    textElement.addEventListener('mouseenter', () => {
      if (isTruncated(textElement as HTMLElement)) {
        this.openTruncatePopover(colIndex, textElement as HTMLElement);
      }
    });

    textElement.addEventListener('mouseleave', () => {
      this.closeTruncatePopover();
    });
  };

  getPopoverObservable = () => {
    return this._badgePopoverObservable;
  };

  getTruncateObservable = () => {
    return this._truncatePopoverObservable;
  };

  private openBadgePopover = (
    index: number,
    referenceElement: HTMLDivElement
  ) => {
    this._badgePopoverObservable.next({
      popoverPos: index,
      referenceElement,
    });
  };

  private closeBadgePopover = () => {
    this._badgePopoverObservable.next({
      popoverPos: null,
      referenceElement: null,
    });
  };

  private openTruncatePopover = (
    index: number,
    referenceElement: HTMLElement
  ) => {
    this._truncatePopoverObservable.next({
      popoverPos: index,
      referenceElement,
    });
  };

  private closeTruncatePopover = () => {
    this._truncatePopoverObservable.next({
      popoverPos: null,
      referenceElement: null,
    });
  };
}

export default ColHeaderController;
