import Sheet from './Sheet';
import SpreadSheetList from './SpreadSheetList';

class SpreadSheetNavigate {
  private spreadSheetList: SpreadSheetList;
  private currentSpreadSheetPosition = 0;
  private currentSheetPosition = 0;
  constructor({ spreadSheetList }: { spreadSheetList: SpreadSheetList }) {
    this.spreadSheetList = spreadSheetList;
  }

  goToBySheet = (sheet: Sheet) => {
    const selectedSpreadSheets = this.spreadSheetList.getSelectedSpreadSheets();
    for (let i = 0; i < selectedSpreadSheets.length; i++) {
      const selectedSheets = selectedSpreadSheets[i].getSelectedSheets();
      for (let j = 0; j < selectedSheets.length; j++) {
        if (selectedSheets[j] === sheet) {
          this.currentSpreadSheetPosition = i;
          this.currentSheetPosition = j;
          return selectedSheets[j];
        }
      }
    }

    return;
  };

  getCurrentSpreadSheet = () => {
    const currentSpreadSheet =
      this.spreadSheetList.getSelectedSpreadSheets()[
        this.currentSpreadSheetPosition
      ];

    return currentSpreadSheet;
  };

  getCurrentSheet = () => {
    const currentSpreadSheet = this.getCurrentSpreadSheet();
    const sheets = currentSpreadSheet.getSelectedSheets();

    return sheets[this.currentSheetPosition];
  };

  previous = () => {
    const previousSheetPosition = this.currentSheetPosition - 1;

    if (previousSheetPosition < 0) {
      const previousSpreadSheetPosition = this.currentSpreadSheetPosition - 1;

      if (previousSpreadSheetPosition < 0) {
        return false;
      } else {
        const previousSpreadSheet =
          this.spreadSheetList.getSelectedSpreadSheets()[
            previousSpreadSheetPosition
          ];
        const sheetsLength = previousSpreadSheet.getSelectedSheets().length;

        this.currentSpreadSheetPosition = previousSpreadSheetPosition;
        this.currentSheetPosition = sheetsLength - 1;

        return true;
      }
    } else {
      this.currentSheetPosition = previousSheetPosition;
      return true;
    }
  };

  next = (): boolean | undefined => {
    const nextSheetPosition = this.currentSheetPosition + 1;

    const currentSpreadSheet =
      this.spreadSheetList.getSelectedSpreadSheets()[
        this.currentSpreadSheetPosition
      ];

    const sheetLength = currentSpreadSheet.getSelectedSheets().length;

    if (nextSheetPosition >= sheetLength) {
      const nextSpreadSheetPosition = this.currentSpreadSheetPosition + 1;
      const spreadSheetLength =
        this.spreadSheetList.getSelectedSpreadSheets().length;

      if (nextSpreadSheetPosition >= spreadSheetLength) {
        return false;
      } else {
        this.currentSpreadSheetPosition = nextSpreadSheetPosition;
        this.currentSheetPosition = 0;

        if (this.getCurrentSheet().getIsManualSelectHeader()) {
          return this.next();
        }

        return true;
      }
    } else {
      this.currentSheetPosition = nextSheetPosition;

      if (this.getCurrentSheet().getIsManualSelectHeader()) {
        return this.next();
      }

      return true;
    }
  };

  goToLastSpreadSheet = () => {
    this.currentSpreadSheetPosition = Math.max(
      this.spreadSheetList.getSelectedSpreadSheets().length - 1,
      0
    );
  };

  goToLastSheet = () => {
    const spreadSheet = this.getCurrentSpreadSheet();

    this.currentSheetPosition = Math.max(
      spreadSheet.getSelectedSheets().length - 1,
      0
    );
  };

  getSpreadSheetList = () => {
    return this.spreadSheetList;
  };
}

export default SpreadSheetNavigate;
