import { MouseEvent, forwardRef } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../../assets/icon/arrow-down.svg';
import { css, cx } from '../../../../../emotion';
import { ConfigTheme, Size } from './type';

type MenuSelectorProps = {
  shownValue?: string;
  onClick?: (event: MouseEvent) => void;
  placeholder?: string;
  hasSelected: boolean;
  size?: Size;
  configTheme?: ConfigTheme;
  className?: string;
};

const MenuSelector = forwardRef<HTMLDivElement, MenuSelectorProps>(
  (
    {
      shownValue,
      placeholder,
      hasSelected,
      size = 'base',
      configTheme,
      className,
      ...props
    }: MenuSelectorProps,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cx(
          'rounded-medium flex cursor-pointer items-center border bg-white',
          hasSelected ? 'border-blue-dark-900' : 'border-gray-120',
          size === 'small' ? 'px-2 py-0.5 text-xs' : 'px-3 py-2 text-sm',
          hasSelected ? 'text-gray-660' : 'text-gray-560',
          css({ '&&': configTheme?.root }),
          className
        )}
        {...props}
      >
        <p className={cx('truncate')}>
          {hasSelected ? shownValue : placeholder}
        </p>
        <ArrowDown
          className={cx(
            'ml-auto inline-block flex-shrink-0',
            css({ '&&': configTheme?.icon })
          )}
        />
      </div>
    );
  }
);

export default MenuSelector;
