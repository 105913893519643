/* istanbul ignore file */

import { SHEET_COLUMN_TYPE } from '../constants/sheet';
import { isEmpty, uniq } from 'lodash';
import Sheet, { SheetJSON, Value } from './Sheet';

export type SheetColumnJSON = {
  columnKey: string;
  id: string;
  sheet: SheetJSON;
  rows: Value[];
  type: SHEET_COLUMN_TYPE;
  sheetColumns?: SheetColumnJSON[];
};

let id = 0;

class SheetColumn {
  private uniques: Value[];
  private removed = false;
  private label?: string = '';
  protected rows: Value[];
  protected sheet: Sheet;
  protected id: string;
  protected columnKey: string;
  protected index?: number;
  protected parsedDate = false;
  private refJoinSheetColum?: SheetColumn;

  constructor({
    sheet,
    columnKey,
    rows,
    id: idConstruct,
    notSetUnique = false,
    label,
    refJoinSheetColum,
  }: {
    sheet: Sheet;
    columnKey: string;
    rows: Value[];
    id?: string;
    notSetUnique?: boolean;
    label?: string;
    refJoinSheetColum?: SheetColumn;
  }) {
    this.columnKey = columnKey.trim();
    this.sheet = sheet;
    this.rows = isEmpty(rows) ? [''] : rows;
    if (typeof idConstruct === 'undefined') {
      id++;
      this.id = `${columnKey}-${id}`;
    } else {
      this.id = idConstruct;
    }

    if (notSetUnique) {
      this.uniques = [];
    } else {
      this.uniques = uniq(this.getRows());
    }

    if (label) {
      this.label = label;
    }
    this.refJoinSheetColum = refJoinSheetColum;
  }

  getColumnKey = () => {
    return this.columnKey;
  };

  getId = () => {
    return this.id;
  };

  getSheet = () => {
    return this.sheet;
  };

  getRows = () => {
    return this.rows;
  };

  getUniqueRows = () => {
    return this.uniques;
  };

  remove = () => {
    this.removed = true;
  };

  unRemove = () => {
    this.removed = false;
  };

  isRemoved = () => {
    return this.removed;
  };

  getType = () => {
    return SHEET_COLUMN_TYPE.NORMAL;
  };

  getFileName = () => {
    return this.sheet.getSheetFileName();
  };

  toJSON = (): SheetColumnJSON => {
    return {
      columnKey: this.columnKey,
      id: this.id,
      sheet: this.sheet.toJSON(),
      rows: this.rows,
      type: this.getType(),
    };
  };

  equal = (sheetColumn: SheetColumn) => {
    return this.getId() === sheetColumn.getId();
  };

  keyEqual = (sheetColumn: SheetColumn) => {
    return this.columnKey === sheetColumn.columnKey;
  };

  getLabel = () => {
    return this.label;
  };

  setLabel = (label: string) => {
    this.label = label;
  };

  setIndex = (index: number) => {
    this.index = index;
  };

  getIndex = () => {
    return this.index;
  };

  setRows(rows: Value[]) {
    this.rows = rows;
  }

  setParsedDate(parsed: boolean) {
    this.parsedDate = parsed;
  }

  hasParsedDate() {
    return this.parsedDate;
  }

  getRefJoinSheetColum = () => {
    return this.refJoinSheetColum;
  };
}

export default SheetColumn;
