import { CSSInterpolation, css, cx } from '@nuvo-importer/common';
import Popover from 'baseUI/Popover';
import { Boundary } from '@popperjs/core';
import { RefObject, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { JoinSheet, JoinSheetColumn } from '@nuvo-importer/common/sdk';
import { useTranslation } from 'react-i18next';

type IJoinSheetNameProps = {
  className?: string;
  boundary?: Boundary;
  sheetColumn: JoinSheetColumn;
  joinPopoverTheme?: CSSInterpolation;
  customTheme?: CSSInterpolation;
};

const JoinSheetName = ({
  className,
  boundary,
  sheetColumn,
  joinPopoverTheme,
  customTheme,
}: IJoinSheetNameProps) => {
  const [isShowPopover, setShowIsPopover] = useState(false);
  const { t } = useTranslation();

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setShowIsPopover(true),
        onMouseLeave: () => setShowIsPopover(false),
      };
    } else {
      return {
        onClick: () => setShowIsPopover((isShow) => !isShow),
        onMouseLeave: () => setShowIsPopover(false),
      };
    }
  };

  const joinedSheetNames = sheetColumn
    .getJoinedSheetColumns()
    .map((sheetColumn) => {
      return `[${sheetColumn.getSheet().getName()}]`;
    });

  return (
    <Popover
      boundary={boundary}
      direction="top"
      isShow={isShowPopover}
      overflowCheckOffset={{ top: 10, bottom: -25, right: 300 }}
      padding={{
        right: -100,
      }}
      message={
        <div
          className={cx(
            'bg-blue-light-400 rounded-medium z-170 max-w-204 break-words px-4 py-3 text-center text-xs text-white',
            css({
              '&&': joinPopoverTheme,
            })
          )}
        >
          {t('txt_used_to_join')}{' '}
          {joinedSheetNames ? joinedSheetNames.join(' ') : null}
        </div>
      }
      render={({ referenceElement }) => {
        return (
          <div
            {...getOnHover()}
            ref={referenceElement as RefObject<HTMLDivElement>}
            className={cx(
              'text-xss text-blue-light-500 rounded-2lg bg-blue-light-50 ml-1 flex whitespace-nowrap px-2 py-1 leading-3',
              className,
              css({
                '&&': customTheme,
              })
            )}
          >
            {(sheetColumn.getSheet() as JoinSheet).getJoinedSheets().length}{' '}
            {t('txt_sheets')}
          </div>
        );
      }}
    />
  );
};

export default JoinSheetName;
