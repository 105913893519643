import { useCallback } from 'react';
import { useField } from 'react-final-form';
import useErrorMessage from '../../../core/form/errorMessage';

type Props<T> = {
  name: string;
  onChange?: (value: T | null) => void;
  isFieldShowError: boolean;
};

const useViewModel = <T>({
  name,
  onChange: onChangeProp,
  isFieldShowError,
}: Props<T>) => {
  const { input, meta } = useField(name);

  const onChange = useCallback(
    (value: T | null) => {
      input.onChange(value);
      onChangeProp?.(value);
    },
    [input, onChangeProp]
  );

  const { isShowError, errorMessage } = useErrorMessage({
    isFieldShowError,
    meta,
  });

  return { input, meta, onChange, isShowError, errorMessage };
};

export default useViewModel;
