import DevelopMode from 'baseUI/Banner/DevelopMode';
import { NUVO_ELEMENT_ID } from 'core/constants/element';
import { cx } from 'core/emotion';
import { useCallback } from 'react';
import { render } from 'react-dom';

export const useDevelopBanner = () => {
  const renderDevelopBannerForMainPage = (
    isDeveloperMode: boolean,
    isLoading: boolean,
    identifier: string
  ) => {
    return isDeveloperMode ? (
      isLoading ? null : (
        <div
          id={`${NUVO_ELEMENT_ID.DEV_BANNER_ROOT}-${identifier}`}
          className="w-fit mb-4"
        >
          <DevelopMode isLoading={isLoading} identifier={identifier} />
        </div>
      )
    ) : null;
  };

  const renderDevelopBannerForSubPage = (
    isShowBanner: boolean,
    identifier: string
  ) => {
    return isShowBanner ? (
      <div
        id={`${NUVO_ELEMENT_ID.DEV_BANNER_ROOT_SUB_PAGE}-${identifier}`}
        className={cx('w-fit mb-4')}
      >
        <DevelopMode identifier={identifier} />
      </div>
    ) : null;
  };

  const renderDevelopBannerForModal = (
    isShowBanner: boolean,
    identifier: string
  ) => {
    return (
      <div
        id={`${NUVO_ELEMENT_ID.DEV_BANNER_ROOT}-${identifier}`}
        className={cx('mx-auto flex items-center px-3 pt-3 lg:px-8')}
      >
        {isShowBanner ? (
          <div className="mt-5">
            <DevelopMode identifier={identifier} />
          </div>
        ) : null}
      </div>
    );
  };

  const ensureDevelopBannerVisibility = useCallback(
    ({ isVisible, viewMode, isDeveloperMode, identifier, forceListener }) => {
      if (isVisible) return;
      const container = document.getElementById(
        `${NUVO_ELEMENT_ID.STEPPER_ROOT}-${identifier}`
      );
      if (!container) return;
      const prependBanner = (mode: 'subpage' | 'page' | 'modal') => {
        const rootId =
          mode === 'subpage'
            ? `${NUVO_ELEMENT_ID.DEV_BANNER_ROOT_SUB_PAGE}-${identifier}`
            : `${NUVO_ELEMENT_ID.DEV_BANNER_ROOT}-${identifier}`;
        const forceListeningId =
          mode === 'subpage' ? 'secondaryRootTargetEleId' : 'rootTargetEleId';
        const div = document.createElement('div');
        const banner =
          mode === 'subpage'
            ? renderDevelopBannerForSubPage(isDeveloperMode, identifier)
            : mode === 'page'
            ? renderDevelopBannerForMainPage(isDeveloperMode, false, identifier)
            : renderDevelopBannerForModal(isDeveloperMode, identifier);
        if (!banner) return;
        setTimeout(() => {
          document.getElementById(rootId)?.remove();
          render(banner, div);
          container?.prepend(div?.children?.[0]);
          forceListener(forceListeningId, identifier);
        }, 0);
      };

      const delayPrepend = () => setTimeout(() => prependBanner(viewMode), 0);
      delayPrepend();
    },
    []
  );

  return {
    renderDevelopBannerForMainPage,
    renderDevelopBannerForSubPage,
    renderDevelopBannerForModal,
    ensureDevelopBannerVisibility,
  };
};
