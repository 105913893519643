import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import '@getnuvo/ui-kit/index.css';
import { Button } from '@getnuvo/ui-kit';
import { usePreviewConfig } from '../../context/PreviewConfigProvider';
import { ConfigureAPI } from './services';
import { ConfigureModal } from './components/ConfigureModal';

export const Configure = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { setDataModels } = usePreviewConfig();

  const onOpenModal = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const onDismissModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const [loadingTDMs, setLoadingTDMs] = useState<boolean>(true);

  useEffect(() => {
    setLoadingTDMs(true);
    ConfigureAPI.getTDMs()
      .then((dataModels) => {
        setDataModels(dataModels);
      })
      .finally(() => {
        setLoadingTDMs(false);
      });
  }, [setDataModels]);

  return (
    <div className="nuvo-sdk__configure">
      <Button onClick={onOpenModal} loading={loadingTDMs}>
        Configure
      </Button>

      {openModal && (
        <ConfigureModal open={openModal} onDismiss={onDismissModal} />
      )}
    </div>
  );
};
