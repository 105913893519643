import { useCallback, useState } from 'react';

export const useSheetPreview = () => {
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const openPreview = useCallback((): void => {
    setShowPreview(true);
  }, [setShowPreview]);

  const dismissPreview = useCallback((): void => {
    setShowPreview(false);
  }, [setShowPreview]);

  return {
    showPreview,
    openPreview,
    dismissPreview,
  };
};
