import { useRef, useState } from 'react';
import { usePopper as useBasePopper } from 'react-popper';

export type IPopper = {
  referenceElement: React.RefObject<HTMLDivElement>;
  styles: { [key: string]: React.CSSProperties };
  setPopperElement: (element: HTMLDivElement | null) => void;
  attributes: { [key: string]: { [key: string]: string } | undefined };
  popperElement: HTMLDivElement | null;
};

const usePopper = () => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = useBasePopper(
    referenceElement.current,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 6],
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            padding: {
              bottom: 30,
            },
          },
        },
      ],
    }
  );

  return {
    referenceElement,
    styles,
    setPopperElement,
    attributes,
    popperElement,
  };
};

export default usePopper;
