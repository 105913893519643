import { Sheet } from '@nuvo-importer/common/sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type OnJoinClick = (sheet: Sheet) => void;
export type OnAppendClick = (sheet: Sheet) => void;
export type OnRemoveClick = (sheet: Sheet) => void;
export type OnAddFirstSheet = (sheet: Sheet) => void;
export type OnPreviewClick = (sheet: Sheet) => void;
export type OnAddToAllRows = (sheet: Sheet) => void;

type IViewModelProps = {
  onJoinClick: OnJoinClick;
  onAppendClick: OnAppendClick;
  onAddToAllRows: OnAddToAllRows;
  sheet: Sheet;
};

const useViewModel = ({
  onJoinClick,
  onAppendClick,
  onAddToAllRows,
  sheet,
}: IViewModelProps) => {
  const { t } = useTranslation();
  const menus = useMemo(() => {
    return [
      {
        label: t('txt_join_on_columns'),
        onClick: () => {
          onJoinClick(sheet);
        },
      },
      {
        label: t('txt_append_to_columns'),
        onClick: () => {
          onAppendClick(sheet);
        },
      },
      {
        label: t('txt_add_to_all_rows'),
        onClick: () => {
          onAddToAllRows(sheet);
        },
        disabled: sheet.getValues().length !== 1,
      },
    ];
  }, [sheet, onJoinClick, onAppendClick, onAddToAllRows, t]);

  return { menus };
};

export default useViewModel;
