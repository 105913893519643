import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';

const EmptyText = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <p className={cx('text-sm capitalize italic text-gray-400', className)}>
      {t('txt_empty')}
    </p>
  );
};

export default EmptyText;
