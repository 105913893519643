import { TextEditor } from 'handsontable/editors';
import { NumberFormat } from '../../../dataModel/columnsAPI';

export default abstract class BaseTextInputEditor extends TextEditor {
  abstract getCellCustomStyle(): string;

  private rowHeight = 35;

  override createElements() {
    super.createElements();
    this.TEXTAREA.className = `${this.getCellCustomStyle()}`;
  }

  override open(event?: Event) {
    const mediaSize = this.cellProperties?.mediaSize;
    const htMasterHasBorderLeft = document.querySelector(
      '.ht_master.handsontable.innerBorderLeft.innerBorderInlineStart'
    );
    if (htMasterHasBorderLeft && this.col === 0) {
      this.TEXTAREA.style.marginLeft = '-1px';
    } else {
      this.TEXTAREA.style.marginLeft = '0px';
    }

    if (mediaSize) {
      this.TEXTAREA.style.lineHeight = '1.6';
    } else {
      this.recalculateLineHeight(this.rowHeight, true, true);
      this.TEXTAREA.addEventListener('input', this.handleInputChange);
    }
    super.open(event);
  }

  override close(): void {
    super.close();
    this.TEXTAREA.removeEventListener('input', this.handleInputChange);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue(newValue?: any): void {
    if (this.cellProperties?.type === 'numeric') {
      const numberFormat = this.cellProperties?.numberFormat;
      if (numberFormat === NumberFormat.EU && !isNaN(Number(newValue))) {
        super.setValue(`${newValue}`?.replace(/\./g, ','));
        return;
      }
      super.setValue(newValue);
    } else {
      super.setValue(newValue);
    }
  }

  private handleInputChange = (_event: Event) => {
    this.recalculateLineHeight(this.rowHeight);
  };

  private recalculateLineHeight(
    actualRowHeight?: number,
    forceRefreshDimensions?: boolean,
    onlyFirstTime?: boolean
  ) {
    const value = this.getValue();
    const multiLineHeight = '1.6';
    const singleLineHeight = '2';
    const hasLineBreak = value.includes('\n');

    if (onlyFirstTime) {
      this.TEXTAREA.style.lineHeight = singleLineHeight;
    }

    setTimeout(() => {
      if (hasLineBreak) {
        if (this.TEXTAREA.style.lineHeight === multiLineHeight) return;
        this.TEXTAREA.style.lineHeight = multiLineHeight;
        forceRefreshDimensions && this.refreshDimensions();
      } else {
        if (
          this.TEXTAREA.scrollHeight >
          (actualRowHeight || this.TEXTAREA.clientHeight)
        ) {
          if (this.TEXTAREA.style.lineHeight === multiLineHeight) return;
          this.TEXTAREA.style.lineHeight = multiLineHeight;
          forceRefreshDimensions && this.refreshDimensions();
        } else {
          if (this.TEXTAREA.style.lineHeight === singleLineHeight) return;
          this.TEXTAREA.style.lineHeight = singleLineHeight;
        }
      }
    }, 0);
  }
}
