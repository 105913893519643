import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  useCallback,
} from 'react';

type CircleLoadingModalProps = {
  label: string;
};

type CircleLoadingModalManagerProps = {
  children?: ReactNode;
};

type ContextType = {
  isOpen: boolean;
  isProcessing: boolean;
  setProcessing: (isProcessing: boolean) => void;
  setOpenModal: (isOpen: boolean) => void;
  showLoading: (options: CircleLoadingModalProps) => void;
  dismissLoading: () => void;
};

const CircleLoadingManagerContext = createContext({} as ContextType);

const CircleLoadingManager: FC<CircleLoadingModalManagerProps> = ({
  children,
}) => {
  const [isOpen, setOpenModal] = useState(false);
  const [_options, setOptions] = useState<CircleLoadingModalProps>();
  const [isProcessing, setProcessing] = useState(false);

  const showLoading = (options: CircleLoadingModalProps) => {
    setOptions(options);
    setOpenModal(true);
  };

  const dismissLoading = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <CircleLoadingManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        showLoading,
        dismissLoading,
        isProcessing,
        setProcessing,
      }}
    >
      {children}
      {/* {options && <CircleLoadingModal label={options.label} />} */}
    </CircleLoadingManagerContext.Provider>
  );
};

export const useContextCircleLoadingModalManager = () => {
  return useContext(CircleLoadingManagerContext);
};

export default CircleLoadingManager;
