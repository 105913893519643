import { useState } from 'react';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import NodeMatchingRepository from './MatchingRepository/NodeMatchingRepository';
import UploadInputSheetRepository from './MatchingRepository/services/UploadInputSheetRepository';
import UploadInputSheetAPI from './MatchingRepository/services/UploadInputSheetAPI';
import BaseMatchingRepository from './MatchingRepository/BaseMatchingRepository';
import { useSettings } from 'settings';
import InputSheetClearer from './MatchingRepository/services/InputSheetClearer';
import NodeProcessingEngine from './engines/node/NodeProcessingEngine';
import BrowserProcessingEngine from './engines/browser/BrowserProcessingEngine';
import BrowserMatchingRepository from './MatchingRepository/BrowserMatchingRepository';

type MatchingContextProviderProps = {
  children: ReactNode;
};

const MatchingContext = createContext<{
  isLoadingModel: boolean;
  matchingRepository: BaseMatchingRepository | undefined;
  executing: boolean;
  setExecuting: (executing: boolean) => void;
}>({
  matchingRepository: undefined,
  isLoadingModel: true,
  executing: true,
  setExecuting: () => {},
});

export const useMatching = () => {
  const context = useContext(MatchingContext);
  return context;
};

const MatchingContextProvider = ({
  children,
}: MatchingContextProviderProps) => {
  const [isLoadingModel] = useState(false);
  const [executing, setExecuting] = useState(true);
  const { processingEngine, enableReuseMapping } = useSettings();

  const matchingRepository = useMemo(() => {
    const browserEngine = new BrowserProcessingEngine();
    const nodeEngine = new NodeProcessingEngine();

    if (processingEngine === 'node') {
      const uploadInputSheetAPI = new UploadInputSheetAPI();
      const inputSheetClearer = new InputSheetClearer(uploadInputSheetAPI);
      const uploadRepo = new UploadInputSheetRepository(
        uploadInputSheetAPI,
        inputSheetClearer
      );

      const repo = new NodeMatchingRepository(uploadRepo, nodeEngine);

      if (enableReuseMapping) repo.setIgnoreReuseMappingColumn(false);

      return repo;
    } else {
      const repo = new BrowserMatchingRepository(browserEngine, nodeEngine);

      return repo;
    }
  }, [processingEngine, enableReuseMapping]);

  return (
    <MatchingContext.Provider
      value={{
        matchingRepository,
        isLoadingModel,
        executing,
        setExecuting,
      }}
    >
      {children}
    </MatchingContext.Provider>
  );
};

export default MatchingContextProvider;
