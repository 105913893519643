import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { ICleaningAssistantGroup } from '../../api/CleaningAssistant.dto';
import { ChevronRight } from 'lucide-react';

interface IProps {
  group: ICleaningAssistantGroup;
  index: number;
}

const CleaningGroup: React.FC<IProps> = ({ group, index }: IProps) => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const { setSelectedGroupIndex } = context;
  const s = styles(context);

  const onClick = () => {
    setSelectedGroupIndex(index);
  };

  return (
    <div className={s.rootClass} onClick={onClick}>
      <span className={s.nameClass}>{group.column}</span>
      <span className={s.dividerClass} />
      <span className={s.countClass}>
        {t('txt_cleanings', { count: group.suggestions.length })}
      </span>

      <ChevronRight className={s.iconClass} size={16} />
    </div>
  );
};

export default CleaningGroup;
