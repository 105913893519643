import { DataModel } from '../../../../dataModel/model/DataModel';

const useViewModel = ({
  dataModel,
  deleteColumn,
}: {
  dataModel: DataModel;
  deleteColumn: (columnKey: string) => void;
}) => {
  const onMenuClick = () => {
    deleteColumn(dataModel.getKey());
  };

  return { onMenuClick };
};

export default useViewModel;
