import React from 'react';
import { FormElement } from '@getnuvo/ui-kit';
import { TextareaControl } from '../controls/TextareaControl';

export const CleaningFunction = () => {
  return (
    <div className="nuvo-sdk__form-block nuvo-sdk__codeArea">
      <FormElement label="Column Hook">
        <TextareaControl placeholder="({})" name="columnHook" />
      </FormElement>

      <FormElement label="onEntryInit">
        <TextareaControl
          placeholder="(row, rowIndex) => {}"
          name="entryInitHook"
        />
      </FormElement>

      <FormElement label="onEntryChange">
        <TextareaControl
          placeholder="(row, rowIndex) => {}"
          name="entryChangeHook"
        />
      </FormElement>

      <FormElement label="onResult">
        <TextareaControl placeholder="() => {}" name="resultHook" />
      </FormElement>

      <FormElement label="onCancel">
        <TextareaControl placeholder="() => {}" name="cancelHook" />
      </FormElement>
    </div>
  );
};
