import { css, cx } from 'core/emotion';
import { Variant } from '../types';
import { useVariant } from './variant';
import { useTheme } from 'theme';
import { useMemo } from 'react';

export const useStyle = ({
  variant,
  open,
}: {
  variant: Variant;
  open: boolean;
  classname?: string;
}) => {
  const theme = useTheme();
  const hoverBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark600Color();
  }, [theme]);
  const { getVariantClassname, getOpenClassname } = useVariant({
    open,
    variant,
  });

  const menuButtonClassname = cx(
    getVariantClassname(),
    getOpenClassname(),
    cx(
      'text-sm text-color-primary w-full h-8.5 inset py-2 pl-3 pr-8 relative inline-flex font-normal leading-5 items-center border rounded-medium disabled:opacity-80 outline-none bg-white',
      css({
        ':hover': {
          borderColor: hoverBorderColor,
        },
      })
    )
  );

  return {
    menuButtonClassname,
  };
};
