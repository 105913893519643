import { DATATYPE } from '../../dataType';
import { parseNumberStringToNumber } from '../../number';
import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isBoolean, isNil, isNumber, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';
import { NumberFormat } from '../../dataModel/columnsAPI';
import { NumberParser } from '../../utils/NumberParser';

class CurrencyParser implements ValueParser {
  parse = (dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (isArray(value) || isObject(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return '';
    }

    if (value === '') {
      return '';
    }

    const valueNumber = isNumber(value)
      ? value
      : parseNumberStringToNumber(`${value}`, true);

    if (isNaN(valueNumber)) {
      return `${value}`;
    }

    const targetFormat =
      dataModel.getType() === DATATYPE.CURRENCY_EUR
        ? NumberFormat.EU
        : NumberFormat.US;
    const resultNumber = NumberParser.convertToFormat(valueNumber, {
      targetFormat,
    });

    if (dataModel.getType() === DATATYPE.CURRENCY_EUR) {
      return `${resultNumber} €`;
    } else {
      return `${resultNumber} $`;
    }
  };
}

export default CurrencyParser;
