import { isArray, isNumber, uniq } from 'lodash';
import {
  DropdownOptionValidate,
  DropdownOptionValidation,
  ValidateOperators,
} from '../../../dataModel/columnsAPI';
import { FieldValue } from '../../../value';
import { Option } from '../../../dataModel/model/CategoryDataModel';
import hash from 'object-hash';
import { CacheOption } from '../../../constants/cacheOption';
import { CacheOptionItem } from './IOptionsValidators';

const compareOperators = ['GTE', 'GT', 'LT', 'LTE', 'NEQ', 'EQ'] as const;
const relationalOperators = ['AND', 'OR', 'NOT'] as const;
const allOperators = [...compareOperators, ...relationalOperators];

type CompareOperator = (typeof compareOperators)[number];
type RelationalOperator = (typeof relationalOperators)[number];

export class OptionValidator {
  private numberComparator = (actual: FieldValue, expected: FieldValue) => {
    if (isNumber(actual) || isNumber(expected)) {
      return Number(actual) === Number(expected);
    } else {
      return actual === expected;
    }
  };

  private comparisonValidate = (
    compareOperator: CompareOperator,
    actual: FieldValue | FieldValue[],
    expected: FieldValue[]
  ) => {
    const isExpectedArray = isArray(expected);

    if (isArray(actual)) {
      return this.multipleComparisonValidate(compareOperator, actual, expected);
    }
    if (compareOperator === 'NEQ') {
      return isExpectedArray
        ? expected.every((expectedItem) => {
            return !this.numberComparator(actual, expectedItem);
          })
        : !this.numberComparator(actual, expected);
    } else {
      const expectedItem = isExpectedArray ? expected[0] : expected;
      switch (compareOperator) {
        case 'GTE':
          return Number(actual) >= Number(expectedItem);
        case 'GT':
          return Number(actual) > Number(expectedItem);
        case 'LT':
          return Number(actual) < Number(expectedItem);
        case 'LTE':
          return Number(actual) <= Number(expectedItem);
      }
      return isExpectedArray
        ? expected.some((expectedItem) =>
            this.numberComparator(actual, expectedItem)
          )
        : this.numberComparator(actual, expected);
    }
  };

  private multipleComparisonValidate = (
    compareOperator: CompareOperator,
    actual: FieldValue[],
    expected: FieldValue[]
  ) => {
    let neqCounter = 0;
    for (let i = 0; i < actual.length; i++) {
      const value = actual[i];
      if (this.comparisonValidate(compareOperator, value, expected)) {
        if (compareOperator === 'NEQ') {
          neqCounter = neqCounter + 1;
          if (neqCounter === actual.length) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  };

  private relationValidate = (
    compareOperator: RelationalOperator,
    condition1: boolean,
    condition2: boolean
  ) => {
    switch (compareOperator) {
      case 'AND':
        return condition1 && condition2;
      case 'OR':
        return condition1 || condition2;
    }

    return false;
  };

  private recValidateItem = (
    row: Record<string, FieldValue>,
    validate: DropdownOptionValidate,
    operator: ValidateOperators
  ): boolean => {
    let lastResult: boolean | null = null;

    if (relationalOperators.includes(operator as RelationalOperator)) {
      for (const [key] of Object.entries(validate)) {
        if (!allOperators.includes(key.split('_')?.[0] as ValidateOperators)) {
          return this.recValidateItem(row, validate, 'EQ');
        }
      }
    }

    for (const [operatorKey] of Object.entries(validate)) {
      const currentOperator = operatorKey as ValidateOperators;
      const validator = validate[currentOperator] as DropdownOptionValidate;
      const splitCurrentOperator = currentOperator?.split(
        '_'
      )?.[0] as ValidateOperators;
      if (relationalOperators.includes(operator as RelationalOperator)) {
        if (lastResult === null) {
          lastResult = this.recValidateItem(
            row,
            validator,
            splitCurrentOperator
          );
        } else {
          lastResult = this.relationValidate(
            operator as RelationalOperator,
            lastResult,
            this.recValidateItem(row, validator, splitCurrentOperator)
          );
        }
      } else {
        for (let i = 0; i < Object.keys(validate).length; ++i) {
          const columnKey = Object.keys(validate)[i] as ValidateOperators;
          const actualValue = row[columnKey];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const compareValue = validate[columnKey] as any;

          if (lastResult === null) {
            lastResult = this.comparisonValidate(
              operator as CompareOperator,
              actualValue,
              compareValue
            );
          } else {
            lastResult = this.relationValidate(
              'AND',
              lastResult,
              this.comparisonValidate(
                operator as CompareOperator,
                actualValue,
                compareValue
              )
            );
          }
        }
      }
    }

    return lastResult ?? false;
  };

  private getCacheOption(hash: string) {
    try {
      const cacheOption: CacheOptionItem[] = JSON.parse(
        localStorage.getItem(CacheOption) ?? '[]'
      );

      for (const iterator of cacheOption) {
        if (iterator[hash]) {
          return iterator;
        }
      }
      return;
    } catch (err) {
      console.warn(err);
      return;
    }
  }

  private getCacheOptionByColumnKey(hash: string, columnKey: string) {
    const values = this.getCacheOption(hash);
    return values?.[hash]?.[columnKey];
  }

  private setCacheOption(hash: string, value: Option[], columnKey: string) {
    try {
      const cacheOptions: CacheOptionItem[] = JSON.parse(
        localStorage.getItem(CacheOption) ?? '[]'
      );
      const previousValue = this.getCacheOption(hash) ?? {};
      if (!previousValue?.[hash]) {
        previousValue[hash] = {};
      }
      previousValue[hash][columnKey] = value ?? [];

      let targetIndex = -1;

      for (const [index, value] of cacheOptions.entries()) {
        if (value[hash]) {
          targetIndex = index;
        }
      }

      for (let i = 0; i < cacheOptions.length; i++) {
        const option = cacheOptions[i];

        for (const [key] of Object.entries(option)) {
          if (hash === key) {
            targetIndex = i;
            break;
          }
        }
      }

      if (targetIndex >= 0) {
        cacheOptions[targetIndex] = previousValue;
      } else {
        cacheOptions.push(previousValue);
      }

      if (cacheOptions.length > 20) {
        cacheOptions.splice(0, 1);
      }

      localStorage.setItem(CacheOption, JSON.stringify(cacheOptions));
    } catch (err) {
      console.warn(err);
    }
  }

  private getRelatedColumnKeyByOptions(options: Option[]) {
    let relatedColumnKey: string[] = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const validations = option.validations ?? [];

      for (let j = 0; j < validations.length; j++) {
        const validation = validations[j]?.validate;
        relatedColumnKey = this.getRelatedColumnKeyByValidation(
          validation,
          relatedColumnKey
        );
      }
    }

    return uniq(relatedColumnKey);
  }

  private getRelatedColumnKeyByValidation(
    validation: DropdownOptionValidate,
    relatedColumnKey: string[]
  ) {
    if (typeof validation === 'object') {
      for (const [key] of Object.entries(validation)) {
        if (
          !allOperators.includes(key as CompareOperator & RelationalOperator) &&
          key !== 'validate' &&
          key !== 'errorMessage'
        ) {
          relatedColumnKey.push(key);
        } else {
          this.getRelatedColumnKeyByValidation(
            validation[key] as DropdownOptionValidate,
            relatedColumnKey
          );
        }
      }
    }
    return relatedColumnKey;
  }

  validateOption(
    row: Record<string, FieldValue>,
    validate: DropdownOptionValidate
  ) {
    return this.recValidateItem(row, validate, 'AND');
  }

  validateOptions = (
    row: Record<string, FieldValue>,
    validations: DropdownOptionValidation[]
  ) => {
    for (let i = 0; i < validations.length; ++i) {
      const result = this.validateOption(row, validations[i].validate);
      if (!result) {
        return {
          valid: false,
          errorMessage: validations[i].errorMessage,
        };
      }
    }

    return {
      valid: true,
    };
  };

  getFilterOptions(
    columnKey: string,
    options: Option[],
    rowValue: Record<string, FieldValue>
  ) {
    const tempNewOptions: Option[] = [];
    const relatedColumnKeys = this.getRelatedColumnKeyByOptions(options);
    const parsedObj: Record<string, FieldValue> = {};

    for (let i = 0; i < relatedColumnKeys.length; i++) {
      const key = relatedColumnKeys[i];
      parsedObj[key] = rowValue[key];
    }

    const cacheHash = hash(parsedObj);
    const targetCacheOption = this.getCacheOptionByColumnKey(
      cacheHash,
      columnKey
    );

    if (targetCacheOption) {
      return {
        options: targetCacheOption.length ? targetCacheOption : options,
        source: 'cache',
      };
    } else {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const validations = option.validations ?? [];
        const resultValidation: boolean[] = [];
        for (let j = 0; j < validations.length; j++) {
          const validation = validations[j]?.validate;
          resultValidation.push(
            this.validateOption(rowValue, validation as DropdownOptionValidate)
          );
        }
        if (!resultValidation.some((entry) => entry === false)) {
          tempNewOptions.push(option);
        }
      }
      this.setCacheOption(cacheHash, tempNewOptions, columnKey);
    }
    return {
      options: tempNewOptions.length ? tempNewOptions : options,
      source: 'new',
    };
  }

  /* istanbul ignore next */
  hasOptionsFilter(
    columnKey: string,
    options: Option[],
    rowValue: Record<string, FieldValue>
  ) {
    const relatedColumnKeys = this.getRelatedColumnKeyByOptions(options);
    const parsedObj: Record<string, FieldValue> = {};

    for (let i = 0; i < relatedColumnKeys.length; i++) {
      const key = relatedColumnKeys[i];
      parsedObj[key] = rowValue[key];
    }

    const cacheHash = hash(parsedObj);
    const targetCacheOption = this.getCacheOptionByColumnKey(
      cacheHash,
      columnKey
    );

    if (targetCacheOption) {
      return !!targetCacheOption.length;
    } else {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const validations = option.validations ?? [];
        const resultValidation: boolean[] = [];
        for (let j = 0; j < validations.length; j++) {
          const validation = validations[j]?.validate;
          resultValidation.push(
            this.validateOption(rowValue, validation as DropdownOptionValidate)
          );
        }
        if (!resultValidation.some((entry) => entry === false)) {
          return true;
        }
      }
    }
    return false;
  }
}
