import { css, cx } from '../../core/emotion';
import useViewModel from './viewModel';
import { Variant } from './variantStyle';
import { Fragment, useEffect, useRef } from 'react';
import chroma from 'chroma-js';

export interface Props extends React.ComponentProps<'button'> {
  loading?: boolean;
  variant?: Variant;
  size?: 'small' | 'tiny' | 'normal';
  hideDisableStyle?: boolean;
  textClassName?: string;
  noSpan?: boolean;
  loadingStyle?: string;
}

export const Loader = ({ className }: { className?: string }) => {
  const circleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (circleRef.current) {
      const style = window.getComputedStyle(circleRef.current);
      const opacityBorder = chroma(style.borderLeftColor).alpha(0.3).hex();

      circleRef.current.style.borderLeftColor = opacityBorder;
      circleRef.current.style.borderRightColor = opacityBorder;
      circleRef.current.style.borderBottomColor = opacityBorder;
      circleRef.current.style.borderTopColor = style.borderColor;
    }
  }, [circleRef]);

  return <div ref={circleRef} className={cx('circle-loader', className)} />;
};

const Button = ({
  children,
  loading,
  className,
  variant = 'primary',
  size = 'normal',
  hideDisableStyle = false,
  textClassName,
  noSpan = false,
  loadingStyle,
  ...props
}: Props) => {
  const { variantClassname, sizeClassname } = useViewModel({
    variant,
    size,
    hideDisableStyle,
  });

  return (
    <button
      type="button"
      {...props}
      className={cx(
        className,
        variantClassname,
        sizeClassname,
        `rounded-medium focus:outline-none transition-width inline-flex items-center justify-center border-transparent text-sm font-semibold leading-3`,
        !hideDisableStyle ? 'disabled:cursor-not-allowed' : '',
        css`
          padding-top: 7px;
          padding-bottom: 7px;
        `
      )}
    >
      {loading && (
        <span>
          <span className={`-ml-1 mr-2 ${loadingStyle}`}>
            <Loader
              className={
                size === 'small' ? 'relative top-px !h-4 !w-4 !border-2' : ''
              }
            />
          </span>
        </span>
      )}
      {noSpan ? (
        <Fragment key="text-button">{children}</Fragment>
      ) : (
        <span className={cx('relative flex leading-6', textClassName)}>
          {children}
        </span>
      )}
    </button>
  );
};

export default Button;
