import { DataModel } from '../dataModel/model/DataModel';
import { CSSInterpolation } from '../emotion';
import { HookedRecordInfoLevel, HookedRecordResult } from '../hooks/hooksAPI';
import { FieldValue } from '../value';

export type ConfigTheme = {
  root: CSSInterpolation;
  th: CSSInterpolation;
  td: {
    root?: CSSInterpolation;
    normal?: CSSInterpolation;
    error?: CSSInterpolation;
    warning?: CSSInterpolation;
    info?: CSSInterpolation;
  };
  selectedBackground: {
    normal?: string;
    error?: string;
    warning?: string;
    info?: string;
  };
  infoIcon?: CSSInterpolation;
  exampleRow?: CSSInterpolation;
  popover?: PopoverThemeProps;
};

export type PopoverThemeProps = {
  root?: CSSInterpolation;
  info?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  warning?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  error?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
};

export enum RECORD_INFO_SOURCE {
  CHANGE = 'CHANGE',
}

export type RecordInfo = {
  rowIndex: number;
  colIndex: number;
  popover: {
    message: string;
    level: HookedRecordInfoLevel;
    source?: RECORD_INFO_SOURCE;
  };
};

export type RecordResult = {
  value?: Record<string, FieldValue>;
  info?: RecordInfo[];
};

export enum CELL_TYPE_CLASS {
  DEFAULT = 'default-cell',
  INFO = 'info-cell',
  ERROR = 'error-cell',
  WARNING = 'warning-cell',
}

export type CellCoordsDimension = {
  row: number;
  col: number;
};

export type FieldMeta = {
  level: HookedRecordInfoLevel;
  message: string;
};

export type HandleChangeInfoFn = (
  entryChange: Record<string, HookedRecordResult>,
  rowIndex: number,
  dataModels: DataModel[]
) => void;
