import { falsyWords, truthyWords } from '../../constants/boolean';
import { isArray, isNil, isObject } from 'lodash';
import ValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';

class BooleanValueParser implements ValueParser {
  parse = (value: PossibleValue, _options?: IValueParserOptions) => {
    if (isNil(value)) {
      return undefined;
    }

    if (isArray(value) || isObject(value)) {
      return undefined;
    }

    if (truthyWords().includes(`${value}`.toLowerCase())) {
      return 'true';
    }

    if (falsyWords().includes(`${value}`.toLowerCase())) {
      return 'false';
    }

    return `${value}`;
  };
}

export default BooleanValueParser;
