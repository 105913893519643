import { PossibleValue } from '../dataModelSheet/valueParser/IValueParser';
import {
  cleanSpecialCharAndToLowercase,
  transformDataSpecial,
} from '../replaceRegex';
import { Option } from './model/CategoryDataModel';
import { DataModel } from './model/DataModel';

export const findDataModel = (
  dataModels: DataModel[],
  key: string,
  isAutoMapping?: boolean
) => {
  for (let i = 0; i < dataModels.length; i++) {
    let dataModelKey = dataModels[i].getKey();
    let targetKey = key;

    if (isAutoMapping) {
      dataModelKey = cleanSpecialCharAndToLowercase(dataModelKey);
      targetKey = cleanSpecialCharAndToLowercase(targetKey);
    }

    if (dataModelKey === targetKey) {
      return dataModels[i];
    }
  }
  return null;
};

export const findColIndexByKey = (key: string, dataModels: DataModel[]) => {
  for (let i = 0; i < dataModels.length; i++) {
    if (dataModels[i].getKey() === key) {
      return i;
    }
  }

  return -1;
};

export const isDropdownOptionEqual = (option: Option, value: PossibleValue) => {
  return `${option.value}`.toLowerCase() === `${value}`.toLowerCase();
};

type CustomKeyOption = {
  baseKey?: string;
  baseKeyCounter?: number;
};

export type CustomOptionMeta = {
  key: {
    value: string;
    baseKey: string;
    baseKeyCounter: number;
  };
};

const getMaxCounterByKey = (
  inputBaseKey: string,
  options: CustomKeyOption[]
) => {
  let counter = 0;
  for (let i = 0; i < options.length; i++) {
    const entry = options[i];
    const counterKey = 'baseKeyCounter';
    const compareKey = 'baseKey';

    if (
      entry?.[counterKey] &&
      inputBaseKey === entry?.[compareKey] &&
      counter < (entry?.[counterKey] ?? 0)
    ) {
      counter = entry?.[counterKey] || 0;
    }
  }

  return counter;
};

const isNewOptionByKey = (options: CustomKeyOption[], baseKey: string) => {
  return !options.some((entry) => entry.baseKey === baseKey);
};

export const generateKey = (
  cleanedSearchValue: string,
  options: CustomKeyOption[],
  transformDataSpecialToKey = true
): CustomOptionMeta => {
  const baseKey = transformDataSpecialToKey
    ? transformDataSpecial(cleanedSearchValue)
    : cleanedSearchValue;
  let newCounterKey = getMaxCounterByKey(baseKey, options) + 1;

  let keyValue = baseKey;

  if (isNewOptionByKey(options, baseKey)) {
    newCounterKey--;
  } else if (newCounterKey > 0) {
    keyValue = `${baseKey}_${newCounterKey}`;
  }

  return {
    key: {
      value: keyValue,
      baseKey,
      baseKeyCounter: newCounterKey,
    },
  };
};
