import { useCallback, useEffect, useRef, useState } from 'react';
import { useLicenseKeyAuth } from '../license/Authorization/AuthProvider';
import useLocationPathname from 'core/location';

interface IElementOptions {
  rootViewId: string;
  isShowElement: boolean;
  identifier: string;
  targetEleId: string;
  rootTargetEleId: string;
  secondaryRootTargetEleId?: string;
  predicateRootTarget?: (mutations: MutationRecord[]) => boolean;
}

const baseConfig = { childList: true, subtree: true };
const fullConfig = {
  ...baseConfig,
  attributes: true,
  characterData: true,
  characterDataOldValue: true,
};

export const useDomMutationSpy = (options: IElementOptions) => {
  const [isVisible, setVisible] = useState(true);
  const { isLoading } = useLicenseKeyAuth();
  const { pathname } = useLocationPathname();

  const observerTextEle = useRef<Record<string, MutationObserver>>({});
  const observerRootViewEle = useRef<Record<string, MutationObserver>>({});
  const observerRootTargetEle = useRef<Record<string, MutationObserver>>({});
  const observerSecondaryRootTargetEle = useRef<
    Record<string, MutationObserver>
  >({});

  const getTargetElement = useCallback(() => {
    const element = document.getElementById(
      `${options.targetEleId}-${options.identifier}`
    );
    return element;
  }, [options.identifier, options.targetEleId]);

  const listenerForSecondaryRootTarget = useCallback(
    (identifier: string) => {
      observerSecondaryRootTargetEle.current[identifier]?.disconnect();
      const targetSecondaryRootElement = options.secondaryRootTargetEleId
        ? document.getElementById(
            `${options.secondaryRootTargetEleId}-${identifier}`
          )
        : null;
      if (targetSecondaryRootElement) {
        observerSecondaryRootTargetEle.current[identifier] =
          new MutationObserver((_mutations: MutationRecord[]) => {
            setVisible(false);
          });

        observerSecondaryRootTargetEle.current[identifier].observe(
          targetSecondaryRootElement,
          fullConfig
        );
      }
    },
    [options.secondaryRootTargetEleId]
  );

  const listenerForRootTarget = useCallback(
    (identifier: string) => {
      observerRootTargetEle.current[identifier]?.disconnect();
      const targetRootElement = options.rootTargetEleId
        ? document.getElementById(`${options.rootTargetEleId}-${identifier}`)
        : null;
      if (targetRootElement) {
        observerRootTargetEle.current[identifier] = new MutationObserver(
          (mutations: MutationRecord[]) => {
            if (options?.predicateRootTarget) {
              const predicate = options?.predicateRootTarget?.(mutations);
              setVisible(predicate);
            } else {
              setVisible(false);
            }
          }
        );

        observerRootTargetEle.current[identifier].observe(
          targetRootElement,
          fullConfig
        );
      }
    },
    [options]
  );

  const listenerForTextElement = useCallback(() => {
    const targetTextElement = getTargetElement();
    if (targetTextElement) {
      observerTextEle.current[options.identifier] = new MutationObserver(
        (_mutations: MutationRecord[]) => {
          setVisible(false);
        }
      );
      observerTextEle.current[options.identifier].observe(
        targetTextElement,
        fullConfig
      );
    } else {
      setVisible(false);
    }
  }, [getTargetElement, options.identifier]);

  const forceListener = useCallback(
    (
      targetRoot: 'rootTargetEleId' | 'secondaryRootTargetEleId',
      identifier: string
    ) => {
      if (targetRoot === 'secondaryRootTargetEleId') {
        listenerForSecondaryRootTarget(identifier);
      } else {
        listenerForRootTarget(identifier);
      }
    },
    [listenerForRootTarget, listenerForSecondaryRootTarget]
  );

  const forceTargetListener = useCallback(() => {
    listenerForTextElement();
  }, [listenerForTextElement]);

  useEffect(() => {
    if (isLoading) return;

    if (!options.isShowElement) {
      setVisible(true);
      return;
    }

    const rootViewElement = document.getElementById(options.rootViewId);
    listenerForRootTarget(options.identifier);
    listenerForSecondaryRootTarget(options.identifier);
    listenerForTextElement();

    if (rootViewElement) {
      observerRootViewEle.current[options.identifier] = new MutationObserver(
        (_mutations: MutationRecord[]) => {
          const ele = getTargetElement();
          setVisible(ele !== null);
        }
      );
      observerRootViewEle.current[options.identifier].observe(
        rootViewElement,
        baseConfig
      );
    }

    const observerTextEleIdentifier =
      observerTextEle?.current[options.identifier];
    const observerRootViewEleIdentifier =
      observerRootViewEle?.current[options.identifier];
    const observerRootTargetEleIdentifier =
      observerRootTargetEle?.current[options.identifier];
    const observerSecondaryRootTargetEleIdentifier =
      observerSecondaryRootTargetEle?.current[options.identifier];

    return () => {
      observerTextEleIdentifier?.disconnect();
      observerRootViewEleIdentifier?.disconnect();
      observerRootTargetEleIdentifier?.disconnect();
      observerSecondaryRootTargetEleIdentifier?.disconnect();
    };
  }, [
    isLoading,
    pathname,
    options.rootViewId,
    options.rootTargetEleId,
    options.secondaryRootTargetEleId,
    options.identifier,
    options.isShowElement,
    getTargetElement,
    listenerForRootTarget,
    listenerForSecondaryRootTarget,
    listenerForTextElement,
  ]);

  return { isVisible, setVisible, forceListener, forceTargetListener };
};
