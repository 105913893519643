/* istanbul ignore file */
import TextInfo from 'baseUI/TextInfo';
import { TEXT_DEV_BANNER } from 'core/constants/style';

const DevelopMode = ({
  identifier,
  isLoading,
}: {
  identifier: string;
  isLoading?: boolean;
}) => {
  return (
    <div
      className={`${
        isLoading ? 'invisible' : 'visible'
      } bg-blue-light-50 rounded-full px-2 py-1`}
    >
      <TextInfo
        identifier={identifier}
        text={TEXT_DEV_BANNER}
        textSize="small"
        textColor="text-blue-dark-900"
      />
    </div>
  );
};

export default DevelopMode;
