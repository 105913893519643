import { css, CSSObject } from 'core/emotion';
import { CategoryDataModel, DataModel } from 'dataModel';
import { useMemo } from 'react';
import SheetColumn from './../../../../../sheetImporter/SheetColumn';
import { Row } from '../../common/rowExamples';
import ExampleValues from '../../ExampleValues';
import OptionsMatcher from '../../OptionsMatcher';
import { ColumnMatchThemeAPI } from '../types';
import DataModelSheetMatcher from './../../../../../matching/DataModelSheetMatcher';
import { useTheme } from '../../../../../theme';

const Previewer = ({
  matchedDataModel,
  sheetColumn,
  prefixName,
  rowExamples,
  configTheme,
  dataModelSheetMatcher,
  loadingMatchingMoreOptions,
}: {
  matchedDataModel?: DataModel;
  sheetColumn: SheetColumn;
  dataModelSheetMatcher: DataModelSheetMatcher;
  prefixName: string;
  rowExamples: Row[];
  configTheme?: ColumnMatchThemeAPI;
  loadingMatchingMoreOptions: boolean;
}) => {
  const theme = useTheme();

  const memoConfigTheme = useMemo(() => {
    return {
      root: css({ '&&': configTheme?.columnMatchValue?.root }),
      borderColor: css({
        '&&': {
          borderColor: (configTheme?.columnMatchValue?.root as CSSObject)
            ?.borderColor,
        },
      }),
      color: css({
        '&&': {
          color: (configTheme?.columnMatchValue?.root as CSSObject)?.color,
        },
      }),
      emptyValue: css({
        '&&': configTheme?.columnMatchValue?.emptyValue,
      }),
      icon: css`
        .color {
          color: ${(configTheme?.columnMatchValue?.icon as CSSObject)?.color ??
          '#c5c5c5'};
          ${configTheme?.columnMatchValue?.icon}
        }
      `,
      configThemeMenuButton: {
        root: css({
          '&&': configTheme?.columnMatchValue?.dropdown?.button?.root,
        }),
        closeIcon: css({
          '&&': {
            color:
              (
                configTheme?.columnMatchValue?.dropdown?.button
                  ?.icon as CSSObject
              )?.color ?? '#C5C5C5',
          },
        }),
        arrowIcon: css({
          '&&': {
            color:
              (
                configTheme?.columnMatchValue?.dropdown?.button
                  ?.root as CSSObject
              )?.color ?? '#545454',
          },
        }),
        placeholder: css({
          '&&': {
            color: configTheme?.columnMatchValue?.dropdown?.button?.placeholder,
          },
        }),
      },
      configThemeMenuItem: {
        root: css({
          '&&': configTheme?.columnMatchValue?.dropdown?.root,
        }),
        scrollbarNavigatorColor: css({
          '&& .simplebar-scrollbar:before': {
            backgroundColor: `${configTheme?.columnMatchValue?.dropdown?.scrollbar?.navigatorColor} !important`,
          },
        }),
        scrollbarBackgroundColor: css({
          '&& .simplebar-track': {
            backgroundColor: `${configTheme?.columnMatchValue?.dropdown?.scrollbar?.backgroundColor} !important`,
          },
        }),
        option: css({
          '&&': configTheme?.columnMatchValue?.dropdown?.option,
        }),
        selectedOption: css({
          '&&': configTheme?.columnMatchValue?.dropdown?.selectedOption,
        }),
        scrollbar: {
          backgroundColor:
            configTheme?.columnMatchValue?.scrollbar?.backgroundColor ??
            '#dfe2e4',
          navigatorColor:
            configTheme?.columnMatchValue?.scrollbar?.navigatorColor ??
            '#b3bcc0',
        },
        header: css({ '&&': configTheme?.columnMatchValue?.dropdown?.header }),
        search: {
          root: css({
            '&&': configTheme?.columnMatchValue?.dropdown?.search?.root,
          }),
          icon: css`
            .color {
              color: #383838;
              ${configTheme?.columnMatchValue?.dropdown?.search?.icon}
            }
          `,
          placeholder: css`
            ::placeholder {
              color: ${configTheme?.columnMatchValue?.dropdown?.search
                ?.placeholder};
              opacity: 1;
            }
          `,
        },
        createNewOption: {
          root: css({
            '&&': configTheme?.columnMatchValue?.dropdown?.createNewOption
              ?.root,
          }),
          icon: css`
            color: #162338;
            ${configTheme?.columnMatchValue?.dropdown?.createNewOption?.icon}
          `,
        },
        multiSelectionBadge: {
          root: css(
            {
              '&&': configTheme?.columnMatchValue?.dropdown?.multiSelectionBadge
                ?.root,
            },
            css`
              background-color: ${(
                configTheme?.columnMatchValue?.dropdown?.multiSelectionBadge
                  ?.root as CSSObject
              )?.backgroundColor ?? '#D0DBED80'};
            `
          ),
          icon: css`
            color: ${theme.getGlobalTheme().getDark500Color()};
            ${configTheme?.columnMatchValue?.dropdown?.multiSelectionBadge
              ?.icon}
          `,
        },
        iconCheckedColor:
          configTheme?.columnMatchValue?.dropdown?.iconCheckedColor,
      },
    };
  }, [configTheme, theme]);

  if (matchedDataModel && matchedDataModel.isDropdown()) {
    return (
      <div>
        <OptionsMatcher
          matchedDataModel={matchedDataModel as CategoryDataModel}
          sheetColumn={sheetColumn}
          dataModelSheetMatcher={dataModelSheetMatcher}
          prefixName={prefixName}
          configTheme={memoConfigTheme}
          loadingMatchingMoreOptions={loadingMatchingMoreOptions}
        />
      </div>
    );
  }

  return <ExampleValues rows={rowExamples} />;
};

export default Previewer;
