import Button from 'baseUI/Button';
import { css, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { ModelTemplate } from 'core/constants/modelTemplate';
import useViewModel from './viewModel';
import { useTheme } from 'theme';
import { useConfigure } from 'configure';
import DownloadTemplateImg from './DownloadTemplateImg';
import {
  BoxSkeleton,
  DownloadTempleViewSkeleton,
  TextSkeleton,
} from '@nuvo-importer/common';

type DownloadTemplateProps = {
  className?: string;
  isLoading: boolean;
};

const DownloadTemplateCard = ({
  className,
  isLoading,
}: DownloadTemplateProps) => {
  const { t } = useTranslation();
  const { downloadTemplateSheet, isExporting } = useViewModel();
  const theme = useTheme();
  const { settings } = useConfigure();

  return (
    <div
      className={cx(
        'border-1 rounded-2lg shadow-card w-400 mb-0 border-gray-100 bg-white p-8',
        className,
        css({ '&&': theme.getDropzoneTheme().content.root })
      )}
    >
      {isLoading ? (
        <DownloadTempleViewSkeleton />
      ) : (
        <>
          <p
            className={cx(
              'text-color-primary mb-2 text-xl font-semibold',
              css({ '&&': theme.getDropzoneTheme().content.title })
            )}
          >
            {t('txt_download_template')}
          </p>
          <p
            className={cx(
              'text-color-secondary text-sm',
              css({ '&&': theme.getDropzoneTheme().content.description })
            )}
          >
            {settings.disableExcelTemplate && !settings.disableTemplates
              ? t('txt_download_only_csv_template_desc', {
                  button_name: t('txt_select_files'),
                })
              : t('txt_download_template_desc', {
                  button_name: t('txt_select_files'),
                })}
          </p>
        </>
      )}
      {isLoading ? (
        <BoxSkeleton className="w-340 h-250 my-4" />
      ) : (
        <DownloadTemplateImg
          customStyle={theme.getDropzoneTheme().content?.illustration}
        />
      )}
      {isLoading ? (
        <TextSkeleton className="h-8 w-full" />
      ) : (
        <div
          className={cx(
            !settings.disableExcelTemplate && !settings.disableTemplates
              ? 'large:grid flex grid-cols-2 justify-center gap-2'
              : 'flex justify-center'
          )}
        >
          {!settings.disableExcelTemplate ? (
            <Button
              variant="secondary"
              className="!px-4"
              loading={isLoading || isExporting}
              disabled={isLoading || isExporting}
              onClick={() => {
                downloadTemplateSheet(ModelTemplate.XLSX);
              }}
            >
              {t('txt_download_excel_modal')}
            </Button>
          ) : null}
          <Button
            variant="secondary"
            loading={isLoading}
            disabled={isLoading}
            className={cx(
              settings.disableExcelTemplate && !settings.disableTemplates
                ? 'w-57'
                : '!px-4'
            )}
            onClick={() => {
              downloadTemplateSheet(ModelTemplate.CSV);
            }}
          >
            {t('txt_download_csv')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DownloadTemplateCard;
