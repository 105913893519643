import { useMemo, useRef } from 'react';
import { JoinSheet, Sheet, displayDateText } from '@nuvo-importer/common/sdk';
import { HotTableClass } from '@handsontable/react';
import { GridSettings } from 'handsontable/settings';
import { useMediaQuery } from 'react-responsive';
import { CSSInterpolation, cx } from 'core/emotion';
import { useLicenseKeyAuth } from 'license';
import { useMainView } from 'settings';
import { maxHeightOfReviewEntries } from 'core/constants/style';
import useBorderStyle from './borderStyle';
import colHeadersView from './colHeaders/view';
import ColHeaderController from './colHeaders/Controller';
import { textRenderer } from 'handsontable/renderers';
import Core from 'handsontable/core';
import { SHEET_TYPE } from '@nuvo-importer/common/core';

type UseViewModelParams = {
  sheet: { state: Sheet | null };
  configTheme?: {
    root: CSSInterpolation;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
};

const widthColumn = 251;

const useViewModel = ({ sheet }: UseViewModelParams) => {
  const { htLicenseKey } = useLicenseKeyAuth();
  const { modal } = useMainView();
  const instance = useRef<HotTableClass>(null);
  const scrollContainerRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const mediaSize = useMediaQuery({
    query: '(max-width: 1440px)',
  });
  const colHeaderController = useMemo(() => {
    return new ColHeaderController();
  }, []);

  const dataSet = useMemo(() => {
    return sheet.state?.getValues() ?? [];
  }, [sheet]);

  const colAmount = useMemo(() => {
    return sheet.state?.getColumns().length ?? 0;
  }, [sheet]);

  const sheetColumns = useMemo(() => {
    return sheet.state?.getColumns() ?? [];
  }, [sheet]);

  const cellStyle = useMemo(
    () => ({
      afterGetRowHeader: (_row: number, TH: HTMLElement) => {
        TH.classList.add('default-cell', 'htMiddle');
      },
      cells: (row: number, col: number) => {
        const cellProperties: { className?: string } = {
          className: 'default-cell htMiddle',
        };
        if (!sheet.state || sheet.state.getType() !== SHEET_TYPE.JOIN) {
          return cellProperties;
        }
        const joinSheet = sheet.state as JoinSheet;
        const joinHistory = joinSheet.getJoinHistory();
        if (joinHistory.length === 0) {
          return cellProperties;
        }
        let previousColLength: number;
        let previousRowLength: number;

        if (joinHistory.length === 1) {
          const joinedSheets = joinSheet.getJoinedSheets();
          previousColLength = joinedSheets[0].getColumns().length;
          previousRowLength = joinedSheets[0].getData().length - 1;
        } else {
          previousColLength = joinHistory[joinHistory.length - 2].col;
          previousRowLength = joinHistory[joinHistory.length - 2].row - 1;
        }

        if (col >= previousColLength || row >= previousRowLength) {
          cellProperties.className += ' nuvo-appended-cell';
        }

        return cellProperties;
      },
    }),
    [sheet.state]
  );

  const settingTable = useMemo(() => {
    const hotSetting: GridSettings = {
      data: dataSet,
      licenseKey: htLicenseKey,
      colHeaders: (index) => {
        return colHeadersView({
          sheetColumn: sheetColumns[index],
        });
      },
      rowHeaders: true,
      autoColumnSize: true,
      autoRowSize: false,
      rowHeaderWidth: 60,
      columnHeaderHeight: mediaSize ? 23 : 33,
      renderAllRows: false,
      height: '100%',
      width: '100%',
      columns: Array(colAmount).fill({
        // NOTE: we can't use arrow function because this need to reference to handsontable instance
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderer: function renderer(this: Core, ...args: any) {
          args[5] = displayDateText(args[5]);
          textRenderer.apply(this, args);
        },
      }),
      stretchH: 'all',
      colWidths: Array(colAmount).fill(widthColumn),
      className: 'htMiddle default-cell',
      rowHeights: mediaSize ? 23 : 33,
      viewportColumnRenderingOffset: 10,
      afterGetColHeader: (colIndex, TH) => {
        TH.className = cx(
          'htMiddle htLeft bg-gray-50 text-sm font-normal text-gray-560 header'
        );
        TH.children[0].className =
          'px-4 htMiddle htLeft h-full flex items-center';
        colHeaderController.registerBadgeEvent(TH, colIndex);
        colHeaderController.registerTruncateEvent(TH, colIndex);
      },
      ...cellStyle,
      outsideClickDeselects: false,
      readOnly: true,
      beforeOnCellMouseDown: (event, coords) => {
        if (coords.row < 0) {
          event.stopImmediatePropagation();
        }
      },
      disableVisualSelection: true,
    };

    return hotSetting;
  }, [
    mediaSize,
    dataSet,
    colAmount,
    htLicenseKey,
    cellStyle,
    sheetColumns,
    colHeaderController,
  ]);

  const styleTable = useMemo(() => {
    const baseHeightRow = mediaSize ? 23 : 33;
    return {
      width: modal ? '100%' : 'calc(100% - 48px)',
      height: Math.min(
        (dataSet.length + 1) * baseHeightRow + 2 + 6,
        maxHeightOfReviewEntries
      ),
      maxHeight: !modal ? '465px' : '100%',
      minHeight: '100%',
    };
  }, [dataSet.length, modal, mediaSize]);

  useBorderStyle({
    colAmount,
    containerRef,
    dataSet,
    instance,
    scrollContainerRef,
    widthColumn,
  });

  return {
    instance,
    settingTable,
    styleTable,
    colHeaderController,
    sheetColumns,
  };
};

export default useViewModel;
