import { css, cx } from 'core/emotion';
import { ReactNode } from 'react';
import { useTheme } from '../../theme/theme';

type CardProps = {
  className?: string;
  children: ReactNode;
};

export const Card = ({ className, children }: CardProps) => {
  const theme = useTheme();
  return (
    <div
      className={cx(
        'sm:max-w-528 my-8 inline-block w-full transform rounded-lg bg-white text-center align-middle shadow-xl transition-all sm:align-middle',
        className,
        css({
          '&&': {
            borderRadius: theme.getGlobalTheme().getBorderRadius(),
          },
        })
      )}
      onClick={(event) => event.stopPropagation()}
    >
      {children}
    </div>
  );
};
