import { useFormContext } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { ISelectOption, Toast } from '@getnuvo/ui-kit';
import { IConfigureForm, IEditTDM, ITargetDataModel } from '../../types';
import {
  DataModelType,
  usePreviewConfig,
} from '../../../../context/PreviewConfigProvider';
import { findTDM } from '../../utils';

type OpenMode = 'new' | 'edit' | null;

const newTdm: IEditTDM = {
  label: '',
  value: '',
  config: '',
};

export const useViewModel = () => {
  const { watch, setValue } = useFormContext<IConfigureForm>();
  const targetDataModel = watch('targetDataModel');

  const [editTdm, setEditTdm] = useState<IEditTDM | null>(null);
  const { dataModels } = usePreviewConfig();

  const tdmOptions: ISelectOption[] = useMemo(() => {
    const filteredDataModels: IEditTDM[] = !dataModels
      ? []
      : dataModels
          .filter((item: ITargetDataModel) => {
            return (
              item.value !== DataModelType.TEST_VERSION &&
              item.value !== DataModelType.CRM
            );
          })
          .map((item: ITargetDataModel) => {
            return {
              value: item.value,
              label: item.label,
              config: JSON.stringify(item.config, null, 2),
            };
          });

    const preloadedDataModels: IEditTDM[] = [
      {
        value: DataModelType.TEST_VERSION,
        label: 'Test Version',
        config: '',
      },
      {
        value: DataModelType.CRM,
        label: 'CRM',
        config: '',
      },
    ];

    const items: IEditTDM[] = filteredDataModels.concat(preloadedDataModels);

    const newItems: ISelectOption[] = [];
    const firstItems: ISelectOption[] = [];

    for (let i = 0; i < items.length; ++i) {
      const element = items[i];

      const option: ISelectOption = {
        id: element.value,
        text: element.label,
      };

      if (option.id === undefined) {
        continue;
      }

      if (option?.text && option.text.includes('SALES DEMO:')) {
        firstItems.push(option);
      } else {
        newItems.push(option);
      }
    }

    newItems.sort((a: ISelectOption, b: ISelectOption) => {
      if (a.text > b.text) return 1;
      else if (a.text < b.text) return -1;
      return 0;
    });

    const divider: ISelectOption = {
      id: '__divider__',
      text: '',
      type: 'divider',
    };

    return [...firstItems, divider, ...newItems];
  }, [dataModels]);

  const toggleModal = useCallback(
    (mode: OpenMode) => () => {
      if (mode === null) {
        setEditTdm(null);
      } else if (mode === 'new') {
        setEditTdm(newTdm);
      } else {
        const tdm: ITargetDataModel | undefined = findTDM(
          dataModels,
          targetDataModel
        );

        if (tdm) {
          setEditTdm({
            ...tdm,
            config: JSON.stringify(tdm.config, null, 2),
          });
        } else {
          Toast.error({
            title: `Could not find target data model ${targetDataModel}`,
          });
        }
      }
    },
    [dataModels, targetDataModel]
  );

  const tdmValue = useMemo(() => {
    return tdmOptions.find((o: ISelectOption) => o.id === targetDataModel);
  }, [tdmOptions, targetDataModel]);

  const changeConfiguration = useCallback(
    (targetDataModel: string) => {
      const tdm = findTDM(dataModels, targetDataModel);
      const cleaningFunctions = tdm?.dataHooks;

      setValue('targetDataModel', targetDataModel);

      setValue('targetDataModelId', tdm?._id);

      if (cleaningFunctions?.columnHooks !== undefined) {
        setValue('columnHook', cleaningFunctions.columnHooks);
      }

      if (cleaningFunctions?.onEntryInit !== undefined) {
        setValue('entryInitHook', cleaningFunctions.onEntryInit);
      }

      if (cleaningFunctions?.onEntryChange !== undefined) {
        setValue('entryChangeHook', cleaningFunctions.onEntryChange);
      }

      if (cleaningFunctions?.onResult !== undefined) {
        setValue('resultHook', cleaningFunctions.onResult);
      }

      if (cleaningFunctions?.onCancel !== undefined) {
        setValue('cancelHook', cleaningFunctions.onCancel);
      }

      if (cleaningFunctions?.preloadData !== undefined) {
        setValue('preloadData', cleaningFunctions.preloadData);
      }

      if (tdm?.style !== undefined) {
        setValue('style', tdm.style);
      }

      if (cleaningFunctions?.dataHandler !== undefined) {
        setValue('dataHandler', cleaningFunctions.dataHandler);
      }
    },
    [dataModels, setValue]
  );

  const onTDMChange = useCallback(
    (option: ISelectOption) => {
      changeConfiguration(option.id);
    },
    [changeConfiguration]
  );

  const applyNextTDM = useCallback(
    (tdm: ITargetDataModel) => {
      changeConfiguration(tdm.value);
    },
    [changeConfiguration]
  );

  return {
    editTdm,
    toggleModal,
    tdmValue,
    tdmOptions,
    onTDMChange,
    applyNextTDM,
  };
};
