import {
  FilterValueItems,
  FilterValuesSelected,
} from '../../../columns/FilterStrategy';

type ViewModelProps = {
  valueItems: FilterValueItems;
  valuesSelected: FilterValuesSelected;
  onValuesSelected: (valuesSelected: FilterValuesSelected) => void;
};

const useViewModel = ({
  valueItems,
  valuesSelected,
  onValuesSelected,
}: ViewModelProps) => {
  const onSelectAllClick = () => {
    const updatedValueItems = { ...valuesSelected };
    valueItems.forEach((item) => {
      updatedValueItems[item.value] = true;
    });
    onValuesSelected(updatedValueItems);
  };

  const onClearClick = () => {
    const updatedValueItems = { ...valuesSelected };
    valueItems.forEach((item) => {
      updatedValueItems[item.value] = false;
    });
    onValuesSelected(updatedValueItems);
  };

  const isActionDisabled = valueItems.length === 0;

  return {
    onSelectAllClick,
    isActionDisabled,
    onClearClick,
  };
};

export default useViewModel;
