import { ReactNode } from 'react';
import { DataModel } from 'dataModel';
import { css, CSSObject, cx } from 'core/emotion';
import PopoverInfo from '../../common/PopoverInfo';
import { ReactComponent as CheckIcon } from '../../../../../assets/icon/check-icon.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/icon/disclaimer.svg';
import { useTheme } from 'theme';
import { Boundary } from '@popperjs/core';
import { OverflowCheckOffset } from 'core/constants/overflowCheckOffset';

export const Check = () => {
  const theme = useTheme();
  return (
    <CheckIcon
      className={cx(
        'flex-shrink-0',
        css({
          '&& .background': {
            fill:
              (
                theme.getColumnMatchTheme()?.matchingTitle
                  ?.checkIcon as CSSObject
              )?.color ?? '#10B981',
          },
        })
      )}
    />
  );
};

export const Warning = () => {
  const theme = useTheme();
  return (
    <WarningIcon
      className={cx(
        'flex-shrink-0',
        css({
          '&& .background': {
            fill:
              (
                theme.getColumnMatchTheme()?.matchingTitle
                  ?.warningIcon as CSSObject
              )?.color ?? '#FFC116',
          },
        })
      )}
    />
  );
};

export const Text = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const theme = useTheme();

  return (
    <p
      className={cx(
        'text-color-primary line-clamp-4 mx-2 break-words text-sm',
        className,
        css({ '&&': theme.getColumnMatchTheme().matchingTitle.root })
      )}
    >
      {children}
    </p>
  );
};

export const DataModelDescription = ({
  matchedDataModel,
  configThemePopoverInfoIcon,
  boundary,
  overflowCheckOffset,
  flip,
}: {
  matchedDataModel: DataModel;
  configThemePopoverInfoIcon?: string;
  boundary?: Boundary;
  overflowCheckOffset?: Partial<OverflowCheckOffset>;
  flip?: boolean;
}) => {
  return (
    <PopoverInfo
      flip={flip}
      boundary={boundary}
      overflowCheckOffset={overflowCheckOffset}
      configTheme={configThemePopoverInfoIcon}
      description={matchedDataModel.getDescription()}
    />
  );
};

export const Wrapper = ({ children }: { children: ReactNode }) => {
  return <div className="flex items-center pt-1">{children}</div>;
};
