export const VALIDATION_KEY = {
  REQUIRED: 'required',
  UNIQUE: 'unique',
  REGEX: 'regex',
  REQUIRED_WITH: 'required_with',
  REQUIRED_WITHOUT: 'required_without',
  REQUIRED_WITH_ALL: 'required_with_all',
  REQUIRED_WITHOUT_ALL: 'required_without_all',
  REQUIRED_WITH_VALUES: 'required_with_values',
  REQUIRED_WITHOUT_VALUES: 'required_without_values',
  REQUIRED_WITH_ALL_VALUES: 'required_with_all_values',
  REQUIRED_WITHOUT_ALL_VALUES: 'required_without_all_values',
  NOT_IN_OPTIONS: 'not_in_options',
  INVALID_FORMAT_NUMBER_FLOAT: 'invalid_format_number_float',
  INVALID_FORMAT_NUMBER_INT: 'invalid_format_number_int',
  REQUIRED_NUMBER: 'required_number',
  REQUIRED_CATEGORY: 'category_required',
  INVALID_FORMAT_DATE: 'invalid_format_date',
  INVALID_FORMAT_DATE_DMY: 'invalid_format_date_dmy',
  INVALID_FORMAT_DATE_MDY: 'invalid_format_date_mdy',
  INVALID_FORMAT_DATE_ISO: 'invalid_format_date_iso',
  INVALID_FORMAT_DATETIME: 'invalid_format_datetime',
  INVALID_FORMAT_TIME_HMS: 'invalid_format_time_hms',
  INVALID_FORMAT_TIME_HMS_24: 'invalid_format_time_hms_24',
  INVALID_FORMAT_TIME_HM: 'invalid_format_time_hm',
  INVALID_FORMAT_TIME_HM_24: 'invalid_format_time_hm_24',
  INVALID_FORMAT_EMAIL: 'invalid_format_email',
  INVALID_FORMAT_URL_WWW: 'invalid_format_url_www',
  INVALID_FORMAT_URL_HTTPS: 'invalid_format_url_https',
  INVALID_FORMAT_URL: 'invalid_format_url',
  INVALID_FORMAT_PHONE: 'invalid_format_phone',
  INVALID_FORMAT_ZIP_CODE_DE: 'invalid_format_zip_code_de',
  INVALID_FORMAT_PERCENTAGE: 'invalid_format_percentage',
  INVALID_FORMAT_COUNTRY_CODE_ALPHA_2: 'invalid_format_country_code_alpha_2',
  INVALID_FORMAT_COUNTRY_CODE_ALPHA_3: 'invalid_format_country_code_alpha_3',
  INVALID_FORMAT_CURRENCY_CODE: 'invalid_format_currency_code',
  INVALID_FORMAT_CURRENCY_EUR: 'invalid_format_currency_eur',
  INVALID_FORMAT_CURRENCY_USD: 'invalid_format_currency_usd',
  INVALID_FORMAT_BIC: 'invalid_format_bic',
  INVALID_FORMAT_VAT_EU: 'invalid_format_vat_eu',
  INVALID_FORMAT_GTIN: 'invalid_format_gtin',
  INVALID_FORMAT_IBAN: 'invalid_format_iban',
  INVALID_OPTIONS_CONDITION: 'invalid_options_condition',
} as const;
