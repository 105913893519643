export const headerCase1 = {
  step: 'header',
  data: [
    [
      'Storage temperature',
      'Product ID',
      'Product Name',
      'Description',
      'Product size',
      'Product size metric',
      'Calorific value (kcal)',
      'Deposit of the outer packaging unit',
      'Number of dispatch stage 1',
      'Price in US',
      'Shipping location',
      'Deposit per unit',
      'Note',
      'Test1',
      'NoteT',
      'Test2',
      'T3',
      'XXX',
      'Blar',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 1',
      '20scoopsCNX-0',
      'Lorem datum',
      123,
      'mooo',
      '100 kcal',
      null,
      '2',
      1,
      'USA',
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 2',
      'Google-1',
      'Lorem datum',
      22,
      null,
      '200 kcal',
      null,
      '4',
      1,
      'GERMAN',
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 3',
      'Facebook-2',
      'Lorem datum',
      33,
      null,
      '300 kcal',
      null,
      '5',
      1,
      'Thailand',
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 4',
      '20scoopsCNX-3',
      'Lorem datum',
      null,
      null,
      '100 kcal',
      null,
      '2',
      1,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 5',
      'Google-4',
      'Lorem datum',
      null,
      null,
      '200 kcal',
      null,
      '4',
      1,
      null,
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 6',
      'Facebook-5',
      'Lorem datum',
      44,
      null,
      '300 kcal',
      null,
      '5',
      1,
      null,
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 7',
      '20scoopsCNX-6',
      'Lorem datum',
      null,
      null,
      '100 kcal',
      null,
      '2',
      1,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 8',
      'Google-7',
      'Lorem datum',
      null,
      null,
      '200 kcal',
      null,
      '4',
      1,
      null,
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 9',
      'Facebook-8',
      'Lorem datum',
      55,
      null,
      '300 kcal',
      null,
      '5',
      1,
      null,
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 10',
      '20scoopsCNX-9',
      'Lorem datum',
      null,
      null,
      '100 kcal',
      null,
      '2',
      1,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 11',
      'Google-10',
      'Lorem datum',
      66,
      null,
      '200 kcal',
      null,
      '4',
      1,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 12',
      'Facebook-11',
      'Lorem datum',
      null,
      null,
      '300 kcal',
      null,
      '5',
      1,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 13',
      '20scoopsCNX-12',
      'Lorem datum',
      null,
      null,
      '100 kcal',
      null,
      '2',
      1,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 14',
      'Google-13',
      'Lorem datum',
      null,
      null,
      '200 kcal',
      null,
      '4',
      1,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 15',
      'Facebook-14',
      'Lorem datum',
      null,
      null,
      '300 kcal',
      null,
      '5',
      1,
      null,
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 16',
      '20scoopsCNX-15',
      'Lorem datum',
      77,
      null,
      '100 kcal',
      null,
      '2',
      1,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 17',
      'Google-16',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      1,
      null,
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 18',
      'Facebook-17',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      1,
      null,
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 19',
      '20scoopsCNX-18',
      'Lorem datum',
      8,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      1,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 20',
      'Google-19',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 21',
      'Facebook-20',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 22',
      '20scoopsCNX-21',
      'Lorem datum',
      99,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 23',
      'Google-22',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 24',
      'Facebook-23',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 25',
      '20scoopsCNX-24',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 26',
      'Google-25',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 27',
      'Facebook-26',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 28',
      '20scoopsCNX-27',
      'Lorem datum',
      44,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 29',
      'Google-28',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 30',
      'Facebook-29',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 31',
      '20scoopsCNX-30',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 32',
      'Google-31',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 33',
      'Facebook-32',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 34',
      '20scoopsCNX-33',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 35',
      'Google-34',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 36',
      'Facebook-35',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 37',
      '20scoopsCNX-36',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 38',
      'Google-37',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 39',
      'Facebook-38',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 40',
      '20scoopsCNX-39',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 41',
      'Google-40',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 42',
      'Facebook-41',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'FALSE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 43',
      '20scoopsCNX-42',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'TRUE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 44',
      'Google-43',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'TRUE',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 45',
      'Facebook-44',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'TRUE',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 46',
      '20scoopsCNX-45',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'NO',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 47',
      'Google-46',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'YES',
      '4',
      null,
      null,
      '0,03',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 48',
      'Facebook-47',
      'Lorem datum',
      null,
      'kg',
      '300 kcal',
      'NO',
      '5',
      null,
      null,
      '0,25',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 49',
      '20scoopsCNX-48',
      'Lorem datum',
      null,
      'g',
      '100 kcal',
      'FALSE',
      '2',
      null,
      null,
      '0,15',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 50',
      'Google-49',
      'Lorem datum',
      null,
      'ml',
      '200 kcal',
      'FALSE',
      '4',
      null,
      null,
      '0,03',
    ],
  ],
  headerIndex: 0,
};

export const headerCase2 = {
  step: 'header',
  data: [
    { id: 123, info: 'boom' },
    { id: 1234, info: 'whoa1' },
    { id: 1234, info: 'whoa2' },
    { id: 1234, info: 'whoa3' },
    { id: 1234, info: 'whoa4' },
    { id: 1234, info: 'whoa5' },
    { id: 1234, info: 'whoa6' },
    { id: 1234, info: 'whoa7' },
    { id: 1234, info: 'whoa8' },
    { id: 1234, info: 'whoa9' },
    { id: 1234, info: 'whoa10' },
  ],
};

export const headerEmptyCase = {
  step: 'header',
  data: [],
};

export const headerCase3 = {
  step: 'header',
  data: [
    ['calorfic value 1', 'packaging 1', 100, 'henry'],
    [
      'calorfic value (kcal)',
      'deposit of the outer packaging unit',
      'price in us',
      'vendor name',
    ],
    ['calorfic value 2', 'packaging 2', 100, 'tle'],
  ],
  headerIndex: 5,
};

export const headerCase4 = {
  step: 'header',
  data: {
    data: [
      {
        date_dmy: '14.01.2022',
        date_mdy: '10.31.2022',
        date_ymd: '2022.10.31',
        date_iso: '31/1/2022',
        datetime: '1/1/2022 00:01',
        time_hms: '12:01:59',
        time_hms_24: '23:01:59',
        time_hm: '12:01',
        time_hm_24: '23:01',
      },
    ],
  },
  headerIndex: 5,
};

export const matchCase1 = {
  step: 'mapping',
  data: [
    [
      'Storage temperature',
      'Product ID',
      'Product Name',
      'Description',
      'Product size',
      'Product size metric',
      'Calorific value (kcal)',
      'Deposit of the outer packaging unit',
      'Number of dispatch stage 1',
      'Price in US',
      'Shipping location',
      'Deposit per unit',
      'Note',
      'Test1',
      'NoteT',
      'Test2',
      'T3',
      'XXX',
      'Blar',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 1',
      '20scoopsCNX-0',
      'Lorem datum',
      123,
      'mooo',
      '100 kcal',
      null,
      '2',
      1,
      'USA',
      '0,15',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
    [
      'frisch: 0° to 2°C',
      'Product 2',
      'Google-1',
      'Lorem datum',
      22,
      null,
      '200 kcal',
      null,
      '4',
      1,
      'GERMAN',
      '0,03',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
      'Lorem datum',
    ],
  ],
};

// Test with Test Version TDM
export const reviewEntriesCase1 = {
  step: 'review',
  data: [
    {
      storage_temperature: {
        value: 'boom value',
        info: [
          {
            message: 'Cat!!!',
            level: 'error',
          },
        ],
      },
      product_id: {
        value: 'boom value',
        info: [
          {
            message: 'Dog!!!',
            level: 'warning',
          },
        ],
      },
    },
    null,
    {
      storage_temperature: {
        value: 'boom value',
        info: [
          {
            message: 'Cat!!!',
            level: 'error',
          },
        ],
      },
      product_id: {
        value: 'boom value',
        info: [
          {
            message: 'Dog!!!',
            level: 'warning',
          },
        ],
      },
    },
  ],
};

// Test with Test Version TDM
export const reviewEntriesCase2 = {
  step: 'review',
  data: [
    {
      storage_temperature: 'boom',
      product_id: 'product',
    },
    null,
    {
      storage_temperature: 'boom2',
      product_id: ['test'],
    },
  ],
};

export const reviewEntriesEmptyCase = {
  step: 'review',
  data: [[]],
};

export const reviewEntriesNilCase = {
  step: 'review',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: undefined as any,
};

export const emptyCase1 = {
  step: 'review',
  data: [],
};

export const emptyCase2 = {
  step: 'header',
  data: {
    data: [],
  },
};

// Test with Test Version TDM
export const notMatchedTDM = {
  step: 'review',
  data: [
    {
      hat: {
        value: 'test',
        info: [],
      },
      storage_temperature: {
        value: 'boom',
        info: [
          {
            message: 'Cat!!!',
            level: 'error',
          },
        ],
      },
      product_id: {
        value: 'boom',
        info: [
          {
            message: 'Dog!!!',
            level: 'warning',
          },
        ],
      },
    },
  ],
};

export const invalidFormat = {
  step: 'review',
  data: {
    hat: {
      value: 'test',
      info: [],
    },
    storage_temperature: {
      value: 'boom',
      info: [
        {
          message: 'Cat!!!',
          level: 'error',
        },
      ],
    },
    product_id: {
      value: 'boom',
      info: [
        {
          message: 'Dog!!!',
          level: 'warning',
        },
      ],
    },
  },
};

export const sheetSelectionCase1 = {
  step: 'sheetSelection',
  data: [
    {
      fileName: 'CRM Data Sample (4).xlsx',
      sheets: [
        {
          sheetName: 'Data',
          data: [
            [
              'Storage temperature',
              'Product ID',
              'Product Name',
              'Description',
              'Product size',
              'Product size metric',
              'Calorific value (kcal)',
              'Deposit of the outer packaging unit',
              'Number of dispatch stage 1',
              'Price in US',
              'Shipping location',
              'Deposit per unit',
              'Note',
              'Test1',
              'NoteT',
              'Test2',
              'T3',
              'XXX',
              'Blar',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 1',
              '20scoopsCNX-0',
              'Lorem datum',
              123,
              'mooo',
              '100 kcal',
              null,
              '2',
              1,
              'USA',
              '0,15',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 2',
              'Google-1',
              'Lorem datum',
              22,
              null,
              '200 kcal',
              null,
              '4',
              1,
              'GERMAN',
              '0,03',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
          ],
        },
        {
          sheetName: 'Kopie von Data',
          data: [
            [
              'Storage temperature',
              'Product ID',
              'Product Name',
              'Description',
              'Product size',
              'Product size metric',
              'Calorific value (kcal)',
              'Deposit of the outer packaging unit',
              'Number of dispatch stage 1',
              'Price in US',
              'Shipping location',
              'Deposit per unit',
              'Note',
              'Test1',
              'NoteT',
              'Test2',
              'T3',
              'XXX',
              'Blar',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 1',
              '20scoopsCNX-0',
              'Lorem datum',
              123,
              'mooo',
              '100 kcal',
              null,
              '2',
              1,
              'USA',
              '0,15',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 2',
              'Google-1',
              'Lorem datum',
              22,
              null,
              '200 kcal',
              null,
              '4',
              1,
              'GERMAN',
              '0,03',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
          ],
        },
        {
          sheetName: 'Information',
          data: [
            [
              'Storage temperature',
              'Product ID',
              'Product Name',
              'Description',
              'Product size',
              'Product size metric',
              'Calorific value (kcal)',
              'Deposit of the outer packaging unit',
              'Number of dispatch stage 1',
              'Price in US',
              'Shipping location',
              'Deposit per unit',
              'Note',
              'Test1',
              'NoteT',
              'Test2',
              'T3',
              'XXX',
              'Blar',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 1',
              '20scoopsCNX-0',
              'Lorem datum',
              123,
              'mooo',
              '100 kcal',
              null,
              '2',
              1,
              'USA',
              '0,15',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 2',
              'Google-1',
              'Lorem datum',
              22,
              null,
              '200 kcal',
              null,
              '4',
              1,
              'GERMAN',
              '0,03',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
          ],
        },
      ],
    },
    {
      fileName: 'user-data (4).xlsx',
      sheets: [
        {
          sheetName: 'Customers',
          data: [
            [
              'Storage temperature',
              'Product ID',
              'Product Name',
              'Description',
              'Product size',
              'Product size metric',
              'Calorific value (kcal)',
              'Deposit of the outer packaging unit',
              'Number of dispatch stage 1',
              'Price in US',
              'Shipping location',
              'Deposit per unit',
              'Note',
              'Test1',
              'NoteT',
              'Test2',
              'T3',
              'XXX',
              'Blar',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 1',
              '20scoopsCNX-0',
              'Lorem datum',
              123,
              'mooo',
              '100 kcal',
              null,
              '2',
              1,
              'USA',
              '0,15',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 2',
              'Google-1',
              'Lorem datum',
              22,
              null,
              '200 kcal',
              null,
              '4',
              1,
              'GERMAN',
              '0,03',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
          ],
        },
      ],
    },
  ],
};

export const sheetSelectionCaseInvalid = {
  step: 'sheetSelection',
  data: [
    {
      fileName: 'CRM Data Sample (4).xlsx',
      sheets: [
        {
          sheetName: 'data',
          data: [],
        },
        {
          sheetName: 'Kopie von Data',
          data: [
            {
              storage_temperature: 'boom',
              product_id: 'product',
            },
            {
              storage_temperature: 'boom2',
              product_id: ['test'],
            },
          ],
        },
        {
          sheetName: '',
          data: [
            {
              storage_temperature: 'boom',
              product_id: 'product',
            },
            {
              storage_temperature: 'boom2',
              product_id: ['test'],
            },
          ],
        },
      ],
    },
    {
      fileName: 'user-data (4).xlsx',
      sheets: [
        {
          sheetName: 'Customers',
          data: [
            [
              'Storage temperature',
              'Product ID',
              'Product Name',
              'Description',
              'Product size',
              'Product size metric',
              'Calorific value (kcal)',
              'Deposit of the outer packaging unit',
              'Number of dispatch stage 1',
              'Price in US',
              'Shipping location',
              'Deposit per unit',
              'Note',
              'Test1',
              'NoteT',
              'Test2',
              'T3',
              'XXX',
              'Blar',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 1',
              '20scoopsCNX-0',
              'Lorem datum',
              123,
              'mooo',
              '100 kcal',
              null,
              '2',
              1,
              'USA',
              '0,15',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
            [
              'frisch: 0° to 2°C',
              'Product 2',
              'Google-1',
              'Lorem datum',
              22,
              null,
              '200 kcal',
              null,
              '4',
              1,
              'GERMAN',
              '0,03',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
              'Lorem datum',
            ],
          ],
        },
      ],
    },
  ],
};
