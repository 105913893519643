import useViewModel from './viewModel';
import { SingleDropdownProps } from '../Dropdown/types';
import Dropdown from '../Dropdown';

export const SingleDropdown = ({
  title,
  onChange,
  onBlur,
  options,
  variant,
  value,
  selectRef,
  onRemoveMatchColumn,
  configThemeDropdownButton,
  configThemeDropdownMenuItems,
  onCreateColumnModel,
}: SingleDropdownProps) => {
  const viewModel = useViewModel({
    onChange,
    onBlur,
    options,
    value,
    selectRef,
  });

  return (
    <Dropdown
      {...viewModel}
      variant={variant}
      title={title}
      options={options}
      onRemoveMatchColumn={onRemoveMatchColumn}
      configThemeDropdownButton={configThemeDropdownButton}
      configThemeDropdownMenuItems={configThemeDropdownMenuItems}
      onCreateColumnModel={onCreateColumnModel}
    />
  );
};

export default SingleDropdown;
