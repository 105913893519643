import { isNil, isObject, isArray, isEmpty } from 'lodash';
import { UploadData } from '../../types';

class NuvoSessionValidator {
  static checkIsEmpty = (data: UploadData | undefined) => {
    return isNil(data) || isEmpty(data);
  };

  static isInValidObjectArray = (data: UploadData | undefined) => {
    return !isObject(data) && !isArray(data);
  };
}

export default NuvoSessionValidator;
