import React, { FC } from 'react';
import { IInputProps, Input } from '@getnuvo/ui-kit';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps extends IInputProps {
  name: string;
}

export const InputControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <Input {...props} value={value} onChange={onChange} />
      )}
    />
  );
};
