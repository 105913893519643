import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cancelHookObservable, resultHookObservable } from './observable';
import HooksAPIMapper from './HooksAPIMapper';
import { useConfigure } from 'configure';
import { useSettings } from 'settings';
import { useDataModels } from 'dataModel';
import { Subject } from 'rxjs';
import { DataModelSheet } from 'dataModelSheet';
import { CompleteImportType } from '../settings/settingsAPI';
import isPromise from 'is-promise';
import { RejectSubmitResult } from './../errors';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useContextLoadingPopupManager } from './../baseUI/LoadingPopup/context';
import { PassSubmitResult } from './../passSubmitResult';
import { SubmitResult, SubmitResultType } from 'core/submitResult';
import { ReviewStepHandler } from '../dataHandler/ReviewStepHandler';
import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { HeaderStepHandler } from '../dataHandler/HeaderStepHandler';
import { useWidgetContext } from 'main/WidgetProvider';
import { getImportLogs } from './utils/getImportLogs';
import { ImportLogs } from './hooksAPI';

export const HookContext = createContext<{
  loadingInitialValues: boolean;
  setLoadingInitialValues: (loading: boolean) => void;
  cancelHookObservable: Subject<null>;
  resultHookObservable: Subject<{
    dataModelSheet: DataModelSheet;
    notifyOnlyValidValues: boolean;
    onError: (err: RejectSubmitResult) => void;
    onComplete: (passSubmitResult?: PassSubmitResult) => void;
  }>;
}>({
  cancelHookObservable,
  resultHookObservable,
  setLoadingInitialValues: () => {},
  loadingInitialValues: true,
});

export const useHooks = () => {
  const {
    onResults,
    onCancel,
    onEntryChange,
    onEntryInit,
    columnHooks,
    dataHandler,
  } = useConfigure();
  const hooks = useContext(HookContext);
  const setting = useSettings();
  const { featureWhiteList } = useFeatureWhiteList();
  const { functionsUsage: widgetFunctionsUsage } = useWidgetContext();

  const dataHandlerMapper = useMemo(() => {
    return {
      headerStep: new HeaderStepHandler(dataHandler?.headerStep),
      reviewStep: new ReviewStepHandler(dataHandler?.reviewStep),
    };
  }, [dataHandler]);

  const hasPreHeaderModifier = useMemo(() => {
    if (widgetFunctionsUsage?.dataHandler) {
      return (
        widgetFunctionsUsage.dataHandler.headerStep &&
        featureWhiteList.getDataHandler()
      );
    }

    if (dataHandler?.headerStep && featureWhiteList.getDataHandler()) {
      return true;
    } else {
      return false;
    }
  }, [dataHandler?.headerStep, featureWhiteList, widgetFunctionsUsage]);

  const hooksAPIMapper = useMemo(() => {
    return new HooksAPIMapper(
      {
        onResults,
        onCancel,
        onEntryChange,
        onEntryInit,
        columnHooks,
      },
      setting.identifier
    );
  }, [
    onResults,
    onCancel,
    onEntryChange,
    onEntryInit,
    columnHooks,
    setting.identifier,
  ]);

  const getIdentifier = () => {
    return setting.identifier;
  };

  return {
    ...hooks,
    hooksAPIMapper,
    getIdentifier,
    dataHandlerMapper,
    hasPreHeaderModifier,
  };
};

export const HooksProvider = ({ children }: { children: ReactNode }) => {
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);

  return (
    <HookContext.Provider
      value={{
        cancelHookObservable,
        resultHookObservable,
        loadingInitialValues,
        setLoadingInitialValues,
      }}
    >
      <HooksSubscriber />
      {children}
    </HookContext.Provider>
  );
};

const HooksSubscriber = () => {
  const {
    cancelHookObservable,
    resultHookObservable,
    hooksAPIMapper,
    getIdentifier,
  } = useHooks();

  const dataModel = useDataModels();
  const setting = useSettings();
  const { t } = useTranslation();
  const { isOpenModal } = useContextLoadingPopupManager();
  const { featureWhiteList } = useFeatureWhiteList();

  useEffect(() => {
    const cancelHookObservableSubscription = cancelHookObservable.subscribe(
      () => {
        hooksAPIMapper.getGeneralHooks(getIdentifier()).onCancel();
      }
    );

    const resultHookObservableSubscription = resultHookObservable.subscribe(
      ({ dataModelSheet, onComplete, onError }) => {
        if (!hooksAPIMapper.getGeneralHooks(getIdentifier()).onResults) {
          return;
        }

        const logs: ImportLogs = getImportLogs(
          featureWhiteList,
          dataModelSheet
        );

        let handleSubmitResult:
          | RejectSubmitResult
          | PassSubmitResult
          | undefined
          | null = null;

        const complete = (
          submitResult?: RejectSubmitResult | PassSubmitResult
        ) => {
          handleSubmitResult = submitResult;
        };
        const rowsLimit = featureWhiteList.getRowsLimit();
        const hookResult = hookResultMethod(
          dataModelSheet,
          setting.completeImportAction
        );
        const allErrorsAsResults = dataModelSheet.getAllErrorsAsResults();
        const listener = hooksAPIMapper
          .getGeneralHooks(dataModelSheet.getIdentifier())
          ?.onResults?.(
            rowsLimit ? hookResult.slice(0, rowsLimit) : hookResult,
            rowsLimit
              ? allErrorsAsResults.slice(0, rowsLimit)
              : allErrorsAsResults,
            complete,
            logs
          );

        /* istanbul ignore next */
        if (isPromise(listener)) {
          listener
            ?.then(() => {
              if (!isOpenModal?.current) return;
              if (handleSubmitResult !== null) {
                if (handleSubmitResult?.type === SubmitResultType.Reject) {
                  if (
                    !isNil(
                      (handleSubmitResult as RejectSubmitResult).getMessage()
                    )
                  ) {
                    onError(handleSubmitResult as RejectSubmitResult);
                  } else {
                    onComplete();
                  }
                } else {
                  onComplete(handleSubmitResult as PassSubmitResult);
                }
              } else {
                setTimeout(() => {
                  if (!isOpenModal?.current) return;
                  onComplete();
                }, 5000);
              }
            })
            .catch((err) => {
              onError(
                new RejectSubmitResult(
                  t('txt_default_title_error'),
                  err.message
                )
              );
            });
        } else {
          if (!isOpenModal?.current) return;
          if (handleSubmitResult !== null) {
            if (
              (handleSubmitResult as SubmitResult)?.type ===
              SubmitResultType.Reject
            ) {
              if (
                !isNil((handleSubmitResult as RejectSubmitResult)?.getMessage())
              ) {
                onError(handleSubmitResult);
              } else {
                onComplete();
              }
            } else {
              onComplete(handleSubmitResult);
            }
          } else {
            setTimeout(() => {
              if (!isOpenModal?.current) return;
              onComplete();
            }, 5000);
          }
        }
      }
    );

    return () => {
      cancelHookObservableSubscription.unsubscribe();
      resultHookObservableSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cancelHookObservable,
    resultHookObservable,
    hooksAPIMapper,
    getIdentifier,
    dataModel,
    featureWhiteList,
  ]);

  const hookResultMethod = (
    dataModelSheet: DataModelSheet,
    action?: CompleteImportType
  ) => {
    switch (action) {
      case 'discard':
      case 'block':
        return dataModelSheet.getValidValues();
      case 'submit':
      default:
        return dataModelSheet.getAllValuesAsResults();
    }
  };

  return null;
};
