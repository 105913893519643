import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { GridSettings } from 'handsontable/settings';
import {
  DO_NOT_SWAP,
  FIRST_AFTER_SECOND,
  FIRST_BEFORE_SECOND,
} from './constant';
import moment, { Moment } from 'moment';
import Handsontable from 'handsontable';

export function compareFunctionFactory(
  sortOrder: SortOrderType,
  columnMeta: GridSettings,
  dateFormat: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (value: any, nextValue: any) {
    if (value === nextValue) {
      return DO_NOT_SWAP;
    }

    if (Handsontable.helper.isEmpty(value)) {
      if (Handsontable.helper.isEmpty(nextValue)) {
        return DO_NOT_SWAP;
      }

      return FIRST_AFTER_SECOND;
    }

    if (Handsontable.helper.isEmpty(nextValue)) {
      return FIRST_BEFORE_SECOND;
    }

    let firstDate: Moment;
    let nextDate: Moment;
    try {
      firstDate = moment(value, dateFormat);
    } catch (err) {
      return FIRST_AFTER_SECOND;
    }

    try {
      nextDate = moment(nextValue, dateFormat);
    } catch (err) {
      return FIRST_BEFORE_SECOND;
    }

    if (!firstDate.isValid()) {
      return FIRST_AFTER_SECOND;
    }

    if (!nextDate.isValid()) {
      return FIRST_BEFORE_SECOND;
    }

    if (nextDate.isAfter(firstDate)) {
      return sortOrder === 'asc' ? FIRST_BEFORE_SECOND : FIRST_AFTER_SECOND;
    }

    if (nextDate.isBefore(firstDate)) {
      return sortOrder === 'asc' ? FIRST_AFTER_SECOND : FIRST_BEFORE_SECOND;
    }

    return DO_NOT_SWAP;
  };
}
