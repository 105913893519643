import { FieldValue } from '../../value';
import { DataModelType } from '../model/DataModel';

export enum VALIDATION {
  REQUIRED = 'REQUIRED',
  REQUIRED_WITH = 'REQUIRED_WITH',
  REQUIRED_WITH_VALUES = 'REQUIRED_WITH_VALUES',
  REQUIRED_WITH_ALL = 'REQUIRED_WITH_ALL',
  REQUIRED_WITH_ALL_VALUES = 'REQUIRED_WITH_ALL_VALUES',
  REQUIRED_WITHOUT = 'REQUIRED_WITHOUT',
  REQUIRED_WITHOUT_VALUES = 'REQUIRED_WITHOUT_VALUES',
  REQUIRED_WITHOUT_ALL = 'REQUIRED_WITHOUT_ALL',
  REQUIRED_WITHOUT_ALL_VALUES = 'REQUIRED_WITHOUT_ALL_VALUES',
  REGEX = 'REGEX',
  UNIQUE = 'UNIQUE',
}

export type Field = {
  key: string;
  label: string;
  value: FieldValue[];
  type: DataModelType;
};

class Validator {
  private validation: VALIDATION;
  private regex?: string;
  private fields: Field[];
  private errorMessage?: string;

  constructor({
    validation,
    regex,
    fields,
    errorMessage,
  }: {
    validation: VALIDATION;
    regex?: string;
    fields?: Field[];
    errorMessage?: string;
  }) {
    this.validation = validation;
    this.regex = regex;
    this.fields = fields ?? [];
    this.errorMessage = errorMessage;
  }

  getValidation = () => {
    return this.validation;
  };

  getRegex = () => this.regex;

  getFields = () => this.fields;

  getErrorMessage = () => this.errorMessage;

  serialize = () => {
    return {
      validation: this.getValidation(),
      fields: this.getFields(),
      regex: this.getRegex(),
      errorMessage: this.getErrorMessage(),
    };
  };
}

export default Validator;
