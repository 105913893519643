import { getBooleanOptionsToRenderUI } from '../constants/boolean';
import { Value } from '../exportData/valueParser/ExportValueParser';
import { DataModel } from '../dataModel/model/DataModel';
import CategoryDataModel from '../dataModel/model/CategoryDataModel';
import { Workbook, Worksheet } from 'nuvo-exceljs';
import { HookedRecordInfoLevel } from '../hooks/hooksAPI';
import { CELL_COLOR } from '../level';
import { DATATYPE } from '../dataType';
import { NumberToAlphabet } from '../numberToAlphabet';
import {
  COUNTRY_CODE_ALPHA_2_OPTIONS,
  COUNTRY_CODE_ALPHA_3_OPTIONS,
  CURRENCY_CODE_OPTIONS,
} from '../dropdownOptions';

type HeaderInfoType = {
  header: string;
  key: string;
  width: number;
  description: string;
  mark: string;
}[];

type DataRow = Record<
  string,
  {
    value: Value;
    errors: { message: string; level: HookedRecordInfoLevel }[];
  }
>;

const DEFAULT_WIDTH_COLUMN = 42;

export const setHeaderStyle = (
  ws: Worksheet,
  headers?: HeaderInfoType,
  isIncludedDesHeader?: boolean
) => {
  for (let i = 0; i < 1; i++) {
    ws.getRow(i + 1).font = {
      bold: i === 0,
    };

    ws.views = [{ state: 'frozen', ySplit: i + 1 }];

    /* istanbul ignore next */
    for (let j = 0; j < ws.getRow(i + 1).cellCount; j++) {
      const cell = ws.getRow(i + 1).getCell(j + 1);
      if (headers && (headers?.length ?? 0) > 0 && isIncludedDesHeader) {
        for (let k = 0; k < headers?.length; k++) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF162338' },
          };
          cell.font = {
            color: { argb: 'FFFFFF' },
          };
          if (
            headers[k]?.header === cell.value &&
            (headers[k].description?.trim()?.length > 0 ||
              headers[k].mark?.trim()?.length > 0)
          ) {
            cell.note = {
              texts: [
                {
                  font: {
                    bold: true,
                    size: 12,
                    color: { argb: '000000' },
                  },
                  text: `DESCRIPTION:\n`,
                },
                {
                  font: {
                    bold: true,
                    size: 12,
                    color: { argb: 'FFFF0000' },
                  },
                  text: `${headers[k].mark}\n`,
                },
                {
                  font: {
                    size: 12,
                  },
                  text: headers[k].description,
                },
              ],
            };
            break;
          }
        }
      }
    }
  }

  let rowIndex = 1;
  for (rowIndex; rowIndex <= ws.rowCount; rowIndex++) {
    ws.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }
};

export const setColumnWidthAutoFit = (ws: Worksheet) => {
  ws.columns.forEach((column) => {
    column.width = DEFAULT_WIDTH_COLUMN;
    column.alignment = { wrapText: true };
  });
};

export const setErrorStyleAndComment = (
  ws: Worksheet,
  contents: DataRow[],
  dataModels: DataModel[]
) => {
  const maxCell = 80000;
  let count = 0;

  for (let i = 0; i < contents.length; i++) {
    const element = contents[i];
    for (let j = 0; j < dataModels.length; j++) {
      const dataModel = dataModels[j];
      const errors = element[dataModel.getKey()].errors;
      count++;
      if (errors.length > 0) {
        const cell = ws.getRow(i + 2).getCell(j + 1);

        let hasError = false;
        let hasWarning = false;
        let hasInfo = false;

        for (let i = 0; i < errors.length; i++) {
          if (errors[i].level === 'error') {
            hasError = true;
            break;
          }
        }

        /* istanbul ignore next */
        if (!hasError) {
          for (let i = 0; i < errors.length; i++) {
            if (errors[i].level === 'warning') {
              hasWarning = true;
              break;
            }
          }
        }

        if (!hasError && !hasWarning) {
          for (let i = 0; i < errors.length; i++) {
            if (errors[i].level === 'info') {
              hasInfo = true;
              break;
            }
          }
        }

        const color = (() => {
          if (hasError) {
            return CELL_COLOR.error;
          } else if (hasWarning) {
            return CELL_COLOR.warning;
          } else if (hasInfo) {
            return CELL_COLOR.info;
          } else {
            return CELL_COLOR.info;
          }
        })();

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
        };

        if (count <= maxCell) {
          cell.note = {
            texts: errors.map((error) => {
              return {
                font: {
                  size: 14,
                },
                text: `${error.message} \n`,
              };
            }),
          };
        }
      }
    }
  }
};

export const setDataValidation = (
  ws: Worksheet,
  dataModels: DataModel[],
  startRow = 1,
  totalRow = 1
) => {
  let counter = 1;
  const fieldValidation: {
    model: DataModel;
    index: number;
    formulae: string[];
  }[] = [];
  const numberToAlphabetInstance = new NumberToAlphabet();
  for (let i = 0; i < dataModels.length; i++) {
    const dataModel = dataModels[i];
    const cellIndex = i + 1;
    if (hasCategoryType(dataModel)) {
      const letter = numberToAlphabetInstance.numberToString(counter);
      const categoryModal = dataModel as CategoryDataModel;
      const formula = [
        `options!$${letter}$2:$${letter}$${
          categoryModal.getOptions().length + 1
        }`,
      ];

      fieldValidation.push({
        model: dataModel,
        index: cellIndex,
        formulae: formula,
      });

      counter++;
    }
    if (dataModel.getType() === DATATYPE.BOOLEAN) {
      fieldValidation.push({
        model: dataModel,
        index: cellIndex,
        formulae: [`"${getBooleanOptionsToRenderUI().join(',')}"`],
      });
    }
  }

  for (let i = startRow; i <= totalRow; ++i) {
    fieldValidation.forEach((field) => {
      ws.getCell(i, field?.index).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: field.formulae,
      };
    });
  }
};

export const getHeaderKeysDropdownType = (
  dataModels: DataModel[]
): string[] => {
  const headerKeys: string[] = [];
  for (let i = 0; i < dataModels.length; ++i) {
    const element = dataModels[i];
    if (
      element.getType() === DATATYPE.MULTIPLE_SELECT ||
      element.getType() === DATATYPE.SINGLE_SELECT ||
      element.getType() === DATATYPE.COUNTRY_CODE_ALPHA_2 ||
      element.getType() === DATATYPE.COUNTRY_CODE_ALPHA_3 ||
      element.getType() === DATATYPE.CURRENCY_CODE ||
      element.getType() === DATATYPE.BOOLEAN ||
      element.getIsMultiSelection()
    ) {
      headerKeys.push(element.getKey());
    }
  }
  return headerKeys;
};

export function hasCategoryType(dataModels: DataModel): boolean;
export function hasCategoryType(dataModels: DataModel[]): boolean;
export function hasCategoryType(dataModels: DataModel | DataModel[]): boolean {
  if (Array.isArray(dataModels)) {
    for (let i = 0; i < dataModels.length; ++i) {
      if (hasCategoryType(dataModels[i])) return true;
    }
    return false;
  } else {
    if (
      [
        DATATYPE.SINGLE_SELECT,
        DATATYPE.COUNTRY_CODE_ALPHA_2,
        DATATYPE.COUNTRY_CODE_ALPHA_3,
        DATATYPE.CURRENCY_CODE,
      ].includes(dataModels.getType())
    ) {
      return true;
    }
    return false;
  }
}

export const createOptionSheet = (
  workbook: Workbook,
  dataModels: DataModel[]
) => {
  if (!hasCategoryType(dataModels)) return;

  let counter = 1;
  const sheetOptions = workbook.addWorksheet('options');

  const optionHeaders = [];
  const tdmOptions: CategoryDataModel[] = [];
  let maxOptions = 0;
  const optionHeaderKey: Record<number, string> = {};

  for (let i = 0; i < dataModels.length; i++) {
    const tdm = dataModels[i];

    if (!hasCategoryType(tdm)) continue;

    const categoryModel = tdm as CategoryDataModel;
    if (categoryModel.getType() === DATATYPE.COUNTRY_CODE_ALPHA_2) {
      categoryModel.setOptions(COUNTRY_CODE_ALPHA_2_OPTIONS);
    } else if (categoryModel.getType() === DATATYPE.COUNTRY_CODE_ALPHA_3) {
      categoryModel.setOptions(COUNTRY_CODE_ALPHA_3_OPTIONS);
    } else if (categoryModel.getType() === DATATYPE.CURRENCY_CODE) {
      categoryModel.setOptions(CURRENCY_CODE_OPTIONS);
    }

    const options = categoryModel.getOptions();

    if (options.length > maxOptions) {
      maxOptions = options.length;
    }

    tdmOptions.push(tdm as CategoryDataModel);

    const key = `${tdm.getLabel()}_${counter}`;
    optionHeaderKey[counter] = key;

    optionHeaders.push({
      header: tdm.getLabel(),
      key: key,
      width: 60,
      description: tdm.getDescription(),
      mark: '',
    });

    counter++;
  }

  sheetOptions.columns = optionHeaders;
  setHeaderStyle(sheetOptions);
  setColumnWidthAutoFit(sheetOptions);

  const rows: Record<string, string>[] = [];

  for (let j = 0; j < maxOptions; j++) {
    const row: Record<string, string> = {};
    for (let i = 0; i < tdmOptions.length; i++) {
      const tdm = tdmOptions[i];
      const key = optionHeaderKey[i + 1];
      row[key] = tdm.getOptions()?.[j]?.label || '';
    }

    rows.push(row);
  }

  sheetOptions.addRows(rows);
};
