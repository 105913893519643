/* istanbul ignore file */

import { isEqual } from 'lodash';
import { SheetData, Value } from './Sheet';
import SheetColumn from './SheetColumn';

export const isSheetColumnOptionEqual = (
  uniqueRow: Value,
  sheetColumnOptionValue: Value
) => {
  return isEqual(`${uniqueRow}`, `${sheetColumnOptionValue}`);
};

export const getSheetDataBySheetColumns = (sheetColumns: SheetColumn[]) => {
  let maxRow = 0;

  for (let i = 0; i < sheetColumns.length; i++) {
    const sheetColumn = sheetColumns[i];
    const rows = sheetColumn.getRows();
    if (rows.length > maxRow) {
      maxRow = rows.length;
    }
  }

  const values: SheetData = [];

  for (let rowIndex = 0; rowIndex < maxRow; rowIndex++) {
    values[rowIndex] = [];
    for (let colIndex = 0; colIndex < sheetColumns.length; colIndex++) {
      const sheetColumn = sheetColumns[colIndex];
      const rows = sheetColumn.getRows();
      values[rowIndex][colIndex] = rows[rowIndex] ?? null;
    }
  }

  return values;
};
