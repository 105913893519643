import { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper as useBasePopper } from 'react-popper';
import { useDropdownBoundary } from '../dropdownBoundary';

export type Popper = {
  referenceElement: React.RefObject<HTMLDivElement>;
  styles: { [key: string]: React.CSSProperties };
  setPopperElement: (element: HTMLDivElement | null) => void;
  attributes: { [key: string]: { [key: string]: string } | undefined };
  popperElement: HTMLDivElement | null;
  isReferenceHide: boolean;
};

export const usePopper = ({
  isOpenDropdown,
}: {
  isOpenDropdown: boolean;
}): Popper => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { boundary, footerHeight } = useDropdownBoundary();

  const modifiers = useMemo(
    () => [
      { name: 'eventListeners', enabled: isOpenDropdown },
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          tether: true,
          altBoundary: true,
          boundary,
          padding: {
            bottom: footerHeight,
            top: 0,
          },
        },
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          boundary,
          padding: {
            bottom: footerHeight,
            top: 0,
          },
        },
      },
    ],
    [boundary, footerHeight, isOpenDropdown]
  );

  const { styles, attributes, update, state } = useBasePopper(
    referenceElement.current,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers,
    }
  );

  useEffect(() => {
    if (isOpenDropdown && update) {
      update();
    }
  }, [isOpenDropdown, update]);

  const isReferenceHide =
    (state?.modifiersData.hide?.referenceClippingOffsets.bottom ?? 0) >
    footerHeight * -1;

  return {
    referenceElement,
    styles,
    setPopperElement,
    attributes,
    popperElement,
    isReferenceHide,
  };
};
