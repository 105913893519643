import { useTranslation } from 'react-i18next';
import { ReactComponent as XSearchIcon } from '../../../../../../assets/icon/x-search.svg';
import { css, cx } from '../../../../../emotion';
import { ConfigTheme } from '../../../type';

const EmptyValueList = ({ configTheme }: { configTheme?: ConfigTheme }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        'text-gray-560 rounded-medium py-6 text-center text-sm font-semibold',
        css({
          '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
            ?.valueList?.root,
        })
      )}
    >
      <div className="flex justify-center">
        <XSearchIcon
          className={cx(
            'text-gray-410',
            css({
              '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                ?.filter?.valueList?.emptyIcon,
            })
          )}
        />
      </div>
      <p className={cx('mt-3')}>{t('txt_no_results_found')}</p>
    </div>
  );
};

export default EmptyValueList;
