import React, { FC, ReactNode } from 'react';

interface IProps {
  title: string;
  children: ReactNode;
}

export const ConfigureFormSection: FC<IProps> = ({
  title,
  children,
}: IProps) => {
  return (
    <section className="nuvo-sdk__form-section">
      <header className="nuvo-sdk__form-sectionHeader">
        <h3 className="nuvo-sdk__form-sectionTitle paragraph-1 semi-bold">
          {title}
        </h3>
      </header>
      <div className="nuvo-sdk__form-sectionContent">{children}</div>
    </section>
  );
};
