import { css, cx } from '@nuvo-importer/common';
import MenuButton from 'baseUI/MenuButton';
import { Sheet, SheetItemThemeAPI } from '@nuvo-importer/common/sdk';
import useViewModel, {
  OnAddFirstSheet,
  OnAppendClick,
  OnJoinClick,
  OnRemoveClick,
  OnPreviewClick,
  OnAddToAllRows,
} from './viewModel';
import IconButton, { IIconButton } from 'baseUI/IconButton';
import TruncatePopover from 'baseUI/TruncatePopover';
import { RefObject } from 'react';

type ISheetItemProps = {
  className?: string;
  onJoinClick: OnJoinClick;
  onRemoveClick: OnRemoveClick;
  onAppendClick: OnAppendClick;
  onAddFirstSheet?: OnAddFirstSheet;
  onAddToAllRows: OnAddToAllRows;
  sheet: Sheet;
  showRemoveButton: boolean;
  boundary?: HTMLDivElement;
  noSourceSheet?: boolean;
  onPreviewClick: OnPreviewClick;
  loading: boolean;
  theme?: SheetItemThemeAPI;
};

const SheetItem = ({
  className,
  onJoinClick,
  onAppendClick,
  onRemoveClick,
  sheet,
  showRemoveButton,
  boundary,
  noSourceSheet,
  onAddFirstSheet,
  onPreviewClick,
  onAddToAllRows,
  loading,
  theme,
}: ISheetItemProps) => {
  const { menus } = useViewModel({
    onJoinClick,
    onAppendClick,
    onAddToAllRows,
    sheet,
  });

  return (
    <div
      className={cx(
        'border-gray-120 rounded-medium border bg-white p-2 pl-3',
        className,
        css({
          '&&': theme?.root,
        })
      )}
    >
      <div className="mb-1 flex items-center justify-between">
        <TruncatePopover
          render={({ textRef, getHover, referenceElement }) => (
            <p
              {...getHover()}
              ref={textRef as RefObject<HTMLParagraphElement>}
              className={cx(
                'text-blue-light-500 truncate text-sm font-semibold',
                css({ '&&': theme?.title })
              )}
            >
              <span ref={referenceElement as RefObject<HTMLDivElement>}>
                {sheet.getName()}
              </span>
            </p>
          )}
        />
        <div className="flex-shrink-0">
          <IconButton
            icon={IIconButton.EYE}
            className={cx('mr-1', css({ '&&': theme?.previewButton }))}
            iconClassName="text-blue-dark"
            onClick={() => {
              onPreviewClick(sheet);
            }}
          />
          {showRemoveButton ? (
            <IconButton
              icon={IIconButton.MINUS}
              className={cx(
                'border-blue-dark-50 border',
                css({ '&&': theme?.removeButton })
              )}
              onClick={() => {
                onRemoveClick(sheet);
              }}
              disabled={loading}
            />
          ) : (
            <MenuButton
              icon={IIconButton.PLUS}
              menus={menus}
              boundary={boundary}
              disabled={loading}
              className={css({ '&&': theme?.addButton })}
              menuTheme={theme?.addMenu}
              onClick={
                noSourceSheet
                  ? () => {
                      onAddFirstSheet?.(sheet);
                    }
                  : undefined
              }
            />
          )}
        </div>
      </div>
      <TruncatePopover
        render={({ referenceElement, textRef, getHover }) => {
          return (
            <div
              ref={referenceElement as RefObject<HTMLDivElement>}
              {...getHover()}
            >
              <p
                className={cx(
                  'text-gray-560 truncate text-xs',
                  css({ '&&': theme?.description })
                )}
                ref={textRef as RefObject<HTMLParagraphElement>}
              >
                {sheet.getSheetFileName()}
              </p>
            </div>
          );
        }}
      />
    </div>
  );
};

export default SheetItem;
