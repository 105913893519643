import { DataModel } from '../../../dataModel/model/DataModel';
import { DATATYPE } from '../../../dataType';

class DataModelColumn {
  private dataModel: DataModel;

  constructor(dataModel: DataModel) {
    this.dataModel = dataModel;
  }

  public calculateColumnSize = () => {
    const baseWidth = 150;
    const widthPerSize = 70;
    return Math.round(
      baseWidth + widthPerSize * this.dataModel.getColumnSize()
    );
  };

  getType = () => {
    if (this.dataModel.isDropdown()) {
      return 'dropdown';
    } else {
      switch (this.dataModel.getType()) {
        case DATATYPE.STRING:
          return 'text';
        case DATATYPE.INTEGER:
        case DATATYPE.FLOAT:
          return 'numeric';
        case DATATYPE.PERCENTAGE:
          return 'percentage';
        case DATATYPE.CURRENCY_EUR:
        case DATATYPE.CURRENCY_USD:
          return 'currency';
        case DATATYPE.TIME_HM:
        case DATATYPE.TIME_HMS:
        case DATATYPE.TIME_HMS_24:
        case DATATYPE.TIME_HM_24:
          return 'time';
        case DATATYPE.DATETIME:
        case DATATYPE.DATE_DMY:
        case DATATYPE.DATE_ISO:
        case DATATYPE.DATE_MDY:
          return 'date';
        default:
          return 'text';
      }
    }
  };
}

export default DataModelColumn;
