import { RefObject } from 'react';
import { ColumnAPI } from '../../../../dataModel/columnsAPI';
import { isTruncated } from '../../../../dom';
import ContextMenuController from '../../../DataModelSheetForm/ContextMenu/controller/ContextMenuController';
import AllColumnSetting from '../../../DataModelSheetForm/columns/AllColumnSetting';
import HideStrategy, {
  HideIcon,
} from '../../../DataModelSheetForm/columns/HideStrategy';
import SortStrategy, {
  SortState,
} from '../../../DataModelSheetForm/columns/SortStrategy';
import { HotTableClass } from '@handsontable/react';
import { CustomColumnUIObservable } from '../../../DataModelSheetForm/customColumns';
import { Subject } from 'rxjs';
import { DataModel } from '../../../../dataModel/model/DataModel';

const iconInfo = `<svg width="20" height="21" viewBox="0 0 20 21" class="!bg-transparent" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 19a8 8 0 1 0 0-17 8 8 0 0 0 0 17ZM10 14h0M10 7v3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

const menuButton = `<svg width="20" height="20" class="dot-icon" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1634 11.6667C16.6236 11.6667 16.9967 11.2936 16.9967 10.8333C16.9967 10.3731 16.6236 10 16.1634 10C15.7032 10 15.3301 10.3731 15.3301 10.8333C15.3301 11.2936 15.7032 11.6667 16.1634 11.6667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.3302 11.6667C10.7904 11.6667 11.1635 11.2936 11.1635 10.8333C11.1635 10.3731 10.7904 10 10.3302 10C9.86992 10 9.49683 10.3731 9.49683 10.8333C9.49683 11.2936 9.86992 11.6667 10.3302 11.6667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.49666 11.6667C4.9569 11.6667 5.33 11.2936 5.33 10.8333C5.33 10.3731 4.9569 10 4.49666 10C4.03643 10 3.66333 10.3731 3.66333 10.8333C3.66333 11.2936 4.03643 11.6667 4.49666 11.6667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const ascSortIcon = `<svg width="20" height="21" class="asc-sort-icon" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.38829 13.1437L9.45224 13.0732L9.45544 13.0695C9.95524 12.461 9.53034 11.5122 8.71316 11.5122L4.2339 11.512L4.13692 11.5177L4.13109 11.5184C3.90893 11.5448 3.70524 11.655 3.56164 11.8266C3.41804 11.9982 3.34538 12.2181 3.35849 12.4414C3.3716 12.6648 3.4695 12.8747 3.63218 13.0283C3.79487 13.1819 4.01017 13.2675 4.2339 13.2678H6.78161L3.74621 16.303L3.68218 16.3734L3.67907 16.3772C3.17926 16.9857 3.60416 17.9345 4.42135 17.9345L8.90061 17.9347L8.99759 17.929L9.00342 17.9283C9.22558 17.9019 9.42927 17.7917 9.57287 17.6201C9.71646 17.4485 9.78913 17.2286 9.77602 17.0053C9.76291 16.7819 9.66501 16.572 9.50232 16.4184C9.33964 16.2648 9.12434 16.1792 8.90061 16.1789H6.35289L9.38829 13.1437Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.431 13.9124C15.3245 13.9565 15.2278 14.0212 15.1463 14.1027L14.4451 14.8039V5.38994C14.4451 5.15714 14.3526 4.93387 14.188 4.76926C14.0234 4.60464 13.8001 4.51216 13.5673 4.51216C13.3345 4.51216 13.1112 4.60464 12.9466 4.76926C12.782 4.93387 12.6895 5.15714 12.6895 5.38994V14.8039L11.9883 14.1027C11.9068 14.0212 11.8101 13.9565 11.7036 13.9124C11.5971 13.8682 11.483 13.8455 11.3677 13.8454C11.2524 13.8454 11.1382 13.868 11.0317 13.9121C10.9251 13.9562 10.8283 14.0208 10.7468 14.1023C10.6652 14.1838 10.6005 14.2806 10.5564 14.3871C10.5122 14.4936 10.4894 14.6078 10.4894 14.723C10.4894 14.8383 10.512 14.9525 10.5561 15.059C10.6002 15.1656 10.6649 15.2624 10.7464 15.344L12.9467 17.5435C13.1113 17.7081 13.3346 17.8005 13.5673 17.8005C13.8001 17.8005 14.0233 17.7081 14.1879 17.5435L16.3883 15.3439C16.4698 15.2624 16.5344 15.1656 16.5785 15.059C16.6226 14.9525 16.6453 14.8383 16.6452 14.723C16.6452 14.6078 16.6224 14.4936 16.5783 14.3871C16.5341 14.2806 16.4694 14.1838 16.3879 14.1023C16.3063 14.0208 16.2095 13.9562 16.103 13.9121C15.9964 13.868 15.8823 13.8454 15.767 13.8454C15.6517 13.8455 15.5375 13.8682 15.431 13.9124Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.86849 4.57227C7.63773 4.0656 7.13103 3.73438 6.56725 3.73438C6.27245 3.73439 5.98486 3.82551 5.74384 3.99527C5.50283 4.16503 5.32015 4.40518 5.22085 4.68275L3.4069 9.76163L3.37949 9.85412L3.37821 9.85957C3.32687 10.0783 3.36135 10.3083 3.47453 10.5024C3.58771 10.6965 3.77099 10.8397 3.98663 10.9027C4.20227 10.9657 4.43385 10.9436 4.6337 10.841C4.83348 10.7384 4.98642 10.563 5.06092 10.3511L5.40806 9.37886H7.72645L8.07359 10.3517L8.11156 10.4412L8.11407 10.4463C8.21335 10.6471 8.38566 10.8024 8.59571 10.8803C8.80575 10.9582 9.03763 10.9528 9.24385 10.8653C9.45007 10.7778 9.61502 10.6147 9.70493 10.4096C9.79483 10.2044 9.80287 9.97255 9.72744 9.76163L7.91374 4.68349L7.87022 4.57606L7.86849 4.57227ZM6.03483 7.6233L6.56725 6.13287L7.09968 7.6233H6.03483Z" fill="currentColor"/>
</svg>
`;

const descSortIcon = `<svg width="20" height="21" class="desc-sort-icon" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4296 8.3991C15.3231 8.35496 15.2264 8.29028 15.1449 8.20876L14.444 7.5078V16.9193C14.444 17.152 14.3515 17.3753 14.1869 17.5398C14.0224 17.7044 13.7991 17.7969 13.5664 17.7969C13.3336 17.7969 13.1104 17.7044 12.9458 17.5398C12.7813 17.3753 12.6888 17.152 12.6888 16.9193V7.5078L11.9878 8.20876C11.9064 8.29028 11.8096 8.35496 11.7032 8.3991C11.5967 8.44325 11.4826 8.46599 11.3673 8.46604C11.252 8.46608 11.1379 8.44341 11.0314 8.39934C10.9249 8.35527 10.8281 8.29065 10.7466 8.20917C10.665 8.1277 10.6003 8.03096 10.5562 7.92448C10.512 7.818 10.4893 7.70387 10.4893 7.5886C10.4892 7.47334 10.5119 7.35919 10.556 7.25269C10.6 7.14618 10.6647 7.04935 10.7462 6.96782L12.9459 4.76882C13.1105 4.6043 13.3337 4.51187 13.5664 4.51187C13.7991 4.51187 14.0223 4.6043 14.1868 4.76882L16.3867 6.96786C16.4681 7.04939 16.5328 7.14618 16.5768 7.25269C16.6209 7.35919 16.6436 7.47334 16.6435 7.5886C16.6435 7.70387 16.6207 7.818 16.5766 7.92448C16.5324 8.03096 16.4678 8.1277 16.3862 8.20917C16.3047 8.29065 16.2079 8.35527 16.1014 8.39934C15.9949 8.44341 15.8807 8.46608 15.7655 8.46604C15.6502 8.46599 15.5361 8.44325 15.4296 8.3991Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.91409 4.68444L7.87039 4.5776L7.86884 4.57402C7.63813 4.06747 7.13155 3.73633 6.56791 3.73633C6.27318 3.73634 5.98566 3.82744 5.7447 3.99716C5.50374 4.16688 5.32111 4.40697 5.22183 4.68447L3.40836 9.76204L3.38095 9.85451L3.37967 9.85996C3.32834 10.0786 3.36281 10.3086 3.47597 10.5026C3.58913 10.6967 3.77236 10.8399 3.98795 10.9029C4.20354 10.9659 4.43507 10.9438 4.63487 10.8412C4.83461 10.7386 4.98751 10.5632 5.06199 10.3514L5.40903 9.37941H7.72679L8.07383 10.3519L8.1118 10.4415L8.11431 10.4466C8.21357 10.6473 8.38584 10.8026 8.59583 10.8804C8.80583 10.9583 9.03765 10.953 9.24382 10.8655C9.45 10.778 9.61492 10.615 9.7048 10.4098C9.79468 10.2047 9.80272 9.97291 9.7273 9.76204L7.91409 4.68444ZM6.03566 7.62424L6.56791 6.1343L7.10016 7.62424H6.03566Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.38824 13.1623L9.45217 13.0918L9.45537 13.0881C9.95506 12.4798 9.53026 11.5312 8.71326 11.5312L4.23516 11.531L4.1382 11.5367L4.13238 11.5374C3.91026 11.5638 3.70662 11.674 3.56306 11.8455C3.41949 12.0171 3.34684 12.2369 3.35995 12.4602C3.37306 12.6835 3.47094 12.8934 3.63359 13.0469C3.79623 13.2005 4.01149 13.2861 4.23516 13.2864H6.78216L3.74758 16.3208L3.68357 16.3912L3.68046 16.395C3.18077 17.0033 3.60557 17.9519 4.42256 17.9519L8.90066 17.9521L8.99762 17.9464L9.00345 17.9457C9.22556 17.9193 9.42921 17.8091 9.57277 17.6376C9.71633 17.466 9.78898 17.2462 9.77587 17.0229C9.76277 16.7996 9.66489 16.5897 9.50224 16.4362C9.33959 16.2826 9.12434 16.197 8.90066 16.1967H6.35367L9.38824 13.1623Z" fill="currentColor"/>
</svg>`;

const hideArrowIcon = `<svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
<path d="M3.5 7.03516L0.5 4.03516L3.5 1.03516" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const sortDescAndFilterIcon = `<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 13.084L14 5.58398M14 5.58398L11.5 8.39648M14 5.58398L16.5 8.39648" stroke="currentColor" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.9474 11.209H6.77366M3.1795 6.52148H8.66938M1.5 1.83398H12.0882" stroke="currentColor" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const sortAscAndFilterIcon = `<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.9474 11.209H7.52714M3.1795 6.52148H9.43455M13.8529 5.58398V13.084M13.8529 13.084L16.5 10.2715M13.8529 13.084L11.2059 10.2715M1.5 1.83398H12.0882" stroke="currentColor" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const filterIcon = `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 10.834H15M2.5 5.83398H17.5M7.5 15.834H12.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

type IHeaderClasses = {
  headerSvgClass?: string;
  infoStyleClass?: string;
  exampleStyleClass: string;
};

export const customAddColumnHeader = ({
  enableExample,
  classes,
}: {
  enableExample: boolean;
  classes: IHeaderClasses;
}) => {
  const html = `<div class="justify-center title-header bg-gray-50 text-center"><span class="nuvo-custom-add-column-button">
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 4.03711V13.3704" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33331 8.70312H12.6666" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</span></div>`;

  const htmlWithExampleRow =
    html +
    `<div class="example-header w-full leading-8 text-gray-800 px-3 text-left text-sm font-normal bg-gray-450 ${classes.exampleStyleClass}"></div>`;

  return enableExample ? htmlWithExampleRow : html;
};

export const customGetAddColumnHeader = (
  thElement: HTMLElement,
  customColumnUIObservable: Subject<CustomColumnUIObservable>
) => {
  const addColumnButton = thElement.querySelector(
    '.nuvo-custom-add-column-button'
  );
  if (addColumnButton) {
    addColumnButton.addEventListener('click', () => {
      customColumnUIObservable.next({ isOpen: true, type: 'add' });
    });
  }
};

export const customColumnHeader = (
  column: ColumnAPI,
  dataModel: DataModel,
  options: {
    enableExample: boolean;
    smartTable: boolean;
    classes: IHeaderClasses;
    sorting: SortState | null;
    hasFiltered: boolean;
    hideIcon?: HideIcon;
  }
) => {
  const { classes, sorting, hasFiltered, hideIcon } = options;

  const getMenuIcon = () => {
    if (hasFiltered && sorting !== null) {
      if (sorting === SortState.ASC) {
        return sortAscAndFilterIcon;
      } else {
        return sortDescAndFilterIcon;
      }
    } else if (hasFiltered && sorting === null) {
      return filterIcon;
    } else {
      if (sorting === null) {
        return menuButton;
      } else if (sorting === SortState.ASC) {
        return ascSortIcon;
      } else {
        return descSortIcon;
      }
    }
  };

  const isRequired = column?.validations?.find(
    (entry) => entry.validate === 'required'
  );

  if (!column) {
    return '';
  }

  const html = `
  <div class="title-header bg-gray-50 text-gray-560 text-sm px-4">
    <div id="text-title" class="text-sm font-normal flex-shrink truncate">${
      column.label
    }</div>
    <span class="mr-1 ml-1 text-sm font-normal text-left">${
      isRequired ? '*' : ''
    }</span>
    ${
      column.description
        ? `<span class="icon-info ${classes.infoStyleClass} ${classes.headerSvgClass}">${iconInfo}</span>`
        : ''
    }
   ${
     options.smartTable || !!dataModel.getCreator()
       ? ` <div class="${
           ContextMenuController.menuButtonClassName
         } ml-auto"><div class="pl-2 text-gray-260 hover:text-gray-560 column-menu-icon">${getMenuIcon()}</div></div><div class="${
           SortStrategy.sortLoaderClassName
         } ml-auto"><div class="pl-2"><div class="header-circle-loader" /></div></div>
          </div>`
       : '<div></div>'
   }${
    hideIcon?.iconL
      ? `<div class="${HideStrategy.hideButtonClassName} ${HideStrategy.hideIconLeftClassName} text-gray-560">${hideArrowIcon}</div>`
      : ''
  } ${
    hideIcon?.iconR
      ? `<div class="${HideStrategy.hideButtonClassName} ${HideStrategy.hideIconRightClassName} text-gray-560">${hideArrowIcon}</div>`
      : ''
  }</div>`;

  const htmlWithExampleRow =
    html +
    `<div class="example-header w-full leading-8 text-gray-800 px-3 text-left text-sm font-normal bg-gray-450 ${
      classes.exampleStyleClass
    }">${column.example ?? ''}</div>`;

  return !options.enableExample ? html : htmlWithExampleRow;
};

const getFreezeLastColumn = (hotInstance: RefObject<HotTableClass>) => {
  return hotInstance.current?.hotInstance?.rootElement.querySelector(
    `.ht_clone_top_inline_start_corner thead tr:first-child th:last-child .${HideStrategy.hideButtonClassName}.${HideStrategy.hideIconRightClassName}`
  );
};

const getNotFreezeFirstColumn = (
  hotInstance: RefObject<HotTableClass>,
  index: number
) => {
  return hotInstance.current?.hotInstance?.rootElement.querySelector(
    `.ht_clone_top thead tr:first-child th:nth-of-type(${index}) .${HideStrategy.hideButtonClassName}.${HideStrategy.hideIconLeftClassName}`
  );
};

export const customGetColHeader = (
  column: ColumnAPI,
  thElement: HTMLElement,
  popperElement: HTMLElement,
  onHoverHeaderInfo: (
    popperElement: HTMLElement,
    rootElement: HTMLElement,
    textValue: string
  ) => void,
  allColumnSetting: AllColumnSetting,
  columnIndex: number,
  hotInstance: RefObject<HotTableClass>
) => {
  const titleElement = thElement.querySelector(
    `.title-header > div`
  ) as HTMLElement;

  const thIconElement = thElement.querySelector(`.icon-info`) as HTMLElement;

  const exampleElement = thElement.querySelector(
    `.example-header`
  ) as HTMLElement | null;

  const hideButtons = thElement.querySelectorAll(
    `.${HideStrategy.hideButtonClassName}`
  );

  titleElement?.addEventListener(
    'mouseover',
    () => {
      if (isTruncated(titleElement)) {
        onHoverHeaderInfo(popperElement, titleElement, column.label);
      }
    },
    false
  );

  thIconElement?.addEventListener(
    'mouseover',
    () => {
      onHoverHeaderInfo(popperElement, thIconElement, column.description ?? '');
    },
    false
  );

  const previousHideIcon = thElement.previousElementSibling?.querySelector(
    `.${HideStrategy.hideButtonClassName}.${HideStrategy.hideIconRightClassName}`
  );
  const nextHideIcon = thElement.nextElementSibling?.querySelector(
    `.${HideStrategy.hideButtonClassName}.${HideStrategy.hideIconLeftClassName}`
  );

  hideButtons?.forEach((button) => {
    button.addEventListener('click', (event: Event) => {
      const isIconLeft = (event.target as HTMLDivElement).classList.contains(
        HideStrategy.hideIconLeftClassName
      );
      allColumnSetting.showHideColumns(columnIndex, isIconLeft);
    });

    button.addEventListener('mouseover', (event: Event) => {
      button.classList.add(HideStrategy.hideButtonHoverClassName);
      const isIconLeft = (event.target as HTMLDivElement).classList.contains(
        HideStrategy.hideIconLeftClassName
      );
      if (isIconLeft) {
        const isPreviousFreeze = allColumnSetting.isPreviousFreeze(columnIndex);

        if (isPreviousFreeze) {
          const freezeHideBtn = getFreezeLastColumn(hotInstance);
          freezeHideBtn?.classList.add(HideStrategy.hideButtonHoverClassName);
        } else {
          previousHideIcon?.classList.add(
            HideStrategy.hideButtonHoverClassName
          );
        }
      } else {
        const isFreeze = allColumnSetting
          .getFreezeColumns()
          .includes(columnIndex);
        const isNextFreeze = allColumnSetting.isNextFreeze(columnIndex);
        if (isFreeze && !isNextFreeze) {
          const numOfFreeze = allColumnSetting.getFreezeColumns(false).length;
          const notFreezeHideBtn = getNotFreezeFirstColumn(
            hotInstance,
            numOfFreeze + 2
          );
          notFreezeHideBtn?.classList.add(
            HideStrategy.hideButtonHoverClassName
          );
        } else {
          nextHideIcon?.classList.add(HideStrategy.hideButtonHoverClassName);
        }
      }
    });

    button.addEventListener('mouseout', () => {
      hotInstance.current?.hotInstance?.rootElement
        .querySelectorAll(`.${HideStrategy.hideButtonClassName}`)
        .forEach((element) => {
          element.classList.remove(HideStrategy.hideButtonHoverClassName);
        });
    });
  });

  if (exampleElement)
    exampleElement.addEventListener(
      'mouseover',
      () => {
        if (isTruncated(exampleElement)) {
          onHoverHeaderInfo(
            popperElement,
            exampleElement,
            column.example ?? ''
          );
        }
      },
      false
    );
};
