import { CSSObject } from '../../core/emotion';
import { ThemeAPI } from '../themeAPI';
import ButtonTheme from './ButtonTheme';
import chroma from 'chroma-js';

class PrimaryButtonTheme extends ButtonTheme {
  constructor(themeAPI: ThemeAPI) {
    super('primary', 'primaryColor', themeAPI);
  }

  generateByGlobalColor = () => {
    const primaryGlobalColor = this.themeAPI.globals?.primaryColor;
    const primaryColorChroma = chroma(primaryGlobalColor ?? '');
    const primaryHoverColor = chroma
      .hsl(
        primaryColorChroma.get('hsl.h') + 0.102,
        primaryColorChroma.get('hsl.s') - 0.00009,
        primaryColorChroma.get('hsl.l') - 0.2039
      )
      .hex();

    return {
      backgroundColor: primaryGlobalColor,
      color: this.getTextColor(primaryGlobalColor ?? ''),
      ':hover': {
        backgroundColor: primaryHoverColor,
      },
    };
  };

  protected generateByButtonTheme = () => {
    const theme = {
      ...((this.themeAPI.buttons?.['primary'] as CSSObject) ?? {}),
    };

    if (theme.backgroundColor && !theme[':hover']?.backgroundColor) {
      const basedColor = chroma(theme.backgroundColor as string);
      const hoverColor = chroma
        .hsl(
          basedColor.get('hsl.h') + 0.10204,
          basedColor.get('hsl.s') - 0.00009,
          basedColor.get('hsl.l') - 0.103
        )
        .hex();

      theme[':hover'] = {
        backgroundColor: hoverColor,
      };
    }

    return theme;
  };

  private getTextColor = (bgColor: string) => {
    const whiteColor = chroma('white');
    const darkGrayColor = chroma('#162338');

    const contrastWithWhite = chroma.contrast(bgColor, whiteColor);
    const contrastWithDarkGray = chroma.contrast(bgColor, darkGrayColor);

    if (contrastWithWhite > 3.1 || contrastWithWhite > contrastWithDarkGray) {
      return whiteColor.hex();
    } else {
      return darkGrayColor.hex();
    }
  };
}

export default PrimaryButtonTheme;
