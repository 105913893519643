import { Subject } from 'rxjs';
import { DataModelSheet } from 'dataModelSheet';
import { RejectSubmitResult } from './../errors';

export const resultHookObservable = new Subject<{
  dataModelSheet: DataModelSheet;
  notifyOnlyValidValues: boolean;
  onError: (error: RejectSubmitResult) => void;
  onComplete: () => void;
}>();

export const cancelHookObservable = new Subject<null>();
