import Router from '../../Router';
import Modal from '../../Modal';
import { css, cx } from 'core/emotion';
import { useTheme } from '@nuvo-importer/common';

type RouteProps = {
  isOpenModal: boolean;
  cancel: () => void;
  closeModal: () => void;
};

const Route = ({ isOpenModal, cancel, closeModal }: RouteProps) => {
  const theme = useTheme();

  return (
    <Modal isOpenModal={isOpenModal} cancel={cancel} closeModal={closeModal}>
      <div
        className={cx(
          'h-full w-full pt-8',
          css({ '&&': theme.getRootTheme().container })
        )}
      >
        <Router />
      </div>
    </Modal>
  );
};

export default Route;
