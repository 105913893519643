import { css, cx, CSSInterpolation } from '../../../core/emotion';
import { ReactNode, RefObject } from 'react';
import Sheet from '../../../core/sheet/Sheet';
import { SheetName } from '../SheetName';
import { ReactComponent as IconRight } from '../../../assets/icon/arrow-right.svg';
import Progressbar from '../../Progressbar';
import useViewModel from './viewModel';
import { COLOR } from '../../../core/constants/colors';

type TopActionProps = {
  allSheets: Sheet[];
  wrapperSheetNameRef: RefObject<HTMLDivElement>;
  configThemeSheetName?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  configThemeSheetNameSelected?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  configThemeProgressBar: {
    root?: CSSInterpolation;
    progress?: {
      navigatorColor?: string;
      backgroundColor?: string;
    };
  };
  currentProgress: number;
  currentSheetSelected: number;
  goToSheet: (sheet: Sheet) => void;
  className?: string;
  action?: ReactNode;
};

const TopAction = ({
  allSheets,
  wrapperSheetNameRef,
  configThemeSheetName,
  configThemeSheetNameSelected,
  configThemeProgressBar,
  currentProgress,
  currentSheetSelected,
  goToSheet,
  className,
  action,
}: TopActionProps) => {
  const { onScrollWrapperSheet, hasScroll, allSheetsSize, isHasScroll } =
    useViewModel({
      wrapperSheetNameRef,
      allSheets,
    });
  const hasMoreAllSheet = allSheetsSize > 1;

  const isScroller = !isHasScroll
    ? 'bg-white cursor-pointer hover:bg-gray-110'
    : 'bg-gray-120 cursor-not-allowed';

  const iconStyled = css`
    svg path {
      stroke: ${COLOR.DEFAULT};
    }
  `;

  return (
    <div
      className={cx(
        'mb-2 flex w-full items-center justify-between space-x-2',
        hasMoreAllSheet ? 'mb-2' : 'mb-6',
        className
      )}
    >
      <div className="flex h-full w-full max-w-6xl flex-shrink flex-grow space-x-2 overflow-auto">
        <div
          ref={wrapperSheetNameRef}
          className={cx(
            'flex h-full w-full max-w-6xl flex-shrink flex-grow space-x-2 overflow-auto',
            css`
              ::-webkit-scrollbar {
                -webkit-appearance: none;
                display: none;
              }
              ::-webkit-scrollbar-track {
                display: none;
              }
              ::-webkit-scrollbar-thumb {
                display: none;
              }
            `
          )}
        >
          {allSheets.map((sheet, index) => {
            return (
              <SheetName
                key={`${sheet.getName()}:${index}`}
                sheet={sheet}
                configTheme={configThemeSheetName}
                currentSelected={currentSheetSelected === index}
                isCurrentOrAfter={
                  currentSheetSelected === index || index > currentProgress
                }
                isConfirmed={index < currentProgress}
                configThemeSheetNameSelected={configThemeSheetNameSelected}
                hasMoreAllSheet={hasMoreAllSheet}
                goToSheet={goToSheet}
              />
            );
          })}
        </div>
        {hasScroll ? (
          <div
            onClick={onScrollWrapperSheet}
            className={cx(
              'border-gray-120 rounded-medium max-h-3/4 my-2 flex h-auto items-center justify-center border px-2',
              isScroller,
              iconStyled
            )}
          >
            <IconRight />
          </div>
        ) : null}
      </div>
      <div className="flex items-center space-x-2">
        {allSheetsSize > 1 && (
          <Progressbar
            step={currentProgress}
            sheetsSize={allSheetsSize}
            configThemeProgressBar={configThemeProgressBar}
          />
        )}
        {action}
      </div>
    </div>
  );
};

export default TopAction;
