import { css, cx } from '../../core/emotion';

interface CheckboxParams extends React.ComponentProps<'input'> {
  variant?: 'primary' | 'danger';
  disabled?: boolean;
}

const Checkbox = (props: CheckboxParams) => {
  return (
    <label
      className={cx(
        'checkmark border-neutral-100 rounded-medium relative block h-6 w-6 cursor-pointer overflow-hidden border border-solid bg-white',
        css`
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          input:checked ~ .checkmark {
            background-color: #082561;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 8px;
            top: 3px;
            width: 6px;
            height: 13px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        `
      )}
    >
      <input
        type="checkbox"
        className={'absolute top-0 left-0 h-6 w-6 opacity-0'}
        {...props}
      />
      <span className={cx('checkmark absolute top-0 left-0 h-6 w-6')}></span>
    </label>
  );
};

export default Checkbox;
