import { useEffect, useState } from 'react';

const useViewModel = ({
  timer,
  initialProgress,
  step = 1,
  animateByKeyframe,
}: {
  timer: number;
  initialProgress?: number;
  step?: number;
  animateByKeyframe?: boolean;
}) => {
  const [progress, setProgress] = useState(
    initialProgress ? initialProgress : 1
  );

  useEffect(() => {
    if (animateByKeyframe) {
      return () => {};
    }

    if (progress < 100) {
      const interval = setInterval(() => {
        setProgress((progress) => progress + step);
      }, timer);

      return () => {
        clearInterval(interval);
      };
    } else {
      setProgress(100);
    }

    return () => {};
  }, [timer, progress, step, animateByKeyframe]);

  const isMultipleLines = (text: string) => {
    return text.length >= 80;
  };

  return { progress, isMultipleLines, setProgress };
};

export default useViewModel;
