import {
  falsyWords,
  getBooleanOptionsToRenderUI,
  truthyWords,
} from '../../constants/boolean';
import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isNil, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';

class BooleanValueParser implements ValueParser {
  parse = (_dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (isArray(value) || isObject(value)) {
      return '';
    }

    if (truthyWords().includes(`${value}`.toLowerCase())) {
      return getBooleanOptionsToRenderUI()[0];
    }

    if (falsyWords().includes(`${value}`.toLowerCase())) {
      return getBooleanOptionsToRenderUI()[1];
    }

    return '';
  };
}

export default BooleanValueParser;
