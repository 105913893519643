export const NUVO_TAILWIND_CLASS = 'nuvo-tailwind';
export const maxHeightOfReviewEntries = 10000;

export enum DEFAULT_THEME_COLOR {
  BACKGROUND = '#ffffff',
  PRIMARY = '#5C8EF2',
  SECONDARY = '#e0eafd',
  SUCCESS = '#36B37E',
  WARNING = '#FF5630',
  LIGHT_WARNING = '#FFEBE6',
}
