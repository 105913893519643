import { useMainView } from 'settings';
import { useModal } from '../../main/Modal';

const useViewModel = () => {
  const { toggleFullScreen, isFullScreen } = useModal();

  const { modal } = useMainView();

  return {
    toggleFullScreen,
    isFullScreen,
    modal,
  };
};

export default useViewModel;
