import { Variant, variantClassname } from './variantStyle';
import { Size, sizeClassname } from './sizeStyle';
import { useTheme } from '../../theme';

type UseViewModelParams = {
  variant: Variant;
  size: Size;
  hideDisableStyle: boolean;
};

const useViewModel = ({
  variant,
  size,
  hideDisableStyle,
}: UseViewModelParams) => {
  const theme = useTheme();
  return {
    variantClassname: variantClassname({
      variant,
      hideDisableStyle,
      configTheme: theme.getButtonTheme(),
    }),
    sizeClassname: sizeClassname({ size }),
  };
};

export default useViewModel;
