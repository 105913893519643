import { useMemo } from 'react';

const useViewModel = ({
  status,
}: {
  status: 'current' | 'next' | 'previous';
}) => {
  const previous = useMemo(() => {
    return status === 'previous';
  }, [status]);

  const current = useMemo(() => {
    return status === 'current';
  }, [status]);

  return { previous, current };
};

export default useViewModel;
