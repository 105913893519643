import { CONFIG_ANIM_TIME } from '../../core';
import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import SuccessModal, { SuccessModalProps } from './index';

type SuccessModalManagerProps = {
  children?: ReactNode;
};

type ContextType = {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  showSuccessModal: (options: SuccessModalProps) => void;
};

const ModalSuccessManagerContext = createContext({} as ContextType);

const SuccessModalManager: FC<SuccessModalManagerProps> = ({ children }) => {
  const [isOpen, setOpenModal] = useState(false);
  const [options, setOptions] = useState<SuccessModalProps>();

  const showSuccessModal = useCallback((options: SuccessModalProps) => {
    setOptions(options);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOpenModal(false);
      }, options?.duration ?? CONFIG_ANIM_TIME.THREE_SECONDS);
    }
  }, [isOpen, options?.duration]);

  return (
    <ModalSuccessManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        showSuccessModal,
      }}
    >
      {children}
      {options && (
        <SuccessModal
          title={options.title}
          description={options.description}
          options={options.options}
          logoURL={options.logoURL}
        />
      )}
    </ModalSuccessManagerContext.Provider>
  );
};

export const useContextSuccessModalManager = () => {
  return useContext(ModalSuccessManagerContext);
};

export default SuccessModalManager;
