import { cx } from '@nuvo-importer/common';

type ICountBadgeProps = {
  count: number;
  className?: string;
};

const CountBadge = ({ count, className }: ICountBadgeProps) => {
  return (
    <div
      className={cx(
        'text-blue-light-500 bg-blue-light-50 inline-block rounded-2xl px-2.5 py-1 text-xs',
        className
      )}
    >
      {count}
    </div>
  );
};

export default CountBadge;
