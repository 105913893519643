import { css, cx, useTheme } from '@nuvo-importer/common';
import { SheetColumn } from '@nuvo-importer/common/sdk';

const SheetName = ({
  sheetColumn,
  showColumnKey,
}: {
  sheetColumn: SheetColumn;
  showColumnKey: boolean;
}) => {
  const theme = useTheme();
  return (
    <div
      className={cx(
        'text-xss mt-2 ml-7 leading-3',
        css({
          '&&': theme.getColumnMatchTheme().sheet?.root,
        })
      )}
    >
      <span
        className={cx(
          'text-blue-light-500 font-semibold',
          css({
            '&&': theme.getColumnMatchTheme().sheet?.name,
          })
        )}
      >
        {sheetColumn.getSheet().getName()}
      </span>{' '}
      <span
        className={cx(
          'text-gray-560',
          css({
            '&&': theme.getColumnMatchTheme().sheet?.fileName,
          })
        )}
      >
        {sheetColumn.getSheet().getSheetFileName()}
      </span>{' '}
      {showColumnKey ? (
        <span
          className={cx(
            'text-neutral-gray italic',
            css({
              '&&': theme.getColumnMatchTheme().sheet?.column,
            })
          )}
        >
          ({sheetColumn.getColumnKey()})
        </span>
      ) : null}
    </div>
  );
};

export default SheetName;
