import { useMemo, useState } from 'react';
import { Option } from '../viewModel';

type ViewModelProps<T> = {
  setSelected: (value: T[]) => void;
  selected: T[];
  options: Option<T>[];
};

const useViewModel = <T>({
  selected,
  setSelected,
  options,
}: ViewModelProps<T>) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const onSelectItem = (option: Option<T>) => {
    if (selected.includes(option.value)) {
      setSelected(selected.filter((value) => value !== option.value));
    } else {
      setSelected([...selected, option.value]);
    }
  };

  const isAllSelected = useMemo(() => {
    return options.every((item) => selected.includes(item.value));
  }, [options, selected]);

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelected([]);
    } else {
      setSelected(options.map((option) => option.value));
    }
  };

  const onMouseOver = (index: number) => {
    setHighlightedIndex(index);
  };

  const onMenuPaneMouseOut = () => {
    setHighlightedIndex(-1);
  };

  const allCheckboxIndeterminate = useMemo(() => {
    if (
      !isAllSelected &&
      options.some((item) => selected.includes(item.value))
    ) {
      return true;
    } else {
      return false;
    }
  }, [options, selected, isAllSelected]);

  return {
    onSelectItem,
    isAllSelected,
    onSelectAll,
    highlightedIndex,
    onMouseOver,
    onMenuPaneMouseOut,
    allCheckboxIndeterminate,
  };
};

export default useViewModel;
