const PREFIX_VALUE = 'a09dfde5254303c288e569776e2a0a6a_';

export const encode = (str: string, disabledPrefix?: boolean): string =>
  disabledPrefix
    ? `${btoa(unescape(encodeURIComponent(str)))}`
    : `${PREFIX_VALUE}${btoa(unescape(encodeURIComponent(str)))}`;

export const decode = (key: string, str: string): string => {
  if (typeof process === 'object') {
    try {
      return Buffer.from(str.replace(key, ''), 'base64').toString();
    } catch (e) {
      console.log('nuvo error: ', e);
      return decodeURIComponent(escape(atob(str.replace(key, ''))));
    }
  }
  return decodeURIComponent(escape(atob(str.replace(key, ''))));
};
