import { cx } from '@emotion/css';
import { ReactNode } from 'react';
import { Configure } from './Configure';
import { ToastContainer } from '@getnuvo/ui-kit';

type LayoutProps = {
  children: ReactNode;
  title: string;
  className?: string;
};

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <header>
        <Configure />
      </header>
      <main className="pt-8">
        <div className={cx(className, 'min-h-400')}>{children}</div>
      </main>

      <ToastContainer />
    </div>
  );
};

export default Layout;
