import SimpleBar from 'simplebar-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import {
  ColumnMapping,
  Sheet,
  SheetColumn,
  createWorker,
} from '@nuvo-importer/common/sdk';
import { FormState } from 'final-form';
import { joinSheetModalId } from '../..';
import { IMatchingValuesWorker } from '../../../../../../reviewEntries/worker/type';
import matchingValuesBuildWorker from '../../../../../../worker/matchingValues.txt';
import { IFormValues } from '../viewModel';
import { Remote, releaseProxy, wrap } from 'comlink';
import { convertColumnMappingToIndex } from '../../../../utils';

const getHasChanged = (
  previousMatching: {
    target: SheetColumn | null;
    source: SheetColumn | null;
  }[],
  updatedMatching: {
    target: SheetColumn | null;
    source: SheetColumn | null;
  }[]
) => {
  return (
    updatedMatching.length !== previousMatching.length ||
    updatedMatching.some((updatedMatchingItem, index) => {
      const previousSource = previousMatching[index].source;
      const previousTarget = previousMatching[index].target;
      const updatedSource = updatedMatchingItem.source;
      const updatedTarget = updatedMatchingItem.target;
      if (
        !previousSource ||
        !previousTarget ||
        !updatedSource ||
        !updatedTarget
      )
        return true;
      return (
        !updatedSource.equal(previousSource) ||
        !updatedTarget.equal(previousTarget)
      );
    })
  );
};

const useViewModel = ({
  joinTargetSheet,
  sourceSheet,
}: {
  joinTargetSheet: Sheet;
  sourceSheet: Sheet;
}) => {
  const { values } = useFormState<IFormValues>();
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const [matchPercent, setMatchPercent] = useState(0);
  const matchingValuesWorker = useRef<Worker>();
  const matchingValuesWorkerRemote =
    useRef<Remote<IMatchingValuesWorker> | null>();
  const [countMatching, setCountMatching] = useState(false);
  const scrollbarRef = useRef<SimpleBar>(null);
  const sheetName = joinTargetSheet.getName();
  const joinMaxLength = useMemo(() => {
    return Math.min(
      joinTargetSheet.getColumns().length,
      sourceSheet.getColumns().length
    );
  }, [joinTargetSheet, sourceSheet]);

  const clearWorker = () => {
    if (matchingValuesWorkerRemote.current) {
      matchingValuesWorkerRemote.current?.[releaseProxy]();
      matchingValuesWorker.current?.terminate();
      matchingValuesWorkerRemote.current = null;
    }
  };

  useEffect(() => {
    return () => {
      clearWorker();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setModalElement(
        (document.getElementById(joinSheetModalId) as HTMLDivElement) ?? null
      );
    }, 0);
  }, []);

  const onValuesChange = async ({
    values: updateValues,
  }: FormState<IFormValues>) => {
    const updatedMatching = updateValues.columns.filter((col) => {
      return col.source && col.target;
    });
    const previousMatching = values.columns.filter((col) => {
      return col.source && col.target;
    });
    const hasChanged = getHasChanged(previousMatching, updatedMatching);
    if (updatedMatching.length > 0 && hasChanged) {
      clearWorker();
      matchingValuesWorker.current = createWorker(matchingValuesBuildWorker);
      matchingValuesWorkerRemote.current = wrap<IMatchingValuesWorker>(
        matchingValuesWorker.current
      );

      setCountMatching(true);
      const mappingIndexes = convertColumnMappingToIndex(
        updatedMatching as ColumnMapping[],
        sourceSheet,
        joinTargetSheet
      );
      const matchingCount =
        await matchingValuesWorkerRemote.current.countMatching(
          sourceSheet.toJSON(),
          joinTargetSheet.toJSON(),
          mappingIndexes
        );
      const matchPercent = (matchingCount.count / matchingCount.total) * 100;
      setMatchPercent(Math.round(matchPercent * 100) / 100);
      setCountMatching(false);
      clearWorker();
    }
  };

  const getTargetOptions = useCallback(
    (position) => {
      return joinTargetSheet
        .getColumns()
        .filter((sheetColumn) => {
          return values.columns.every((item, index) => {
            if (index === position || !item.target) {
              return true;
            }

            return !item.target.equal(sheetColumn);
          });
        })
        .map((sheetColumn) => {
          return {
            label: sheetColumn.getColumnKey() ?? '',
            value: sheetColumn,
          };
        });
    },
    [joinTargetSheet, values]
  );

  const getSourceOptions = useCallback(
    (position: number) => {
      return sourceSheet
        .getColumns()
        .filter((sheetColumn) => {
          return values.columns.every((item, index) => {
            if (index === position || !item.source) {
              return true;
            }

            return !item.source.equal(sheetColumn);
          });
        })
        .map((sheetColumn) => {
          return {
            label: sheetColumn.getColumnKey() ?? '',
            value: sheetColumn,
          };
        });
    },
    [sourceSheet, values.columns]
  );

  return {
    scrollbarRef,
    modalElement,
    getTargetOptions,
    sheetName,
    getSourceOptions,
    joinMaxLength,
    values,
    matchPercent,
    onValuesChange,
    countMatching,
  };
};

export default useViewModel;
