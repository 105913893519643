import {
  ICleaningAssistantRequestDTO,
  ICleaningAssistantResponse,
} from './CleaningAssistant.dto';
import Axios, { AxiosResponse } from 'axios';

class CleaningAIServices {
  async getSuggestions(
    licenseKey: string,
    origin: string,
    body: ICleaningAssistantRequestDTO
  ): Promise<ICleaningAssistantResponse> {
    const url = `${process.env.NX_CLEANING_ASSISTANT_URL}/data-cleaning`;

    const headers = {
      apikey: process.env.NX_API_GATEWAY_TOKEN,
      Authorization: licenseKey,
      originRequest: origin,
    };

    return await Axios.post(url, body, {
      headers,
    }).then(({ data }: AxiosResponse<ICleaningAssistantResponse>) => data);
  }
}

export default CleaningAIServices;
