import { createContext, useContext, useState, ReactNode } from 'react';
import { SheetColumn } from 'sheetImporter';

type ContextType = {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  sheetColumnsJoin: SheetColumn[];
  setSheetColumnsJoin: (sheetColumnsJoin: SheetColumn[]) => void;
};

const JoinColumnsManagerContext = createContext({} as ContextType);

const JoinColumnsManager = ({ children }: { children: ReactNode }) => {
  const [isOpen, setOpenModal] = useState(false);
  const [sheetColumnsJoin, setSheetColumnsJoin] = useState<SheetColumn[]>([]);

  return (
    <JoinColumnsManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        sheetColumnsJoin,
        setSheetColumnsJoin,
      }}
    >
      {children}
    </JoinColumnsManagerContext.Provider>
  );
};

export const useContextJoinColumnsModalManager = () => {
  return useContext(JoinColumnsManagerContext);
};

export default JoinColumnsManager;
