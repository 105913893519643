import { TextLoading } from '../TextLoading';
import { css, cx } from '../../core/emotion';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import useViewModel from './viewModel';

const containerStyle = css`
  width: 196px;
`;

export type ProgressProcessingImperativeHandle = {
  reset: () => void;
};

const ProgressProcessing = forwardRef<
  ProgressProcessingImperativeHandle,
  {
    timer: number;
    text?: string;
    className?: string;
    initialProgress?: number;
    notShowJustMoment?: boolean;
    step?: number;
    animateByKeyframe?: boolean;
  }
>(
  (
    {
      timer,
      text,
      className,
      initialProgress,
      notShowJustMoment = false,
      animateByKeyframe,
      step,
    },
    forwardRef
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { progress, isMultipleLines, setProgress } = useViewModel({
      timer,
      initialProgress,
      step,
      animateByKeyframe,
    });

    useImperativeHandle(forwardRef, () => {
      return {
        reset: () => {
          setProgress(0);
        },
      };
    });

    const isMultipleLine = useMemo(
      () =>
        isMultipleLines(
          progress >= 100 && !notShowJustMoment
            ? t('txt_just_a_moment_please')
            : (text as string)
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [text, progress, notShowJustMoment]
    );

    return (
      <div
        className={cx(
          className,
          isMultipleLine ? 'max-w-md' : '',
          css`
            min-width: 196px;
          `
        )}
      >
        <div
          className={cx(
            'will-change-transform w-full p-3',
            css({ '&&': theme.getLoaderTheme().root })
          )}
        >
          <div className="mx-auto mb-10">
            <div
              className={cx(
                'mx-auto h-2.5 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700',
                containerStyle,
                css`
                  max-width: 172px;
                  min-width: 172px;
                `
              )}
              style={{
                transform: 'translateZ(0)',
              }}
            >
              <div
                className={cx(
                  'relative -left-full h-2.5 rounded-full transition-transform ease-linear',
                  css`
                    background-color: ${theme.getLoaderTheme()
                      .loadAnimationColor
                      ? theme.getLoaderTheme().loadAnimationColor
                      : theme.getGlobalTheme().getPrimaryColor()};
                  `,
                  containerStyle,
                  animateByKeyframe ? 'translate-by-keyframe' : ''
                )}
                style={{
                  width: '100%',
                  transform: animateByKeyframe
                    ? undefined
                    : `translateX(${progress}%)`,
                  willChange: 'transform',
                }}
              ></div>
            </div>
          </div>
          {progress >= 100 && !notShowJustMoment ? (
            <TextLoading
              className={cx(
                'text-color-primary mx-auto -mt-7 text-center text-sm font-semibold',
                css({ '&&': theme.getLoaderTheme().content })
              )}
            >
              {t('txt_just_a_moment_please')}
            </TextLoading>
          ) : (
            <p
              className={cx(
                'text-color-primary -mt-7 flex justify-center text-center text-sm font-semibold',
                css({ '&&': theme.getLoaderTheme().content })
              )}
            >
              {text}
            </p>
          )}
        </div>
      </div>
    );
  }
);

export default ProgressProcessing;
