import { useState } from 'react';
import { usePopper } from 'react-popper';

const useViewModel = ({ boundary }: { boundary?: HTMLDivElement }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  const popper = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          boundary,
        },
      },
      { name: 'hide', enabled: false },
    ],
  });

  return {
    popperElement,
    setPopperElement,
    referenceElement,
    setReferenceElement,
    popper,
  };
};

export default useViewModel;
