import { HotTableClass } from '@handsontable/react';
import { RefObject, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { UndoRedoEvent } from '../../type';

type IViewModelProps = {
  undoRedoObservable: Subject<UndoRedoEvent>;
  hotInstance: RefObject<HotTableClass>;
};

const useViewModel = ({ undoRedoObservable, hotInstance }: IViewModelProps) => {
  const [isUndoAvailable, setIsUndoAvailable] = useState(false);
  const [isRedoAvailable, setIsRedoAvailable] = useState(false);

  const onUndoClick = () => {
    hotInstance.current?.hotInstance?.undo();
  };

  const onRedoClick = () => {
    hotInstance.current?.hotInstance?.redo();
  };

  useEffect(() => {
    const updateUndoRedoAvailable = () => {
      setIsUndoAvailable(
        hotInstance.current?.hotInstance?.isUndoAvailable() ?? false
      );
      setIsRedoAvailable(
        hotInstance.current?.hotInstance?.isRedoAvailable() ?? false
      );
    };
    updateUndoRedoAvailable();
    const subscription = undoRedoObservable.subscribe(updateUndoRedoAvailable);

    return () => {
      subscription.unsubscribe();
    };
  }, [undoRedoObservable, hotInstance]);

  return { onUndoClick, onRedoClick, isUndoAvailable, isRedoAvailable };
};

export default useViewModel;
