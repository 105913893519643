import { cx, css } from 'core/emotion';
import { useTheme } from 'theme';
import BaseModal from '../BaseModal';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'core/constants/screensSize';
import useViewModel from './viewModel';
import { useMemo } from 'react';
import { COLOR_SCROLL_BAR } from 'core/constants/colors';

const JoinColumnsModal = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isNotSmallScreen } = useScreenSize();
  const {
    isHasScroll,
    isOpen,
    onCloseModal,
    sheetColumnsSize,
    tableWrapperRef,
    sheetColumns,
  } = useViewModel();

  const configureStyled = useMemo(() => {
    return theme.getColumnMatchTheme().dialogJoinColumn;
  }, [theme]);

  return (
    <BaseModal
      className={cx(
        'max-w-914 !my-auto border border-gray-100 !p-8 !pt-5 !pr-5',
        !isNotSmallScreen ? 'invisible' : ''
      )}
      gravity="middle"
      isOpen={isOpen}
      onCloseModal={() => onCloseModal()}
      isShowCloseIcon
      wrapperClassName={cx(
        'join-columns-modal px-10',
        !isNotSmallScreen ? 'invisible' : '',
        css({
          '&&': {
            zIndex: 10000,
          },
        })
      )}
      contentClassName="pr-3"
      overlayStyled={configureStyled?.overlay}
      bodyStyled={configureStyled?.root}
      closeIconStyled={configureStyled?.closeIcon}
    >
      <div className="mt-4 text-left">
        <h1
          className={cx(
            'text-3.5xl text-blue-dark-900 mb-2 font-semibold',
            css({ '&&': configureStyled?.title })
          )}
        >
          {t('txt_joined_columns')}
        </h1>
        <div
          className={cx(
            'text-gray-560 text-base font-normal',
            css({ '&&': configureStyled?.description })
          )}
        >
          {t('txt_joined_columns_description', {
            sheet: sheetColumnsSize,
            column: sheetColumnsSize,
          })}
        </div>
      </div>
      <div
        className={cx(
          'rounded-medium mt-6 h-full w-full bg-gray-50 p-2',
          css({ '&&': configureStyled.table?.background })
        )}
      >
        <div
          ref={tableWrapperRef}
          className={cx(
            'rounded-medium flex space-x-2 overflow-x-auto ',
            isHasScroll ? 'pb-3' : 'pb-0',
            css`
              ::-webkit-scrollbar {
                -webkit-appearance: none;
                height: 5px;
              }
              ::-webkit-scrollbar-track {
                background-color: ${configureStyled.table?.scrollbar
                  ?.backgroundColor
                  ? configureStyled.table?.scrollbar?.backgroundColor
                  : COLOR_SCROLL_BAR.BACKGROUND};
                border-radius: 60px;
              }
              ::-webkit-scrollbar-thumb {
                background-color: ${configureStyled.table?.scrollbar
                  ?.navigatorColor
                  ? configureStyled.table?.scrollbar?.navigatorColor
                  : COLOR_SCROLL_BAR.NAVIGATOR};
                border-radius: 60px;
                opacity: 1;
              }
            `
          )}
        >
          {sheetColumns.map((sheetColumn) => {
            return (
              <div
                className={cx(
                  'min-w-412 rounded-medium h-full w-full bg-white',
                  css({ '&&': configureStyled.table?.wrapper })
                )}
              >
                <div>
                  <div className="p-4 text-left">
                    <h1
                      className={cx(
                        'text-blue-dark-900 mb-1 w-full truncate text-base font-semibold',
                        css({ '&&': configureStyled.table?.title })
                      )}
                    >
                      {sheetColumn?.getSheet().getName() ||
                        t('txt_default_sheet_prefix_name')}
                    </h1>
                    <div
                      className={cx(
                        'text-gray-560 w-full truncate text-base',
                        css({ '&&': configureStyled.table?.description })
                      )}
                    >
                      {`${sheetColumn?.getFileName()}`}
                    </div>
                  </div>
                </div>
                <div
                  className={cx(
                    'rounded-medium mx-2 mb-2 h-full overflow-hidden border border-gray-200 text-left',
                    css({ '&&': configureStyled.table?.root })
                  )}
                >
                  <table
                    className={cx(
                      'join-columns-table w-full',
                      css`
                        tbody tr:last-child td {
                          border: none;
                        }
                      `
                    )}
                  >
                    <thead>
                      <tr>
                        <th
                          className={cx(
                            'text-gray-560 leading-5.5 border-b-1 h-10 bg-gray-50 px-4 text-sm font-normal',
                            css({ '&&': configureStyled.table?.th })
                          )}
                        >
                          {sheetColumn.getColumnKey()}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sheetColumn
                        .getRows()
                        .slice(0, 10)
                        .map((row) => {
                          return (
                            <tr>
                              <td
                                className={cx(
                                  'h-33 text-blue-dark-900 leading-5.5 border-b-1 box-border bg-white !px-4 text-sm font-normal',
                                  css({ '&&': configureStyled.table?.td })
                                )}
                              >
                                {row}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

export default JoinColumnsModal;
