import { useTranslation } from 'react-i18next';
import AddFilesUploader from '../../../sheetImporter/AddFilesUploader';
import { ReactComponent as AddIcon } from '../../../assets/icon/add.svg';
import useViewModel from './viewModel';
import SpreadSheetList from '../../../sheetImporter/SpreadSheetList';
import { Loader } from 'baseUI/Button';
import { useTheme } from 'theme';
import { css, cx } from 'core/emotion';

const AddFile = ({
  onAddFile,
}: {
  onAddFile: (spreadSheetList: SpreadSheetList) => void;
}) => {
  const { t } = useTranslation();
  const { handleSuccess, handleError, processing, setProcessing } =
    useViewModel({
      onFileSelectedSuccess: onAddFile,
    });
  const theme = useTheme();

  return (
    <AddFilesUploader
      onFileSelectedSuccess={handleSuccess}
      onFileSelectedError={handleError}
      onProcessing={setProcessing}
    >
      <button
        disabled={processing}
        className={cx(
          'text-blue-dark-900 rounded-medium border-blue-dark-50 flex w-full items-center justify-center border bg-white px-4 py-3 text-center text-sm font-semibold disabled:cursor-auto',
          css({ '&&': theme.getButtonTheme().secondary }),
          css({ '&&&': theme.getSheetSelectTheme().addButton?.root })
        )}
      >
        {processing ? (
          <span className="-mr-0.5 flex items-center">
            <Loader className="!inline-block" />
          </span>
        ) : (
          <AddIcon
            className={cx(
              css`
                color: currentColor;
              `,
              css({
                '&&': theme.getSheetSelectTheme()?.addButton?.icon,
              })
            )}
          />
        )}
        <span className={cx('ml-2')}>{t('txt_add_files')}</span>
      </button>
    </AddFilesUploader>
  );
};

export default AddFile;
