import { BooleanSingleton } from '../BooleanSingleton';

export const booleanOptions = ['Ja', 'Nein'];

export const truthyWords = () => {
  return BooleanSingleton.getInstance().getTruthyWords();
};

export const falsyWords = () => {
  return BooleanSingleton.getInstance().getFalsyWords();
};

export const booleanDropdownOptions = () => {
  return BooleanSingleton.getInstance().getOption();
};

export const getBooleanOptionsToRenderUI = () =>
  BooleanSingleton.getInstance().getBooleanOptionToRender();

export const booleanWhitelist = [...truthyWords(), ...falsyWords()];
