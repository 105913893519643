import { DataModel } from '../../../core/dataModel/model/DataModel';
import BooleanValueParser from './BooleanValueParser';
import IValueParser, { PossibleValue } from './IValueParser';
import CategoryValueParser from './CategoryValueParser';
import StringValueParser from './StringValueParser';
import IntNumberValueParser from './IntNumberValueParser';
import FloatNumberValueParser from './FloatNumberValueParser';
import CurrencyValueParser from './CurrencyValueParser';
import { DATATYPE } from '../../../core/dataType';
export type { ExcelValue as Value } from './IValueParser';

class ExportValueParser {
  static parse = (dataModel: DataModel, value: PossibleValue) => {
    let parser: IValueParser;
    switch (dataModel.getType()) {
      case DATATYPE.INTEGER:
        parser = new IntNumberValueParser();
        break;
      case DATATYPE.FLOAT:
        parser = new FloatNumberValueParser();
        break;
      case DATATYPE.BOOLEAN:
        parser = new BooleanValueParser();
        break;
      case DATATYPE.SINGLE_SELECT:
      case DATATYPE.CURRENCY_CODE:
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
        parser = new CategoryValueParser();
        break;
      case DATATYPE.CURRENCY_EUR:
      case DATATYPE.CURRENCY_USD:
        parser = new CurrencyValueParser();
        break;
      default:
        parser = new StringValueParser();
        break;
    }

    return parser.parse(dataModel, value);
  };
}

export default ExportValueParser;
