import { css, CSSInterpolation, cx } from 'core/emotion';
import Popover from 'baseUI/Popover';
import { DataModelSheetMatch } from '../../../../../../../matching/DataModelSheetMatching';
import BadgeMatchItemWrapper from '../index.view';
import useViewModel from './viewModel';
import SheetColumn from './../../../../../../../sheetImporter/SheetColumn';
import { Boundary } from '@popperjs/core';
import { useRef } from 'react';
import { useTheme } from '../../../../../../../theme';

const maxStyle = css`
  max-width: 80px;
`;

const PopoverBadgeMatchItem = ({
  sheetColumn,
  onRemoveMatchColumn,
  boundary,
  configThemeBadge,
  configThemePopoverInfo,
}: {
  sheetColumn: DataModelSheetMatch;
  onRemoveMatchColumn: (value: SheetColumn) => void;
  boundary?: Boundary;
  configThemeBadge?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
  configThemePopoverInfo?: {
    root?: string;
    arrowIcon?: string;
  };
}) => {
  const { isShowPopover, getOnHover, onDeleteMatchColumn } = useViewModel({
    sheetColumn,
    onRemoveMatchColumn,
  });
  const ref = useRef<HTMLSpanElement>(null);
  const theme = useTheme();

  const element = ref.current as HTMLSpanElement;

  const columnKey = sheetColumn.sheetColumn.getColumnKey();

  return (
    <Popover
      isShowArrow
      boundary={boundary}
      direction="top"
      isShow={isShowPopover && element?.scrollWidth > element?.clientWidth}
      overflowCheckOffset={{ top: 10, bottom: -25 }}
      message={
        <div
          className={cx(
            'text-xss rounded-medium border-none py-1 px-2 text-white',
            css({
              backgroundColor: theme.getGlobalTheme().getDark500Color(),
            }),
            css({
              '&&': configThemePopoverInfo?.root,
            })
          )}
        >
          {columnKey}
        </div>
      }
      arrowClassName={configThemePopoverInfo?.arrowIcon}
      render={({ referenceElement }) => {
        return (
          <span ref={referenceElement} {...getOnHover()}>
            <BadgeMatchItemWrapper
              tabIndex={-1}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onDeleteMatchColumn={onDeleteMatchColumn}
              configTheme={configThemeBadge}
            >
              <span ref={ref} className={cx('w-fit truncate', maxStyle)}>
                {columnKey}
              </span>
            </BadgeMatchItemWrapper>
          </span>
        );
      }}
    />
  );
};

export default PopoverBadgeMatchItem;
