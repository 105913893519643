import { ColumnMapping } from '@nuvo-importer/common/sdk';
import Sheet from '../Sheet';

export const convertColumnMappingToIndex = (
  columnMappings: ColumnMapping[],
  sourceSheet: Sheet,
  targetSheet: Sheet
) => {
  return columnMappings.map((joinColumn) => {
    return {
      source: sourceSheet
        .getColumns()
        .findIndex((column) => column.equal(joinColumn.source)),
      target: targetSheet
        .getColumns()
        .findIndex((column) => column.equal(joinColumn.target)),
    };
  });
};
