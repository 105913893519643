import useViewModel from './viewModel';
import Popover from '../Popover';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand-solid.svg';
import { ReactComponent as CompressIcon } from '../../assets/icon/compress-solid.svg';
import { css, CSSInterpolation, cx } from '../../core/emotion';

const FullScreenButton = ({
  className,
  theme,
  toggleFullScreen,
  isFullScreen,
  modal,
}: {
  className?: string;
  theme?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
  toggleFullScreen: () => void;
  isFullScreen: boolean;
  modal?: boolean;
}) => {
  const { getOnHover, isShowInfo, setIsShowInfo, isShowButton } = useViewModel({
    modal,
  });
  const { t } = useTranslation();

  if (!isShowButton) {
    return null;
  }

  return (
    <Popover
      isShowArrow
      arrowClassName="#0D2737"
      isShow={isShowInfo}
      direction="top"
      offset={[0, 8]}
      message={
        <div className="text-xss bg-primary rounded-medium py-1 px-2 text-white">
          {isFullScreen ? t('txt_collapse_view') : t('txt_expand_view')}
        </div>
      }
      render={({ referenceElement }) => {
        return (
          <button
            ref={referenceElement as RefObject<HTMLButtonElement>}
            {...getOnHover()}
            onClick={() => {
              toggleFullScreen();
              window.dispatchEvent(new Event('resize'));
              setIsShowInfo(false);
            }}
            className={cx(
              ' text-blue-dark-900 flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50',
              className,
              css({ '&&': theme?.root })
            )}
          >
            {isFullScreen ? (
              <CompressIcon
                className={cx('h-5 w-5', css({ '&& path': theme?.icon }))}
              />
            ) : (
              <ExpandIcon
                className={cx('h-5 w-5', css({ '&& path': theme?.icon }))}
              />
            )}
          </button>
        );
      }}
    />
  );
};

export default FullScreenButton;
