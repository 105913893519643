import DataModelSheetMatcher from './../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from './../../matching/DataModelSheetMatching';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { ColumnAPI, DataModel } from 'dataModel';

export const useAutomaticMapping = () => {
  const [isBypass, setBypass] = useState<boolean>(true);
  const { state: locationState } = useLocation();

  const state = locationState as {
    dataModelSheetMatching: DataModelSheetMatching;
    spreadSheetNavigate: SpreadSheetNavigate;
    dataModelSheetMatcher: DataModelSheetMatcher;
    columns: ColumnAPI[];
    dataModels: DataModel[];
    isAutoMapping?: boolean;
  };

  useEffect(() => {
    if (
      state?.dataModelSheetMatcher === undefined &&
      state?.dataModelSheetMatching === undefined
    ) {
      setBypass(false);
    } else {
      setBypass(!!state.isAutoMapping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    isBypass,
    setBypass,
  };
};
