import { DATATYPE } from 'core/dataType';
import { booleanDropdownOptions } from 'core/constants/boolean';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import { DataModel } from '../../dataModel/model/DataModel';

export const breathing = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const allDropdownOptions = (dataModels: DataModel[]) => {
  const items: {
    label: string;
    value: string;
  }[][] = [];
  for (let i = 0; i < dataModels.length; ++i) {
    let options: { label: string; value: string }[] = [];
    if (dataModels[i].isCategoryType()) {
      for (
        let j = 0;
        j < (dataModels[i] as CategoryDataModel).getOptions().length;
        j++
      ) {
        options.push({
          label: (dataModels[i] as CategoryDataModel).getOptions()[j].label,
          value: (dataModels[i] as CategoryDataModel).getOptions()[j].value,
        });
      }
    } else if (dataModels[i].getType() === DATATYPE.BOOLEAN) {
      options = booleanDropdownOptions();
    }

    items.push(options);
  }
  return items;
};

export const findDropdownOption = (
  columnIndex: number,
  value: string,
  allDropdownOptions: {
    label: string;
    value: string;
  }[][],
  optionValueType: 'label' | 'value' | 'both'
) => {
  const options = allDropdownOptions[columnIndex];
  if (options && options.length > 0) {
    for (let i = 0; i < options.length; ++i) {
      if (optionValueType === 'both') {
        if (
          options[i].value?.toLowerCase()?.trim() ===
            `${value}`?.toLowerCase()?.trim() ||
          options[i]?.label?.toLowerCase() === `${value}`?.toLowerCase()?.trim()
        ) {
          return options[i]['value'];
        }
      } else {
        if (
          optionValueType === 'label'
            ? options[i].value?.toLowerCase()?.trim() ===
              `${value}`?.toLowerCase()?.trim()
            : options[i].label?.toLowerCase()?.trim() ===
              `${value}`?.toLowerCase()?.trim()
        ) {
          return options[i][optionValueType];
        }
      }
    }
    return value;
  } else {
    return value;
  }
};

export function shouldParseDateFormat(
  colType: DATATYPE | undefined,
  hasParsedDate: boolean
) {
  if (
    [
      DATATYPE.DATE_DMY,
      DATATYPE.DATE_MDY,
      DATATYPE.DATE_ISO,
      DATATYPE.DATETIME,
      DATATYPE.DATE,
    ].includes(colType || DATATYPE.STRING) &&
    !hasParsedDate
  ) {
    return true;
  }

  return false;
}
