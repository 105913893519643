import { Header, ActionFooter } from 'navigation';
import { useTranslation } from 'react-i18next';
import { SheetTable } from './index.view';
import useViewModel from './viewModel';
import TopSections from '../TopSections';
import Pagination from 'baseUI/Pagination';
import { usePagination } from './common';
import { useMainView, useSettings } from 'settings';
import { css, cx } from 'core/emotion';
import { useTheme } from '@nuvo-importer/common';

const SheetSelectionPage = () => {
  const { modal } = useMainView();
  const wrapperClassName = modal ? 'absolute' : 'fixed';
  const widthWrapperClassName = modal ? 'w-full mt-6' : 'px-0';
  const { t } = useTranslation();
  const { multipleFileUpload } = useSettings();
  const {
    onBackClick,
    onContinueClick,
    sheets,
    onSelected,
    selected,
    disabledContinue,
    onSelectViewTemplate,
    viewTemplate,
    onAddFile,
    spreadSheets,
    onSelectedSpreadSheet,
    selectedSpreadSheet,
    displaySheets,
    setGridHeight,
    gridHeight,
  } = useViewModel();

  const {
    pageIndex,
    sliceSheets,
    pageOptions,
    pageCount,
    onNextPage,
    onPreviousPage,
    onGotoPage,
  } = usePagination({
    sheets: displaySheets,
    viewTemplate,
    setGridHeight,
  });

  const theme = useTheme();
  const pageHeaderTheme = theme.getSheetSelectTheme().pageHeader;

  return (
    <div className={cx('flex flex-col', modal ? '' : 'mb-6')}>
      <Header
        title={t('txt_sheet_selection')}
        description={t(
          multipleFileUpload
            ? 'txt_multiple_sheet_selection_description'
            : 'txt_sheet_selection_description'
        )}
        className="flex-shrink-0"
        headerTheme={pageHeaderTheme}
      />
      <TopSections
        onSelectViewTemplate={onSelectViewTemplate}
        selectViewTemplate={viewTemplate}
        size={sheets.length}
        fileSize={spreadSheets.length}
      />
      <div className={cx('flex-grow', modal ? 'px-0' : 'px-6')}>
        <SheetTable
          spreadSheets={spreadSheets}
          sheets={sliceSheets}
          onSelected={(id) => onSelected(id, pageIndex)}
          selected={selected}
          viewTemplate={viewTemplate}
          onAddFile={onAddFile}
          onSelectMenu={onSelectedSpreadSheet}
          selectedMenu={selectedSpreadSheet}
          gridHeight={gridHeight}
        />
      </div>
      <div className="mt-6">
        <Pagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          previousPage={() => onPreviousPage()}
          canPreviousPage={pageIndex !== 0}
          nextPage={() => onNextPage()}
          canNextPage={pageIndex !== pageCount - 1}
          gotoPage={onGotoPage}
          pageOptions={pageOptions}
        />
      </div>
      <div className={cx(modal ? 'pt-6' : '')}>
        <ActionFooter
          footerStyle="px-8"
          className={cx(
            'bottom-0 left-0 right-0 z-10 flex flex-col bg-white',
            wrapperClassName,
            widthWrapperClassName,
            css`
              filter: drop-shadow(0px -13px 50px rgba(17, 48, 68, 0.08));
            `
          )}
          disabledPositive={disabledContinue}
          onNegativeClick={onBackClick}
          onPositiveClick={onContinueClick}
        />
      </div>
    </div>
  );
};

export default SheetSelectionPage;
