import CountBadge from 'baseUI/CountBadge';
import SheetList from './SheetList';
import SimpleBar from 'simplebar-react';
import useViewModel from './viewModel';
import { Sheet } from '@nuvo-importer/common/sdk';
import { css, useTheme, cx } from '@nuvo-importer/common';
import {
  OnAddFirstSheet,
  OnAppendClick,
  OnJoinClick,
  OnRemoveClick,
  OnAddToAllRows,
} from './SheetItem/viewModel';
import { SheetPreview } from '../../../../uploadData/SheetSelectionPage/common/SheetPreview';
import { useTranslation } from 'react-i18next';
import WrapperStyled from '../../../../uploadData/SheetSelectionPage/common/SheetPreview/WrapperStyled';

type SheetSelectionProps = {
  sourceSheets: Sheet[];
  unSelectedSheets: Sheet[];
  onJoinClick: OnJoinClick;
  onAppendClick: OnAppendClick;
  onAddFirstSheet: OnAddFirstSheet;
  onRemoveClick: OnRemoveClick;
  onAddToAllRows: OnAddToAllRows;
  loading: boolean;
};

const SheetSelection = ({
  sourceSheets,
  unSelectedSheets,
  onJoinClick,
  onRemoveClick,
  onAddFirstSheet,
  onAppendClick,
  onAddToAllRows,
  loading,
}: SheetSelectionProps) => {
  const { scrollContainerRef, dismissPreview, openPreview, previewSheet } =
    useViewModel();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div
      className={cx(
        'border-gray-120 nuvo-join-sheet-list-scroll mr-3 flex w-72 flex-shrink-0 flex-col rounded-lg border p-3',
        css({
          '&&': theme.getJoinSheetTheme().sheetSelection?.root,
        })
      )}
      ref={scrollContainerRef}
    >
      <SimpleBar forceVisible={false} autoHide={false} className="max-h-full">
        <div className="flex flex-shrink flex-grow flex-col">
          <div className="mb-2 flex items-center">
            <p
              className={cx(
                'text-gray-660 mr-2 text-sm',
                css({
                  '&&': theme.getJoinSheetTheme().sheetSelection?.title,
                })
              )}
            >
              {t('txt_joined_sheets')}
            </p>
            <CountBadge
              count={sourceSheets.length}
              className={css({
                '&&': theme.getJoinSheetTheme().sheetSelection?.badge,
              })}
            />
          </div>
          <SheetList
            sheets={sourceSheets}
            emptyText={t('txt_no_sheet_joined')}
            onAppendClick={onAppendClick}
            onJoinClick={onJoinClick}
            onRemoveClick={onRemoveClick}
            onAddToAllRows={onAddToAllRows}
            onPreviewClick={openPreview}
            showRemoveButton
            boundary={scrollContainerRef.current ?? undefined}
            sourceSheets={sourceSheets}
            loading={loading}
            className={css({
              '&&': theme.getJoinSheetTheme().sheetSelection?.joinedSheets
                ?.root,
            })}
            sheetItemTheme={theme.getJoinSheetTheme().sheetSelection?.sheet}
          />
        </div>
        <div className="mt-4 flex min-h-0 flex-shrink flex-grow flex-col">
          <div className="mb-2 flex items-center">
            <p
              className={cx(
                'text-gray-660 mr-2 text-sm',
                css({
                  '&&': theme.getJoinSheetTheme().sheetSelection?.title,
                })
              )}
            >
              {t('txt_unselected_sheets')}
            </p>
            <CountBadge
              count={unSelectedSheets.length}
              className={css({
                '&&': theme.getJoinSheetTheme().sheetSelection?.badge,
              })}
            />
          </div>
          <SheetList
            sheets={unSelectedSheets}
            emptyText={t('txt_no_sheet_select')}
            onAppendClick={onAppendClick}
            onJoinClick={onJoinClick}
            onRemoveClick={onRemoveClick}
            onPreviewClick={openPreview}
            onAddToAllRows={onAddToAllRows}
            boundary={scrollContainerRef.current ?? undefined}
            onAddFirstSheet={onAddFirstSheet}
            sourceSheets={sourceSheets}
            loading={loading}
            className={css({
              '&&': theme.getJoinSheetTheme().sheetSelection?.unselectedSheets
                ?.root,
            })}
            sheetItemTheme={theme.getJoinSheetTheme().sheetSelection?.sheet}
          />
        </div>
      </SimpleBar>
      <WrapperStyled theme={theme?.getJoinSheetTheme().sheetPreview?.table}>
        {previewSheet && (
          <SheetPreview
            sheet={previewSheet}
            isOpen={true}
            onCloseModal={dismissPreview}
            sheetName={previewSheet.getName()}
            filename={previewSheet.getSheetFileName()}
            theme={theme.getJoinSheetTheme().sheetPreview}
          />
        )}
      </WrapperStyled>
    </div>
  );
};

export default SheetSelection;
