import { VariableSizeList } from 'react-window';
import { CSSProperties, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import ValueItem from '../ValueItem';
import useViewModel from './viewModel';
import { css, cx } from '../../../../../../../core/emotion';
import { ValueItemData } from './type';
import {
  FilterValuesSelected,
  FilterValueItems,
} from '../../../../columns/FilterStrategy';
import { ConfigTheme } from '../../../../type';
import { convertHexColorToUrlSVGColor } from '../../../../../../style';

const hideListScrollbar = css`
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }
`;

type ValueListProps = {
  valuesSelected: FilterValuesSelected;
  onValuesSelected: (valuesSelected: FilterValuesSelected) => void;
  valueItems: FilterValueItems;
  currentColumnIndex: number;
  configTheme?: ConfigTheme;
};

const ValueList = ({
  valuesSelected,
  onValuesSelected,
  valueItems,
  currentColumnIndex,
  configTheme,
}: ValueListProps) => {
  const { height, itemCount, itemSize } = useViewModel({
    valueItems,
  });
  const listRef = useRef<VariableSizeList>(null);

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [valueItems]);

  return (
    <div
      className={cx(
        'filter-value-list-scroll rounded-medium border-gray-120 border py-2',
        css({
          '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
            ?.valueList?.root,
        }),
        css`
          .value-list-item {
            ${configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
              ?.valueList?.item}
          }

          .value-list-item .nuvo-checkbox {
            ${configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
              ?.valueList?.checkbox}
            &:checked {
              background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='${convertHexColorToUrlSVGColor(
                (
                  configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                    ?.filter?.valueList?.checkbox as CSSProperties
                )?.['color']
              ) ||
              '%23ffffff'}' d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/></svg>");
            }
          }
        `
      )}
    >
      <SimpleBar
        style={{
          width: '100%',
          height,
        }}
        forceVisible={false}
        autoHide
      >
        {({ scrollableNodeRef, contentNodeRef }) => {
          return (
            <VariableSizeList<ValueItemData>
              width="100%"
              height={height}
              itemCount={itemCount}
              ref={listRef}
              itemSize={(index) => {
                if (index === itemCount - 1) {
                  return itemSize - 8;
                } else {
                  return itemSize;
                }
              }}
              className={hideListScrollbar}
              innerRef={contentNodeRef}
              outerRef={scrollableNodeRef}
              itemData={{
                valuesSelected,
                onValuesSelected,
                valueItems,
                currentColumnIndex,
                itemCount,
              }}
            >
              {ValueItem}
            </VariableSizeList>
          );
        }}
      </SimpleBar>
    </div>
  );
};

export default ValueList;
