import { Toast } from '@getnuvo/ui-kit';
import { licenseKeyHeader } from 'core/constants/service';
import { PreviewConfig } from '../../../context/PreviewConfigProvider';
import { ITargetDataModel } from '../types';

const baseURL = `${process.env.NX_API_URL}/demo-target-data-model-config`;
const apikey = process.env.NX_API_GATEWAY_TOKEN ?? '';

export const ConfigureAPI = {
  getTDMs: async (): Promise<ITargetDataModel[]> => {
    const baseURL = `${process.env.NX_API_URL}/demo-target-data-model-config?platform=react`;

    const spacialListToFirstTDM: string[] = [
      'Produktschulung_TDM_wHooks',
      'ecommerce_demo',
      'financial_demo',
      'Produktschulung',
      'Produktschulung_TDM_wHooks_LILA',
    ];

    return fetch(baseURL, { headers: { apikey } })
      .then((res) => {
        if (res.ok) {
          return res.text();
        }

        return Promise.resolve('[]');
      })
      .then((data: string): ITargetDataModel[] => {
        try {
          const configs: ITargetDataModel[] = JSON.parse(data ?? '[]');
          const sortConfigs: ITargetDataModel[] = configs.sort((a, b) =>
            a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
          );
          const newConfigs: ITargetDataModel[] = [];

          spacialListToFirstTDM.forEach((item) => {
            sortConfigs.forEach((config) => {
              if (item === config.label) {
                newConfigs.push(config);
                return;
              }
            });
          });

          sortConfigs.forEach((item) => {
            if (!spacialListToFirstTDM.includes(item.label)) {
              newConfigs.push(item);
            }
          });

          return newConfigs;
        } catch (err) {
          console.log(err);
          return [];
        }
      });
  },
  saveTDMs: async (dataModels: ITargetDataModel[]) => {
    return fetch(baseURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        apikey,
      },
      body: JSON.stringify({
        platform: 'react',
        config: JSON.stringify(dataModels),
      }),
    })
      .then(() => {
        return ConfigureAPI.getTDMs();
      })
      .then((dataModels: ITargetDataModel[]) => {
        return dataModels;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  },
  clearRememberedMapping: async (licenseKey: string): Promise<void> => {
    return fetch(`${process.env.NX_API_URL}/suggest-mapping`, {
      method: 'DELETE',
      headers: {
        [licenseKeyHeader]: licenseKey,
        platform: 'react',
        apikey,
      },
    }).then((res) => {
      if (res.ok) {
        Toast.success({
          title: 'Remembered mappings were cleared.',
        });
      } else {
        Toast.error({
          title: 'Could not clear remembered mappings.',
        });
      }
    });
  },
  updateConfiguration: async (values: PreviewConfig) => {
    return fetch(`${baseURL}/${values.targetDataModelId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        apikey,
      },
      body: JSON.stringify({
        dataHooks: {
          columnHooks: values.columnHook ?? '',
          onEntryInit: values.entryInitHook ?? '',
          onEntryChange: values.entryChangeHook ?? '',
          onResult: values.resultHook ?? '',
          onCancel: values.cancelHook ?? '',
          preloadData: values.preloadData ?? '',
          dataHandler: values.dataHandler ?? '',
        },
        style: values.style ?? '',
      }),
    }).then((res) => {
      return res.json();
    });
  },
};
