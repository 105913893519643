import useInAppNavigate from 'core/navigate';
import { nuvoSessionListener } from 'main/nuvoSessionInstance';
import { useEffect } from 'react';
import { useDataModels } from '../../dataModel/dataModelConfig';
import {
  NuvoSessionHeaderAndMatchingHandler,
  NuvoSessionReviewEntriesHandler,
  NuvoSessionSheetSelectionHandler,
} from 'core/dynamicImport/NuvoSessionHandler';
import { DATA_EMPTY, DynamicStep } from 'core/dynamicImport/NuvoSession';
import {
  HEADER_SELECTION_PATH,
  MATCH_COLUMN_PATH,
  REVIEW_ENTRIES_PATH,
  UPLOAD_PAGE_PATH,
  SHEET_SELECTION_PATH,
} from 'core/constants/route';
import { useSettings } from 'settings';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { ERROR_CODE_IMPORT_FILE } from '../../errors/errorCode';
import NuvoSessionValidator from 'core/dynamicImport/NuvoSessionValidator';
import useWarnLicense from '../../license/warnLicense';
import { isEmpty } from 'lodash';

type DynamicImportHandlerProps = {
  modal: boolean;
  isAuth: boolean;
  openModal: () => void;
};

const DynamicImportHandler = ({
  modal,
  isAuth,
  openModal,
}: DynamicImportHandlerProps) => {
  const navigate = useInAppNavigate();
  const dataModel = useDataModels();
  const {
    columns,
    embedUploadArea = false,
    dynamicImport,
    identifier,
  } = useSettings();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { automaticHeaderDetection } = useSettings();
  const { warnLicenseKeyIsInvalid } = useWarnLicense();

  useEffect(() => {
    nuvoSessionListener.listener(
      identifier,
      async ({ data, headerIndex, step: rawStep }) => {
        try {
          if (!isAuth) {
            warnLicenseKeyIsInvalid();
            return;
          }
          if (!dynamicImport) {
            throw new Error(`${ERROR_CODE_IMPORT_FILE.NOT_SUPPORT}`);
          }
          if (
            NuvoSessionValidator.checkIsEmpty(data) &&
            rawStep !== DynamicStep.REVIEW
          ) {
            if (modal && !embedUploadArea) {
              openModal();
              navigate({ pathname: UPLOAD_PAGE_PATH });
            }
            return;
          }
          if (NuvoSessionValidator.isInValidObjectArray(data)) {
            throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
          }

          let step = rawStep;

          if (
            ![
              DynamicStep.HEADER,
              DynamicStep.MAPPING,
              DynamicStep.REVIEW,
              DynamicStep.SHEET_SELECTION,
            ].includes(step as DynamicStep)
          ) {
            step = DynamicStep.HEADER;
          }

          if (step === DynamicStep.REVIEW) {
            const nuvoSessionReviewEntriesHandler =
              new NuvoSessionReviewEntriesHandler(dataModel.getDataModels());

            const { parsedValues, dataInfos } =
              nuvoSessionReviewEntriesHandler.handle({
                data: (isEmpty(data) ? DATA_EMPTY : data) ?? DATA_EMPTY,
              });

            if (modal) {
              openModal();
            }

            setTimeout(() => {
              navigate(
                {
                  pathname: REVIEW_ENTRIES_PATH,
                },
                {
                  state: {
                    columns,
                    dataModels: dataModel.getDataModels(),
                    dynamicValues: parsedValues,
                    dynamicDataInfos: dataInfos,
                    dynamicUploadStart: REVIEW_ENTRIES_PATH,
                    hideStepper: true,
                  },
                }
              );
            });
          } else if (step === DynamicStep.SHEET_SELECTION) {
            const nuvoSessionSheetSelectionHandler =
              new NuvoSessionSheetSelectionHandler();

            const spreadSheetList =
              await nuvoSessionSheetSelectionHandler.handle({
                data: data ?? [],
                hasDateType: dataModel.hasDateType(),
              });

            const pathname = SHEET_SELECTION_PATH;

            if (modal) {
              openModal();
            }

            setTimeout(() => {
              navigate(
                {
                  pathname,
                },
                {
                  state: {
                    spreadSheetList: spreadSheetList,
                    dynamicUploadStart: pathname,
                  },
                }
              );
            }, 220);
          } else {
            const nuvoSessionHeaderAndMatchingHandler =
              new NuvoSessionHeaderAndMatchingHandler();

            const spreadSheetNavigate =
              await nuvoSessionHeaderAndMatchingHandler.handle({
                data: data ?? {},
                hasDateType: dataModel.hasDateType(),
                headerIndex,
                automaticHeaderDetection,
              });

            const sheetData = spreadSheetNavigate?.getCurrentSheet().getData();

            if (NuvoSessionValidator.checkIsEmpty(sheetData)) {
              if (modal && !embedUploadArea) {
                openModal();
                navigate({ pathname: UPLOAD_PAGE_PATH });
              }
              return;
            }

            const pathname =
              step === DynamicStep.HEADER && !automaticHeaderDetection
                ? HEADER_SELECTION_PATH
                : MATCH_COLUMN_PATH;

            if (modal) {
              openModal();
            }

            setTimeout(() => {
              navigate(
                {
                  pathname,
                },
                {
                  state: {
                    spreadSheetNavigate: spreadSheetNavigate,
                    dynamicUploadStart: pathname,
                  },
                }
              );
            }, 220);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          const errorMessage = () => {
            switch (err.message) {
              case `${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`:
                return t('txt_error_dynamic_import_invalid_data_format');
              case `${ERROR_CODE_IMPORT_FILE.NOT_MATCHED_TDM}`:
                return t('txt_error_dynamic_import_not_matched_tdm');
              case `${ERROR_CODE_IMPORT_FILE.NOT_SUPPORT}`:
                return t('txt_error_dynamic_import_no_support');
              default:
                return t('txt_error_dynamic_import_unknown');
            }
          };
          showConfirmModal({
            title: t('txt_default_title_error'),
            description: `<div>
                <p className="font-bold">
               ${errorMessage()}
                </p>
              </div>`,
            textNegativeButton: t('txt_close'),
            isShowIcon: true,
            isShowPositiveButton: false,
            onClickNegativeButton: () => {},
          });
        }
      }
    );
  }, [
    modal,
    navigate,
    openModal,
    dataModel,
    columns,
    embedUploadArea,
    t,
    showConfirmModal,
    isAuth,
    warnLicenseKeyIsInvalid,
    dynamicImport,
    identifier,
    automaticHeaderDetection,
  ]);

  return null;
};

export default DynamicImportHandler;
