import { useTranslation } from 'react-i18next';
import AllColumnSetting from '../../columns/AllColumnSetting';
import ContextMenuItem from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import ContextMenuController from '../controller/ContextMenuController';
import useViewModel from './viewModel';
import { ConfigTheme } from '../../type';

type HideMenuItemProps = {
  hide: boolean;
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  currentColumnIndex: number;
  configTheme?: ConfigTheme;
};

const HideMenuItem = ({
  hide,
  allColumnSetting,
  contextMenuController,
  currentColumnIndex,
  configTheme,
}: HideMenuItemProps) => {
  const { onMenuClick } = useViewModel({
    allColumnSetting,
    contextMenuController,
    hide,
    currentColumnIndex,
  });
  const { t } = useTranslation();

  return (
    <ContextMenuItem
      icon={ContextMenuIcon.HIDE}
      text={t('txt_hide_column')}
      disabled={allColumnSetting.hasMaximumHideColumn()}
      onClick={onMenuClick}
      showInfo={allColumnSetting.hasMaximumHideColumn()}
      infoMessage={t('txt_hide_column_maximum_info')}
      configTheme={configTheme}
    />
  );
};

export default HideMenuItem;
