import useViewModel from './viewModel';
import Dropdown from '../Dropdown';
import { SingleDropdownProps } from '../Dropdown/types';

export const SingleDropdown = ({
  onChange,
  onBlur,
  options,
  value,
  configThemeMenuButton,
  configThemeMenuItem,
  hasScrollbar,
  onCreateOptions,
  type,
  renderInVirtual,
  allowCustomOptions,
}: SingleDropdownProps) => {
  const viewModel = useViewModel({
    onChange,
    onBlur,
    options,
    value,
    hasScrollbar,
    type,
    allowCustomOptions,
  });

  return (
    <Dropdown
      {...viewModel}
      onCreateOptions={onCreateOptions}
      configThemeMenuItem={configThemeMenuItem}
      configThemeMenuButton={configThemeMenuButton}
      hasScrollbar={hasScrollbar}
      options={options}
      isMultiSelect={false}
      renderInVirtual={renderInVirtual}
    />
  );
};

export default SingleDropdown;
