import { encode } from 'core/base64Utils';
import { EventLogBody } from './../dto/model';
import {
  API_GATEWAY_TOKEN,
  API_URL,
  licenseKeyHeader,
} from 'core/constants/service';
import Rxios from 'core/services/Rxios';
import Tracking from '../../../tracking/Tracking';

class EventLogAPI extends Rxios {
  constructor() {
    super({
      baseURL: API_URL,
    });
  }

  /* Sending data to the server. */
  send = (body: EventLogBody, license: string) => {
    return this.post<void>(
      '/event-log',
      { data: encode(JSON.stringify(body)) },
      {
        headers: {
          apikey: API_GATEWAY_TOKEN,
          [licenseKeyHeader]: license,
          framework: Tracking.getInstance().getFrameWork(),
        },
      }
    );
  };
}

export default EventLogAPI;
