import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DataModelSheetMatcher from './../../matching/DataModelSheetMatcher';
import DataModelSheetMatching, {
  DataModelSheetMatch,
} from './../../matching/DataModelSheetMatching';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { useMainView, useSettings } from 'settings';
import { useScroll } from 'core/scroll';
import { usePage } from 'main/MainView';
import {
  HEADER_SELECTION_PATH,
  JOIN_COLUMN_PATH,
  MATCH_COLUMN_PATH,
  REVIEW_ENTRIES_PATH,
  SHEET_SELECTION_PATH,
  UPLOAD_PAGE_PATH,
} from 'core/constants/route';
import { useNavigatePolicy } from 'navigation';
import useInAppNavigate from 'core/navigate';
import { useSubmit } from './submit';
import { useConfigure } from 'configure';
import { useMatching } from '../../matching/MatchingProvider';
import {
  SHEET_COLUMN_TYPE,
  Sheet,
  SheetColumn,
} from '@nuvo-importer/common/sdk';

export type FormValues = {
  matching: DataModelSheetMatch[];
};

const showJoinColumnFirst = (a: SheetColumn, b: SheetColumn) => {
  if (
    a.getType() === SHEET_COLUMN_TYPE.JOIN &&
    b.getType() !== SHEET_COLUMN_TYPE.JOIN
  ) {
    return -1;
  } else if (
    a.getType() !== SHEET_COLUMN_TYPE.JOIN &&
    b.getType() === SHEET_COLUMN_TYPE.JOIN
  ) {
    return 1;
  } else {
    return 0;
  }
};

const useViewModel = ({
  dataModelSheetMatching,
  dataModelSheetMatcher,
  setShowFieldRequired,
}: {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcher: DataModelSheetMatcher;
  setShowFieldRequired: (isShowRequired: boolean) => void;
}) => {
  const dataModels = dataModelSheetMatching.getDataModels();
  const navigate = useInAppNavigate();
  const { state: locationState } = useLocation();
  const { matchingRepository } = useMatching();

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
    dynamicUploadStart?: string;
    oldSelectedSingleSheetWithoutModified: Sheet;
  };

  const { showConfirmModal, isOpen } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { automaticHeaderDetection, embedUploadArea = false } = useSettings();
  const { cancel } = usePage();
  const { scrollToTop } = useScroll();
  const { modal } = useMainView();
  const { getHasSheetSelectionPage, getHasJoinColumnPage } =
    useNavigatePolicy();
  const { getColumnsAndDataModels } = useSubmit();
  const { licenseKey } = useConfigure();

  const initialValues = useMemo(() => {
    const initialValues: FormValues = {
      matching: [],
    };

    dataModelSheetMatching.getSheets().forEach((sheet) => {
      sheet
        .getColumns()
        .sort(showJoinColumnFirst)
        .forEach((sheetColumn) => {
          initialValues.matching.push({
            sheetColumn,
            matchedDataModel:
              dataModelSheetMatching.getMatchingBySheetColumn(sheetColumn)
                ?.matchedDataModel,
          });
        });
    });

    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPreviousPage = () => {
    const spreadSheetNavigate = state.spreadSheetNavigate;
    if (spreadSheetNavigate) {
      spreadSheetNavigate.goToLastSpreadSheet();
      spreadSheetNavigate.goToLastSheet();
      const hasJoinColumnPage = getHasJoinColumnPage(
        spreadSheetNavigate.getSpreadSheetList()
      );

      if (hasJoinColumnPage) {
        navigate(
          {
            pathname: JOIN_COLUMN_PATH,
          },
          {
            state: {
              spreadSheetNavigate,
              hasBackStep: true,
              dynamicUploadStart: state?.dynamicUploadStart,
            },
          }
        );

        return;
      }

      if (automaticHeaderDetection) {
        if (
          !getHasSheetSelectionPage(spreadSheetNavigate.getSpreadSheetList())
        ) {
          if (embedUploadArea || !modal) {
            cancel();
          } else {
            cancel(false);
            navigate({ pathname: UPLOAD_PAGE_PATH });
          }
        } else {
          navigate(
            {
              pathname: SHEET_SELECTION_PATH,
            },
            {
              state: {
                spreadSheetList: spreadSheetNavigate.getSpreadSheetList(),
                hasBackStep: true,
                dynamicUploadStart: state?.dynamicUploadStart,
                oldSelectedSingleSheetWithoutModified:
                  state?.oldSelectedSingleSheetWithoutModified,
              },
            }
          );
        }
      } else {
        navigate(
          {
            pathname: HEADER_SELECTION_PATH,
          },
          {
            state: {
              spreadSheetNavigate,
              hasBackStep: true,
              dynamicUploadStart: state?.dynamicUploadStart,
            },
          }
        );
      }
    }
  };

  const cleanup = () => {
    matchingRepository?.clearData(licenseKey);
  };

  const onBackClick = () => {
    const spreadSheetNavigate = state.spreadSheetNavigate;
    if (state?.dynamicUploadStart === MATCH_COLUMN_PATH) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_go_back_dynamic_import_title'),
        textNegativeButton: t('txt_confirm_leave_confirm_btn'),
        textPositiveButton: t('txt_confirm_leave_cancel_btn'),
        onClickNegativeButton: () => {
          cleanup();
          cancel();
        },
      });
    } else if (spreadSheetNavigate) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_back_page_dialog'),
        textNegativeButton: t('txt_go_back'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          cleanup();
          scrollToTop();
          goToPreviousPage();
          setShowFieldRequired(false);
        },
      });
    } else {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_back_page_dialog'),
        textNegativeButton: t('txt_go_back'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          cleanup();
          cancel();
        },
      });
    }
  };

  const onSubmit = (values: FormValues) => {
    dataModelSheetMatching.setDataModelSheetMatch(values.matching);
    const spreadSheetNavigate = state.spreadSheetNavigate;

    const {
      reviewEntriesColumns,
      reviewEntriesDataModels,
      notMatchedDataModel,
    } = getColumnsAndDataModels(dataModelSheetMatching, values.matching);

    if (notMatchedDataModel.length > 0) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_match_column_required'),
        description: t('txt_dialog_un_matched', {
          unMatched: `<b>${notMatchedDataModel
            .map((dataModel) => dataModel.getLabel())
            .join(', ')}</b>`,
        }),
        textNegativeButton: t('txt_submit_understand'),
        isShowPositiveButton: false,
        onClickNegativeButton: () => {
          setShowFieldRequired(true);
        },
      });
    } else {
      setShowFieldRequired(false);
      navigate(
        {
          pathname: REVIEW_ENTRIES_PATH,
        },
        {
          state: {
            dataModelSheetMatching: dataModelSheetMatching,
            dataModelSheetMatcher: dataModelSheetMatcher,
            spreadSheetNavigate: spreadSheetNavigate,
            columns: reviewEntriesColumns,
            dataModels: reviewEntriesDataModels,
            dynamicUploadStart: state?.dynamicUploadStart,
            oldSelectedSingleSheetWithoutModified:
              state.oldSelectedSingleSheetWithoutModified,
          },
        }
      );
    }
  };

  return {
    dataModels,
    initialValues,
    onBackClick,
    onSubmit,
    isModalClose: isOpen,
  };
};

export default useViewModel;
