import { Subject } from 'rxjs';
import IconButton, {
  IconButtonType,
} from '../../../../../components/IconButton';
import useViewModel from './viewModel';
import { HotTableClass } from '@handsontable/react';
import { RefObject } from 'react';
import { UndoRedoEvent } from '../../type';
import { useTheme } from '../../../../../theme';
import { css, cx } from '../../../../emotion';

type IUndoRedoButtonProps = {
  undoRedoObservable: Subject<UndoRedoEvent>;
  hotInstance: RefObject<HotTableClass>;
};

const UndoRedoButton = ({
  undoRedoObservable,
  hotInstance,
}: IUndoRedoButtonProps) => {
  const { onUndoClick, onRedoClick, isUndoAvailable, isRedoAvailable } =
    useViewModel({ undoRedoObservable, hotInstance });
  const configTheme = useTheme();

  return (
    <>
      <IconButton
        icon={IconButtonType.UNDO}
        disabled={!isUndoAvailable}
        onClick={onUndoClick}
        className={cx(
          'mr-1',
          css({
            '&&': configTheme.getReviewEntriesTheme().undoButton,
          })
        )}
      />
      <IconButton
        icon={IconButtonType.REDO}
        disabled={!isRedoAvailable}
        onClick={onRedoClick}
        className={cx(
          'mr-2',
          css({
            '&&': configTheme.getReviewEntriesTheme().redoButton,
          })
        )}
      />
    </>
  );
};

export default UndoRedoButton;
