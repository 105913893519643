import { css, cx } from '../../core/emotion';
import React, { useMemo } from 'react';
import { useTheme } from '../../theme';

interface ErrorMessageProps extends React.ComponentProps<'div'> {
  fullWidth?: boolean;
  variant?: 'primary' | 'danger' | 'info' | 'tooltip';
}

const Message = ({
  className,
  fullWidth = false,
  variant = 'primary',
  ...props
}: ErrorMessageProps) => {
  const configTheme = useTheme();
  const infoClassName = css({ '&&': configTheme.getPopoverInfo() });
  const variantClassName = useMemo(() => {
    switch (variant) {
      case 'danger':
        return 'text-danger bg-red-60 border-danger border';
      case 'primary':
        return cx(
          'text-white border',
          css({
            backgroundColor: configTheme.getGlobalTheme().getDark500Color(),
            borderColor: configTheme.getGlobalTheme().getDark500Color(),
          })
        );
      case 'info':
        return cx(
          'text-white border',
          infoClassName,
          css({
            backgroundColor: configTheme.getGlobalTheme().getDark500Color(),
            borderColor: configTheme.getGlobalTheme().getDark500Color(),
          })
        );
      case 'tooltip':
        return 'bg-blue-dark-800 !text-gray-50';
      default:
        return '';
    }
  }, [infoClassName, variant, configTheme]);
  return (
    <p
      className={cx(
        `${
          fullWidth ? 'w-full' : 'w-fit'
        } text-sbase animate-fade break-words rounded py-2 px-3`,
        variantClassName,
        className,
        'text-left'
      )}
      {...props}
    />
  );
};

export default Message;
