import { UseSelectStateChange, useSelect } from 'downshift';
import { RefObject, useLayoutEffect, useMemo, useState } from 'react';
import { usePopper } from './popper';
import { HotTableClass } from '@handsontable/react';

type ViewModelProps<T> = {
  value: T;
  onChange: (value: T) => void;
  options: Option<T>[];
  hotInstance?: RefObject<HotTableClass>;
  modal?: boolean;
};

export type Option<T> = {
  label: string;
  value: T;
  separator?: boolean;
};

const useViewModel = <T>({
  value,
  onChange,
  options,
  hotInstance,
  modal,
}: ViewModelProps<T>) => {
  const popper = usePopper({ flipEnabled: false });
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const handleChange = (changes: UseSelectStateChange<Option<T>>) => {
    if (changes.selectedItem?.value) {
      onChange(changes.selectedItem?.value);
    }
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<Option<T>>({
    items: options,
    selectedItem:
      options.find((option) => option.value === value) ?? options[0],
    onSelectedItemChange: handleChange,
  });

  const shownValue = useMemo(() => {
    return selectedItem?.label;
  }, [selectedItem]);

  useLayoutEffect(() => {
    const calculateMaxHeight = () => {
      const rootElement = hotInstance?.current?.hotInstance?.rootElement;
      const referenceElement = popper.referenceElement.current;
      if (!rootElement || !referenceElement) {
        setMaxHeight(undefined);
        return;
      } else {
        const referenceElementBounding =
          referenceElement.getBoundingClientRect();
        const rootElementBounding = rootElement.getBoundingClientRect();
        const height =
          rootElementBounding.top +
          rootElementBounding.height -
          (referenceElementBounding.top + referenceElementBounding.height) +
          96;

        setMaxHeight(height);
      }
    };

    if (modal) {
      calculateMaxHeight();

      window.addEventListener('resize', calculateMaxHeight);

      return () => {
        window.removeEventListener('resize', calculateMaxHeight);
      };
    } else {
      return () => {};
    }
  }, [hotInstance, popper.referenceElement, modal]);

  return {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    popper,
    shownValue,
    maxHeight,
  };
};

export default useViewModel;
