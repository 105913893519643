import { isArray, isBoolean, isNil, isNumber, isObject } from 'lodash';
import ValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';
import { NumberParser } from '../../utils/NumberParser';

class PercentValueParser implements ValueParser {
  parse = (value: PossibleValue, options?: IValueParserOptions) => {
    if (isNil(value)) {
      return '';
    }

    if (isObject(value) || isArray(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    if (isNumber(value)) {
      return value;
    }

    const converted = NumberParser.convertStringToNumber(value, {
      format: options?.numberFormat,
    });

    if (converted === null) {
      return `${value}`;
    }

    return Number(converted);
  };
}

export default PercentValueParser;
