import { css, CSSInterpolation, CSSObject, cx } from '../../core/emotion';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../assets/icon/check-icon.svg';
import useViewModel from './viewModel';
import { ReactComponent as ArrowRightIcon } from '../../assets/icon/arrow-right.svg';
import { useMemo } from 'react';
import { TextSkeleton } from '../Skeleton';

interface StepperViewProps {
  label: string;
  status: 'current' | 'next' | 'previous';
  rightIcon: boolean;
  configTheme?: {
    incomplete?: CSSInterpolation;
    current?: CSSInterpolation;
    complete?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
  labelClassName?: string;
  isLoading?: boolean;
}

export const StepperView = ({
  label,
  status,
  rightIcon,
  configTheme,
  labelClassName,
  isLoading,
}: StepperViewProps) => {
  const { t } = useTranslation();
  const { previous, current } = useViewModel({
    status,
  });

  const memoStyled = useMemo(() => {
    return {
      icon: cx(
        'mr-2 h-5 w-5',
        css({
          '&& .background': {
            fill: (configTheme?.icon as CSSObject)?.color ?? '#10B981',
          },
        })
      ),
      label: cx(
        `text-xs font-normal md:text-sm md:block whitespace-nowrap ${
          previous || current ? 'text-color-primary' : 'text-gray-410'
        }`,
        {
          'group-hover-stepper-text': previous,
        },
        {
          'font-semibold': current,
        },
        css({ '&&': configTheme?.incomplete }),
        previous && css({ '&&': configTheme?.complete }),
        current && css({ '&&': configTheme?.current }),
        labelClassName
      ),
    };
  }, [configTheme, current, previous, labelClassName]);

  return (
    <div className="group flex items-center" aria-current="step">
      <div className="group-hover-stepper-text flex cursor-default items-center">
        {status === 'previous' && (
          <CheckIcon className={memoStyled.icon} aria-hidden="true" />
        )}
        {isLoading ? (
          <TextSkeleton className="h-5 w-24" />
        ) : (
          <span className={memoStyled.label}>{t(label)}</span>
        )}
      </div>
      {rightIcon && (
        <ArrowRightIcon
          className="text-gray-410 mx-1 h-4 w-4 flex-shrink-0 md:mx-4"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default StepperView;
