import { css, CSSObject, cx } from '@emotion/css';
import { ReactNode, useMemo } from 'react';
import { COLOR_SCROLL_BAR, COLOR_TABLE } from '@nuvo-importer/common/core';
import { SheetPreviewTableThemeAPI } from '@nuvo-importer/common/sdk';

type WrapperStyledProps = {
  children: ReactNode;
  theme?: SheetPreviewTableThemeAPI;
};

const WrapperStyled = ({ children, theme }: WrapperStyledProps) => {
  const header = useMemo(() => {
    return css`
      .sheet-preview .handsontable .header {
        ${theme?.th}
      }

      .sheet-preview .handsontable .htCore tr:first-child td {
        border-top-color: ${(theme?.td as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
        padding: 0 16px !important;
         {
        }
      }

      .sheet-preview .handsontable .htCore tr:first-child th {
        border-top-color: ${(theme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }

      .sheet-preview .handsontable .ht_clone_inline_start .htCore th {
        ${theme?.th}
      }

      .sheet-preview .handsontable .htCore th {
        box-sizing: border-box;
        height: 33px !important;
        padding: 8px 0 !important;
        ${theme?.th}
      }
    `;
  }, [theme]);

  const body = useMemo(() => {
    return css`
      .sheet-preview .ht_master .wtHider .htCore tbody .default-cell {
        ${theme?.td}
        padding: 0 16px !important;
      }

      .sheet-preview .ht_clone_left .wtHider .htCore tbody .default-cell {
        ${theme?.td}
        padding: 0 16px !important;
      }
    `;
  }, [theme]);

  const scrollbar = useMemo(() => {
    return css`
      .sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-thumb {
        background-color: ${theme?.scrollbarNavigatorColor};
      }

      .sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-track {
        background-color: ${theme?.scrollbarBackgroundColor};
      }

      scrollbar-color: ${theme?.scrollbarNavigatorColor
          ? theme?.scrollbarNavigatorColor
          : COLOR_SCROLL_BAR.NAVIGATOR}
        ${theme?.scrollbarBackgroundColor
          ? theme?.scrollbarBackgroundColor
          : COLOR_SCROLL_BAR.BACKGROUND};
    `;
  }, [theme]);

  return (
    <div
      className={cx(
        'h-full',
        css`
          .sheet-preview {
            ${theme?.root}
          }
        `,
        header,
        body,
        scrollbar
      )}
    >
      {children}
    </div>
  );
};

export default WrapperStyled;
