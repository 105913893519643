import { ISelectOption } from '@getnuvo/ui-kit';

export const completeImportActionOptions: ISelectOption[] = [
  {
    id: 'discard',
    text: 'Discard',
  },
  {
    id: 'submit',
    text: 'Submit',
  },
  {
    id: 'block',
    text: 'Block',
  },
];

export const inputTypeOptions: ISelectOption[] = [
  {
    id: 'pdf',
    text: 'PDF',
  },
  {
    id: 'json',
    text: 'JSON',
  },
  {
    id: 'xml',
    text: 'XML',
  },
  {
    id: 'csv',
    text: 'CSV',
  },
  {
    id: 'tsv',
    text: 'TSV',
  },
  {
    id: 'xlsx',
    text: 'XLSX',
  },
  {
    id: 'xls',
    text: 'XLS',
  },
];

export const languageOptions: ISelectOption[] = [
  {
    id: 'en',
    text: '🇬🇧 English',
    description: {
      text: 'en',
    },
  },
  {
    id: 'de',
    text: '🇩🇪 German',
    description: {
      text: 'de',
    },
  },
  {
    id: 'es',
    text: '🇪🇸 Spanish',
    description: {
      text: 'es',
    },
  },
  {
    id: 'fr',
    text: '🇫🇷 French',
    description: {
      text: 'fr',
    },
  },
  {
    id: 'it',
    text: '🇮🇹 Italian',
    description: {
      text: 'it',
    },
  },
  {
    id: 'nl',
    text: '🇳🇱 Netherlands',
    description: {
      text: 'nl',
    },
  },
  {
    id: 'pl',
    text: '🇵🇱 Polish',
    description: {
      text: 'pl',
    },
  },
  {
    id: 'pt',
    text: '🇵🇹 Portuguese',
    description: {
      text: 'pt',
    },
  },
  {
    id: 'sv',
    text: '🇸🇪 Swedish',
    description: {
      text: 'sv',
    },
  },
  {
    id: 'zh',
    text: '🇨🇳 Chinese',
    description: {
      text: 'zh',
    },
  },
  {
    id: 'cz',
    text: '🇨🇿 Czech',
    description: {
      text: 'cz',
    },
  },
  {
    id: 'dk',
    text: '🇩🇰 Danish',
    description: {
      text: 'dk',
    },
  },
  {
    id: 'hu',
    text: '🇭🇺 Hungarian',
    description: {
      text: 'hu',
    },
  },
  {
    id: 'lt',
    text: '🇱🇹 Lithuanian',
    description: {
      text: 'lt',
    },
  },
  {
    id: 'no',
    text: '🇳🇴 Norwegian',
    description: {
      text: 'no',
    },
  },
  {
    id: 'ru',
    text: '🇷🇺 Russian',
    description: {
      text: 'ru',
    },
  },
  {
    id: 'sk',
    text: '🇸🇰 Slovak',
    description: {
      text: 'sk',
    },
  },
];

export const buttonModeOptions: ISelectOption[] = [
  { id: 'import', text: 'Import' },
  { id: 'edit', text: 'Edit' },
  { id: 'both', text: 'Both' },
];

export const processingEngineOptions: ISelectOption[] = [
  {
    id: 'default',
    text: 'Default',
    description: {
      text: 'Frontend',
    },
  },
  {
    id: 'node',
    text: 'Node',
    description: {
      text: 'Backend',
    },
  },
];
