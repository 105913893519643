import { TFunction } from 'i18next';
import type { Error, LeanError } from './index';
import { ColumnAPI, ValidatorAPI } from '../../dataModel/columnsAPI';
import { VALIDATION_KEY } from '../constant';
import { isArray } from 'lodash';
import { getColumnByKey } from '../utils/validator';
import moment from 'moment';
import { DataModel } from '../../dataModel/model/DataModel';

class ValidateMessageUtil {
  private constructor() {
    throw new Error('This class is static please use static method instead 🙏');
  }

  private static dateExample = moment(
    '03-05-2023 19:03:47',
    'MM-DD-YYYY HH:mm:ss'
  );

  /**
   * A function that returns a validation message cell.
   * @param {TFunction} translate - TFunction - this is a function for i18n
   * @param {Error} error - Error - this is a error oject for all cell
   * @param {ColumnAPI[]} columns - ColumnAPI[] - this is a column object
   * @returns A message of validation cell
   */
  static getValidateMessage = (
    translate: TFunction,
    error: LeanError,
    columns: ColumnAPI[],
    baseColumns: ColumnAPI[]
  ) => {
    const { colIndex, validate: validateKey, validateMsg } = error;
    const validationItem = columns[colIndex].validations?.find(
      (validation) =>
        validation.validate === validateKey &&
        validation.errorMessage === validateMsg
    );
    const column = columns[colIndex];

    if (validationItem?.errorMessage) {
      return validationItem?.errorMessage;
    }

    switch (validateKey) {
      case VALIDATION_KEY.REQUIRED:
        if (
          column.columnType === 'category' ||
          column.columnType === 'boolean' ||
          column.columnType === 'currency_code' ||
          column.columnType === 'country_code_alpha_2' ||
          column.columnType === 'country_code_alpha_3'
        ) {
          return translate('txt_field_category_required');
        } else {
          return translate('txt_field_required');
        }
      case VALIDATION_KEY.NOT_IN_OPTIONS:
        return translate('txt_field_not_in_options');
      case VALIDATION_KEY.INVALID_FORMAT_NUMBER_INT:
        return translate('txt_field_invalid_format_number_int');
      case VALIDATION_KEY.REQUIRED_NUMBER:
        return translate('txt_field_invalid_format_number');
      case VALIDATION_KEY.INVALID_FORMAT_NUMBER_FLOAT:
        return translate('txt_field_invalid_format_number_float');
      case VALIDATION_KEY.REGEX:
        return translate('txt_field_invalid_regex_format');
      case VALIDATION_KEY.INVALID_FORMAT_DATE: {
        const outputFormat = DataModel.getOutputFormat(column.outputFormat);
        return translate('txt_field_invalid_date', {
          dateFormat: outputFormat,
          dateFormatExample: this.dateExample.format(outputFormat),
        });
      }
      case VALIDATION_KEY.INVALID_FORMAT_DATE_DMY:
        return translate('txt_field_invalid_date_dmy');
      case VALIDATION_KEY.INVALID_FORMAT_DATE_MDY:
        return translate('txt_field_invalid_date_mdy');
      case VALIDATION_KEY.INVALID_FORMAT_DATE_ISO:
        return translate('txt_invalid_format_date_iso');
      case VALIDATION_KEY.INVALID_FORMAT_DATETIME:
        return translate('txt_invalid_format_datetime');
      case VALIDATION_KEY.INVALID_FORMAT_TIME_HMS:
        return translate('txt_invalid_format_time_hms');
      case VALIDATION_KEY.INVALID_FORMAT_TIME_HMS_24:
        return translate('txt_invalid_format_time_hms_24');
      case VALIDATION_KEY.INVALID_FORMAT_TIME_HM:
        return translate('txt_invalid_format_time_hm');
      case VALIDATION_KEY.INVALID_FORMAT_TIME_HM_24:
        return translate('txt_invalid_format_time_hm_24');
      case VALIDATION_KEY.INVALID_FORMAT_EMAIL:
        return translate('txt_invalid_format_email');
      case VALIDATION_KEY.INVALID_FORMAT_URL_WWW:
        return translate('txt_invalid_format_url_www');
      case VALIDATION_KEY.INVALID_FORMAT_URL_HTTPS:
        return translate('txt_invalid_format_url_https');
      case VALIDATION_KEY.INVALID_FORMAT_URL:
        return translate('txt_invalid_format_url');
      case VALIDATION_KEY.INVALID_FORMAT_PHONE:
        return translate('txt_invalid_format_phone');
      case VALIDATION_KEY.INVALID_FORMAT_ZIP_CODE_DE:
        return translate('txt_invalid_format_zip_code_de');
      case VALIDATION_KEY.INVALID_FORMAT_PERCENTAGE:
        return translate('txt_invalid_format_percentage');
      case VALIDATION_KEY.INVALID_FORMAT_CURRENCY_EUR:
        return translate('txt_invalid_format_currency_eur');
      case VALIDATION_KEY.INVALID_FORMAT_CURRENCY_USD:
        return translate('txt_invalid_format_currency_usd');
      case VALIDATION_KEY.INVALID_FORMAT_BIC:
        return translate('txt_invalid_format_bic');
      case VALIDATION_KEY.INVALID_FORMAT_VAT_EU:
        return translate('txt_invalid_format_vat_eu');
      case VALIDATION_KEY.INVALID_FORMAT_GTIN:
        return translate('txt_invalid_format_gtin');
      case VALIDATION_KEY.INVALID_FORMAT_IBAN:
        return translate('txt_invalid_format_iban');

      case VALIDATION_KEY.REQUIRED_WITH: {
        const validation = columns[colIndex].validations?.find(
          (validation) => validation.validate === VALIDATION_KEY.REQUIRED_WITH
        );
        return translate('txt_field_required_with', {
          columns: this.getValidateWithMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITHOUT: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITHOUT
        );

        return translate('txt_field_required_without', {
          columns: this.getValidateWithMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITHOUT_VALUES: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITHOUT_VALUES
        );

        return translate('txt_field_required_without_values', {
          columns: this.getValidateValuesMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITH_ALL_VALUES: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITH_ALL_VALUES
        );
        return translate('txt_field_required_with_all_values', {
          columns: this.getValidateValuesMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITHOUT_ALL_VALUES: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITHOUT_ALL_VALUES
        );

        return translate('txt_field_required_without_all_values', {
          columns: this.getValidateValuesMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITH_ALL: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITH_ALL
        );

        return translate('txt_field_required_with_all', {
          columns: this.getValidateWithMessage(baseColumns, validation),
        });
      }

      case VALIDATION_KEY.REQUIRED_WITHOUT_ALL: {
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITHOUT_ALL
        );

        return translate('txt_field_required_without_all', {
          columns: this.getValidateWithMessage(baseColumns, validation),
        });
      }
      case VALIDATION_KEY.REQUIRED_WITH_VALUES:
        // eslint-disable-next-line no-case-declarations
        const validation = columns[colIndex].validations?.find(
          (validation) =>
            validation.validate === VALIDATION_KEY.REQUIRED_WITH_VALUES
        );

        return translate('txt_field_required_with_values', {
          columns: this.getValidateValuesMessage(baseColumns, validation),
        });

      case VALIDATION_KEY.UNIQUE:
        return translate('txt_field_unique');

      case VALIDATION_KEY.INVALID_OPTIONS_CONDITION:
        return validateMsg ?? translate('txt_field_not_in_options') ?? '';
      default:
        return '';
    }
  };

  /**
   * A function that returns a validation message of value cell.
   * @param {ColumnAPI[]} columns - ColumnAPI[] - this is a column object
   * @param {ValidatorAPI} validation - ValidatorAPI - this is validation of cell
   * @returns A message of validation value of cell
   */
  private static getValidateValuesMessage = (
    columns: ColumnAPI[],
    validation?: ValidatorAPI
  ) => {
    const fields = Object.keys(validation?.columnValues ?? {}).map((key) => {
      const fieldValue = validation?.columnValues?.[key];
      const values = isArray(fieldValue) ? fieldValue : [fieldValue];
      return `"${getColumnByKey(key, columns)?.label}" = ${values
        .map((value) => `"${value}"`)
        .join(' or ')}`;
    });

    return fields.join(', ');
  };

  /**
   * A function that returns a validation message cell.
   * @param {ColumnAPI[]} columns - ColumnAPI[] - this is a column object
   * @param {ValidatorAPI} validation - ValidatorAPI - this is validation of cell
   * @returns A message of validation of cell
   */
  private static getValidateWithMessage = (
    columns: ColumnAPI[],
    validation?: ValidatorAPI
  ) => {
    return validation?.columns
      ?.map((key) => columns.find((col) => col.key === key)?.label)
      .join(', ');
  };
}
export default ValidateMessageUtil;
