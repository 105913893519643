import { cx, css } from 'core/emotion';
import { ReactComponent as SearchIcon } from '../../assets/icon/search.svg';
import { useState, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'theme';

const SearchInput = forwardRef<
  HTMLInputElement,
  {
    onChange: (value: string) => void;
    theme?: {
      root?: string;
      icon?: string;
      placeholder?: string;
    };
    headerTheme?: string;
    value?: string;
  }
>(({ onChange, theme, headerTheme, value }, ref) => {
  const { t } = useTranslation();
  const globalTheme = useTheme();

  const searchBgColor = useMemo(() => {
    return globalTheme.getGlobalTheme().getDark50Color();
  }, [globalTheme]);

  const borderFocusColor = useMemo(() => {
    return globalTheme.getGlobalTheme().getDark600Color();
  }, [globalTheme]);

  const borderNormalColor = useMemo(() => {
    return globalTheme.getGlobalTheme().getDark400Color();
  }, [globalTheme]);

  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      tabIndex={1}
      className={cx(
        'rounded-t-medium p-2',
        css({
          backgroundColor: searchBgColor,
        }),
        headerTheme
      )}
    >
      <div
        className={cx(
          'flew-row rounded-medium flex h-9 items-center border bg-white px-3',
          isFocused
            ? css({
                borderColor: borderFocusColor,
              })
            : css({
                borderColor: borderNormalColor,
              }),
          theme?.root
        )}
      >
        <SearchIcon className={theme?.icon} />
        <input
          ref={ref}
          value={value}
          className={cx(
            'text-color-primary outline-none h-full flex-1 border-none bg-transparent pl-3 text-sm focus:ring-0',
            theme?.root,
            theme?.placeholder
          )}
          placeholder={t('txt_search')}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
    </div>
  );
});

export default SearchInput;
