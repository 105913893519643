import SheetItem from '../SheetItem';
import Empty from '../Empty';
import {
  OnJoinClick,
  OnAppendClick,
  OnRemoveClick,
  OnAddFirstSheet,
  OnPreviewClick,
  OnAddToAllRows,
} from '../SheetItem/viewModel';
import { Sheet, SheetItemThemeAPI } from '@nuvo-importer/common/sdk';
import { css, cx, useTheme } from '@nuvo-importer/common';

type ISheetListProps = {
  sheets: Sheet[];
  emptyText: string;
  onJoinClick: OnJoinClick;
  onAppendClick: OnAppendClick;
  onRemoveClick: OnRemoveClick;
  onAddFirstSheet?: OnAddFirstSheet;
  showRemoveButton?: boolean;
  boundary?: HTMLDivElement;
  sourceSheets?: Sheet[];
  onPreviewClick: OnPreviewClick;
  onAddToAllRows: OnAddToAllRows;
  loading: boolean;
  className?: string;
  sheetItemTheme?: SheetItemThemeAPI;
};

const SheetList = ({
  sheets,
  emptyText,
  onJoinClick,
  onAppendClick,
  onRemoveClick,
  showRemoveButton = false,
  boundary,
  onAddFirstSheet,
  sourceSheets,
  onPreviewClick,
  onAddToAllRows,
  loading,
  className,
  sheetItemTheme,
}: ISheetListProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        minHeight: 64,
      }}
      className={cx(
        'rounded-medium min-h-0 flex-grow bg-gray-50 p-2',
        className
      )}
    >
      {sheets.length > 0 ? (
        sheets.map((sheet, index) => (
          <SheetItem
            key={sheet.getId()}
            className={index < sheets.length - 1 ? 'mb-1' : ''}
            onJoinClick={onJoinClick}
            onAppendClick={onAppendClick}
            onRemoveClick={onRemoveClick}
            sheet={sheet}
            showRemoveButton={showRemoveButton}
            boundary={boundary}
            onAddFirstSheet={onAddFirstSheet}
            noSourceSheet={sourceSheets && sourceSheets.length === 0}
            onPreviewClick={onPreviewClick}
            onAddToAllRows={onAddToAllRows}
            loading={loading}
            theme={sheetItemTheme}
          />
        ))
      ) : (
        <Empty
          text={emptyText}
          className={css({
            '&&': theme.getJoinSheetTheme().sheetSelection?.empty,
          })}
        />
      )}
    </div>
  );
};

export default SheetList;
