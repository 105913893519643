import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useScroll } from 'core/scroll';
import { useTranslation } from 'react-i18next';
import useNavigatePolicy from '../../navigation/navigatePolicy';
import { useLocation } from 'react-router-dom';
import { Sheet, SpreadSheetNavigate } from '@nuvo-importer/common/sdk';
import { useMainView, useSettings } from 'settings';
import { usePage } from 'main/MainView';
import useInAppNavigate from 'core/navigate';
import {
  HEADER_SELECTION_PATH,
  MATCH_COLUMN_PATH,
  SHEET_SELECTION_PATH,
  UPLOAD_PAGE_PATH,
} from 'core/constants/route';
import { SourceSheetState } from './joinSheet';

const useViewModel = ({
  unSelectedSheets,
  sourceSheet,
}: {
  unSelectedSheets: Sheet[];
  sourceSheet: SourceSheetState;
}) => {
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { scrollToTop } = useScroll();
  const { getHasSheetSelectionPage } = useNavigatePolicy();
  const { state: locationState } = useLocation();
  const state = locationState as {
    spreadSheetNavigate: SpreadSheetNavigate;
    hasBackStep?: boolean;
    dynamicUploadStart?: boolean;
  };
  const { automaticHeaderDetection, embedUploadArea = false } = useSettings();
  const { modal } = useMainView();
  const { cancel } = usePage();
  const navigate = useInAppNavigate();

  const goToPreviousPage = () => {
    const spreadSheetNavigate = state.spreadSheetNavigate;
    if (!spreadSheetNavigate) return;
    spreadSheetNavigate.goToLastSpreadSheet();
    spreadSheetNavigate.goToLastSheet();
    spreadSheetNavigate.getSpreadSheetList().removeJoinSheet();

    if (automaticHeaderDetection) {
      if (!getHasSheetSelectionPage(spreadSheetNavigate.getSpreadSheetList())) {
        if (embedUploadArea || !modal) {
          cancel();
        } else {
          cancel(false);
          navigate({ pathname: UPLOAD_PAGE_PATH });
        }
      } else {
        navigate(
          {
            pathname: SHEET_SELECTION_PATH,
          },
          {
            state: {
              spreadSheetList: spreadSheetNavigate.getSpreadSheetList(),
              hasBackStep: state?.hasBackStep,
              dynamicUploadStart: state?.dynamicUploadStart,
            },
          }
        );
      }
    } else {
      navigate(
        {
          pathname: HEADER_SELECTION_PATH,
        },
        {
          state: {
            spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    }
  };

  const goToNextPage = () => {
    if (!state.spreadSheetNavigate || !sourceSheet.state) return;
    state.spreadSheetNavigate
      .getSpreadSheetList()
      .setJoinSheet(sourceSheet.state);
    navigate(
      {
        pathname: MATCH_COLUMN_PATH,
      },
      {
        state: {
          spreadSheetNavigate: state.spreadSheetNavigate,
          hasBackStep: state?.hasBackStep,
          dynamicUploadStart: state?.dynamicUploadStart,
        },
      }
    );
  };

  const onContinueClick = () => {
    if (unSelectedSheets.length > 0) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_unjoined_sheets'),
        description: t('txt_unjoined_sheets_desc'),
        textNegativeButton: t('txt_continue'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          goToNextPage();
        },
      });
    } else {
      goToNextPage();
    }
  };

  const onBackClick = () => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_confirm_title'),
      description: t('txt_back_page_dialog'),
      textNegativeButton: t('txt_go_back'),
      textPositiveButton: t('txt_cancel'),
      onClickNegativeButton: () => {
        const spreadSheetNavigate = state.spreadSheetNavigate;
        if (spreadSheetNavigate) {
          scrollToTop();
          goToPreviousPage();
        } else {
          cancel();
        }
      },
    });
  };

  return { onContinueClick, onBackClick };
};

export default useViewModel;
