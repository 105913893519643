import Rxios from 'core/services/Rxios';
import { PROCESSING_API_URL, licenseKeyHeader } from 'core/constants/service';
import { SheetData } from '@nuvo-importer/common/sdk';
import Tracking from '../../../tracking/Tracking';

class UploadInputSheetAPI extends Rxios {
  constructor() {
    super({
      baseURL: PROCESSING_API_URL,
    });
  }

  createFile = (licenseKey: string) => {
    return this.post<{ msg: string; id: string }>(
      '/file',
      {},
      {
        headers: {
          [licenseKeyHeader]: licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
          originRequest: Tracking.getInstance().getOrigin(),
        },
      }
    );
  };

  uploadData = (id: string, data: SheetData, licenseKey: string) => {
    return this.post<{ msg: string; total: number }>(`/file/${id}`, data, {
      headers: {
        [licenseKeyHeader]: licenseKey,
        framework: Tracking.getInstance().getFrameWork(),
        originRequest: Tracking.getInstance().getOrigin(),
      },
    });
  };

  complete = (id: string, totalChunk: number, licenseKey: string) => {
    return this.post<{ msg: string }>(
      `/file/${id}/combine`,
      {
        total_chunk: totalChunk,
      },
      {
        headers: {
          [licenseKeyHeader]: licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
          originRequest: Tracking.getInstance().getOrigin(),
        },
      }
    );
  };

  clearFile = (fileId: string, complete: boolean, licenseKey: string) => {
    return this.delete<{ msg: string }>(
      `/file/${fileId}?complete=${complete}`,
      {
        headers: {
          [licenseKeyHeader]: licenseKey,
          framework: Tracking.getInstance().getFrameWork(),
          originRequest: Tracking.getInstance().getOrigin(),
        },
      }
    );
  };
}

export default UploadInputSheetAPI;
