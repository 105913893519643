import { DataModel } from '../../dataModel/model/DataModel';
import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, { PossibleValue } from './IValueParser';
import { NumberParser } from '../../utils/NumberParser';
import { NumberFormat } from '../../dataModel/columnsAPI';

class PercentValueParser implements ValueParser {
  parse = (
    _dataModel: DataModel,
    value: PossibleValue,
    _isBeforeHook: boolean
  ) => {
    if (isNil(value)) {
      return null;
    }

    if (isArray(value) || isObject(value)) {
      return null;
    }

    if (isBoolean(value)) {
      return null;
    }

    if (value === '') {
      return null;
    }

    const universalDecimal = `${value}`.replace(',', '.');
    const result = NumberParser.convertStringToNumber(universalDecimal, {
      format: NumberFormat.US,
    });

    if (result === null) {
      return `${value}`;
    }

    return Number(result) / 100;
  };
}

export default PercentValueParser;
