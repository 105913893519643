import useViewModel from './viewModel';
import { CSSInterpolation } from 'core/emotion';
import { FullScreenButton as BaseFullScreenButton } from '@nuvo-importer/common';

const FullScreenButton = ({
  className,
  theme,
}: {
  className?: string;
  theme?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
}) => {
  const { toggleFullScreen, isFullScreen, modal } = useViewModel();

  return (
    <BaseFullScreenButton
      className={className}
      theme={theme}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      modal={modal}
    />
  );
};

export default FullScreenButton;
