import { useEffect, useMemo, useState } from 'react';
import {
  FilterByConditionState,
  FilterCondition,
  FilterLogicalOperator,
  filterConditionsHasValueField,
} from '../../../columns/FilterStrategy';
import { ColumnSetting } from '../../../columns/AllColumnSetting';
import ColumnSettingHelper from '../../../columns/ColumnSettingHelper';

const mapFilterToState = (
  columnSetting: ColumnSetting
): [FilterByConditionState, FilterByConditionState | null] => {
  const filterByCondition =
    ColumnSettingHelper.getFilterByCondition(columnSetting);
  return [
    {
      condition: filterByCondition?.items[0]?.condition ?? FilterCondition.NONE,
      value: filterByCondition?.items[0]?.value ?? '',
      secondValue: filterByCondition?.items[0]?.secondValue ?? '',
    },
    filterByCondition?.items[1]
      ? {
          condition:
            filterByCondition?.items[1]?.condition ?? FilterCondition.NONE,
          value: filterByCondition?.items[1]?.value ?? '',
          secondValue: filterByCondition?.items[1]?.secondValue ?? '',
        }
      : null,
  ];
};

const useFilterByCondition = ({
  columnSetting,
}: {
  columnSetting: ColumnSetting;
}) => {
  const [filterByConditions, setFilterByConditions] = useState<
    [FilterByConditionState, FilterByConditionState | null]
  >(mapFilterToState(columnSetting));

  const filterByCondition = useMemo(() => {
    return ColumnSettingHelper.getFilterByCondition(columnSetting);
  }, [columnSetting]);

  const [logicalOperator, setLogicalOperator] = useState<FilterLogicalOperator>(
    filterByCondition?.logicalOperator ?? FilterLogicalOperator.AND
  );

  useEffect(() => {
    setFilterByConditions(mapFilterToState(columnSetting));
    setLogicalOperator(
      filterByCondition?.logicalOperator ?? FilterLogicalOperator.AND
    );
  }, [filterByCondition, columnSetting]);

  const onFirstFilterByConditionChange = (value: FilterByConditionState) => {
    setFilterByConditions([value, filterByConditions[1]]);
  };
  const onSecondFilterByConditionChange = (value: FilterByConditionState) => {
    setFilterByConditions([filterByConditions[0], value]);
  };

  useEffect(() => {
    if (
      !filterConditionsHasValueField.includes(
        filterByConditions[0].condition
      ) &&
      filterByConditions[0].value !== ''
    ) {
      setFilterByConditions([
        { ...filterByConditions[0], value: '', secondValue: '' },
        filterByConditions[1],
      ]);
    }

    if (
      filterByConditions[1] &&
      !filterConditionsHasValueField.includes(
        filterByConditions[1].condition
      ) &&
      filterByConditions[1].value !== ''
    ) {
      setFilterByConditions([
        filterByConditions[1],
        { ...filterByConditions[1], value: '', secondValue: '' },
      ]);
    }
  }, [filterByConditions]);

  useEffect(() => {
    if (
      filterByConditions[0].condition !== FilterCondition.NONE &&
      !filterByConditions[1]
    ) {
      setFilterByConditions([
        filterByConditions[0],
        {
          condition: FilterCondition.NONE,
          value: '',
          secondValue: '',
        },
      ]);
    } else if (
      filterByConditions[0].condition === FilterCondition.NONE &&
      filterByConditions[1]
    ) {
      setFilterByConditions([filterByConditions[0], null]);
      setLogicalOperator(FilterLogicalOperator.AND);
    }
  }, [filterByConditions]);

  return {
    logicalOperator,
    onFirstFilterByConditionChange,
    onSecondFilterByConditionChange,
    setLogicalOperator,
    filterByConditions,
    setFilterByConditions,
  };
};

export default useFilterByCondition;
