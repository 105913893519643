import { ReactComponent as NuvoWithTextLogo } from '../../assets/icon/nuvo-with-text-logo.svg';
import { cx } from '../../core/emotion';

export const Watermark = ({
  id,
  viewMode,
  allowRender,
  identifier,
}: {
  id: string;
  viewMode: 'upload' | 'footer';
  allowRender: boolean;
  identifier: string;
}) => {
  if (!allowRender) return <div />;

  return (
    <div
      id={id}
      className={cx(
        'w-fit flex items-center justify-center',
        viewMode === 'upload'
          ? 'absolute bottom-4 !w-full'
          : 'absolute top-1/2 bottom-14 left-6 mx-auto'
      )}
    >
      <p
        id={`nuvo-watermark-text-${identifier}`}
        className="text-gray-410 px-2 text-xs font-semibold"
      >
        Powered by
      </p>
      <NuvoWithTextLogo id={`nuvo-watermark-logo-${identifier}`} />
    </div>
  );
};
