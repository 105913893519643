import { CSSObject } from '../../core/emotion';
import { ThemeAPI } from '../themeAPI';
import ButtonTheme from './ButtonTheme';
import chroma, { Color } from 'chroma-js';

class SecondaryButtonTheme extends ButtonTheme {
  constructor(themeAPI: ThemeAPI) {
    super('secondary', 'secondaryColor', themeAPI);
  }

  protected generateByGlobalColor = () => {
    const secondaryGlobalColor = this.themeAPI.globals?.secondaryColor;
    const secondaryColorChroma = chroma(secondaryGlobalColor ?? '');
    const secondaryHoverColor = chroma
      .hsl(
        chroma(secondaryColorChroma).get('hsl.h') + 0.3325,
        chroma(secondaryColorChroma).get('hsl.s') - 0.0303,
        chroma(secondaryColorChroma).get('hsl.l') - 0.0647
      )
      .hex();

    return {
      backgroundColor: secondaryGlobalColor,
      borderColor: secondaryGlobalColor,
      color: this.getTextColor(secondaryColorChroma),
      ':hover': {
        backgroundColor: secondaryHoverColor,
        borderColor: secondaryHoverColor,
      },
    };
  };

  protected generateByButtonTheme = () => {
    const theme = {
      ...((this.themeAPI.buttons?.['secondary'] as CSSObject) ?? {}),
    };

    if (theme.backgroundColor && !theme[':hover']?.backgroundColor) {
      const basedColor = chroma(theme.backgroundColor as string);
      const hoverColor = chroma
        .hsl(
          basedColor.get('hsl.h') + 0.3325,
          basedColor.get('hsl.s') - 0.0303,
          basedColor.get('hsl.l') - 0.0647
        )
        .hex();

      theme[':hover'] = {
        backgroundColor: hoverColor,
      };
    }

    return theme;
  };

  private getTextColor = (bgColor: Color) => {
    const whiteColor = chroma('white');
    const bgColorChroma = chroma(bgColor);
    const darkColor = chroma.hsl(
      chroma(bgColorChroma).get('hsl.h') + 0.6896,
      chroma(bgColorChroma).get('hsl.s') - 0.0255,
      chroma(bgColorChroma).get('hsl.l') - 0.3627
    );

    const contrastWithDark = chroma.contrast(bgColor, darkColor);
    const contrastWithWhite = chroma.contrast(bgColor, whiteColor);

    if (contrastWithDark > contrastWithWhite) {
      return darkColor.hex();
    } else {
      return whiteColor.hex();
    }
  };
}

export default SecondaryButtonTheme;
