export const elementId = {
  columnInfoElementId: 'column-popper-info-element',
  cellPopperElementId: {
    root: 'cell-popper-element-id',
    single: 'cell-popper-single-element-id',
    multiple: 'cell-popper-multiple-element-id',
  },
  popoverElementId: {
    parentElementId: 'parent-element-id',
    columnInfoElementId: 'columnInfoElementId',
    errorElementId: 'errorElementId',
    warningElementId: 'warningElementId',
    infoElementId: 'infoElementId',
    multipleElementId: 'multipleElementId',
  },
  tableWrapperId: 'table-wrapper',
  tableScrollerId: 'table-scroller',
  dropdownElementId: {
    parent: 'dropdown-parent-element-id',
    scroll: 'dropdown-scroll-element-id',
  },
};

export const HIGHT_SCROLL_BAR = 8;

export const nuvoCustomAddColumnKey = 'nuvo-custom-add-column';
