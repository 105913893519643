export type StackedMessageLevel = 'error' | 'warning' | 'info';

export enum LEVEL {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const DEFAULT_LEVEL = 'info';

export const CELL_COLOR = {
  error: 'FCEAEA',
  warning: 'FFFBF0',
  info: 'F5F8FE',
};
