import { ReactComponent as SortAscIcon } from '../../../../../assets/icon/sort-asc-icon.svg';
import { ReactComponent as SortDescIcon } from '../../../../../assets/icon/sort-desc-icon.svg';
import { ReactComponent as FreezeIcon } from '../../../../../assets/icon/freeze-icon.svg';
import { ReactComponent as HideIcon } from '../../../../../assets/icon/hide-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../../assets/icon/remove-icon.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/icon/filter-icon.svg';
import { ReactComponent as BinIcon } from '../../../../../assets/icon/bin-dash.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';

export enum ContextMenuIcon {
  SORT_ASC = 'SORT_ASC',
  SORT_DESC = 'SORT_DESC',
  FREEZE = 'FREEZE',
  HIDE = 'HIDE',
  REMOVE = 'REMOVE',
  FILTER = 'FILTER',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

const Icon = ({
  icon,
  className,
}: {
  icon: ContextMenuIcon;
  className?: string;
}) => {
  switch (icon) {
    case ContextMenuIcon.SORT_ASC:
      return <SortAscIcon className={className} />;

    case ContextMenuIcon.SORT_DESC:
      return <SortDescIcon className={className} />;

    case ContextMenuIcon.FREEZE:
      return <FreezeIcon className={className} />;

    case ContextMenuIcon.HIDE:
      return <HideIcon className={className} />;

    case ContextMenuIcon.REMOVE:
      return <RemoveIcon className={className} />;

    case ContextMenuIcon.FILTER:
      return <FilterIcon className={className} />;

    case ContextMenuIcon.DELETE:
      return <BinIcon className={className} />;

    case ContextMenuIcon.EDIT:
      return <EditIcon className={className} />;

    default:
      return <div />;
  }
};

export default Icon;
