import { useTranslation } from 'react-i18next';
import { css, cx } from '../../../../../../core/emotion';
import { useMemo } from 'react';
import { ConfigTheme } from '../../../type';

type RadioChoice<T> = {
  label: string;
  value: T;
  disabled?: boolean;
};

type RadioChoiceProps<T> = {
  choices: RadioChoice<T>[];
  optionsClassName?: string;
  optionsWrapperClassName?: string;
  wrapperOption?: string;
  value: T;
  onClick?: () => void;
  onChange: (value: T) => void;
  size?: 'small' | 'base';
  configTheme?: ConfigTheme;
};

const RadioChoiceItem = ({
  label,
  value,
  disabled = false,
  className,
  isChecked,
  onClick,
  size,
  configTheme,
}: {
  label: string;
  value: string;
  className?: string;
  disabled?: boolean;
  isChecked: boolean;
  onClick?: () => void;
  size?: 'small' | 'base';
  configTheme?: ConfigTheme;
}) => {
  const { t } = useTranslation();
  const inputRadioStyle = useMemo(() => {
    return css`
      box-shadow: none !important;
    `;
  }, []);

  return (
    <label
      htmlFor={`${value}`}
      className={cx(
        'border-1 rounded-medium flex flex-grow items-center bg-white px-3 shadow-none',
        disabled
          ? 'text-gray-560 cursor-not-allowed bg-gray-50'
          : 'text-gray-660 cursor-pointer',
        isChecked
          ? 'bg-blue-light-50 border-blue-light-100'
          : 'border-gray-120',
        size === 'small' ? 'h-6' : 'py-2',
        className,
        css({
          '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.filter
            ?.radioButton?.root,
        })
      )}
    >
      <input
        checked={isChecked}
        type="radio"
        id={`${value}`}
        className={cx(
          'text-blue-dark-900 border-gray-210 cursor-pointer focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-50',
          inputRadioStyle,
          css({
            '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
              ?.filter?.radioButton?.radio,
          })
        )}
        disabled={disabled}
        onClick={onClick}
        onChange={() => {}}
      />
      <span className="ml-2 text-xs">{t(label)}</span>
    </label>
  );
};

const RadioChoice = <T,>({
  choices,
  optionsWrapperClassName,
  optionsClassName,
  wrapperOption,
  value,
  onChange,
  size = 'base',
  configTheme,
}: RadioChoiceProps<T>) => {
  return (
    <div className={optionsWrapperClassName}>
      <div className={cx(wrapperOption, 'flex space-x-2')}>
        {choices.map((choice) => (
          <RadioChoiceItem
            label={choice.label}
            value={`${choice.value}`}
            key={`${choice.value}`}
            disabled={choice.disabled}
            className={optionsClassName}
            isChecked={value === choice.value}
            size={size}
            onClick={() => {
              onChange(choice.value);
            }}
            configTheme={configTheme}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioChoice;
