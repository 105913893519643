import Tracking from './../../../../tracking/Tracking';
import FileImportBanner from './../../../../sheetImporter/FileImportBanner';
import useViewModel from './viewModel';

type UploaderProps = {
  className?: string;
  containerClassName?: string;
  onUploadSuccess: () => void;
  onProcessing: () => void;
  onUploadFailure: () => void;
  onClickManualEntry: () => void;
};

const Uploader = ({
  className,
  onUploadSuccess,
  onProcessing,
  onUploadFailure,
  containerClassName,
  onClickManualEntry,
}: UploaderProps) => {
  const { onUploadFileSuccess, identifier } = useViewModel({
    onUploadSuccess,
    onUploadFailure,
  });
  return (
    <FileImportBanner
      className={className}
      containerClassName={containerClassName}
      onFileSelectedSuccess={(spreadSheetList) => {
        Tracking.getInstance().pushEventStack(identifier, {
          step: 'upload',
          action: 'end',
        });
        onUploadFileSuccess(spreadSheetList);
      }}
      onProcessing={onProcessing}
      onFileSelectedError={onUploadFailure}
      onClickManualEntry={onClickManualEntry}
    />
  );
};

export default Uploader;
