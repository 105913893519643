import { css, cx } from 'core/emotion';
import { ReactComponent as ArrowIcon } from '../../../assets/icon/arrow.svg';

const openStyle = css`
  svg {
    transform: rotate(180deg);
  }
`;

const closeStyle = css`
  svg {
    transform: rotate(0deg);
  }
`;

const ArrowDown = ({ open }: { open: boolean }) => {
  return (
    <div
      className={cx(
        'absolute top-1/2 right-3 -translate-y-1/2 transform',
        open ? openStyle : closeStyle
      )}
    >
      <ArrowIcon className="block" aria-hidden="true" />
    </div>
  );
};

export default ArrowDown;
