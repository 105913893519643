import { css, cx, useTheme } from '@nuvo-importer/common';
import { Sheet } from '@nuvo-importer/common/sdk';
import BaseModal from '../../../../baseUI/Modal/BaseModal';
import JoinSheetForm from './JoinSheetForm';
import AppendSheetForm from './AppendSheetForm';
import { HandleAppendCol, HandleJoin } from '../../joinSheet';

export const joinSheetModalId = 'join-sheet-modal-id';

type JoinSheetModalProps = {
  isOpen: boolean;
  close: () => void;
  joinTargetSheet: Sheet | null;
  appendTargetSheet: Sheet | null;
  sourceSheet: Sheet | null;
  handleAppendCol: HandleAppendCol;
  handleJoin: HandleJoin;
};

const JoinSheetModal = ({
  close,
  isOpen,
  joinTargetSheet,
  sourceSheet,
  handleJoin,
  appendTargetSheet,
  handleAppendCol,
}: JoinSheetModalProps) => {
  const theme = useTheme();
  return (
    <BaseModal
      gravity="middle"
      className={cx('sm:max-w-1.7xl w-full !p-0')}
      isOpen={isOpen}
      onCloseModal={() => {
        close();
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      isShowCloseIcon={true}
      id={joinSheetModalId}
      contentClassName={cx(
        '!items-stretch',
        css`
          height: auto;
          min-height: 500px;
          max-height: 680px;
        `
      )}
      overlayStyled={
        joinTargetSheet
          ? theme.getJoinSheetTheme().joinColumnsDialog?.overlay
          : theme.getJoinSheetTheme()?.appendColumnsDialog?.overlay
      }
      bodyStyled={
        joinTargetSheet
          ? theme.getJoinSheetTheme().joinColumnsDialog?.root
          : theme.getJoinSheetTheme()?.appendColumnsDialog?.root
      }
      closeIconStyled={
        joinTargetSheet
          ? theme.getJoinSheetTheme().joinColumnsDialog?.closeIcon
          : theme.getJoinSheetTheme()?.appendColumnsDialog?.closeIcon
      }
    >
      {sourceSheet ? (
        <div className="flex h-full min-h-0 flex-grow flex-col px-6 pb-6 text-left">
          {joinTargetSheet ? (
            <JoinSheetForm
              joinTargetSheet={joinTargetSheet}
              sourceSheet={sourceSheet}
              handleJoin={handleJoin}
            />
          ) : appendTargetSheet ? (
            <AppendSheetForm
              sourceSheet={sourceSheet}
              handleAppendCol={handleAppendCol}
              appendTargetSheet={appendTargetSheet}
            />
          ) : null}
        </div>
      ) : null}
    </BaseModal>
  );
};

export default JoinSheetModal;
