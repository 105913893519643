import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    'bg-white',
    css`
      display: flex;
      padding: 6px 12px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.root),
    }),
  ]);

  const newValue = cx([
    'text-small neutral-gray font-semibold',
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.newValue),
    }),
  ]);

  const oldValue = cx([
    'text-small text-left text-gray-260',
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.oldValue),
    }),
  ]);

  const oldValueEmpty = cx([
    'capitalize',
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.oldValueEmpty),
    }),
  ]);

  const checkboxClass = cx([
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.checkbox),
    }),
  ]);
  const detailsClass = cx([
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.details),
    }),
  ]);
  const actionsClass = cx([
    'text-neutral-600',
    css`
      margin-inline-start: auto;
      display: flex;
      align-items: center;
      gap: 4px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.actions),
    }),
  ]);

  const commonButtonClass = cx([
    css`
      width: 24px;
      height: 24px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      gap: 8px;
      position: relative;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.15s ease-out;
      outline: none;
      user-select: none;
    `,
  ]);

  const findButtonClass = cx([
    commonButtonClass,
    'text-gray-560 bg-transparent hover:bg-gray-50',
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.findButton),
    }),
  ]);

  const dismissButtonClass = cx([
    commonButtonClass,
    'text-blue-dark-900 bg-transparent border-blue-dark-50 hover:bg-blue-dark-50',
    css({
      '&&': css(cleaningAssistantTheme.suggestion?.dismissButton),
    }),
  ]);

  const applyButtonClass = cx([
    commonButtonClass,
    css({
      '&&': cx([
        css(`padding: 0`),
        css(cleaningAssistantTheme.suggestion?.applyButton),
      ]),
    }),
  ]);

  return {
    rootClass,
    newValue,
    oldValue,
    oldValueEmpty,
    checkboxClass,
    detailsClass,
    actionsClass,
    findButtonClass,
    dismissButtonClass,
    applyButtonClass,
  };
};
