import {
  createPopper,
  detectOverflow,
  Instance,
  ModifierArguments,
  Placement,
} from '@popperjs/core';

export enum POPOVER_TYPE_CLASS {
  DEFAULT = 'default-popper',
  INFO = 'info-popper',
  ERROR = 'error-popper',
  WARNING = 'warning-popper',
  MULTIPLE = 'multiple-popper',
}

export class StackedMessagePopper {
  popperInstance!: Instance | undefined;
  rootElement!: Element;
  popperElement!: HTMLElement;
  parentElement!: Element;
  popperClassName!: POPOVER_TYPE_CLASS;
  enableExamples!: boolean;
  isRemoveScrollEvent = false;
  htCloneLeftWtHolderElement!: Element;
  private defaultPaddingLeft = 87;
  private paddingLeftOffset = 20;
  private paddingLeft = 57;

  initPopper(
    rootElement: Element,
    popperElement: HTMLElement,
    parentElement: Element,
    enableExamples: boolean
  ) {
    this.rootElement = rootElement;
    this.popperElement = popperElement;
    this.enableExamples = enableExamples;
    this.parentElement = parentElement;

    this.popperInstance = createPopper(this.rootElement, this.popperElement, {
      placement: 'left',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 12],
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: ['right', 'top', 'bottom'],
            allowedAutoPlacements: ['right', 'top'],
            boundary: parentElement,
            padding: {
              left: this.paddingLeft - this.paddingLeftOffset,
            },
          },
        },
        {
          name: 'overflow-offset',
          enabled: true,
          phase: 'main',
          requiresIfExists: ['offset'],
          fn: this.overflowHide.bind(this),
        },
      ],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overflowHide = ({ state }: ModifierArguments<any>) => {
    const overflow = detectOverflow(state, {
      boundary: this.parentElement,
      elementContext: 'reference',
    });
    const top = this.enableExamples ? -34 : 0;
    const left = (this.paddingLeft - this.paddingLeftOffset) * -1;

    if (
      overflow.top > top ||
      state.modifiersData.hide?.isReferenceHidden ||
      (state.modifiersData.hide?.referenceClippingOffsets.left ?? 0) > left
    ) {
      state.attributes['popper']['overflow-hide'] = true;
      this.removeScrollEvent();
    } else {
      state.attributes['popper']['overflow-hide'] = false;
      this.enableScrollEvent();
    }
  };

  removeScrollEvent = () => {
    if (!this.isRemoveScrollEvent) {
      this.isRemoveScrollEvent = true;
      this.popperInstance?.setOptions((options) => ({
        ...options,
        modifiers: [
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(options.modifiers as any[]),
          { name: 'eventListeners', enabled: false },
        ],
      }));
    }
  };

  enableScrollEvent = () => {
    if (this.isRemoveScrollEvent) {
      this.isRemoveScrollEvent = false;
      this.popperInstance?.setOptions((options) => ({
        ...options,
        modifiers: [
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(options.modifiers as any[]),
          { name: 'eventListeners', enabled: true },
        ],
      }));
    }
  };

  changeRootElement(
    rootElement: Element,
    popperElement: HTMLElement,
    parentElement: Element,
    popperClassName: POPOVER_TYPE_CLASS | '',
    htCloneLeftWtHolderElement?: Element,
    placement?: Placement,
    enableExamples?: boolean
  ) {
    let updatePaddingLeft;
    if (htCloneLeftWtHolderElement) {
      updatePaddingLeft = htCloneLeftWtHolderElement.clientWidth;
    } else {
      updatePaddingLeft = this.defaultPaddingLeft;
    }
    if (this.paddingLeft !== updatePaddingLeft) {
      this.popperInstance?.destroy();
      this.popperInstance = undefined;
    }
    this.paddingLeft = updatePaddingLeft;

    if (popperClassName) {
      this.popperClassName = popperClassName;
    }
    popperElement.className = `${this.popperClassName} ${POPOVER_TYPE_CLASS.DEFAULT}`;

    if (this.popperInstance) {
      this.popperInstance.state.elements.reference = rootElement;
      this.show();
    } else {
      this.initPopper(
        rootElement,
        popperElement,
        parentElement,
        enableExamples ?? false
      );
      this.show();
    }
  }

  show() {
    this.popperElement.style.zIndex = '50';

    setTimeout(() => {
      this.popperInstance?.forceUpdate();
      this.popperElement.style.visibility = 'visible';
      this.popperElement.style.opacity = '1';
    }, 0);
  }

  private hideByCss() {
    if (this.popperElement?.style) {
      this.popperElement.style.opacity = '0';
      this.popperElement.style.visibility = 'none';
    }
  }

  tryUpdate = () => {
    this.popperInstance?.update();
  };

  destroy() {
    this.hideByCss();
  }

  clearInstance() {
    this.hideByCss();
    this.popperInstance?.destroy();
    this.popperInstance = undefined;
  }
}
