import {
  formatTime,
  DATE_FNS_FORMAT_DATE,
  MOMENT_FORMAT_TIME,
} from '../../date';
import { parse, format } from 'date-fns';
import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';
import { DATATYPE } from '../../dataType';
import moment from 'moment';
import { DATE_GENERAL_FORMAT } from '../../constants/date';

class DateValueParser implements ValueParser {
  parse = (value: PossibleValue, options?: IValueParserOptions) => {
    if (isNil(value)) {
      return '';
    }

    if (isArray(value) || isObject(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    const type = options?.dataModel?.getType();

    if (!type) {
      return `${value}`;
    }

    if (typeof value === 'string') {
      try {
        const date = parse(value, DATE_GENERAL_FORMAT, new Date());

        if (options?.dataModel?.getType() === DATATYPE.DATE) {
          const result = moment(date);
          if (result.isValid()) {
            return result.format(options?.dataModel?.getOutputFormat());
          } else {
            throw new Error('invalid date');
          }
        } else {
          const formatType = DATE_FNS_FORMAT_DATE[type];

          if (!formatType) {
            return `${value}`;
          }

          return format(date, formatType);
        }
      } catch (err) {
        const timeFormat = MOMENT_FORMAT_TIME[type];

        if (!timeFormat) {
          return `${value}`;
        }

        return formatTime(value, timeFormat);
      }
    } else {
      return `${value}`;
    }
  };
}

export default DateValueParser;
