import { css, cx } from '../../../../../../core/emotion';
import ValueList from './ValueList';
import useViewModel from './viewModel';
import {
  FilterValueItems,
  FilterValuesSelected,
} from '../../../columns/FilterStrategy';
import EmptyValueList from './EmptyValueList';
import { useTranslation } from 'react-i18next';
import { ConfigTheme } from '../../../type';

type FilterByValueListProps = {
  className?: string;
  valuesSelected: FilterValuesSelected;
  onValuesSelected: (valuesSelected: FilterValuesSelected) => void;
  valueItems: FilterValueItems;
  currentColumnIndex: number;
  checkedCount: number;
  configTheme?: ConfigTheme;
};

const FilterByValueList = ({
  className,
  valuesSelected,
  onValuesSelected,
  valueItems,
  currentColumnIndex,
  checkedCount,
  configTheme,
}: FilterByValueListProps) => {
  const { onSelectAllClick, onClearClick, isActionDisabled } = useViewModel({
    valueItems,
    valuesSelected,
    onValuesSelected,
  });
  const { t } = useTranslation();

  return (
    <div className={cx('border-gray-120', className)}>
      <div className="flex items-center justify-end py-1.5">
        <p
          className={cx(
            'text-xss mr-auto text-gray-700',
            css({
              '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                ?.filter?.label,
            })
          )}
        >
          {t('txt_filter_item_selected', { count: checkedCount })}
        </p>
        <button
          className={cx(
            'text-blue-dark-900 mr-3 cursor-pointer text-xs font-semibold disabled:cursor-not-allowed',
            css({
              '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                ?.filter?.outlineButton,
            })
          )}
          disabled={isActionDisabled}
          onClick={onSelectAllClick}
        >
          {t('txt_select_all')}
        </button>
        <button
          className={cx(
            'text-gray-560 cursor-pointer text-xs font-semibold disabled:cursor-not-allowed',
            css({
              '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                ?.filter?.outlineButton,
            })
          )}
          disabled={isActionDisabled}
          onClick={onClearClick}
        >
          {t('txt_clear')}
        </button>
      </div>
      <div className="bg-gray-50">
        {valueItems.length === 0 ? (
          <EmptyValueList configTheme={configTheme} />
        ) : (
          <ValueList
            valuesSelected={valuesSelected}
            onValuesSelected={onValuesSelected}
            valueItems={valueItems}
            currentColumnIndex={currentColumnIndex}
            configTheme={configTheme}
          />
        )}
      </div>
    </div>
  );
};

export default FilterByValueList;
