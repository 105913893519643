import { VerifyLicenseKeyResponse } from '../types';

export const authLicense = (
  developerMode: boolean,
  verificationResponse: Omit<VerifyLicenseKeyResponse, 'ht_license_key'>
) => {
  if (!verificationResponse.development_mode) {
    return verificationResponse.is_verified && !developerMode;
  } else {
    return verificationResponse.is_verified && developerMode;
  }
};
