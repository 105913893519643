import { css, cx } from 'core/emotion';

type CheckboxProps = {
  className?: string;
  variant?: 'primary' | 'danger';
  checked?: boolean;
  disabled?: boolean;
} & React.ComponentProps<'input'>;

const Checkbox = ({
  variant = 'primary',
  className,
  checked,
  ...props
}: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={cx(
        'h-6 w-6 cursor-pointer rounded-full border-gray-300 focus:ring-0 focus:ring-white disabled:cursor-not-allowed disabled:border-gray-400',
        {
          'border-primary': variant === 'primary',
          'border-danger': variant === 'danger',
        },
        css`
          && {
            :checked {
              background-color: #082561;
              border-color: #082561;

              :focus {
                background-color: #082561;
                border-color: #082561;
              }

              :hover {
                background-color: #082561;
                border-color: #082561;
              }
            }
          }
        `,
        className
      )}
      {...props}
      checked={checked}
    />
  );
};

export default Checkbox;
