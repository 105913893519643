import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import { CSSObject, css, cx } from '../../../../emotion';
import { ReactComponent as DuplicateIcon } from '../../../../../assets/icon/duplicate.svg';
import { ReactComponent as BinIcon } from '../../../../../assets/icon/bin.svg';
import { useMemo } from 'react';
import SelectedBadge from './SelectedBadge';
import { ReviewEntriesThemeAPI } from '../../../../../theme/themeAPI';

type SelectActionsProps = {
  selectedCount: number;
  onDeleteClick: () => void;
  onDuplicateClick: () => void;
  isLoadingDuplicate: boolean;
  customTheme?: ReviewEntriesThemeAPI;
};

const SelectActions = ({
  selectedCount,
  onDeleteClick,
  onDuplicateClick,
  isLoadingDuplicate,
  customTheme,
}: SelectActionsProps) => {
  const { t } = useTranslation();

  const buttonStyle = useMemo(() => {
    return cx(
      '!border-0 !px-3',
      css`
        padding-top: 9px !important;
        padding-bottom: 9px !important;
      `
    );
  }, []);

  return (
    <div className="flex items-center">
      <Button
        variant="secondary"
        className={cx(
          buttonStyle,
          'mr-2',
          css({ '&&': customTheme?.duplicateButton })
        )}
        onClick={onDuplicateClick}
        loading={isLoadingDuplicate}
      >
        <span className="flex items-center">
          {!isLoadingDuplicate ? (
            <DuplicateIcon
              className={cx(
                'mr-2',
                customTheme?.duplicateButton ? '' : 'text-neutral-gray'
              )}
            />
          ) : (
            <span />
          )}
          {t('txt_duplicate_button')}
        </span>
      </Button>
      <Button
        variant="secondary"
        className={cx(
          buttonStyle,
          'mr-3',
          (customTheme?.removeButton as CSSObject)?.color
            ? ''
            : '!text-salmon-500',
          css({ '&&': customTheme?.removeButton })
        )}
        onClick={onDeleteClick}
      >
        <span className="flex items-center">
          <BinIcon className="mr-2" />
          {t('txt_delete_button')}
        </span>
      </Button>
      <SelectedBadge
        className={cx(css({ '&&': customTheme?.selectedBadge }))}
        count={selectedCount}
      />
    </div>
  );
};

export default SelectActions;
