import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { elementId } from '../../../../DataModelSheetForm/constant';

export const InnerElementType = forwardRef<HTMLDivElement>((props, ref) => {
  return <div ref={ref} {...props} className="dropdown-editor-scroller" />;
});

export const createOuterElementType = ({
  onMouseOut,
}: {
  onMouseOut: () => void;
}) => {
  return forwardRef<HTMLDivElement>((props, ref) => {
    const scrollerElementRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => scrollerElementRef.current as HTMLDivElement
    );

    useEffect(() => {
      const preventScroll = (event: Event) => {
        event.stopPropagation();
      };
      const scrollerElementRefCurrent = scrollerElementRef.current;

      if (scrollerElementRefCurrent) {
        scrollerElementRefCurrent.addEventListener('scroll', preventScroll);

        scrollerElementRefCurrent.addEventListener('wheel', preventScroll);
      }

      return () => {
        if (scrollerElementRefCurrent) {
          scrollerElementRefCurrent.removeEventListener('wheel', preventScroll);
          scrollerElementRefCurrent.removeEventListener(
            'scroll',
            preventScroll
          );
        }
      };
    }, []);

    return (
      <div
        ref={scrollerElementRef}
        id={elementId.dropdownElementId.scroll}
        onMouseOut={onMouseOut}
        {...props}
      />
    );
  });
};
