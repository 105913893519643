import ColumnsAPIMapper from '../dataModel/ColumnsAPIMapper';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import FeatureWhiteList from '../settings/FeatureWhiteList';
import { ConfigureAPI } from './configureAPI';
import ThemeAPIMapper from '../theme/ThemeAPIMapper';
import { ConfigureThemeProvider } from '@nuvo-importer/common';

const defaultConfig = {
  licenseKey: '',
  settings: {
    identifier: '',
    columns: [],
    modal: true,
    disableSuccessModal: false,
    allowManualInput: false,
    style: {},
    preloadData: [],
  },
};

export const ConfigureContext = createContext<ConfigureAPI>(defaultConfig);

export const DataModelContext = createContext<ColumnsAPIMapper>(
  new ColumnsAPIMapper([])
);

const FeatureWhiteListContext = createContext<{
  featureWhiteList: FeatureWhiteList;
  setFeatureWhiteList: (featureWhiteList: FeatureWhiteList) => void;
}>({
  featureWhiteList: new FeatureWhiteList(),
  setFeatureWhiteList: () => {},
});

type ConfigureProviderProps = { configure: ConfigureAPI; children: ReactNode };

export const ConfigureProvider = ({
  configure,
  children,
}: ConfigureProviderProps) => {
  const [featureWhiteList, setFeatureWhiteList] = useState(
    new FeatureWhiteList()
  );
  const parsedConfigure = useMemo(() => {
    configure.settings.columns = configure.settings.columns.filter(
      (item) => !!item
    );

    return configure;
  }, [configure]);

  const dataModelAPIMapper = useMemo(
    () => new ColumnsAPIMapper(parsedConfigure.settings.columns ?? []),
    [parsedConfigure.settings]
  );

  const theme = useMemo(() => {
    if (featureWhiteList.hasCustomStyling()) {
      return new ThemeAPIMapper(configure.settings?.style ?? {});
    } else {
      return new ThemeAPIMapper({});
    }
  }, [configure.settings.style, featureWhiteList]);

  return (
    <ConfigureThemeProvider theme={theme}>
      <DataModelContext.Provider value={dataModelAPIMapper}>
        <FeatureWhiteListContext.Provider
          value={{
            featureWhiteList,
            setFeatureWhiteList,
          }}
        >
          <ConfigureContext.Provider value={parsedConfigure}>
            {children}
          </ConfigureContext.Provider>
        </FeatureWhiteListContext.Provider>
      </DataModelContext.Provider>
    </ConfigureThemeProvider>
  );
};

export const useConfigure = () => {
  return useContext(ConfigureContext);
};

export const useFeatureWhiteList = () => {
  return useContext(FeatureWhiteListContext);
};
