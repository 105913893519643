import TruncatePopover from 'baseUI/TruncatePopover';
import { cx, css, CSSInterpolation } from 'core/emotion';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'theme';

type TabMenuMultipleItemProps = {
  name: string;
  selected: boolean;
  onSelect: (index: number) => void;
  index: number;
  sizes: number;
  configTheme?: {
    item?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
    selectedItem?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      border?: CSSInterpolation;
    };
  };
};

export const TabMenuMultipleItem = ({
  configTheme,
  name,
  selected,
  onSelect,
  index,
  sizes,
}: TabMenuMultipleItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const normalPrimaryColor = theme.getGlobalTheme().getPrimaryColor();

  return selected ? (
    <div
      className={cx(
        'rounded-medium min-w-240 py-2.3 relative mr-0 max-h-60 w-full cursor-pointer overflow-hidden bg-white pr-2.5 pl-3.5 md:mb-0',
        css({ '&&': configTheme?.selectedItem?.root })
      )}
    >
      <div
        className={cx(
          'absolute left-0 top-0 bottom-0 h-full w-1',
          css({
            backgroundColor: normalPrimaryColor,
          }),
          css({ '&&': configTheme?.selectedItem?.border })
        )}
      />
      <div className="truncate text-left">
        <TruncatePopover
          detectChangeText={name}
          render={({ referenceElement, textRef, getHover }) => {
            return (
              <div
                className={cx('flex w-full items-center justify-between')}
                ref={referenceElement as RefObject<HTMLDivElement>}
                {...getHover()}
              >
                <span
                  ref={textRef}
                  className={cx(
                    'max-w-2xs text-color-primary mb-1 truncate text-base font-semibold',
                    css({ '&&': configTheme?.selectedItem?.title })
                  )}
                >
                  {name}
                </span>
              </div>
            );
          }}
        />
        <p
          className={cx(
            'text-color-secondary text-sm',
            css({ '&&': configTheme?.selectedItem?.description })
          )}
        >
          {t(sizes === 1 ? 'txt_num_one_sheet_other' : 'txt_num_sheet_other', {
            count: sizes,
          })}
        </p>
      </div>
    </div>
  ) : (
    <div
      className={cx(
        'rounded-medium min-w-240 py-2.3 relative mr-0 max-h-60 w-full cursor-pointer overflow-hidden bg-gray-50 pr-2.5 pl-3.5 md:mb-0',
        css({ '&&': configTheme?.item?.root })
      )}
      onClick={() => onSelect(index)}
    >
      <div
        className={cx(
          'absolute left-0 top-0 bottom-0 h-full w-1 bg-transparent',
          css({ '&&': configTheme?.item?.border })
        )}
      />
      <div className="truncate text-left">
        <TruncatePopover
          detectChangeText={name}
          render={({ referenceElement, textRef, getHover }) => {
            return (
              <div
                className={cx('flex w-full items-center justify-between')}
                ref={referenceElement as RefObject<HTMLDivElement>}
                {...getHover()}
              >
                <span
                  ref={textRef}
                  className={cx(
                    'max-w-2xs text-color-primary mb-1 truncate text-base',
                    css({ '&&': configTheme?.item?.title })
                  )}
                >
                  {name}
                </span>
              </div>
            );
          }}
        />
        <p
          className={cx(
            'text-color-secondary text-sm',
            css({ '&&': configTheme?.item?.description })
          )}
        >
          {t(sizes === 1 ? 'txt_num_one_sheet_other' : 'txt_num_sheet_other', {
            count: sizes,
          })}
        </p>
      </div>
    </div>
  );
};
