import { css, cx } from 'core/emotion';
import { isMobile, isTablet } from 'react-device-detect';
import Popover from 'baseUI/Popover';
import Message from 'baseUI/Popover/Message';
import { useContext, useState, useMemo } from 'react';
import { ReactComponent as InfoBlackIcon } from '../../../../../assets/icon/info-icon-black.svg';
import { DropdownBoundaryContext } from '../../../../DropdownBoundaryContext';
import { Boundary } from '@popperjs/core';
import { OverflowCheckOffset } from 'core/constants/overflowCheckOffset';

const maxStyle = css`
  text-align: left;
  max-width: 320px;
`;

const PopoverInfo = ({
  description,
  configTheme,
  boundary,
  overflowCheckOffset = {
    top: 10,
    left: 0,
    right: 0,
    bottom: 0,
  },
  flip,
}: {
  description: string;
  configTheme?: string;
  boundary?: Boundary;
  overflowCheckOffset?: Partial<OverflowCheckOffset>;
  flip?: boolean;
}) => {
  const [isShow, setIsShow] = useState(false);
  const { pageWrapperElement } = useContext(DropdownBoundaryContext);

  const getHandleHelpInfoEvent = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => {
          setIsShow(true);
        },
        onMouseLeave: () => {
          setIsShow(false);
        },
      };
    } else {
      return {
        onClick: () => {
          setIsShow(true);
        },
        onMouseLeave: () => {
          setIsShow(false);
        },
      };
    }
  };

  const iconStyled = useMemo(() => {
    return configTheme;
  }, [configTheme]);

  return (
    <Popover
      flip={flip}
      isShow={isShow}
      offset={[0, 8]}
      direction="top-start"
      rootElement={pageWrapperElement}
      boundary={boundary}
      overflowCheckOffset={overflowCheckOffset}
      message={
        <Message className={maxStyle} variant="info">
          {description}
        </Message>
      }
      className={`pointer-events-none ${isShow ? 'visible' : 'invisible'}`}
      render={({ referenceElement }) => {
        return (
          <span
            ref={referenceElement}
            className={cx('flex-shrink-0', iconStyled)}
            {...getHandleHelpInfoEvent()}
          >
            <InfoBlackIcon className={cx('h-5 w-5 rotate-180 transform')} />
          </span>
        );
      }}
    />
  );
};

export default PopoverInfo;
