import { matchSorter } from 'match-sorter';
import { VariableSizeList } from 'react-window';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useDebounce } from 'react-use';
import { DataModel } from '../../../../../dataModel/model/DataModel';
import CategoryDataModel, {
  Option,
} from '../../../../../dataModel/model/CategoryDataModel';
import { elementId } from '../../../../DataModelSheetForm/constant';
import SearchInput from '../../../common/SearchInput';
import { createOuterElementType, InnerElementType } from './scroll';
import { isDropdownOptionEqual } from '../../../../../dataModel/utils';
import { css, CSSInterpolation, cx } from '../../../../../emotion';
import { filterMultipleValues } from '../../../../../reviewEntries/utils/dropdown';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import { useTheme } from '../../../../../../theme';
import { isEmpty } from 'lodash';
import DropdownOptions from '../../../../../../components/DropdownOptions';
import type { DropdownOptionProps } from '../../../../../../components/DropdownOptions/type';
import CreateNewColumnOptionButton from '../../../../../../components/CreateNewColumnOptionButton';
import { Subject } from 'rxjs';
import { CustomOptionUIObservable } from '../../../../DataModelSheetForm/customOption';

const optionItemHeight = 39;

type MenuItemDropdownProps = {
  onSelectOption: (
    value: string | number | string[],
    multiSelect?: boolean
  ) => void;
  currentEditingValueRef: MutableRefObject<string | string[]>;
  currentEditingModelRef: MutableRefObject<DataModel | undefined>;
  optionsRef: MutableRefObject<Option[]>;
  currentSelectorRef: MutableRefObject<number>;
  options: Option[];
  isShowSearchBar: boolean;
  searchValueRef: MutableRefObject<string>;
  configTheme?: {
    header?: CSSInterpolation;
    search?: {
      root?: CSSInterpolation;
      icon?: CSSInterpolation;
      placeholder?: string;
    };
    button?: {
      closeIcon?: CSSInterpolation;
      arrowIcon?: CSSInterpolation;
    };
    item?: {
      root?: CSSInterpolation;
      option?: CSSInterpolation;
      selectedOption?: CSSInterpolation;
    };
    multiSelectionBadge?: {
      root?: CSSInterpolation;
      icon?: CSSInterpolation;
    };
    iconCheckedColor?: string;
    createNewOption?: {
      root?: CSSInterpolation;
      icon?: CSSInterpolation;
    };
  };
  isLoading?: boolean;
  customOptionUIObservable: Subject<CustomOptionUIObservable>;
  modal?: boolean;
};

const MenuItemDropdown = ({
  onSelectOption,
  currentEditingValueRef,
  currentEditingModelRef,
  currentSelectorRef,
  options,
  isShowSearchBar,
  configTheme,
  searchValueRef,
  isLoading = false,
  customOptionUIObservable,
  modal,
}: MenuItemDropdownProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentSelector, setCurrentSelectorRef] = useState(
    currentSelectorRef.current
  );
  const [isMultiSelect, setIsMultiSelect] = useState(
    currentEditingModelRef.current?.getIsMultiSelection()
  );

  const [currentEditingValue, setCurrentEditingValue] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const listOptionsRef = useRef<VariableSizeList>(null);
  const [offsetHeightHeader, setOffsetHeightHeader] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSelectorRef(currentSelectorRef.current);
      setSearchValue(searchValueRef.current);
      setIsMultiSelect(currentEditingModelRef.current?.getIsMultiSelection());
      if (typeof currentEditingValueRef.current === 'string') {
        setCurrentEditingValue([currentEditingValueRef.current as string]);
      } else {
        setCurrentEditingValue(
          (currentEditingValueRef.current as string[]) || []
        );
      }
    }, 0);

    return () => {
      clearInterval(interval);
    };
  }, [
    currentSelectorRef,
    currentEditingValueRef,
    searchValueRef,
    currentEditingModelRef,
  ]);

  useDebounce(
    () => {
      setDebouncedValue(searchValue);
    },
    500,
    [searchValue]
  );

  const searchOptions = useMemo(
    () =>
      matchSorter(options, debouncedValue, {
        keys: ['value', 'label'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (item) => item,
      }),
    [options, debouncedValue]
  );

  const dropdownOptions = useMemo(() => {
    return searchOptions.map((option, index) => {
      return {
        option: option as DropdownOptionProps,
        index,
      };
    });
  }, [searchOptions]);

  containerRef.current = !containerRef.current
    ? (document.querySelector(
        '#review-entries-table .ht_master'
      ) as HTMLDivElement)
    : containerRef.current;

  const isShowHeader = useMemo(
    () => isShowSearchBar || isMultiSelect,
    [isMultiSelect, isShowSearchBar]
  );

  const isSelecting = useCallback(
    (option: DropdownOptionProps) => {
      if (isMultiSelect) {
        return currentEditingValue?.some((value) =>
          isDropdownOptionEqual(option as Option, value)
        );
      } else {
        return isDropdownOptionEqual(
          option as Option,
          currentEditingValueRef.current
        );
      }
    },
    [currentEditingValue, currentEditingValueRef, isMultiSelect]
  );

  const onSelect = (value: string) => {
    const newValues = filterMultipleValues({
      currentValue: currentEditingValue,
      isMultiSelect: isMultiSelect ?? false,
      options,
      updateValue: value,
    });

    if (isMultiSelect) {
      setCurrentEditingValue(newValues as string[]);
      onSelectOption(newValues, isMultiSelect);
    } else {
      onSelectOption(newValues);
    }
  };

  const hoveredItemStyle = useMemo(() => {
    return css({
      backgroundColor: theme.getGlobalTheme().getHoverDropdownCategory(),
    });
  }, [theme]);

  const selectedItemStyle = useMemo(() => {
    return cx(
      'selected',
      css({
        backgroundColor: theme.getGlobalTheme().getLight50Color(),
      })
    );
  }, [theme]);

  const hoveredAndSelectedItemStyle = useMemo(() => {
    return cx(
      'options-hover',
      css({
        ':hover': {
          backgroundColor: theme.getGlobalTheme().getHoverDropdownCategory(),
        },
      })
    );
  }, [theme]);

  const checkedIconStyle = useMemo(() => {
    return configTheme?.iconCheckedColor
      ? css(`color: ${configTheme?.iconCheckedColor}`)
      : css({
          color: theme.getGlobalTheme().getNormal500Color(),
        });
  }, [configTheme?.iconCheckedColor, theme]);

  const onMouseOver = useCallback(
    (index: number) => {
      currentSelectorRef.current = index;
    },
    [currentSelectorRef]
  );

  const outerElementType = useMemo(() => {
    return createOuterElementType({
      onMouseOut: () => {
        currentSelectorRef.current = options.findIndex(
          (entry) => entry.value === currentEditingValueRef.current
        );
      },
    });
  }, [options, currentSelectorRef, currentEditingValueRef]);

  const isSearching = !isEmpty(searchValue);

  const itemCount = useMemo(() => {
    if (isShowHeader) {
      return searchOptions.length + 1;
    } else {
      return searchOptions.length;
    }
  }, [searchOptions, isShowHeader]);

  const configThemeHeader = useMemo(() => {
    return cx(css({ '&&': configTheme?.header }));
  }, [configTheme]);

  const searchHeaderTextRef = useRef<HTMLParagraphElement>(null);

  const optionItemData = {
    items: dropdownOptions,
    onSelect,
    onMouseOver,
    currentSelector,
    hoveredItemStyle,
    isSelecting,
    hoveredAndSelectedItemStyle,
    selectedItemStyle,
    checkedIconStyle,
    isShowHeader,
    configThemeHeader,
    isMultiSelect,
    searchHeaderTextRef,
    isSearching: !isEmpty(searchValue),
  };

  const isShowCustomOption =
    currentEditingModelRef.current?.isCategoryType() &&
    (
      currentEditingModelRef.current as CategoryDataModel
    ).getAllowCustomOptions();

  useEffect(() => {
    if (searchHeaderTextRef.current) {
      setOffsetHeightHeader(searchHeaderTextRef.current?.offsetHeight ?? 0);
    }
  }, [dropdownOptions, searchHeaderTextRef.current?.offsetHeight]);

  useLayoutEffect(() => {
    listOptionsRef.current?.resetAfterIndex(0);
    listOptionsRef.current?.scrollToItem(0);
  }, [isShowHeader, isMultiSelect, searchOptions, offsetHeightHeader]);

  const createNewColumnOptionStyled = useMemo(() => {
    return configTheme?.createNewOption
      ? {
          root: css({ '&&': configTheme?.createNewOption?.root }),
          icon: css({ '&&': configTheme?.createNewOption?.icon }),
        }
      : undefined;
  }, [configTheme]);

  const optionsListHeight = useMemo(() => {
    const optionsListMaxHeight = isShowCustomOption ? 150 : 162;
    let baseHeight = optionItemHeight * searchOptions.length;
    if (isShowHeader) {
      baseHeight += offsetHeightHeader;
    }
    return Math.min(baseHeight, optionsListMaxHeight);
  }, [
    isShowHeader,
    searchOptions.length,
    offsetHeightHeader,
    isShowCustomOption,
  ]);

  if (!containerRef.current) {
    return null;
  }

  const getItemSize = (index: number) => {
    if (isShowHeader && index === 0) {
      return offsetHeightHeader;
    } else {
      return optionItemHeight;
    }
  };

  return createPortal(
    <div
      id={elementId.dropdownElementId.parent}
      className="rounded-medium nuvo-invisible pointer-events-none fixed bg-white shadow-lg"
      style={{
        zIndex: modal ? 120 : 20,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isShowSearchBar ? (
            <SearchInput
              onChange={(value) => {
                searchValueRef.current = value?.trim();
              }}
              value={searchValue}
              headerTheme={cx(css({ '&&': configTheme?.header }))}
              theme={{
                root: css({ '&&': configTheme?.search?.root }),
                icon: css`
                  .color {
                    color: #383838;
                    ${configTheme?.search?.icon}
                  }
                `,
                placeholder: css`
                  ::placeholder {
                    color: ${configTheme?.search?.placeholder};
                    opacity: 1;
                  }
                `,
              }}
            />
          ) : null}
          <DropdownOptions
            className={
              !isEmpty(options) &&
              (isSearching ? searchOptions.length > 0 : true)
                ? 'rounded-b-medium'
                : ''
            }
            outerElementType={outerElementType}
            innerElementType={InnerElementType}
            height={optionsListHeight}
            itemCount={itemCount}
            itemSize={getItemSize}
            ref={listOptionsRef}
            optionItemData={optionItemData}
          />
          {isEmpty(options) ? (
            <div className="block px-4 py-6 text-center text-sm capitalize italic text-gray-400">
              <span>{t('txt_empty')}</span>
            </div>
          ) : isSearching && searchOptions.length <= 0 ? (
            <p className="block px-4 py-6 text-center text-sm capitalize italic text-gray-400">
              {t('txt_empty')}
            </p>
          ) : null}
          {isShowCustomOption ? (
            <CreateNewColumnOptionButton
              className="box-border"
              label={t('txt_create_new_option')}
              configTheme={createNewColumnOptionStyled}
              onClick={() => {
                if (
                  currentEditingModelRef.current &&
                  currentEditingModelRef.current?.isCategoryType()
                ) {
                  customOptionUIObservable.next({
                    isOpen: true,
                    dataModel:
                      currentEditingModelRef.current as CategoryDataModel,
                  });
                }
              }}
            />
          ) : null}
        </>
      )}
    </div>,
    containerRef.current
  );
};

export default MenuItemDropdown;
