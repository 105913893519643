import { SubmitResult, SubmitResultType } from 'core/submitResult';

interface PassSubmitResultData {
  successfulRecords: number;
  failedRecords: number;
  title: string;
  text: string;
  imageUrl: string;
  duration?: number;
}

export default class PassSubmitResult implements SubmitResult {
  public type = SubmitResultType.Pass;
  private successfulRecords: number;
  private failedRecords: number;
  private title: string;
  private text: string;
  private imageUrl: string;
  private duration?: number;

  constructor(data: PassSubmitResultData) {
    this.successfulRecords = data.successfulRecords;
    this.failedRecords = data.failedRecords;
    this.title = data.title;
    this.text = data.text;
    this.imageUrl = data.imageUrl;
    this.duration = data?.duration;
  }

  getSuccessfulRecords(): number {
    return this.successfulRecords;
  }

  getFailedRecords(): number {
    return this.failedRecords;
  }

  getTitle(): string {
    return `${this.title}`;
  }

  getText(): string {
    return `${this.text}`;
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getDuration(): number | undefined {
    return this?.duration;
  }
}
