import { DataModel } from 'dataModel';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import DataModelSheetMatcher from './../../../../matching/DataModelSheetMatcher';
import useViewModel from './viewModel';
import SingleDropdown from '../common/SingleDropdown';
import { Variant } from 'core/constants/variant';
import { CSSInterpolation, cx } from 'core/emotion';

type DataModelMatcherProps = {
  title: string;
  variant?: Variant;
  matchedDataModel?: DataModel;
  dataModelSheetMatcherModel: DataModelSheetMatcher;
  prefixName: string;
  sheetColumn: SheetColumn;
  configThemeDropdownButton?: {
    root?: string;
    icon?: string;
    placeholder?: string;
  };
  configThemeDropdownMenuItems?: {
    root?: string;
    borderColorRoot?: string;
    navigatorColor?: string;
    backgroundColor?: string;
    header?: string;
    menuItem?: {
      selectedOption?: string;
      option?: string;
      badge?: { root?: CSSInterpolation; icon?: CSSInterpolation };
      search?: {
        root?: string;
        icon?: string;
        placeholder?: string;
      };
      createNewOption?: {
        root?: string;
        icon?: string;
      };
    };
    popoverInfoIcon?: string;
    popoverInfoTheme?: {
      root?: string;
      arrowIcon?: string;
    };
    multiSelectionBadge?: {
      root?: string;
      icon?: string;
    };
  };

  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setLoadingMatchingMoreOptions: (loadingMatchingMoreOptions: boolean) => void;
};

const DataModelMatcher = ({
  title,
  variant = 'primary',
  prefixName,
  dataModelSheetMatcherModel,
  sheetColumn,
  configThemeDropdownButton,
  configThemeDropdownMenuItems,
  setDataModelSheetMatcher,
  setLoadingMatchingMoreOptions,
}: DataModelMatcherProps) => {
  const {
    options,
    onChange,
    value,
    input,
    onRemoveMatchColumn,
    onSubmitCreateDataModel,
  } = useViewModel({
    dataModelSheetMatcherModel,
    prefixName,
    sheetColumn,
    setDataModelSheetMatcher,
    setLoadingMatchingMoreOptions,
  });

  return (
    <div className={cx('max-w-272 flex w-full justify-end')}>
      <SingleDropdown
        {...input}
        title={title}
        value={value}
        variant={variant}
        options={options}
        onChange={onChange}
        onRemoveMatchColumn={onRemoveMatchColumn}
        configThemeDropdownButton={configThemeDropdownButton}
        configThemeDropdownMenuItems={configThemeDropdownMenuItems}
        onCreateColumnModel={onSubmitCreateDataModel}
      />
    </div>
  );
};

export default DataModelMatcher;
