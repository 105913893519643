import AllColumnSetting from '../../columns/AllColumnSetting';
import ContextMenuController from '../controller/ContextMenuController';

const useViewModel = ({
  allColumnSetting,
  contextMenuController,
  freeze,
  currentColumnIndex,
}: {
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  freeze: boolean;
  currentColumnIndex: number;
}) => {
  const onMenuClick = () => {
    if (!freeze && allColumnSetting.hasMaxFreezeColumn()) return;
    allColumnSetting.setFreezeToColumnSetting(currentColumnIndex, !freeze);
    contextMenuController.closeMenu();
  };

  return { onMenuClick };
};

export default useViewModel;
