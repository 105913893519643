import BooleanValueParser from './BooleanValueParser';
import IValueParser, { PossibleValue } from './IValueParser';
import CategoryParser from './CategoryParser';
import StringValueParser from './StringValueParser';
import IntNumberValueParser from './IntNumberValueParser';
import FloatNumberValueParser from './FloatNumberValueParser';
import { DATATYPE } from '../../dataType';
import CurrencyValueParser from './CurrencyValueParser';
import PercentValueParser from './PercentValueParser';
import { DataModel } from '../../dataModel/model/DataModel';

class ValueResultParser {
  static parse = (dataModel: DataModel, value: PossibleValue) => {
    let parser: IValueParser;
    switch (dataModel.getType()) {
      case DATATYPE.INTEGER:
        parser = new IntNumberValueParser();
        break;
      case DATATYPE.FLOAT:
        parser = new FloatNumberValueParser();
        break;
      case DATATYPE.BOOLEAN:
        parser = new BooleanValueParser();
        break;
      case DATATYPE.SINGLE_SELECT:
      case DATATYPE.CURRENCY_CODE:
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
        parser = new CategoryParser();
        break;
      case DATATYPE.CURRENCY_EUR:
      case DATATYPE.CURRENCY_USD:
        parser = new CurrencyValueParser();
        break;
      case DATATYPE.PERCENTAGE:
        parser = new PercentValueParser();
        break;
      case DATATYPE.DATE_DMY:
      case DATATYPE.DATE_MDY:
      case DATATYPE.DATE_ISO:
      case DATATYPE.DATETIME:
      case DATATYPE.TIME_HMS:
      case DATATYPE.TIME_HMS_24:
      case DATATYPE.TIME_HM:
      case DATATYPE.TIME_HM_24:
      case DATATYPE.EMAIL:
      case DATATYPE.URL_WWW:
      case DATATYPE.URL_HTTPS:
      case DATATYPE.URL:
      case DATATYPE.PHONE:
      case DATATYPE.ZIP_CODE_DE:
      case DATATYPE.BIC:
      case DATATYPE.VAT_EU:
      case DATATYPE.GTIN:
      case DATATYPE.IBAN:
      default:
        parser = new StringValueParser();
        break;
    }

    return parser.parse(dataModel, value, false);
  };
}

export default ValueResultParser;
