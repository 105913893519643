import { isArray, isEqual, flatten } from 'lodash';
import { UploadData } from '../types';
import Sheet, { Value } from './Sheet';

export function intersection(a: Value[], b: Value[]) {
  const setA = new Set(a);
  return b.filter((value) => setA.has(value));
}

export const findSheetColumnBySheets = (
  sheets: Sheet[],
  sheetColumnKey: string,
  uniqueRows: Value[]
) => {
  const dupSheetColumns = flatten(
    sheets.map((sheet) => sheet.getColumns())
  ).filter((column) => column.getColumnKey() === sheetColumnKey);

  const isDuplicated = dupSheetColumns.length > 1;

  if (isDuplicated) {
    for (let i = 0; i < dupSheetColumns.length; i++) {
      const element = dupSheetColumns[i];
      const intersectItems = intersection(element.getUniqueRows(), uniqueRows);
      if (intersectItems.length === uniqueRows.length) {
        return element;
      }
    }

    return null;
  } else {
    return dupSheetColumns[0];
  }
};

export const isSheetColumnOptionEqual = (
  uniqueRow: Value,
  sheetColumnOptionValue: Value
) => {
  return isEqual(`${uniqueRow}`, `${sheetColumnOptionValue}`);
};

export const getIsDataInSheetDataFormat = (data: UploadData) => {
  return isArray(data) && data.every((item) => isArray(item));
};
