import { FC, ReactNode, useRef } from 'react';
import { useContextSuccessModalManager } from './context';
import { Card } from './Modal.view';
import { ReactComponent as SuccessMarkIcon } from '../../assets/icon/ic_success.svg';
import { ReactComponent as FailureMarkIcon } from '../../assets/icon/ic_failure.svg';
import { css, cx } from 'core/emotion';
import { isEmpty, isNil } from 'lodash';
import { useTheme } from 'theme';
import { NUVO_TAILWIND_CLASS } from 'core/constants/style';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

export type SuccessModalProps = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  logoURL?: string;
  options?: { amountOfSuccess: number; amountOfFailure: number };
  duration?: number;
};

const SuccessModal: FC<SuccessModalProps> = ({
  title,
  description,
  logoURL,
  options,
}) => {
  const { isOpen, setOpenModal } = useContextSuccessModalManager();
  const divRef = useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal
      portalClassName={NUVO_TAILWIND_CLASS}
      isOpen={isOpen}
      overlayClassName={{
        base: cx(
          'fixed inset-0 overflow-y-auto inset-0 bg-black bg-opacity-75 transition-opacity ease-out duration-300',
          css({ '&&': theme.getDialogConfirmTheme().overlay, opacity: 0 }),
          css`
            z-index: 10000;
          `
        ),
        afterOpen: css({
          '&&': {
            opacity: 1,
          },
        }),
        beforeClose: css`
          opacity: 0 !important;
        `,
      }}
      className={{
        base: cx(
          'block scale-95 transform transition-all duration-300 ease-out',
          css`
            opacity: 0;
          `
        ),
        beforeClose: cx(
          css`
            opacity: 0 !important;
            transform: scale(0.95) !important;
          `
        ),
        afterOpen: css({
          '&&': {
            opacity: 1,
            transform: 'scale(1)',
          },
        }),
      }}
      preventScroll
      closeTimeoutMS={300}
    >
      <div
        className="h-screen items-end justify-center text-center sm:p-0"
        onClick={() => {
          setOpenModal(false);
        }}
      >
        <span
          className={cx('inline-block h-screen align-middle')}
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Card className={css({ '&&': theme.getDialogConfirmTheme().root })}>
          <div
            ref={divRef}
            className={cx('flex h-full flex-col items-center justify-center')}
          >
            <div className="h-auto w-full text-center text-lg ">
              <div
                className={cx(
                  'mb-6 w-full pt-6',
                  css({
                    backgroundColor: theme.getGlobalTheme().getLight50Color(),
                    borderTopRightRadius: theme
                      .getGlobalTheme()
                      .getBorderRadius(),
                    borderTopLeftRadius: theme
                      .getGlobalTheme()
                      .getBorderRadius(),
                    borderBottomRightRadius: 8,
                    borderBottomLeftRadius: 8,
                  })
                )}
              >
                {logoURL ? (
                  <div className="flex justify-center pb-6">
                    <img
                      className="h-40 w-auto object-contain"
                      src={logoURL}
                      alt="nuvo-custom-icon-success"
                    ></img>
                  </div>
                ) : (
                  <div className="flex w-full justify-center">
                    <img
                      className="h-40 w-40 object-contain"
                      src="https://s3.eu-central-1.amazonaws.com/general-upload.getnuvo.com/tada-lottie.gif"
                      alt="nuvo-custom-icon-success"
                    ></img>
                  </div>
                )}
              </div>
              {title && (
                <p
                  className={cx(
                    'xss:pb-2 text-color-primary pb-1 text-center text-xl font-semibold',
                    css({ '&&': theme.getDialogConfirmTheme().title }),
                    { 'mb-8': isEmpty(description) }
                  )}
                >
                  {title}
                </p>
              )}
              <div className="pb-8">
                {description && (
                  <p
                    className={cx(
                      'text-color-secondary text-center text-sm font-normal',
                      css({ '&&': theme.getDialogConfirmTheme().description })
                    )}
                  >
                    {description}
                  </p>
                )}
                {options &&
                (!isNil(options?.amountOfSuccess) ||
                  !isNil(options?.amountOfFailure)) ? (
                  <div className="mx-4 flex justify-center gap-x-3 pt-4">
                    <div className="border-1 min-w-210 text-xsmall border-gray-120 !w-fit flex items-center rounded-lg pr-5">
                      <div className="border-r-1 border-gray-120 rounded-tl-lg rounded-bl-lg bg-green-100 p-2">
                        <SuccessMarkIcon />
                      </div>
                      <p className="pl-3 text-gray-800">
                        {t('txt_successful_records', {
                          amount: options?.amountOfSuccess ?? 0,
                        })}
                      </p>
                    </div>

                    <div className="border-1 text-xsmall min-w-210 border-gray-120 !w-fit flex items-center rounded-lg pr-5">
                      <div className="bg-salmon-50 border-r-1 border-gray-120 rounded-tl-lg rounded-bl-lg p-2">
                        <FailureMarkIcon />
                      </div>
                      <p className="pl-3 text-gray-800">
                        {t('txt_failed_records', {
                          amount: options?.amountOfFailure ?? 0,
                        })}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default SuccessModal;
