import { isArray, isBoolean, isNil, isObject } from 'lodash';
import ValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';

class StringValueParser implements ValueParser {
  parse = (value: PossibleValue, _options?: IValueParserOptions) => {
    if (isNil(value)) {
      return '';
    }

    if (isArray(value) || isObject(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return JSON.stringify(value);
    }

    return `${value}`;
  };
}

export default StringValueParser;
