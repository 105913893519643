import { createContext, ReactNode } from 'react';

const DeveloperModeContext = createContext<boolean>(false);

type DeveloperModeProviderProps = {
  developerMode: boolean;
  children: ReactNode;
};

export const DeveloperModeProvider = ({
  developerMode,
  children,
}: DeveloperModeProviderProps) => {
  return (
    <DeveloperModeContext.Provider value={developerMode}>
      {children}
    </DeveloperModeContext.Provider>
  );
};
