import { useState, MouseEvent } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

const useViewModel = () => {
  const [isShowPopover, setShowIsPopover] = useState(false);

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: (event: MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          setShowIsPopover(true);
        },
        onMouseLeave: () => setShowIsPopover(false),
      };
    } else {
      return {
        onClick: () => setShowIsPopover((isShow) => !isShow),
        onMouseLeave: () => setShowIsPopover(false),
      };
    }
  };

  return {
    isShowPopover,
    setShowIsPopover,
    getOnHover,
  };
};

export default useViewModel;
