export enum DATATYPE {
  BOOLEAN = 'boolean',
  SINGLE_SELECT = 'single-select',
  MULTIPLE_SELECT = 'multiple-select',
  INTEGER = 'int',
  FLOAT = 'float',
  STRING = 'string',
  DATE_DMY = 'date_dmy',
  DATE_MDY = 'date_mdy',
  DATE_ISO = 'date_iso',
  DATETIME = 'datetime',
  DATE = 'date',
  TIME_HMS = 'time_hms',
  TIME_HMS_24 = 'time_hms_24',
  TIME_HM = 'time_hm',
  TIME_HM_24 = 'time_hm_24',
  EMAIL = 'email',
  URL_WWW = 'url_www',
  URL_HTTPS = 'url_https',
  URL = 'url',
  PHONE = 'phone',
  ZIP_CODE_DE = 'zip_code_de',
  PERCENTAGE = 'percentage',
  COUNTRY_CODE_ALPHA_2 = 'country_code_alpha_2',
  COUNTRY_CODE_ALPHA_3 = 'country_code_alpha_3',
  CURRENCY_CODE = 'currency_code',
  CURRENCY_EUR = 'currency_eur',
  CURRENCY_USD = 'currency_usd',
  BIC = 'bic',
  VAT_EU = 'vat_eu',
  GTIN = 'gtin',
  IBAN = 'iban',
}
