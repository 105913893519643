import { useTranslation } from 'react-i18next';

const EmptyRows = () => {
  const { t } = useTranslation();
  return (
    <div className="border-gray-450 border-1 rounded-b-medium py-5 ">
      <p className="text-center text-sm capitalize italic text-gray-400">
        {t('txt_empty')}
      </p>
    </div>
  );
};

export default EmptyRows;
