import {
  MatchingJSON,
  MatchingSheetJSON,
} from '../../reviewEntries/DataModelSheetMatchingValues';
import * as Comlink from 'comlink';
import { MatchingSerializer } from 'core/matching/serializer';
import { NumberFormat } from '@nuvo-importer/common/core';

Comlink.transferHandlers.set('Matching', {
  canHandle: ((obj: unknown) => {
    if (typeof obj === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const item = obj as any;
      if (item.matching) {
        return true;
      }
      if (item.numberFormat) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as unknown as any,
  serialize: (instance: {
    matching: MatchingSheetJSON[];
    numberFormat: NumberFormat;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return MatchingSerializer.serialize(instance) as any;
  },
  deserialize: (obj: {
    matching: MatchingJSON[];
    numberFormat: NumberFormat;
  }): { matching: MatchingSheetJSON[]; numberFormat: NumberFormat } => {
    return MatchingSerializer.deserialize(obj);
  },
});
