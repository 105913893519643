import Handsontable from 'handsontable';
import { ColumnSetting } from './AllColumnSetting';

export type HideIcon = {
  iconL?: boolean;
  iconR?: boolean;
};

class HideStrategy {
  static hideButtonClassName = 'hide-arrow-btn';
  static hideButtonHoverClassName = 'hide-arrow-btn-hover';
  static hideIconLeftClassName = 'hide-icon-left';
  static hideIconRightClassName = 'hide-icon-right';
  private hotInstance?: Handsontable;

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  hideColumn(
    columnIndex: number,
    hide: boolean,
    columnSettings: ColumnSetting[]
  ) {
    const cloneColumnSettings = [...columnSettings];
    cloneColumnSettings[columnIndex].hide = hide;
    const hideColumnsPlugin = this.hotInstance?.getPlugin('hiddenColumns');
    const plugin = this.hotInstance?.getPlugin('autoColumnSize');
    if (hide) {
      hideColumnsPlugin?.hideColumn(columnIndex);
    } else {
      hideColumnsPlugin?.showColumn(columnIndex);
    }

    plugin?.calculateAllColumnsWidth();
    this.hotInstance?.render();
    window.dispatchEvent(new Event('resize'));
    return cloneColumnSettings;
  }

  hasMaximumHideColumn = (columnSettings: ColumnSetting[]) => {
    const showColumnSettings = columnSettings.filter((columnSetting) => {
      return !columnSetting.hide;
    });

    return showColumnSettings.length === 1;
  };

  showHideColumns = (
    columnSettings: ColumnSetting[],
    columnIndex: number,
    isShowLeft: boolean
  ) => {
    const cloneColumnSettings = [...columnSettings];
    const hideCols = [];

    if (isShowLeft) {
      for (let i = columnIndex - 1; i >= 0; --i) {
        if (columnSettings[i].hide) {
          hideCols.push(i);
          cloneColumnSettings[i].hide = false;
        } else {
          break;
        }
      }
    } else {
      for (let i = columnIndex + 1; i < columnSettings.length; ++i) {
        if (columnSettings[i].hide) {
          hideCols.push(i);
          cloneColumnSettings[i].hide = false;
        } else {
          break;
        }
      }
    }

    const hideColumnsPlugin = this.hotInstance?.getPlugin('hiddenColumns');
    hideColumnsPlugin?.showColumns(hideCols);
    this.hotInstance?.render();
  };

  displayShowColumnIcon(columnSettings: ColumnSetting[]) {
    const icons: HideIcon[] = [];

    // NOTE: physical column index
    for (let i = 0; i < columnSettings.length; ++i) {
      // NOTE: first column
      if (i === 0) {
        if (columnSettings[i + 1]?.hide) {
          icons[i] = { iconR: true };
        } else {
          icons[i] = { iconR: false };
        }
      } else if (i === columnSettings.length - 1) {
        // NOTE: last column
        if (columnSettings[i - 1]?.hide) {
          icons[i] = {
            iconL: true,
          };
        } else {
          icons[i] = {
            iconL: false,
          };
        }
      } else {
        // NOTE: middle column
        if (columnSettings[i - 1]?.hide && columnSettings[i + 1]?.hide) {
          icons[i] = {
            iconL: true,
            iconR: true,
          };
        } else if (columnSettings[i - 1]?.hide) {
          icons[i] = {
            iconL: true,
            iconR: false,
          };
        } else if (columnSettings[i + 1]?.hide) {
          icons[i] = {
            iconL: false,
            iconR: true,
          };
        } else {
          icons[i] = {
            iconL: false,
            iconR: false,
          };
        }
      }
    }

    return icons;
  }
}

export default HideStrategy;
