import { ReactNode } from 'react';
import { css, CSSInterpolation, cx } from 'core/emotion';
import { breakpoints } from 'core/constants/breakpoints';

const Card = ({
  children,
  configTheme,
}: {
  children: ReactNode;
  configTheme?: CSSInterpolation;
}) => {
  return (
    <div
      className={cx(
        'border-1 rounded-medium border-gray-120 smmd:pr-14 large:pb-2 mmd:pr-14 relative mb-2 grid items-start gap-x-8 p-2 pb-0 pr-10',
        css`
          @media (min-width: ${breakpoints.large}) {
            grid-template-columns: minmax(610px, 1fr) 1fr;
          }
        `,
        css({ '&&': configTheme })
      )}
    >
      {children}
    </div>
  );
};

export default Card;
