export const getSizeByWidthForModal = (windowWidth: number) => {
  if (windowWidth >= 1755) {
    return 12;
  } else if (windowWidth >= 1495) {
    return 10;
  } else if (windowWidth >= 1281) {
    return 8;
  } else if (windowWidth >= 1155) {
    return 6;
  } else if (windowWidth >= 919) {
    return 4;
  } else {
    return 2;
  }
};

export const getSizeByWidthForPage = (elementWidth: number) => {
  if (elementWidth >= 1659) {
    return 12;
  } else if (elementWidth >= 1459) {
    return 10;
  } else if (elementWidth >= 1259) {
    return 8;
  } else if (elementWidth >= 1059) {
    return 6;
  } else if (elementWidth >= 859) {
    return 4;
  } else {
    return 2;
  }
};
