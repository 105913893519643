import {
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react';
import ModalView from './index.view';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { useDevelopMode } from 'license';
import { useMainView } from 'settings';

const ModalContext = createContext<{
  cancel: () => void;
  closeModal: () => void;
  closeFullScreen: () => void;
  toggleFullScreen: () => void;
  isFullScreen: boolean;
}>({
  cancel: () => {},
  closeModal: () => {},
  closeFullScreen: () => {},
  toggleFullScreen: () => {},
  isFullScreen: false,
});

export const useModal = () => {
  return useContext(ModalContext);
};

const Modal = ({
  children,
  isOpenModal,
  cancel,
  closeModal,
}: {
  children: ReactNode;
  isOpenModal: boolean;
  cancel: () => void;
  closeModal: () => void;
}) => {
  const { showConfirmModal } = useContextConfirmModalManager();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { t } = useTranslation();
  const mainView = useMainView();
  const { isDeveloperMode } = useDevelopMode();

  const onCloseModal = useCallback(() => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_confirm_title_close_modal'),
      description: t('txt_close_page_modal'),
      textNegativeButton: t('txt_positive_button_close_modal'),
      textPositiveButton: t('txt_negative_button_close_modal'),
      onClickNegativeButton: () => {
        cancel();
      },
    });
  }, [cancel, showConfirmModal, t]);

  const closeFullScreen = useCallback(() => {
    setIsFullScreen(false);
  }, []);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((isFullScreen) => !isFullScreen);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        cancel,
        closeModal,
        closeFullScreen,
        toggleFullScreen,
        isFullScreen,
      }}
    >
      <ModalView
        isOpen={isOpenModal}
        isShowBanner={mainView.modal && isDeveloperMode}
        onCloseModal={onCloseModal}
        isFullScreen={isFullScreen}
      >
        {children}
      </ModalView>
    </ModalContext.Provider>
  );
};

export default Modal;
