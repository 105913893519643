import useViewModel from '../viewModel';
import DesktopMenuItem from '../MenuItem/Desktop';

const Desktop = () => {
  const { menuItems } = useViewModel();

  return (
    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
      {menuItems.map((item) => (
        <DesktopMenuItem key={item.name} name={item.name} path={item.path} />
      ))}
    </div>
  );
};

export default Desktop;
