import { cx } from 'core/emotion';

const PageNavigationButton = ({
  children,
  className,
  ...props
}: React.ComponentProps<'button'>) => {
  return (
    <button
      type="button"
      className={cx(
        className,
        'focus:outline-none hover:text-blue-dark-900 mx-px flex h-6 w-6 items-center justify-center rounded-full'
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default PageNavigationButton;
