import { createPopper, Instance, Placement } from '@popperjs/core';
import { BehaviorSubject } from 'rxjs';

export class InfoMessagePopper {
  private state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showEvents = ['mouseenter'];
  hideEvents = ['mouseleave'];
  popperInstance!: Instance;
  rootElement!: Element;
  popperElement!: HTMLElement;

  initPopper(
    rootElement: Element,
    popperElement: HTMLElement,
    textValue: string,
    placement?: Placement
  ) {
    this.rootElement = rootElement;
    this.popperElement = popperElement;

    if (!popperElement) return;

    this.popperInstance = createPopper(this.rootElement, this.popperElement, {
      placement: placement || 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: ['top-end'],
            allowedAutoPlacements: ['top-end'],
          },
        },
      ],
    });

    popperElement.innerHTML = '';
    popperElement.innerHTML = textValue;

    this.show(rootElement, popperElement);

    this.showEvents.forEach((event) => {
      this.rootElement?.addEventListener(event, () =>
        this.show(rootElement, popperElement)
      );
    });

    this.hideEvents.forEach((event) => {
      this.rootElement?.addEventListener(event, () =>
        this.hide(rootElement, popperElement)
      );
    });
  }

  changeRootElement(
    rootElement: Element,
    popperElement: HTMLElement,
    textValue: string,
    placement?: Placement
  ) {
    this.rootElement = rootElement;
    this.popperElement = popperElement;
    this.removeEventListener(this.rootElement, this.popperElement);
    this.initPopper(rootElement, popperElement, textValue, placement);
  }

  private removeEventListener(
    rootElement: Element,
    popperElement: HTMLElement
  ) {
    this.showEvents.forEach((event) => {
      this.rootElement?.removeEventListener(event, () =>
        this.show(rootElement, popperElement)
      );
    });

    this.hideEvents.forEach((event) => {
      this.rootElement?.removeEventListener(event, () =>
        this.hide(rootElement, popperElement)
      );
    });
  }

  show(rootElement: Element, popperElement: HTMLElement) {
    this.rootElement = rootElement;
    this.popperElement = popperElement;

    this.popperElement?.setAttribute('data-show', '');

    if (this.popperElement.style) {
      this.popperElement.style.display = 'block';
    }

    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...(options.modifiers as []),
        { name: 'eventListeners', enabled: true },
      ],
    }));

    this.popperInstance.update();
  }

  hide(rootElement: Element, popperElement: HTMLElement) {
    this.rootElement = rootElement;
    this.popperElement = popperElement;

    this.popperElement?.removeAttribute('data-show');

    if (this.popperElement.style) {
      this.popperElement.style.display = 'none';
    }

    this.popperInstance?.setOptions((options) => ({
      ...options,
      modifiers: [
        ...(options.modifiers as []),
        { name: 'eventListeners', enabled: false },
      ],
    }));
  }

  hideCurrentRoot = () => {
    if (this.rootElement && this.popperElement) {
      this.hide(this.rootElement, this.popperElement);
    }
  };
}
