import { MutableRefObject, RefObject, useMemo } from 'react';
import DataModelRegistry from './DataModelRegistry';
import { Subject } from 'rxjs';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import { DropdownOptionType } from '../../dataModel/columnsAPI';
import { FieldValue } from '../../value';
import { HotTableClass } from '@handsontable/react';

type UseCustomOptionProps = {
  dataModelRegistry: DataModelRegistry;
  setDropdownOptionValue: ({
    row,
    col,
    value,
  }: {
    row: number;
    col: number;
    value: FieldValue;
  }) => void;
  editRow: MutableRefObject<number>;
  editCol: MutableRefObject<number>;
  hotInstance: RefObject<HotTableClass>;
};

export type CustomOptionUIObservable =
  | {
      isOpen: true;
      dataModel: CategoryDataModel;
    }
  | {
      isOpen: false;
    };

const useCustomOption = ({
  dataModelRegistry,
  editRow,
  editCol,
  setDropdownOptionValue,
  hotInstance,
}: UseCustomOptionProps) => {
  const customOptionUIObservable = useMemo(() => {
    return new Subject<CustomOptionUIObservable>();
  }, []);

  const addOption = (
    dataModel: CategoryDataModel,
    optionName: string,
    optionType: DropdownOptionType
  ) => {
    dataModelRegistry.addOption(dataModel, optionName, optionType);
    setDropdownOptionValue({
      row: editRow.current,
      col: editCol.current,
      value: optionName.toString().length ? optionName : null,
    });

    hotInstance.current?.hotInstance?.selectCell(
      editRow.current,
      editCol.current
    );
  };

  return { addOption, customOptionUIObservable };
};

export default useCustomOption;
