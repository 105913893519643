import MenuButton from './MenuButton';
import MenuItems from './MenuItems';
import Portal from 'baseUI/Portal';
import { useDropdownBoundary } from '../../../../common/dropdownBoundary';
import { useMainView } from '../../../../../../../settings/MainView';
import { DropdownProps } from './types';

export const Dropdown = ({
  options,
  configThemeMenuButton,
  configThemeMenuItem,
  hasScrollbar,
  boundary,
  onCreateOptions,
  popper,
  shownValue,
  isOpenDropdown,
  isEmpty,
  menuRef,
  onClear,
  getToggleButtonProps,
  onMenuButtonKeyDown,
  getMenuProps,
  onMenuKeyDown,
  getItemProps,
  highlightedIndex,
  selectedItem,
  searchOptions,
  onSearchOption,
  isShowSearch,
  isSearching,
  searchValue,
  isShowCustomOptions,
  setHighlightedIndex,
  toggleDropdown,
  isMultiSelect,
}: DropdownProps) => {
  const { pageWrapperElement } = useDropdownBoundary();
  const { modal } = useMainView();

  return (
    <div className="w-full">
      <div ref={popper.referenceElement}>
        <MenuButton
          configThemeMenuButton={configThemeMenuButton}
          shownValue={shownValue}
          open={isOpenDropdown}
          isEmpty={isEmpty}
          ref={menuRef}
          onClear={onClear}
          isShowCustomOptions={isShowCustomOptions}
          {...getToggleButtonProps({
            onKeyDown: onMenuButtonKeyDown,
          })}
        />
      </div>
      {isOpenDropdown ? (
        <Portal enable={modal} root={pageWrapperElement}>
          <div
            {...getMenuProps({
              onKeyDown: onMenuKeyDown,
            })}
          >
            <MenuItems
              configThemeMenuItem={configThemeMenuItem}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              options={options}
              popper={popper}
              selectedItem={selectedItem}
              hasScrollbar={hasScrollbar}
              rootElement={boundary}
              searchOptions={searchOptions}
              setSearchValue={onSearchOption}
              isShowSearch={isShowSearch}
              isSearching={isSearching}
              searchValue={searchValue}
              isShowCustomOptions={isShowCustomOptions}
              onCreateOptions={onCreateOptions}
              setHighlightedIndex={setHighlightedIndex}
              toggleDropdown={toggleDropdown}
              isMultiSelect={isMultiSelect}
            />
          </div>
        </Portal>
      ) : (
        <div className="hidden" {...getMenuProps()} />
      )}
    </div>
  );
};

export default Dropdown;
