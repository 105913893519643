import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IConfigureForm } from '../../types';
import { Toast } from '@getnuvo/ui-kit';
import { ConfigureAPI } from '../../services';

export const useViewModel = () => {
  const { watch } = useFormContext<IConfigureForm>();
  const licenseKey = watch('licenseKey');

  const [clearMappingLoading, setClearMappingLoading] =
    useState<boolean>(false);

  const clearRememberedMapping = useCallback(() => {
    if (!licenseKey) {
      Toast.error({
        title: 'You need to provide the license key.',
      });
      return;
    }

    setClearMappingLoading(true);

    ConfigureAPI.clearRememberedMapping(licenseKey).finally(() => {
      setClearMappingLoading(false);
    });
  }, [licenseKey]);

  return {
    clearMappingLoading,
    clearRememberedMapping,
  };
};
