import { useClickAway } from 'react-use';
import usePopper from './popper';
import { useEffect, useState } from 'react';
import ContextMenuController from '../../ContextMenu/controller/ContextMenuController';

const useViewModel = ({
  contextMenuController,
}: {
  contextMenuController: ContextMenuController;
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { popper } = usePopper({ isOpenMenu });
  const [waitingConfirmReplace, setWaitingConfirmReplace] = useState(false);

  useClickAway(popper.popperElement, (event) => {
    if (
      popper.referenceElement.current?.contains(
        event.target as HTMLDivElement
      ) ||
      waitingConfirmReplace
    ) {
      return;
    }
    setIsOpenMenu(false);
  });

  useEffect(() => {
    const subscription = contextMenuController
      .contextMenuObservable()
      .subscribe(() => {
        setIsOpenMenu(false);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [contextMenuController]);

  return {
    popper,
    isOpenMenu,
    setIsOpenMenu,
    setWaitingConfirmReplace,
  };
};

export default useViewModel;
