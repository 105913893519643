import { SortOrderType } from 'handsontable/plugins/columnSorting';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { GridSettings } from 'handsontable/settings';
import { compareFunctionFactory as defaultCompareFunctionFactory } from './default';
import { compareFunctionFactory as booleanCompareFunctionFactory } from './boolean';
import { compareFunctionFactory as categoryCompareFunctionFactory } from './category';
import { compareFunctionFactory as currencyCompareFunctionFactory } from './currency';
import { compareFunctionFactory as percentageCompareFunctionFactory } from './percentage';
import { compareFunctionFactory as dateCompareFunctionFactory } from './date';
import { compareFunctionFactory as numberCompareFunctionFactory } from './number';
import { DATATYPE } from '../../../../dataType';

const compareFunctionFactory = (
  sortOrder: SortOrderType,
  columnMeta: GridSettings,
  dataModel: DataModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findDropdownOption: (value: any) => any
) => {
  switch (dataModel.getType()) {
    case DATATYPE.SINGLE_SELECT:
    case DATATYPE.MULTIPLE_SELECT:
      return categoryCompareFunctionFactory(
        sortOrder,
        columnMeta,
        findDropdownOption,
        dataModel.getIsMultiSelection()
      );

    case DATATYPE.BOOLEAN:
      return booleanCompareFunctionFactory(sortOrder, columnMeta);
    case DATATYPE.CURRENCY_EUR:
    case DATATYPE.CURRENCY_USD:
      return currencyCompareFunctionFactory(sortOrder, columnMeta);
    case DATATYPE.PERCENTAGE:
      return percentageCompareFunctionFactory(sortOrder, columnMeta);
    // NOTE: other date column types (DATE_DMY, DATE_MDY, etc.) are deprecated, so we will just sort as normal string
    case DATATYPE.DATE:
      return dateCompareFunctionFactory(
        sortOrder,
        columnMeta,
        dataModel.getOutputFormat()
      );
    case DATATYPE.INTEGER:
    case DATATYPE.FLOAT:
      return numberCompareFunctionFactory(sortOrder, columnMeta);
    default:
      return defaultCompareFunctionFactory(sortOrder, columnMeta);
  }
};

export default compareFunctionFactory;
