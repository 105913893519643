interface IBooleanOption {
  label: string;
  value: 'true' | 'false';
}

export class BooleanSingleton {
  static trueValue = 'true';
  static falseValue = 'false';
  private static instance: BooleanSingleton;
  private options: IBooleanOption[] = [];
  private truthyWords: (string | boolean | number)[] = [];
  private falsyWords: (string | boolean | number)[] = [];
  private defaultTruthyWords = ['true', true, 1, '1', 'yes', 'ja'];
  private defaultFalsyWords = ['false', false, 0, '0', 'no', 'nein'];

  private constructor() {
    this.truthyWords = this.defaultTruthyWords;
    this.falsyWords = this.defaultFalsyWords;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new BooleanSingleton();
    }

    return this.instance;
  }

  setOptions(options: IBooleanOption[]) {
    this.options = options;
  }

  getOption() {
    return this.options.length === 0
      ? [
          { label: 'yes', value: BooleanSingleton.trueValue },
          { label: 'no', value: BooleanSingleton.falseValue },
        ]
      : this.options;
  }

  setTruthyWords(word: string) {
    this.truthyWords = [...this.truthyWords, word?.toLowerCase(), word];
    this.truthyWords = Array.from(new Set(this.truthyWords));
  }

  setFalsyWords(word: string) {
    this.falsyWords = [...this.falsyWords, word?.toLowerCase(), word];
    this.falsyWords = Array.from(new Set(this.falsyWords));
  }

  getTruthyWords() {
    return this.truthyWords?.filter((item) => `${item}`.length > 0);
  }

  getFalsyWords() {
    return this.falsyWords?.filter((item) => `${item}`.length > 0);
  }

  getBooleanOptionToRender = () => {
    return [
      this.defaultTruthyWords.length === this.truthyWords.length
        ? 'Ja'
        : this.truthyWords[this.truthyWords.length - 1],
      this.defaultFalsyWords.length === this.falsyWords.length
        ? 'Nein'
        : this.falsyWords[this.falsyWords.length - 1],
    ];
  };
}
