import MenuButton from './MenuButton';
import MenuItems from './MenuItems';
import Portal from '../Portal';
import useViewModel, { IOnChange, IOption, IValue } from './viewModel';
import {
  CSSInterpolation,
  CSSObject,
  css,
  useTheme,
} from '@nuvo-importer/common';
import { ColumnDropdownThemeAPI } from '@nuvo-importer/common/sdk';

export type IColumnDropdownProps = {
  value: IValue;
  options: IOption[];
  rootDropdownPortal?: HTMLDivElement;
  menuTitle: string;
  onChange: IOnChange;
  placeholder?: string;
  wrapperClassName?: string;
  isShowSheetName?: boolean;
  disabled?: boolean;
  isShowError?: boolean;
  joinPopoverTheme?: CSSInterpolation;
  theme?: ColumnDropdownThemeAPI;
};

const ColumnDropdown = ({
  value,
  options,
  rootDropdownPortal,
  menuTitle,
  onChange,
  placeholder,
  wrapperClassName,
  isShowSheetName = true,
  disabled,
  isShowError,
  joinPopoverTheme,
  theme,
}: IColumnDropdownProps) => {
  const {
    getToggleButtonProps,
    searchRef,
    onMenuButtonKeyDown,
    menuButtonRef,
    onMenuKeyDown,
    isOpen,
    highlightedIndex,
    selectedItem,
    getItemProps,
    searchValue,
    searchOptions,
    isSearching,
    getMenuProps,
    menuRef,
    onSearchValue,
    shownValue,
    onClear,
    popper,
    isEmpty,
  } = useViewModel({ value, options, onChange, disabled });
  const globalTheme = useTheme();

  return (
    <div ref={popper.referenceElement} className={wrapperClassName}>
      <MenuButton
        shownValue={shownValue}
        open={isOpen}
        isEmpty={isEmpty}
        onClear={onClear}
        placeholder={placeholder}
        getToggleButtonProps={getToggleButtonProps}
        ref={menuButtonRef}
        onKeyDown={onMenuButtonKeyDown}
        disabled={disabled}
        isShowError={isShowError}
        theme={theme}
      />
      {isOpen ? (
        <Portal enable={true} root={rootDropdownPortal}>
          <div
            {...getMenuProps(
              {
                onKeyDown: onMenuKeyDown,
                ref: menuRef,
              },
              { suppressRefError: true }
            )}
          >
            <MenuItems
              menuTitle={menuTitle}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              options={options}
              searchOptions={searchOptions}
              searchValue={searchValue}
              searchRef={searchRef}
              popper={popper}
              selectedItem={selectedItem}
              isShowSearch
              onSearchValue={onSearchValue}
              isSearching={isSearching}
              isShowSheetName={isShowSheetName}
              joinPopoverTheme={joinPopoverTheme}
              customTheme={theme}
              configThemePopoverInfo={{
                root: css({ '&&': globalTheme.getPopoverInfo() }),
                arrowIcon: (globalTheme.getPopoverInfo() as CSSObject)
                  ?.backgroundColor
                  ? css(
                      (globalTheme.getPopoverInfo() as CSSObject)
                        ?.backgroundColor
                    )
                  : undefined,
              }}
            />
          </div>
        </Portal>
      ) : (
        <div className="hidden" {...getMenuProps()} />
      )}
    </div>
  );
};

export default ColumnDropdown;
