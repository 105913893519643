import { createContext, useContext, useState } from 'react';

export const CollapseContext = createContext({
  isShow: false,
  isCollapse: false,
});

export const useCollapseProvider = () => {
  const [isShow, setIsShow] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  return { isShow, setIsShow, isCollapse, setIsCollapse };
};

export const useCollapseContext = () => {
  const context = useContext(CollapseContext);

  return context;
};
