import { MouseEvent, forwardRef, useMemo } from 'react';
import { Eye, Plus, Trash2, Minus } from 'lucide-react';
import { cx } from '@nuvo-importer/common';

type IIconButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  iconClassName?: string;
  size?: number;
  icon: IIconButton;
  className?: string;
  disabled?: boolean;
};

export enum IIconButton {
  EYE = 'EYE',
  PLUS = 'PLUS',
  DELETE = 'DELETE',
  MINUS = 'MINUS',
}

const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  (
    { onClick, iconClassName, icon: iconProp, size = 16, className, disabled },
    ref
  ) => {
    const icon = useMemo(() => {
      switch (iconProp) {
        case IIconButton.EYE:
          return <Eye className={iconClassName} size={size} />;
        case IIconButton.PLUS:
          return <Plus className={iconClassName} size={size} />;
        case IIconButton.DELETE:
          return <Trash2 className={iconClassName} size={size} />;
        case IIconButton.MINUS:
          return <Minus className={iconClassName} size={size} />;
      }
    }, [iconProp, size, iconClassName]);

    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        className={cx(
          'hover:bg-blue-dark-50 rounded-medium inline-flex cursor-pointer items-center justify-center p-1 disabled:cursor-not-allowed disabled:opacity-70',
          className
        )}
        onClick={onClick}
      >
        {icon}
      </button>
    );
  }
);

export default IconButton;
