import { RefObject } from 'react';
import { Validator } from '../../reviewEntries/validator';
import { NewRowChange, RecordInfo } from './type';
import { HotTableClass } from '@handsontable/react';
import CheckboxController from './checkbox/CheckboxController';
import AllColumnSetting from './columns/AllColumnSetting';
import ModeViewTable from './ModeViewTable/ModeViewTable';
import DataModelRegistry from './DataModelRegistry';
import isPromise from 'is-promise';
import { OnEntryChange } from '../../hooks/hooksAPI';

type UseDuplicateProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  validator: Validator;
  dataInfos: Record<string, RecordInfo[]>;
  hotInstance: RefObject<HotTableClass>;
  updateTotalError: () => void;
  checkboxController?: CheckboxController;
  allColumnSetting: AllColumnSetting;
  modeViewTable: ModeViewTable;
  onAfterDuplicate?: () => void;
  dataModelRegistry: DataModelRegistry;
  handleAfterNewRow: (newRows: NewRowChange[]) => Promise<void> | undefined;
  onEntryChange?: OnEntryChange;
};

const useDuplicate = ({
  dataSet,
  validator,
  dataInfos,
  hotInstance,
  updateTotalError,
  checkboxController,
  allColumnSetting,
  modeViewTable,
  onAfterDuplicate,
  dataModelRegistry,
  handleAfterNewRow,
  onEntryChange,
}: UseDuplicateProps) => {
  const duplicateDataSet = (rows: number[]) => {
    const tempDataSets = [...dataSet];
    const updateLength = dataSet.length - 1 + rows.length;

    let j = 0;
    let k = 0;
    let rowIndex;
    for (rowIndex = 0; rowIndex < updateLength; rowIndex++) {
      const dupRow = rows[j];
      dataSet[rowIndex] = { ...tempDataSets[k] };
      if (dupRow === k) {
        j++;
      } else {
        k++;
      }
    }

    dataSet[rowIndex] = tempDataSets[tempDataSets.length - 1];
  };

  const duplicateError = (rows: number[]) => {
    const errors = validator.getError();
    const tempErrors = [...errors];

    const updateLength = errors.length + rows.length;

    let j = 0;
    let k = 0;
    for (let rowIndex = 0; rowIndex < updateLength; rowIndex++) {
      const dupRow = rows[j];

      if (dupRow === k) {
        j++;
        errors[rowIndex] = tempErrors[k] ? [...tempErrors[k]] : [];
      } else {
        errors[rowIndex] = tempErrors[k];
        k++;
      }

      for (let i = 0; i < errors[rowIndex]?.length ?? 0; ++i) {
        const errorCell = errors[rowIndex][i];
        if (errorCell) {
          errors[rowIndex][i] = { ...errorCell, rowIndex };
        }
      }
    }
  };

  const duplicateDataInfos = (rows: number[], originalDataLength: number) => {
    const tempDataInfos = { ...dataInfos };
    const updateLength = originalDataLength + rows.length;

    let j = 0;
    let k = 0;
    for (let rowIndex = 0; rowIndex < updateLength; ++rowIndex) {
      const dupRow = rows[j];
      if (dupRow === k) {
        j++;
        dataInfos[rowIndex] = tempDataInfos[k] ? [...tempDataInfos[k]] : [];
      } else {
        dataInfos[rowIndex] = tempDataInfos[k];
        k++;
      }

      for (let i = 0; i < (dataInfos[`${rowIndex}`]?.length ?? 0); ++i) {
        const dataInfoCell = dataInfos[`${rowIndex}`][i];
        if (dataInfoCell) {
          dataInfos[`${rowIndex}`][i] = { ...dataInfoCell, rowIndex };
        }
      }
    }
  };

  const onDuplicate = (rows: number[]) => {
    const columns = dataModelRegistry.getColumns();
    const sortedRows = rows
      .map((row) => {
        return hotInstance.current?.hotInstance?.toPhysicalRow(row) ?? row;
      })
      .sort((a, b) => a - b);
    const originalDataLength = dataSet.length - 1;
    duplicateDataSet(sortedRows);
    duplicateError(sortedRows);
    duplicateDataInfos(sortedRows, originalDataLength);
    validator.generateUniqueListFromDataSet(dataSet, columns);
    const duplicatedRows: NewRowChange[] = [];

    for (let i = 0; i < sortedRows.length; i++) {
      const copyRowIndex = sortedRows[i];
      const newRowIndex = copyRowIndex + i + 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRowData: any[] = [];
      columns.forEach((col, colIndex) => {
        currentRowData.push(dataSet[newRowIndex][col.key]);
        if (
          col.validations?.find(
            (validation) => validation.validate === 'unique'
          ) &&
          !col.isMultiSelect
        ) {
          const { error } = validator.validateCell(col, columns, {
            value: dataSet[newRowIndex][col.key],
            key: col.key,
            row: Object.keys(dataSet[newRowIndex]).map((key) => ({
              rowIndex: newRowIndex,
              key: key,
              value: dataSet[newRowIndex][key],
            })),
          });
          validator.setError(error, colIndex, newRowIndex);
        }
      });
      duplicatedRows.push({
        actionType: 'create',
        changeLog: {},
        currentRowData,
        rowIndex: newRowIndex,
      });
    }

    checkboxController?.clearCheckedMaps();

    hotInstance.current?.hotInstance?.updateData(dataSet);

    if (allColumnSetting.hasFilter()) {
      allColumnSetting.getFilterStrategy().recalculate();
    }

    const sortingColumnIndex = allColumnSetting.getSortingColumnIndex();

    if (sortingColumnIndex > -1) {
      const columnSetting =
        allColumnSetting.getColumnSetting(sortingColumnIndex);
      allColumnSetting
        .getSortStrategy()
        .sortColumn(sortingColumnIndex, columnSetting.sort);
    }
    let afterDuplicate;
    if (onEntryChange) {
      afterDuplicate = handleAfterNewRow(duplicatedRows);
    } else {
      updateTotalError();
    }

    const handleRender = () => {
      modeViewTable.recalculate();
      hotInstance.current?.hotInstance?.render();
      onAfterDuplicate?.();
    };

    if (isPromise(afterDuplicate)) {
      afterDuplicate.then(() => {
        handleRender();
      });
    } else {
      handleRender();
    }
  };

  return { onDuplicate };
};

export default useDuplicate;
