import { NumberFormat } from '../../dataModel/columnsAPI';
import { NumberParser } from '../../utils/NumberParser';
import BaseNumberValueParser from './BaseNumberValueParser';
import { PossibleValue } from './IValueParser';

class FloatNumberValueParser extends BaseNumberValueParser {
  valueParser = (value: PossibleValue) => {
    const universalDecimal = `${value}`.replace(',', '.');
    const result = NumberParser.convertStringToNumber(universalDecimal, {
      format: NumberFormat.US,
    });

    if (result === null) {
      return `${value}`;
    }

    return Number(result);
  };
}

export default FloatNumberValueParser;
