import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  FormElement,
  Toast,
} from '@getnuvo/ui-kit';
import { FormProvider, useForm } from 'react-hook-form';
import { InputControl } from '../controls/InputControl';
import { TextareaControl } from '../controls/TextareaControl';
import { IEditTDM, ITargetDataModel } from '../../types';
import { usePreviewConfig } from '../../../../context/PreviewConfigProvider';
import { findTDM, parseJSON } from '../../utils';
import { ConfigureAPI } from '../../services';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';

interface IProps {
  open: boolean;
  onDismiss: () => void;
  defaultValues: IEditTDM;
  setCurrentTDM: (tdm: ITargetDataModel) => void;
}

export const TDMModal: FC<IProps> = ({
  open,
  onDismiss,
  defaultValues,
  setCurrentTDM,
}: IProps) => {
  const form = useForm<IEditTDM>({
    defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });
  const { dataModels, setDataModels } = usePreviewConfig();
  const [processing, setProcessing] = useState<boolean>(false);

  const { handleSubmit, formState } = form;

  const onSubmit = handleSubmit(
    (data: IEditTDM) => {
      if (!dataModels) {
        Toast.error({
          title: 'No data models found',
        });
      } else {
        setProcessing(true);
        const nextDataModels: ITargetDataModel[] = [...dataModels];
        let nextId = '';

        if (defaultValues.value === '') {
          const id = data.label.toLowerCase().split(' ').join('_');
          nextDataModels.push({
            value: id,
            label: data.label,
            config: parseJSON(data.config),
          } as ITargetDataModel);
          nextId = id;
        } else {
          const index = nextDataModels.findIndex(
            (m: ITargetDataModel) => m.value === defaultValues.value
          );

          if (index < 0) {
            Toast.error({ title: 'Could not find target data model' });
            return;
          }

          nextDataModels[index] = {
            ...nextDataModels[index],
            label: data.label,
            config: parseJSON(data.config),
          };
          nextId = data.value;
        }

        ConfigureAPI.saveTDMs(nextDataModels)
          .then((dataModels: ITargetDataModel[]) => {
            setDataModels(dataModels);
            const nextTDM: ITargetDataModel | undefined = findTDM(
              dataModels,
              nextId
            );
            if (nextTDM) {
              setCurrentTDM(nextTDM);
            }
            setProcessing(false);
            onDismiss();
          })
          .catch(() => {
            setProcessing(false);
          });
      }
    },
    (errors) => {
      console.log(errors);
    }
  );

  return (
    <FormProvider {...form}>
      <Dialog open={open} onDismiss={onDismiss} outsidePress={false}>
        <DialogHeader title="Target Data Model" />
        <DialogBody>
          <div className="nuvo-sdk__tdm-form">
            <div className="nuvo-sdk__form-block">
              <FormElement
                label="Title"
                message={{
                  text: formState?.errors?.label?.message || '',
                  variant: 'error',
                }}
              >
                <InputControl placeholder="Enter TDM name" name="label" />
              </FormElement>

              <div className="nuvo-sdk__codeArea">
                <FormElement
                  label="Config (JSON)"
                  message={{
                    text: formState?.errors?.config?.message || '',
                    variant: 'error',
                  }}
                >
                  <TextareaControl placeholder="{}" rows={10} name="config" />
                </FormElement>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="outlineSecondary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={onSubmit} loading={processing}>
            Save
          </Button>
        </DialogFooter>
      </Dialog>
    </FormProvider>
  );
};
