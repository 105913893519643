/* istanbul ignore file */

class Similarity {
  private similarity: number;
  constructor({ similarity }: { similarity: number }) {
    this.similarity = similarity;
  }

  getSimilarityAsPercent = () => {
    return `${Math.min(Math.round(this.similarity * 100), 100)}%`;
  };

  getSimilarity = () => {
    return this.similarity;
  };
}

export default Similarity;
