import { DataModel } from 'dataModel';
import { useMemo } from 'react';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import useEmptyState from '../common/emptyState';
import useRowExamples from '../common/rowExamples';
import { NumberToAlphabet } from '@nuvo-importer/common/sdk';

const numberToAlphabet = new NumberToAlphabet();

const useViewModel = ({
  matchedDataModel,
  sheetColumn,
  prefixName,
  isCollapse,
  index,
}: {
  matchedDataModel?: DataModel;
  sheetColumn: SheetColumn;
  prefixName: string;
  isCollapse: boolean;
  index: number;
}) => {
  const { rows: rowExamples } = useRowExamples({
    sheetColumn,
  });
  const { isPreviewDataEmpty } = useEmptyState({
    prefixName,
    sheetColumn,
    matchedDataModel,
  });

  const isBorderBottomRounded = useMemo(() => {
    if (isCollapse) {
      return true;
    }
    return isPreviewDataEmpty;
  }, [isPreviewDataEmpty, isCollapse]);

  const columnName = numberToAlphabet.numberToString(index + 1).toUpperCase();

  return { isBorderBottomRounded, rowExamples, columnName };
};

export default useViewModel;
