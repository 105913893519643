import { useTranslation } from 'react-i18next';
import AllColumnSetting from '../../columns/AllColumnSetting';
import ContextMenuItem from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import ContextMenuController from '../controller/ContextMenuController';
import useViewModel from './viewModel';
import { ConfigTheme } from '../../type';

type FreezeMenuItemProps = {
  freeze: boolean;
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  currentColumnIndex: number;
  configTheme?: ConfigTheme;
};

const FreezeMenuItem = ({
  freeze,
  allColumnSetting,
  contextMenuController,
  currentColumnIndex,
  configTheme,
}: FreezeMenuItemProps) => {
  const { onMenuClick } = useViewModel({
    allColumnSetting,
    contextMenuController,
    freeze,
    currentColumnIndex,
  });
  const { t } = useTranslation();

  return (
    <ContextMenuItem
      icon={ContextMenuIcon.FREEZE}
      text={freeze ? t('txt_unfreeze_column') : t('txt_freeze_column')}
      onClick={onMenuClick}
      disabled={!freeze && allColumnSetting.hasMaxFreezeColumn()}
      showInfo={!freeze && allColumnSetting.hasMaxFreezeColumn()}
      infoMessage={t('txt_freeze_column_maximum_info')}
      configTheme={configTheme}
    />
  );
};

export default FreezeMenuItem;
