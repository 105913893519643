import {
  ColumnMapping,
  JoinSheet,
  JoinType,
  SHEET_TYPE,
  Sheet,
} from '@nuvo-importer/common/sdk';
import { ICombineSheetsStrategy } from './combineSheetsStrategy';
import { convertColumnMappingToIndex } from './utils';
import { IMatchingValuesWorker } from '../../reviewEntries/worker/type';

export const createJoinSheet = async (
  sourceSheet: Sheet,
  targetSheet: Sheet,
  mappings: ColumnMapping[],
  combineStrategy: ICombineSheetsStrategy
) => {
  const mappingIndexes = convertColumnMappingToIndex(
    mappings,
    sourceSheet,
    targetSheet
  );
  const result = await combineStrategy(
    sourceSheet.toJSON(),
    targetSheet.toJSON(),
    mappingIndexes
  );
  let joinSheet;
  if (sourceSheet.getType() === SHEET_TYPE.JOIN) {
    (sourceSheet as JoinSheet).joinSheet(
      result,
      targetSheet,
      mappings,
      mappingIndexes
    );
    joinSheet = sourceSheet;
  } else {
    joinSheet = new JoinSheet({
      joinedData: result,
      mappings,
      mappingIndexes,
      sheets: [sourceSheet, targetSheet],
    });
  }

  return joinSheet;
};

export const removeJoinSheet = async (
  removeSheet: Sheet,
  sourceSheetParam: Sheet,
  combineStrategies: Omit<IMatchingValuesWorker, 'getValues' | 'countMatching'>
) => {
  if (sourceSheetParam.getType() === SHEET_TYPE.NORMAL) {
    return null;
  }

  const sourceSheet = sourceSheetParam as JoinSheet;
  const joinedSheets = sourceSheet.getJoinedSheets();
  const removeIndex = joinedSheets.findIndex((sheet) =>
    sheet.equal(removeSheet)
  );
  if (removeIndex < 0) {
    return sourceSheetParam;
  }
  const joinHistory = sourceSheet.getJoinHistory();
  const updatedJoinedSheets = joinedSheets.filter(
    (_sheet, index) => index < removeIndex
  );
  const updatedJoinHistory = joinHistory.filter((_history, index) => {
    return index < removeIndex - 1;
  });

  if (updatedJoinHistory.length === 0) {
    return updatedJoinedSheets[0] ?? null;
  }

  let updatedSourceSheet: Sheet = updatedJoinedSheets[0];
  for (let i = 0; i < updatedJoinHistory.length; ++i) {
    const updatedJoinSheet = updatedJoinedSheets[i + 1];
    const sourceSheet = updatedSourceSheet;
    const mappingColumns = updatedJoinHistory[i].mappingIndexes.map(
      (mapping) => {
        return {
          source: sourceSheet.getColumns()[mapping.source],
          target: updatedJoinSheet.getColumns()[mapping.target],
        };
      }
    );
    updatedSourceSheet = await createJoinSheet(
      updatedSourceSheet,
      updatedJoinSheet,
      mappingColumns,
      getCombineStrategy(updatedJoinHistory[i].type, combineStrategies)
    );
  }

  return updatedSourceSheet;
};

const getCombineStrategy = (
  joinType: JoinType,
  combineStrategies: Omit<IMatchingValuesWorker, 'getValues' | 'countMatching'>
) => {
  const mapping = {
    [JoinType.ADD_TO_ALL_ROWS]: combineStrategies.addToAllRows,
    [JoinType.APPEND_TO_COLUMNS]: combineStrategies.appendToColumns,
    [JoinType.JOIN_ON_COLUMNS]: combineStrategies.joinOnColumns,
    [JoinType.JOIN_WITHOUT_COLUMNS]: combineStrategies.joinWithoutColumns,
  };

  return mapping[joinType];
};
