import { useMemo } from 'react';
import { cx, css } from 'core/emotion';
import { useVariant } from './variant';
import { useTheme } from 'theme';

export const useStyle = ({ open }: { open: boolean; classname?: string }) => {
  const theme = useTheme();
  const hoverBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark600Color();
  }, [theme]);
  const { getVariantClassname, getOpenClassname } = useVariant({
    open,
  });

  const menuButtonClassname = cx(
    getVariantClassname(),
    getOpenClassname(),
    cx(
      'text-sm text-gray-310 w-full h-8.5 py-2 px-3 pr-12 relative inline-flex items-center border rounded-medium disabled:opacity-80 outline-none bg-white truncate',
      css({
        ':hover': {
          borderColor: hoverBorderColor,
        },
      })
    )
  );

  return {
    menuButtonClassname,
  };
};
