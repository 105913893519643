import ThemeAPIMapper from './ThemeAPIMapper';

class ThemeCssVariable {
  private themeMapper: ThemeAPIMapper;
  constructor(themeMapper: ThemeAPIMapper) {
    this.themeMapper = themeMapper;
  }

  private getRootElement = () => {
    const root = document.documentElement;
    return root;
  };

  private setCssProperty = (property: string, value: string) => {
    const root = this.getRootElement();

    root.style.setProperty(property, value);
  };

  private setupGlobal = () => {
    const globalTheme = this.themeMapper.getGlobalTheme();

    this.setCssProperty('--globals-font-family', globalTheme.getFontFamily());
    this.setCssProperty(
      '--text-color-primary',
      globalTheme.getPrimaryTextColor()
    );
    this.setCssProperty(
      '--text-color-secondary',
      globalTheme.getSecondaryTextColor()
    );
    this.setCssProperty(
      '--globals-background-color',
      globalTheme.getBackgroundColor()
    );
  };

  private setupToast = () => {
    const toastTheme = this.themeMapper.getGlobalTheme();

    this.setCssProperty(
      '--globals-toast-success-bg',
      toastTheme.getSuccessColor().lighterColor
    );
    this.setCssProperty(
      '--globals-toast-success-color',
      toastTheme.getSuccessColor().darkerColor
    );

    this.setCssProperty(
      '--globals-toast-warning-bg',
      toastTheme.getWarningColor().lighterColor
    );
    this.setCssProperty(
      '--globals-toast-warning-color',
      toastTheme.getWarningColor().darkerColor
    );
  };

  setup = () => {
    this.setupGlobal();
    this.setupToast();
  };
}

export default ThemeCssVariable;
