import { useContextCreateNewOptionModal } from './CreateNewOptionModalContext';
import useViewModel from './viewModel';
import {
  useTheme,
  CreateNewOptionModal as BaseCreateNewOptionModal,
} from '@nuvo-importer/common';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';
import { useMemo } from 'react';

type CreateNewOptionModalProps = {
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const CreateNewOptionModal = ({
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: CreateNewOptionModalProps) => {
  const { isOpen, close, dataModel } = useContextCreateNewOptionModal();
  const { initialValues, onSubmit } = useViewModel({
    isOpen,
    dataModelSheetMatcher,
    setDataModelSheetMatcher,
    setDataModelSheetMatching,
  });
  const theme = useTheme();
  const configureStyled = useMemo(() => {
    return theme.getColumnMatchTheme().dialogCreateCustomOption;
  }, [theme]);

  return (
    <BaseCreateNewOptionModal
      configureStyled={configureStyled}
      close={close}
      initialValues={initialValues}
      isOpen={isOpen}
      onSubmit={onSubmit}
      dataModel={dataModel}
    />
  );
};

export default CreateNewOptionModal;
