import TruncatePopover from '../../TruncatePopover';
import { css, CSSInterpolation, cx } from '../../../core/emotion';
import { RefObject } from 'react';
import Sheet from '../../../core/sheet/Sheet';
import useViewModel from './viewModel';

type SheetNameProps = {
  sheet: Sheet;
  configTheme?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  currentSelected: boolean;
  configThemeSheetNameSelected?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  hasMoreAllSheet: boolean;
  isCurrentOrAfter: boolean;
  goToSheet: (sheet: Sheet) => void;
  isConfirmed: boolean;
};

export const SheetName = ({
  sheet,
  configTheme,
  currentSelected,
  configThemeSheetNameSelected,
  hasMoreAllSheet,
  goToSheet,
  isCurrentOrAfter,
  isConfirmed,
}: SheetNameProps) => {
  const { sheetName, fileName, onClick, isClickAble } = useViewModel({
    sheet,
    hasMoreAllSheet,
    goToSheet,
    isCurrentOrAfter,
  });

  return (
    <div
      onClick={onClick}
      className={cx(
        'w-fit rounded-tl-medium rounded-medium relative pl-4',
        currentSelected ? 'current' : 'previous',
        hasMoreAllSheet
          ? `max-w-204 py-3 ${
              currentSelected
                ? 'bg-blue-50'
                : !isConfirmed
                ? 'bg-gray-50'
                : 'bg-blue-light-50'
            } min-w-204`
          : 'min-w-auto bg-white pb-0.5',
        currentSelected && hasMoreAllSheet
          ? css({ '&&': configThemeSheetNameSelected?.root })
          : css({ '&&': configTheme?.root }),
        hasMoreAllSheet
          ? isClickAble
            ? 'cursor-pointer hover:bg-blue-50'
            : 'cursor-not-allowed'
          : 'cursor-auto'
      )}
    >
      <div
        className={cx(
          'rounded-tl-medium rounded-bl-medium absolute left-0 top-0 h-full w-1',
          currentSelected ? 'bg-blue-light-500' : '',
          currentSelected && hasMoreAllSheet
            ? css({ '&&': configThemeSheetNameSelected?.border })
            : css({ '&&': configTheme?.border })
        )}
      />
      <TruncatePopover
        pageWrapperElement={document.body}
        detectChangeText={fileName}
        render={({ referenceElement, textRef, getHover }) => {
          return (
            <div
              className={cx('flex w-full items-center justify-between')}
              ref={referenceElement as RefObject<HTMLDivElement>}
              {...getHover()}
            >
              <span
                ref={textRef}
                className={cx(
                  'text-color-primary text-left font-medium',
                  hasMoreAllSheet
                    ? 'mb-1 truncate pr-4 text-base'
                    : '-mt-0.5 text-xl',
                  currentSelected && hasMoreAllSheet
                    ? css({ '&&': configThemeSheetNameSelected?.title })
                    : css({ '&&': configTheme?.title })
                )}
              >
                {sheetName}
              </span>
            </div>
          );
        }}
      />
      <TruncatePopover
        pageWrapperElement={document.body}
        detectChangeText={fileName}
        render={({ referenceElement, textRef, getHover }) => {
          return (
            <div
              className={cx('flex w-full items-center justify-between')}
              ref={referenceElement as RefObject<HTMLDivElement>}
              {...getHover()}
            >
              <span
                ref={textRef}
                className={cx(
                  'text-gray-310 truncate text-left text-base opacity-50',
                  hasMoreAllSheet ? 'mt-0 pr-4' : '-mt-0.5',
                  currentSelected && hasMoreAllSheet
                    ? css({ '&&': configThemeSheetNameSelected?.description })
                    : css({ '&&': configTheme?.description })
                )}
              >
                {fileName}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};
