import { isObject } from 'lodash';

const CellErrorType = 'CellError';

class CellError {
  public rowIndex: number;
  public errorMessage: string;
  public type = CellErrorType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isCellError = (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isObject(value) && (value as any).type === CellErrorType) {
      return true;
    } else {
      return false;
    }
  };

  constructor({
    rowIndex,
    errorMessage,
  }: {
    rowIndex: number;
    errorMessage: string;
  }) {
    this.rowIndex = rowIndex;
    this.errorMessage = errorMessage;
  }

  public value = 'Formula Error';
}

export default CellError;
