import SpreadSheetList from '../../../sheetImporter/SpreadSheetList';
import useBaseViewModel from '../../../sheetImporter/FileImportBanner/viewModel';
import { useState } from 'react';

const useViewModel = ({
  onFileSelectedSuccess,
}: {
  onFileSelectedSuccess: (spreadSheetList: SpreadSheetList) => void;
}) => {
  const [processing, setProcessing] = useState(false);

  const { handleSuccess, handleError } = useBaseViewModel({
    onFileSelectedSuccess,
  });

  return { handleSuccess, handleError, setProcessing, processing };
};

export default useViewModel;
