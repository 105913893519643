import { ThemeAPI } from '../themeAPI';
import { Variant } from '../../components/Button/variantStyle';
import { CSSInterpolation } from '../../core/emotion';

export type ColorGlobalTheme = 'primaryColor' | 'secondaryColor';

abstract class ButtonTheme {
  private buttonKey: Variant;
  private globalKey: ColorGlobalTheme;
  protected themeAPI: ThemeAPI;

  constructor(
    buttonKey: Variant,
    globalKey: ColorGlobalTheme,
    themeAPI: ThemeAPI
  ) {
    this.buttonKey = buttonKey;
    this.globalKey = globalKey;
    this.themeAPI = themeAPI;
  }

  protected abstract generateByGlobalColor(): CSSInterpolation;
  protected abstract generateByButtonTheme(): CSSInterpolation;

  generate = (): undefined | CSSInterpolation => {
    if (this.themeAPI.buttons?.[this.buttonKey]) {
      return this.generateByButtonTheme();
    }

    const globalColor = this.themeAPI.globals?.[this.globalKey];

    if (globalColor) {
      return this.generateByGlobalColor();
    }

    return;
  };
}

export default ButtonTheme;
