import {
  useImperativeHandle,
  useMemo,
  useRef,
  KeyboardEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { useDebounce } from 'react-use';
import { usePopper } from '../Dropdown/popper';
import type { Option, ViewModel, ViewModelProps } from '../Dropdown/types';
import { isEqual, isNil, isEmpty as lodashIsEmpty, isBoolean } from 'lodash';
import { useSelect, UseSelectStateChange } from 'downshift';
import { matchSorter } from 'match-sorter';
import { useClickAway } from 'react-use';
import { useSettings } from 'settings';

const useViewModel = ({
  onBlur,
  options,
  onChange,
  value,
  selectRef,
}: ViewModelProps): ViewModel => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleChange = (changes: UseSelectStateChange<Option>) => {
    onChange(changes.selectedItem?.value ?? null);
    onBlur?.();
  };

  const { allowCustomColumns } = useSettings();

  const isEmpty = isNil(value) || (lodashIsEmpty(value) && !isBoolean(value));

  const itemToString = (item: Option | null) => `${item?.value ?? ''}`;
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const {
    isOpen: isOpenDropdown,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    getMenuProps,
    selectedItem,
    openMenu,
    closeMenu,
    reset,
    setHighlightedIndex,
  } = useSelect({
    items: options,
    itemToString,
    selectedItem:
      options.find((option) =>
        isEqual(option.value.getKey(), value?.getKey())
      ) ?? null,
    onSelectedItemChange: handleChange,
    stateReducer: (_state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      switch (type) {
        case useSelect.stateChangeTypes.MenuBlur: {
          return { ...changes, isOpen: true };
        }
        default:
          return changes;
      }
    },
  });

  useEffect(() => {
    if (isOpenDropdown) {
      setSearchValue('');
    }
  }, [isOpenDropdown]);

  const shownValue = useMemo(() => {
    return selectedItem?.label;
  }, [selectedItem]);

  const popper = usePopper({
    isOpenDropdown,
  });

  const focusMenu = () => {
    menuRef?.current?.focus();
  };

  const toggleDropdown = () => {
    if (isOpenDropdown) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  useImperativeHandle(selectRef, () => {
    return {
      closeDropdown: closeMenu,
      toggleDropdown,
      openDropdown: openMenu,
      focusMenu,
      isOpenDropdown,
    };
  });

  const onMenuButtonKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'ArrowLeft':
      case 'ArrowRight':
        // https://github.com/downshift-js/downshift/issues/734
        (
          event.nativeEvent as unknown as { preventDownshiftDefault: boolean }
        ).preventDownshiftDefault = true;
        break;
      case 'Backspace':
        reset();
        break;
      case 'Enter':
        event.stopPropagation();
    }
  };

  const onMenuKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ' ' && isOpenDropdown) {
      (
        event.nativeEvent as unknown as { preventDownshiftDefault: boolean }
      ).preventDownshiftDefault = true;
    }
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
    if (event.key === 'Backspace') {
      reset();
    }
  };

  const onClear = () => {
    reset();
  };

  useDebounce(
    () => {
      setDebouncedValue(searchValue);
    },
    500,
    [searchValue]
  );

  const searchOptions = useMemo(
    () =>
      matchSorter(options, debouncedValue, {
        keys: ['value', 'label'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (item) => item,
      }),
    [options, debouncedValue]
  );

  useClickAway({ current: menuRef.current }, (event) => {
    if (!menuButtonRef.current?.contains(event.target as HTMLElement)) {
      // Handle for react 18
      setTimeout(() => {
        closeMenu();
      }, 0);
    }
  });

  return {
    isOpenDropdown,
    popper,
    shownValue,
    isEmpty,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    getMenuProps,
    menuRef,
    selectedItem,
    onMenuButtonKeyDown,
    onMenuKeyDown,
    onClear,
    setSearchValue,
    searchOptions,
    menuButtonRef,
    searchRef,
    isSearching: !lodashIsEmpty(searchValue),
    searchValue,
    allowCustomColumns,
    setHighlightedIndex,
    toggleDropdown,
  };
};

export default useViewModel;
