import { useLayoutEffect, useEffect, useRef, useState } from 'react';

const oneLineHeight = 31;

const useCollapse = () => {
  const [isShow, setIsShow] = useState(false);
  const refContent = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);
  const [isAvailableCollapse, setIsAvailableCollapse] = useState(false);

  useLayoutEffect(() => {
    if (!refWrapper.current) {
      return;
    }
    if (isShow) {
      refWrapper.current.style.height = `auto`;
    } else {
      refWrapper.current.style.height = `${oneLineHeight}px`;
    }
  }, [isShow]);

  useEffect(() => {
    if (isAvailableCollapse) {
      setIsShow(false);
    }
  }, [isAvailableCollapse]);

  useLayoutEffect(() => {
    const checkAvailableCollapse = () => {
      const height = refContent.current?.offsetHeight ?? 0;

      if (height > oneLineHeight) {
        setIsAvailableCollapse(true);
      } else {
        setIsAvailableCollapse(false);
      }
    };

    checkAvailableCollapse();

    window.addEventListener('resize', checkAvailableCollapse);

    return () => {
      window.removeEventListener('resize', checkAvailableCollapse);
    };
  }, []);

  return { isShow, setIsShow, refContent, isAvailableCollapse, refWrapper };
};

export default useCollapse;
