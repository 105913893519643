import CategoryDataModel from './CategoryDataModel';
import { DataModel } from './DataModel';

export class DataModelContainer {
  private dataModels: DataModel[];
  constructor({ dataModels }: { dataModels: DataModel[] }) {
    this.dataModels = dataModels;
  }

  getDataModelByKey = (key: string) => {
    const dataModel =
      this.dataModels.find((dataModel) => dataModel.getKey() === key) ?? null;

    return dataModel;
  };

  getCategoryModelByKey = (key: string) => {
    const dataModel =
      this.dataModels
        .filter((dataModel) => dataModel.isCategoryType())
        .find((dataModel) => dataModel.getKey() === key) ?? null;

    if (dataModel) {
      return dataModel as CategoryDataModel;
    } else {
      return null;
    }
  };

  getDataModels = () => {
    return this.dataModels;
  };
}
