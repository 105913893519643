import { DataModel, useDataModels } from 'dataModel';
import { useMemo } from 'react';
import { FormValues } from '../viewModel';

const useViewModel = ({ values }: { values: FormValues }) => {
  const dataModel = useDataModels();

  const requiredDataModels = useMemo(() => {
    return dataModel
      .getDataModels()
      .filter((dataModel) => dataModel.getIsRequired());
  }, [dataModel]);

  const matchedDataModels = useMemo(() => {
    return values.matching
      .map((item) => {
        return item.matchedDataModel?.dataModel;
      })
      .filter((item) => !!item) as DataModel[];
  }, [values]);

  return { requiredDataModels, matchedDataModels };
};

export default useViewModel;
