import { css, cx } from '../../../../../core/emotion';
import { ICleaningAssistantContext } from './index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();
  const rootClass = cx([
    css`
      & .nuvo-popover__content {
        padding: 0;
      }
    `,
    css(cleaningAssistantTheme.root),
  ]);

  return {
    rootClass,
  };
};
