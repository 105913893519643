import { isNil, isEmpty as lodashIsEmpty, isBoolean } from 'lodash';
import {
  KeyboardEvent,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Option, SelectRef } from '../Dropdown/types';
import { usePopper } from '../Dropdown/popper';
import { matchSorter } from 'match-sorter';
import { useDebounce } from 'react-use';
import { DATATYPE } from 'core/dataType';
import { KeyboardEventHandler, useImperativeHandle } from 'react';
import { useSettings } from 'settings';

export const itemToString = (item: Option | null) => `${item?.value ?? ''}`;

export const useViewModel = ({
  value,
  isOpenDropdown,
  hasScrollbar,
  closeMenu,
  openMenu,
  reset,
  selectRef,
  options,
  type,
  allowCustomOptions,
}: {
  value?: string | string[];
  isOpenDropdown: boolean;
  hasScrollbar: boolean;
  closeMenu: () => void;
  openMenu: () => void;
  reset: () => void;
  selectRef?: Ref<SelectRef>;
  options: Option[];
  type?: DATATYPE;
  allowCustomOptions?: boolean;
}) => {
  const menuRef = useRef<HTMLButtonElement>();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const { allowCustomOptions: deprecatedAllowCustomOptions } = useSettings();

  const isEmpty = isNil(value) || (lodashIsEmpty(value) && !isBoolean(value));

  const popper = usePopper({
    isOpenDropdown,
    hasScrollbar,
  });

  const focusMenu = () => {
    menuRef?.current?.focus();
  };

  const toggleDropdown = () => {
    if (isOpenDropdown) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  useImperativeHandle(selectRef, () => {
    return {
      closeDropdown: closeMenu,
      toggleDropdown,
      openDropdown: openMenu,
      focusMenu,
      isOpenDropdown,
    };
  });

  const onClear = () => {
    reset();
  };

  const onMenuButtonKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.key === 'Backspace') {
      reset();
    }
  };

  const onMenuKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ' ' && isOpenDropdown) {
      (
        event.nativeEvent as unknown as { preventDownshiftDefault: boolean }
      ).preventDownshiftDefault = true;
    }
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
    if (event.key === 'Backspace') {
      reset();
    }
  };

  useDebounce(
    () => {
      setDebouncedValue(searchValue);
    },
    500,
    [searchValue]
  );

  const optionWithIndex = useMemo(() => {
    return options.map((option, index) => {
      return {
        option,
        index,
      };
    });
  }, [options]);

  const searchOptions = useMemo(
    () =>
      matchSorter(optionWithIndex, debouncedValue, {
        keys: ['option.value', 'option.label'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (item) => item,
      }),
    [optionWithIndex, debouncedValue]
  );

  useEffect(() => {
    if (!isOpenDropdown) {
      setSearchValue('');
    }
  }, [isOpenDropdown]);

  const onSearchOption = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const isShowSearch = useMemo(
    () =>
      ((deprecatedAllowCustomOptions || allowCustomOptions) &&
        type === DATATYPE.SINGLE_SELECT) ||
      options.length > 5,
    [allowCustomOptions, deprecatedAllowCustomOptions, options.length, type]
  );

  const isShowCustomOptions = useMemo(() => {
    return !!(
      (deprecatedAllowCustomOptions || allowCustomOptions) &&
      type === DATATYPE.SINGLE_SELECT
    );
  }, [allowCustomOptions, deprecatedAllowCustomOptions, type]);

  return {
    isEmpty,
    isShowCustomOptions,
    isShowSearch,
    onSearchOption,
    searchOptions,
    onClear,
    onMenuKeyDown,
    popper,
    onMenuButtonKeyDown,
    menuRef,
    searchValue,
    toggleDropdown,
  };
};
