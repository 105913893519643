import { css, cx } from 'core/emotion';
import { SheetTableGrid, SheetTableList } from './common';
import Sheet from './../../sheetImporter/Sheet';
import SpreadSheet, {
  SpreadSheetType,
} from './../../sheetImporter/SpreadSheet';
import SpreadSheetList from './../../sheetImporter/SpreadSheetList';
import TabMenuMultiple from 'baseUI/TabMenuMultiple';
import useViewModel from './viewModel';
import { useTheme } from 'theme';
import AddFile from './AddFile';
import { useSettings } from 'settings';
import { useMemo } from 'react';
import { ViewTemplate } from 'core/constants/storage';

type SheetTableProps = {
  sheets: {
    id: string;
    sheets: Sheet[];
    sheet: Sheet;
    filename: string;
    type: SpreadSheetType;
    sheetName: string;
  }[];
  onSelected: (id: string) => void;
  selected: string[];
  viewTemplate: ViewTemplate;
  onAddFile: (spreadSheetList: SpreadSheetList) => void;
  spreadSheets: SpreadSheet[];
  onSelectMenu: (name: number) => void;
  selectedMenu: number;
  gridHeight: number | null;
};

export const SheetTable = ({
  sheets,
  selected,
  onSelected,
  viewTemplate,
  onAddFile,
  spreadSheets,
  selectedMenu,
  onSelectMenu,
  gridHeight,
}: SheetTableProps) => {
  const { parseDataSheet, tabMenuMultipleRef, isHasScroll } = useViewModel();
  const { multipleFileUpload } = useSettings();
  const gridTemplateWrapper = cx(css`
    grid-template-columns: repeat(auto-fill, 212px);
    grid-template-rows: repeat(2, 1fr);
    overflow: auto;
  `);
  const theme = useTheme();
  const sheetSelectTheme = theme.getSheetSelectTheme();

  const gridContainerStyled = useMemo(() => {
    return css({ '&&': theme.getSheetSelectTheme().grid?.container });
  }, [theme]);

  const listContainerStyled = useMemo(() => {
    return css({ '&&': theme.getSheetSelectTheme().list?.container });
  }, [theme]);

  return (
    <div
      className={cx(
        'rounded-medium min-h-400 flex flex-col bg-gray-50 p-4',
        css({
          '&&': sheetSelectTheme.root,
        })
      )}
    >
      <TabMenuMultiple
        tabMenuMultipleRef={tabMenuMultipleRef}
        isHasScroll={isHasScroll}
        configTheme={{
          root: sheetSelectTheme?.sheetList?.root,
          item: sheetSelectTheme?.unSelectSheetName,
          selectedItem: sheetSelectTheme.sheetName,
          scrollbar: sheetSelectTheme?.scrollbar,
        }}
        menuListWrapperClassName={cx(css`
          height: 100%;
        `)}
        names={spreadSheets.map((spreadSheet) => spreadSheet.getFilename())}
        sizes={spreadSheets.map(
          (spreadSheet) => spreadSheet.getSheets().length
        )}
        bottomAction={
          multipleFileUpload ? <AddFile onAddFile={onAddFile} /> : null
        }
        viewTemplate={viewTemplate}
        gridHeight={gridHeight}
        subtitle={() => {
          return viewTemplate === ViewTemplate.GRID ? (
            <div
              className={cx(
                'rounded-medium grid gap-2 bg-white p-2',
                gridTemplateWrapper,
                gridContainerStyled
              )}
            >
              {sheets.map((sheet, index) => {
                const dataSheet = parseDataSheet(sheet, index);
                return (
                  <SheetTableGrid
                    key={dataSheet.id}
                    sheet={sheet.sheet}
                    dataSheet={dataSheet}
                    onSelected={onSelected}
                    selected={selected}
                    configTheme={sheetSelectTheme.grid}
                    index={index}
                  />
                );
              })}
            </div>
          ) : (
            <div className={cx('min-h-full bg-white', listContainerStyled)}>
              {sheets.map((sheet, index) => {
                const dataSheet = parseDataSheet(sheet, index);
                return (
                  <SheetTableList
                    key={dataSheet.id}
                    sheet={sheet.sheet}
                    dataSheet={dataSheet}
                    onSelected={onSelected}
                    selected={selected}
                    configTheme={sheetSelectTheme.list}
                    index={index}
                  />
                );
              })}
            </div>
          );
        }}
        onSelectMenu={onSelectMenu}
        selectedMenu={selectedMenu}
        selectedAmount={selected.length}
      />
    </div>
  );
};
