import React from 'react';
import { ITabsItem, Tabs } from '@getnuvo/ui-kit';
import { Configuration } from '../Configuration';
import { CleaningFunction } from '../CleaningFunction';
import { PreloadData } from '../PreloadData';
import { DataHandler } from '../DataHandler';
import { Style } from '../Style';

export const ConfigureForm = () => {
  const items: ITabsItem[] = [
    {
      text: 'Configuration',
      content: <Configuration />,
    },
    {
      text: 'Cleaning Function',
      content: <CleaningFunction />,
    },
    {
      text: 'Preload Data',
      content: <PreloadData />,
    },
    {
      text: 'Data Handler',
      content: <DataHandler />,
    },
    {
      text: 'Style',
      content: <Style />,
    },
  ];

  return (
    <div className="nuvo-sdk__configure-form">
      <Tabs items={items} />
    </div>
  );
};
