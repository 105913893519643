import TruncatePopover from 'baseUI/TruncatePopover';
import { css, CSSInterpolation, cx } from 'core/emotion';
import { RefObject, useMemo } from 'react';
import Sheet from './../../../sheetImporter/Sheet';
import useViewModel from './viewModel';
import { GlobalTheme } from '../../../theme/GlobalTheme';

type SheetNameProps = {
  sheet: Sheet;
  configTheme?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  currentSelected: boolean;
  configThemeSheetNameSelected?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  hasMoreAllSheet: boolean;
  isCurrentOrAfter: boolean;
  goToSheet: (sheet: Sheet) => void;
  isConfirmed: boolean;
  globalTheme: GlobalTheme;
};

export const SheetName = ({
  sheet,
  configTheme,
  currentSelected,
  configThemeSheetNameSelected,
  hasMoreAllSheet,
  goToSheet,
  isCurrentOrAfter,
  isConfirmed,
  globalTheme,
}: SheetNameProps) => {
  const { sheetName, fileName, onClick, isClickAble } = useViewModel({
    sheet,
    hasMoreAllSheet,
    goToSheet,
    isCurrentOrAfter,
  });

  const borderBackgroundColor = globalTheme.getPrimaryColor();

  const selectedHeaderNameBgColor = useMemo(() => {
    return globalTheme.getNormal50Color();
  }, [globalTheme]);

  const styledBorderBackgroundColor = useMemo(() => {
    return css({
      backgroundColor: borderBackgroundColor,
    });
  }, [borderBackgroundColor]);

  return (
    <div
      onClick={onClick}
      className={cx(
        'w-fit rounded-tl-medium rounded-medium relative pl-4',
        currentSelected ? 'current' : 'previous',
        hasMoreAllSheet
          ? cx(
              `max-w-204 py-3`,
              currentSelected
                ? css({ backgroundColor: selectedHeaderNameBgColor })
                : !isConfirmed
                ? 'bg-gray-50'
                : cx(
                    css({ backgroundColor: globalTheme.getLight50Color() }),
                    'min-w-204'
                  )
            )
          : 'min-w-auto bg-white pb-0.5',
        currentSelected && hasMoreAllSheet
          ? css({ '&&': configThemeSheetNameSelected?.root })
          : css({ '&&': configTheme?.root }),
        hasMoreAllSheet
          ? isClickAble
            ? cx(
                'cursor-pointer',
                css({
                  ':hover': {
                    backgroundColor: selectedHeaderNameBgColor,
                  },
                })
              )
            : 'cursor-not-allowed'
          : 'cursor-auto'
      )}
    >
      <div
        className={cx(
          'rounded-tl-medium rounded-bl-medium absolute left-0 top-0 h-full w-1',
          currentSelected ? styledBorderBackgroundColor : '',
          currentSelected && hasMoreAllSheet
            ? css({ '&&': configThemeSheetNameSelected?.border })
            : css({ '&&': configTheme?.border })
        )}
      />
      <TruncatePopover
        detectChangeText={fileName}
        render={({ referenceElement, textRef, getHover }) => {
          return (
            <div
              className={cx('flex w-full items-center justify-between')}
              ref={referenceElement as RefObject<HTMLDivElement>}
              {...getHover()}
            >
              <span
                ref={textRef}
                className={cx(
                  'text-color-primary text-left font-medium',
                  hasMoreAllSheet
                    ? 'mb-1 truncate pr-4 text-base'
                    : '-mt-0.5 text-xl',
                  currentSelected && hasMoreAllSheet
                    ? css({ '&&': configThemeSheetNameSelected?.title })
                    : css({ '&&': configTheme?.title })
                )}
              >
                {sheetName}
              </span>
            </div>
          );
        }}
      />
      <TruncatePopover
        detectChangeText={fileName}
        render={({ referenceElement, textRef, getHover }) => {
          return (
            <div
              className={cx('flex w-full items-center justify-between')}
              ref={referenceElement as RefObject<HTMLDivElement>}
              {...getHover()}
            >
              <span
                ref={textRef}
                className={cx(
                  'text-gray-310 truncate text-left text-base opacity-50',
                  hasMoreAllSheet ? 'mt-0 pr-4' : '-mt-0.5',
                  currentSelected && hasMoreAllSheet
                    ? css({ '&&': configThemeSheetNameSelected?.description })
                    : css({ '&&': configTheme?.description })
                )}
              >
                {fileName}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};
