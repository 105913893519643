import { useSettings } from 'settings';

export const useReviewEntriesConfig = () => {
  const { completeImportAction, enableMassiveErrorAlert } = useSettings();

  return {
    completeImportAction: completeImportAction ?? 'discard',
    enableMassiveErrorAlert: enableMassiveErrorAlert,
  };
};
