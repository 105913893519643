export const supportedTypes = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  xml: 'text/xml',
  json: 'application/json',
  tsv_text: 'text/tab-separated-values',
  tsv: '.tsv',
  pdf: 'application/pdf',
};
