import { CategoryDataModel, DataModel } from 'dataModel';
import { Value } from './../../../../../../sheetImporter/Sheet';
import SingleDropdown from './SingleDropdown';
import useViewModel from './viewModel';
import { BaseMatcherOptions } from '../../types';
import MultipleDropdown from './MultipleDropdown';

type OptionMatcherProps = {
  name: string;
  prefixName: string;
  dataModel: DataModel;
  sheetColumnOption: Value;
  index: number;
  hasScrollbar: boolean;
  configThemeMenuButton?: {
    root?: string;
    closeIcon?: string;
    arrowIcon?: string;
    placeholder?: string;
  };
  baseMatcherOptions: BaseMatcherOptions;
  configThemeMenuItem?: {
    root?: string;
    scrollbarNavigatorColor?: string;
    scrollbarBackgroundColor?: string;
    option?: string;
    selectedOption?: string;
    header?: string;
    search?: {
      root?: string;
      icon?: string;
      placeholder?: string;
    };
    createNewOption?: {
      root?: string;
      icon?: string;
    };
    multiSelectionBadge?: {
      root?: string;
      icon?: string;
    };
  };
  renderInVirtual?: boolean;
};

const OptionMatcher = ({
  name: baseName,
  dataModel,
  sheetColumnOption,
  prefixName,
  index,
  configThemeMenuButton,
  configThemeMenuItem,
  hasScrollbar,
  baseMatcherOptions,
  renderInVirtual,
}: OptionMatcherProps) => {
  const { input, options, onChange, onCreateOptions, isMultiSelection } =
    useViewModel({
      baseName,
      dataModel,
      sheetColumnOption,
      prefixName,
      index,
      baseMatcherOptions,
    });

  const dropdownProps = {
    ...input,
    value: input.value,
    onChange: onChange,
    options: options,
    configThemeMenuButton: configThemeMenuButton,
    configThemeMenuItem: configThemeMenuItem,
    hasScrollbar,
    onCreateOptions: onCreateOptions,
    type: dataModel.getType(),
    allowCustomOptions:
      (dataModel as CategoryDataModel).getAllowCustomOptions?.() || false,
    renderInVirtual,
    sheetColumnOption,
  };

  return (
    <div className="max-w-268 flex w-full justify-end">
      {!isMultiSelection ? (
        <SingleDropdown {...dropdownProps} />
      ) : (
        <MultipleDropdown {...dropdownProps} />
      )}
    </div>
  );
};

export default OptionMatcher;
