export const NUVO_ELEMENT_ID = {
  APP_ROOT: 'nuvo-app-root-element',
  PAGE_ROOT: 'nuvo-root-element',
  PAGE_VIEW_ROOT: 'nuvo-root-pageview',
  MODAL_ROOT: 'nuvo-root-modal-element',
  DEV_BANNER_ROOT: 'nuvo-dev-banner-root',
  DEV_BANNER_ROOT_SUB_PAGE: 'nuvo-dev-banner-root-sub-page',
  DEV_BANNER_ICON: 'nuvo-dev-banner-icon',
  DEV_BANNER_TEXT: 'nuvo-dev-banner-text',
  PAGE_WRAPPER: 'page-wrapper',
  STEPPER_ROOT: 'nuvo-stepper-root',
  BASE_MODAL: 'nuvo-base-modal',
  UPLOAD_AREA_ROOT: 'nuvo-upload-area-root',
  FOOTER_ROOT: 'nuvo-footer-root',
  WATERMARK: 'nuvo-watermark',
} as const;
