import { ColumnSetting } from './AllColumnSetting';
import {
  FilterCondition,
  FilterState,
  FilterValueItems,
  FilterValuesSelected,
} from './FilterStrategy';

class ColumnSettingHelper {
  static getFilterByValueSelected = (columnSetting: ColumnSetting) => {
    return columnSetting.filterState?.byValue.selected;
  };

  static getFilterByValueSelectedForPrefill = (
    columnSetting: ColumnSetting,
    valueItems: FilterValueItems
  ) => {
    const selected = columnSetting.filterState?.byValue.selected;

    if (!selected) {
      const updatedSelected: FilterValuesSelected = {};
      for (let i = 0; i < valueItems.length; ++i) {
        const item = valueItems[i];
        updatedSelected[item.value] = true;
      }

      return updatedSelected;
    } else {
      return selected;
    }
  };

  static getFilterByCondition = (columnSetting: ColumnSetting) => {
    return columnSetting.filterState?.byCondition;
  };

  static hasFilterCondition = (columnSetting: ColumnSetting, index: number) => {
    const condition =
      columnSetting.filterState?.byCondition?.items[index]?.condition;

    return condition && condition !== FilterCondition.NONE;
  };

  static hasFilterValueSelected = (columnSetting: ColumnSetting) => {
    return !!columnSetting.filterState?.byValue.selected;
  };

  static setFilter = (
    columnSetting: ColumnSetting,
    filter: FilterState | null
  ) => {
    columnSetting.filterState = filter;
  };

  static addFilterValueSelected = (
    columnSetting: ColumnSetting,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    isSelected: boolean
  ) => {
    const selectedByValue = columnSetting.filterState?.byValue.selected;
    if (selectedByValue) {
      selectedByValue[value] = isSelected;
    }
  };
}

export default ColumnSettingHelper;
