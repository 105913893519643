import SpreadSheetSelecting from './SpreadSheetSelectingStorage';

export const setSelectingSpreadSheetIndex = (index: number) => {
  SpreadSheetSelecting.getInstance().setSelectedSpreadSheet(index);
};

export const getSelectingSpreadSheetIndex = (): number => {
  return SpreadSheetSelecting.getInstance().getSelectedSpreadSheet();
};

export const setSelectingSpreadSheetPageIndex = (index: number) => {
  SpreadSheetSelecting.getInstance().setSelectingSpreadSheetPageIndex(index);
};

export const getSelectingSpreadSheetPageIndex = (): number => {
  return SpreadSheetSelecting.getInstance().getSelectingSpreadSheetPageIndex();
};
