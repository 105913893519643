import CleaningAIServices from './CleaningAssistant.services';
import {
  CleaningLogsRecord,
  CleaningStatus,
  ICleaningAssistantGroup,
  ICleaningAssistantRequestDTO,
  ICleaningAssistantResponse,
  ICleaningAssistantRowSuggestion,
  ICleaningAssistantSuggestion,
} from './CleaningAssistant.dto';
import { DataModel } from '../../../../../dataModel/model/DataModel';

class CleaningAIRepository {
  private cleaningAIServices: CleaningAIServices;

  constructor(cleaningAiAPI: CleaningAIServices) {
    this.cleaningAIServices = cleaningAiAPI;
  }

  // istanbul ignore next
  async getSuggestions(
    licenseKey: string,
    origin: string,
    body: ICleaningAssistantRequestDTO
  ): Promise<ICleaningAssistantResponse> {
    return await this.cleaningAIServices.getSuggestions(
      licenseKey,
      origin,
      body
    );
  }

  groupSuggestions(
    response: ICleaningAssistantResponse,
    dataModels: DataModel[],
    cleaningLogs: CleaningLogsRecord[],
    errorsMap: Record<string, string>
  ): ICleaningAssistantGroup[] {
    const suggestionGroups: ICleaningAssistantGroup[] = [];

    if (!response) {
      return suggestionGroups;
    }

    const indexMap: Record<string, number> = {};

    response.forEach((row: ICleaningAssistantRowSuggestion) => {
      for (const column in row.data) {
        if (indexMap[column] === undefined) {
          indexMap[column] = suggestionGroups.length;
        }

        const index = indexMap[column];

        if (suggestionGroups[index] === undefined) {
          suggestionGroups[index] = {
            column,
            suggestions: [],
          };
        }

        const colIndex = dataModels.findIndex(
          (dm: DataModel) => dm.getKey() === column
        );

        const id = `${row.rowIndex}_${colIndex}`;
        const suggestion: ICleaningAssistantSuggestion = {
          id,
          rowIndex: row.rowIndex,
          colIndex: colIndex,
          column: column,
          value: row.data[column].value,
          suggestion: row.data[column].suggestion,
        };

        suggestionGroups[index].suggestions.push(suggestion);

        if (!cleaningLogs) {
          return;
        }
        /** By default all suggestions are marked as ignored */
        const cleaningIndex = cleaningLogs.findIndex((l) => l.id === id);

        if (cleaningIndex < 0) {
          cleaningLogs?.push({
            id,
            key: column,
            value: row.data[column].value,
            error_message: errorsMap[id] || '',
            suggested_value: row.data[column].suggestion,
            status: CleaningStatus.Ignored,
          });
        }
      }
    });

    return suggestionGroups;
  }
}

export default CleaningAIRepository;
