import {
  createPopper,
  detectOverflow,
  Instance,
  ModifierArguments,
} from '@popperjs/core';

export class ItemMenuPopper {
  isEditing = false;
  private dropdownInstance!: Instance | undefined;
  private rootElement!: Element | null;
  private dropdownElement!: HTMLElement;
  private parentElement!: Element;
  private enableExamples = false;
  private defaultPaddingLeft = 87;
  private paddingLeft = this.defaultPaddingLeft;
  private rootModelElement?: HTMLElement | null;
  private modal?: boolean;

  constructor({
    enableExamples,
    modal,
  }: {
    enableExamples?: boolean;
    modal?: boolean;
  }) {
    this.enableExamples = enableExamples ?? false;
    this.modal = modal;
    if (modal) {
      this.rootModelElement = document.querySelector(
        '#nuvo-root-modal-element'
      );
    }
  }

  initPopper(
    rootElement: Element,
    dropdownElement: HTMLElement,
    parentElement: Element
  ) {
    this.rootElement = rootElement;
    this.dropdownElement = dropdownElement;
    this.parentElement = parentElement;
    this.dropdownInstance = createPopper(
      this.rootElement,
      this.dropdownElement,
      {
        placement: 'bottom',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-1, 0],
            },
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['top'],
              boundary: this.modal ? this.rootModelElement : undefined,
            },
          },
          {
            name: 'overflow-offset',
            enabled: true,
            phase: 'main',
            requiresIfExists: ['offset', 'flip'],
            fn: this.overflowHide,
          },
        ],
      }
    );

    setTimeout(() => {
      this.dropdownInstance?.forceUpdate();
    }, 100);
  }

  open(
    rootElement: Element,
    dropdownElement: HTMLElement,
    parentElement: Element,
    htCloneLeftWtHolderElement?: Element
  ) {
    let updatePaddingLeft;
    if (htCloneLeftWtHolderElement) {
      updatePaddingLeft = htCloneLeftWtHolderElement.clientWidth;
    } else {
      updatePaddingLeft = this.defaultPaddingLeft;
    }
    if (this.paddingLeft !== updatePaddingLeft) {
      this.dropdownInstance?.destroy();
      this.dropdownInstance = undefined;
    }
    this.paddingLeft = updatePaddingLeft;

    this.removeEventListener();

    if (rootElement === this.rootElement) {
      this.rootElement = rootElement;

      if (dropdownElement.hasAttribute('overflow-hide')) {
        return dropdownElement.removeAttribute('overflow-hide');
      }

      this.close();

      return;
    }

    if (dropdownElement.style) {
      dropdownElement.scrollTo(0, 0);
    }
    this.isEditing = true;
    this.addEventListener();
    if (this.dropdownInstance) {
      this.dropdownElement = dropdownElement;
      this.rootElement = rootElement;
      this.dropdownInstance.state.elements.reference = rootElement;
      setTimeout(() => {
        this.show(this.rootElement as Element, this.dropdownElement);
        this.isEditing = true;
      });
    } else {
      this.initPopper(rootElement, dropdownElement, parentElement);
      this.show(this.rootElement as Element, this.dropdownElement);
    }
  }

  show(rootElement: Element, dropdownElement: HTMLElement) {
    this.rootElement = rootElement;
    this.dropdownElement = dropdownElement;

    this.dropdownElement.style.borderWidth = '1px';
    this.dropdownElement.style.opacity = '1';
    this.dropdownElement.style.visibility = 'visible';
    this.dropdownElement.style.pointerEvents = 'auto';
    if (this.dropdownInstance) {
      this.dropdownInstance.state.elements.reference = rootElement;
    }
    this.dropdownInstance?.forceUpdate();
  }

  clearInstance() {
    this.hide();
    this.dropdownInstance?.destroy();
    this.dropdownInstance = undefined;
    this.removeEventListener();
    this.isEditing = false;
    this.rootElement = null;
  }

  close = () => {
    this.hide();
    if (this.dropdownElement) {
      this.isEditing = false;
      this.rootElement = null;
    }
    this.removeEventListener();
  };

  hide = () => {
    if (this.dropdownElement) {
      this.dropdownElement.style.opacity = '0';
      this.dropdownElement.style.visibility = 'hidden';
      this.dropdownElement.style.pointerEvents = 'none';
      this.dropdownElement.style.borderWidth = '0';
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private overflowHide = ({ state }: ModifierArguments<any>) => {
    const overflowWithRef = detectOverflow(state, {
      boundary: this.parentElement,
      elementContext: 'reference',
    });
    const overflow = detectOverflow(state, {
      boundary: this.parentElement,
    });

    const top = this.enableExamples ? -34 : 0;
    const left = this.paddingLeft * -1 + 32;

    if (
      overflowWithRef.top > top ||
      overflowWithRef.bottom > 0 ||
      overflow.left > left ||
      overflow.right > 2
    ) {
      state.attributes['popper']['overflow-hide'] = true;
    } else {
      state.attributes['popper']['overflow-hide'] = false;
    }
  };

  private addEventListener() {
    window.addEventListener('click', this.onClickOutside);
  }

  private removeEventListener() {
    window.removeEventListener('click', this.onClickOutside);
  }

  private onClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLDivElement;
    const targetClassList = target.classList;
    if (
      !targetClassList.contains('custom-dropdown-renderer') &&
      !targetClassList.contains('htAutocomplete') &&
      !targetClassList.contains('wtBorder') &&
      !targetClassList.contains('wtSpreader') &&
      !targetClassList.contains('custom-dropdown-boolean-renderer') &&
      !targetClassList.contains('dropdown-item') &&
      !targetClassList.contains('current') &&
      !targetClassList.contains('search-input') &&
      !targetClassList.contains('dropdown-sub-item') &&
      !targetClassList.contains('tick-path') &&
      !targetClassList.contains('dropdown-header') &&
      !targetClassList.contains('dropdown-editor-scroller') &&
      !targetClassList.contains('dropdown-sub-item-label')
    ) {
      this.close();
    }
  };
}
