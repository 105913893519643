import moment from 'moment';
import { FilterCondition } from '../../../columns/FilterStrategy';
import { DataRow } from '../type';
import { RecordInfo } from '../../../type';
import { Validator } from '../../../../../reviewEntries/validator';
import { DataModel } from '../../../../../dataModel/model/DataModel';

export const CONDITION_NAME = FilterCondition.DATE_AFTER;

export function condition(
  dataRow: DataRow,
  [value]: [value: string],
  __: Record<string, RecordInfo[]>,
  ___: Validator,
  dataModel: DataModel
) {
  const outputFormat = dataModel.getOutputFormat();
  const date = moment(dataRow.value, outputFormat);
  const inputDate = moment(value, outputFormat);

  if (!date.isValid() || !inputDate.isValid()) {
    return false;
  }

  return date.diff(inputDate) >= 0;
}

export const meta = {
  name: 'Filters:conditions.dateAfter',
  inputsCount: 1,
  showOperators: true,
};
