import {
  DataModelSheetMatchingAutoMatching,
  LogAutoOptionMapping,
} from 'core/matching/autoMatching';
import Rxios from 'core/services/Rxios';

abstract class BaseAutoMatching extends Rxios {
  abstract save: (
    identifier: string,
    serializeValue: DataModelSheetMatchingAutoMatching,
    key: string
  ) => Promise<void>;
  abstract getAutoMatch: (
    identifier: string,
    key: string
  ) => Promise<DataModelSheetMatchingAutoMatching | null>;
  abstract saveOption: (
    key: string,
    items: LogAutoOptionMapping[]
  ) => Promise<void>;

  protected handleGetAutoMatch = (
    identifier: string,
    item: {
      identifier: string;
      serializeValue: DataModelSheetMatchingAutoMatching;
    }
  ) => {
    if (item.identifier === identifier) {
      return Promise.resolve(item.serializeValue);
    }
    return Promise.reject(`not found saved matching`);
  };
}

export default BaseAutoMatching;
