import { isDropdownOptionEqual } from '../../dataModel/utils';
import CategoryDataModel, {
  Option,
} from '../../dataModel/model/CategoryDataModel';
import { DataModel } from '../../dataModel/model/DataModel';
import { DATATYPE } from '../../dataType';
import { booleanDropdownOptions } from '../../constants/boolean';

export const filterMultipleValues = ({
  currentValue,
  options,
  updateValue,
  isMultiSelect,
}: {
  currentValue: string | string[];
  options: Option[];
  updateValue: string;
  isMultiSelect: boolean;
}) => {
  if (isMultiSelect) {
    const currentValues = currentValue as string[];
    let newValues: string[] = currentValues.filter((value) => {
      if (value) {
        return options.some((option) => isDropdownOptionEqual(option, value));
      } else {
        return false;
      }
    });
    if (newValues.includes(updateValue)) {
      newValues = newValues.filter((entry) => entry !== updateValue);
    } else {
      newValues = [...newValues, updateValue];
    }
    return newValues;
  } else {
    return updateValue;
  }
};

export const breathing = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const allDropdownOptions = (dataModels: DataModel[]) => {
  const items: {
    label: string;
    value: string;
  }[][] = [];
  for (let i = 0; i < dataModels.length; ++i) {
    let options: { label: string; value: string }[] = [];
    if (dataModels[i].isCategoryType()) {
      for (
        let j = 0;
        j < (dataModels[i] as CategoryDataModel).getOptions().length;
        j++
      ) {
        options.push({
          label: (dataModels[i] as CategoryDataModel).getOptions()[j].label,
          value: (dataModels[i] as CategoryDataModel).getOptions()[j].value,
        });
      }
    } else if (dataModels[i].getType() === DATATYPE.BOOLEAN) {
      options = booleanDropdownOptions();
    }

    items.push(options);
  }
  return items;
};

export const findDropdownOption = (
  columnIndex: number,
  value: string,
  allDropdownOptions: {
    label: string;
    value: string;
  }[][],
  optionValueType: 'label' | 'value' | 'both'
) => {
  const options = allDropdownOptions[columnIndex];
  if (options && options.length > 0) {
    for (let i = 0; i < options.length; ++i) {
      if (optionValueType === 'both') {
        if (
          options[i].value?.toLowerCase()?.trim() ===
            value.toLowerCase()?.trim() ||
          options[i].label?.toLowerCase() === value.toLowerCase()?.trim()
        ) {
          return options[i]['value'];
        }
      } else {
        if (
          optionValueType === 'label'
            ? options[i].value?.toLowerCase()?.trim() ===
              value.toLowerCase()?.trim()
            : options[i].label?.toLowerCase()?.trim() ===
              value.toLowerCase()?.trim()
        ) {
          return options[i][optionValueType];
        }
      }
    }
    return value;
  } else {
    return value;
  }
};
