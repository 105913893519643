import { css, cx } from 'core/emotion';
import { ReactNode } from 'react';
import { useSettings } from 'settings';
import { useTheme } from 'theme';
import {
  HeaderThemeAPI,
  HeadlineSkeleton,
  SubHeadlineSkeleton,
} from '@nuvo-importer/common';

type HeaderProps = {
  title?: string;
  description?: string;
  className?: string;
  readerActions?: ReactNode;
  scaleFontByWidth?: boolean;
  wrapperClassName?: string;
  headerTheme?: HeaderThemeAPI;
  isLoading?: boolean;
};

const Header = ({
  title,
  description,
  className,
  readerActions,
  scaleFontByWidth = true,
  wrapperClassName,
  headerTheme,
  isLoading,
}: HeaderProps) => {
  const theme = useTheme();
  const { modal } = useSettings();
  const headerStyleClassName = modal ? '-mx-3 lg:-mx-8 px-3 lg:px-8' : '';

  const globalHeaderStyle = theme.getHeaderTheme();
  const style: HeaderThemeAPI = {
    root: headerTheme?.root || globalHeaderStyle.root,
    title: headerTheme?.title || globalHeaderStyle.title,
    description: headerTheme?.description || globalHeaderStyle.description,
  };

  return (
    <div
      className={cx(
        'mb-8 px-6 pt-3',
        headerStyleClassName,
        wrapperClassName,
        css({ '&&': style.root })
      )}
    >
      <div className="flex items-end justify-between">
        <div>
          {isLoading ? (
            <HeadlineSkeleton />
          ) : (
            <div
              className={cx(
                'justify-left text-color-primary mb-2 flex items-center space-x-2 font-semibold',
                scaleFontByWidth
                  ? css({
                      '&&': css`
                        font-size: 22px;
                        line-height: 28px;
                        @media (min-width: 1700px) {
                          font-size: 39px;
                          line-height: 47px;
                        }
                      `,
                    })
                  : 'text-4.5xl',
                className,
                css({ '&&': style.title }),
                css`
                  line-height: 1.2 !important;
                `
              )}
            >
              {title ? <h1>{title}</h1> : null}
            </div>
          )}
          {isLoading ? (
            <SubHeadlineSkeleton />
          ) : description ? (
            <div
              className={cx(
                'text-color-secondary mt-2 text-left text-sm font-normal',
                css({ '&&': style.description })
              )}
            >
              <h2>{description}</h2>
            </div>
          ) : (
            <div />
          )}
        </div>
        {readerActions}
      </div>
    </div>
  );
};

export default Header;
