import React, { FC } from 'react';
import { Button, FormElement, Select } from '@getnuvo/ui-kit';
import { Edit, Plus } from 'lucide-react';
import { TDMModal } from './TDMModal';
import './index.css';
import { useViewModel } from './useViewModel';

export const TargetDataModel: FC = () => {
  const {
    editTdm,
    toggleModal,
    tdmOptions,
    tdmValue,
    onTDMChange,
    applyNextTDM,
  } = useViewModel();

  return (
    <>
      <FormElement label="Target Data Model">
        <div className="nuvo-sdk__form-row">
          <div className="nuvo-sdk__form-limitWidth">
            <Select
              placeholder="Select Target Data Model"
              options={tdmOptions}
              value={tdmValue}
              onChange={onTDMChange}
              maxHeight="300px"
              portal
              search
            />
          </div>
          <Button
            startIcon={<Edit />}
            variant="outlineSecondary"
            onClick={toggleModal('edit')}
          >
            Edit
          </Button>
          <Button
            startIcon={<Plus />}
            variant="outlinePrimary"
            onClick={toggleModal('new')}
          >
            Create New
          </Button>
        </div>
      </FormElement>

      {editTdm !== null && (
        <TDMModal
          open={true}
          onDismiss={toggleModal(null)}
          defaultValues={editTdm}
          setCurrentTDM={applyNextTDM}
        />
      )}
    </>
  );
};
