import { useWarnSmallScreen, useHooks } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useMainView, useSettings } from 'settings';
import { MAIN_PAGE_PATH } from 'core/constants/route';
import { CONFIG_ANIM_TIME } from 'core/constants/time';
import SpreadSheetSelecting from '../../uploadData/SheetSelectionPage/common/SpreadSheetSelectingStorage';
import { useScreenSize } from 'core/constants/screensSize';
import useInAppNavigate from 'core/navigate';
import { useContextFileParserWorkerManager } from '../../sheetImporter/worker/WebWorkerProvider';
import { useContextCircleLoadingModalManager } from '../../baseUI/Modal/CircleLoading/context';
import { useEffectOnce } from 'core/useEffectOnce';
import { useMatching } from '../../matching/MatchingProvider';
import { useConfigure } from '../../configure/ConfigureProvider';
import { CacheOption } from '@nuvo-importer/common/sdk';
import { useLicenseKeyAuth } from 'license';
import { FreezeStrategyStore } from '@nuvo-importer/common';
import { useContextCustomViewModalManager } from 'baseUI/Modal/CustomView';
import useLocationPathname from 'core/location';
import Tracking from '../../tracking/Tracking';

const useViewModel = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { cancelHookObservable } = useHooks();
  const { modal } = useMainView();
  const navigate = useInAppNavigate();
  const { isNotSmallScreen } = useScreenSize();
  const { warnSmallScreen } = useWarnSmallScreen();
  const { terminateUploadWebWorker } = useContextFileParserWorkerManager();
  const { convertFile2JsonRef } = useContextCustomViewModalManager();
  const [timeUpload, setTimeUpload] = useState(0);
  const { setProcessing } = useContextCircleLoadingModalManager();
  const { columns, identifier } = useSettings();
  const { isAuth } = useLicenseKeyAuth();
  const { matchingRepository } = useMatching();
  const { licenseKey } = useConfigure();
  const { pathname } = useLocationPathname();

  const openModal = () => {
    if (!isNotSmallScreen) {
      warnSmallScreen(() => setIsOpenModal(false));
    } else {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    if (pathname === MAIN_PAGE_PATH) {
      Tracking.getInstance().clearEventStack(identifier);
    }
  }, [pathname, identifier]);

  const clearSession = useCallback(() => {
    SpreadSheetSelecting.getInstance().removeSheetSelectionViewMode();
  }, []);

  const closeModal = useCallback(() => {
    clearSession();
    terminateUploadWebWorker();
    setTimeout(() => {
      setIsOpenModal(false);
    }, 0);
    setTimeout(() => {
      navigate(MAIN_PAGE_PATH);
    }, CONFIG_ANIM_TIME.MEDIUM);
  }, [clearSession, navigate, terminateUploadWebWorker]);

  const cancel = (isCloseModal = true) => {
    if (isCloseModal) {
      closeModal();
    }
    matchingRepository?.clearData(licenseKey);
    FreezeStrategyStore.cleanUp();
    cancelHookObservable.next(null);
    convertFile2JsonRef.current?.cancel();
    setProcessing(false);
  };

  const closePage = useCallback(() => {
    clearSession();
    matchingRepository?.clearData(licenseKey);
    FreezeStrategyStore.cleanUp();
    if (modal) {
      closeModal();
    } else {
      navigate(MAIN_PAGE_PATH);
    }
    try {
      localStorage.removeItem(CacheOption);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }, [
    modal,
    navigate,
    closeModal,
    clearSession,
    matchingRepository,
    licenseKey,
  ]);

  useEffectOnce(() => {
    const hasDotInColumn = columns.some((column) => {
      return column.key?.includes('.');
    });

    const deprecatedColumnTypes = columns.filter((column) => {
      switch (column.columnType) {
        case 'date_dmy':
        case 'date_iso':
        case 'date_mdy':
        case 'datetime':
        case 'time_hms':
        case 'time_hms_24':
        case 'time_hm':
        case 'time_hm_24':
          return true;
        default:
          return false;
      }
    });

    deprecatedColumnTypes.forEach((item) => {
      console.warn(`nuvo: '${item.columnType}' is deprecated`);
    });

    if (hasDotInColumn) {
      console.warn(
        `nuvo: A column's key should not contain a dot character, as this could result in unexpected behaviour during the review entries step.`
      );
    }
  });

  return {
    isOpenModal,
    openModal,
    cancel,
    closeModal,
    modal,
    closePage,
    setIsOpenModal,
    timeUpload,
    setTimeUpload,
    isAuth,
    identifier,
  };
};

export default useViewModel;
