import { css, CSSInterpolation, cx } from '../../core/emotion';
import { useTranslation } from 'react-i18next';

type ProgressbarProp = {
  step: number;
  sheetsSize: number;
  className?: string;
  configThemeProgressBar?: {
    root?: CSSInterpolation;
    progress?: {
      navigatorColor?: string;
      backgroundColor?: string;
    };
  };
};

const Progressbar = ({
  step = 0,
  sheetsSize,
  className,
  configThemeProgressBar,
}: ProgressbarProp) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        'max-w-204 text-blue-dark-900 rounded-medium min-w-204 flex h-full w-full flex-col px-5 py-3 text-left text-sm font-normal leading-4',
        className,
        css({ '&&': configThemeProgressBar?.root })
      )}
    >
      <p className="mb-0.5">
        {t('txt_progress_bar', {
          step,
          size: sheetsSize,
        })}
      </p>
      <div className="mt-4" aria-hidden="true">
        <div
          className={cx(
            'bg-gray-120 w-full overflow-hidden rounded-full',
            css({
              '&&': {
                backgroundColor:
                  configThemeProgressBar?.progress?.backgroundColor,
              },
            })
          )}
        >
          <div
            className={cx(
              'bg-green-510 h-2 rounded-full',
              css({
                '&&': {
                  backgroundColor:
                    configThemeProgressBar?.progress?.navigatorColor,
                },
              })
            )}
            style={{
              width: `${step === 0 ? 1 : (100 / sheetsSize) * step}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Progressbar;
