import { useCallback, useMemo } from 'react';
import {
  NuvoImporter,
  HookedRecordValue,
  ColumnHookCallback,
  Row,
  HookRowResult,
  RejectSubmitResult,
  ResultValues,
  PassSubmitResult,
  nuvoSession,
  ErrorValues,
  ImportLogs,
  EntryChangeRow,
  EntryChangeResult,
} from 'lib-nuvo-react';
import Button from 'baseUI/Button';
import {
  DataModelType,
  usePreviewConfig,
} from 'app/main/context/PreviewConfigProvider';
import Layout from '../index.view';
import { dataModelCRM, mockDataModels } from '../../utils/dataModelMock';
import { ColumnAPI } from 'lib-nuvo-react';
import { isArray, isEmpty } from 'lodash';
import { sheetSelectionCase1 } from '../dynamicImportCases';
import { UploadOptions } from 'core/dynamicImport/NuvoSession';
import { multiDropdownOptionsTDM } from './multiDropdownOptionsTDM';
import { parseJSON, safeDataHandlerEval } from '../Configure/utils';

const PRODUCT_DATA_IDENTIFIER = 'product_data';
const PRODUCT_DATA_1_IDENTIFIER = 'product_data_1';

const Development = () => {
  const { configure, dataModels } = usePreviewConfig();

  const safeEval = (code: string, nameHook: string) => {
    try {
      // eslint-disable-next-line no-eval
      return eval(code);
    } catch (e) {
      return () => {
        console.log(`Please check the syntax of the ${nameHook}.`);
      };
    }
  };

  const onEntryChange = !isEmpty(configure?.entryChangeHook)
    ? safeEval(configure?.entryChangeHook ?? '', 'onEntryChange')
    : (_rows: EntryChangeRow[]) => {
        const result = [
          {
            rowIndex: 0,
            data: {
              company_domain_name: 'changed!',
            },
          },
          {
            rowIndex: 1,
            data: {
              phone_number: {
                value: 'what',
                info: [
                  {
                    level: 'info',
                    message: 'works',
                  },
                ],
              },
            },
          },
          {
            rowIndex: 0,
            data: {
              company_domain_name: 'updated!',
              email_address: 'crobberts3@stumbleupon.com',
            },
          },
          {
            rowIndex: 1,
            data: {
              email_address: 'test@stumbleupon.com',
              phone_number: {
                info: [
                  {
                    level: 'warning',
                    message: 'what!?',
                  },
                ],
              },
            },
          },
          {
            rowIndex: 2,
            data: {
              email_address: 'test@stumbleupon.com',
              phone_number: {
                info: [
                  {
                    level: 'warning',
                    message: 'what!?',
                  },
                ],
              },
            },
          },
          {
            rowIndex: 6,
            data: {
              company_domain_name: 'what',
            },
          },
        ] as EntryChangeResult[];

        return result;
      };

  const onCancel = !isEmpty(configure?.cancelHook)
    ? safeEval(configure?.cancelHook ?? '', 'onCancel')
    : () => {
        console.log('onCancel');
      };

  // NOTE: switch to testing `onResult` asynchronous task
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const asyncOnResult = async (
    _results: ResultValues,
    _errors: ErrorValues,
    complete: (submitResult?: RejectSubmitResult | PassSubmitResult) => void,
    _logs: ImportLogs
  ): Promise<void> => {
    const res = await fetch(
      'https://my-json-server.typicode.com/getnuvo/nuvo/customers'
    );
    await res.json();
    complete(new RejectSubmitResult('Title Error ', 'Internal Server'));
  };

  // NOTE: switch to testing `onResult` asynchronous task (promise)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const promiseOnResult = async (
    _results: ResultValues,
    _errors: ErrorValues,
    complete: (submitResult?: RejectSubmitResult | PassSubmitResult) => void,
    _logs: ImportLogs
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      fetch('https://my-json-server.typicode.com/getnuvo/nuvo/customers')
        .then((res) => {
          return res.json();
        })
        .then((_data) => {
          setTimeout(() => {
            complete(new RejectSubmitResult('Title Error ', 'Internal Server'));
            resolve();
          }, 5000);
          // complete()
        })
        .catch((err) => {
          reject(err);

          complete(new RejectSubmitResult('Title Error ', 'Internal Server'));
        });
    });
  };

  // NOTE: switch to testing `onResult` pass submit result
  const passSubmitOnResult = (
    results: ResultValues,
    _errors: ErrorValues,
    complete: (submitResult?: PassSubmitResult | RejectSubmitResult) => void,
    _logs: ImportLogs
  ) => {
    console.log('results: ', results);
    complete(
      new PassSubmitResult({
        successfulRecords: 10,
        failedRecords: 5,
        title: 'Successful import',
        text: 'Your data has been successfully imported.',
        imageUrl: '',
        // duration: 500,
      })
    );
    // complete(new RejectSubmitResult('Title Error ', 'Internal Server'));
  };

  // NOTE: switch to testing `onResult` synchronous task
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const syncOnResult = (
    results: ResultValues,
    errors: ErrorValues,
    complete: (submitResult?: RejectSubmitResult) => void
  ) => {
    complete();
  };

  const onResults = !isEmpty(configure?.resultHook)
    ? safeEval(configure?.resultHook ?? '', 'onResults')
    : passSubmitOnResult;

  const onEntryInit = !isEmpty(configure?.entryInitHook)
    ? safeEval(configure?.entryInitHook ?? '', 'onEntryInit')
    : (_row: Row, _rowIndex: number): HookRowResult => {
        return new Promise((resolve, _) => {
          resolve({
            product: {
              info: [
                {
                  level: 'error',
                  message: 'onEntryInit',
                },
              ],
              value: 'product_1',
            },
            storage_temperature: {
              info: [
                {
                  level: 'warning',
                  message: 'onEntryInit',
                },
              ],
            },
            vendor_name: {
              value: 'test',
              info: [
                {
                  level: 'info',
                  message: 'onEntryInit',
                },
              ],
            },
            deposit: {
              info: [
                {
                  level: 'warning',
                  message: 'onEntryInit',
                },
              ],
              value: 'test',
            },
            calorific_value: {
              info: [
                {
                  level: 'error',
                  message: 'onEntryInit',
                },
              ],
              value: 'test kcal',
            },
            continent: {
              info: [
                {
                  level: 'error',
                  message: 'onEntryInit',
                },
              ],
              value: 'asia',
            },
          });
        });
      };

  const columnHooks: Record<string, ColumnHookCallback> = !isEmpty(
    configure?.columnHook
  )
    ? safeEval(configure?.columnHook ?? '', 'columnHooks')
    : {
        storage_temperature: (hookedRecordValues: HookedRecordValue[]) => {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(
                hookedRecordValues.map(([_, rowIndex]) => {
                  return [
                    {
                      info: [
                        {
                          level: 'warning',
                          message: 'this is warn message',
                        },
                      ],
                    },
                    rowIndex,
                  ];
                })
              );
            }, 0);
          });
        },
        deposit: (_hookedRecordValues: HookedRecordValue[]) => {
          return [];
        },
        product_size: () => {
          return [
            [
              {
                value: 200,
                info: [
                  {
                    level: 'error',
                    message: 'this is error message',
                  },
                ],
              },
              2,
            ],
            [
              {
                value: 400,
                info: [
                  {
                    level: 'error',
                    message: 'this is error message',
                  },
                ],
              },
              4,
            ],
          ];
        },
      };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getValidateDropdownOptionsTDM = multiDropdownOptionsTDM;

  const getTargetDataModel = useCallback((): ColumnAPI[] => {
    if (configure?.targetDataModel === DataModelType.CRM) {
      const index =
        dataModels?.findIndex((item) => item.value === DataModelType.CRM) ?? -1;
      if (index > -1) {
        return isArray(dataModels?.[index].config)
          ? ((dataModels?.[index].config ?? []) as ColumnAPI[])
          : [];
      } else {
        return dataModelCRM;
      }
    }

    if (configure?.targetDataModel === DataModelType.TEST_VERSION) {
      const index =
        dataModels?.findIndex(
          (item) => item.value === DataModelType.TEST_VERSION
        ) ?? -1;
      if (index > -1) {
        return isArray(dataModels?.[index].config)
          ? ((dataModels?.[index].config ?? []) as ColumnAPI[])
          : [];
      } else {
        return mockDataModels;
      }
    }

    const index =
      dataModels?.findIndex(
        (item) => item.value === configure?.targetDataModel
      ) ?? -1;

    return isArray(dataModels?.[index]?.config)
      ? ((dataModels?.[index].config ?? []) as ColumnAPI[])
      : [];
  }, [configure?.targetDataModel, dataModels]);

  const onDataHandler = !isEmpty(configure?.dataHandler)
    ? safeDataHandlerEval(configure?.dataHandler ?? '', 'onDataHandler')
    : '';

  // nuvoSession.init()
  nuvoSession.upload(
    sheetSelectionCase1 as UploadOptions,
    PRODUCT_DATA_IDENTIFIER
  );

  const columns = useMemo(() => {
    return JSON.parse(JSON.stringify(getTargetDataModel()));
  }, [getTargetDataModel]);

  return (
    <Layout title="Development">
      <div className="w-full">
        <div className="flex gap-x-1 pb-2">
          <Button
            onClick={() => {
              nuvoSession.start(PRODUCT_DATA_IDENTIFIER);
            }}
          >
            start Product Data
          </Button>
          <Button
            onClick={() => {
              // nuvoSession.upload(
              //   {
              //     step: 'review',
              //     data: [
              //       {
              //         demo: {
              //           value: 'bbbbbbb',
              //         },
              //       },
              //     ],
              //   } as UploadOptions,
              //   PRODUCT_DATA_1_IDENTIFIER
              // );

              nuvoSession.start(PRODUCT_DATA_1_IDENTIFIER);
            }}
          >
            start Product Data 1
          </Button>
        </div>
        <div className="flex flex-col gap-y-2">
          <NuvoImporter
            key={PRODUCT_DATA_IDENTIFIER}
            licenseKey={configure.licenseKey ?? ''}
            settings={{
              developerMode: configure.developerMode,
              identifier: PRODUCT_DATA_IDENTIFIER,
              enableExamples: configure.enableExamples,
              modal: configure.modal,
              title: configure.title,
              disableTemplates: configure.disableTemplates,
              completeImportAction: configure.completeImportAction,
              enableMassiveErrorAlert: configure.enableMassiveErrorAlert,
              maxEntries: configure.maxEntries,
              automaticHeaderDetection: configure.automaticHeaderDetection,
              i18nOverrides: {
                en: {
                  txt_allow_multiple_select_files: 'Select files',
                  txt_match_column: 'Match Columns Step',
                  txt_match_column_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                  txt_review_entries: 'Review Entries',
                  txt_review_entries_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                },
                de: {
                  txt_allow_multiple_select_files: 'Select files de',
                  txt_match_column: 'Match Columns',
                  txt_match_column_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                  txt_review_entries: 'Review Entries',
                  txt_review_entries_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                },
                es: {
                  txt_allow_multiple_select_files: 'Select files es',
                },
                fr: {
                  txt_allow_multiple_select_files: 'Select files fr',
                },
                it: {
                  txt_allow_multiple_select_files: 'Select files it',
                },
                nl: {
                  txt_allow_multiple_select_files: 'Select files nl',
                },
                pl: {
                  txt_allow_multiple_select_files: 'Select files pl',
                },
                pt: {
                  txt_allow_multiple_select_files: 'Select files pt',
                },
                sv: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                cz: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                dk: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                hu: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                lt: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                no: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                ru: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                sk: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
                zh: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
              },
              style: parseJSON(configure.style, {}),
              columns,
              embedUploadArea: configure.embedUploadArea,
              multipleFileUpload: configure.multipleFileUpload,
              disableSuccessModal: configure.disableSuccessModal,
              allowManualInput: configure.allowManualInput,
              onlyMappedColumns: configure.onlyMappedColumns,
              allowCustomColumns: configure.allowCustomColumns,
              allowCustomOptions: configure.allowCustomOptions,
              disableExcelTemplate: configure.disableExcelTemplate,
              automaticMapping: configure.automaticMapping,
              preloadData: parseJSON(configure.preloadData, []),
              buttonMode: configure.buttonMode,
              inputTypes: configure.inputTypes,
              language: configure.language,
              processingEngine: configure.processingEngine,
              smartTable: configure.smartTable,
              cleaningAssistant: configure.cleaningAssistant,
            }}
            onEntryChange={onEntryChange}
            onResults={onResults}
            onCancel={onCancel}
            onEntryInit={onEntryInit}
            columnHooks={columnHooks}
            dataHandler={onDataHandler}
          />
          <NuvoImporter
            key={PRODUCT_DATA_1_IDENTIFIER}
            licenseKey={configure.licenseKey ?? ''}
            settings={{
              developerMode: configure.developerMode,
              identifier: PRODUCT_DATA_1_IDENTIFIER,
              enableExamples: configure.enableExamples,
              modal: configure.modal,
              title: configure.title,
              disableTemplates: configure.disableTemplates,
              completeImportAction: configure.completeImportAction,
              enableMassiveErrorAlert: configure.enableMassiveErrorAlert,
              maxEntries: configure.maxEntries,
              automaticHeaderDetection: configure.automaticHeaderDetection,
              i18nOverrides: {
                en: {
                  txt_allow_multiple_select_files: 'Select files',
                  txt_match_column: 'Match Columns Step',
                  txt_match_column_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                  txt_review_entries: 'Review Entries',
                  txt_review_entries_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                },
                de: {
                  txt_allow_multiple_select_files: 'Select files de',
                  txt_match_column: 'Match Columns',
                  txt_match_column_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                  txt_review_entries: 'Review Entries',
                  txt_review_entries_description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
                },
                es: {
                  txt_allow_multiple_select_files: 'Select files es',
                },
                fr: {
                  txt_allow_multiple_select_files: 'Select files fr',
                },
                it: {
                  txt_allow_multiple_select_files: 'Select files it',
                },
                nl: {
                  txt_allow_multiple_select_files: 'Select files nl',
                },
                pl: {
                  txt_allow_multiple_select_files: 'Select files pl',
                },
                pt: {
                  txt_allow_multiple_select_files: 'Select files pt',
                },
                sv: {
                  txt_allow_multiple_select_files: 'Select files sv',
                },
              },
              style: parseJSON(configure.style, {}),
              columns: [
                {
                  key: 'demo',
                  label: 'demo',
                  columnType: 'string',
                },
              ],
              embedUploadArea: configure.embedUploadArea,
              multipleFileUpload: configure.multipleFileUpload,
              disableSuccessModal: configure.disableSuccessModal,
              allowManualInput: configure.allowManualInput,
              onlyMappedColumns: configure.onlyMappedColumns,
              allowCustomColumns: configure.allowCustomColumns,
              allowCustomOptions: configure.allowCustomOptions,
              disableExcelTemplate: configure.disableExcelTemplate,
              automaticMapping: configure.automaticMapping,
              preloadData: parseJSON(configure.preloadData, []),
              buttonMode: configure.buttonMode,
              inputTypes: configure.inputTypes,
              language: configure.language,
              processingEngine: configure.processingEngine,
              smartTable: configure.smartTable,
            }}
            onEntryChange={onEntryChange}
            onResults={onResults}
            onCancel={onCancel}
            onEntryInit={onEntryInit}
            columnHooks={columnHooks}
            dataHandler={onDataHandler}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Development;
