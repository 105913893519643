import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { ReactComponent as ArrowLeft } from '../../assets/icon/arrow-left.svg';

interface BackButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  label?: string;
  hideIcon?: boolean;
}

const BackButton = ({
  onClick,
  disabled,
  className,
  label,
  hideIcon,
}: BackButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="secondary"
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {!hideIcon && (
        <div className="mr-2 flex items-center">
          <ArrowLeft />
        </div>
      )}
      {t(label ?? 'txt_back')}
    </Button>
  );
};

export default BackButton;
