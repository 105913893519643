import { isArray } from 'lodash';
import { DataModel } from '../../../../../../core/dataModel/model/DataModel';

const SUPPORT_SET_CONSTRUCTOR = new Set([1]).has(1);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createArrayAssertion(initialData: any[], dataModel: DataModel) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataset: any = initialData.map((item) => {
    if (dataModel.isNumeric() && item !== '') {
      const result = Number(item);
      if (isNaN(result)) {
        return item;
      }
      return result;
    }
    return item;
  });

  if (SUPPORT_SET_CONSTRUCTOR) {
    dataset = new Set(dataset);
  }

  const isMultipleDropodown =
    dataModel.isDropdown() && dataModel.getIsMultiSelection();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (value: any) {
    let result;

    if (SUPPORT_SET_CONSTRUCTOR) {
      if (isMultipleDropodown && isArray(value)) {
        result = value.some((item) => dataset.has(item));
      } else {
        result = dataset.has(value);
      }
    } else {
      /* eslint-disable no-bitwise */
      result = !!~dataset.indexOf(value);
    }

    return result;
  };
}
