import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ColumnMapping,
  SHEET_TYPE,
  JoinSheet,
  Sheet,
  SpreadSheetNavigate,
  createWorker,
} from '@nuvo-importer/common/sdk';
import { createJoinSheet, removeJoinSheet } from './joinSheetFactory';
import { IMatchingValuesWorker } from '../../reviewEntries/worker/type';
import matchingValuesBuildWorker from '../../worker/matchingValues.txt';
import { Remote, releaseProxy, wrap } from 'comlink';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import RemoveSheetContent from './components/RemoveSheetContent';

export type HandleAppendCol = (columns: ColumnMapping[]) => void;
export type HandleJoin = (columns: ColumnMapping[]) => Promise<void>;
export type SourceSheetState = { state: Sheet | null };

const loadingTime = 200;
const loadingTimeWithModal = 400;

const useJoinSheet = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state: locationState } = useLocation();
  const state = locationState as {
    spreadSheetNavigate: SpreadSheetNavigate;
    hasBackStep?: boolean;
    dynamicUploadStart?: boolean;
  };
  const [joinTargetSheet, setJoinTargetSheet] = useState<Sheet | null>(null);
  const [appendTargetSheet, setAppendTargetSheet] = useState<Sheet | null>(
    null
  );
  const [sourceSheet, setSourceSheet] = useState<SourceSheetState>({
    state:
      state.spreadSheetNavigate.getSpreadSheetList().getJoinSheet() ?? null,
  });
  const [loading, setLoading] = useState(false);
  const matchingValuesWorker = useRef<Worker>();
  const matchingValuesWorkerRemote =
    useRef<Remote<IMatchingValuesWorker> | null>();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();

  const clearWorker = () => {
    if (matchingValuesWorkerRemote.current) {
      matchingValuesWorkerRemote.current?.[releaseProxy]();
      matchingValuesWorker.current?.terminate();
      matchingValuesWorkerRemote.current = null;
    }
  };

  useEffect(() => {
    return () => {
      clearWorker();
    };
  }, []);

  const close = () => {
    clearWorker();
    setIsOpen(false);
    setJoinTargetSheet(null);
    setAppendTargetSheet(null);
  };

  const onJoinClick = useCallback((sheet: Sheet) => {
    setAppendTargetSheet(null);
    setJoinTargetSheet(sheet);
    setIsOpen(true);
  }, []);

  const onAppendClick = useCallback((sheet: Sheet) => {
    setJoinTargetSheet(null);
    setAppendTargetSheet(sheet);
    setIsOpen(true);
  }, []);

  const removeSheet = (sheet: Sheet) => {
    if (!sourceSheet.state) return;
    setLoading(true);
    setTimeout(async () => {
      if (!sourceSheet.state) return;
      clearWorker();
      matchingValuesWorker.current = createWorker(matchingValuesBuildWorker);
      matchingValuesWorkerRemote.current = wrap<IMatchingValuesWorker>(
        matchingValuesWorker.current
      );
      const updatedJoinSheet = await removeJoinSheet(
        sheet,
        sourceSheet.state,
        matchingValuesWorkerRemote.current
      );
      clearWorker();
      setSourceSheet({
        state: updatedJoinSheet,
      });
      setLoading(false);
    }, loadingTime);
  };

  const onRemoveClick = (sheet: Sheet) => {
    if (!sourceSheet.state) return;
    if (sourceSheet.state.getType() === SHEET_TYPE.NORMAL) {
      removeSheet(sheet);
      return;
    }
    const joinedSheets = (sourceSheet.state as JoinSheet).getJoinedSheets();
    const removeIndex = joinedSheets.findIndex((joinSheet) =>
      joinSheet.equal(sheet)
    );
    const joinHistory = (sourceSheet.state as JoinSheet).getJoinHistory();
    const removedJoinHistories = joinHistory.filter((_history, index) => {
      return index >= removeIndex;
    });
    const isRemoveHasMapping = removedJoinHistories.some((history) => {
      return history.mappingIndexes.length > 0;
    });
    if (!isRemoveHasMapping) {
      removeSheet(sheet);
      return;
    }
    const removedJoinedSheets = joinedSheets.filter(
      (_sheet, index) =>
        index > removeIndex &&
        joinHistory[index === 0 ? 0 : index - 1].mappingIndexes.length > 0
    );
    const joinedColumns = (sourceSheet.state as JoinSheet).getJoinColumns();

    showConfirmModal({
      isShowIcon: true,
      title: t('txt_remove_join_sheet', { sheetName: sheet.getName() }),
      description: t('txt_remove_join_sheet_desc', {
        sheetName: sheet.getName(),
      }),
      content: (
        <RemoveSheetContent
          removedSheets={removedJoinedSheets}
          joinedColumns={joinedColumns}
        />
      ),
      textNegativeButton: t('txt_continue'),
      textPositiveButton: t('txt_cancel'),
      descriptionClassName: '!pb-2',
      onClickNegativeButton: () => {
        removeSheet(sheet);
      },
    });
  };

  const onAddFirstSheet = (sheet: Sheet) => {
    setLoading(true);
    setTimeout(() => {
      setSourceSheet({ state: sheet });
      setLoading(false);
    }, loadingTime);
  };

  const onAddToAllRows = useCallback(
    async (sheet: Sheet) => {
      if (!sourceSheet.state) return;
      setLoading(true);
      clearWorker();
      matchingValuesWorker.current = createWorker(matchingValuesBuildWorker);
      matchingValuesWorkerRemote.current = wrap<IMatchingValuesWorker>(
        matchingValuesWorker.current
      );
      const updatedJoinSheet = await createJoinSheet(
        sourceSheet.state,
        sheet,
        [],
        matchingValuesWorkerRemote.current.addToAllRows
      );
      setTimeout(() => {
        setSourceSheet({ state: updatedJoinSheet });
        setLoading(false);
      }, loadingTime);
      clearWorker();
    },
    [sourceSheet.state]
  );

  const sourceSheets = useMemo(() => {
    if (!sourceSheet.state) {
      return [];
    }

    if (sourceSheet.state.getType() === SHEET_TYPE.JOIN) {
      return [...(sourceSheet.state as JoinSheet).getJoinedSheets()];
    }

    return [sourceSheet.state];
  }, [sourceSheet]);

  const sheets = useMemo(() => {
    return state.spreadSheetNavigate
      .getSpreadSheetList()
      .getSelectedSheets(true);
  }, [state]);

  const unSelectedSheets = useMemo(() => {
    return sheets.filter((sheet) =>
      sourceSheets.every((sourceSheet) => !sourceSheet.equal(sheet))
    );
  }, [sheets, sourceSheets]);

  const handleJoin = async (columns: ColumnMapping[]) => {
    if (!sourceSheet.state || !joinTargetSheet) return;
    clearWorker();
    matchingValuesWorker.current = createWorker(matchingValuesBuildWorker);
    matchingValuesWorkerRemote.current = wrap<IMatchingValuesWorker>(
      matchingValuesWorker.current
    );
    const updateJoinSheet = await createJoinSheet(
      sourceSheet.state,
      joinTargetSheet,
      columns,
      columns.length === 0
        ? matchingValuesWorkerRemote.current.joinWithoutColumns
        : matchingValuesWorkerRemote.current.joinOnColumns
    );
    close();
    setLoading(true);
    setTimeout(() => {
      setSourceSheet({ state: updateJoinSheet });
      setLoading(false);
    }, loadingTimeWithModal);
  };

  const handleAppendCol = async (columns: ColumnMapping[]) => {
    if (!sourceSheet.state || !appendTargetSheet) return;
    clearWorker();
    matchingValuesWorker.current = createWorker(matchingValuesBuildWorker);
    matchingValuesWorkerRemote.current = wrap<IMatchingValuesWorker>(
      matchingValuesWorker.current
    );

    const updateJoinSheet = await createJoinSheet(
      sourceSheet.state,
      appendTargetSheet,
      columns,
      matchingValuesWorkerRemote.current.appendToColumns
    );
    close();
    setLoading(true);
    setTimeout(() => {
      setSourceSheet({ state: updateJoinSheet });
      setLoading(false);
    }, loadingTimeWithModal);
  };

  const disabledContinue = !sourceSheet.state;

  return {
    onJoinClick,
    isOpen,
    close,
    joinTargetSheet,
    sourceSheets,
    unSelectedSheets,
    onRemoveClick,
    onAddFirstSheet,
    sourceSheet,
    handleJoin,
    loading,
    disabledContinue,
    appendTargetSheet,
    onAppendClick,
    handleAppendCol,
    onAddToAllRows,
  };
};

export default useJoinSheet;
